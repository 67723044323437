import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'
import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { StyledSubheaderWithOfferTimer as S } from './SubheaderWithOfferTimer.styles'
import { PRICE_TIMER_PERIOD } from './constants'

type TProps = {
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  weeklyPrice?: number
  dailyPrice?: number
  currency?: string
}

export const SubheaderWithOfferTimer: React.FC<TProps> = ({
  elemForComparisonRef,
  onButtonClick,
  weeklyPrice,
  dailyPrice,
  currency,
}) => {
  const { t } = useTranslation()

  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  return (
    <S.Wrapper>
      <S.Container $isButtonVisible={isButtonVisible}>
        <S.TextContainer>
          <S.TimerText>
            <Trans
              i18nKey="purchaseWithoutIntro.subheaderTimer.price"
              values={{
                currency: CURRENCY_SYMBOLS[currency || Currency.USD],
                price: getCurrentPrice(weeklyPrice),
                context: dailyPrice && PRICE_TIMER_PERIOD,
              }}
            />
          </S.TimerText>
          <S.TimerText>
            {t`purchaseWithoutIntro.subheaderTimer.validTime`}
          </S.TimerText>
        </S.TextContainer>

        <S.TimerWithOffer
          isButtonVisible={isButtonVisible}
          $isButtonVisible={isButtonVisible}
          hasHour
          withText
        />
      </S.Container>

      <S.ButtonContainer $isButtonVisible={isButtonVisible}>
        <S.TimerButton
          data-order="1"
          data-text="get_now"
          onClick={onButtonClick}
        >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
      </S.ButtonContainer>
    </S.Wrapper>
  )
}
