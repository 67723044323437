import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $isDefault?: boolean
  $h?: boolean
}

type TPriceProps = { $hasMexicanCurrency: boolean; $hasSpanishLocale?: boolean }

const commonPriceStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${Color.LIGHT_GREY};
`

const crossedPriceStyles = css`
  text-decoration: line-through;
  text-decoration-color: ${Color.RED};
  color: ${Color.LIGHT_GREY};
`

export const StyledSubscriptionItemIntroOfferV2 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 12px;
    padding: 6px 1px;
    border-radius: 16px;
    background: #f8f9f9;
    border: 1px solid #cfd3da;

    ${({ $isSelected }) =>
      $isSelected &&
      `
        padding: 5px 0;
        border: 2px solid ${Color.PRIMARY};
        background: ${Color.WHITE};
        
    `};

    ${({ $isDefault }) =>
      $isDefault &&
      `
        padding-top: 6px;
        border-top: 20px solid ${Color.PRIMARY};
    `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border-radius: 8px;
      border: 2px solid ${Color.LIGHT_GREY};
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<TProps & TPriceProps>`
    color: ${Color.LIGHT_GREY};
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 4px;

    ${({ $isSelected }) =>
      $isSelected &&
      `
       color: ${Color.DARK};
    `};
    ${({ $hasMexicanCurrency }) =>
      $hasMexicanCurrency &&
      `
       font-size: 17px;
    `};
    ${({ $hasSpanishLocale }) =>
      $hasSpanishLocale &&
      `
       font-size: 18px;
    `};
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span`
    color: ${Color.LIGHT_GREY};
    font-size: 12px;
    font-style: normal;
    text-decoration: line-through;
    text-decoration-color: ${Color.LIGHT_GREY};
    font-weight: 500;
    line-height: 14px;
  `,
  PreviousPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    ${crossedPriceStyles};

    ${({ $hasMexicanCurrency }) =>
      $hasMexicanCurrency &&
      `
        font-size: 12px;
    `};

    & + svg {
      padding: 0 2px;
    }
  `,
  CurrentPrice: styled.span<TProps & TPriceProps>`
    ${commonPriceStyles};

    ${({ $isSelected }) =>
      $isSelected &&
      `
       color: ${Color.DARK};
    `};

    ${({ $hasMexicanCurrency }) =>
      $hasMexicanCurrency &&
      `
       font-size: 12px;
    `};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    min-height: 57px;
    margin-right: 8px;
    padding: 8px 4px;
    color: ${Color.LIGHT_GREY};
  `,
  PriceValue: styled.span<TProps & TPriceProps>`
    font-size: 27px;
    font-weight: 600;
    line-height: 22px;
    padding: 2px 0;

    ${({ $isSelected }) =>
      $isSelected &&
      `
       color: ${Color.DARK};
    `};
    ${({ $hasMexicanCurrency }) =>
      $hasMexicanCurrency &&
      css`
        font-size: 18px;

        strong {
          font-size: 12px;
        }
      `};
    ${({ $hasSpanishLocale }) =>
      $hasSpanishLocale &&
      css`
        font-size: 20px;
      `};
  `,
  Period: styled.span`
    font-size: 12px;
    font-weight: 600;
    line-height: 10px;
  `,
}
