import { Store, createStore } from 'redux'

import { IAction, IAppState } from 'models/store.model'

import { enhancer } from './enhancer'
import { rootReducer } from './rootReducer'

export type TAppStore = Store<IAppState>

export const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  enhancer,
)
