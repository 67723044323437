import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { UPSELL_BENEFITS } from 'modules/purchase/components/UpsellCancelOfferBenefits/constants'

import { MAIN_GOALS } from 'root-constants'

import { StyledUpsellBenefits as S } from './UpsellCancelOfferBenefits.styles'

export const UpsellCancelOfferBenefits: React.FC = () => {
  const { t } = useTranslation()
  const isStayFitFlow = useSelector(selectIsStayFitFlow)

  const chosenGoal = useMemo(
    () => (isStayFitFlow ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitFlow],
  )

  return (
    <S.Wrapper>
      <S.Title>
        {t('upsellCancelOffer.benefitsTitle', {
          context: chosenGoal,
        })}
      </S.Title>
      {UPSELL_BENEFITS[chosenGoal].map(({ id, textPath, img }) => (
        <S.TextContainer key={id}>
          <S.Image src={img} alt={id} />
          <S.Text>{t(textPath)}</S.Text>
        </S.TextContainer>
      ))}
    </S.Wrapper>
  )
}
