import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import bubblesImg from 'assets/images/bubbles.png'
import flagBg from 'assets/images/flag-icon.svg'
import tenMillionPeopleBg from 'assets/images/ten-million-people-japan.png'

import { Gender } from 'root-constants'

import { StyledTenMillionPeople as S } from './TenMillionPeopleJapan.styles'
import { QUESTION } from './constants'

export const TenMillionPeopleJapan: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.PictureContainer>
          <img
            src={imageUrl || tenMillionPeopleBg}
            alt="10 million people trust Dancebit with their fitness goals"
          />
          <S.Title>
            <S.Image src={flagBg} />
            <S.Text>
              <Trans i18nKey="onboarding.tenMillionPeople.question" />
            </S.Text>
          </S.Title>
        </S.PictureContainer>
        <S.Container isLarge>
          <S.Subtitle>
            <Trans i18nKey="onboarding.tenMillionPeople.subtitleBelly" />
          </S.Subtitle>
          <S.BubblesImg src={bubblesImg} alt="bubbles" />
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
