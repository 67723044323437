import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledFixedButton = styled(Button)`
  max-width: 328px;
  font-size: 20px;
  background-color: ${Color.PRIMARY};
  margin: 10px auto;

  &[data-fixed] {
    position: fixed;
    z-index: 3;
    bottom: 20px;
    left: 0;
    right: 0;
  }

  &[data-visible='false'] {
    visibility: hidden;
  }
  &[data-visible='true'] {
    visibility: visible;
  }
`
