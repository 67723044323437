export const BONUS_LIST_ITEM = [
  {
    id: '1',
    text: 'purchaseUpgrade.targetWeight',
  },
  {
    id: '2',
    text: 'purchaseUpgrade.supportSustain',
  },
  {
    id: '3',
    text: 'purchaseUpgrade.energy',
  },
  {
    id: '4',
    text: 'purchaseUpgrade.health',
  },
  {
    id: '5',
    text: 'purchaseUpgrade.personalHealth',
  },
  {
    id: '6',
    text: 'purchaseUpgrade.happier',
  },
]
