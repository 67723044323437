export const QUESTION = 'How often do you get stressed?'

export const OPTION_VALUES_SOMATIC = [
  {
    value: 'regularly',
    title: 'onboarding.stress.regularly',
  },
  {
    value: 'quiteOften',
    title: 'onboarding.stress.quiteOften',
  },
  {
    value: 'rarely',
    title: 'onboarding.stress.rarely',
  },
  {
    value: 'almostNever',
    title: 'onboarding.stress.almostNever',
  },
]
