import React, { ProgressHTMLAttributes } from 'react'

import { StyledProgress } from './Progress.styles'

export interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  color?: string
  $background?: string
}

export const Progress: React.FC<IProps> = (props) => (
  <StyledProgress {...props} />
)
