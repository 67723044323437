import clothesIcon from 'assets/images/flow-belly/icons/clothes.svg'
import pictureIcon from 'assets/images/flow-belly/icons/picture.svg'
import selfieIcon from 'assets/images/flow-belly/icons/selfie.svg'
import tripIcon from 'assets/images/flow-belly/icons/trip.svg'
import meditationIcon from 'assets/images/meditation-icon.svg'

export const QUESTION =
  'After reaching your goal weight, how would you reward yourself?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.rewardYourself.buyingNewClothes',
    value: 'buyingNewClothes',
    imageUrl: clothesIcon,
  },
  {
    text: 'onboarding.rewardYourself.personalDay',
    value: 'personalDay',
    imageUrl: meditationIcon,
  },
  {
    text: 'onboarding.rewardYourself.sharingSocialMedia',
    value: 'sharingSocialMedia',
    imageUrl: pictureIcon,
  },
  {
    text: 'onboarding.rewardYourself.picturesMyself',
    value: 'picturesMyself',
    imageUrl: selfieIcon,
  },
  {
    text: 'onboarding.rewardYourself.traveling',
    value: 'traveling',
    imageUrl: tripIcon,
  },
]
