import React, { useRef } from 'react'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { StyledFixedButton } from './FixedButton.styles'

interface IProps {
  onClick: () => void
  children?: React.ReactNode
}

export const FixedButton: React.FC<IProps> = ({ onClick, children }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isButtonHidden = useTargetHiddenOnScroll(buttonRef)

  return (
    <>
      <StyledFixedButton buttonRef={buttonRef} onClick={onClick}>
        {children}
      </StyledFixedButton>

      <StyledFixedButton
        data-fixed
        data-visible={isButtonHidden}
        onClick={onClick}
      >
        {children}
      </StyledFixedButton>
    </>
  )
}
