import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfoV2 = {
  Wrapper: styled.div`
    position: relative;
    padding: 50px 20px 20px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    border-radius: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Text: styled.p`
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.DISABLED};

    a {
      color: ${Color.PRIMARY};
    }
  `,
  SecureBadge: styled.img`
    position: absolute;
    top: -30px;
    left: calc(50% - 88px);
    max-width: 176px;
  `,
  TermsLink: styled.a`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.PRIMARY};
    text-decoration: none;
  `,
}
