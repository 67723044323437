import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

type TPhotoResultV2Props = {
  $hasBackground?: boolean
}

export const StyledPhotoResultV2 = {
  ResultContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 -20px 24px;
    padding-bottom: 37px;
    border-radius: 24px;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      margin: 0 0 24px;
    }
  `,
  CardWrapper: styled.div<TPhotoResultV2Props>`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin: ${({ $hasBackground }) =>
      $hasBackground ? '0 16px 8px 16px' : '0 16px 16px 16px'};
    padding: 16px;
    border-radius: 24px;
    background-size: cover;
    text-align: left;
    overflow: hidden;
    background: ${Color.WHITE};
    width: 100%;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
  `,
  Author: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
    margin-right: 6px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
    padding: 8px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  PhotoResultWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background-size: cover;
    background: ${Color.LAVENDER_LIGHT};
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
  CarouselContainer: styled.div<TPhotoResultV2Props>`
    max-width: 100%;

    ${({ $hasBackground }) =>
      $hasBackground &&
      css`
        background-color: ${Color.LAVENDER_LIGHT};
        border-radius: 24px;
        padding-bottom: 16px;
      `}

    .swiper-container {
      padding-bottom: 25px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: #e3e4e8;
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
      background: #b8bbc1;
    }
  `,
  Title: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
}
