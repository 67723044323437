import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useElements, useStripe } from '@stripe/react-stripe-js'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  PaymentMethod,
  TRIAL_ONE_MONTH_DURATION,
  WEEK_DURATION,
} from 'modules/purchase/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  CENTS_IN_DOLLAR,
  Cohort,
  SEVEN_DAY_TRIAL_DURATION,
  TimeInterval,
} from 'root-constants'

import { StyledLinkPaymentButton as S } from './LinkPaymentButton.styles'

type TProps = {
  buttonHeight?: number
  borderRadius?: number
  setAlternativePaymentMethodCallback?: (paymentMethod: PaymentMethod) => void
}

export const LinkPaymentButton: React.FC<TProps> = ({
  buttonHeight = 56,
  borderRadius = 16,
  setAlternativePaymentMethodCallback,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const selectedPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const [paymentRequest, setPaymentRequest] = useState<any>(null)

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  const currentPageId = useMemo(() => getPageIdFromPathName(pathname), [
    pathname,
  ])

  const planPeriodDescription = useMemo(() => {
    const isSevenDayPlan =
      trialPeriodDays.toString() === SEVEN_DAY_TRIAL_DURATION ||
      (periodName === TimeInterval.DAY &&
        periodQuantity.toString() === SEVEN_DAY_TRIAL_DURATION)

    return isSevenDayPlan
      ? t('purchase1.paymentForm.trialPlanDescription')
      : t('purchaseIntroOffer.monthlyPeriod', {
          count: trialPeriodDays
            ? Math.round(trialPeriodDays / TRIAL_ONE_MONTH_DURATION)
            : periodQuantity,
        })
  }, [trialPeriodDays, periodName, periodQuantity, t])

  const planPeriodDescriptionWeek = useMemo(
    () =>
      t('purchaseWeeklyOffer.paymentForm.planDescription', {
        count: Math.round(trialPeriodDays / WEEK_DURATION),
      }),
    [t, trialPeriodDays],
  )

  const planDescription = useMemo(
    () =>
      cohortToUse === Cohort.DB_ADULT_WEEK
        ? planPeriodDescriptionWeek
        : planPeriodDescription,
    [cohortToUse, planPeriodDescription, planPeriodDescriptionWeek],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      total: {
        label: planDescription,
        amount: calculatedPrice,
      },
      disableWallets: ['applePay', 'googlePay'],
    })

    pr?.canMakePayment().then((result) => {
      if (!result) {
        dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
        return
      }

      setPaymentRequest(pr)

      if (setAlternativePaymentMethodCallback && result.link) {
        setAlternativePaymentMethodCallback(PaymentMethod.LINK)
      }

      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    dispatch,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    currency,
    setAlternativePaymentMethodCallback,
    planDescription,
  ])

  const handleButtonClick = () => {
    if (!paymentRequest) return

    paymentRequest.show()

    dispatch(setPaymentMethodAction(PaymentMethod.LINK))
    eventLogger.logPaymentMethodSelected(PaymentMethod.LINK)
  }

  return (
    <div>
      {paymentRequest && (
        <S.Wrapper buttonHeight={buttonHeight} borderRadius={borderRadius}>
          <S.Button onClick={handleButtonClick} backgroundColor="#61d17a" />
        </S.Wrapper>
      )}
    </div>
  )
}
