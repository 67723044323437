import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useInitPinterestTag = (pinterestTagId: string): void => {
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldPinterestTagPaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [
      isEUUser,
      userCookieConsentAnswer?.length,
      hasAdvertizingAndMarketingCookie,
    ],
  )

  useLayoutEffect(() => {
    if (!pinterestTagId || !userCountryCode || shouldPinterestTagPaused) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = `
      !function(e) {
        if(!window.pintrk) {
          window.pintrk=function() {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))
          };
          var n=window.pintrk;n.queue=[],n.version="3.0";
          var t=document.createElement("script");t.async=!0,t.src=e;
          var r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)
        }
      }
      ("https://s.pinimg.com/ct/core.js");
      pintrk('load', ${pinterestTagId}, {em: ''});
      pintrk('page');
      `
    document.head.appendChild(script)
  }, [pinterestTagId, shouldPinterestTagPaused, userCountryCode])
}
