import styled from 'styled-components'

type TOptionContainerProps = {
  $hasFullWidth?: boolean
  $withoutMargin?: boolean
  $isDisabled?: boolean
  $flexOrder?: number
}

export const StyledOption = {
  OptionContainer: styled.label<TOptionContainerProps>`
    cursor: pointer;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: ${({ $hasFullWidth }) => ($hasFullWidth ? '100%' : 'auto')};
    margin-bottom: ${({ $withoutMargin }) => ($withoutMargin ? '0' : '12px')};
    pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
    order: ${({ $flexOrder }) => $flexOrder || 'initial'};

    & > input {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
}
