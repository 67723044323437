import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { ScratchCard } from 'components/ScratchCard'
import { SvgImage } from 'components/SvgImage'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import confetti from 'assets/animation/confetti.json'
import partyingIcon from 'assets/images/sprite/partying-face.svg'

import { goTo } from 'browser-history'

import { StyledScratch as S } from './Scratch.styles'
import { QUESTION } from './constants'

export const Scratch: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const [isScratchingStarted, setIsScratchingStarted] = useState<boolean>(false)
  const [isScratchingFinished, setIsScratchingFinished] = useState<boolean>(
    false,
  )
  const confettiAnimationRef = useRef<HTMLDivElement>(null)

  const { currentSubscriptionPageId } = useGetPageInfo()

  useDisableOverscroll()

  useEffect(() => {
    eventLogger.logScratchDiscountPageShow()
  }, [])

  useEffect(() => {
    if (confettiAnimationRef.current && isScratchingFinished) {
      lottie.loadAnimation({
        container: confettiAnimationRef.current,
        animationData: confetti,
        name: 'confetti',
        autoplay: true,
        loop: false,
      })
    }

    return () => lottie.destroy('confetti')
  }, [isScratchingFinished])

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers: '',
    })

    goTo({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [currentSubscriptionPageId, search])

  const handleStart = () => {
    !isScratchingStarted && eventLogger.logScratchingStarted()
    setIsScratchingStarted(true)
  }

  const handleFinish = () => {
    eventLogger.logDiscountPopupShow()
    setIsScratchingFinished(true)
  }

  return (
    <div>
      <Container>
        <PageTitle marginBottom={16}>
          {t('onboarding.scratch.question')}
        </PageTitle>
        <S.Subtitle>
          <Trans i18nKey="onboarding.scratch.subtitle" />
        </S.Subtitle>

        <ScratchCard onComplete={handleFinish} onStart={handleStart} />
        {!isScratchingFinished && (
          <S.Label>
            {t(
              isScratchingStarted
                ? 'onboarding.scratch.keepScratching'
                : 'onboarding.scratch.getDiscount',
            )}
          </S.Label>
        )}
      </Container>

      <S.Modal $isShown={isScratchingFinished}>
        <S.ModalContent>
          <S.Animation ref={confettiAnimationRef} />
          <SvgImage svg={partyingIcon} width={48} height={48} />
          <S.ModalTitle>{t('onboarding.scratch.modal.title')}</S.ModalTitle>
          <S.ModalSubtitle>
            {t('onboarding.scratch.modal.subtitle')}
          </S.ModalSubtitle>
          <strong>{t('onboarding.scratch.modal.discount')}</strong>
          <S.Disclaimer>{t('onboarding.scratch.modal.willApply')}</S.Disclaimer>
          <NavigationButton type="button" onClick={handleClick}>
            {t('onboarding.scratch.getReward')}
          </NavigationButton>
        </S.ModalContent>
      </S.Modal>
    </div>
  )
}
