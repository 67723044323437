import styled from 'styled-components'

import { Container } from 'components/Container'
import { HeaderV2 } from 'components/HeaderV2'

import stickerImg from 'assets/images/sale-sticker-1.png'
import bgImage from 'assets/images/welcome-japan-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const WelcomeJapanStyles = {
  Root: styled.div`
    height: 100%;
    background-image: url(${bgImage});
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-size: contain;
    }
  `,
  Title: styled.div`
    color: #5173a6;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${Color.WHITE};
    paint-order: stroke fill;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 16px;

    & > div {
      margin-top: 8px;
      color: #e53c93;
      text-align: center;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${Color.WHITE};
      font-size: 24px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-radius: 4px;
      border: 4px solid #ffc9e5;
      background: linear-gradient(90deg, #fffce0 0%, #fff7a5 100%);
    }
  `,
  ActionCall: styled.h3`
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
    text-transform: lowercase;
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 16px;
    }
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 11px;
    width: 100%;
    margin-bottom: 26px;
    margin-top: 20px;
  `,
  Action: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 138px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  Terms: styled.div`
    z-index: 2;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #5173a6;
    margin: 0 auto;
    padding-bottom: 16px;

    a {
      color: #5173a6;
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: #5173a6;
    margin: 0 5px;
    vertical-align: middle;
  `,
  Sticker: styled.div`
    position: absolute;
    top: 50px;
    right: 5px;
    width: 86px;
    height: 88px;
    background: url(${stickerImg});
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      color: ${Color.WHITE};
      text-align: center;
      text-transform: uppercase;
      transform: rotate(12deg);
      max-width: 66px;
      padding-bottom: 18px;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-style: italic;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -1px;
    }
  `,
  Container: styled(Container)`
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(var(--full-height) - 56px);
    height: 100%;
  `,
  HeaderV2: styled(HeaderV2)`
    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: linear-gradient(
        146deg,
        #ddecf9 21.61%,
        #fbf3ff 55.52%,
        #fae1fe 87.27%
      );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `,
}
