import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledPreparePlan = {
  ProgressContainer: styled.div`
    margin-bottom: 56px;
  `,
  RatingTitle: styled.h2`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Card: styled.div`
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
    border-radius: 24px;
    padding: 16px;
  `,
  CardRating: styled.img`
    display: block;
    max-width: 90px;
    min-height: 15px;
    margin: 0 auto 8px;
  `,
  CardReview: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  CardReviewer: styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: 32px auto;
    max-width: 280px;
    text-align: center;

    span {
      color: ${Color.PRIMARY};
    }
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    padding-bottom: 24px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
        padding-bottom: 24px;
      `}
  `,
}
