import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import bgImage from 'assets/images/dancebit-user-bg.png'

import { Color } from 'root-constants'

const appearPhoto = keyframes`
    from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
  `

const appearText = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  `

export const StyledDancebitUsers = {
  Wrapper: styled.div`
    height: calc(100% - 141px);
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  `,
  Container: styled(Container)`
    height: 100%;
  `,
  Content: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  UsersBlock: styled.div`
    height: 82px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 12px;
  `,
  User: styled.img`
    position: absolute;
    width: 82px;
    height: 82px;
    top: 0;
    opacity: 0;

    &:nth-child(1) {
      animation: ${appearPhoto} 1s ease-out 0.3s forwards;
      left: 20%;
    }

    &:nth-child(2) {
      animation: ${appearPhoto} 1s ease-out 1.3s forwards;
      left: 40%;
    }

    &:nth-child(3) {
      animation: ${appearPhoto} 1s ease-out 2.3s forwards;
      left: 60%;
    }
  `,
  UsersBlockDescription: styled.div`
    width: 100%;
    height: 24px;
    overflow: hidden;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #525b7d;
    margin-bottom: 64px;

    & div {
      opacity: 0;
      animation: ${appearText} 1s ease-out 2.5s forwards;
    }
  `,
  Title: styled.h2`
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 8px;
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: #e6eafe;
  `,
}
