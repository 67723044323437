import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import imageYesAdult from 'assets/images/green-mark.svg'
import imageNo from 'assets/images/no-icon.svg'
import img from 'assets/images/pick-up-workout-woman-mirror.png'
import imageNoAdult from 'assets/images/red-cross.svg'
import imgAdult from 'assets/images/second-statement.png'
import imageYes from 'assets/images/yes-icon.svg'

import { ApprovalStatus } from 'root-constants'

import { PROPS_FOR_YES_NO_BUTTONS } from '../EasilyGiveUp/constants'
import { StyledNoIdeaToPickUp as S } from './NoIdeaToPickUp.styles'
import { QUESTION } from './constants'

export const NoIdeaToPickUp: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { handleChange, setIsAnswersDisabled } = useDelayedContinue()
  const { isNewAdultFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...PROPS_FOR_YES_NO_BUTTONS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container isLarge $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={77}>
        <Trans i18nKey="onboarding.statements.question" />
      </PageTitle>

      <S.QuestionBlock>
        <S.Image
          src={isNewAdultFlow ? imgAdult : img}
          alt="Do you relate to the statement below?"
        />
        <S.QuestionContainer>
          <S.Question $isAdult={isNewAdultFlow}>
            <Trans i18nKey="onboarding.statements.noIdesPickUp" />
          </S.Question>
        </S.QuestionContainer>
      </S.QuestionBlock>

      <S.ButtonsBLock>
        <AnswerWithIcon
          {...optionProps}
          theme={
            isNewAdultFlow ? answerTheme.DANCEBIT_ADULT : answerTheme.DANCEBIT
          }
          value={ApprovalStatus.YES}
          iconSrc={isNewAdultFlow ? imageYesAdult : imageYes}
        >
          <Trans i18nKey="actions.yes" />
        </AnswerWithIcon>
        <AnswerWithIcon
          {...optionProps}
          theme={
            isNewAdultFlow ? answerTheme.DANCEBIT_ADULT : answerTheme.DANCEBIT
          }
          value={ApprovalStatus.NO}
          iconSrc={isNewAdultFlow ? imageNoAdult : imageNo}
        >
          <Trans i18nKey="actions.no" />
        </AnswerWithIcon>
      </S.ButtonsBLock>
    </S.Container>
  )
}
