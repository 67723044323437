import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CompositeAnswerButton } from 'components/CompositeAnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledDiet as S } from './Diet.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Diet: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <div>
      <Container>
        <S.Title>{t`onboarding.diet.question`}</S.Title>
        <S.DescriptionContainer>
          <PageTitleDescription>
            {t`onboarding.diet.description`}
          </PageTitleDescription>
        </S.DescriptionContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_DIET}
            disabled={isAnswersDisabled}
          >
            <CompositeAnswerButton
              title={t`onboarding.diet.noDiet.title`}
            >{t`onboarding.diet.noDiet.description`}</CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.VEGETARIAN}
            disabled={isAnswersDisabled}
          >
            <CompositeAnswerButton
              title={t`onboarding.diet.vegetarian.title`}
            >{t`onboarding.diet.vegetarian.description`}</CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_GLUTEN_DIET}
            disabled={isAnswersDisabled}
          >
            <CompositeAnswerButton
              title={t`onboarding.diet.noGlutenDiet.title`}
            >{t`onboarding.diet.noGlutenDiet.description`}</CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.VEGAN}
            disabled={isAnswersDisabled}
          >
            <CompositeAnswerButton
              title={t`onboarding.diet.vegan.title`}
            >{t`onboarding.diet.vegan.description`}</CompositeAnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
    </div>
  )
}
