import React, { ButtonHTMLAttributes } from 'react'

import { Button as StorybookButton, buttonTheme } from 'storybook-ui'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { StyledButton } from './Button.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
  disabledBackgroundColor?: string
  margin?: string
  backgroundColor?: string
  color?: string
  className?: string
}

export const Button: React.FC<IProps> = ({
  children,
  buttonRef = null,
  disabledBackgroundColor,
  backgroundColor,
  margin,
  ...props
}) => {
  const { isBellyFlow } = useCohortInfo()

  if (isBellyFlow) {
    return (
      <StorybookButton
        {...props}
        {...(backgroundColor && { backgroundColor })}
        margin={margin}
        theme={buttonTheme.DANCEBIT}
      >
        {children}
      </StorybookButton>
    )
  }

  return (
    <StyledButton
      ref={buttonRef}
      $disabledBackgroundColor={disabledBackgroundColor}
      {...props}
    >
      {children}
    </StyledButton>
  )
}
