import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LiveChatWidget, useWidgetCustomerData } from '@livechat/widget-react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import {
  sendUserConfigAction,
  setLiveChatPropsAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionDuration,
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectGeneralSubscriptionTrialPeriod,
  selectGeneralSubscriptionTrialPrice,
  selectIsUpsellPurchased,
  selectPeriodQuantity,
  selectUserPaymentCurrency,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
  selectUserProductName,
  selectUserStatus,
  selectUserUpsellProductName,
  selectUserUpsellProductPrice,
} from 'root-redux/selects/user'

import { useLiveChatAvailability } from 'hooks/useLiveChatAvailability'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { selectIsSubscriptionUpgraded } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  Cohort,
  CurrentEnvironment,
  FREE_USER_GROUP_ID,
  FREE_USER_GROUP_ID_DEV,
  LIVE_CHAT_PUBLIC_KEY,
  LiveChatStatus,
  Locale,
  MK_EMAIL_COHORTS,
  PlanAddition,
} from 'root-constants'

import { USER_GROUP_ID_DEV, USER_GROUP_ID_PROD } from './constants'

dayjs.extend(utc)
dayjs.extend(tz)

export const LiveChat: React.FC = () => {
  const price = useSelector(selectGeneralSubscriptionPrice)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const subscriptionDuration = useSelector(selectGeneralSubscriptionDuration)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const trialPrice = useSelector(selectGeneralSubscriptionTrialPrice)
  const isUpgraded = useSelector(selectIsSubscriptionUpgraded)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const customerData = useWidgetCustomerData()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const product = useSelector(selectUserProductName)
  const upsellProduct = useSelector(selectUserUpsellProductName)
  const userStatus = useSelector(selectUserStatus)
  const upsellPrice = useSelector(selectUserUpsellProductPrice)
  const isUpsellPurchased = useSelector(selectIsUpsellPurchased)
  const paymentMethod = useSelector(selectUserPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const language = useSelector(selectLanguage)
  const isProd = getCurrentEnv() === CurrentEnvironment.PROD

  const cohortToUse = parentCohort || cohort

  const dispatch = useDispatch()

  const { email, userName, uuid } = usePurchaseStore()

  const { isLiveChatVisible } = useLiveChatAvailability()

  const isEmailCohort = useMemo(
    () => MK_EMAIL_COHORTS.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isSubsDetailsRequired = useMemo(
    () => cohortToUse !== Cohort.EMAIL_INTRO_FAST,
    [cohortToUse],
  )

  const upsellPlan = useMemo(
    () => (isUpsellPurchased ? `${upsellProduct}, ${upsellPrice}` : ''),
    [isUpsellPurchased, upsellPrice, upsellProduct],
  )

  const groupId = useMemo(() => {
    if (isEmailCohort) {
      return isProd ? FREE_USER_GROUP_ID : FREE_USER_GROUP_ID_DEV
    }

    if (isProd) {
      return USER_GROUP_ID_PROD[language] || USER_GROUP_ID_PROD[Locale.ENGLISH]
    }

    return USER_GROUP_ID_DEV[language] || USER_GROUP_ID_DEV[Locale.ENGLISH]
  }, [isEmailCohort, isProd, language])

  const [isInitialOpen, setIsInitialOpen] = useState(false)

  useEffect(() => {
    const subscriptionVars = userStatus?.hasSubscription &&
      isSubsDetailsRequired && {
        subscription_price: price.toString(),
        subscription_duration: subscriptionDuration,
        price_id: priceId,
        trial_period: `${trialPeriodDays}`,
        trial_price: `${trialPrice}`,
        payment_currency: paymentCurrency,
        upsell_product: upsellPlan,
        payment_method: paymentMethod,
        payment_system: paymentSystem,
        language,
        product,
      }

    const addons = cohort === Cohort.DB_GIFT &&
      periodQuantity === '3' && {
        addons: PlanAddition.MEAL_PLAN,
      }

    window.LiveChatWidget.call('update_session_variables', {
      email,
      username: userName || 'No username',
      email_user: isEmailCohort,
      ...addons,
      ...subscriptionVars,
    })

    dispatch(
      sendUserConfigAction({
        email,
        username: userName || 'No username',
        email_user: isEmailCohort,
        ...addons,
      }),
    )
  }, [
    periodQuantity,
    cohort,
    dispatch,
    isSubsDetailsRequired,
    userStatus,
    email,
    price,
    priceId,
    subscriptionDuration,
    trialPeriodDays,
    trialPrice,
    userName,
    isUpgraded,
    paymentCurrency,
    isEmailCohort,
    cohortToUse,
    product,
    upsellProduct,
    upsellPrice,
    upsellPlan,
    paymentMethod,
    paymentSystem,
    language,
  ])

  useEffect(() => {
    window.LiveChatWidget.on('visibility_changed', () => {
      window.LiveChatWidget.call('update_session_variables', {
        livechat_opened: true,
      })

      dispatch(
        sendUserConfigAction({
          livechat_opened: true,
        }),
      )
    })
  }, [dispatch])

  useEffect(() => {
    if (isLiveChatVisible) {
      window.LiveChatWidget.call('maximize')

      if (!isInitialOpen) {
        eventLogger.logLiveChatOpened()
        googleAnalyticsLogger.logLiveChatOpened()
        setIsInitialOpen(true)
      }
    } else {
      window.LiveChatWidget.call('hide')
      setIsInitialOpen(false)
    }
  }, [isInitialOpen, isLiveChatVisible])

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    if (isLiveChatVisible) {
      timerId = setTimeout(() => {
        document.documentElement.style.setProperty(
          '--livechat-visibility',
          'block',
        )
      }, 1000)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [isLiveChatVisible])

  useEffect(() => {
    customerData && dispatch(setLiveChatPropsAction(customerData))
  }, [customerData, dispatch])

  const handleHideGreeting = () => {
    eventLogger.logLiveChatClosed()
    googleAnalyticsLogger.logLiveChatClosed()
  }

  const handleChatVisibilityChanged = ({ visibility }) => {
    if (!isLiveChatVisible) return

    if (visibility === LiveChatStatus.MINIMIZED) {
      eventLogger.logLiveChatClosed()
      googleAnalyticsLogger.logLiveChatClosed()
    }

    if (visibility === LiveChatStatus.MAXIMIZED && !isInitialOpen) {
      eventLogger.logLiveChatOpened()
      googleAnalyticsLogger.logLiveChatOpened()
    }
  }

  return (
    <LiveChatWidget
      license={LIVE_CHAT_PUBLIC_KEY}
      group={groupId}
      customerEmail={uuid}
      customerName={userName || 'No username'}
      onGreetingHidden={handleHideGreeting}
      onVisibilityChanged={handleChatVisibilityChanged}
    />
  )
}
