import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Answer, answerTheme } from 'storybook-ui'

import { HeaderV2 } from 'components/HeaderV2'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowAnimation from 'assets/animation/arrowAnimation.json'
import arrowSvg from 'assets/images/sprite/arrow-button-with-primary-bg.svg'

import { PageId } from 'page-constants'
import { Cohort, Color, Gender, Locale, PagesSource } from 'root-constants'

import { IMPROVE_AREAS_VALUES } from '../ImproveAreas/constants'
import { DEFAULT_OPTIONS, OPTION_VALUES } from '../Welcome/constants'
import { WelcomeBellyRefStyles as S } from './WelcomeBellyRef.styles'
import { QUESTION } from './constants'

export const WelcomeBellyRef: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const animationRef = useRef<HTMLDivElement>(null)

  const uuid = useSelector(selectUUID)
  const lang = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const isNewWelcomeBelly = cohort === Cohort.DB_BELLY80

  const { isTTClearFlow } = useCohortInfo()

  const { title, subtitle, optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  const cards = useMemo(() => {
    if (optionData && optionData.length) return optionData

    return OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS
  }, [optionData, cohortToUse])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            {
              eventID: isPersonalDataAllowed ? uuid : '',
            },
          )
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        dispatch(
          setAnswersAction({
            answers: Gender.FEMALE,
            pageId: PageId.GENDER,
          }),
        )
        dispatch(
          setAnswersAction({
            answers: [IMPROVE_AREAS_VALUES.BELLY],
            pageId: PageId.IMPROVE_AREAS,
          }),
        )

        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      isPersonalDataAllowed,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <>
      <S.Root $isTestBackground={isNewWelcomeBelly}>
        <HeaderV2 />
        <S.Container isLarge>
          {isNewWelcomeBelly ? (
            <div>
              <S.Title $isTestColor>
                <Trans i18nKey="onboarding.welcomeBelly.title" />
              </S.Title>
              <S.Description>
                <Trans i18nKey="onboarding.welcomeBelly.subtitle" />
              </S.Description>
            </div>
          ) : (
            <S.Title
              $isPtLang={lang === Locale.PORTUGUESE}
              $isJaLang={lang === Locale.JAPANESE}
              $isDeLang={lang === Locale.DEUTSCH}
            >
              <Trans
                i18nKey={
                  title || isTTClearFlow
                    ? 'onboarding.welcomeBellyRef.titleClear'
                    : 'onboarding.welcomeBellyRef.title'
                }
              />
            </S.Title>
          )}

          <div>
            <S.Subtitle>
              <Trans
                i18nKey={subtitle || 'onboarding.welcomeBellyRef.subtitle'}
              />
            </S.Subtitle>
            <S.Arrow ref={animationRef} />

            <S.Actions>
              {cards.map(({ value, text, from, to }) => (
                <Answer
                  {...optionProps}
                  theme={answerTheme.DANCEBIT}
                  value={value}
                  minWidth="165px"
                  maxWidth="142px"
                  height="54px"
                  padding="8px 12px"
                  borderRadius="16px"
                  border="1px solid rgba(255, 255, 255, 0.40)"
                  backgroundColor="rgba(255, 255, 255, 0.35)"
                  activeBackgroundColor={Color.PRIMARY}
                  activeBorder={`1px solid ${Color.PRIMARY}`}
                  disabled={isAnswersDisabled}
                  key={value}
                >
                  {text ? (
                    <S.Action>
                      <Trans>{text}</Trans>
                      <SvgImage svg={arrowSvg} height={24} width={24} />
                    </S.Action>
                  ) : (
                    <S.Action>
                      <Trans
                        i18nKey="onboarding.welcome.optionTitle"
                        values={{
                          context: from,
                        }}
                        components={{
                          age: to
                            ? t('onboarding.welcome.age', {
                                from,
                                to,
                              })
                            : from,
                          icon: (
                            <SvgImage
                              svg={arrowSvg}
                              height={24}
                              width={24}
                              fill={Color.PRIMARY}
                            />
                          ),
                        }}
                      />
                    </S.Action>
                  )}
                </Answer>
              ))}
            </S.Actions>
          </div>
        </S.Container>

        <S.Terms>
          <p>{t('footer.disclaimer')}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Root>
    </>
  )
}
