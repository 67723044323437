export const QUESTION = 'How long do you want your workouts to be?'

export const OPTION_VALUES = [
  {
    value: 'easy',
    title: 'onboarding.howLongTrain.easy',
  },
  {
    value: 'medium',
    title: 'onboarding.howLongTrain.medium',
  },
  {
    value: 'hard',
    title: 'onboarding.howLongTrain.hard',
  },
]
