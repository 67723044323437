export const QUESTION = 'How many times per week would you like to train?'

export const OPTION_VALUES = [
  {
    value: '1-2',
  },
  {
    value: '3-4',
  },
  {
    value: '5+',
  },
]
