import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import diagramPicture from 'assets/images/purchaseNoOnboarding/diagram-min.png'
import drNockSmeetonIcon from 'assets/images/purchaseNoOnboarding/dr-nick-smeeton-min.png'
import healthAndHumanDepartmentIcon from 'assets/images/purchaseNoOnboarding/health-and-human-service-min.png'

import { StyledBodyAge as S } from './BodyAge.styles'

export const BodyAge: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>{t('purchaseNoOnboarding.bodyAge.title')}</S.Title>
      <S.Picture src={diagramPicture} alt="" />
      <ul>
        <S.Card>
          <S.Icon
            src={healthAndHumanDepartmentIcon}
            alt="United States Department of Health and Human Services"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseNoOnboarding.bodyAge.text1"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
        <S.Card>
          <S.Icon
            src={drNockSmeetonIcon}
            alt="Dr Nick Smeeton from Stanford University"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseNoOnboarding.bodyAge.text2"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
      </ul>
    </S.Root>
  )
}
