import first from 'assets/images/numbers/first.svg'
import fourth from 'assets/images/numbers/fourth.svg'
import second from 'assets/images/numbers/second.svg'
import third from 'assets/images/numbers/third.svg'

export const HELPS_BENEFITS_DATA = [
  {
    iconSrc: first,
    title: 'purchaseUpgrade.helpsBenefits.title1',
    description: `purchaseUpgrade.helpsBenefits.description1`,
  },
  {
    iconSrc: second,
    title: 'purchaseUpgrade.helpsBenefits.title2',
    description: `purchaseUpgrade.helpsBenefits.description2`,
  },
  {
    iconSrc: third,
    title: 'purchaseUpgrade.helpsBenefits.title3',
    description: `purchaseUpgrade.helpsBenefits.description3`,
  },
  {
    iconSrc: fourth,
    title: 'purchaseUpgrade.helpsBenefits.title4',
    description: `purchaseUpgrade.helpsBenefits.description4`,
  },
]
