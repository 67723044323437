import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledRewardYourself = {
  Container: styled(Container)<{ $isNewAdultFlow: boolean }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
}
