import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDancingVsExercises = {
  Container: styled(Container)`
    position: relative;
    margin-top: 16px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  AnimationContainer: styled.div`
    min-width: 343px;
    min-height: 220px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    overflow: hidden;
    padding: 0 16px 20px;
    margin-bottom: 20px;
  `,
  UserRecommended: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 92px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
}
