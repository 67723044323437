import styled from 'styled-components'

import blueCheck from 'assets/images/check-icon-rounded.svg'
import courseDescriptionBg from 'assets/images/course-description-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCourseDescription = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 266px;
    margin: 0 auto;
    padding: 32px 0 0 16px;
    background: url(${courseDescriptionBg}) no-repeat center;
    background-size: 100% 100%;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Course: styled.p`
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 32px;
      max-width: 210px;
    }

    & > span {
      font-family: 'Caveat', cursive;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: ${Color.WHITE};
      text-transform: capitalize;
    }
  `,
  GoalContainer: styled.div`
    max-width: 240px;
    margin: 0 auto 40px;
  `,
  Goal: styled.p`
    position: relative;
    padding-left: 24px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 16px;
      height: 16px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center no-repeat;
      background-size: contain;
    }
  `,
}
