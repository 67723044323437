import styled from 'styled-components'

export const StyledDiet = {
  Title: styled.header`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 24px;
  `,
  OptionsContainer: styled.div`
    padding-bottom: 40px;
  `,
}
