import { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

export const ThemeLimitedDealContainerConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background: linear-gradient(
      91deg,
      rgba(255, 83, 67, 0.15) -8.21%,
      rgba(225, 34, 108, 0.15) 95.97%
    );
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background: linear-gradient(
      91deg,
      rgba(255, 206, 60, 0.15) -8.21%,
      rgba(255, 144, 0, 0.15) 95.97%
    );
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background: linear-gradient(
      269deg,
      rgba(116, 112, 255, 0.15) 0%,
      rgba(226, 163, 248, 0.15) 125.26%
    );
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background-color: ${Color.BLACK};
  `,
}

export const ThemeLimitedDealDiscountLabelConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background: linear-gradient(120deg, #ffdd63, #ee7200);
    color: ${Color.BLACK};
  `,
}

export const ThemeLimitedDealTextConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background-image: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background-image: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background-image: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
  `,
  [DynamicDiscountTheme.BLACK]: css`
    -webkit-text-fill-color: ${Color.WHITE};
    margin-right: 9px;
  `,
}

export const FreeOfferThemeConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);

    button {
      background: ${Color.WHITE};
      color: ${Color.PRIMARY};
    }
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background: var(--Base-Black100, #000);
  `,
}
