import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledNiceToMeet = {
  Subtitle: styled.h3`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 14px;
    margin-top: 16px;
    color: ${Color.DARK};
    text-align: center;
  `,
  PictureContainer: styled.div`
    width: 100%;
    margin: 0 auto 24px auto;
    aspect-ratio: 320/316;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-height: 500px) and (max-height: 699px) {
      width: 100%;
      max-width: 225px;
    }

    @media (min-height: 699px) and (max-height: 799px) {
      width: 100%;
      max-width: 275px;
    }
  `,
  AgeFlowPictureContainer: styled.div`
    width: 100%;
    max-width: 320px;
    min-height: 320px;
    margin: 32px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
