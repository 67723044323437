import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import betterResultIcon from 'assets/images/better-result-icon.png'

import { StyledTenMillionPeople as S } from './BetterResult.styles'
import { QUESTION } from './constants'

export const BetterResult: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <Container>
          <S.Image src={betterResultIcon} />
          <S.Title>{t('onboarding.betterResult.title')}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="onboarding.betterResult.subtitle" />
          </S.Subtitle>
        </Container>
      </S.ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
