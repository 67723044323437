import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import starIcon from 'assets/images/sprite/mono-star.svg'

import { StyledPopupUpsellProgram as S } from './PopupUpsellProgram.styles'

type TProps = {
  isVisible: boolean
  setIsVisible: () => void
  programCoverImage: string
  product: string
  points: string[]
  checkMarkImage: string
  benefitsBorderColor: string
  benefitsBackgroundColor: string
}

export const PopupUpsellProgram: FC<TProps> = ({
  isVisible,
  product,
  points,
  benefitsBorderColor,
  benefitsBackgroundColor,
  checkMarkImage,
  programCoverImage,
  setIsVisible,
}) => (
  <S.Wrapper $isVisible={isVisible}>
    <S.Content>
      <S.CloseButton onClick={setIsVisible} />

      <S.ImageBlock>
        <S.Image src={programCoverImage} />

        <S.ProgramTitle>
          <Trans i18nKey={`purchaseWithCart.cartProducts.${product}.title`} />
        </S.ProgramTitle>
      </S.ImageBlock>

      <S.ProgramContainer>
        <S.ProgramDescription>
          <Trans
            i18nKey={`purchaseWithCart.cartProducts.${product}.description`}
          />
        </S.ProgramDescription>

        <S.Rating>
          <S.Stars>
            <SvgImage svg={starIcon} width={16} height={16} />
            <SvgImage svg={starIcon} width={16} height={16} />
            <SvgImage svg={starIcon} width={16} height={16} />
            <SvgImage svg={starIcon} width={16} height={16} />
            <SvgImage svg={starIcon} width={16} height={16} />
          </S.Stars>
          5.0
        </S.Rating>

        <S.ProgramOffers>
          <Trans i18nKey={`purchaseWithCart.cartProducts.${product}.offers`} />
        </S.ProgramOffers>

        <S.BenefitList
          $borderColor={benefitsBorderColor}
          $backgroundColor={benefitsBackgroundColor}
        >
          {points?.map((point) => (
            <S.BenefitItem key={point}>
              <S.CheckMark src={checkMarkImage} />
              <S.BenefitText>
                <Trans i18nKey={point} />
              </S.BenefitText>
            </S.BenefitItem>
          ))}
        </S.BenefitList>

        <S.ProgramDescriptionText>
          <Trans
            i18nKey={`purchaseWithCart.cartProducts.${product}.benefitsDescription`}
          />
        </S.ProgramDescriptionText>

        <S.BackButton onClick={setIsVisible}>
          <Trans i18nKey="actions.back" />
        </S.BackButton>
      </S.ProgramContainer>
    </S.Content>
  </S.Wrapper>
)
