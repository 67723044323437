import React, { InputHTMLAttributes } from 'react'

import { StyledToggle as S } from './Toggle.styles'

type TProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange'
> & {
  labels: [string, string]
  onChange: (isChecked: boolean) => void
  isFlat?: boolean
}

export const Toggle: React.FC<TProps> = ({
  labels,
  onChange,
  checked,
  isFlat = false,
  ...props
}) => (
  <S.Wrapper $isFlat={isFlat}>
    <S.Input
      $isFlat={isFlat}
      type="checkbox"
      checked={checked}
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(target.checked)
      }}
      {...props}
    />
    <S.Toggle $isFlat={isFlat} />
    <S.Value $isFlat={isFlat} $labels={labels} />
  </S.Wrapper>
)
