import normalLegsBg from 'assets/images/flow-belly/normal-legs.png'
import normalBg from 'assets/images/flow-belly/normal.png'
import obeseLegsBg from 'assets/images/flow-belly/obese-legs.png'
import obeseBg from 'assets/images/flow-belly/obese.png'
import overweightLegsBg from 'assets/images/flow-belly/overweight-legs.png'
import overweightBg from 'assets/images/flow-belly/overweight.png'
import underweightLegsBg from 'assets/images/flow-belly/underweight-legs.png'
import underweightBg from 'assets/images/flow-belly/underweight.png'
import normalIcon from 'assets/images/sprite/normal.svg'
import riskIcon from 'assets/images/sprite/risk.svg'

export const QUESTION = 'Your Fitness Profile'

export const BMI_VALUES = {
  UNDERWEIGHT: 'underweight',
  NORMAL: 'normal',
  OVERWEIGHT: 'overweight',
  OBESE: 'obese',
}

export const BMI_OPTIONS = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: '#03AAF5',
    bgColor: '#EAF7FF',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightBg,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55D16F',
    bgColor: '#F0FAEE',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalBg,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#F90',
    bgColor: '#FFF4E3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightBg,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#E74C3C',
    bgColor: '#FDEDEB',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseBg,
  },
}
export const BMI_OPTIONS_LEGS = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: '#03AAF5',
    bgColor: '#EAF7FF',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightLegsBg,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55D16F',
    bgColor: '#F0FAEE',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalLegsBg,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#F90',
    bgColor: '#FFF4E3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightLegsBg,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#E74C3C',
    bgColor: '#FDEDEB',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseLegsBg,
  },
}
