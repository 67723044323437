import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { RecommendationCard } from 'components/RecommendationCard'

import { addProductToCart } from 'root-redux/actions/user'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ButtonTapType,
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  UpsellId,
  UpsellScreenId,
} from 'root-constants'

import { StyledHealthProfile as S } from './SuperchargeYourself.styles'
import { cardStyleParams } from './constants'

export const SuperchargeYourself: React.FC = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { t } = useTranslation()

  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown({
      screenName: ScreenName.UPSELL_ONBOARDING,
      screenId: ScreenId.SUPERCHARGE_YOURSELF,
      screen: UpsellScreenId.SCREEN_2,
      goal: MAIN_GOALS.LOSE_WEIGHT,
    })
  }, [hasObUpsellTest, search])

  const handleClick = () => {
    dispatch(addProductToCart(UpsellId.SUPERCHARGE_YOURSELF))

    eventLogger.logUpsellPurchaseButtonTap({
      screenName: ScreenName.UPSELL_ONBOARDING,
      screenId: ScreenId.SUPERCHARGE_YOURSELF,
      screen: UpsellScreenId.SCREEN_2,
      subscription: UpsellId.SUPERCHARGE_YOURSELF,
      tap: ButtonTapType.ADD_TO_PLAN,
    })

    goTo({
      pathname: PageId.TONING_PILATES,
      search,
    })
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose({
      screenName: ScreenName.UPSELL_ONBOARDING,
      screenId: ScreenId.SUPERCHARGE_YOURSELF,
      screen: UpsellScreenId.SCREEN_2,
      tap: ButtonTapType.DONT_NEED,
    })

    goTo({
      pathname: PageId.TONING_PILATES,
      search,
    })
  }

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <S.Container isLarge>
          <S.PageTitle marginBottom={16}>
            <Trans i18nKey="onboarding.superchargeYourself.title" />
          </S.PageTitle>

          <RecommendationCard {...cardStyleParams} />
        </S.Container>
      </S.ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        onClick={handleClick}
        additionalContent={
          <S.SkipUpsell onClick={handleSkip}>
            {t('onboarding.superchargeYourself.skip')}
          </S.SkipUpsell>
        }
        additionalTitle={
          <S.ButtonTitle>
            {t('onboarding.superchargeYourself.buttonTitle')}
          </S.ButtonTitle>
        }
      >
        {t`onboarding.superchargeYourself.addUpsell`}
      </NavigationButtonBelly>
    </>
  )
}
