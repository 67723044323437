import React, {
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import dayjs from 'dayjs'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserMotivationEvent,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import {
  ANSWER_DATE_FORMAT,
  GROWTHBOOK_EXPERIMENT,
  Locale,
} from 'root-constants'

import { NO_MOTIVATING_EVENT } from '../Reach/constants'
import { StyledEventDate as S } from './EventDate.styles'
import { QUESTION } from './constants'

export const EventDate: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const motivationEvent = useSelector(selectCurrentUserMotivationEvent)
  const language = useSelector(selectLanguage)

  const minDate = dayjs().add(1, 'day').toDate()
  const currentDate = dayjs().add(1, 'day').add(3, 'month').toDate()

  const [startDate, setStartDate] = useState<Date>(currentDate)
  const [isOpen, setOpen] = useState(false)

  const { isNewAdultFlow } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const isJapaneseLanguage = useMemo(() => language === Locale.JAPANESE, [
    language,
  ])

  const hasMotivationEvent = useMemo(
    () => motivationEvent === NO_MOTIVATING_EVENT,
    [motivationEvent],
  )

  useLayoutEffect(() => {
    if (hasMotivationEvent) {
      replaceHistory(nextPagePath)
    }
  }, [hasMotivationEvent, nextPagePath])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    const date = dayjs(startDate).format(ANSWER_DATE_FORMAT)

    handleContinue(date)
  }, [handleContinue, startDate])

  const handleSkip = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const toggleDatePickerVisibility = useCallback(
    () => setOpen((open) => !open),
    [],
  )

  return (
    <>
      <S.ContainerBeforePinnedButton $isAdult={isNewAdultFlow}>
        <Container>
          <PageTitle marginBottom={8}>
            {t('onboarding.eventDate.title')}
          </PageTitle>
          <S.Subtitle marginBottom={24}>
            {t('onboarding.eventDate.subtitle')}
          </S.Subtitle>

          <S.CustomDatePicker
            $isJapanese={isJapaneseFlow}
            $isAdult={isNewAdultFlow}
            isJapanese={isJapaneseLanguage}
            language={language}
            selectedDate={startDate}
            minDate={minDate}
            toggleDatePickerVisibility={toggleDatePickerVisibility}
            onChange={setStartDate}
          />
        </Container>
      </S.ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        type="button"
        $isAdult={isNewAdultFlow}
        disabled={isOpen}
        onClick={handleNextClick}
        additionalContent={
          <S.SkipButton onClick={handleSkip}>
            {t('actions.skipQuestion')}
          </S.SkipButton>
        }
      >
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </>
  )
}
