import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Swiper from 'swiper'

import { Accordion } from 'components/Accordion'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReachTarget } from 'modules/purchase/components/ReachTarget'
import { Reviews } from 'modules/purchase/components/Reviews'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionTwoPlansBlock } from 'modules/purchase/components/SubscriptionTwoPlansBlock'
import { TimerWithPersonalization } from 'modules/purchase/components/TimerWithPersonalization'
import { TwoPlansTimer } from 'modules/purchase/components/TwoPlansTimer'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import appStore from 'assets/images/app-store.svg'
import googlePlay from 'assets/images/google-play.svg'
import usersRecommended from 'assets/images/recommended-by.png'
import starSvg from 'assets/images/sprite/two-color-star.svg'

import {
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutTwoPlans } from '../CheckoutTwoPlans'
import { StyledPurchaseTwoPlans as S } from './PurchaseTwoPlans.styles'

export const PurchaseTwoPlans: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)
  const { buttonText } = useDynamicPaywallConfig()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)
  const [firstSwiper, setFirstSwiper] = useState<Swiper | undefined>(undefined)
  const [secondSwiper, setSecondSwiper] = useState<Swiper | undefined>(
    undefined,
  )

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
  } = usePurchaseStore()

  usePurchaseAnalytics({
    screenId: ScreenId.TWO_PLANS,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const hasIncludedVat = useVatInfo()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.TWO_PLANS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${hasIncludedVat ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX}`,
      ),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const onChoosePlan = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <div>
      {!isCheckoutShown && (
        <>
          <ReachTarget />
          <S.Wrapper>
            <TimerWithPersonalization />
            <S.GoalBlock>
              <S.GoalSuggestion>
                {t('purchaseTwoPlans.goalBlock.title')}
              </S.GoalSuggestion>
              <S.Goal>
                <Trans
                  i18nKey="purchaseTwoPlans.goalBlock.goal"
                  values={{
                    context: isStayFitGoal
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              </S.Goal>
              <S.GoalDescription>
                <Trans
                  i18nKey="purchaseTwoPlans.goalBlock.description"
                  values={{
                    context: isStayFitGoal
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              </S.GoalDescription>
            </S.GoalBlock>
            <S.GraphContainer>
              {isStayFitGoal ? (
                <S.GoalChartAge hasTitle={false} />
              ) : (
                <S.LoseWeightGraphAge hasTitle={false} />
              )}
              <S.UserRecommended>
                <S.Img src={usersRecommended} alt="Users recommended" />
                <S.Text>
                  <Trans i18nKey="purchaseTwoPlans.goalBlock.userRecommended" />
                </S.Text>
              </S.UserRecommended>
            </S.GraphContainer>
          </S.Wrapper>
          <TwoPlansTimer
            elemForComparisonRef={buttonWrapperElementRef}
            onButtonClick={onChoosePlan}
          />

          <S.Wrapper>
            <SubscriptionTwoPlansBlock
              onSwiperInit={setFirstSwiper}
              controller={secondSwiper}
            >
              <S.Button
                type="button"
                data-order="2"
                data-text="get_my_plan"
                onClick={onChoosePlan}
              >
                {buttonText || t`actions.getMyPlan`}
              </S.Button>
            </SubscriptionTwoPlansBlock>

            <S.Container ref={buttonWrapperElementRef}>
              <FeaturedBlock />
            </S.Container>
            <S.PurchasePhoneSlider />
            <S.DisclaimerBlock>
              <S.AppsContainer>
                <S.App>
                  <S.AppLogo src={googlePlay} alt="Google Play" />
                  <S.AppName>{t('purchaseTwoPlans.googlePlay')}</S.AppName>
                </S.App>
                <S.App>
                  <S.AppLogo src={appStore} alt="App Store" />
                  <S.AppName>{t('purchaseTwoPlans.appStore')}</S.AppName>
                </S.App>
              </S.AppsContainer>
              <S.Rating>
                <S.RatingStars>
                  <SvgImage svg={starSvg} width={20} />
                  <SvgImage svg={starSvg} width={20} />
                  <SvgImage svg={starSvg} width={20} />
                  <SvgImage svg={starSvg} width={20} />
                  <SvgImage svg={starSvg} width={20} />
                </S.RatingStars>

                <S.RatingBlock>
                  <S.RatingText>
                    <Trans i18nKey="purchaseTwoPlans.rating" />
                  </S.RatingText>
                </S.RatingBlock>
              </S.Rating>

              <S.DownloadsCount>
                <Trans i18nKey="purchaseTwoPlans.downloads" />
              </S.DownloadsCount>

              <IntroOfferDisclaimer />
            </S.DisclaimerBlock>

            <S.PhotoResult
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />

            <S.PurchaseButton
              type="button"
              data-order="3"
              data-text="get_my_plan"
              onClick={onChoosePlan}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.PurchaseButton>

            <S.AccordionTitle>
              <Trans i18nKey="purchaseTwoPlans.accordion.title" />
            </S.AccordionTitle>

            <Accordion />
            <Reviews />

            <SubscriptionTwoPlansBlock
              onSwiperInit={setSecondSwiper}
              controller={firstSwiper}
            >
              <S.Button
                type="button"
                data-order="4"
                data-text="get_my_plan"
                onClick={onChoosePlan}
              >
                {buttonText || t`actions.getMyPlan`}
              </S.Button>
            </SubscriptionTwoPlansBlock>

            <MoneyBackGuarantee />
            <SecurityInfo />
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutTwoPlans
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </div>
  )
}
