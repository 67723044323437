import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { FBConsent } from 'root-constants'

export const useFacebookPixelInitialization = (
  facebookPixelIds: string[],
): void => {
  const uuid = useSelector(selectUUID)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
    isPersonalDataAllowed,
  } = useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!uuid || !facebookPixelIds.length) return

    const fbPixelScript = document.createElement('script')

    fbPixelScript.type = 'text/javascript'
    fbPixelScript.text = `
    !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
    `
    document.body.appendChild(fbPixelScript)

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, {
        external_id: isPersonalDataAllowed ? uuid : '',
      })

      !isPersonalDataAllowed &&
        window.fbq('set', 'autoConfig', false, facebookPixelId)
    })

    isEUUser &&
      (!userCookieConsentAnswer?.length || !hasAdvertizingAndMarketingCookie) &&
      window.fbq('consent', FBConsent.REVOKE)

    isEUUser &&
      userCookieConsentAnswer?.length &&
      hasAdvertizingAndMarketingCookie &&
      window.fbq('consent', FBConsent.GRANT)

    window.fbq('track', 'PageView', {})
  }, [
    facebookPixelIds,
    hasAdvertizingAndMarketingCookie,
    isEUUser,
    isPersonalDataAllowed,
    userCookieConsentAnswer?.length,
    uuid,
  ])
}
