import styled from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

import { ThemeTickerConfig } from './constants'

type TTheme = {
  $theme?: DynamicDiscountTheme
}

export const StyledTicker = {
  Root: styled.div<TTheme>`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.5px;
    font-style: normal;
    text-transform: uppercase;
    position: relative;
    z-index: 0;

    & .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }

    & .swiper-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
    }

    & svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    ${({ $theme }) =>
      $theme ? ThemeTickerConfig[$theme] : ThemeTickerConfig.violet}
  `,
  Sale: styled.p`
    display: flex;
    align-items: center;
    margin-right: 4px;
  `,
}
