import arms from 'assets/images/improve-arms-adult.png'
import belly from 'assets/images/improve-belly-adult.png'
import buttocks from 'assets/images/improve-buttocks-adult.png'
import chest from 'assets/images/improve-chest-adult.png'
import fullBody from 'assets/images/improve-full-body-adult.png'
import hips from 'assets/images/improve-hips-adult.png'
import legs from 'assets/images/improve-legs-adult.png'

import { IMPROVE_AREAS_VALUES } from '../ImproveAreas/constants'

export const QUESTION = 'Which areas do you want to focus on?'
export const OPTION_VALUES = [
  {
    text: 'onboarding.improveAreas.legs',
    value: IMPROVE_AREAS_VALUES.LEGS,
    imageUrl: legs,
  },
  {
    text: 'onboarding.improveAreas.belly',
    value: IMPROVE_AREAS_VALUES.BELLY,
    imageUrl: belly,
  },
  {
    text: `onboarding.improveAreas.arms`,
    value: IMPROVE_AREAS_VALUES.ARMS,
    imageUrl: arms,
  },
  {
    text: `onboarding.improveAreas.chest`,
    value: IMPROVE_AREAS_VALUES.CHEST,
    imageUrl: chest,
  },
  {
    text: 'onboarding.improveAreas.buttocks',
    value: IMPROVE_AREAS_VALUES.BUTTOCKS,
    imageUrl: buttocks,
  },
  {
    text: 'onboarding.improveAreas.hips',
    value: IMPROVE_AREAS_VALUES.HIPS,
    imageUrl: hips,
  },
  {
    text: 'onboarding.improveAreas.fullBody',
    value: IMPROVE_AREAS_VALUES.FULL_BODY,
    imageUrl: fullBody,
  },
]
