import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import lottie from 'lottie-web/build/player/lottie_light'

import { eventLogger } from 'services/eventLogger.service'

import scratch from 'assets/animation/scratching.json'
import brush from 'assets/images/brush.png'
import couponBg from 'assets/images/coupon-bg.png'
import couponOpenedWhite from 'assets/images/coupon-opened-white.png'
import couponOpened from 'assets/images/coupon-opened.png'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledScratchCard as S } from './ScratchCard.styles'

const { canvasWidth, canvasHeight, img } = {
  canvasWidth: 311,
  canvasHeight: 311,
  img: couponBg,
}

type TPoint = {
  x: number
  y: number
}

type TProps = {
  onComplete: () => void
  onStart: () => void
}

const DISCOUNT = '50%'

export const ScratchCard: React.FC<TProps> = ({ onComplete, onStart }) => {
  const [loaded, setLoaded] = useState(false)
  const [numberOfScratches, setNumberOfScratches] = useState(0)
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const brushImageRef = useRef<HTMLImageElement | null>(null)

  const isDrawingRef = useRef(false)
  const lastPointRef = useRef<TPoint | null>(null)
  const isFinishedRef = useRef(false)
  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (animationRef.current && loaded) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: scratch,
        name: 'scratch',
        autoplay: true,
        loop: true,
      })
    }

    return () => lottie.destroy('scratch')
  }, [loaded])

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctxRef.current = ctx

    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight)
      setLoaded(true)
    }
    image.src = img
    imageRef.current = image

    const brushImage = new Image(30, 30)
    brushImage.src = brush
    brushImageRef.current = brushImage
  }, [])

  const getFilledInPixels = useCallback((stride = 1) => {
    const canvas = canvasRef.current
    const ctx = ctxRef.current

    if (!canvas || !ctx) return 0

    const x = 0
    const y = 0
    const { width } = canvas
    const { height } = canvas

    const pixels = ctx.getImageData(x, y, width, height)
    const total = pixels.data.length / stride
    let count = 0

    for (let i = 0; i < pixels.data.length; i += stride) {
      if (parseInt(pixels.data[i].toString(), 10) === 0) {
        count++
      }
    }

    return Math.round((count / total) * 100)
  }, [])

  const getMouse = (e: any, canvas: HTMLCanvasElement) => {
    const { top, left } = canvas.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

    let x = 0
    let y = 0

    if (e.pageX && e.pageY) {
      x = e.pageX - left - scrollLeft
      y = e.pageY - top - scrollTop
    } else if (e.touches) {
      x = e.touches[0].clientX - left - scrollLeft
      y = e.touches[0].clientY - top - scrollTop
    }

    return {
      x,
      y,
    }
  }

  const distanceBetween = (point1: TPoint | null, point2: TPoint | null) => {
    if (point1 && point2) {
      return Math.sqrt(
        // eslint-disable-next-line no-restricted-properties
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
      )
    }
    return 0
  }

  const angleBetween = (point1: TPoint | null, point2: TPoint | null) => {
    if (point1 && point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y)
    }
    return 0
  }

  const handlePercentage = useCallback(
    (filledInPixels = 0) => {
      if (isFinishedRef.current) return

      const finishPercent = 50

      if (filledInPixels > finishPercent) {
        if (canvasRef.current) {
          canvasRef.current.style.transition = '1s'
          canvasRef.current.style.opacity = '0'
        }

        eventLogger.logScratchingFinished(numberOfScratches)
        onComplete && onComplete()

        isFinishedRef.current = true
      }
    },
    [numberOfScratches, onComplete],
  )

  const handleMouseDown = (e: any) => {
    isDrawingRef.current = true
    lastPointRef.current = getMouse(e, canvasRef.current as HTMLCanvasElement)
    onStart()

    setNumberOfScratches((value) => value + 1)
  }

  const handleMouseMove = (e: any) => {
    if (!isDrawingRef.current) return

    const canvas = canvasRef.current
    const ctx = ctxRef.current

    if (!canvas || !ctx) return

    const currentPoint = getMouse(e, canvas)
    const distance = distanceBetween(lastPointRef.current, currentPoint)
    const angle = angleBetween(lastPointRef.current, currentPoint)

    for (let i = 0; i < distance; i++) {
      const x = (lastPointRef.current?.x || 0) + Math.sin(angle) * i || 0
      const y = (lastPointRef.current?.y || 0) + Math.cos(angle) * i || 0

      ctx.globalCompositeOperation = 'destination-out'

      brushImageRef.current &&
        ctx.drawImage(brushImageRef.current, x, y, 30, 30)
    }

    lastPointRef.current = currentPoint
    handlePercentage(getFilledInPixels(32))
  }

  const handleMouseUp = () => {
    isDrawingRef.current = false
  }

  return (
    <S.Container
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 2,
        }}
        width={canvasWidth}
        height={canvasHeight}
      />
      <S.Animation
        ref={animationRef}
        style={{
          display: numberOfScratches === 0 ? 'visible' : 'none',
        }}
      />
      <S.Card
        style={{
          visibility: loaded ? 'visible' : 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={isJapaneseFlow ? couponOpenedWhite : couponOpened}
          width={canvasWidth}
          height={canvasHeight}
          alt="discount-coupon"
        />

        <S.Text>
          <S.Discount>{DISCOUNT}</S.Discount>
          <S.Description>
            <Trans i18nKey="onboarding.scratch.offYourSubscription" />
          </S.Description>
        </S.Text>
      </S.Card>
    </S.Container>
  )
}
