import styled from 'styled-components'

import resultCollectionBg from 'assets/images/result-collection-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledResultSlider = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding-bottom: 24px;
    background: url(${resultCollectionBg}) no-repeat top center;
    background-size: cover;
    margin: 24px auto;
    border-radius: 24px;
    & > * {
      position: relative;
      z-index: 1;
    }

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.WHITE};
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.PRIMARY};
      opacity: 1;
    }
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 0px;
  `,
  TopLabel: styled.p`
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;

    & strong {
      color: ${Color.PRIMARY};
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
    }
  `,
  Image: styled.img`
    width: 253px;
    height: 514px;
  `,
  Subtitle: styled.span`
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  `,
}
