import React from 'react'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { StyledPayPalButton as S } from './PayPalButton.styles'

// todo: delete when rollup primer 100%
export const PayPalButton: React.FC = () => {
  const { isBellyFlow } = useCohortInfo()

  return (
    <S.ButtonsContainer $isBellyFlow={isBellyFlow}>
      <PrimerPaymentForm showPayPalButton showCardForm={false} />
    </S.ButtonsContainer>
  )
}
