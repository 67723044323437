import styled from 'styled-components'

import { Timer } from 'modules/purchase/components/Timer'

import bgFigure from 'assets/images/bg-timer-figure.svg'

export const StyledTimerWithPersonalization = {
  GradientContainer: styled.div`
    background: linear-gradient(96deg, #fffae1 1.79%, #ffd5b7 96.91%);
    margin: 16px 0;
    border-radius: 20px;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: url(${bgFigure}) no-repeat right;
    background-size: contain;
    padding: 16px 24px;
  `,
  Title: styled.h2`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
  `,
  Subtitle: styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  Text: styled.span`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-right: 4px;
  `,
  Timer: styled(Timer)`
    color: #e85e47;
  `,
}
