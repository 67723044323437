export const QUESTION = 'How much sleep do you usually get?'

export const OPTION_VALUES = [
  {
    title: 'onboarding.sleep.lessThanFive',
    value: 'lessThan5',
  },
  {
    title: 'onboarding.sleep.fiveSix',
    value: '5-6',
  },
  {
    title: 'onboarding.sleep.sevenEight',
    value: '7-8',
  },
  {
    title: 'onboarding.sleep.moreThanEight',
    value: 'moreThan8',
  },
]
