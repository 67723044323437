import iconFifth from 'assets/images/free-offer-review-icon-fifth.png'
import iconFirst from 'assets/images/free-offer-review-icon-first-belly.png'
import iconFourth from 'assets/images/free-offer-review-icon-fourth.png'
import iconSecond from 'assets/images/free-offer-review-icon-second.png'
import iconSeventh from 'assets/images/free-offer-review-icon-seventh-belly.png'
import iconSixth from 'assets/images/free-offer-review-icon-sixth-belly.png'
import iconThird from 'assets/images/free-offer-review-icon-third-belly.png'
import imgFifth from 'assets/images/free-offer-review-photo-fifth-belly.png'
import imgFirst from 'assets/images/free-offer-review-photo-first-belly.png'
import imgFourth from 'assets/images/free-offer-review-photo-fourth-belly.png'
import imgSecond from 'assets/images/free-offer-review-photo-second-belly.png'
import imgSeventh from 'assets/images/free-offer-review-photo-seventh-belly.png'
import imgSixth from 'assets/images/free-offer-review-photo-sixth-belly.png'
import imgThird from 'assets/images/free-offer-review-photo-third-belly.png'
import resultImgFirst from 'assets/images/success-stories-first-bg-belly.png'
import resultIconFirst from 'assets/images/success-stories-first-icon.png'
import resultImgSecond from 'assets/images/success-stories-second-bg-belly.png'
import resultIconSecond from 'assets/images/success-stories-second-icon.png'
import resultImgThird from 'assets/images/success-stories-third-bg-belly.png'
import resultIconThird from 'assets/images/success-stories-third-icon-belly.png'

export const CUSTOMER_REVIEWS_BELLY = [
  {
    icon: iconFirst,
    name: 'purchaseFreeOffer.reviews.first.name',
    date: 'purchaseFreeOffer.reviews.first.date',
    image: imgFirst,
    likesCount: 132,
    commentsCount: 36,
  },
  {
    icon: iconSecond,
    name: 'purchaseFreeOffer.reviews.second.name',
    date: 'purchaseFreeOffer.reviews.second.date',
    image: imgSecond,
    likesCount: 74,
    commentsCount: 17,
  },
  {
    icon: iconThird,
    name: 'purchaseFreeOffer.reviews.third.name',
    date: 'purchaseFreeOffer.reviews.third.date',
    image: imgThird,
    likesCount: 47,
    commentsCount: 21,
  },
  {
    icon: iconFourth,
    name: 'purchaseFreeOffer.reviews.fourth.name',
    date: 'purchaseFreeOffer.reviews.fourth.date',
    image: imgFourth,
    likesCount: 236,
    commentsCount: 54,
  },
  {
    icon: iconFifth,
    name: 'purchaseFreeOffer.reviews.fifth.name',
    date: 'purchaseFreeOffer.reviews.fifth.date',
    image: imgFifth,
    likesCount: 18,
    commentsCount: 8,
  },
  {
    icon: iconSixth,
    name: 'purchaseFreeOffer.reviews.sixth.name',
    date: 'purchaseFreeOffer.reviews.sixth.date',
    image: imgSixth,
    likesCount: 108,
    commentsCount: 27,
  },
  {
    icon: iconSeventh,
    name: 'purchaseFreeOffer.reviews.seventh.name',
    date: 'purchaseFreeOffer.reviews.seventh.date',
    image: imgSeventh,
    likesCount: 9,
    commentsCount: 3,
  },
]

export const RESULTS_BELLY = [
  {
    icon: resultIconFirst,
    name: 'purchaseFreeOffer.successStories.first.name',
    date: 'purchaseFreeOffer.successStories.first.date',
    image: resultImgFirst,
    text: 'purchaseFreeOfferBelly.successStories.first.text',
    likesCount: 148,
    commentsCount: 55,
  },
  {
    icon: resultIconSecond,
    name: 'purchaseFreeOffer.successStories.second.name',
    date: 'purchaseFreeOffer.successStories.second.date',
    text: 'purchaseFreeOfferBelly.successStories.second.text',
    image: resultImgSecond,
    likesCount: 129,
    commentsCount: 26,
  },
  {
    icon: resultIconThird,
    name: 'purchaseFreeOffer.successStories.third.name',
    date: 'purchaseFreeOffer.successStories.third.date',
    text: 'purchaseFreeOfferBelly.successStories.third.text',
    image: resultImgThird,
    likesCount: 96,
    commentsCount: 19,
  },
]
