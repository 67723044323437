export const DEFAULT_PLAN_BENEFITS = {
  first: 'purchaseWithoutIntro.whatYouGet.list.mostPopularWeight',
  second: 'purchaseWithoutIntro.whatYouGet.list.effectiveWorkouts',
  third: 'purchase1.specialPrograms',
  fourth: 'purchase3.planHighlights.noEquipment',
  fifth: 'purchaseWithoutIntro.whatYouGet.list.mostPopularDance',
}

export const SOMATIC_PLAN_BENEFITS = {
  first: 'purchaseWithoutIntro.whatYouGet.list.personalPlan',
  second: 'purchaseWithoutIntro.whatYouGet.list.effectiveWorkoutsSomatic',
  third: 'purchaseWithoutIntro.whatYouGet.list.diverseWorkouts',
  fourth: 'purchaseWithoutIntro.whatYouGet.list.practiceAnywhere',
  fifth: 'purchaseWithoutIntro.whatYouGet.list.lessStress',
}
