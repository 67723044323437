import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDancingVsExercises = {
  AnimationContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  `,
  AnimationTitle: styled.h2`
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  `,
  Animation: styled.div<{
    $isAgeFlow: boolean
  }>`
    min-height: 254px;
    min-width: 320px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        border-radius: 24px;
        background: ${Color.WHITE};
        padding: 10px 12px;
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
        margin-top: 30px;
      `}
  `,
  ButtonContainer: styled.div`
    padding-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
