import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWorkSchedule as S } from './WorkSchedule.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const WorkSchedule: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { optionData } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container>
      <PageTitle marginBottom={32}>
        {t('onboarding.workSchedule.question')}
      </PageTitle>

      {(optionData?.length ? optionData : OPTION_VALUES).map(
        ({ value, text, imageUrl }) => (
          <Option {...optionProps} disabled={isAnswersDisabled} value={value}>
            <S.AnswerButton iconSrc={imageUrl}>
              <Trans>{text}</Trans>
            </S.AnswerButton>
          </Option>
        ),
      )}
    </Container>
  )
}
