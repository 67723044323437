import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import icon from 'assets/images/sprite/chevron-up.svg'

import { StyledFAQList as S } from './FAQListNew.styles'

type TProps = {
  className?: string
}

export const FAQListNew: React.FC<TProps> = ({ className = '' }) => {
  const { isTTClearFlow } = useCohortInfo()
  const [openedQuestions, setOpenedQuestions] = useState({
    first: false,
    second: false,
    third: false,
  })

  return (
    <S.Wrapper className={className}>
      <S.Title>
        <Trans i18nKey="purchase1.faqList.title" />
      </S.Title>
      <S.ListBody>
        <S.ListItem>
          <S.ListItemTitle
            onClick={() =>
              setOpenedQuestions((prev) => ({
                ...prev,
                first: !prev.first,
              }))
            }
          >
            <Trans i18nKey="purchase1.faqList.questionFirst" />
            <S.SvgIcon svg={icon} $isOpened={openedQuestions.first} />
          </S.ListItemTitle>
          {openedQuestions.first && (
            <S.ListItemText>
              <Trans
                i18nKey={
                  isTTClearFlow
                    ? 'purchase1.faqList.answerFirstClear'
                    : 'purchase1.faqList.answerFirst'
                }
              />
            </S.ListItemText>
          )}
        </S.ListItem>
        <S.ListItem>
          <S.ListItemTitle
            onClick={() =>
              setOpenedQuestions((prev) => ({
                ...prev,
                second: !prev.second,
              }))
            }
          >
            <Trans i18nKey="purchase1.faqList.questionSecond" />
            <S.SvgIcon svg={icon} $isOpened={openedQuestions.second} />
          </S.ListItemTitle>
          {openedQuestions.second && (
            <S.ListItemText>
              <Trans
                i18nKey={
                  isTTClearFlow
                    ? 'purchase1.faqList.answerSecondClear'
                    : 'purchase1.faqList.answerSecond'
                }
              />
            </S.ListItemText>
          )}
        </S.ListItem>
        <S.ListItem>
          <S.ListItemTitle
            onClick={() =>
              setOpenedQuestions((prev) => ({
                ...prev,
                third: !prev.third,
              }))
            }
          >
            <Trans i18nKey="purchase1.faqList.questionThird" />
            <S.SvgIcon svg={icon} $isOpened={openedQuestions.third} />
          </S.ListItemTitle>
          {openedQuestions.third && (
            <S.ListItemText>
              <Trans i18nKey="purchase1.faqList.answerThird" />
            </S.ListItemText>
          )}
        </S.ListItem>
      </S.ListBody>
    </S.Wrapper>
  )
}
