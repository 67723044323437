import styled from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

export const StyledChangeForBetter = {
  Image: styled.img`
    width: 100%;
    min-height: 253px;
    margin-top: 32px;
  `,
  Description: styled.div`
    font-size: 17px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY_80};
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    background: linear-gradient(
        192deg,
        rgba(36, 160, 255, 0.4) 0.83%,
        rgba(165, 198, 255, 0) 30.16%
      ),
      linear-gradient(
        20deg,
        rgba(165, 198, 255, 0) 51.34%,
        rgba(124, 192, 255, 0.4) 102.2%
      ),
      linear-gradient(
        165deg,
        rgba(129, 128, 246, 0.6) 3.19%,
        rgba(218, 192, 254, 0.6) 21.21%,
        rgba(227, 237, 254, 0.6) 59.11%
      ),
      ${Color.WHITE};
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background: #eef4fe;
  `,
}
