import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CURRENCY_SYMBOLS, PremiumProduct } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import button from 'assets/images/slider-arrow.svg'

import { StyledSubscriptionTwoPlansItem as S } from './SubscriptionTwoPlansItem.styles'

type TProps = {
  subscription: ISubscription
  onNavigationPrev?: () => void
  onNavigationNext?: () => void
  children: ReactNode
}

export const SubscriptionTwoPlansItem: FC<TProps> = ({
  subscription: {
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialCurrentPrice },
    currency,
  },
  onNavigationPrev,
  onNavigationNext,
  children,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)

  const hasVatInfo = useVatInfo()

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const isPremium = useMemo(() => product === PremiumProduct.PREMIUM, [product])

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  return (
    <>
      <S.NavigationContainer $isPremium={isPremium}>
        <S.PrevButton onClick={onNavigationPrev} $isBasic={!isPremium}>
          <img src={button} alt="Previous slide" />
        </S.PrevButton>
        <S.NextButton onClick={onNavigationNext} $isPremium={isPremium}>
          <img src={button} alt="Next slide" />
        </S.NextButton>
      </S.NavigationContainer>

      <S.Card>
        <S.Badge $isPremium={isPremium}>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.badge"
            values={{
              context: isPremium
                ? PremiumProduct.PREMIUM
                : PremiumProduct.BASIC,
            }}
          />
        </S.Badge>

        <S.PlanTitle>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.planTitle"
            values={{
              context: isPremium
                ? PremiumProduct.PREMIUM
                : PremiumProduct.BASIC,
            }}
          />
        </S.PlanTitle>

        <S.PlanDescription $isPremium={isPremium}>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.planDescription"
            values={{
              context: isPremium
                ? PremiumProduct.PREMIUM
                : PremiumProduct.BASIC,
            }}
          />
        </S.PlanDescription>

        <S.OldPrice>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.price"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: getCurrentPrice(fullPrice),
            }}
          />
        </S.OldPrice>

        <S.NewPrice>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.price"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: getCurrentPrice(currentPriceWithFractionalPart),
            }}
          />
        </S.NewPrice>

        <S.WhatInSubscription $isPremium={isPremium}>
          <S.Item $isBasic={!isPremium}>
            <Trans i18nKey="purchaseTwoPlans.subscriptions.whatInSubscription.extraChallenges" />
          </S.Item>
          <S.Item $isBasic={!isPremium}>
            <Trans i18nKey="purchaseTwoPlans.subscriptions.whatInSubscription.additionalPrograms" />
          </S.Item>
          <S.Item>
            <Trans i18nKey="purchaseTwoPlans.subscriptions.whatInSubscription.specialProgram" />
          </S.Item>
          <S.Item>
            <Trans i18nKey="purchaseTwoPlans.subscriptions.whatInSubscription.energyBoost" />
          </S.Item>
          <S.Item>
            <Trans i18nKey="purchaseTwoPlans.subscriptions.whatInSubscription.result" />
          </S.Item>
        </S.WhatInSubscription>

        {isPremium && (
          <S.Bonus>
            <S.BonusTitle>
              {t('purchaseTwoPlans.subscriptions.premiumBonus')}
            </S.BonusTitle>
            <S.BonusDescription>
              <Trans i18nKey="purchaseTwoPlans.subscriptions.bonusDescription" />
            </S.BonusDescription>
          </S.Bonus>
        )}

        <S.ButtonContainer>{children}</S.ButtonContainer>
      </S.Card>
    </>
  )
}
