import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { createProductId } from 'helpers/createProductId'

import {
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { MAIN_GOALS, ScreenId, ScreenName } from 'root-constants'

export const usePurchaseUpgradeAnalytics = (
  screenName: ScreenName,
  screenId: ScreenId,
): void => {
  const price = useSelector(selectSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const accountName = useSelector(selectStripeAccountName)
  const accountId = useSelector(selectStripeAccountId)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const goal = useMemo(
    () => (isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitGoal],
  )

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price,
      }),
    [periodName, periodQuantity, price],
  )

  const hasSubscription = useMemo(() => price && periodName && periodQuantity, [
    periodName,
    periodQuantity,
    price,
  ])

  useEffect(() => {
    if (!hasSubscription) return

    screenName === ScreenName.ADDITIONAL_OFFER
      ? eventLogger.logUpgradePageShown(
          screenName,
          screenId,
          goal,
          accountId,
          accountName,
        )
      : eventLogger.logSalePageShown({
          productIds: [productId],
          screenName,
          screenId,
          isAmplitudeEvent: true,
        })
  }, [
    accountId,
    accountName,
    goal,
    hasSubscription,
    productId,
    screenId,
    screenName,
  ])
}
