import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import bgImage from 'assets/images/age-flow-bg.png'

export const StyledAgeFlowContainer = css`
  &::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const StyledGender = {
  Container: styled(Container)<{ $isAgeFlow: boolean }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
