import image2 from 'assets/images/foto-result1.png'
import image1 from 'assets/images/foto-result2.png'
import image3 from 'assets/images/foto-result3.png'

export const SLIDES_FEMALE = [
  {
    id: '1',
    imageUrl: image1,
    author: 'purchaseUpgrade.reviews.author1',
    review: 'purchaseUpgrade.reviews.review1',
  },
  {
    id: '2',
    imageUrl: image2,
    author: 'purchaseUpgrade.reviews.author2',
    review: 'purchaseUpgrade.reviews.review2',
  },
  {
    id: '3',
    imageUrl: image3,
    author: 'purchaseUpgrade.reviews.author3',
    review: 'purchaseUpgrade.reviews.review3',
  },
]
