import styled from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import largePriceBadgeUrl from 'assets/images/price-badge-large.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedLargePriceBadgeUrl from 'assets/images/selected-price-badge-large.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $isMexican?: boolean
}

type TDutchProps = {
  $isDutch?: boolean
}

export const StyledSubscriptionItem = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 15px 0;
    border-radius: 16px;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -15px;
    left: 55px;
    transform: translateY(-50%);
    padding: 4px 12px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
  `,
  PlanPeriod: styled.span<TDutchProps>`
    padding-left: 45px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(19, 29, 48, 0.5);
    max-width: 155px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: ${({ $isDutch }) => ($isDutch ? '14px' : '16px')};
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PreviousPrice: styled.span<TDutchProps>`
    margin: 18px 3px 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    color: rgba(19, 29, 48, 0.5);

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      margin: ${({ $isDutch }) => ($isDutch ? '18px 3px 0 0' : '18px 8px 0 0')};
    }
  `,
  CurrentPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 76px;
    min-height: 40px;
    margin-right: 10px;
    color: rgba(19, 29, 48, 0.5);
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ $isMexican }) =>
      $isMexican &&
      `
        min-width: 92px;
        min-height: 49px;
        background-image: url(${largePriceBadgeUrl});
        
        & > span:first-child {
        margin: 9px 5px 0 0; 
        }
    `};

    ${({ $isSelected, $isMexican }) =>
      $isSelected &&
      ($isMexican
        ? `
        background-image: url(${selectedLargePriceBadgeUrl});
        color: #ffffff;
    `
        : `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
    `)};
  `,
  PriceValue: styled.span`
    margin: 5px 5px 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  `,
  Period: styled.span`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
  `,
}
