import React from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { PagesSource } from 'root-constants'

import { StyledDisclaimer as S } from './Disclaimer.styles'

type TProps = {
  padding?: number
}

export const Disclaimer: React.FC<TProps> = ({ padding = 0 }) => {
  const { t } = useTranslation()

  return (
    <S.Container $padding={padding}>
      {t`login.disclaimer`} <PrivacyPolicyLink source={PagesSource.LOGIN} />{' '}
      {t`result.and`}{' '}
      <TermsOfUseLink
        text="commonComponents.termsOfUse"
        source={PagesSource.LOGIN}
      />
    </S.Container>
  )
}
