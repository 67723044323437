import styled from 'styled-components'

import { Container } from 'components/Container'

import branchLeft from 'assets/images/branch-left-v2.svg'
import branchRight from 'assets/images/branch-right-v2.svg'
import tenMillionWomen from 'assets/images/ten-million-women.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTenMillionPeople = {
  Root: styled.div`
    position: relative;
    background-image: url(${tenMillionWomen});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100% - 50px);

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-size: contain;
    }
  `,
  Title: styled.h2`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 4px rgba(24, 11, 79, 0.06);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 360px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 34px;
      height: 57px;
      background: url(${branchRight});
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 34px;
      height: 57px;
      background: url(${branchLeft});
    }
  `,
  Subtitle: styled.h3`
    color: #2f3a53;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      color: ${Color.PRIMARY};
      font-size: 20px;
      font-style: italic;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 21px 16px 16px;
  `,
}
