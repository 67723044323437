import { css } from 'styled-components'

import { PaymentMethod } from 'modules/purchase/constants'

import amazonIcon from 'assets/images/amazon-pay.png'
import boletoIcon from 'assets/images/boleto-logo.png'
import cashappIcon from 'assets/images/cashapp-pay.png'
import revolutIcon from 'assets/images/revolut-pay.png'

import { Color } from 'root-constants'

export const PAYMENT_ELEMENT_STYLE = {
  [PaymentMethod.AMAZON_PAY]: css`
    background: #f5d48c;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 113px;
      height: 30px;
      background: url(${amazonIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: #edc875;
    }

    &:active,
    &:focus-visible {
      background: #ffe6b1;
    }
  `,
  [PaymentMethod.REVOLUT_PAY]: css`
    background: #191c1f;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 98px;
      height: 18px;
      background: url(${revolutIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: ${Color.BLACK};
    }

    &:active,
    &:focus-visible {
      background: #45484c;
    }
  `,
  [PaymentMethod.CASHAPP]: css`
    background: #191c1f;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 110px;
      height: 24px;
      background: url(${cashappIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: ${Color.BLACK};
    }

    &:active,
    &:focus-visible {
      background: #45484c;
    }
  `,
  [PaymentMethod.BOLETO]: css`
    background: #191c1f;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 72px;
      height: 46px;
      background: url(${boletoIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: ${Color.BLACK};
    }

    &:active,
    &:focus-visible {
      background: #45484c;
    }

    &:disabled {
      &:before {
        opacity: 0.5;
      }
    }
  `,
  default: css`
    background: #191c1f;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 110px;
      height: 24px;
      background: url(${cashappIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: ${Color.BLACK};
    }

    &:active,
    &:focus-visible {
      background: #45484c;
    }
  `,
}
