import reviewJones from 'assets/images/helped-millions-people-jones.png'
import reviewMike from 'assets/images/helped-millions-people-mike.png'
import reviewSamantha from 'assets/images/helped-millions-people-samantha.png'

export const REVIEW_SLIDES = [
  {
    name: 'purchaseFreeOffer.reviews.second.name',
    review: 'purchaseLiveChat.usersBeforeAfter',
    weight: 'purchaseLiveChat.weightSamantha',
    img: reviewSamantha,
    isBigReview: true,
  },
  {
    name: 'purchaseWithoutIntro.photoResult.author2.author',
    review: 'purchaseLiveChat.jonesReview',
    weight: 'purchaseLiveChat.weightPatricia',
    img: reviewJones,
  },
  {
    name: 'purchaseWithoutIntro.photoResult.author5.author',
    review: 'purchaseLiveChat.mikeReview',
    weight: 'onboarding.helpedMillionsPeople.weightMike',
    img: reviewMike,
  },
]
