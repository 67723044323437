import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react'
import lottie from 'lottie-web/build/player/lottie_light'

import { ButtonJa } from 'components/ButtonJa'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { ScratchCard } from 'components/ScratchCard'
import { SvgImage } from 'components/SvgImage'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import confetti from 'assets/animation/confetti.json'
import partyingIcon from 'assets/images/sprite/partying-face.svg'

import { goTo, replaceHistory } from 'browser-history'
import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledScratch as S } from './Scratch.styles'
import { QUESTION } from './constants'

export const Scratch: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const growthbook = useGrowthBook()
  const { isRefCohort } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const [isScratchingStarted, setIsScratchingStarted] = useState<boolean>(false)
  const [isScratchingFinished, setIsScratchingFinished] = useState<boolean>(
    false,
  )
  const confettiAnimationRef = useRef<HTMLDivElement>(null)

  const { currentSubscriptionPageId } = useGetPageInfo()

  const hasScratchTestDbRef = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1257_DB_REF,
  )
  const hasScratchTestDbBellyRef = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1257_DB_BELLY_REF,
  )

  useDisableOverscroll()

  useLayoutEffect(() => {
    if (isJapaneseFlow || hasScratchTestDbBellyRef || hasScratchTestDbRef) {
      eventLogger.logScratchDiscountPageShow()
      return
    }

    if (growthbook?.ready && isRefCohort) {
      replaceHistory({
        pathname: currentSubscriptionPageId,
        search,
      })
    }
  }, [
    isRefCohort,
    currentSubscriptionPageId,
    growthbook,
    hasScratchTestDbBellyRef,
    hasScratchTestDbRef,
    search,
    isJapaneseFlow,
  ])

  useEffect(() => {
    if (confettiAnimationRef.current && isScratchingFinished) {
      lottie.loadAnimation({
        container: confettiAnimationRef.current,
        animationData: confetti,
        name: 'confetti',
        autoplay: true,
        loop: false,
      })
    }

    return () => lottie.destroy('confetti')
  }, [isScratchingFinished])

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers: '',
    })

    goTo({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [currentSubscriptionPageId, search])

  const handleStart = () => {
    !isScratchingStarted && eventLogger.logScratchingStarted()
    setIsScratchingStarted(true)
  }

  const handleFinish = () => {
    eventLogger.logDiscountPopupShow()
    setIsScratchingFinished(true)
  }

  return (
    <S.Wrapper
      $hasMarginTop={
        hasScratchTestDbRef || hasScratchTestDbBellyRef || isJapaneseFlow
      }
      $isJapanese={isJapaneseFlow}
    >
      <Container>
        <PageTitle marginBottom={16}>
          {t('onboarding.scratch.question')}
        </PageTitle>
        <S.Subtitle>
          <Trans i18nKey="onboarding.scratch.subtitle" />
        </S.Subtitle>

        <ScratchCard onComplete={handleFinish} onStart={handleStart} />
        {!isScratchingFinished && (
          <S.Label>
            {t(
              isScratchingStarted
                ? 'onboarding.scratch.keepScratching'
                : 'onboarding.scratch.getDiscount',
            )}
          </S.Label>
        )}
      </Container>

      <S.Modal $isShown={isScratchingFinished}>
        <S.ModalContent>
          <S.Animation ref={confettiAnimationRef} />
          <SvgImage svg={partyingIcon} width={48} height={48} />
          <S.ModalTitle>{t('onboarding.scratch.modal.title')}</S.ModalTitle>
          <S.ModalSubtitle>
            {t('onboarding.scratch.modal.subtitle')}
          </S.ModalSubtitle>
          <strong>{t('onboarding.scratch.modal.discount')}</strong>
          <S.Disclaimer>{t('onboarding.scratch.modal.willApply')}</S.Disclaimer>
          {isJapaneseFlow ? (
            <S.ButtonContainer>
              <ButtonJa onClick={handleClick}>
                {t('onboarding.scratch.getReward')}
              </ButtonJa>
            </S.ButtonContainer>
          ) : (
            <NavigationButton type="button" onClick={handleClick}>
              {t('onboarding.scratch.getReward')}
            </NavigationButton>
          )}
        </S.ModalContent>
      </S.Modal>
    </S.Wrapper>
  )
}
