import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { LifestyleStyles as S } from './Lifestyle.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Lifestyle: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={8}>{t('onboarding.lifestyle.title')}</PageTitle>
      <S.PageDescription marginBottom={24}>
        {t('onboarding.lifestyle.subtitle')}
      </S.PageDescription>

      {OPTION_VALUES.map(({ description, title, value }) => (
        <Answer
          {...optionProps}
          value={value}
          theme={answerTheme.DANCEBIT}
          margin="0 0 16px"
          height="80px"
          padding="16px"
          disabled={isAnswersDisabled}
          key={value}
        >
          <S.ComplexAnswer>
            {t(title)}
            <S.AnswerDescription>{t(description)}</S.AnswerDescription>
          </S.ComplexAnswer>
        </Answer>
      ))}
    </Container>
  )
}
