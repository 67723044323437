import styled from 'styled-components'

import cardBg from 'assets/images/customer-review-bg.png'

export const StyledReviewsCarousel = {
  Wrapper: styled.div`
    padding-bottom: 16px;

    .swiper-pagination-bullets .swiper-pagination-bullet {
      background: #fadb8e;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: #ffc530;
    }
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  CardContainer: styled.div`
    padding-bottom: 30px;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 216px;
    padding: 32px 16px;
    background: url(${cardBg}) center center no-repeat;
    border-radius: 25px;
  `,
  Author: styled.p`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Rating: styled.div`
    margin-bottom: 8px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
}
