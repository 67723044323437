import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react'

import { Button } from 'components/Button'

import { StyledNavigationButton as S } from './NavigationButton.styles'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  isAgeFlow?: boolean
  className?: string
  onClick?: () => void
}

export const NavigationButton: React.FC<IButtonProps> = ({
  disabled,
  isAgeFlow = false,
  className = '',
  onClick,
  children,
  ...props
}) => {
  const stickyButtonRef = useRef<HTMLDivElement>(null)

  const updateStickyElementState = () => {
    const element = stickyButtonRef.current

    if (element && element.parentElement) {
      const isStuck =
        window.innerHeight - element.clientHeight - element.offsetTop <= 0

      element.toggleAttribute('stuck', isStuck)
    }
  }

  useEffect(() => {
    updateStickyElementState()

    window.addEventListener('resize', updateStickyElementState)
    window.addEventListener('touchend', updateStickyElementState)

    return () => {
      window.removeEventListener('resize', updateStickyElementState)
      window.removeEventListener('touchend', updateStickyElementState)
    }
  }, [])

  return (
    <S.Root $isAgeFlow={isAgeFlow} className={className} ref={stickyButtonRef}>
      <S.NavigationButtonContainer>
        <Button disabled={disabled} onClick={onClick} {...props}>
          {children}
        </Button>
      </S.NavigationButtonContainer>
    </S.Root>
  )
}
