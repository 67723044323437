import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledPaymentMethodToggle = {
  PaymentMethodsTitle: styled.h2`
    color: ${Color.DARK};
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding-bottom: 8px;
    text-align: center;
  `,
  PaymentMethodsWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    margin: 16px 0 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #e9eaed;
  `,
  PaymentMethod: styled.div<{ $isSelected: boolean }>`
    width: 100px;
    min-width: 100px;
    height: 78px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid
      ${({ $isSelected }) => ($isSelected ? Color.PRIMARY : '#c4c4c4')};

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        p {
          color: ${Color.DARK};
        }
      `}
    &:only-child,
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ div {
      flex: 1;
    }
  `,
  PaymentMethodText: styled.p`
    color: #9498a5;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
  `,
  AlternativePaymentMethodImage: styled.img`
    width: 72px;
    height: 46px;
  `,
}
