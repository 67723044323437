import styled from 'styled-components'

import checkIconSvg from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledWhatYouGet = {
  Wrapper: styled.div`
    display: flex;
    width: 343px;
    padding: 16px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(166, 179, 194, 0.3);
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  Card: styled.div`
    position: relative;
    display: flex;
    width: 295px;
    padding: 16px 24px 24px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 1px solid #a5c7f8;
    background: radial-gradient(
      51.49% 60.94% at 54.07% 29.35%,
      rgba(199, 199, 255, 0.8) 0.91%,
      #f1f2fb 90.74%
    );
  `,
  BonusBadge: styled.div`
    position: absolute;
    left: 0;
    top: 25px;
    display: flex;
    width: 65px;
    padding: 4px 8px;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    border-radius: 0px 20px 20px 0px;
    background: linear-gradient(270deg, #59d7fc 2.08%, #0398f5 95.88%);
  `,
  ImageContainer: styled.div`
    max-width: 110px;
    max-height: 145px;
  `,
  CardTitle: styled.h3`
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
  `,
  CardDescription: styled.p`
    color: #686c75;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  List: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  `,
  ListItem: styled.div`
    position: relative;
    padding-left: 26px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
      color: ${Color.WHITE};
      background-image: url(${checkIconSvg});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  `,
}
