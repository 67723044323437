import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { IMAGE_PATH } from 'modules/purchase/components/BodyAgeV2/constants'

import drNockSmeetonIcon from 'assets/images/purchaseNoOnboarding/dr-nick-smeeton-min.png'
import healthAndHumanDepartmentIcon from 'assets/images/purchaseNoOnboarding/health-and-human-service-min.png'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledBodyAgeV2 as S } from './BodyAgeV2.styles'

type TProps = {
  className?: string
  title?: string
}

export const BodyAgeV2: React.FC<TProps> = ({ className, title }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const diagramPicture = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`,
    [language],
  )

  return (
    <S.Root className={className}>
      <S.Title>{t(title || 'purchaseUpgrade.bodyAge.title')}</S.Title>
      <S.Picture
        src={diagramPicture}
        alt="Stay young as long and possible diagram"
      />
      <ul>
        <S.Card>
          <S.Icon
            src={healthAndHumanDepartmentIcon}
            alt="United States Department of Health and Human Services"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseUpgrade.bodyAge.text1"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
        <S.Card>
          <S.Icon
            src={drNockSmeetonIcon}
            alt="Dr Nick Smeeton from Stanford University"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseUpgrade.bodyAge.text2"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
      </ul>
    </S.Root>
  )
}
