import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { ErrorNotification } from 'components/ErrorNotification'
import { Footer } from 'components/Footer'
import { Modal } from 'components/Modal'

import { resetErrorAction } from 'root-redux/actions/common'
import { sendUserEmailAction } from 'root-redux/actions/user'
import { selectError, selectUserName } from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import lockIconSvg from 'assets/images/sprite/lock.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { EMAIL_QUERY_PARAM } from 'root-constants'

import { StyledEmailNoOnboarding as S } from './EmailNoOnboarding.styles'

export const EmailNoOnboarding: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const userStatus = useSelector(selectUserStatus)
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)
  const name = useSelector(selectUserName)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, setEmail] = useEmailInputField()

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const errorText = useMemo(
    () => (!email.isValid ? email.validationText : ''),
    [email.isValid, email.validationText],
  )

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo({
        pathname: PageId.CHECKOUT_NO_ONBOARDING,
        search,
      })
    }
  }, [userStatus, search])

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  useEffect(() => {
    setEmail((prevState) => ({
      ...prevState,
      value: sessionStorage.getItem(EMAIL_QUERY_PARAM) || '',
    }))
  }, [setEmail])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      if (!email.isValid) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
        }),
      )

      googleAnalyticsLogger.logEnterEmailPageCompleted()
      eventLogger.logEmailPageCompleted({
        email: isPersonalDataAllowed ? email.value : '',
      })
      window.axon &&
        window.axon('track', 'sign_up', {
          user_id: isPersonalDataAllowed ? uuid : '',
          method: 'email',
        })
      window.fbq &&
        window.fbq(
          'track',
          'Lead',
          {},
          { eventID: isPersonalDataAllowed ? uuid : '' },
        )
      window.rdt &&
        window.rdt('track', 'Lead', {
          email: isPersonalDataAllowed ? email.value : '',
          externalId: isPersonalDataAllowed ? uuid : '',
          ...(!isPersonalDataAllowed && {
            em: '',
            uuid: '',
          }),
        })
      window.pintrk && window.pintrk('track', 'lead')
      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq &&
        window.ttq.track('CompleteRegistration', {
          event_id: isPersonalDataAllowed ? uuid : '',
        })
      window.snaptr &&
        window.snaptr('track', 'SIGN_UP', {
          user_email: isPersonalDataAllowed ? email.value : '',
          firstname: isPersonalDataAllowed ? name : '',
        })
    },
    [
      name,
      email.isValid,
      email.value,
      dispatch,
      handleErrorModal,
      isPersonalDataAllowed,
      uuid,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  return (
    <div>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="emailNoOnboarding.title" components={[<strong />]} />
        </S.Title>
        <form onSubmit={handleSubmit}>
          <S.Input
            value={email.value}
            isValid={isEmailValid}
            labelName={t`login.emailPlaceholder`}
            hasValidationIcon
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <S.Disclaimer>
            <S.LockIcon svg={lockIconSvg} width={20} />
            <Trans
              i18nKey="onboarding.email.disclaimer"
              components={[<br />]}
            />
          </S.Disclaimer>

          <S.Button type="submit" disabled={!email.value || !isEmailValid}>
            <ErrorNotification errorText={errorText} />
            {t`actions.getMyPlan`}
          </S.Button>
        </form>
      </S.Wrapper>
      <Footer />
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </div>
  )
}
