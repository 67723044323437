import armsFemaleAge from 'assets/images/arms-female-age.png'
import armsFemale from 'assets/images/arms-female.png'
import armsMaleAge from 'assets/images/arms-male-age.png'
import armsMale from 'assets/images/arms-male.png'
import bellyFemaleAge from 'assets/images/belly-female-age.png'
import bellyFemale from 'assets/images/belly-female.png'
import bellyMaleAge from 'assets/images/belly-male-age.png'
import bellyMale from 'assets/images/belly-male.png'
import buttocksFemaleAge from 'assets/images/buttocks-female-age.png'
import buttocksFemale from 'assets/images/buttocks-female.png'
import buttocksMaleAge from 'assets/images/buttocks-male-age.png'
import buttocksMale from 'assets/images/buttocks-male.png'
import chestFemaleAge from 'assets/images/chest-female-age.png'
import chestFemale from 'assets/images/chest-female.png'
import chestMaleAge from 'assets/images/chest-male-age.png'
import chestMale from 'assets/images/chest-male.png'
import hipsFemaleAge from 'assets/images/hips-female-age.png'
import hipsFemale from 'assets/images/hips-female.png'
import hipsMaleAge from 'assets/images/hips-male-age.png'
import hipsMale from 'assets/images/hips-male.png'
import legsFemaleAge from 'assets/images/legs-female-age.png'
import legsFemale from 'assets/images/legs-female.png'
import legsMaleAge from 'assets/images/legs-male-age.png'
import legsMale from 'assets/images/legs-male.png'

import { ImproveAreasStepOption } from 'root-constants'

export const IMPROVE_AREAS_CARD_MALE = {
  [ImproveAreasStepOption.BELLY]: {
    image: bellyMale,
    title: 'onboarding.improveAreas.belly',
  },
  [ImproveAreasStepOption.ARMS]: {
    image: armsMale,
    title: 'onboarding.improveAreas.arms',
  },
  [ImproveAreasStepOption.CHEST]: {
    image: chestMale,
    title: 'onboarding.improveAreas.chest',
  },
  [ImproveAreasStepOption.HIPS]: {
    image: hipsMale,
    title: 'onboarding.improveAreas.hips',
  },
  [ImproveAreasStepOption.LEGS]: {
    image: legsMale,
    title: 'onboarding.improveAreas.legs',
  },
  [ImproveAreasStepOption.BUTTOCKS]: {
    image: buttocksMale,
    title: 'onboarding.improveAreas.buttocks',
  },
}
export const IMPROVE_AREAS_CARD_MALE_AGE = {
  [ImproveAreasStepOption.BELLY]: {
    image: bellyMaleAge,
    title: 'onboarding.improveAreas.belly',
  },
  [ImproveAreasStepOption.ARMS]: {
    image: armsMaleAge,
    title: 'onboarding.improveAreas.arms',
  },
  [ImproveAreasStepOption.CHEST]: {
    image: chestMaleAge,
    title: 'onboarding.improveAreas.chest',
  },
  [ImproveAreasStepOption.HIPS]: {
    image: hipsMaleAge,
    title: 'onboarding.improveAreas.hips',
  },
  [ImproveAreasStepOption.LEGS]: {
    image: legsMaleAge,
    title: 'onboarding.improveAreas.legs',
  },
  [ImproveAreasStepOption.BUTTOCKS]: {
    image: buttocksMaleAge,
    title: 'onboarding.improveAreas.buttocks',
  },
}

export const IMPROVE_AREAS_CARD_FEMALE = {
  [ImproveAreasStepOption.ARMS]: {
    image: armsFemale,
    title: 'onboarding.improveAreas.arms',
  },
  [ImproveAreasStepOption.CHEST]: {
    image: chestFemale,
    title: 'onboarding.improveAreas.chest',
  },
  [ImproveAreasStepOption.BELLY]: {
    image: bellyFemale,
    title: 'onboarding.improveAreas.belly',
  },
  [ImproveAreasStepOption.BUTTOCKS]: {
    image: buttocksFemale,
    title: 'onboarding.improveAreas.buttocks',
  },
  [ImproveAreasStepOption.LEGS]: {
    image: legsFemale,
    title: 'onboarding.improveAreas.legs',
  },
  [ImproveAreasStepOption.HIPS]: {
    image: hipsFemale,
    title: 'onboarding.improveAreas.hips',
  },
}
export const IMPROVE_AREAS_CARD_FEMALE_AGE = {
  [ImproveAreasStepOption.ARMS]: {
    image: armsFemaleAge,
    title: 'onboarding.improveAreas.arms',
  },
  [ImproveAreasStepOption.CHEST]: {
    image: chestFemaleAge,
    title: 'onboarding.improveAreas.chest',
  },
  [ImproveAreasStepOption.BELLY]: {
    image: bellyFemaleAge,
    title: 'onboarding.improveAreas.belly',
  },
  [ImproveAreasStepOption.BUTTOCKS]: {
    image: buttocksFemaleAge,
    title: 'onboarding.improveAreas.buttocks',
  },
  [ImproveAreasStepOption.LEGS]: {
    image: legsFemaleAge,
    title: 'onboarding.improveAreas.legs',
  },
  [ImproveAreasStepOption.HIPS]: {
    image: hipsFemaleAge,
    title: 'onboarding.improveAreas.hips',
  },
}
