import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { UpgradeDisclaimer } from 'modules/purchase/components/UpgradeDisclaimer'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledFeaturedBlockUpgrade as S } from './FeaturedBlockUpgrade.styles'

export const FeaturedBlockUpgrade: React.FC = () => {
  const hasDisclaimer = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_708)

  return (
    <S.Container>
      <picture>
        <source srcSet={featuredImageWebp} type="image/webp" />
        <img src={featuredImagePng} alt="body" />
      </picture>
      {!hasDisclaimer && <UpgradeDisclaimer />}
    </S.Container>
  )
}
