import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { PLAN_HIGHLIGHTS } from 'modules/purchase/components/PlanHighlights/constants'

import { MAIN_GOALS } from 'root-constants'

import { StyledPlanHighlights as S } from './PlanHighlights.styles'

export const PlanHighlights: React.FC = () => {
  const { t } = useTranslation()
  const isStayFit = useSelector(selectIsStayFitFlow)
  const { benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Title>
        {benefitsBlockTitle || t`purchase3.planHighlights.title`}
      </S.Title>
      <ul>
        {(benefits.length ? benefits : PLAN_HIGHLIGHTS).map(({ id, text }) => (
          <S.ListItem key={id}>
            <Trans
              i18nKey={text}
              values={{
                context: isStayFit
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </S.ListItem>
        ))}
      </ul>
    </S.Wrapper>
  )
}
