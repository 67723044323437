export const QUESTION = 'What’s your height?'

export type TInitialState = {
  valueIn: string
  valueFt: string
  isValid: boolean
  isCountryWithImperialSystem: boolean
  unit: string
}

export const INITIAL_STATE: TInitialState = {
  valueIn: '',
  valueFt: '',
  isValid: false,
  isCountryWithImperialSystem: false,
  unit: '',
}

export const enum MinMaxHeight {
  MIN_HEIGHT_CM = 92,
  MAX_HEIGHT_CM = 241,
  MIN_HEIGHT_FT = 3,
  MAX_HEIGHT_FT = 7,
  MIN_HEIGHT_IN = 0,
  MAX_HEIGHT_IN = 11,
  MIN_IMP_HEIGHT = 3.0,
  MAX_IMP_HEIGHT = 7.11,
}
