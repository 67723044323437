import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import guideCover from 'assets/images/self-care-card.png'

import { StyledWhatYouGet as S } from './WhatYouGet.styles'

export const WhatYouGet: FC<{ isCardVisible: boolean }> = ({
  isCardVisible,
}) => (
  <S.Wrapper>
    <S.Title>
      <Trans i18nKey="purchaseUpgrade.bonusTitle" />
    </S.Title>

    {isCardVisible && (
      <S.Card>
        <S.BonusBadge>
          <Trans i18nKey="purchaseUpgrade.bonus" />
        </S.BonusBadge>
        <S.ImageContainer>
          <img src={guideCover} alt="Self-care guide" />
        </S.ImageContainer>
        <S.CardTitle>
          <Trans i18nKey="purchaseUpgrade.descriptionTitle" />
        </S.CardTitle>
        <S.CardDescription>
          <Trans i18nKey="purchaseUpgrade.description" />
        </S.CardDescription>
      </S.Card>
    )}

    <S.List>
      <S.ListItem>
        <Trans i18nKey="purchaseLiveChat.reachTarget" />
      </S.ListItem>
      <S.ListItem>
        <Trans i18nKey="purchaseUpgrade.supportSustain" />
      </S.ListItem>
      <S.ListItem>
        <Trans i18nKey="purchaseLiveChat.reduceStress" />
      </S.ListItem>
      <S.ListItem>
        <Trans i18nKey="purchaseLiveChat.personalHealth" />
      </S.ListItem>
      <S.ListItem>
        <Trans i18nKey="purchaseUpgrade.happier" />
      </S.ListItem>
    </S.List>
  </S.Wrapper>
)
