import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import tenMillionPeopleImage from 'assets/images/ten-million-people.png'

import { Gender } from 'root-constants'

import { StyledTenMillionPeople as S } from './TenMillionPeople.styles'
import { QUESTION } from './constants'

export const TenMillionPeople: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.PictureContainer>
        <img
          src={imageUrl || tenMillionPeopleImage}
          alt="10 million people have chosen dancebit"
        />
      </S.PictureContainer>
      <Container>
        <S.Title>
          <Trans i18nKey="onboarding.tenMillionPeople.question" />
        </S.Title>
        <S.Subtitle>{t('onboarding.tenMillionPeople.subtitle')}</S.Subtitle>

        <NavigationButton
          type="button"
          onClick={handleClick}
        >{t`actions.continue`}</NavigationButton>
      </Container>
    </>
  )
}
