import imgFirst from 'assets/images/success-stories-first-bg.png'
import iconFirst from 'assets/images/success-stories-first-icon.png'
import imgSecond from 'assets/images/success-stories-second-bg.png'
import iconSecond from 'assets/images/success-stories-second-icon.png'
import imgThird from 'assets/images/success-stories-third-bg.png'
import iconThird from 'assets/images/success-stories-third-icon.png'

export const RESULTS = [
  {
    icon: iconFirst,
    name: 'purchaseFreeOffer.successStories.first.name',
    date: 'purchaseFreeOffer.successStories.first.date',
    image: imgFirst,
    text: 'purchaseFreeOffer.successStories.first.text',
    likesCount: 148,
    commentsCount: 55,
  },
  {
    icon: iconSecond,
    name: 'purchaseFreeOffer.successStories.second.name',
    date: 'purchaseFreeOffer.successStories.second.date',
    text: 'purchaseFreeOffer.successStories.second.text',
    image: imgSecond,
    likesCount: 129,
    commentsCount: 26,
  },
  {
    icon: iconThird,
    name: 'purchaseFreeOffer.successStories.third.name',
    date: 'purchaseFreeOffer.successStories.third.date',
    text: 'purchaseFreeOffer.successStories.third.text',
    image: imgThird,
    likesCount: 96,
    commentsCount: 19,
  },
]
