import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'

import { TSlide } from 'modules/purchase/constants'

import starSvg from 'assets/images/sprite/star.svg'

import { MeasurementSystem } from 'root-constants'

import { StyledPhotoResultV3 as S } from './PhotoResultNew.styles'

Swiper.use([Pagination, Autoplay])

type TProps = {
  reviews: TSlide[]
  className?: string
}

export const PhotoResultNew: FC<TProps> = ({ reviews, className = '' }) => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)

  const isMetricSystemSelected = useMemo(
    () => measurementSystem === MeasurementSystem.METRIC,
    [measurementSystem],
  )

  return (
    <S.Wrapper className={className}>
      <S.Title>{t`purchaseWithoutIntro.photoResult.title`}</S.Title>
      <S.Description>
        <Trans i18nKey="purchaseWithoutIntro.photoResult.descriptionNew" />
      </S.Description>

      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {reviews.map(({ imageUrl, id, author, review, weight }) => (
            <SwiperSlide key={id}>
              {({ isActive, isPrev }) => (
                <S.PhotoResultWrapper>
                  <S.Image src={imageUrl} alt="before" />
                  <S.CardWrapper key={author}>
                    {weight && (
                      <S.ResultContainer>
                        <S.ResultText>
                          {t(weight, {
                            context: isMetricSystemSelected
                              ? 'beforeKg'
                              : 'beforeLbs',
                          })}
                        </S.ResultText>
                        <S.ResultSvgContainer />
                        <S.ResultText>
                          {t(weight, {
                            context: isMetricSystemSelected
                              ? 'afterKg'
                              : 'afterLbs',
                          })}
                        </S.ResultText>
                      </S.ResultContainer>
                    )}
                    <S.Card $isActive={isActive} $isPrev={isPrev}>
                      <S.Author>{t(author)}</S.Author>
                      <S.Rating>
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                      </S.Rating>
                      <S.Text>
                        {t(review, { context: measurementSystem })}
                      </S.Text>
                    </S.Card>
                  </S.CardWrapper>
                </S.PhotoResultWrapper>
              )}
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
