import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState } from 'models/inputField.model'

export type TRepeatPassword = Omit<IInputFieldState, 'firebaseError'>

type TRepeatPasswordHook = [
  TRepeatPassword,
  Dispatch<SetStateAction<TRepeatPassword>>,
]

export const useRepeatPasswordInputField = (
  mainPassword: string,
): TRepeatPasswordHook => {
  const { t } = useTranslation()

  const [password, setPassword] = useState<TRepeatPassword>({
    value: '',
    isValid: true,
    isFocused: false,
    validationText: '',
  })

  useEffect(() => {
    if (mainPassword && password.value && password.value !== mainPassword) {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
      }))
      return
    }

    setPassword((prevState) => ({
      ...prevState,
      isValid: true,
    }))
  }, [mainPassword, password.value, t])

  useEffect(() => {
    setPassword((prevState) => ({
      ...prevState,
      validationText: !password.isValid ? t`login.noMatchPassword` : '',
    }))
  }, [password.isValid, t])

  return [password, setPassword]
}
