import styled from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

export const StyledQuotation = {
  QuoteContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
  `,
  Quote: styled.div`
    text-align: center;
    font-size: 17px;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    margin-top: 24p;
    margin-bottom: 32px;
  `,
  AvatarBlock: styled.div`
    position: relative;
    max-width: 343px;
  `,
  Image: styled.img`
    width: 100%;
    min-height: 240px;
    border-radius: 24px;
  `,
  Label: styled.div`
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    width: 327px;
    padding: 6px 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    color: ${Color.WHITE};
  `,
  Name: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(24, 11, 79, 0.2);
  `,
  Description: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(24, 11, 79, 0.2);
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    background: #ecf2fa;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background: #ecf2fa;
  `,
}
