import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StylesReach = {
  Title: styled(PageTitle)`
    margin-top: 16px;

    & strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.PRIMARY};
    }
  `,

  Badge: styled.div<{ $hasMotivationEvent: boolean }>`
    max-width: ${({ $hasMotivationEvent }) =>
      $hasMotivationEvent ? '260px' : '145px'};
    width: 100%;
    text-align: center;
    margin: 12px auto 24px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${Color.GRAY_10};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    strong {
      text-transform: lowercase;
    }
  `,
  Description: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 43px;
  `,
}
