import styled from 'styled-components'

export const DailyWaterStyles = {
  GlassesContainer: styled.div`
    min-height: 340px;
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
  `,
}
