import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES, QUESTION } from './constants'

export const MotivatingEvent: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t('onboarding.motivatingEvent.title')}
      </PageTitle>

      {(optionData?.length ? optionData : OPTION_VALUES).map(
        ({ text, value, imageUrl }) => (
          <AnswerWithIcon
            {...optionProps}
            disabled={isAnswersDisabled}
            key={value}
            value={value}
            iconSrc={imageUrl}
            theme={answerTheme.DANCEBIT}
            iconHeight="40px"
            iconMinHeight="40px"
            iconMinWidth="40px"
            height="80px"
            padding="16px"
            maxWidth="345px"
            iconAlignSelf="center"
            iconWidth="40px"
            margin="0 0 16px 0"
          >
            <Trans>{text}</Trans>
          </AnswerWithIcon>
        ),
      )}
    </Container>
  )
}
