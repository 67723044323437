import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import loseWeightGraph from 'assets/images/upsell-popup-loseWeight.png'
import stayFitGraph from 'assets/images/upsell-popup-stayFit.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  MAIN_GOALS,
  ONBOARDING_GOAL_EVENT,
  ScreenId,
  ScreenName,
} from 'root-constants'

import { StyledGetDiscountPopup as S } from './GetDiscountPopup.styles'

export const GetDiscountPopup: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const isStayFitFlow = useSelector(selectIsStayFitFlow)

  const goal = useMemo(
    () => (isStayFitFlow ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitFlow],
  )

  const eventGoal = useMemo(
    () =>
      isStayFitFlow
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitFlow],
  )

  useEffect(() => {
    eventLogger.logUpsellCancelPopupVisible(
      ScreenName.UPSELL_LONG,
      ScreenId.UPSELL_LONG,
      eventGoal,
    )
  }, [goal, eventGoal])

  const handleSeeDiscount = useCallback(() => {
    eventLogger.logUpsellCancelPopupClosed(
      ScreenName.UPSELL_LONG,
      ScreenId.UPSELL_LONG,
      eventGoal,
    )

    goTo({
      pathname: PageId.UPSELL_CANCEL_OFFER,
      search,
    })
  }, [eventGoal, search])

  return (
    <S.Wrapper>
      <S.Content>
        <Container>
          <S.Title>
            <Trans
              i18nKey="upsellCancelOffer.seeDiscount.title"
              values={{ context: goal }}
            />
          </S.Title>
          <S.Subtitle>
            <Trans
              i18nKey="upsellCancelOffer.seeDiscount.subtitle"
              values={{ context: goal }}
            />
          </S.Subtitle>
          <img
            src={isStayFitFlow ? stayFitGraph : loseWeightGraph}
            alt="results graph"
            height={275}
          />
          <S.Discount>
            <S.DiscountTitle>
              {t('upsellCancelOffer.seeDiscount.discount')}
            </S.DiscountTitle>
            <div>
              <strong>{t('upsellCancelOffer.seeDiscount.luckyYou')}</strong>
              <p>
                <Trans i18nKey="upsellCancelOffer.seeDiscount.extraDiscount" />
              </p>
            </div>
          </S.Discount>
          <Button onClick={handleSeeDiscount}>
            {t('upsellCancelOffer.seeDiscount.button')}
          </Button>
        </Container>
      </S.Content>
    </S.Wrapper>
  )
}
