import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort, Color } from 'root-constants'

import { OPTION_VALUES, OPTION_VALUES_LEGS, QUESTION } from './constants'

export const PhysicalBuild: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const isLegsFlow = useMemo(() => cohortToUse === Cohort.DB_LEGS, [
    cohortToUse,
  ])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    return isLegsFlow ? OPTION_VALUES_LEGS : OPTION_VALUES
  }, [isLegsFlow, optionData])

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t(
          isLegsFlow
            ? 'onboarding.physicalBuild.titleLegs'
            : 'onboarding.physicalBuild.title',
        )}
      </PageTitle>
      {options.map(({ value, text, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          theme={answerWithIconTheme.DANCEBIT}
          margin="0 0 16px 0"
          borderRadius="16px"
          color={Color.DARK}
          iconWidth="100px"
          key={value}
          value={value}
          iconAlignSelf="flex-start"
          iconSrc={imageUrl}
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
