import React from 'react'

import { StyledAnswerButton as S } from './AnswerButton.styles'

export type TAnswerProps = {
  iconSrc?: string
  iconWidth?: number
  iconHeight?: number
  withCheckbox?: boolean
  isAgeFlow?: boolean
  withDetailedAnswer?: boolean
  className?: string
  children?: React.ReactNode
}

export const AnswerButton: React.FC<TAnswerProps> = ({
  children,
  iconSrc,
  iconWidth,
  iconHeight,
  withCheckbox,
  isAgeFlow = false,
  withDetailedAnswer,
  className,
}) => (
  <S.Wrapper
    className={className}
    data-testid="answer-button"
    $withCheckbox={withCheckbox}
    $isAgeFlow={isAgeFlow}
  >
    <S.Content $withDetailedAnswer={withDetailedAnswer}>
      {iconSrc && (
        <S.Icon src={iconSrc} alt="" height={iconHeight} width={iconWidth} />
      )}
      <S.Text $isAgeFlow={isAgeFlow}>{children}</S.Text>
    </S.Content>
  </S.Wrapper>
)
