import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { Color } from 'root-constants'

export const StyledDownload = {
  Title: styled.h1`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 16px 0 32px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Button: styled.button`
    ${StyledButtonBase};
    font-weight: 600;
    text-transform: uppercase;
  `,
  NoLink: styled.a`
    display: block;
    color: #81848c;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 24px;
    cursor: pointer;
    text-transform: uppercase;
  `,
  NoLinkBelly: styled.a`
    display: block;
    color: ${Color.GRAY_60};
    text-align: center;
    font-style: normal;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    padding: 10px;
    margin-top: 10px;
  `,
}
