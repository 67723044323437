import React, { FC, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Swiper, { Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import arrowSvg from 'assets/images/sprite/right-yellow-arrow.svg'
import starSvg from 'assets/images/sprite/star.svg'

import { Color } from 'root-constants'

import { StyledHelpedMillionsPeople as S } from './HelpedMillionsPeople.styles'
import { QUESTION, REVIEW_SLIDES } from './constants'

Swiper.use([Pagination])
export const HelpedMillionsPeople: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const handleSwitch = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(''), [handleContinue])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Wrapper>
          <Container isLarge>
            <PageTitle marginBottom={16} highlightColor={Color.PRIMARY}>
              <Trans i18nKey="onboarding.helpedMillionsPeople.title" />
            </PageTitle>

            <S.CarouselContainer>
              <Carousel
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true }}
              >
                {REVIEW_SLIDES.map(
                  ({ img, name, review, weight, isBigReview }) => (
                    <SwiperSlide key={name}>
                      <S.Review>
                        <S.Image src={img} />

                        <S.ReviewText $isBigReview={!!isBigReview}>
                          <S.BeforeAfterBlock>
                            <span>
                              <Trans
                                i18nKey={weight}
                                values={{ context: 'beforeLbs' }}
                              />
                            </span>

                            <SvgImage svg={arrowSvg} width={16} height={20} />

                            <span>
                              <Trans
                                i18nKey={weight}
                                values={{ context: 'afterLbs' }}
                              />
                            </span>
                          </S.BeforeAfterBlock>

                          <S.Reviewer>
                            <S.Name>
                              <Trans i18nKey={name} />
                            </S.Name>

                            <S.Rating>
                              <SvgImage svg={starSvg} width={16} height={16} />
                              <SvgImage svg={starSvg} width={16} height={16} />
                              <SvgImage svg={starSvg} width={16} height={16} />
                              <SvgImage svg={starSvg} width={16} height={16} />
                              <SvgImage svg={starSvg} width={16} height={16} />
                            </S.Rating>
                          </S.Reviewer>

                          <S.Comment
                            $isBigReview={!!isBigReview}
                            $isOpen={isOpen}
                          >
                            <Trans i18nKey={review} />
                          </S.Comment>

                          {isBigReview && (
                            <S.ViewMore onClick={handleSwitch}>
                              <Trans
                                i18nKey={
                                  isOpen
                                    ? 'onboarding.helpedMillionsPeople.showLess'
                                    : 'onboarding.helpedMillionsPeople.viewMore'
                                }
                              />
                            </S.ViewMore>
                          )}
                        </S.ReviewText>
                      </S.Review>
                    </SwiperSlide>
                  ),
                )}
              </Carousel>
            </S.CarouselContainer>
          </Container>
        </S.Wrapper>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
