import React, { InputHTMLAttributes } from 'react'

import checkIconSvg from 'assets/images/sprite/check-icon-rounded.svg'
import crossIconSvg from 'assets/images/sprite/cross-icon.svg'

import { StyledInputWithFloatPlaceholder as S } from './InputWithFloatPlaceholder.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  labelName: string
  isValid?: boolean
  validationText?: string
  type?: string
  iconSrc?: string
  marginBottom?: number
  inputHeight?: number
  hasValidationIcon?: boolean
  isAgeFlow?: boolean
}

export const InputWithFloatPlaceholder: React.FC<TProps> = ({
  value,
  labelName,
  isValid = true,
  validationText = '',
  type = 'text',
  iconSrc,
  marginBottom = 0,
  inputHeight = 60,
  hasValidationIcon = false,
  isAgeFlow = false,
  ...props
}) => (
  <S.Wrapper
    data-valid={isValid}
    data-validation-text={validationText}
    $marginBottom={marginBottom}
  >
    <S.Input
      required
      type={type}
      $isAgeFlow={isAgeFlow}
      $inputHeight={inputHeight}
      value={value}
      {...props}
    />
    <S.Label $value={value}>{labelName}</S.Label>

    {hasValidationIcon && (
      <>
        {value && isValid && <S.SvgIcon svg={checkIconSvg} />}
        {value && !isValid && <S.SvgIcon svg={crossIconSvg} />}
      </>
    )}

    {iconSrc && <S.Icon src={iconSrc} alt="input-icon" />}
  </S.Wrapper>
)
