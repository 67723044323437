import React, { FC, ReactNode, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { getAxonItem } from 'helpers/getAxonItem'

import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import check from 'assets/images/sprite/green-white-check.svg'

import { SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { SubscriptionItemLiveChat } from '../SubscriptionItemLiveChat'
import { StyledSubscriptionUpgradeBlock as S } from './SubscriptionUpgradeBlock.styles'

export const SubscriptionUpgradeBlock: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )
  return (
    <S.Wrapper>
      <S.Title>{t`purchase1.subscription.title`}</S.Title>

      {trialPeriodDays === SEVEN_DAY_TRIAL_DURATION && (
        <>
          <S.Notification>
            <S.NotificationTitle>
              <Trans i18nKey="purchaseLiveChat.priceToday" />
            </S.NotificationTitle>

            <S.DurationContainer>
              <S.Duration>
                <Trans i18nKey="purchaseLiveChat.trialDuration" />
              </S.Duration>

              <S.Badge>
                <SvgImage svg={check} width={12} height={12} />
                <S.BadgeText>
                  <Trans i18nKey="purchaseLiveChat.purchased" />
                </S.BadgeText>
              </S.Badge>
            </S.DurationContainer>
          </S.Notification>

          <S.Subtitle>
            <Trans i18nKey="purchaseLiveChat.chooseYourPlan" />
          </S.Subtitle>
        </>
      )}

      <S.Container>
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.planId}
            onChange={handleChange}
            withoutMargin
          >
            <SubscriptionItemLiveChat
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </Option>
        ))}
      </S.Container>

      <S.AchievedGoals>
        <Trans i18nKey="purchaseLiveChat.achievedTheirGoals" />
      </S.AchievedGoals>

      <S.ButtonContainer>{children}</S.ButtonContainer>

      <S.UpgradeDisclaimer />
    </S.Wrapper>
  )
}
