import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StyledDancingStyle as S } from 'pages/questions/DancingStyle/DancingStyle.styles'

import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, CDN_FOLDER_LINK, Cohort } from 'root-constants'

import { NEW_DANCE_STYLES, OPTION_VALUES, QUESTION } from './constants'

export const DancingStyle: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <>
      <S.Container $isAgeFlow={isAgeFlow} isLarge>
        <PageTitle
          isAgeFlow={isAgeFlow}
          marginBottom={16}
        >{t`onboarding.dancingStyle.question`}</PageTitle>
        <S.Subtitle
          $isAgeFlow={isAgeFlow}
        >{t`onboarding.dancingStyle.description`}</S.Subtitle>

        {Object.values(OPTION_VALUES).map((style) => (
          <Option
            {...optionProps}
            key={style}
            value={style}
            checked={answers.includes(style)}
          >
            <S.AnswerButton isAgeFlow={isAgeFlow} withCheckbox>
              <S.DanceCard>
                <video
                  muted
                  loop
                  autoPlay
                  controls={false}
                  playsInline
                  poster={`${CDN_FOLDER_LINK}/videos/${style}Cover.png`}
                  onCanPlayThrough={(event) => event.currentTarget.play()}
                >
                  <source
                    src={`${CDN_FOLDER_LINK}/videos/${style}Style.mp4`}
                    type="video/mp4"
                  />
                </video>

                <S.DanceInfo>
                  {NEW_DANCE_STYLES.includes(style) && (
                    <S.New
                      $isSelected={answers.includes(style)}
                    >{t`onboarding.dancingStyle.new`}</S.New>
                  )}
                  <S.DanceTitle $isSelected={answers.includes(style)}>
                    {t(`onboarding.dancingStyle.${style}`, {
                      context: 'v2',
                    })}
                  </S.DanceTitle>
                  <S.Description>
                    {t(`onboarding.dancingStyle.${style}Description`)}
                  </S.Description>
                </S.DanceInfo>
              </S.DanceCard>
            </S.AnswerButton>
          </Option>
        ))}
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButton>
    </>
  )
}
