import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { CurrentHeightWithRuler } from 'pages/questions/CurrentHeightWithRuler'
import { DefaultHeight } from 'pages/questions/CurrentHeightWithRuler/constants'

import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { Toggle } from 'components/Toggle'

import { setWeightAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectUserHeight,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { convertFeetToInches } from 'helpers/convertFootsToInches'
import { getIsImperialHeightValid } from 'helpers/getIsImperialHeightValid'

import { TPageProps } from 'models/common.model'

import { goTo } from 'browser-history'
import {
  ADULT_COHORT,
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  Cohort,
  FLOAT_NUMBER_REGEX,
  GROWTHBOOK_EXPERIMENT,
  MeasurementSystem,
} from 'root-constants'

import { StyledCurrentHeight as S } from './CurrentHeight.styles'
import {
  INITIAL_STATE,
  MinMaxHeight,
  QUESTION,
  TInitialState,
} from './constants'

export const CurrentHeight: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userCountryCode = useSelector(selectUserCountryCode)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const selectedHeight = useSelector(selectUserHeight)
  const selectedMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  const hasRulerTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_455)

  const [height, setHeight] = useState<TInitialState>({
    ...INITIAL_STATE,
  })

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isMetricSystemSelected = useMemo(
    () => height.unit === MeasurementSystem.METRIC,
    [height.unit],
  )

  const isCountryWithImperialSystem = useMemo(
    () => COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(userCountryCode),
    [userCountryCode],
  )

  useEffect(() => {
    const initialMeasurementSystem = isCountryWithImperialSystem
      ? MeasurementSystem.IMPERIAL
      : MeasurementSystem.METRIC

    const initialHeight =
      selectedHeight ||
      (initialMeasurementSystem === MeasurementSystem.METRIC
        ? DefaultHeight.CM
        : DefaultHeight.IN)

    const preselectedMeasurementSystem = selectedHeight
      ? selectedMeasurementSystem
      : initialMeasurementSystem

    setHeight((prevState) => ({
      ...prevState,
      valueIn: hasRulerTest ? initialHeight : '',
      unit: hasRulerTest
        ? preselectedMeasurementSystem
        : initialMeasurementSystem,
    }))
  }, [
    hasRulerTest,
    isCountryWithImperialSystem,
    selectedHeight,
    selectedMeasurementSystem,
  ])

  const handleMeasurementChange = useCallback(
    (isChecked) => {
      const initialValue = isChecked ? DefaultHeight.IN : DefaultHeight.CM

      setHeight(() => ({
        ...INITIAL_STATE,
        valueIn: hasRulerTest ? initialValue : '',
        unit: isChecked ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
      }))
    },
    [hasRulerTest],
  )

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()

      const inputValue =
        isMetricSystemSelected || hasRulerTest
          ? height.valueIn
          : String(convertFeetToInches(height.valueIn, height.valueFt))

      dispatch(
        setWeightAnswerAction({
          question: QUESTION,
          answers: {
            [pageId]: inputValue,
            measurementSystem: isMetricSystemSelected
              ? MeasurementSystem.METRIC
              : MeasurementSystem.IMPERIAL,
          },
        }),
      )
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [
      isMetricSystemSelected,
      height.valueIn,
      height.valueFt,
      dispatch,
      pageId,
      nextPagePath,
      hasRulerTest,
    ],
  )

  const handleIntegerChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FLOAT_NUMBER_REGEX.test(value)) {
      setHeight((prevState) => ({
        ...prevState,
        valueIn: value,
        isValid: validity.valid,
      }))
    }
  }, [])

  const handleFractionalChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FLOAT_NUMBER_REGEX.test(value)) {
        setHeight((prevState) => ({
          ...prevState,
          valueFt: value,
          isValid: validity.valid,
        }))
      }
    },
    [],
  )

  const isImperialHeightValid = useCallback(
    () =>
      getIsImperialHeightValid({
        fractional: height.valueFt,
        integer: height.valueIn,
      }),
    [height.valueFt, height.valueIn],
  )

  return (
    <form onSubmit={handleClick}>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle
          isAgeFlow={isAgeFlow}
          marginBottom={10}
        >{t`onboarding.currentHeight.question`}</PageTitle>
        <PageTitleDescription marginBottom={30}>
          <Trans
            i18nKey="onboarding.currentHeight.subtitle"
            components={[<br />]}
          />
        </PageTitleDescription>
        <S.Actions>
          <Toggle
            data-testid="weight-toggle"
            labels={[
              t('commonComponents.centimeter'),
              t('commonComponents.feet'),
            ]}
            checked={!isMetricSystemSelected}
            onChange={handleMeasurementChange}
          />
          {hasRulerTest ? (
            <CurrentHeightWithRuler
              onChange={handleIntegerChange}
              value={height.valueIn}
              isMetricSystemSelected={isMetricSystemSelected}
            />
          ) : (
            <S.InputContainer>
              <S.InputWrapper>
                <S.InputIn
                  type="number"
                  value={height.valueIn}
                  isValid={height.isValid}
                  min={
                    isMetricSystemSelected
                      ? MinMaxHeight.MIN_HEIGHT_CM
                      : MinMaxHeight.MIN_HEIGHT_FT
                  }
                  max={
                    isMetricSystemSelected
                      ? MinMaxHeight.MAX_HEIGHT_CM
                      : MinMaxHeight.MAX_HEIGHT_FT
                  }
                  lang="en"
                  step="0.1"
                  allowFloatNumbers={isMetricSystemSelected}
                  onChange={handleIntegerChange}
                  isAgeFlow={isAgeFlow}
                  $isMetricSystemSelected={isMetricSystemSelected}
                  isContentCentered
                />

                <S.Suffix>
                  {isMetricSystemSelected ? '' : t('commonComponents.feet')}
                </S.Suffix>
              </S.InputWrapper>

              {!isMetricSystemSelected && (
                <S.InputWrapper>
                  <S.InputFt
                    type="number"
                    value={height.valueFt}
                    required={false}
                    isContentCentered
                    isValid={height.isValid}
                    min={MinMaxHeight.MIN_HEIGHT_IN}
                    max={MinMaxHeight.MAX_HEIGHT_IN}
                    lang="en"
                    step="1"
                    allowFloatNumbers={false}
                    isAgeFlow={isAgeFlow}
                    $isMetricSystemSelected={isMetricSystemSelected}
                    onChange={handleFractionalChange}
                  />
                  <S.Suffix>{t('commonComponents.in')}</S.Suffix>
                </S.InputWrapper>
              )}
            </S.InputContainer>
          )}
        </S.Actions>
      </S.Container>
      <NavigationButton
        type="submit"
        isAgeFlow={isAgeFlow}
        disabled={
          isMetricSystemSelected || hasRulerTest
            ? !height.isValid
            : !isImperialHeightValid()
        }
      >
        {t`actions.continue`}
      </NavigationButton>
    </form>
  )
}
