import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import offerCoverBelly from 'assets/images/free-offer-cover-belly.png'
import offerCover from 'assets/images/free-offer-cover.png'
import checkIcon from 'assets/images/sprite/red-check.svg'

import { StyledOfferCard as S } from './FreeOfferCard.styles'

type TProps = {
  className?: string
}

const OFFER_PRICE = 28

export const FreeOfferCard: React.FC<TProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const { isSaleFlow } = useCohortInfo()

  return (
    <S.Wrapper className={className}>
      <S.Image src={isSaleFlow ? offerCoverBelly : offerCover} />
      <S.OfferTitle>
        <Trans
          i18nKey={
            isSaleFlow
              ? 'purchaseFreeOfferBelly.offerTitle'
              : 'purchaseFreeOffer.offerTitle'
          }
        />
      </S.OfferTitle>
      <S.Price>
        <S.Free>
          <SvgImage svg={checkIcon} width={16} height={16} />
          {t('purchaseFreeOffer.freeGift')}
        </S.Free>
        <S.PriceValue>
          {CURRENCY_SYMBOLS[currency]}
          {OFFER_PRICE} {t('purchaseFreeOffer.value')}
        </S.PriceValue>
      </S.Price>
    </S.Wrapper>
  )
}
