import styled from 'styled-components'

type TContainerProps = {
  $isLarge?: boolean
}

export const StyledContainer = {
  Container: styled.div<TContainerProps>`
    width: 100%;
    max-width: ${({ $isLarge }) => ($isLarge ? '375px' : '360px')};
    margin: 0 auto;
    padding: 0 16px;
  `,
}
