import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPageDescription = {
  PageDescription: styled.h2<{ $marginBottom?: number }>`
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.DARK};
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom && `${$marginBottom}px`};
  `,
}
