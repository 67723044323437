import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { CENTS_IN_DOLLAR, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { StyledSubscriptionItemLiveChat as S } from './SubscriptionItemLiveChat.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

const MONTHS_IN_ONE_YEAR = 12

export const SubscriptionItemLiveChat: FC<TProps> = ({
  isSelected,
  subscription: {
    mainPrices: { periodQuantity },
    upgradeDiff,
    isDefault,
    currency,
  },
}) => {
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const price = useMemo(
    () =>
      trialPeriodDays === SEVEN_DAY_TRIAL_DURATION
        ? upgradeDiff?.fullPrice
        : upgradeDiff?.amountToPay,
    [trialPeriodDays, upgradeDiff?.amountToPay, upgradeDiff?.fullPrice],
  )

  const priceToDisplay = useMemo(() => (price || 0) / CENTS_IN_DOLLAR, [price])

  return (
    <S.Container $isSelected={isSelected}>
      <S.YearPrice $isSelected={isSelected}>
        <S.PlanDuration>
          <Trans
            i18nKey="purchaseLiveChat.yearlyPeriod"
            values={{
              count: periodQuantity / MONTHS_IN_ONE_YEAR,
            }}
          />
        </S.PlanDuration>
        <S.TrialPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: priceToDisplay,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.TrialPrice>
      </S.YearPrice>
      <S.DiscountBlock>
        <S.Badge>
          <Trans i18nKey="purchaseUpgrade.discount" />
        </S.Badge>
        <S.FullPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: priceToDisplay * 2,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.FullPrice>
      </S.DiscountBlock>

      {!isDefault && (
        <S.BonusContainer>
          <S.BonusTitle>
            <Trans i18nKey="purchaseLiveChat.bonusProgram" />
          </S.BonusTitle>
          <S.BonusPrice>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: 0,
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.BonusPrice>
        </S.BonusContainer>
      )}
    </S.Container>
  )
}
