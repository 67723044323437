import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledPreparePlanBelly = {
  ProgressContainer: styled.div`
    margin-top: 16px;
    margin-bottom: 48px;

    p {
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    svg {
      opacity: 1;
    }
  `,
  RatingTitle: styled.h2`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Card: styled.div`
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    min-height: 164px; ;
  `,
  CardRating: styled.img`
    display: block;
    max-width: 96px;
    min-height: 16px;
    margin-top: 8px;
  `,
  CardReview: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
  `,
  CardReviewerImage: styled.img`
    min-width: 48px;
    min-height: 48px;
    border-radius: 48px;
    margin-right: 12px;
  `,
  CardReviewer: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `,
  ReviewTitle: styled.div`
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
  `,
  PeopleChooseTitle: styled.div`
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      color: ${Color.PRIMARY};
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 4px;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: 32px auto;
    max-width: 280px;
    text-align: center;

    span {
      color: ${Color.PRIMARY};
    }
  `,
  Container: styled(Container)`
    padding-bottom: 24px;
  `,
}
