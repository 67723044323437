import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TContentContainerProps = {
  $isVisible: boolean
  $hasBottomPadding: boolean
}

type TPaymentMethodProps = {
  $isSelected: boolean
}

export const StyledCheckoutSeparateMethods = {
  Root: styled.div<{ $isJapanese: boolean }>`
    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  ContentContainer: styled.div<TContentContainerProps>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    max-width: 360px;
    margin: 0 auto;
    padding-top: 40px;

    ${({ $hasBottomPadding }) =>
      $hasBottomPadding &&
      css`
        padding-bottom: 115px;
      `};
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 65px;
    right: 15px;
    width: 24px;
    height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  PaymentMethodsTitle: styled.h2`
    color: ${Color.DARK};
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding-bottom: 8px;
    text-align: center;
  `,
  PaymentMethodsWrapper: styled.div<{ $isJapaneseFlow: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 14px;
    margin: 16px 16px 0;
    padding-bottom: 24px;
    border-bottom: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? `1px solid ${Color.GRAY_40}}` : '1px solid #e9eaed'};
  `,
  PaymentMethod: styled.div<TPaymentMethodProps>`
    width: 100px;
    min-width: 100px;
    height: 78px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${Color.WHITE};
    -webkit-tap-highlight-color: transparent;
    border: 1px solid
      ${({ $isSelected }) => ($isSelected ? Color.PRIMARY : '#c4c4c4')};

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        p {
          color: ${Color.DARK};
        }
      `}
    &:only-child,
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ div {
      flex: 1;
    }
  `,
  PaymentMethodText: styled.p<{ $isJapaneseFlow: boolean }>`
    color: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? '#828692' : '#9498a5'};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
  `,
  AlternativePaymentMethodImage: styled.img`
    width: 72px;
    height: 46px;
  `,
  PriceDescription: styled.div<{ $isJapaneseFlow: boolean }>`
    margin: 12px 16px 0;
    padding-bottom: 16px;
    border-bottom: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? `1px solid ${Color.GRAY_40}}` : '1px solid #e9eaed'};
  `,
  PriceInfo: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  OldPrice: styled.span`
    color: #bd4260;
    font-weight: 600;
    text-decoration: line-through;
    margin-right: 4px;
  `,
  PriceBlockText: styled.span<{ $isJapaneseFlow: boolean }>`
    color: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? '#828692' : '#9498a5'};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  TotalDiscount: styled.span`
    color: #bd4260;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyPayment: styled.div<{ $isJapaneseFlow: boolean }>`
    margin: 16px 8px 0;
    border-radius: 14px;
    background-color: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? '#d0eec8' : '#f2faf0'};
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DailyText: styled.span`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyAmount: styled.span<{ $isJapaneseFlow: boolean }>`
    color: ${({ $isJapaneseFlow }) =>
      $isJapaneseFlow ? '#50ab64' : '#7ad164'};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  TotalPayment: styled.div`
    margin: 16px 16px 24px;
  `,
  TotalDescription: styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Color.DARK};
    font-size: 16px;
    line-height: 24px;
  `,
  TotalText: styled.span`
    font-weight: 600;
  `,
  TotalAmount: styled.span`
    font-weight: 400;

    & > strong {
      font-weight: 700;
    }
  `,
  SaveText: styled.p`
    color: #bd4260;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  PaymentFormWrapper: styled.div`
    padding: 0 16px;
  `,
  PaymentsSystemImage: styled.img`
    margin: 32px auto 16px;
    max-width: 256px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 24px;
  `,
  PaymentsSystemText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.DARK};
    text-align: center;
    padding-bottom: 32px;
    font-style: normal;
    opacity: 0.5;
  `,
  PaymentMethodContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    margin-bottom: 16px;
  `,
}
