import extraChallengesImg from 'assets/images/extra-challenges-upsell-plan.png'
import liveEventsImg from 'assets/images/live-events-upsell-plan.png'
import unleashFemininityImg from 'assets/images/unleash-femininity-upsell-plan.png'
import bellyPlanImg from 'assets/images/upsell-belly-plan.png'
import bodyProgramImg from 'assets/images/upsell-body-program.png'

import { MAIN_GOALS } from 'root-constants'

export const UPSELL_BENEFITS = {
  [MAIN_GOALS.STAY_FIT]: [
    {
      id: '1',
      img: unleashFemininityImg,
      textPath: 'upsellCancelOffer.unleashFemininity',
    },
    {
      id: '2',
      img: bodyProgramImg,
      textPath: 'upsellCancelOffer.confidenceProgram',
    },
    {
      id: '3',
      img: liveEventsImg,
      textPath: 'upsellCancelOffer.liveEvents',
    },
    {
      id: '4',
      img: extraChallengesImg,
      textPath: 'upsellCancelOffer.extraChallenges',
    },
  ],
  [MAIN_GOALS.LOSE_WEIGHT]: [
    {
      id: '1',
      img: bellyPlanImg,
      textPath: 'upsellCancelOffer.bellyFat',
    },
    {
      id: '2',
      img: bodyProgramImg,
      textPath: 'upsellCancelOffer.personalizedProgram',
    },
    {
      id: '3',
      img: liveEventsImg,
      textPath: 'upsellCancelOffer.liveEvents',
    },
    {
      id: '4',
      img: extraChallengesImg,
      textPath: 'upsellCancelOffer.extraChallenges',
    },
  ],
}
