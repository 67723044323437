import React, {
  InputHTMLAttributes,
  KeyboardEvent,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  NUMERIC_KEYPAD_FLOAT_REGEX,
  NUMERIC_KEYPAD_REGEX,
} from 'root-constants'

import { StyledInput as S } from './Input.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  hasVisibilityControl?: boolean
  isValid?: boolean
  isAgeFlow?: boolean
  validationText?: string
  inputRef?: RefObject<HTMLInputElement>
  className?: string
  allowFloatNumbers?: boolean
  maxLength?: number
  isContentCentered?: boolean
}

export const Input: React.FC<TProps> = ({
  label,
  hasVisibilityControl = false,
  isValid = true,
  isAgeFlow = false,
  validationText = '',
  inputRef,
  className,
  type,
  allowFloatNumbers = true,
  maxLength = 30,
  isContentCentered = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const inputType = useMemo(() => {
    if (!hasVisibilityControl) return type

    return isVisible ? 'text' : 'password'
  }, [hasVisibilityControl, isVisible, type])

  const handleNumberKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        !(allowFloatNumbers
          ? NUMERIC_KEYPAD_FLOAT_REGEX
          : NUMERIC_KEYPAD_REGEX
        ).test(event.key)
      ) {
        event.preventDefault()
      }
    },
    [allowFloatNumbers],
  )

  return (
    <S.Wrapper data-valid={isValid} data-validation-text={validationText}>
      <S.Input
        className={className}
        $hasLabel={Boolean(label)}
        $hasButton={hasVisibilityControl}
        $isContentCentered={isContentCentered}
        $isAgeFlow={isAgeFlow}
        required
        type={inputType}
        ref={inputRef}
        spellCheck={false}
        maxLength={maxLength}
        onKeyDown={inputType === 'number' ? handleNumberKeyDown : undefined}
        {...props}
      />
      {label && <S.Label>{label}</S.Label>}
      {hasVisibilityControl && (
        <S.VisibilityButton
          type="button"
          onClick={() => setIsVisible((prevValue) => !prevValue)}
          data-visible={isVisible}
        />
      )}
    </S.Wrapper>
  )
}
