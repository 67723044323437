import getFirmImg from 'assets/images/get-firm-menopause.png'
import balanceHormonesImg from 'assets/images/hormone-balance.png'
import improveHealthImg from 'assets/images/improve-health.png'
import loseWeightImg from 'assets/images/lose-weight-menopause.png'

export const QUESTION = 'What do you want to achieve?'

export const OPTION_VALUES = [
  {
    value: 'loseWeight',
    title: 'onboarding.mainGoal.loseWeight',
    img: loseWeightImg,
  },
  {
    value: 'getFirm',
    title: 'onboarding.mainGoal.getFirm',
    img: getFirmImg,
  },
  {
    value: 'balanceHormones',
    title: 'onboarding.mainGoal.balanceHormones',
    img: balanceHormonesImg,
  },
  {
    value: 'improveHealth',
    title: 'onboarding.mainGoal.improveHealth',
    img: improveHealthImg,
  },
]
