import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import starSvg from 'assets/images/sprite/star.svg'

import { StyledReviewsCarousel as S } from './ReviewsCarousel.styles'
import { REVIEWS_LIST } from './constants'

Swiper.use([Pagination, Autoplay])

export const ReviewsCarousel: React.FC = () => {
  const { t } = useTranslation()
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Title>{customerReviewsTitle || t`purchase3.reviews.title`}</S.Title>
      <Carousel
        slidesPerView={1}
        loop
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {(customerReviews.length ? customerReviews : REVIEWS_LIST).map(
          ({ id, author, review }) => (
            <SwiperSlide key={id}>
              <S.CardContainer>
                <S.Card>
                  <S.Author>{t(author)}</S.Author>
                  <S.Rating>
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                  </S.Rating>
                  <S.Text>
                    <Trans i18nKey={review} />
                  </S.Text>
                </S.Card>
              </S.CardContainer>
            </SwiperSlide>
          ),
        )}
      </Carousel>
    </S.Wrapper>
  )
}
