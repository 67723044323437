import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectAnswers } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { MeasurementSystem } from 'root-constants'

import { StyledReachTarget as S } from './ReachTargetAdult.styles'
import { SLIDES_RESULT } from './constants'

Swiper.use([Pagination, Autoplay])

export const ReachTargetAdult: FC = () => {
  const { t } = useTranslation()

  const userAnswer = useSelector(selectAnswers)

  const { title } = useDynamicPaywallConfig()

  const isMetricSystemSelected = useMemo(
    () => userAnswer?.measurementSystem === MeasurementSystem.METRIC,
    [userAnswer],
  )

  return (
    <S.Wrapper>
      <div>
        <S.Text>
          {title || (
            <Trans
              i18nKey="purchaseWithoutIntro.textReachTarget_loseWeight"
              values={{
                target: userAnswer?.goalWeight || 77,
                measurementSystem: isMetricSystemSelected
                  ? t('commonComponents.metric')
                  : t('commonComponents.imperial'),
              }}
              components={{
                strong: <strong />,
                span: <span />,
              }}
            />
          )}
        </S.Text>
      </div>
      <S.CarouselContainer>
        <Carousel
          slidesPerView={1}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {SLIDES_RESULT.map((src) => (
            <SwiperSlide key={src}>
              <S.PhotoResultWrapper>
                <S.Image src={src} alt="before" />
              </S.PhotoResultWrapper>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
