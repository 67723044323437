import React from 'react'

import { StyledDailyBenefits as S } from './DailyBenefits.styles'

type TDailyBenefitsProps = {
  iconSrc: string
  title: string
  description: string
}

export const DailyBenefits: React.FC<TDailyBenefitsProps> = ({
  iconSrc,
  title,
  description,
}) => (
  <S.Root>
    <S.Caption>
      <S.Icon src={iconSrc} alt="" />
      <S.Title>{title}</S.Title>
    </S.Caption>
    <S.Description>{description}</S.Description>
  </S.Root>
)
