import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { HeaderV2 } from 'components/HeaderV2'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowAnimation from 'assets/animation/arrowAnimation.json'

import { Cohort, MAIN_GOALS, PagesSource } from 'root-constants'

import { StyledMainGoal as S } from './MainGoal.styles'
import { QUESTION } from './constants'

export const MainGoal: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const cohortToUse = parentCohort || cohort

  const [answer, setAnswer] = useState<string>('')

  const animationRef = useRef<HTMLDivElement>(null)

  const { title, subtitle, stickerText, imageUrls } = useDynamicOBConfig()
  const { headline, subtext } = useTitleFromUrl()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const staticTitle = useMemo(() => {
    if (isClearCohort) return t`onboarding.mainGoal.titleClearFlow`

    if (cohortToUse === Cohort.DB_REF) {
      return (
        <S.RefTitleContainer>
          <Trans i18nKey="onboarding.mainGoal.titleRef" />
        </S.RefTitleContainer>
      )
    }

    return t`onboarding.mainGoal.title`
  }, [isClearCohort, t, cohortToUse])

  const staticSubtitle = useMemo(() => {
    if (isClearCohort) return t`onboarding.mainGoal.subtitleClearFlow`

    if (cohortToUse === Cohort.DB_REF) return t`onboarding.mainGoal.subtitleRef`

    return t`onboarding.mainGoal.subtitle`
  }, [isClearCohort, t, cohortToUse])

  const { titleToShow, subtitleToShow } = useMemo(
    () => ({
      titleToShow: headline || title || staticTitle,
      subtitleToShow: subtext || subtitle || staticSubtitle,
    }),
    [headline, staticSubtitle, staticTitle, subtext, subtitle, title],
  )

  const uploadedImage = useMemo(() => {
    const [background, backgroundDesktop, sticker] = imageUrls || []

    return {
      background,
      backgroundDesktop,
      sticker,
    }
  }, [imageUrls])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            { eventID: isPersonalDataAllowed ? uuid : '' },
          )
        window.pintrk && window.pintrk('track', 'custom')
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleChange([value], handleContinue)
        setAnswer(value)
        setIsAnswersDisabled(true)
      },
    }),
    [
      isPersonalDataAllowed,
      uuid,
      pageId,
      setIsAnswersDisabled,
      handleContinue,
      handleChange,
      dispatch,
    ],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  return (
    <div>
      <HeaderV2 />
      <S.Root
        $uploadedBackgroundImage={uploadedImage?.background}
        $uploadedBackgroundImageDesktop={uploadedImage?.backgroundDesktop}
      >
        {stickerText && (
          <S.Sticker $uploadedSticker={uploadedImage.sticker}>
            <p>{stickerText}</p>
          </S.Sticker>
        )}
        <S.Container>
          <S.Subtitle>{subtitleToShow}</S.Subtitle>
          <S.Title>{titleToShow}</S.Title>
          <S.ActionCall>{t`onboarding.mainGoal.question`}</S.ActionCall>
          <S.Arrow ref={animationRef} />
          <S.Actions>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={MAIN_GOALS.STAY_FIT}
              disabled={isAnswersDisabled}
              $isChecked={answer === MAIN_GOALS.STAY_FIT}
            >
              <S.Action>{t`onboarding.mainGoal.stayFit`}</S.Action>
            </S.StyledOption>
            {!isClearCohort && (
              <S.StyledOption
                {...optionProps}
                withoutMargin
                value={MAIN_GOALS.LOSE_WEIGHT}
                disabled={isAnswersDisabled}
                $isChecked={answer === MAIN_GOALS.LOSE_WEIGHT}
              >
                <S.Action>{t`onboarding.mainGoal.loseWeight`}</S.Action>
              </S.StyledOption>
            )}

            {isClearCohort && (
              <S.StyledOption
                {...optionProps}
                withoutMargin
                value={MAIN_GOALS.GET_IN_SHAPE}
                disabled={isAnswersDisabled}
                $isChecked={answer === MAIN_GOALS.GET_IN_SHAPE}
              >
                <S.Action>{t`onboarding.mainGoal.getInShape`}</S.Action>
              </S.StyledOption>
            )}
          </S.Actions>
        </S.Container>
        <S.Terms>
          <p>{t`footer.disclaimer`}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
        <S.Filter />
      </S.Root>
    </div>
  )
}
