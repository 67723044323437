import curvyJa from 'assets/images/curvy-ja.png'
import curvyImgMenopause from 'assets/images/curvy-menopause.png'
import curvyImgAdult from 'assets/images/dream-body-curvy-adult.png'
import healthyImgAdult from 'assets/images/dream-body-healthy-adult.png'
import thinImgAdult from 'assets/images/dream-body-thin-adult.png'
import tonedImgAdult from 'assets/images/dream-body-toned-adult.png'
import curvyImgLegs from 'assets/images/flow-belly/dream-body-curvy-legs.png'
import curvyImg from 'assets/images/flow-belly/dream-body-curvy.png'
import healthyImgLegs from 'assets/images/flow-belly/dream-body-healthy-legs.png'
import healthyImg from 'assets/images/flow-belly/dream-body-healthy.png'
import thinImgLegs from 'assets/images/flow-belly/dream-body-thin-legs.png'
import thinImg from 'assets/images/flow-belly/dream-body-thin.png'
import tonedImgLegs from 'assets/images/flow-belly/dream-body-toned-legs.png'
import tonedImg from 'assets/images/flow-belly/dream-body-toned.png'
import healthyJa from 'assets/images/healthy-ja.png'
import healthyImgMenopause from 'assets/images/healthy-menopause.png'
import thinJa from 'assets/images/thin-ja.png'
import thinImgMenopause from 'assets/images/thin-menopause.png'
import tonedJa from 'assets/images/toned-ja.png'
import tonedImgMenopause from 'assets/images/toned-menopause.png'

export const QUESTION = 'What is your dream body?'

export const OPTION_VALUES = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinImg,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImg,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImg,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImg,
  },
]
export const OPTION_VALUES_MENOPAUSE = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinImgMenopause,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImgMenopause,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImgMenopause,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImgMenopause,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinJa,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedJa,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyJa,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyJa,
  },
]

export const OPTION_VALUES_LEGS = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinImgLegs,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImgLegs,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImgLegs,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImgLegs,
  },
]

export const OPTION_VALUES_ADULT = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinImgAdult,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImgAdult,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImgAdult,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImgAdult,
  },
]
