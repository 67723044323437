import { TimeInterval } from 'root-constants'

export function createIntroOfferProductName({
  periodName,
  trialPeriodQuantity,
  trialPrice,
  periodQuantity,
  price,
}: {
  trialPeriodQuantity: number
  trialPrice: number
  periodName: TimeInterval | null
  periodQuantity: number
  price: number
}): string {
  return `dancebit_${trialPeriodQuantity}_days_${trialPrice}_${periodQuantity}_${periodName}_${price}`
}
