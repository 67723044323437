import styled from 'styled-components'

import { UpgradeDisclaimer } from 'modules/purchase/components/UpgradeDisclaimer'

import backgroundImage from 'assets/images/upgrade-meal-plan-bg.png'

import { Color } from 'root-constants'

import { TrialSubscriptionItemInfo } from '../UpgradeSubscriptionItem/TrialSubscriptionItemInfo'

export const StyledUpgradeSubscriptionItemMealPlan = {
  UpgradePlanBlock: styled.div`
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
    margin: 32px 0 24px;
    border-radius: 24px;
  `,
  PlanTitle: styled.p`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 12px;
  `,
  AdditionalInfoContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  `,
  BonusContainer: styled.div`
    display: flex;
  `,
  FirstTitle: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding-right: 8px;
    color: ${Color.PRIMARY};
    display: flex;
    align-items: center;
  `,
  SecondTitle: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY};
    max-width: 130px;
  `,
  BonusPrice: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY};
  `,
  Period: styled.div`
    padding: 2px 12px;
    background: #eaf7ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    color: ${Color.PRIMARY};
  `,
  ButtonContainer: styled.div`
    margin-top: 12px;
  `,
  TrialSubscriptionItemInfo: styled(TrialSubscriptionItemInfo)`
    span {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  `,
  UpgradeDisclaimer: styled(UpgradeDisclaimer)`
    margin: 24px 0;
    padding-top: 0;
  `,
}
