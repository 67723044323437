import styled from 'styled-components'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledHealthProfile = {
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    height: calc(100% - 210px);
  `,
  Container: styled(Container)`
    margin-top: 16px;
  `,
  PageTitle: styled(PageTitle)`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  ButtonTitle: styled.span`
    color: ${Color.DISABLED};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
  `,
  SkipUpsell: styled.a`
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    margin-top: 16px;
    cursor: pointer;
  `,
}
