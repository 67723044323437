import beHealthierIconJa from 'assets/images/flow-belly/icons/be-healthier-ja.svg'
import beHealthierIcon from 'assets/images/flow-belly/icons/be-healthier.svg'
import feelConfidentIconJa from 'assets/images/flow-belly/icons/feel-confident-ja.svg'
import feelConfidentIcon from 'assets/images/flow-belly/icons/feel-confident.svg'
import getInShapeIconJa from 'assets/images/flow-belly/icons/get-in-shape-ja.svg'
import getInShapeIcon from 'assets/images/flow-belly/icons/get-in-shape.svg'
import otherIconJa from 'assets/images/flow-belly/icons/other-ja.svg'
import otherIcon from 'assets/images/flow-belly/icons/other.svg'

export const QUESTION = 'Whats your biggest motivation?'

export const OPTION_VALUES = [
  {
    title: 'onboarding.motivation.feelConfident',
    value: 'feelConfident',
    icon: feelConfidentIcon,
  },
  {
    title: 'onboarding.motivation.beHealthier',
    value: 'beHealthier',
    icon: beHealthierIcon,
  },
  {
    title: 'onboarding.motivation.getInShape',
    value: 'getInShape',
    icon: getInShapeIcon,
  },
  {
    title: 'onboarding.motivation.other',
    value: 'other',
    icon: otherIcon,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    title: 'onboarding.motivation.feelConfident',
    value: 'feelConfident',
    icon: feelConfidentIconJa,
  },
  {
    title: 'onboarding.motivation.beHealthier',
    value: 'beHealthier',
    icon: beHealthierIconJa,
  },
  {
    title: 'onboarding.motivation.getInShape',
    value: 'getInShape',
    icon: getInShapeIconJa,
  },
  {
    title: 'onboarding.motivation.other',
    value: 'other',
    icon: otherIconJa,
  },
]
