import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledJapaneseContainer = css`
  &::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      146deg,
      #ddecf9 21.61%,
      #fbf3ff 55.52%,
      #fae1fe 87.27%
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const StyledJapaneseBorder = css`
  border: 3px solid transparent;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #c6e2ff, #f8dafb);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  transition: none;
`

export const StyledTenMillionPeople = {
  Container: styled(Container)`
    ${StyledJapaneseContainer};
    position: relative;
  `,
  Title: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -58px;
    width: 100%;
  `,
  Image: styled.img`
    width: 342px;
    height: 96px;
  `,
  Text: styled.p`
    position: absolute;
    top: 18px;
    color: #fff9b7;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  `,
  Subtitle: styled.p`
    position: relative;
    color: #5173a6;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${Color.WHITE};
    paint-order: stroke fill;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 18px;

    &::before {
      position: absolute;
      content: '';
      background: #fff9b7;
      width: 262px;
      height: 12px;
      z-index: -1;
      left: 42px;
      top: 60%;
    }
  `,
  BubblesImg: styled.img`
    position: absolute;
    bottom: -64px;
    left: -24px;
    height: 100px;
  `,
  PictureContainer: styled.div`
    position: relative;
    margin: 0 auto 32px auto;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 327px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
}
