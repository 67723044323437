import styled from 'styled-components'

import { Color } from 'root-constants'

import { Timer } from '../Timer'

type TPersonalizedPlanProps = {
  $isTargetHidden: boolean
}

export const StyledPersonalizedPlan = {
  Wrapper: styled.div`
    margin-bottom: 50px;
    padding: 30px 25px;
    background: ${Color.DANGER_LIGHT};
    box-sizing: border-box;
    border-radius: 24px;
    text-align: center;
  `,
  PlanDescription: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    color: ${Color.DARK};
  `,
  Text: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.DANGER};

    &[data-is-target-hidden='true'] {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 50px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      z-index: 102;
      background: ${Color.DANGER_LIGHT};

      & div {
        font-weight: 700;
        font-size: 34px;

        & strong {
          font-weight: 700;
          font-size: 34px;
        }
      }
    }
  `,
  PersonalizedTimer: styled(Timer)<TPersonalizedPlanProps>`
    & {
      margin-left: ${({ $isTargetHidden }) => ($isTargetHidden ? '10px' : '0')};

      strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
    }
  `,
  TimerText: styled.span`
    padding-right: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Disclaimer: styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  `,
}
