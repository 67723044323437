import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IMPROVE_AREAS_VALUES } from 'pages/questions/ImproveAreas/constants'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Progress } from 'components/Progress'
import { Sale } from 'components/Sale'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressAnimation } from 'hooks/useProgressAnimation'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { Color, DynamicDiscountType, Gender, PagesSource } from 'root-constants'

import { StyledMainGoal as S } from './SayGoodbye.styles'
import { QUESTION } from './constants'

export const SayGoodbye: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const { isSaleFlow } = useCohortInfo()

  const { title, subtitle, imageUrls } = useDynamicOBConfig()
  const { headline, subtext } = useTitleFromUrl()

  const { handleChange, setIsAnswersDisabled } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextStep = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq &&
      window.fbq(
        'track',
        'CustomizeProduct',
        {},
        {
          eventID: isPersonalDataAllowed ? uuid : '',
        },
      )
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: QUESTION,
      answers: '',
    })

    dispatch(
      setAnswersAction({
        answers: Gender.FEMALE,
        pageId: PageId.GENDER,
      }),
    )
    dispatch(
      setAnswersAction({
        answers: [IMPROVE_AREAS_VALUES.BELLY],
        pageId: PageId.IMPROVE_AREAS,
      }),
    )

    handleChange('', handleContinue)
    setIsAnswersDisabled(true)
  }, [
    dispatch,
    handleChange,
    handleContinue,
    isPersonalDataAllowed,
    setIsAnswersDisabled,
    uuid,
  ])

  const { counter, progressAnimationPlayback } = useProgressAnimation({
    duration: 3000,
    callback: handleNextStep,
  })

  const { titleToShow, subtitleToShow } = useMemo(
    () => ({
      titleToShow: headline || title || t('onboarding.sayGoodbye.title'),
      subtitleToShow:
        subtext || subtitle || t('onboarding.sayGoodbye.personalizedPlan'),
    }),
    [t, headline, subtext, subtitle, title],
  )

  const uploadedImage = useMemo(() => {
    const [background, backgroundDesktop, sticker] = imageUrls || []

    return {
      background,
      backgroundDesktop,
      sticker,
    }
  }, [imageUrls])

  useEffect(() => {
    progressAnimationPlayback()
  }, [progressAnimationPlayback])

  return (
    <div>
      {isSaleFlow && (
        <S.TickerContainer>
          {dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}
          {dynamicDiscount?.type !== DynamicDiscountType.STATIC && <Ticker />}
        </S.TickerContainer>
      )}

      <S.Root
        $uploadedBackgroundImage={uploadedImage?.background}
        $uploadedBackgroundImageDesktop={uploadedImage?.backgroundDesktop}
      >
        <S.Container isLarge>
          <div>
            <S.Title>{titleToShow}</S.Title>
            <S.Subtitle>{subtitleToShow}</S.Subtitle>
          </div>

          <div>
            <S.ProgressWrapper>
              <Progress
                $background={Color.WHITE}
                color={Color.PRIMARY}
                value={counter}
                max={100}
              />
            </S.ProgressWrapper>
            <S.Terms>
              <p>{t`footer.disclaimer`}</p>
              <TermsOfUseLink source={PagesSource.LANDING} />
              <S.Separator />
              <PrivacyPolicyLink source={PagesSource.LANDING} />
              <p>
                {t('footer.allRightsReserved', {
                  year: new Date().getFullYear(),
                })}
              </p>
            </S.Terms>
          </div>
        </S.Container>
      </S.Root>
    </div>
  )
}
