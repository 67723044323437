import styled from 'styled-components'

import { Button } from 'components/Button'
import { StyledButtonBase } from 'components/Button/Button.styles'
import { Container } from 'components/Container'
import { GoalChartAge } from 'components/GoalChartAge'
import { LoseWeightGraphAge } from 'components/LoseWeightGraphAge'

import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import { PurchasePhoneSlider } from 'modules/purchase/components/PurchasePhoneSlider'

import { Color } from 'root-constants'

export const StyledPurchaseTwoPlans = {
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  GoalBlock: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
  `,
  GoalSuggestion: styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${Color.DISABLED};
    margin-bottom: 8px;
  `,
  Goal: styled.span`
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    color: ${Color.PRIMARY};
    text-align: center;
    margin-bottom: 8px;
  `,
  GoalDescription: styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  `,
  PurchasePhoneSlider: styled(PurchasePhoneSlider)`
    background: none;
  `,
  GraphContainer: styled.div`
    border-radius: 20px;
    background: ${Color.WHITE};
    box-shadow: 0 5px 20px 0 rgba(141, 144, 158, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  `,
  UserRecommended: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 15px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 92px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  GoalChartAge: styled(GoalChartAge)`
    width: 300px;
    height: 180px;
    min-height: 0;

    & div:nth-child(2) {
      top: 135px;
      left: 8px;
    }

    & div:nth-child(3) {
      top: 95px;
      left: 50px;
    }

    & div:nth-child(4) {
      top: 70px;
      left: 150px;
    }

    & div:nth-child(5) {
      top: 30px;
      left: 220px;
    }
  `,
  LoseWeightGraphAge: styled(LoseWeightGraphAge)`
    width: 300px;
    height: 180px;
    min-height: 0;

    & span:nth-child(5) {
      top: 140px;
      left: 245px;
    }
  `,
  DisclaimerBlock: styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e1e3ec;
    margin-bottom: 50px;
  `,
  AppsContainer: styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  `,
  App: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 24px;
    }
  `,
  AppLogo: styled.img`
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 6px;
  `,
  AppName: styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  RatingStars: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  RatingBlock: styled.div`
    display: flex;
    padding: 4px 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid ${Color.PRIMARY};
    background: #f4faff;
  `,
  RatingText: styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    & strong {
      color: ${Color.PRIMARY};
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  `,
  DownloadsCount: styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.DISABLED};
    margin: 16px 0;

    & strong {
      color: ${Color.DARK};

      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  `,
  PhotoResult: styled(PhotoResultV3)`
    margin: 40px auto 26px;
  `,
  PurchaseButton: styled(Button)`
    margin-bottom: 24px;
  `,
  AccordionTitle: styled.h3`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  `,
  Button: styled.button`
    width: 100%;
    ${StyledButtonBase};
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
