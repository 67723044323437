import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

export const StyledLessonLength = {
  TitleContainer: styled.div`
    max-width: 328px;
    margin: 0 auto;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 0 16px;
        ${StyledAgeFlowContainer};
      `}
  `,
  Subtitle: styled.h3`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
  `,
}
