import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AnswerWithCheckbox, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { MAIN_GOALS } from 'root-constants'

import { StyledWantToAchieve as S } from './WantToAchieve.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const WantToAchieve: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const { isAnswersDisabled } = useDelayedContinue()

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers,
    })

    dispatch(
      setAnswersAction({
        answers: answers.includes(MAIN_GOALS.LOSE_WEIGHT)
          ? answers
          : [...answers, MAIN_GOALS.LOSE_WEIGHT],
        pageId: PageId.MAIN_GOAL,
      }),
    )

    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }, [answers, dispatch, nextPagePath])

  return (
    <S.Root>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={8}>
            {t('onboarding.mainGoal.titleWithAge')}
          </PageTitle>

          <S.Subtitle>{t('onboarding.mainGoal.subtitleAgeFlow')}</S.Subtitle>

          {OPTION_VALUES.map(({ value, text }) => (
            <AnswerWithCheckbox
              {...optionProps}
              disabled={isAnswersDisabled}
              theme={answerWithIconTheme.DANCEBIT_ADULT}
              margin="0 0 16px 0"
              iconSrc={checkIcon}
              key={value}
              value={value}
            >
              <Trans>{text}</Trans>
            </AnswerWithCheckbox>
          ))}
        </Container>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </S.Root>
  )
}
