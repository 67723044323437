import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPersonalPlan = {
  Wrapper: styled.div`
    position: relative;
    height: 152px;
    margin: 0 auto 24px;
    background: linear-gradient(90deg, #fbafd6 2.03%, #e43991 99.97%);
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Content: styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 24px 8px 8px 32px;
    width: 100%;
  `,
  BgImage: styled.img`
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: 136px;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  DiscountText: styled.p`
    padding: 6px 13px;
    border-radius: 8px;
    background: #e43a91;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.WHITE};
    width: fit-content;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 20px;
    }
  `,
  Picture: styled.img`
    width: 121px;
    height: 121px;
  `,
}
