import styled from 'styled-components'

import corner from 'assets/images/review-corner.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReviews = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    margin-bottom: 0;
  `,
  Title: styled.div`
    margin-bottom: 15px;
    color: #e43a91;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  `,
  Comment: styled.div`
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 46px;
    padding: 16px;
    text-align: left;
    border-radius: 8px;
    background: ${Color.WHITE};
    border: 1px solid #ee6eb0;
    filter: drop-shadow(4px 4px 0px #ee6eb0);

    &::after {
      content: '';
      position: absolute;
      bottom: -23px;
      right: 32px;
      width: 38px;
      height: 23px;
      background: url(${corner}) no-repeat;
      background-size: 38px 23px;
    }
  `,
  Author: styled.p`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 85px;
    padding: 8px 0;

    svg {
      width: 14px;
      height: 14px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
  `,
}
