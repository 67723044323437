import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'

import { ButtonJa } from 'components/ButtonJa'

import { resetErrorAction } from 'root-redux/actions/common'
import { selectActionList } from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchase/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchase/helpers/rootHelpers'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import {
  PURCHASE,
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects/common'
import { TCreditCardField, TPaymentErrorState } from 'modules/purchase/types'

import { eventLogger } from 'services/eventLogger.service'

import tooltipInfoImage from 'assets/images/cvv-info.png'
import infoIcon from 'assets/images/information-icon.svg'
import paymentMethodImg from 'assets/images/payment-method.png'

import { CardPaymentFieldName, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import {
  StyledCreditCardFormCancelOfferDark as S,
  stripeElementStyle,
} from './CreditCardFormCancelOfferDark.styles'

type TProps = {
  isCheckoutVisible?: boolean
}

export const CreditCardFormCancelOfferDark: React.FC<TProps> = ({
  isCheckoutVisible = true,
}) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const fetchingActionsList = useSelector(selectActionList)

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const { isBellyFlow } = useCohortInfo()

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    if (isCheckoutVisible) {
      cardNumberElemRef.current?.focus()
    } else {
      cardNumberElemRef.current?.blur()

      elements?.getElement(CardNumberElement)?.clear()
      elements?.getElement(CardCvcElement)?.clear()
      elements?.getElement(CardExpiryElement)?.clear()

      if (cardholderNameElemRef.current) {
        cardholderNameElemRef.current.value = ''
      }
    }
  }, [elements, isCheckoutVisible])

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: {
          isTouched: true,
          error,
          isComplete,
        },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (event) => {
      if (fetchingActionsList.includes(PURCHASE) || isButtonDisabled) return
      setIsButtonDisabled(true)

      event.preventDefault()
      dispatch(resetErrorAction())

      eventLogger.logPaymentMethodSelected(PaymentMethod.CREDIT_CARD)
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        setIsButtonDisabled(false)
        return
      }

      if (hasErrors) {
        setIsButtonDisabled(false)
        return
      }

      const card = elements?.getElement(CardNumberElement)
      const currentPageId = getPageIdFromPathName(pathname)

      if (!stripe || !card || !currentPageId) {
        setIsButtonDisabled(false)
        return
      }

      dispatch(
        purchaseAction({
          stripe,
          card,
          name: '',
        }),
      )
      setIsButtonDisabled(false)
    },
    [
      fetchingActionsList,
      isButtonDisabled,
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      pathname,
      stripe,
      errors,
    ],
  )

  return (
    <>
      <S.PaymentMethodImageContainer>
        <S.PaymentMethodImage src={paymentMethodImg} />
      </S.PaymentMethodImageContainer>
      <S.Form onSubmit={handleSubmit}>
        <S.Wrapper>
          <S.InputContainer>
            <S.CardContainer
              data-placeholder={t`purchase1.paymentForm.cardNumber`}
            >
              <S.CardNumberElement
                onReady={(elem) => {
                  cardNumberElemRef.current = elem
                }}
                options={{
                  showIcon: false,
                  placeholder: t`purchase1.paymentForm.cardNumberHidden`,
                  style: stripeElementStyle,
                  disableLink: true,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.NUMBER,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cardExpiryElemRef,
                  })
                }}
              />
            </S.CardContainer>

            <S.CvvExpiryInputContainer>
              <S.CardExpiryContainer>
                <S.CardContainer
                  data-placeholder={t`purchase1.paymentForm.expiryDate`}
                >
                  <S.CardExpiryElement
                    onReady={(elem) => {
                      cardExpiryElemRef.current = elem
                    }}
                    options={{
                      placeholder: t`purchase1.paymentForm.cardExpiryPlaceholder`,
                      style: stripeElementStyle,
                    }}
                    onChange={({ empty, error, complete }) => {
                      handleChange({
                        fieldName: CardPaymentFieldName.EXPIRY,
                        isEmpty: empty,
                        hasError: !!error,
                        isComplete: complete,
                        nextElemRef: cvcElemRef,
                      })
                    }}
                  />
                </S.CardContainer>
              </S.CardExpiryContainer>

              <S.CardCvcContainer>
                <S.CardContainer
                  data-placeholder={t`purchase1.paymentForm.cvv`}
                >
                  {!errors[CardPaymentFieldName.CVC].error &&
                    !errors[CardPaymentFieldName.CVC].isFocused && (
                      <S.InfoIcon
                        src={infoIcon}
                        onClick={() => setIsTooltipVisible((prev) => !prev)}
                        alt="info"
                      />
                    )}
                  {isTooltipVisible && (
                    <S.TooltipContainer>
                      <S.Tooltip src={tooltipInfoImage} />
                    </S.TooltipContainer>
                  )}

                  <S.CardCvcElement
                    $isBellyFlow={isBellyFlow}
                    onReady={(elem) => {
                      cvcElemRef.current = elem
                    }}
                    options={{
                      placeholder: t`purchaseCancelOffer.checkout.securityNumber`,
                      style: stripeElementStyle,
                    }}
                    onChange={({ empty, error, complete }) => {
                      handleChange({
                        fieldName: CardPaymentFieldName.CVC,
                        isEmpty: empty,
                        hasError: !!error,
                        isComplete: complete,
                        nextElemRef: cardholderNameElemRef,
                      })
                    }}
                  />
                </S.CardContainer>
              </S.CardCvcContainer>
            </S.CvvExpiryInputContainer>
          </S.InputContainer>
        </S.Wrapper>
        {isJapaneseFlow ? (
          <ButtonJa
            type="submit"
            disabled={!stripe || !isFormValid || isButtonDisabled}
          >
            {t`actions.continue`}
          </ButtonJa>
        ) : (
          <S.Button
            type="submit"
            disabled={!stripe || !isFormValid || isButtonDisabled}
            disabledBackgroundColor="rgba(205, 206, 209, 0.85)"
          >
            {t`actions.continue`}
          </S.Button>
        )}
      </S.Form>
    </>
  )
}
