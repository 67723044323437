import styled from 'styled-components'

import { Color, MINIMUM_HEIGHT_BREAKPOINT } from 'root-constants'

export const StyledWorldwideAchieving = {
  Root: styled.div`
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 17.1%,
          rgba(255, 255, 255, 0.66) 32.55%,
          rgba(255, 255, 255, 0.79) 40.4%,
          rgba(255, 255, 255, 0.87) 45.39%,
          ${Color.WHITE} 53.95%
        ),
        linear-gradient(90deg, #4facfe 0%, #00f2fe 113.07%), ${Color.WHITE};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `,
  LogoContainer: styled.div`
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
  `,
  BoxLogo: styled.img`
    max-width: 117px;
  `,
  MapImg: styled.img`
    width: 300px;
    min-height: 180px;
    margin: 0 auto 24px;
  `,
  UsersBlock: styled.div`
    margin: 0 auto 24px;
    max-width: 185px;
    min-height: 51px;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 20px 0 rgba(125, 173, 208, 0.2);
  `,
  Description: styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;

    @media (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      margin-bottom: 48px;
    }
  `,
  ReachedGoals: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Avenir, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    color: ${Color.PRIMARY};
    min-height: 42px;

    & img:last-child {
      transform: scale(-1, 1);
    }
  `,
  Branch: styled.img``,
}
