import React from 'react'

import { StyledContainer as S } from './ContainerBeforePinnedButton.styles'

type TProps = {
  className?: string
  children?: React.ReactNode
}

export const ContainerBeforePinnedButton: React.FC<TProps> = ({
  children,
  className = '',
}) => <S.Container className={className}>{children}</S.Container>
