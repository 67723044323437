import styled, { css } from 'styled-components'

import scrollMark from 'assets/images/srcoll-mark.svg'

export const StyledSelect = {
  Slider: styled.div<{ $isHorizontal: boolean }>`
    position: relative;

    canvas {
      height: 100%;
      width: 84px;

      ${({ $isHorizontal }) =>
        $isHorizontal &&
        css`
          object-fit: contain;
          width: 100%;
          height: 114px;

          & + input + div {
            top: -16px !important;
          }
        `}
    }
  `,
  Cursor: styled.div<{ $isHorizontal: boolean }>`
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    transform: rotate(90deg);
    background-image: url(${scrollMark});
    background-color: transparent;

    ${({ $isHorizontal }) =>
      $isHorizontal &&
      css`
        width: 8px;
        transform: none;
      `}
  `,
}
