import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledPageTitle = {
  PageTitle: styled.h1<{
    $marginBottom?: number
    $marginTop?: number
    $isAgeFlow?: boolean
    $highlightColor?: string
  }>`
    font-weight: ${({ $isAgeFlow }) => ($isAgeFlow ? 800 : 700)};
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom && `${$marginBottom}px`};
    margin-top: ${({ $marginTop }) => $marginTop && `${$marginTop}px`};

    ${({ $highlightColor }) => css`
      & strong {
        color: ${$highlightColor};
      }
    `}
  `,
}
