import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseUpgrade = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 24px 16px 32px;
  `,
  SaveBigTitle: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 16px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 16px;

    strong {
      color: ${Color.PRIMARY};
      font-weight: 800;
    }
  `,
  GraphContainer: styled.div`
    position: relative;
    border-radius: 16px;
    padding: 24px 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e1e3ec;
    margin-bottom: 40px;

    img {
      min-height: 234px;
    }
  `,
  GraphTitle: styled.h3`
    color: ${Color.DARK};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
  `,
  PriceOld: styled.span`
    position: absolute;
    color: ${Color.PRIMARY};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    width: 100px;
    text-align: center;
    left: 52px;
    top: 144px;
  `,
  PriceNow: styled.span`
    position: absolute;
    color: ${Color.WHITE};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    width: 100px;
    text-align: center;
    right: 52px;
    bottom: 112px;
  `,
  GraphText: styled.div`
    display: flex;
    position: absolute;
    left: 46px;
    bottom: 24px;

    p {
      color: ${Color.GRAYISH_BLUE};
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-right: 36px;
      width: 96px;
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #4b5563;
    text-align: center;
    margin-bottom: 24px;
  `,
  SkipBtn: styled.a`
    display: block;
    color: #81848c;
    margin-top: 24px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
  `,
  HighlightedPrice: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    color: ${Color.PRIMARY};
    font-weight: 600;
  `,
  TotalPrice: styled.div`
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Price: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Disclaimer: styled.div`
    border-radius: 16px;
    margin: 24px 0;
    padding: 16px;
    background: #eaf7ff;
    color: #4b5563;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    a {
      color: #4b5563;
      text-decoration: underline;
    }
  `,
  SubscriptionDetails: styled.div`
    padding-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #d5d7d9;
    border-bottom: 1px solid #d5d7d9;
  `,
}
