import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

export const BestShapeStyles = {
  Container: styled(Container)<{
    $bgImageUrl: string
    $isJapanese: boolean
    $isAdult: boolean
  }>`
    min-height: 560px;
    background-image: ${({ $bgImageUrl }) => `url(${$bgImageUrl})`};
    background-size: 375px 485px;
    background-repeat: no-repeat;
    background-position: bottom;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
}
