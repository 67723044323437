import React, { FC, useRef } from 'react'
import { Trans } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useIsInViewport } from 'hooks/useIsInViewport'

import { BENEFITS } from 'modules/purchase/components/ReceivedPlanNew/constants'

import { StyledReceivedPlan as S } from './ReceivedPlanNew.styles'

type TProps = {
  className?: string
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
}
export const ReceivedPlanNew: FC<TProps> = ({
  className = '',
  elemForComparisonRef,
}) => {
  const titleRef = useRef(null)
  const { benefitsBlockTitle } = useDynamicPaywallConfig()
  const isVisible = useIsInViewport(titleRef)

  return (
    <>
      <S.ProgramPlanTitle $isVisible={isVisible} ref={titleRef}>
        {benefitsBlockTitle || (
          <Trans
            i18nKey="purchase1.programDescriptionTitleNew"
            components={{
              div: <div />,
            }}
          />
        )}
      </S.ProgramPlanTitle>
      <S.ProgramPlanContainer className={className} ref={elemForComparisonRef}>
        <ul>
          {BENEFITS.map(({ id, text, icon }) => (
            <S.ListItem key={id}>
              <SvgImage svg={icon} width={32} height={32} />
              <S.Text>
                <Trans i18nKey={text} />
              </S.Text>
            </S.ListItem>
          ))}
        </ul>
      </S.ProgramPlanContainer>{' '}
    </>
  )
}
