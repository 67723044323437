import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow, selectUserAge } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FAQList } from 'modules/purchase/components/FAQList'
import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { IntroOfferDisclaimerV2 } from 'modules/purchase/components/IntroOfferDisclaimerV2'
import { PersonalPlan } from 'modules/purchase/components/PersonalPlan'
import {
  SLIDES_FEMALE,
  SLIDES_FEMALE_CLEAR,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { PromoCodeWithTimer } from 'modules/purchase/components/PromoCodeWithTimer'
import { Reviews } from 'modules/purchase/components/Reviews'
import { ReviewsCancelOffer } from 'modules/purchase/components/ReviewsCancelOffer'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'
import { SubscriptionsBlockIntroOfferAdult } from 'modules/purchase/components/SubscriptionsBlockIntroOfferAdult'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { PageId } from 'page-constants'
import {
  ABC_TEST_VARIANTS,
  GROWTHBOOK_EXPERIMENT,
  Locale,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutModal } from '../CheckoutModal'
import { StyledPurchaseCancelOfferRef as S } from './PurchaseCancelOfferRef.styles'

export const PurchaseCancelOfferRef: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isStayFit = useSelector(selectIsStayFitFlow)
  const age = useSelector(selectUserAge)

  const {
    currentMeasurementSystem,
    fetchingActionsList,
    subscriptions,
    userAnswers,
    selectedSubscriptionId,
    language,
  } = usePurchaseStore()

  const {
    subtitle,
    benefits,
    benefitsBlockTitle,
    buttonText,
  } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenId: ScreenId.CANCEL_DEFAULT,
    isAmplitudeEvent: true,
  })

  const hasIncludedVat = useVatInfo()
  const { isTTClearFlow, isMenopauseFlow, isNewAdultFlow } = useCohortInfo()
  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)
  const hasCancelOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const hasIncreasedPricesEn = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1294)
  const isCheckoutModalTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1291)
  const hasIncreasedPricesLocales = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1295,
  )
  const variantOfModalCheckout = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_1291,
    ABC_TEST_VARIANTS.VARIANT_A,
  )

  const isFitMePrices = language === Locale.ENGLISH || hasIncreasedPricesLocales

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.CANCEL_OFFER, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const isVisible = useMemo(() => {
    if (isCheckoutModalTest) {
      return (
        !isCheckoutShown ||
        (isCheckoutShown &&
          variantOfModalCheckout !== ABC_TEST_VARIANTS.VARIANT_A)
      )
    }
    return !isCheckoutShown
  }, [isCheckoutModalTest, isCheckoutShown, variantOfModalCheckout])

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasIncreasedPricesEn) {
      pricesTags = `${SubscriptionTags.INCREASED_INTRO},${pricesTags}`
    }

    if (isFitMePrices && !isNewAdultFlow) {
      pricesTags = `${SubscriptionTags.INCREASED},${pricesTags}`
    }

    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))
    dispatch(setScreenIdAction(ScreenId.CANCEL_DEFAULT))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
      ),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setScreenNameAction(null))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [
    isNewAdultFlow,
    age,
    dispatch,
    hasIncreasedPricesEn,
    hasHighPrices,
    hasIncludedVat,
    isFitMePrices,
  ])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.CANCEL_OFFER,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {isVisible && (
        <>
          <PersonalPlan onButtonClick={handleShowPayment} />
          <S.Container isLarge>
            <S.AppDescription>
              {subtitle || (
                <Trans
                  i18nKey="purchaseCancelOffer.appDescription"
                  components={[<strong />, <br />]}
                />
              )}
            </S.AppDescription>

            {isNewAdultFlow ? (
              <>
                <S.Title>{t`purchase1.subscription.title`}</S.Title>
                <PromoCodeWithTimer />
                <SubscriptionsBlockIntroOfferAdult>
                  <S.Button
                    type="button"
                    data-order="1"
                    data-text="get_my_plan"
                    onClick={handleShowPayment}
                  >
                    {buttonText || t`actions.getMyPlan`}
                  </S.Button>
                </SubscriptionsBlockIntroOfferAdult>
              </>
            ) : (
              <SubscriptionsBlockIntroOffer
                hasInnerDisclaimer={false}
                isCancelOffer
              >
                <S.Button
                  data-order={hasCancelOfferTest ? '2' : '1'}
                  data-text="get_my_plan"
                  type="button"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </SubscriptionsBlockIntroOffer>
            )}

            <S.RefundCardSmall>
              <Trans i18nKey="purchaseRef.getRefund" />
            </S.RefundCardSmall>

            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || t`purchaseCancelOffer.appBenefits.title`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ text, id }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey={
                          isTTClearFlow
                            ? 'purchaseCancelOffer.appBenefits.targetWeightClear'
                            : 'purchaseCancelOffer.appBenefits.targetWeight'
                        }
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                          weight: userAnswers?.[PageId.GOAL_WEIGHT],
                          unit: t(
                            `commonComponents.${currentMeasurementSystem}`,
                          ),
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.sustainSuccess" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.moreEnergy" />
                    </S.ListItem>
                    {!isStayFit && (
                      <S.ListItem>
                        <Trans i18nKey="purchaseCancelOffer.appBenefits.betterHealth" />
                      </S.ListItem>
                    )}
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.motivation" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.happierYou" />
                    </S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
          </S.Container>

          <HowItWorks
            buttonOrder={hasCancelOfferTest ? 3 : 2}
            onButtonClick={handleShowPayment}
          />

          <S.Container>
            <S.PhotoResultV3
              femaleSlides={isTTClearFlow ? SLIDES_FEMALE_CLEAR : SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <FAQList />
          </S.Container>
          {isMenopauseFlow ? (
            <ReviewsCancelOffer>
              <Button
                data-order={hasCancelOfferTest ? '4' : '3'}
                data-text="get_my_plan"
                type="button"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </ReviewsCancelOffer>
          ) : (
            <S.Container>
              <Reviews />
              <Button
                type="button"
                data-order={hasCancelOfferTest ? '4' : '3'}
                data-text="get_my_plan"
                margin="24px auto 50px"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </S.Container>
          )}
          <S.Container isLarge>
            <S.FeaturedBlockContainer>
              <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
              <picture>
                <source srcSet={featuredImageWebp} type="image/webp" />
                <img src={featuredImagePng} alt="body" />
              </picture>
            </S.FeaturedBlockContainer>
            {isNewAdultFlow ? (
              <IntroOfferDisclaimerV2 />
            ) : (
              <IntroOfferDisclaimer />
            )}
          </S.Container>
        </>
      )}
      <>
        {variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_A ? (
          <S.CheckoutContainer $isVisible={isCheckoutShown}>
            <CheckoutSeparateMethods
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
              isCancelOfferPage
              key={selectedSubscriptionId}
            />
          </S.CheckoutContainer>
        ) : (
          <S.CheckoutContainer $isVisible={isCheckoutShown}>
            <CheckoutModal
              key={selectedSubscriptionId}
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
              isCancelOfferPage
            />
          </S.CheckoutContainer>
        )}
      </>
    </>
  )
}
