// eslint-disable-line max-lines
import { PageId } from './pages'

export const LIVE_CHAT_PUBLIC_KEY = '10826272'
export const PAID_USER_GROUP_ID = '29'
export const FREE_USER_GROUP_ID = '49'
export const PAID_USER_ES_GROUP_ID = '86'
export const PAID_USER_DE_GROUP_ID = '112'
export const DYNAMIC_PAGE_ID_PREFIX = 'dynamic'

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const ANSWER_DATE_FORMAT = 'DD MMM YYYY'
export const DISPLAYING_DATE_FORMAT = 'dd MMM yyyy'

export const MINIMUM_HEIGHT_BREAKPOINT = 640

export const TERMS_OF_USE_LINK = 'https://www.dancebit.dance/terms-of-use'
export const PRIVACY_POLICY_LINK = 'https://www.dancebit.dance/privacy-policy'
export const ACCOUNT_LINK = 'https://account.dance-bit.com/login?source=paywall'
export const SUPPORT_LINK = 'https://account.dance-bit.com/contact-form'

export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu
export const FLOAT_NUMBER_REGEX = /^([1-9]+[0-9]*|0{1})([.,][0-9]{0,1})?$/
export const NUMERIC_KEYPAD_REGEX = /([0-9]{1}|Backspace|Enter)/
export const NUMERIC_KEYPAD_FLOAT_REGEX = /([0-9.,]{1}|Backspace|Enter)/
export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/9a07f2a1d985810c1d7b2b85277a6396bb53c1857ab1f6fa291cac53260b1e21'
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const EMAIL_FROM_URL_REGEXP = /email=([^&]*)/
export const INTEGER_NUMBER_REGEX = /^[1-9]+[0-9]*$/
export const MIN_PASSWORD_LENGTH = 8
export const MIN_NAME_LENGTH = 1
export const MAXIMUM_WEIGHT_LOSS_PERCENTAGE = 0.00143

export const GOAL_WEIGHT_ANIMATION_PATH = '/animations/welcomeScreenGoalWeight'
export const BELLY_ANIMATION_PATH = '/animations/bellyFlowGraph'

export const INCHES_IN_FOOT = 12
export const INITIAL_INCHES_VALUE = 0
export const MAX_HEIGHT_INCHES = 95

export const enum LoginMethod {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum Color {
  WHITE = '#fff',
  BLACK = '#000',
  GRAY = '#bfbfbf',
  LIGHT_GREY = '#848993',
  GRAY_10 = '#f2f3f5',
  GRAY_20 = '#e9e9e9',
  GRAY_40 = '#cacaca',
  GRAY_60 = '#a9a9a9',
  GRAY_70 = '#4c4c4c',
  GRAY_80 = '#626262',
  GRAY_100 = '#2c3131',
  LAVENDER_LIGHT = '#f7f9fc',
  DARK = 'var(--primary-dark-color, #131d30)',
  DARK_RED = '#f83b00',
  PRIMARY = '#03aaf5',
  PRIMARY_LIGHT = '#81d4fa',
  GRAYISH_BLUE = '#6b7280',
  SUCCESS = '#7ad718',
  ERROR = '#ff6169',
  ORANGE = '#ffc530',
  LIGHT = '#f5f6f7',
  GREEN = '#42b33b',
  DANGER = '#eb3b26',
  DANGER_LIGHT = '#fdebe9',
  RED = '#e65c3e',
  DISABLED = '#898e97',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum Cohort {
  DANCEBIT_FLOW_1 = 'dancebit_flow1',
  DANCEBIT_EMAIL_1 = 'dancebit_email_1',
  DANCEBIT_EMAIL_2 = 'dancebit_email_2',
  DANCEBIT_EMAIL_3 = 'dancebit_email_3',
  DANCEBIT_20_30_40 = 'dancebit_20_30_40',
  DANCEBIT_CLEAR_FLOW = 'dancebit_clear_flow',
  DB_INTRO = 'db_intro',
  DB_INTRO_FAST = 'db_intro_fast',
  DB_INTRO_2 = 'db_intro2',
  DB_INTRO_DOP = 'db_intro_dop',
  DB_FLOW_3D = 'db_flow_3d',
  DB_FLOW_7D = 'db_flow_7d',
  DANCEBIT_SHORT = 'dancebit_short',
  DANCEBIT_CHAT_UPGRADE_12 = 'dancebit_chat_upgrade12',
  DANCEBIT_CHAT_UPGRADE_12_PDF = 'dancebit_chat_upgrade12pdf',
  DANCEBIT_CHAT_UPGRADE_12_MEAL = 'dancebit_chat_upgrade12_mealplan',
  DB_FLOW_2 = 'db_flow2',
  DB_CLEAR_INTRO_FAST = 'db_clear_intro_fast',
  DB_INTRO_FAST_18 = 'db_intro_fast18',
  DB_INTRO_FAST_40 = 'db_intro_fast40',
  DB_ADULT = 'db_adult',
  DB_ADULT_18 = 'db_adult_18',
  DB_ADULT_MX = 'db_adult_mx',
  DB_ADULT_2 = 'db_adult2',
  DB_ADULT_VIDEO = 'db_adult_video',
  DB_ADULT_WEEK = 'db_adult_week',
  DB_ADULT_SALE = 'db_adult_sale',
  GLORIA_INTRO_FAST = 'gloria_intro_fast',
  YARINAS = 'yarinas',
  EMAIL_INTRO_FAST = 'email_intro_fast',
  CANCEL_INTRO_FAST = 'cancel_intro_fast',
  COHORT_2024 = '2024',
  SOMATIC_GOAL = 'somatic_goal',
  SOMATIC_AGE = 'somatic_age',
  DB_TWO_PLANS = 'db_2plans',
  DB_BELLY = 'db_belly',
  DB_SOMATIC = 'db_somatic',
  DB_BELLY_SALE = 'db_belly_sale',
  DB_LEGS = 'db_legs',
  DB_REF = 'db_ref',
  DB_INFLUENCER = 'db_influencer',
  DB_BELLY_REF = 'db_belly_ref',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  CHECKOUT = 'checkout',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum LiveChatStatus {
  MAXIMIZED = 'maximized',
  MINIMIZED = 'minimized',
  HIDDEN = 'hidden',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  UPGRADE = 'upgrade',
}

export const enum PagesSource {
  PAYWALL = 'paywall',
  LANDING = 'landing',
  EMAIL = 'email_page',
  LOGIN = 'account_creating_page',
  DOWNLOAD = 'download_page',
}

export const enum SubscriptionTags {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  MEAL_PLAN = 'meal_plan',
  AGE_40_70 = 'age_40_70',
  STAY_FIT = 'stay_fit',
  LOSE_WEIGHT = 'lose_weight',
  NEW_UPSELL = 'new_upsell',
  PAID_UPSELL = 'paid',
  UPSELL_LONG = 'upsell_long',
}

export const enum AvailableAttempt {
  NO_ATTEMPTS,
  ONE_ATTEMPT,
  TWO_ATTEMPTS,
  THREE_ATTEMPTS,
}

export const enum PlanAddition {
  MEAL_PLAN = 'Meal plan',
  UPGRADE_MEAL_PLAN = 'Upgrade meal plan',
  PREMIUM = 'Premium',
  UPSELL = 'Upsell',
  UPGRADE = 'Upgrade',
  CROSSELL = 'crossell',
  BONUS = 'Bonus',
  FREE_OFFER = 'Free offer',
}

export const APP_NAME = 'dancebit'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DATES_NUMBER_FOR_ANIMATION = 8
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const DEFAULT_CURRENCY = 'usd'
export const PAGE_TRANSITION_DELAY = 500
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const SNAPCHAT_QUERY_PARAM = 'is_snapchat_payment'
export const LANG_QUERY_PARAM = 'lang'
export const COHORT_QUERY_PARAM = 'cohort'
export const EMAIL_QUERY_PARAM = 'email'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXTRA_OFFSET_FOR_ANIMATION_SMOOTH_TRIGGER = 150
export const MIN_AGE = 16
export const MAX_AGE = 100
export const DEFAULT_MODAL_TIMEOUT = 3000
export const EXTENDED_MODAL_TIMEOUT = 4000

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
}

export const CLIENT_SUBSCRIPTION_STATUSES: Record<
  SubscriptionStatus,
  string
> = {
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.TRIAL]: 'In Trial',
  [SubscriptionStatus.CANCELLED]: 'Cancelled',
  [SubscriptionStatus.NOT_ACTIVE]: 'Not Active',
}

export const enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const LOST_WEIGHT_PER_WEEK = {
  [MeasurementSystem.METRIC]: 1.2,
  [MeasurementSystem.IMPERIAL]: 2.6,
}

export const enum Locale {
  ENGLISH = 'en',
  FRENCH = 'fr',
  SPANISH = 'es',
  DEUTSCH = 'de',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  IN_CONTEXT = 'af',
}

export const INITIAL_LANGUAGE = Locale.ENGLISH

export const LOST_WEIGHT: Record<MeasurementSystem, string> = {
  [MeasurementSystem.METRIC]: '5',
  [MeasurementSystem.IMPERIAL]: '11',
}

export const COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM: string[] = [
  'AU',
  'CA',
  'GB',
  'NZ',
  'US',
  'ZA',
]

export const POST_PAYMENT_PAGES: string[] = [
  PageId.UPSELL,
  PageId.UPSELL_CANCEL_OFFER,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const SKIPPED_ONBOARDING_COHORTS: string[] = [Cohort.DANCEBIT_SHORT]

export const CUSTOMER_REVIEWS = [
  {
    author: 'purchaseCancelOffer.customerReviews.firstAuthor',
    review: 'purchaseCancelOffer.customerReviews.firstReview',
  },
  {
    author: 'purchaseCancelOffer.customerReviews.secondAuthor',
    review: 'purchaseCancelOffer.customerReviews.secondReview',
  },
  {
    author: 'purchaseCancelOffer.customerReviews.thirdAuthor',
    review: 'purchaseCancelOffer.customerReviews.thirdReview',
  },
]

export const enum GenderStepOption {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum ImproveAreasStepOption {
  ARMS = 'arms',
  CHEST = 'chest',
  BELLY = 'belly',
  HIPS = 'hips',
  LEGS = 'legs',
  BUTTOCKS = 'buttocks',
}

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  CANCEL_OFFER_MEALPLAN = 'cancel_offer_mealplan',
  EMAIL_OFFER = 'email_offer',
  UPSELL_ONE_PLAN = 'upsell_1_plan',
  UPSELL_TOGGLE = 'upsell_switcher',
  SHORT_FLOW = 'short_flow',
  LIVECHAT_UPGRADE = 'livechat_upgrade',
  CHECKOUT = 'checkout',
  PURCHASE_NO_INTRO = 'purchase_no_intro',
  UPGRADE_PLAN = 'upgrade_plan',
  UPSELL_LONG = 'upsell_long',
  ADDITIONAL_OFFER = 'additional_offer',
}

export const enum ScreenId {
  BEFORE_AFTER_PHOTOS = 'before_after_photos',
  CANCEL_DEFAULT = 'cancel_default',
  CANCEL_LEGS = 'cancel_legs',
  PAYWALL_LEGS = 'paywall_ugly_legs',
  TWO_PLANS = '2plans',
  BETTERME = 'betterme',
  OLD = 'old',
  CLEAR = 'clear',
  CANCEL_MEALPLAN = 'cancel_mealplan',
  EMAIL = 'email',
  YARINAS = 'yarinas',
  SHORT_SEVEN_DAYS = 'short_7d',
  BEFORE_AFTER_FLOW2 = 'before_after_flow2',
  UPGRADE = 'upgrade',
  UPGRADE_PDF = 'upgrade_pdf',
  UPGRADE_MEAL_PLAN = 'upgrade_mealplan',
  ADDITIONAL_OFFER = 'additional_offer',
  UPSELL_FIRST_PLAN = 'upsell_1_plan',
  UPSELL_SWITCHER = 'upsell_switcher',
  UPSELL_LONG = 'upsell_long',
}

export const PLAN_ADDITIONS_MAP: Record<string, PlanAddition[]> = {
  [PageId.PURCHASE]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_EMAIL]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_NO_ONBOARDING]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_INTRO_OFFER]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_CANCEL_OFFER_FREE]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_CANCEL_OFFER]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_CANCEL_OFFER_SOMATIC]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN]: [
    PlanAddition.PREMIUM,
    PlanAddition.MEAL_PLAN,
  ],
  [PageId.PURCHASE_TRIAL]: [PlanAddition.PREMIUM],
  [PageId.UPSELL]: [PlanAddition.UPSELL],
  [PageId.UPSELL_CANCEL_OFFER]: [PlanAddition.UPSELL],
  [PageId.PURCHASE_UPGRADE_PDF]: [PlanAddition.BONUS, PlanAddition.UPGRADE],
  [PageId.PURCHASE_UPGRADE]: [PlanAddition.UPGRADE],
  [PageId.PURCHASE_DOP]: [PlanAddition.UPGRADE],
  [PageId.PURCHASE_UPGRADE_MEAL_PLAN]: [
    PlanAddition.UPGRADE,
    PlanAddition.UPGRADE_MEAL_PLAN,
  ],
}

export const MK_EMAIL_COHORTS: Cohort[] = [
  Cohort.EMAIL_INTRO_FAST,
  Cohort.CANCEL_INTRO_FAST,
]

export const LIVECHAT_UPGRADE_COHORTS: Cohort[] = [
  Cohort.DANCEBIT_CHAT_UPGRADE_12,
  Cohort.DANCEBIT_CHAT_UPGRADE_12_PDF,
  Cohort.DANCEBIT_CHAT_UPGRADE_12_MEAL,
]

export const LIVECHAT_ENABLED_COHORTS: { [K in Locale]?: Cohort[] } = {
  [Locale.ENGLISH]: [
    Cohort.DANCEBIT_FLOW_1,
    Cohort.DANCEBIT_EMAIL_1,
    Cohort.DB_INTRO_FAST,
    Cohort.DB_INTRO_2,
    Cohort.DB_INTRO_DOP,
    Cohort.DB_INTRO,
    Cohort.DB_FLOW_7D,
    Cohort.DB_CLEAR_INTRO_FAST,
    Cohort.DB_INTRO_FAST_18,
    Cohort.DB_INTRO_FAST_40,
    Cohort.DB_ADULT,
    Cohort.DB_ADULT_18,
    Cohort.DB_ADULT_2,
    Cohort.DB_ADULT_VIDEO,
    Cohort.DB_ADULT_WEEK,
    Cohort.GLORIA_INTRO_FAST,
    Cohort.YARINAS,
    Cohort.COHORT_2024,
    Cohort.SOMATIC_GOAL,
    Cohort.SOMATIC_AGE,
    Cohort.DB_TWO_PLANS,
    Cohort.DB_BELLY,
    Cohort.DB_BELLY_REF,
    Cohort.DB_SOMATIC,
    Cohort.DB_LEGS,
    Cohort.DB_REF,
  ],
  [Locale.SPANISH]: [
    Cohort.DB_INTRO_FAST,
    Cohort.DB_BELLY,
    Cohort.DB_ADULT,
    Cohort.DB_ADULT_2,
    Cohort.DB_ADULT_18,
    Cohort.DB_INTRO,
    Cohort.DB_REF,
  ],
  [Locale.DEUTSCH]: [Cohort.DB_INTRO_FAST, Cohort.DB_BELLY, Cohort.DB_ADULT],
}

export const SEVEN_DAY_TRIAL_DURATION = '7'

export const EMPTY_OLD_PRICE = {
  fullPrice: 0,
  amountOfDiscount: 0,
  weekly: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  percentOfDiscount: 0,
}

export const INTRO_OFFER_COHORTS: Cohort[] = [
  Cohort.DB_INTRO,
  Cohort.DB_INTRO_FAST,
  Cohort.DB_INTRO_2,
  Cohort.DB_INTRO_DOP,
  Cohort.DANCEBIT_SHORT,
  Cohort.DB_CLEAR_INTRO_FAST,
  Cohort.DB_INTRO_FAST_18,
  Cohort.DB_INTRO_FAST_40,
  Cohort.DB_ADULT,
  Cohort.DB_ADULT_18,
  Cohort.DB_REF,
  Cohort.DB_ADULT_2,
  Cohort.DB_ADULT_VIDEO,
  Cohort.DB_ADULT_WEEK,
  Cohort.GLORIA_INTRO_FAST,
  Cohort.COHORT_2024,
  Cohort.SOMATIC_GOAL,
  Cohort.SOMATIC_AGE,
  Cohort.DB_BELLY,
  Cohort.DB_INFLUENCER,
  Cohort.DB_BELLY_REF,
  Cohort.DB_SOMATIC,
  Cohort.DB_LEGS,
]

export const TRIAL_COHORT: Cohort[] = [
  Cohort.DB_INTRO_FAST,
  Cohort.DB_INTRO_2,
  Cohort.DB_INTRO,
  Cohort.DB_INTRO_DOP,
  Cohort.DB_CLEAR_INTRO_FAST,
  Cohort.DB_INTRO_FAST_18,
  Cohort.DB_INTRO_FAST_40,
  Cohort.DB_ADULT,
  Cohort.DB_ADULT_18,
  Cohort.DB_REF,
  Cohort.DB_ADULT_2,
  Cohort.DB_ADULT_VIDEO,
  Cohort.DB_ADULT_WEEK,
  Cohort.DB_TWO_PLANS,
  Cohort.GLORIA_INTRO_FAST,
  Cohort.YARINAS,
  Cohort.COHORT_2024,
  Cohort.SOMATIC_GOAL,
  Cohort.SOMATIC_AGE,
  Cohort.CANCEL_INTRO_FAST,
  Cohort.EMAIL_INTRO_FAST,
  Cohort.DB_BELLY,
  Cohort.DB_INFLUENCER,
  Cohort.DB_BELLY_REF,
  Cohort.DB_SOMATIC,
  Cohort.DB_LEGS,
]

export const ADULT_COHORT = [
  Cohort.DB_ADULT,
  Cohort.DB_ADULT_18,
  Cohort.DB_ADULT_2,
  Cohort.DB_ADULT_VIDEO,
  Cohort.DB_ADULT_WEEK,
  Cohort.DB_TWO_PLANS,
  Cohort.DB_REF,
]

export const MAIN_GOALS = {
  LOSE_WEIGHT: 'loseWeight',
  STAY_FIT: 'stayFit',
  GET_IN_SHAPE: 'loseWeight',
}

export const GROWTHBOOK_EXPERIMENT = {
  DAN_8: 'dan_8_primer_checkout',
  DAN_284: 'dan_284_new_paywall_with_animation',
  DAN_360: 'dan_360_pricing_test',
  DAN_340: 'dan_340_new_lifestyle_screens',
  DAN_455: 'dan_455_new_ruler_screens',
  DAN_474: 'dan_474_upsell_offer_test',
  DAN_708: 'dan_708_livechat_disclaimers_update',
  DAN_809: 'dan_809_primer_paypal',
  DAN_926: 'dan_926_disable_db_adult_livechat',
  DAN_924: 'dan_924_new_payment_methods',
  DAN_988: 'dan_988_legs_paywall_test',
  DAN_1048: 'dan_1048_hide_pp_button',
}

export const enum ABC_TEST_VARIANTS {
  VARIANT_A = 'variant_a',
  VARIANT_B = 'variant_b',
  VARIANT_C = 'variant_c',
}

export const ONBOARDING_GOAL_EVENT = {
  STAY_FIT: 'stay_fit',
  LOSE_WEIGHT: 'lose_weight',
}

export const WEIGHT_UNITS: Record<MeasurementSystem, string> = {
  [MeasurementSystem.METRIC]: 'kg',
  [MeasurementSystem.IMPERIAL]: 'lb',
}

export const CARDHOLDER_NAME_MIN_LENGTH = 1

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'purchase1.insufficientFunds',
  PAYMENT_FAILED: 'purchase1.insufficientFunds',
  DECLINED: 'purchase1.declinedError',
  AUTHENTICATION_REQUIRED: 'purchase1.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'purchase1.withdrawLimit',
  EXPIRED_CARD: 'purchase1.expiredCard',
  COMMON_ERROR: 'login.somethingWentWrongError',
  ERROR: 'login.somethingWentWrongError',
  INVALID_CARD_NUMBER: 'login.somethingWentWrongError',
  LOST_OR_STOLEN_CARD: 'login.somethingWentWrongError',
  SUSPECTED_FRAUD: 'login.somethingWentWrongError',
  UNKNOWN: 'login.somethingWentWrongError',
  REFER_TO_CARD_ISSUER: 'login.somethingWentWrongError',
  DO_NOT_HONOR: 'login.somethingWentWrongError',
  ISSUER_TEMPORARILY_UNAVAILABLE: 'login.somethingWentWrongError',
}

export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'
export const PRIMER_CARD_NUMBER_ID = 'checkout-card-number-input'
export const PRIMER_CARD_EXPIRY_ID = 'checkout-card-expiry-input'
export const PRIMER_CARD_CVC_ID = 'checkout-card-cvv-input'
export const PRIMER_GOOGLE_PAY_ID = 'google-pay-button'
export const PRIMER_APPLE_PAY_ID = 'apple-pay-button'
export const PRIMER_PAY_PAL_ID = 'paypal-button'
export const INPUT_TEXT_COLOR = '#b8bbc1'

export const PRIMER_FORM_COMMON_STYLES = {
  input: {
    base: {
      paddingLeft: '15px',
      borderRadius: '10px',
      color: Color.DARK,
      fontFamily: 'sans-serif',
      fontSize: '16px',
      fontSmoothing: 'auto',
      fontWeight: '500',
      lineHeight: '51px',
      height: '51px',
      placeholder: {
        color: '#b8bbc1',
        fontWeight: '500',
      },
    },
    error: {
      color: '#ff8484',
      caretColor: '#ff8484',
      focus: {
        color: Color.DARK,
        caretColor: Color.DARK,
      },
    },
  },
  focusCheckoutOnInit: true,
}

export const PRIMER_FORM_COMMON_STYLES_V2 = {
  input: {
    base: {
      paddingLeft: '15px',
      borderRadius: '10px',
      color: Color.DARK,
      fontFamily: 'sans-serif',
      fontSize: '16px',
      fontSmoothing: 'auto',
      fontWeight: '500',
      lineHeight: '51px',
      height: '51px',
      placeholder: {
        color: '#81848c',
        fontWeight: '400',
      },
    },
    error: {
      color: '#ff8484',
      caretColor: '#ff8484',
      focus: {
        color: Color.DARK,
        caretColor: Color.DARK,
      },
    },
  },
  focusCheckoutOnInit: true,
}
export const PRIMER_FORM_COMMON_STYLES_BELLY = {
  input: {
    base: {
      paddingLeft: '15px',
      borderRadius: '10px',
      color: Color.DARK,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'auto',
      fontWeight: '400',
      lineHeight: '51px',
      height: '51px',
      placeholder: {
        color: Color.GRAY_60,
        fontWeight: '400',
      },
    },
    error: {
      color: '#ff8484',
      caretColor: '#ff8484',
      focus: {
        color: Color.DARK,
        caretColor: Color.DARK,
      },
    },
  },
  focusCheckoutOnInit: true,
}

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
}

export const CARDHOLDER_NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

export const COHORT_TO_PAGES_TEST_MAP: Record<string, string[]> = {
  [Cohort.DB_INTRO_DOP]: [GROWTHBOOK_EXPERIMENT.DAN_340],
  [Cohort.DB_INTRO_2]: [GROWTHBOOK_EXPERIMENT.DAN_340],
}

export const PUBLIC_PAGES = [
  PageId.ENTER_EMAIL,
  PageId.PREPAYWALL,
  PageId.RESULT,
  PageId.EMAIL_CONSENT,
  PageId.NAME,
  PageId.PLAN_IS_READY,
]

export const CENTIMETERS_IN_METER = 100
export const IMPERIAL_BMI_CONSTANT = 703

export const OBESE_BMI_THRESHOLD = 30
export const OVERWEIGHT_BMI_THRESHOLD = 25
export const NORMAL_BMI_THRESHOLD = 18.5

export const enum HotJarClientId {
  PROD = '5001574',
  DEV = '5001577',
}

export const HOTJAR_AVAILABLE_COHORTS = [
  Cohort.DB_INTRO_FAST,
  Cohort.DB_INTRO,
  Cohort.DB_INTRO_2,
  Cohort.DB_ADULT,
  Cohort.DB_BELLY,
  Cohort.DB_SOMATIC,
  Cohort.DB_LEGS,
  Cohort.DB_ADULT_MX,
]

export const SEVEN_DAY_PLAN_COHORTS = [
  Cohort.DB_INTRO_2,
  Cohort.DB_ADULT,
  Cohort.DB_BELLY,
  Cohort.DB_SOMATIC,
  Cohort.DB_LEGS,
]

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTIZING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
}

export const enum GAConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const enum FBConsent {
  REVOKE = 'revoke',
  GRANT = 'grant',
}

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'NO',
  'IS',
  'CH',
  'LI',
]

export const enum DynamicDiscountType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export const enum DynamicDiscountTheme {
  VIOLET = 'violet',
  CORAL = 'coral',
  YELLOW = 'yellow',
  BLACK = 'black',
}
