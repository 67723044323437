import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import imageNo from 'assets/images/no-icon.svg'
import img from 'assets/images/unsatisfied-body-woman-mirror.png'
import imageYes from 'assets/images/yes-icon.svg'

import { ApprovalStatus } from 'root-constants'

import { StyledUnsatisfiedBody as S } from './UnsatisfiedBody.styles'
import { QUESTION } from './constants'

export const UnsatisfiedBody: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { handleChange, setIsAnswersDisabled } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={77}>
        <Trans i18nKey="onboarding.statements.question" />
      </PageTitle>

      <S.QuestionBlock>
        <S.Image src={img} alt="Do you relate to the statement below?" />
        <S.QuestionContainer>
          <S.Question>
            <Trans i18nKey="onboarding.statements.unsatisfiedBody" />
          </S.Question>
        </S.QuestionContainer>
      </S.QuestionBlock>

      <S.ButtonsBLock>
        <AnswerWithIcon
          {...optionProps}
          value={ApprovalStatus.YES}
          iconSrc={imageYes}
          theme={answerTheme.DANCEBIT}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          height="80px"
          minWidth="165px"
          padding="16px"
          iconAlignSelf="center"
          iconWidth="40px"
          margin="0 0 16px 0"
        >
          <Trans i18nKey="actions.yes" />
        </AnswerWithIcon>
        <AnswerWithIcon
          {...optionProps}
          value={ApprovalStatus.NO}
          iconSrc={imageNo}
          theme={answerTheme.DANCEBIT}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          height="80px"
          minWidth="165px"
          padding="16px"
          iconAlignSelf="center"
          iconWidth="40px"
          margin="0 0 16px 0"
        >
          <Trans i18nKey="actions.no" />
        </AnswerWithIcon>
      </S.ButtonsBLock>
    </Container>
  )
}
