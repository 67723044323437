import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReviewV2 = {
  Wrapper: styled.div`
    margin: 0 auto;
    padding: 24px 0;
    border-radius: 24px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 0;
  `,
  Title: styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  `,
  Span: styled.span`
    color: ${Color.PRIMARY};
    text-transform: uppercase;
  `,
  Comment: styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 8px 20px 0 #e1e3ec;
    text-align: left;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `,
  Author: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 85px;
    padding: 8px 0;

    svg {
      width: 14px;
      height: 14px;
    }
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAYISH_BLUE};
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
  `,
}
