import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledLessonLength as S } from './LessonLength.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const LessonLength: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle marginBottom={isAgeFlow ? 16 : 30} isAgeFlow={isAgeFlow}>
          {isAgeFlow
            ? t`onboarding.lessonLength.questionAgeFlow`
            : t`onboarding.lessonLength.question`}
        </PageTitle>
        {isAgeFlow && (
          <S.Subtitle>
            <Trans i18nKey="onboarding.lessonLength.description" />
          </S.Subtitle>
        )}
      </S.TitleContainer>
      <S.Container $isAgeFlow={isAgeFlow}>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.EASY}
        >
          <AnswerButton
            isAgeFlow={isAgeFlow}
          >{t`onboarding.lessonLength.easy`}</AnswerButton>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.MEDIUM}
        >
          <AnswerButton
            isAgeFlow={isAgeFlow}
          >{t`onboarding.lessonLength.medium`}</AnswerButton>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.HARD}
        >
          <AnswerButton
            isAgeFlow={isAgeFlow}
          >{t`onboarding.lessonLength.hard`}</AnswerButton>
        </Option>
      </S.Container>
    </div>
  )
}
