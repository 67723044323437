import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledForgetPlanks as S } from './ForgetPlanks.styles'
import { ANSWER, QUESTION, QUESTION_LEGS } from './constants'

export const ForgetPlanks: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isLegsFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: isLegsFlow ? QUESTION_LEGS : QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(ANSWER), [
    handleContinue,
  ])

  return (
    <S.Root>
      <Container isLarge>
        <S.PageTitle marginBottom={8}>
          <Trans i18nKey="onboarding.forgetPlanks.title" />
        </S.PageTitle>

        <S.ContentWrapper>
          <video
            muted
            loop
            autoPlay
            controls={false}
            playsInline
            width="100%"
            height="100%"
            poster={`${CDN_FOLDER_LINK}/videos/no_planks.png`}
          >
            <source
              src={`${CDN_FOLDER_LINK}/videos/no_planks.mp4`}
              type="video/mp4"
            />
          </video>
        </S.ContentWrapper>
      </Container>

      <S.NavigationButtonBelly onClick={handleClick}>
        {t('actions.letsDance')}
      </S.NavigationButtonBelly>
    </S.Root>
  )
}
