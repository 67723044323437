import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { Notification } from 'components/Notification'

import {
  getAutologinTokenAction,
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectDownloadLinks, selectLanguage } from 'root-redux/selects/common'
import {
  selectAutologinToken,
  selectUserLoginEmail,
} from 'root-redux/selects/user'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import checkIcon from 'assets/images/sprite/check-icon-rounded.svg'
import copyImg from 'assets/images/sprite/copy.svg'

import {
  CDN_FOLDER_LINK,
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  LoginMethod,
  PagesSource,
  PlatformOS,
  WEBAPP_QUERY_PARAM,
} from 'root-constants'

import { StyledDownload as S } from './Download.styles'
import {
  LOGIN_METHOD_INSTRUCTION_LINK_MAP,
  POPUP_DISAPPEARANCE_TIME,
  START_IMAGE,
} from './constants'

export const Download: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userEmail = useSelector(selectUserLoginEmail)
  const language = useSelector(selectLanguage)
  const autologinToken = useSelector(selectAutologinToken)
  const downloadLinks = useSelector(selectDownloadLinks)

  const { search } = useLocation()
  const URLParams = new URLSearchParams(search)
  const hasWebappLink = URLParams.get(WEBAPP_QUERY_PARAM)

  const [showModal, setShowModal] = useState(false)

  const imageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${START_IMAGE}_${language}.png`,
    [language],
  )

  const downloadAppLink = useMemo(() => {
    let autologinLink = LOGIN_METHOD_INSTRUCTION_LINK_MAP[LoginMethod.EMAIL]

    if (downloadLinks && hasWebappLink) {
      const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID
      autologinLink =
        downloadLinks[isAndroid ? 'android' : 'ios'] ||
        LOGIN_METHOD_INSTRUCTION_LINK_MAP[LoginMethod.EMAIL]
    }

    return autologinLink.replace(
      CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
      autologinToken || '',
    )
  }, [hasWebappLink, autologinToken, downloadLinks])

  useEffect(() => {
    eventLogger.logGettingAppShown()

    dispatch(sendUserAnswersAction(null, true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
      }),
    )
    dispatch(getAutologinTokenAction())
  }, [dispatch])

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(userEmail).then(() => {
      setShowModal(true)

      eventLogger.logEmailCopy()

      setTimeout(() => setShowModal(false), POPUP_DISAPPEARANCE_TIME)
    })
  }, [userEmail])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    (number: number, buttonText: string) => {
      googleAnalyticsLogger.logDownloadButtonPressed()
      eventLogger.logDownloadClicked(handleContinue, number, buttonText)
    },
    [handleContinue],
  )

  return (
    <>
      <S.Wrapper>
        <S.Title>{t`gettingApp.title`}</S.Title>

        <S.List>
          <S.ListTitle>{t`gettingApp.howDownload.title`}</S.ListTitle>
          <S.ListItem>
            <S.Text>
              <Trans
                i18nKey="gettingApp.howDownload.firstStep"
                components={{
                  button: (
                    <button
                      type="button"
                      data-deep-link={downloadAppLink}
                      onClick={() => handleClick(1, 'download_dancebit')}
                    >{t`login.app`}</button>
                  ),
                }}
              />
            </S.Text>
          </S.ListItem>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.howDownload.secondStep" />
            </S.Text>
          </S.ListItem>
        </S.List>

        <S.ImageContainer>
          <S.Image
            src={imageLink}
            data-deep-link={downloadAppLink}
            onClick={() => handleClick(2, 'get_started')}
          />
        </S.ImageContainer>

        <S.ListWithBorder>
          <S.ListTitle>{t`gettingApp.notLogged.title`}</S.ListTitle>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.notLogged.firstStep" />
            </S.Text>
          </S.ListItem>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.notLogged.secondStep" />
            </S.Text>
          </S.ListItem>

          <S.CopyEmail>
            <S.UserEmail>
              <S.Label>{t`gettingApp.notLogged.email`}</S.Label>
              <S.Email type="text" value={userEmail} readOnly />
            </S.UserEmail>

            <S.CopyButton onClick={handleCopy}>
              <S.SvgIcon
                svg={showModal ? checkIcon : copyImg}
                width={20}
                height={20}
              />
              <Notification text={showModal ? t`gettingApp.copy` : ''} />
            </S.CopyButton>
          </S.CopyEmail>

          <S.ListItem>
            <S.Text>
              <Trans
                i18nKey="gettingApp.notLogged.thirdStep"
                components={{
                  linkTag: (
                    <ContactWithSupport
                      text={t`commonComponents.here`}
                      source={PagesSource.DOWNLOAD}
                    />
                  ),
                }}
              />
            </S.Text>
          </S.ListItem>
        </S.ListWithBorder>
      </S.Wrapper>

      <S.ButtonContainer>
        <S.Button
          type="button"
          data-deep-link={downloadAppLink}
          onClick={() => handleClick(3, 'download_dancebit')}
        >
          {t`gettingApp.buttonText`}
        </S.Button>
      </S.ButtonContainer>
    </>
  )
}
