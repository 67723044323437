import styled from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  $isVisible: boolean
}

export const StyledCheckoutTwoPlans = {
  Wrapper: styled.div<TWrapperProps>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    max-width: 360px;
    margin: 50px auto 0;
    padding: 0 20px 60px;
    text-align: center;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  DescriptionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 13px;

    &:last-of-type {
      margin-bottom: 8px;
    }

    & span {
      text-align: left;
      max-width: 225px;
    }
  `,
  PersonalizedPlan: styled.span`
    text-align: left;
    max-width: 220px;
  `,
  PlanPrice: styled.span`
    font-weight: 700;
  `,
  Discount: styled.span`
    font-weight: 700;
    color: #b1446b;
  `,
  Separator: styled.div`
    border-top: 1px solid #eaeaec;
    width: 100%;
    margin-bottom: 14px;
  `,
  TotalAmountContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  `,
  ValueAddedContainer: styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 43px;
    color: ${Color.DISABLED};
  `,
  DiscountDescription: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #b1446b;
    text-align: right;
  `,
  PaymentContainer: styled.div`
    margin-top: 32px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 24px;
  `,
  Image: styled.img`
    margin: 32px auto 23px;
    max-width: 256px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(19, 29, 48, 0.5);
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 65px;
    right: 15px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
