import { RefObject, useEffect } from 'react'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'

export const useTimerForTarget = (
  timerElementRefs: {
    hours: RefObject<HTMLElement>
    minutes: RefObject<HTMLElement>
    seconds: RefObject<HTMLElement>
  },
  startTimeValueInSeconds: number,
  withHour = false,
): void => {
  useEffect(() => {
    let intervalID: number
    const { hours, minutes, seconds } = timerElementRefs

    const setIntervalCallback = () => {
      if (!seconds.current) return

      const timerElement = seconds.current
      const currentTimerValue = +(
        timerElement.dataset.value || startTimeValueInSeconds
      )

      if (currentTimerValue <= 0) {
        clearInterval(intervalID)
        return
      }

      const nextTimerValue = currentTimerValue - 1

      timerElement.setAttribute('data-value', `${nextTimerValue}`)

      const formattedTime = getDecoratedTimerValue(nextTimerValue, withHour)

      if (hours.current) {
        hours.current.innerHTML = formattedTime.hours
      }

      if (minutes.current) {
        minutes.current.innerHTML = formattedTime.minutes
      }

      seconds.current.innerHTML = formattedTime.seconds
    }

    intervalID = (setInterval(setIntervalCallback, 1000) as unknown) as number

    const leavePageCallback = () => {
      if (document.hidden) {
        clearInterval(intervalID)
        return
      }

      intervalID = (setInterval(setIntervalCallback, 1000) as unknown) as number
    }

    window.addEventListener('visibilitychange', leavePageCallback)

    return () => {
      clearInterval(intervalID)
      window.removeEventListener('visibilitychange', leavePageCallback)
    }
  }, [startTimeValueInSeconds, timerElementRefs, withHour])
}
