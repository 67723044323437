import styled, { css, keyframes } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const tipStyles = css`
  position: absolute;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  color: ${Color.WHITE};
  border-radius: 7px;
  transform: translateX(-50%);
  white-space: nowrap;
`

export const StyledGoalChart = {
  Wrapper: styled.div`
    overflow: hidden;
    margin-bottom: 50px;
  `,
  Title: styled.h2`
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  GraphContent: styled.div`
    position: relative;
  `,
  TipNow: styled.p`
    ${tipStyles};
    top: 85px;
    left: 45px;
    animation-delay: 500ms;
    background-color: ${Color.DISABLED};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      top: 100px;
    }
  `,
  TipAfter: styled.p`
    ${tipStyles};
    margin: 0;
    right: 0;
    top: 5px;
    margin: 0 -16px;
    animation-delay: 1300ms;
    background-color: ${Color.PRIMARY};
  `,
}
