import { ApiService } from 'services/api.service'

import { CommonApi } from './CommonApi'
import { ConfigApi } from './ConfigApi'
import { EventsApi } from './EventsApi'
import { PaymentApi } from './PaymentApi'
import { SubscriptionsApi } from './SubscriptionsApi'
import { UnsubscribeApi } from './UnsubscribeApi'
import { UserApi } from './UserApi'
import { VariantsApi } from './VariantsApi'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

export const baseApiService = new ApiService(API_PREFIX)
export const hooksApiService = new ApiService('/hooks')
export const commonApiService = new ApiService('/api')

export const paymentApi = new PaymentApi(baseApiService)
export const unsubscribeApi = new UnsubscribeApi(baseApiService)
export const userApi = new UserApi(baseApiService)
export const eventsApi = new EventsApi(hooksApiService)
export const variantsApi = new VariantsApi(baseApiService)
export const configApi = new ConfigApi(baseApiService)
export const subscriptionsApi = new SubscriptionsApi(baseApiService)
export const commonApi = new CommonApi(commonApiService)
