import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import {
  DISCLAIMERS,
  SubscriptionDuration,
} from 'modules/purchase/components/FeaturedBlockUpgrade/constants'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { Cohort, PagesSource, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { StyledFeaturedBlockUpgrade as S } from './UpgradeDisclaimer.styles'

type TProps = {
  className?: string
}

export const UpgradeDisclaimer: React.FC<TProps> = ({ className = '' }) => {
  const { t } = useTranslation()

  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const {
    currency,
    periodQuantity,
    amountToPay,
    upgradeFullPrice,
    cohort,
    parentCohort,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const isLiveChatUpgradeCohort = useMemo(
    () => cohortToUse === Cohort.DANCEBIT_CHAT_UPGRADE_ANNUALS_PDF,
    [cohortToUse],
  )

  const hasSevenDayTrial = useMemo(
    () => trialPeriodDays === SEVEN_DAY_TRIAL_DURATION,
    [trialPeriodDays],
  )

  const sevenDayUpdateSubscriptionText = useMemo(
    () =>
      periodQuantity !== SubscriptionDuration.TWO_YEAR
        ? t('purchaseLiveChat.planDuration', {
            count: periodQuantity,
          })
        : t('purchaseLiveChat.twoYearPeriod'),
    [periodQuantity, t],
  )

  return (
    <>
      {hasSevenDayTrial ? (
        <S.Disclaimer className={className}>
          <Trans
            i18nKey={
              isLiveChatUpgradeCohort
                ? 'purchaseLiveChat.sevenDayDisclaimer'
                : 'purchase3.disclaimer'
            }
            values={{
              trialPeriodDays,
              price: upgradeFullPrice,
              periodQuantity,
              currency: CURRENCY_SYMBOLS[currency],
              inclVat: '',
              period: sevenDayUpdateSubscriptionText,
            }}
            components={{ linkTag: <AccountLink /> }}
          />
          <TermsOfUseLink source={PagesSource.PAYWALL} />{' '}
          {t`commonComponents.or`}{' '}
          <ContactWithSupport
            source={PagesSource.PAYWALL}
            text={t('commonComponents.contactSupportDisclaimer')}
          />
        </S.Disclaimer>
      ) : (
        <S.Disclaimer className={className}>
          <Trans
            i18nKey={DISCLAIMERS[periodQuantity]}
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: amountToPay,
              fullPrice: upgradeFullPrice,
              context: isLiveChatUpgradeCohort && 'liveChat',
            }}
            components={{ linkTag: <AccountLink /> }}
          />
          <TermsOfUseLink source={PagesSource.PAYWALL} />{' '}
          {t`commonComponents.or`}{' '}
          <ContactWithSupport
            source={PagesSource.PAYWALL}
            text={t('commonComponents.contactSupportDisclaimer')}
          />
        </S.Disclaimer>
      )}
    </>
  )
}
