import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { BONUS_LIST_ITEM } from 'modules/purchase/components/SubscriptionBonusItem/constants'
import { PDF_COHORTS } from 'modules/purchase/constants'

import image from 'assets/images/guide-img.png'
import imageMeal from 'assets/images/meal-plan.png'

import { Cohort } from 'root-constants'

import { StyledSubscriptionBonusItem as S } from './SubscriptionBonusItem.styles'

export const SubscriptionBonusItem: React.FC = () => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const { benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  const cohortToUse = parentCohort || cohort

  const isPdfCohort = PDF_COHORTS.includes(cohortToUse as Cohort)

  const hasMealPlan = useMemo(
    () => cohortToUse === Cohort.DANCEBIT_CHAT_UPGRADE_12_MEAL,
    [cohortToUse],
  )

  return (
    <S.ProgramPlanContainer>
      <S.ProgramPlanTitle>
        {benefitsBlockTitle || t('purchaseUpgrade.bonusTitle')}
      </S.ProgramPlanTitle>
      {isPdfCohort && (
        <S.GuideContainer>
          <S.ImageContainer>
            <S.BonusContainer>
              <S.Bonus>{t('purchaseUpgrade.bonus')}</S.Bonus>
            </S.BonusContainer>
            <img src={image} alt="Self-care guide" />
          </S.ImageContainer>
          <S.DescriptionContainer>
            <S.DescriptionTitle>{t`purchaseUpgrade.descriptionTitle`}</S.DescriptionTitle>
            <S.Description>{t`purchaseUpgrade.description`}</S.Description>
          </S.DescriptionContainer>
        </S.GuideContainer>
      )}

      {hasMealPlan && (
        <S.MealContainer>
          <S.ImageContainerMealPlan>
            <img src={imageMeal} alt="28-Day Meal Plan" />
          </S.ImageContainerMealPlan>
          <S.DescriptionContainer>
            <S.DescriptionTitle>{t`purchaseUpgradeMealPlan.descriptionTitle`}</S.DescriptionTitle>
            <S.DescriptionMealPlan>{t`purchaseUpgradeMealPlan.description`}</S.DescriptionMealPlan>
          </S.DescriptionContainer>
        </S.MealContainer>
      )}

      <S.List $hasMealPlan={hasMealPlan}>
        {(benefits.length ? benefits : BONUS_LIST_ITEM).map(({ id, text }) => (
          <S.ListItem key={id}>
            <S.ItemContainer>{t(text)}</S.ItemContainer>
          </S.ListItem>
        ))}
      </S.List>
    </S.ProgramPlanContainer>
  )
}
