import styled from 'styled-components'

import { Container } from 'components/Container'

import introBg from 'assets/images/say-goodbye-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledMainGoal = {
  Root: styled.div<{
    $uploadedBackgroundImage: string
    $uploadedBackgroundImageDesktop: string
  }>`
    position: relative;
    background-image: ${({ $uploadedBackgroundImage }) =>
      $uploadedBackgroundImage
        ? `url(${$uploadedBackgroundImage})`
        : `url(${introBg})`};
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 50px);

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-size: contain;
    }
  `,
  TickerContainer: styled.div`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
  `,
  Container: styled(Container)`
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Subtitle: styled.p`
    color: #0091ff;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  Title: styled.h1`
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
  `,
  Terms: styled.div`
    position: relative;
    color: ${Color.GRAY_80};
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY_80};
    margin: 0 5px;
    vertical-align: middle;
  `,
  ProgressWrapper: styled.div`
    margin-bottom: 16px;
    overflow: hidden;
    height: 20px;
    border-radius: 30px;
  `,
}
