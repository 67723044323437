import styled from 'styled-components'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

type TSubscriptionBonusItemProps = {
  $hasMealPlan?: boolean
}

export const StyledSubscriptionBonusItem = {
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    margin-bottom: 32px;
    background: #f4faff;
    border-radius: 24px;
  `,
  DescriptionContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  DescriptionTitle: styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DARK};
    padding-bottom: 8px;
  `,
  Description: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    background: #f9d3ff;
    width: 86px;
    height: 136px;
    min-width: 86px;
    min-height: 136px;
    border-radius: 8px;
  `,
  BonusContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 6px;
  `,
  Bonus: styled.div`
    padding: 4px 8px;
    background: ${Color.PRIMARY};
    border-radius: 11px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    color: ${Color.WHITE};
  `,
  GuideContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    background: ${Color.WHITE};
    border-radius: 24px;
    border: 2px solid #ef8bff;
    margin-bottom: 27px;
    padding: 16px;
    margin-left: 12px;
    margin-right: 12px;
  `,
  ProgramPlanTitle: styled.h2`
    padding-bottom: 16px;
    padding-top: 16px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  ItemContainer: styled.p`
    padding-left: 14px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 22px;
    padding-left: 26px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    &:last-child {
      margin-bottom: 23px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 23px;
      height: 23px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: cover;
      border: 3px solid #d7f1fd;
      border-radius: 10px;
    }
  `,
  MealContainer: styled.div`
    max-width: 305px;
    display: flex;
    background: ${Color.WHITE};
    border-radius: 24px;
    padding: 16px;
    border: 2px solid #55d16f;
    margin-bottom: 16px;
  `,
  ImageContainerMealPlan: styled.div`
    min-height: 155px;
    min-width: 118px;
    margin-right: 16px;
  `,
  DescriptionMealPlan: styled.div`
    font-size: 14px;
    color: ${Color.DARK};
    font-weight: 400;
    line-height: 18px;
  `,
  List: styled.ul<TSubscriptionBonusItemProps>`
    padding: 0 16px;

    ${({ $hasMealPlan }) => $hasMealPlan && 'padding: 0px 24px;'}
  `,
}
