import age18Loss from 'assets/images/age-18-29-loss.png'
import age18somatic from 'assets/images/age-18-somatic.png'
import age24somatic from 'assets/images/age-24-somatic.png'
import age30Loss from 'assets/images/age-30-39-loss.png'
import age30somatic from 'assets/images/age-30-somatic.png'
import age34somatic from 'assets/images/age-34-somatic.png'
import age39 from 'assets/images/age-39.png'
import age40Loss from 'assets/images/age-40-49-loss.png'
import age49 from 'assets/images/age-40-49.png'
import age40somatic from 'assets/images/age-40-somatic.png'
import age44somatic from 'assets/images/age-44-somatic.png'
import age45somatic from 'assets/images/age-45-somatic.png'
import age59 from 'assets/images/age-50-59.png'
import age50Loss from 'assets/images/age-50-loss.png'
import age50somatic from 'assets/images/age-50-somatic.png'
import age60 from 'assets/images/age-60.png'
import ageBelly18 from 'assets/images/flow-belly/age-18-24-belly.png'
import ageBelly25 from 'assets/images/flow-belly/age-25-34-belly.png'
import ageBelly35 from 'assets/images/flow-belly/age-35-44-belly.png'
import ageBelly45 from 'assets/images/flow-belly/age-45-belly.png'
import ageLegs18 from 'assets/images/flow-belly/age-legs-18-24.png'
import ageLegs25 from 'assets/images/flow-belly/age-legs-25-34.png'
import ageLegs35 from 'assets/images/flow-belly/age-legs-35-44.png'
import ageLegs45 from 'assets/images/flow-belly/age-legs-45.png'

import { Cohort } from 'root-constants'

export const QUESTION = 'Join Your Dancing Weight Loss Challenge'

export const DEFAULT_OPTIONS = [
  {
    value: '30-39',
    from: '30',
    to: '39',
    imageUrl: age39,
  },
  {
    value: '40-49',
    from: '40',
    to: '49',
    imageUrl: age49,
  },
  {
    value: '50-59',
    from: '50',
    to: '59',
    imageUrl: age59,
  },
  {
    value: '60+',
    from: '60+',
    imageUrl: age60,
  },
]

export const OPTION_VALUES = {
  [Cohort.SOMATIC_AGE]: [
    {
      value: '18-29',
      from: '18',
      to: '29',
      imageUrl: age18somatic,
    },
    {
      value: '30-39',
      from: '30',
      to: '39',
      imageUrl: age30somatic,
    },
    {
      value: '40-49',
      from: '40',
      to: '49',
      imageUrl: age40somatic,
    },
    {
      value: '50+',
      from: '50+',
      imageUrl: age50somatic,
    },
  ],
  [Cohort.DB_ADULT_2]: [
    {
      value: '18-29',
      from: '18',
      to: '29',
      imageUrl: age18Loss,
    },
    {
      value: '30-39',
      from: '30',
      to: '39',
      imageUrl: age30Loss,
    },
    {
      value: '40-49',
      from: '40',
      to: '49',
      imageUrl: age40Loss,
    },
    {
      value: '50+',
      from: '50+',
      imageUrl: age50Loss,
    },
  ],
  [Cohort.DB_BELLY]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageBelly18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageBelly25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageBelly35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageBelly45,
    },
  ],
  [Cohort.DB_BELLY_SALE]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageBelly18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageBelly25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageBelly35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageBelly45,
    },
  ],
  [Cohort.DB_BELLY_WEEK2]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageBelly18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageBelly25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageBelly35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageBelly45,
    },
  ],
  [Cohort.DB_BELLY_WEEK]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageBelly18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageBelly25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageBelly35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageBelly45,
    },
  ],
  [Cohort.DB_LEGS]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageLegs18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageLegs25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageLegs35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageLegs45,
    },
  ],
  [Cohort.DB_SOMATIC]: [
    {
      value: '18-29',
      from: '18',
      to: '29',
      imageUrl: age24somatic,
    },
    {
      value: '30-39',
      from: '30',
      to: '39',
      imageUrl: age34somatic,
    },
    {
      value: '40-49',
      from: '40',
      to: '49',
      imageUrl: age44somatic,
    },
    {
      value: '50+',
      from: '50+',
      imageUrl: age45somatic,
    },
  ],
  [Cohort.DB_BELLY_REF]: [
    {
      value: '18-24',
      from: '18',
      to: '24',
      imageUrl: ageBelly18,
    },
    {
      value: '25-34',
      from: '25',
      to: '34',
      imageUrl: ageBelly25,
    },
    {
      value: '35-44',
      from: '35',
      to: '44',
      imageUrl: ageBelly35,
    },
    {
      value: '45+',
      from: '45+',
      imageUrl: ageBelly45,
    },
  ],
}
