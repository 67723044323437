import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import logoImg from 'assets/images/university-logo.png'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledHelpAchieveResults as S } from './HelpAchieveResults.styles'
import { ANIMATION_PATH, ANIMATION_PATH_CLEAR, QUESTION } from './constants'

export const HelpAchieveResults: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)

  const language = useSelector(selectLanguage)
  const { isTTClearFlow, isNewAdultFlow } = useCohortInfo()

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: isTTClearFlow
          ? `${CDN_FOLDER_LINK}${ANIMATION_PATH_CLEAR}`
          : `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [isTTClearFlow, language])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton $isAdult={isNewAdultFlow}>
        <S.Container isLarge>
          <PageTitle marginBottom={24}>
            {t`onboarding.helpAchieveResults.title`}
          </PageTitle>

          <S.Animation
            style={{
              minHeight: isTTClearFlow ? '236px' : '270px',
            }}
          >
            <S.YourWeight>
              <Trans
                i18nKey={
                  isTTClearFlow
                    ? 'onboarding.helpAchieveResults.yourFitnessLevel'
                    : 'onboarding.helpAchieveResults.yourWeight'
                }
              />
            </S.YourWeight>

            <S.WithDancebit $isTTClearFlow={isTTClearFlow}>
              <span>
                <Trans i18nKey="onboarding.helpAchieveResults.withDancebit" />
              </span>
            </S.WithDancebit>

            <S.WithoutDancebit $isTTClearFlow={isTTClearFlow}>
              <span>
                <Trans i18nKey="onboarding.helpAchieveResults.withoutDancebit" />
              </span>
            </S.WithoutDancebit>

            <S.Graph ref={animationRef} />

            {!isTTClearFlow && (
              <S.DurationBlock>
                <span>
                  <Trans i18nKey="onboarding.helpAchieveResults.today" />
                </span>
                <span>
                  <Trans i18nKey="onboarding.helpAchieveResults.month" />
                </span>
              </S.DurationBlock>
            )}
          </S.Animation>

          <S.DescriptionBlock>
            <S.Logo src={logoImg} alt="Stanford University" />

            <S.Description>
              <Trans i18nKey="onboarding.helpAchieveResults.description" />
            </S.Description>
          </S.DescriptionBlock>
        </S.Container>
      </S.ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        type="button"
        $isAdult={isNewAdultFlow}
        onClick={handleNextClick}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
