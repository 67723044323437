import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import backgroundImageAdult from 'assets/images/boost-your-health-bg-new-adult.png'
import backgroundImage from 'assets/images/boost-your-health-bg.png'

import { StyledBoostYourHealth as S } from './BoostYourHealth.styles'
import { QUESTION } from './constants'

export const BoostYourHealth: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  const { isNewAdultFlow } = useCohortInfo()

  return (
    <S.Container
      isLarge
      $imageUrl={isNewAdultFlow ? backgroundImageAdult : backgroundImage}
      $isAdult={isNewAdultFlow}
    >
      <S.Root>
        <S.TextBlock>
          <S.PageTitle marginBottom={8} $isAdult={isNewAdultFlow}>
            <Trans
              i18nKey={
                isNewAdultFlow
                  ? 'onboarding.boostYourHealth.titleAgeFlow'
                  : 'onboarding.boostYourHealth.title'
              }
            />
          </S.PageTitle>
          <S.Subtitle $isAdult={isNewAdultFlow}>
            <Trans
              i18nKey={
                isNewAdultFlow
                  ? 'onboarding.boostYourHealth.textAgeFlow'
                  : 'onboarding.boostYourHealth.text'
              }
            />
          </S.Subtitle>
        </S.TextBlock>
      </S.Root>
      <S.NavigationButtonBelly
        type="button"
        onClick={handleClick}
        $isAdult={isNewAdultFlow}
      >
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </S.Container>
  )
}
