import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import leftBranch from 'assets/images/left-branch-gradient.svg'
import rightBranch from 'assets/images/right-branch-gradient.svg'
import tenMillionWomenImageDesktop from 'assets/images/ten-million-women-adult-desktop.jpg'
import tenMillionWomenImage from 'assets/images/ten-million-women-adult.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTenMillionWomen = {
  Root: styled.div`
    position: relative;
    background-image: url(${tenMillionWomenImage});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100% - 50px);

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: ${`url(${tenMillionWomenImageDesktop})`};
    }
  `,
  TextBlock: styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
  `,
  Title: styled.h2`
    position: relative;
    width: 250px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 42px;
      height: 72px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      right: 0;
      background: url(${rightBranch});
    }

    &::before {
      left: 0;
      background: url(${leftBranch});
    }
  `,
  Subtitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  Container: styled(Container)`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 21px 16px 16px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    position: absolute;
    background-color: transparent;
    border: none;
  `,
}
