import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledMoneyCancelOffer = {
  Wrapper: styled.div`
    margin: 32px 0;
    padding: 8px;
    background-color: ${Color.GRAY_10};
    border-radius: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 4px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Content: styled.div`
    padding: 24px 12px 16px;
    border: 2px solid ${Color.PRIMARY};
    border-radius: 16px;
  `,
  TextContainer: styled.div`
    display: flex;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: justify;
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  Badge: styled.img`
    float: right;
    height: 100%;
    width: 65px;
    margin-left: 6px;
    shape-outside: inset(calc(100% - 64px) 0 0);
    object-fit: contain;
    object-position: bottom;
  `,
}
