import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Footer } from 'components/Footer'
import { InputWithLabel } from 'components/InputWithLabel'
import { Modal } from 'components/Modal'
import { SvgImage } from 'components/SvgImage'

import { resetErrorAction } from 'root-redux/actions/common'
import { sendUserEmailAction } from 'root-redux/actions/user'
import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectError,
  selectIsStayFitFlow,
  selectLanguage,
  selectUserName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import lockIconSvg from 'assets/images/sprite/lock.svg'
import emoji from 'assets/images/sprite/present-emoji.svg'

import { goTo } from 'browser-history'
import {
  ADULT_COHORT,
  Cohort,
  Locale,
  MAIN_GOALS,
  MeasurementSystem,
} from 'root-constants'

import { StyledEmail as S } from './Email.styles'
import { PREPAYWALL_PAGES } from './constants'

export const Email: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { search } = useLocation()

  const userStatus = useSelector(selectUserStatus)
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const userAnswers = useSelector(selectAnswers)
  const language = useSelector(selectLanguage)
  const name = useSelector(selectUserName)

  const cohortToUse = parentCohort || cohort

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, setEmail] = useEmailInputField()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const { title, subtitle } = useDynamicOBConfig()

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const nextPage = useMemo(() => {
    if (!userStatus?.email.isEmailConsentTried) {
      return PREPAYWALL_PAGES.CONSENT
    }

    return isAgeFlow ? PREPAYWALL_PAGES.RESULT : PREPAYWALL_PAGES.PREPAYWALL
  }, [isAgeFlow, userStatus?.email.isEmailConsentTried])

  const errorText = useMemo(
    () => (!email.isFocused && !email.isValid ? email.validationText : ''),
    [email.isValid, email.validationText, email.isFocused],
  )

  const titleText = useMemo(() => {
    if (title) return title

    return 'onboarding.email.title'
  }, [title])

  const subtitleText = useMemo(() => {
    if (subtitle) return subtitle

    return 'onboarding.email.subtitle'
  }, [subtitle])

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const weight = useMemo(() => {
    const goalWeight = userAnswers?.goalWeight || '50'
    const unit = t(`commonComponents.${userAnswers?.measurementSystem}`)

    return `${goalWeight} ${unit}`
  }, [t, userAnswers?.goalWeight, userAnswers?.measurementSystem])

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo({
        pathname: nextPage,
        search,
      })
    }
  }, [userStatus?.email, search, nextPage])

  useEffect(() => {
    !userStatus?.email.hasEmail && eventLogger.logEmailPageShown()
  }, [userStatus?.email])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      if (!email.isValid) return

      goTo({
        pathname: PREPAYWALL_PAGES.CONSENT,
        search,
      })
      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
          isConsentRequired: true,
        }),
      )

      googleAnalyticsLogger.logEnterEmailPageCompleted()
      eventLogger.logEmailPageCompleted({
        email: isPersonalDataAllowed ? email.value : '',
      })
      window.axon &&
        window.axon('track', 'sign_up', {
          user_id: isPersonalDataAllowed ? uuid : '',
          method: 'email',
        })
      window.fbq &&
        window.fbq(
          'track',
          'Lead',
          {},
          { eventID: isPersonalDataAllowed ? uuid : '' },
        )
      window.rdt &&
        window.rdt('track', 'Lead', {
          email: isPersonalDataAllowed ? email.value : '',
          externalId: isPersonalDataAllowed ? uuid : '',
          ...(!isPersonalDataAllowed && {
            em: '',
            uuid: '',
          }),
        })
      window.pintrk && window.pintrk('track', 'lead')
      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq &&
        window.ttq.track('CompleteRegistration', {
          event_id: isPersonalDataAllowed ? uuid : '',
        })
      window.snaptr &&
        window.snaptr('track', 'SIGN_UP', {
          user_email: isPersonalDataAllowed ? email.value : '',
          firstname: isPersonalDataAllowed ? name : '',
        })
      window.obApi && window.obApi('track', 'Lead')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'complete_registration',
        })
    },
    [
      name,
      email.isValid,
      email.value,
      search,
      dispatch,
      handleErrorModal,
      isPersonalDataAllowed,
      uuid,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  if (userStatus?.email.hasEmail) return null

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        {isAgeFlow ? (
          <S.Title $isAgeFlow>
            {title || (
              <Trans
                i18nKey="onboarding.email.titleAgeFlow"
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
              />
            )}
            {subtitle && <strong> {subtitle}</strong>}
          </S.Title>
        ) : (
          <>
            <S.Title>
              <Trans i18nKey={titleText} />
            </S.Title>
            <S.Subtitle
              $isMetricSystemSelected={
                userAnswers?.measurementSystem === MeasurementSystem.METRIC
              }
            >
              <Trans
                i18nKey={language === Locale.ENGLISH ? subtitleText : ''}
                components={{
                  highlighted: <span />,
                  emoji: <SvgImage svg={emoji} width={24} />,
                }}
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                  weight,
                }}
              />
            </S.Subtitle>
          </>
        )}
        <form onSubmit={handleSubmit}>
          <S.InputContainer>
            <InputWithLabel
              value={email.value}
              isValid={isEmailValid}
              validationText={errorText}
              labelName={t`login.emailPlaceholder`}
              isAgeFlow={isAgeFlow}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              hasValidationIcon
            />
          </S.InputContainer>
          <S.Disclaimer>
            <S.LockIcon svg={lockIconSvg} width={20} />
            <Trans
              i18nKey={
                isAgeFlow
                  ? 'onboarding.email.disclaimerAge'
                  : 'onboarding.email.disclaimer'
              }
              components={[<br />]}
            />
          </S.Disclaimer>
          <S.ButtonContainer $isAgeFlow={isAgeFlow}>
            <Button type="submit" disabled={!email.value || !isEmailValid}>
              {t`actions.getMyPlan`}
            </Button>
          </S.ButtonContainer>
        </form>
      </S.Container>
      <Footer />
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </div>
  )
}
