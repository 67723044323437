import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import dayjs from 'dayjs'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { ProgressDate } from 'components/ProgressDate'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentUserMotivationEvent,
  selectLanguage,
} from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useNextStep } from 'hooks/useNextStep'

import {
  DAY_MONTH_FORMAT,
  DEFAULT_DATE_FORMAT,
  LOCALIZED_DATE_FORMAT,
  POLAND_DECLINATION,
  SLASH_DATE_FORMAT,
  getDatesArray,
} from 'helpers/datesForGraph'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT, Locale } from 'root-constants'

import { StylesReach as S } from './Reach.styles'
import { MOTIVATING_EVENTS, NO_MOTIVATING_EVENT, QUESTION } from './constants'

export const Reach: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const { isNewAdultFlow } = useCohortInfo()

  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const motivationEvent = useSelector(selectCurrentUserMotivationEvent)
  const language = useSelector(selectLanguage)

  const {
    goalWeight,
    currentWeight,
    goalMonthAndDay,
    goalDate,
    eventDate,
    weightDifference,
  } = useEventWeightInfo()

  const hasMotivationEvent = useMemo(
    () => motivationEvent !== NO_MOTIVATING_EVENT && !!eventDate,
    [eventDate, motivationEvent],
  )

  const unit = useMemo(() => t(`commonComponents.${measurementSystem}`), [
    measurementSystem,
    t,
  ])

  const isEventBeforePredictableDay = useMemo(
    () => goalDate.diff(dayjs(eventDate, DEFAULT_DATE_FORMAT), 'day') > 0,
    [goalDate, eventDate],
  )

  const eventDateFormatted = useMemo(() => {
    switch (language) {
      case Locale.POLISH:
        return POLAND_DECLINATION
      case Locale.JAPANESE:
        return SLASH_DATE_FORMAT
      default:
        return dayjs(goalDate, DEFAULT_DATE_FORMAT).year() > dayjs().year()
          ? LOCALIZED_DATE_FORMAT
          : DAY_MONTH_FORMAT
    }
  }, [goalDate, language])

  const lostWeightToEvent = useMemo(
    () =>
      !!eventDate && isEventBeforePredictableDay
        ? weightDifference
        : Number(currentWeight) - Number(goalWeight),
    [
      currentWeight,
      eventDate,
      goalWeight,
      isEventBeforePredictableDay,
      weightDifference,
    ],
  )

  const datesArray = useMemo(
    () => getDatesArray(goalDate, language, eventDateFormatted, false),
    [eventDateFormatted, goalDate, language],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])
  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container $isAdult={isNewAdultFlow}>
          <S.Title>
            <Trans
              i18nKey="onboarding.reach.title"
              values={{
                weight: t(`commonComponents.weight`, {
                  weight: goalWeight,
                  measurementSystem: unit,
                }),
              }}
              components={{
                progressDate:
                  datesArray.length > 1 ? (
                    <ProgressDate dates={datesArray} />
                  ) : (
                    <>{goalMonthAndDay}</>
                  ),
                strong: <strong />,
              }}
            />
          </S.Title>

          <S.Badge
            $hasMotivationEvent={hasMotivationEvent}
            $isJapanese={isJapaneseFlow}
            $isAdult={isNewAdultFlow}
          >
            {hasMotivationEvent ? (
              <Trans
                i18nKey="onboarding.reach.youWillLoseWithMotivation"
                values={{
                  event: t(MOTIVATING_EVENTS[motivationEvent]),
                  weight: Number(lostWeightToEvent).toFixed(0),
                  unit,
                }}
              />
            ) : (
              <Trans
                i18nKey="onboarding.reach.youWillLose"
                values={{
                  weight: Number(lostWeightToEvent).toFixed(0),
                  unit,
                }}
              />
            )}
          </S.Badge>

          <S.ReachGraphBelly
            $isAdult={isNewAdultFlow}
            $isJapanese={isJapaneseFlow}
          />

          <S.Description>{t('onboarding.reach.description')}</S.Description>
        </S.Container>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        $isAdult={isNewAdultFlow}
        type="button"
        onClick={handleClick}
      >
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </>
  )
}
