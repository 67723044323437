import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import backgroundImage from 'assets/images/boost-your-health-bg.png'

import { StyledBoostYourHealth as S } from './BoostYourHealth.styles'
import { QUESTION } from './constants'

export const BoostYourHealth: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <S.Image src={backgroundImage} />
        <S.Container isLarge>
          <S.PageTitle marginBottom={8}>
            <Trans i18nKey="onboarding.boostYourHealth.title" />
          </S.PageTitle>
          <S.Description>
            <Trans i18nKey="onboarding.boostYourHealth.text" />
          </S.Description>
        </S.Container>
      </S.ContainerBeforePinnedButton>

      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </>
  )
}
