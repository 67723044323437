import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIconAndCheckbox, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { StyledWeightGainEvents as S } from './WeightGainEvents.styles'
import { OPTION_VALUES, QUESTION, SELECT_NONE } from './constants'

export const WeightGainEvents: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const { optionData } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked && answer === SELECT_NONE) {
          setAnswers([answer])
          return
        }

        if (isChecked) {
          const newAnswers = answers.filter((item) => item !== SELECT_NONE)
          setAnswers([...newAnswers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={8}>
            {t('onboarding.weightGainEvents.title')}
          </PageTitle>
          <S.Subtitle>{t('onboarding.weightGainEvents.chooseAll')}</S.Subtitle>

          {(optionData?.length ? optionData : OPTION_VALUES).map(
            ({ value, text, imageUrl }) => (
              <AnswerWithIconAndCheckbox
                {...optionProps}
                key={value}
                value={value}
                imageSrc={imageUrl}
                theme={answerTheme.DANCEBIT}
                borderRadius="16px"
                height="80px"
                padding="16px"
                maxWidth="345px"
                imageAlignSelf="center"
                imageHeight="40px"
                imageWidth="40px"
                margin="0 0 16px 0"
                iconSrc={checkIcon}
                checkboxWidth="24px"
                checked={answers.includes(value)}
              >
                <Trans>{text}</Trans>
              </AnswerWithIconAndCheckbox>
            ),
          )}
        </Container>
      </ContainerBeforePinnedButton>
      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
