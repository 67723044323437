import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants'

import {
  OPTION_VALUES,
  OPTION_VALUES_LEGS,
  OPTION_VALUES_MENOPAUSE,
  QUESTION,
} from './constants'

export const DreamBody: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const { isLegsFlow, isMenopauseFlow } = useCohortInfo()

  const { optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isLegsFlow) return OPTION_VALUES_LEGS

    if (isMenopauseFlow) return OPTION_VALUES_MENOPAUSE

    return OPTION_VALUES
  }, [isMenopauseFlow, isLegsFlow, optionData])

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t(`onboarding.dreamBodyBelly.title`)}
      </PageTitle>

      {options.map(({ value, text, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          theme={answerTheme.DANCEBIT}
          margin="0 0 16px 0"
          disabled={isAnswersDisabled}
          color={Color.DARK}
          value={value}
          key={value}
          iconSrc={imageUrl}
          iconWidth={isMenopauseFlow ? '152px' : '100px'}
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
