import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantParentCohort } from 'root-redux/selects/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { SubscriptionItemInfo } from 'modules/purchase/components/UpgradeSubscriptionItem/SubscriptionItemInfo'
import { CURRENCY_SYMBOLS, PDF_COHORTS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { Cohort, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { TrialSubscriptionItemInfo } from './TrialSubscriptionItemInfo'
import { StyledUpgradeSubscriptionItem as S } from './UpgradeSubscriptionItem.styles'

export const UpgradeSubscriptionItem: React.FC = () => {
  const { t } = useTranslation()

  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  const { cohort, currency } = usePurchaseStore()

  const isPdfCohort = PDF_COHORTS.includes((parentCohort || cohort) as Cohort)

  const hasSevenDayTrial = useMemo(
    () => trialPeriodDays === SEVEN_DAY_TRIAL_DURATION,
    [trialPeriodDays],
  )

  return (
    <S.UpgradePlanBlock>
      <S.PlanTitle>
        {subscriptionBlockTitle || t('purchaseUpgrade.planTitle')}
      </S.PlanTitle>
      {hasSevenDayTrial ? (
        <TrialSubscriptionItemInfo />
      ) : (
        <SubscriptionItemInfo />
      )}
      {isPdfCohort && (
        <S.AdditionalInfoContainer>
          <S.BonusContainer>
            <S.FirstTitle>{t('purchaseUpgrade.firstTitle')}</S.FirstTitle>
            <S.SecondTitle>{t('purchaseUpgrade.secondTitle')}</S.SecondTitle>
          </S.BonusContainer>
          <S.BonusPrice>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: Number(0).toFixed(2),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.BonusPrice>
        </S.AdditionalInfoContainer>
      )}
    </S.UpgradePlanBlock>
  )
}
