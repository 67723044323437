import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { PageDescription } from 'components/PageDescription'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, ALPHABETIC_SYMBOLS_REGEXP, Cohort } from 'root-constants'

import { StyledName as S } from './Name.styles'
import { QUESTION } from './constants'

export const Name: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const [name, setName] = useState({ value: '', isValid: false })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleChange = useCallback(({ target: { value, validity } }) => {
    const isNameValid = !value.match(ALPHABETIC_SYMBOLS_REGEXP)
    if (isNameValid) {
      setName({
        value,
        isValid: validity.valid && value.trim(),
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    (event) => {
      event.preventDefault()
      handleContinue(name.value)
    },
    [name.value, handleContinue],
  )

  return (
    <>
      <form onSubmit={handleNextClick}>
        <S.Container $isAgeFlow={isAgeFlow}>
          <PageTitle marginBottom={10} isAgeFlow={isAgeFlow}>
            {t('onboarding.name.question')}
          </PageTitle>
          <PageDescription>
            {t(
              isAgeFlow
                ? 'onboarding.name.descriptionAgeFlow'
                : 'onboarding.name.description',
            )}
          </PageDescription>
          <S.InputContainer>
            <Input
              type="text"
              placeholder={isAgeFlow ? t('onboarding.name.placeholder') : ''}
              value={name.value}
              isAgeFlow={isAgeFlow}
              isContentCentered={!isAgeFlow}
              onChange={handleChange}
            />
          </S.InputContainer>
        </S.Container>

        <NavigationButton
          type="submit"
          disabled={!name.isValid}
          isAgeFlow={isAgeFlow}
        >
          {t('actions.continue')}
        </NavigationButton>
      </form>
    </>
  )
}
