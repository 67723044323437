export const QUESTION = 'What do you want to achieve?'

export const SELECT_ALL = {
  value: 'all',
  title: 'onboarding.mainGoal.all',
}

export const OPTION_VALUES_CLEAR = [
  {
    value: 'loseBellyFat',
    title: 'onboarding.mainGoal.tightenAbs',
  },
  {
    value: 'getSmallerWaist',
    title: 'onboarding.mainGoal.getSmallerWaist',
  },
  {
    value: 'toneBelly',
    title: 'onboarding.mainGoal.toneBelly',
  },
  {
    value: 'sculptAbs',
    title: 'onboarding.mainGoal.sculptAbs',
  },
]
export const OPTION_VALUES_BELLY = [
  {
    value: 'loseBellyFat',
    title: 'onboarding.mainGoal.loseBellyFat',
  },
  {
    value: 'getSmallerWaist',
    title: 'onboarding.mainGoal.getSmallerWaist',
  },
  {
    value: 'toneBelly',
    title: 'onboarding.mainGoal.toneBelly',
  },
  {
    value: 'sculptAbs',
    title: 'onboarding.mainGoal.sculptAbs',
  },
]
export const OPTION_VALUES_LEGS = [
  {
    value: 'loseLegFat',
    title: 'onboarding.mainGoal.loseLegFat',
  },
  {
    value: 'toneLegs',
    title: 'onboarding.mainGoal.toneLegs',
  },
  {
    value: 'getRidOfHipFat',
    title: 'onboarding.mainGoal.getRidOfHipFat',
  },
  {
    value: 'reduceCellulite',
    title: 'onboarding.mainGoal.reduceCellulite',
  },
]
