import styled, { keyframes } from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  $answer?: string
}

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const StyledReachGraphBelly = {
  Wrapper: styled.div`
    margin-bottom: 16px;
    margin-top: 24px;
  `,
  GraphContent: styled.div`
    position: relative;
    height: 262px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    padding: 30px 17px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  DateLabels: styled.div<{ $isDateWithYear: boolean }>`
    position: absolute;
    bottom: ${({ $isDateWithYear }) => ($isDateWithYear ? '5px' : '15px')};
    left: 50%;
    display: flex;
    align-items: ${({ $isDateWithYear }) =>
      $isDateWithYear ? 'flex-start' : 'center'};
    justify-content: space-between;
    width: 100%;
    padding: 0 22px;
    transform: translateX(-50%);
  `,
  Label: styled.p`
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    color: ${Color.DARK};

    &:last-child {
      color: #55d16f;
    }
  `,
  MiddleLabel: styled.div<TProps>`
    min-width: 110px;
    max-width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px 6px 6px;
    top: 10px;
    right: 10px;
    position: absolute;
    background-color: ${Color.WHITE};
    border-radius: 8px;
    box-shadow: 0px 2px 15px 0px rgba(38, 39, 48, 0.15);
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-delay: 2200ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    z-index: 3;
  `,
  LabelTitle: styled.span<TProps>`
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
  `,
  WithYear: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      color: #55d16f;
    }
  `,
  CurrentWeight: styled.div`
    position: absolute;
    gap: 5px;
    left: 6px;
    top: 10px;
    min-width: 54px;
    height: 30px;
    background: #f83e3e;
    padding: 4px 6px 6px 6px;
    border-radius: 8px;
    flex-direction: row;
    display: flex;
    align-items: center;
    z-index: 2;
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-delay: 300ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #f83e3e;
      border-radius: 0 0 5px 5px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: ${Color.WHITE};
    }
  `,
  GoalWeight: styled.div`
    position: absolute;
    gap: 5px;
    right: 8px;
    bottom: 70px;
    min-width: 54px;
    height: 30px;
    padding: 4px 6px 6px 6px;
    background: #55d16f;
    border-radius: 6px;
    flex-direction: row;
    display: flex;
    align-items: center;
    z-index: 2;
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-delay: 3200ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #55d16f;
      border-radius: 0 0 5px 5px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: ${Color.WHITE};
    }
  `,
}
