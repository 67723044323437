import styled from 'styled-components'

export const StyledScratchCard = {
  Container: styled.div`
    width: 311px;
    height: 311px;
    position: relative;
    user-select: none;
  `,
  Animation: styled.div`
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
  Card: styled.div`
    position: relative;
  `,
  Text: styled.div`
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
  Discount: styled.div`
    color: #e74c3c;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
  `,
  Description: styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #e74c3c;
    text-align: center;
    line-height: 32px;
  `,
}
