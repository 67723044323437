import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'firebase/auth'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import {
  countriesListIncludedVat,
  countriesListWithExcludedVat,
} from 'modules/purchase/constants'

import { Cohort } from 'root-constants'

const VAT_INCLUDED_COHORTS = [
  Cohort.DB_INTRO_DOP,
  Cohort.DB_INTRO_FAST,
  Cohort.DB_INTRO_2,
  Cohort.DANCEBIT_FLOW_1,
  Cohort.DB_INTRO,
  Cohort.DB_FLOW_7D,
  Cohort.DB_FLOW_2,
  Cohort.DB_CLEAR_INTRO_FAST,
  Cohort.DB_INTRO_FAST_18,
  Cohort.DB_INTRO_FAST_40,
  Cohort.DB_ADULT,
  Cohort.DB_ADULT_2,
  Cohort.DB_ADULT_18,
  Cohort.DB_ADULT_VIDEO,
  Cohort.GLORIA_INTRO_FAST,
  Cohort.EMAIL_INTRO_FAST,
  Cohort.CANCEL_INTRO_FAST,
  Cohort.COHORT_2024,
  Cohort.YARINAS,
  Cohort.SOMATIC_GOAL,
  Cohort.SOMATIC_AGE,
  Cohort.DB_TWO_PLANS,
  Cohort.DB_BELLY,
  Cohort.DB_LEGS,
  Cohort.DB_REF,
  Cohort.DB_BELLY_REF,
  Cohort.DB_INFLUENCER,
]

export const useVatInfo = (hasExcludedVat?: boolean): boolean => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const countryCode = useSelector(selectUserCountryCode)
  const cohortToUse = parentCohort || cohort

  return useMemo(() => {
    if (!VAT_INCLUDED_COHORTS.includes(cohortToUse as Cohort)) return false

    return (hasExcludedVat
      ? countriesListWithExcludedVat
      : [...countriesListIncludedVat, ...countriesListWithExcludedVat]
    ).includes(countryCode)
  }, [hasExcludedVat, countryCode, cohortToUse])
}
