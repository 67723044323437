import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import {
  BUTTON_WITH_ICON_STYLE_PROPS,
  GROWTHBOOK_EXPERIMENT,
} from 'root-constants'

import { StyledMotivationBelly as S } from './MotivationBelly.styles'
import { OPTION_VALUES, OPTION_VALUES_JAPANESE, QUESTION } from './constants'

export const MotivationBelly: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const { isNewAdultFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...BUTTON_WITH_ICON_STYLE_PROPS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.motivation.questionBelly" />
      </PageTitle>

      {(isJapaneseFlow || isNewAdultFlow
        ? OPTION_VALUES_JAPANESE
        : OPTION_VALUES
      ).map(({ title, value, icon }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          key={value}
          value={value}
          iconSrc={icon}
          theme={theme}
        >
          <Trans i18nKey={title} />
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
