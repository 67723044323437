import gobletIcon from 'assets/images/flow-belly/icons/goblet.svg'
import injuryIcon from 'assets/images/flow-belly/icons/injury.svg'
import gobletIconAdult from 'assets/images/goblet-adult.svg'
import happyIconAdult from 'assets/images/happy-icon-adult.svg'
import happyIcon from 'assets/images/happy-icon.svg'
import injuryIconAdult from 'assets/images/injury-adult.svg'
import likeIconAdult from 'assets/images/like-icon-adult.svg'
import likeIcon from 'assets/images/like-icon.svg'
import lovelyIconAdult from 'assets/images/lovely-icon-adult.svg'
import lovelyIcon from 'assets/images/lovely-icon.svg'

export const QUESTION =
  'After reaching your goal weight, how would you see yourself?'

export const enum ANSWER_VALUES {
  PROUD_OF_MYSELF = 'proudOfMyself',
  FEELING_GREAT = 'feelingGreat',
  BELIEVE_MYSELF = 'believeMyself',
  FEEL_EMPOWERED = 'feelEmpowered',
  WORRY_LESS = 'worryLess',
}

export const OPTION_VALUES = [
  {
    text: 'onboarding.seeYourself.proudOfMyself',
    value: ANSWER_VALUES.PROUD_OF_MYSELF,
    imageUrl: gobletIcon,
  },
  {
    text: 'onboarding.seeYourself.feelingGreat',
    value: ANSWER_VALUES.FEELING_GREAT,
    imageUrl: lovelyIcon,
  },
  {
    text: 'onboarding.seeYourself.believeMyself',
    value: ANSWER_VALUES.BELIEVE_MYSELF,
    imageUrl: happyIcon,
  },
  {
    text: 'onboarding.seeYourself.feelEmpowered',
    value: ANSWER_VALUES.FEEL_EMPOWERED,
    imageUrl: injuryIcon,
  },
  {
    text: 'onboarding.seeYourself.worryLess',
    value: ANSWER_VALUES.WORRY_LESS,
    imageUrl: likeIcon,
  },
]

export const OPTION_VALUES_ADULT = [
  {
    text: 'onboarding.seeYourself.proudOfMyself',
    value: ANSWER_VALUES.PROUD_OF_MYSELF,
    imageUrl: gobletIconAdult,
  },
  {
    text: 'onboarding.seeYourself.feelingGreat',
    value: ANSWER_VALUES.FEELING_GREAT,
    imageUrl: lovelyIconAdult,
  },
  {
    text: 'onboarding.seeYourself.believeMyself',
    value: ANSWER_VALUES.BELIEVE_MYSELF,
    imageUrl: happyIconAdult,
  },
  {
    text: 'onboarding.seeYourself.feelEmpowered',
    value: ANSWER_VALUES.FEEL_EMPOWERED,
    imageUrl: injuryIconAdult,
  },
  {
    text: 'onboarding.seeYourself.worryLess',
    value: ANSWER_VALUES.WORRY_LESS,
    imageUrl: likeIconAdult,
  },
]
