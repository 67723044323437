import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP } from 'root-constants'

export const useEmailInputField = (
  errorMessage?: string | null,
  resetErrorMessage?: () => void,
): TInputFieldHook => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    isFocused: false,
    validationText: '',
    firebaseError: '',
  })

  useEffect(() => {
    if (!EMAIL_REGEXP.test(email.value) && email.value !== '') {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        firebaseError: '',
      }))
      return
    }

    setEmail((prevState) => ({
      ...prevState,
      isValid: true,
      firebaseError: '',
    }))
  }, [email.value])

  useEffect(() => {
    setEmail((prevState) => ({
      ...prevState,
      validationText: !email.isValid ? t`login.invalidEmail` : '',
    }))
  }, [email.isFocused, email.isValid, t])

  useEffect(() => {
    if (errorMessage) {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        firebaseError: errorMessage,
      }))

      resetErrorMessage && resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [email, setEmail]
}
