import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { NavigationButton } from 'components/NavigationButton'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import dancingStyleIntroAgeFlow from 'assets/images/dancing-style-intro-age-flow.png'
import dancingStyleIntroAgeFlowWebp from 'assets/images/dancing-style-intro-age-flow.webp'
import dancingStyleIntroImage from 'assets/images/dancing-style-intro.png'
import dancingStyleIntroImageWebp from 'assets/images/dancing-style-intro.webp'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledDancingStylesIntro as S } from './DancingStylesIntro.styles'
import { QUESTION } from './constants'

export const DancingStylesIntro: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { imageUrls } = useDynamicOBConfig()

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        {!isAgeFlow && (
          <S.ImageContainer>
            {imageUrls?.length ? (
              <img src={imageUrls[0]} alt="dancing styles intro" />
            ) : (
              <picture>
                <source srcSet={dancingStyleIntroImageWebp} type="image/webp" />
                <img src={dancingStyleIntroImage} alt="dancing styles intro" />
              </picture>
            )}
          </S.ImageContainer>
        )}
        <S.Title $isAgeFlow={isAgeFlow}>
          {t(
            isAgeFlow
              ? `onboarding.dancingStylesIntro.questionAgeFlow`
              : `onboarding.dancingStylesIntro.question`,
          )}
        </S.Title>
        {isAgeFlow && (
          <S.AgeFlowPictureContainer>
            {imageUrls?.length ? (
              <img src={imageUrls[0]} alt="dancing styles intro" />
            ) : (
              <picture>
                <source
                  srcSet={dancingStyleIntroAgeFlowWebp}
                  type="image/webp"
                />
                <img
                  src={dancingStyleIntroAgeFlow}
                  alt="dancing styles intro"
                />
              </picture>
            )}
          </S.AgeFlowPictureContainer>
        )}
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        onClick={handleNextClick}
      >{t`actions.continue`}</NavigationButton>
    </div>
  )
}
