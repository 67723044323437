import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { SLIDES_FEMALE } from 'modules/purchase/components/PhotoResultV2/constants'

import starSvg from 'assets/images/sprite/star.svg'

import { StyledPhotoResultV2 as S } from './PhotoResultV2.styles'

Swiper.use([Pagination, Autoplay])

type TProps = {
  hasBackground?: boolean
  className?: string
}

export const PhotoResultV2: React.FC<TProps> = ({
  hasBackground = false,
  className,
}) => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  return (
    <S.ResultContainer className={className}>
      {customerReviewsTitle && <S.Title>{customerReviewsTitle}</S.Title>}
      <S.CarouselContainer $hasBackground={hasBackground}>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {(customerReviews.length ? customerReviews : SLIDES_FEMALE).map(
            ({ imageUrl, id, author, review }) => (
              <SwiperSlide key={id}>
                <S.PhotoResultWrapper>
                  <S.Image src={imageUrl} alt="before" />
                  <S.CardWrapper key={author} $hasBackground={hasBackground}>
                    <S.Card>
                      <S.Author>{t(author)}</S.Author>
                      <S.Rating>
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                      </S.Rating>
                    </S.Card>
                    <S.Text>{t(review, { context: measurementSystem })}</S.Text>
                  </S.CardWrapper>
                </S.PhotoResultWrapper>
              </SwiperSlide>
            ),
          )}
        </Carousel>
      </S.CarouselContainer>
    </S.ResultContainer>
  )
}
