import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router'

import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const PurchaseProvider: FC = () => {
  const { search } = useLocation()
  const hasPurchaseTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_284)
  const hasPurchaseFreeOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_988)
  const growthBookState = useGrowthBook()

  useEffect(() => {
    if (!growthBookState?.ready) return

    let nextPage = PageId.PURCHASE_WITHOUT_INTRO

    if (hasPurchaseTest) nextPage = PageId.PURCHASEV2

    if (hasPurchaseFreeOfferTest) nextPage = PageId.PURCHASE_FREE_OFFER

    goTo({
      pathname: nextPage,
      search,
    })
  }, [growthBookState, hasPurchaseFreeOfferTest, hasPurchaseTest, search])

  return <Spinner />
}
