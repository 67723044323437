import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIsPersonalDataAllowedAction } from 'root-redux/actions/user'
import {
  selectUserConfig,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { CookieConsentOption, EU_COUNTRY_CODES } from 'root-constants'

export const useCookieConsentAnswer = () => {
  const dispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)
  const config = useSelector(selectUserConfig)

  const isEUUser = EU_COUNTRY_CODES.includes(userCountryCode.toUpperCase())

  const { userCookieConsentAnswer } = useMemo(
    () => ({
      userCookieConsentAnswer: config?.cookie_consent as string[],
    }),
    [config],
  )

  const hasAdvertizingAndMarketingCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(
        CookieConsentOption.ADVERTIZING_AND_TARGETING,
      ),
    [userCookieConsentAnswer],
  )

  const hasFunctionalCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(CookieConsentOption.FUNCTIONAL_COOKIES),
    [userCookieConsentAnswer],
  )

  const hasAnalyticsAndPerformanceCookie = useMemo(
    () =>
      userCookieConsentAnswer?.includes(
        CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
      ),
    [userCookieConsentAnswer],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useEffect(() => {
    dispatch(setIsPersonalDataAllowedAction(isPersonalDataAllowed))
  }, [dispatch, isPersonalDataAllowed])

  return {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
    isPersonalDataAllowed,
  }
}
