import styled from 'styled-components'

import { Container } from 'components/Container'

export const BestShapeStyles = {
  Container: styled(Container)<{ $bgImageUrl: string }>`
    min-height: 560px;
    background-image: ${({ $bgImageUrl }) => `url(${$bgImageUrl})`};
    background-size: 375px 485px;
    background-repeat: no-repeat;
    background-position: bottom;
  `,
}
