import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPersonalPlan = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto 17px;
    padding: 16px 5px 16px 24px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background: linear-gradient(0deg, #f5f5f7, #f5f5f7), #f5f5f7;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  `,
  DiscountText: styled.p`
    padding: 6px 16px;
    background: #b1446b;
    border-radius: 8px;
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
    color: ${Color.WHITE};
    width: fit-content;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 20px;
    }
  `,
  Picture: styled.picture`
    max-width: 115px;
    max-height: 130px;
    min-width: 125px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 135px;
    }
  `,
}
