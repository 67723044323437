import proudOfYourselfImage from 'assets/images/be-proud-of-yourself.jpg'
import believeInYourselfImage from 'assets/images/believe-in-yourself.jpg'
import feelAmazingEveryDayImage from 'assets/images/feel-amazing-every-day.jpg'
import worryLessImage from 'assets/images/get-rid-of-body-worries.jpg'
import makeHealthyChoicesImage from 'assets/images/make-healthy-choices.jpg'

import { ANSWER_VALUES } from '../SeeYourself/constants'

export const QUESTION = 'beproud'

export const OPTIONS_VALUES = {
  [ANSWER_VALUES.PROUD_OF_MYSELF]: {
    title: 'onboarding.seeYourselfResult.proudOfYourself',
    description: 'onboarding.seeYourselfResult.proudOfYourselfDescription',
    imageUrl: proudOfYourselfImage,
  },
  [ANSWER_VALUES.FEELING_GREAT]: {
    title: 'onboarding.seeYourselfResult.feelAmazingEveryDay',
    description: 'onboarding.seeYourselfResult.feelAmazingEveryDayDescription',
    imageUrl: feelAmazingEveryDayImage,
  },
  [ANSWER_VALUES.BELIEVE_MYSELF]: {
    title: 'onboarding.seeYourselfResult.believeInYourself',
    description: 'onboarding.seeYourselfResult.believeInYourselfDescription',
    imageUrl: believeInYourselfImage,
  },
  [ANSWER_VALUES.FEEL_EMPOWERED]: {
    title: 'onboarding.seeYourselfResult.makeHealthyChoices',
    description: 'onboarding.seeYourselfResult.makeHealthyChoicesDescription',
    imageUrl: makeHealthyChoicesImage,
  },
  [ANSWER_VALUES.WORRY_LESS]: {
    title: 'onboarding.seeYourselfResult.worryLess',
    description: 'onboarding.seeYourselfResult.worryLessDescription',
    imageUrl: worryLessImage,
  },
}
