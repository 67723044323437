import styled from 'styled-components'

import { Container } from 'components/Container'

import stickerImg from 'assets/images/sale-sticker-1.png'

import { Color } from 'root-constants'

export const WelcomeBellyStyles = {
  Root: styled.div`
    height: 100%;
    background: radial-gradient(
      102.14% 70.44% at 34% 31.4%,
      #f3f9ff 0%,
      #e0f0ff 39.74%,
      #a4ceff 100%
    );
  `,
  Title: styled.h1`
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: ${Color.DARK};
    padding-top: 16px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.span`
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.GRAY_60};
    padding-top: 4px;
  `,
  Actions: styled.div<{ $isLegsFlow: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 16px;
    width: 100%;
    margin-bottom: 26px;
    margin-top: ${({ $isLegsFlow }) => ($isLegsFlow ? '20px' : '12px')};
  `,
  Image: styled.img<{ $isLegsFlow: boolean }>`
    object-fit: cover;
    width: 153px;
    height: ${({ $isLegsFlow }) => ($isLegsFlow ? '132px' : '115px')};
    position: absolute;
    top: ${({ $isLegsFlow }) => ($isLegsFlow ? '-12px' : '5px')};
    left: 0;
  `,
  Action: styled.div`
    position: absolute;
    left: 4px;
    bottom: 8px;
    border-radius: 16px;
    width: calc(100% - 8px);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  Terms: styled.div`
    z-index: 2;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: ${Color.GRAY_80};
    margin: 0 auto;
    padding-bottom: 16px;

    a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY_80};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Sticker: styled.div`
    position: absolute;
    top: 50px;
    right: 5px;
    width: 86px;
    height: 88px;
    background: url(${stickerImg});
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      color: ${Color.WHITE};
      text-align: center;
      text-transform: uppercase;
      transform: rotate(12deg);
      max-width: 66px;
      padding-bottom: 18px;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-style: italic;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -1px;
    }
  `,
  Container: styled(Container)`
    position: relative;
  `,
}
