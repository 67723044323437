import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { MAIN_GOALS } from 'root-constants'

import { StyledHowQuickly as S } from './HowQuickly.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const HowQuickly: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle marginBottom={30} isAgeFlow>
          <Trans
            i18nKey="onboarding.howQuickly.question"
            values={{
              context: isStayFitGoal
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </PageTitle>
      </S.TitleContainer>
      <S.Container>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.QUICKLY_AS_POSSIBLE}
        >
          <AnswerButton
            isAgeFlow
          >{t`onboarding.howQuickly.quicklyAsPossible`}</AnswerButton>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.SLOW_AND_STEADY}
        >
          <AnswerButton
            isAgeFlow
          >{t`onboarding.howQuickly.slowAndSteady`}</AnswerButton>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.BETWEEN_THE_TWO}
        >
          <AnswerButton
            isAgeFlow
          >{t`onboarding.howQuickly.betweenTheTwo`}</AnswerButton>
        </Option>
      </S.Container>
    </div>
  )
}
