import styled, { css } from 'styled-components'

import resultTrainingCardBg from 'assets/images/result-personal-card-bg.jpg'

import { Color } from 'root-constants'

type TResultTrainingCardProps = {
  $isAgeFlow: boolean
}

export const StyledResultTrainingCard = {
  Wrapper: styled.div<TResultTrainingCardProps>`
    position: relative;
    min-height: 410px;
    background: url(${resultTrainingCardBg}) no-repeat top center;
    background-size: cover;
    border-radius: 24px;
    display: flex;
    align-items: center;
    margin: 40px 0 30px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        min-height: 488px;
        align-items: flex-start;
        margin: 24px 0;
      `}
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  Sections: styled.div<TResultTrainingCardProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 0 30px 30px;
    text-align: left;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 24px 0 24px 24px;
      `}
  `,
  Section: styled.div<TResultTrainingCardProps>`
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        margin-bottom: 16px;
      `}
  `,
  Label: styled.div<TResultTrainingCardProps>`
    position: relative;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: #9499a0;
    color: #9499a0;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-weight: 600;
        line-height: 22px;
        color: ${Color.PRIMARY};
        text-decoration: none;
        padding: 0;
      `}

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: -13px;
      display: ${({ $isAgeFlow }) => ($isAgeFlow ? 'none' : 'block')};
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #9499a0;
    }
  `,
  Value: styled.div<TResultTrainingCardProps>`
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      `}

    p {
      padding-bottom: 4px;

      text-transform: ${({ $isAgeFlow }) =>
        $isAgeFlow ? 'capitalize' : 'none'};
    }
  `,
  ImageContainer: styled.div<TResultTrainingCardProps>`
    flex: 1;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        position: absolute;

        width: 55%;
        left: 165px;
        top: 75px;
      `}
  `,
  Image: styled.img`
    padding-right: 10px;
    image-rendering: -webkit-optimize-contrast;
  `,
}
