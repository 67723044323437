import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { UPSELL_BENEFITS } from 'modules/purchase/components/UpsellBenefits/constants'

import { Cohort, MAIN_GOALS } from 'root-constants'

import { StyledUpsellBenefits as S } from './UpsellBenefits.styles'

type TProps = {
  title: string
}

export const UpsellBenefits: React.FC<TProps> = ({ title }) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohortToUse = parentCohort || cohort

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  return (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey={title} />
      </S.Title>
      {UPSELL_BENEFITS.map(({ id, textPath }) => (
        <S.Text key={id}>
          <Trans
            i18nKey={textPath}
            values={{
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </S.Text>
      ))}
    </S.Wrapper>
  )
}
