import React from 'react'
import { useTranslation } from 'react-i18next'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { StyledFeaturedBlock as S } from './FeaturedBlock.styles'

export const FeaturedBlock: React.FC<{
  hasTitle?: boolean
  className?: string
}> = ({ hasTitle = true, className }) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      {hasTitle && <S.Title>{t`purchase1.featuredBlockTitle`}</S.Title>}
      <picture>
        <source srcSet={featuredImageWebp} type="image/webp" />
        <img src={featuredImagePng} alt="body" />
      </picture>
    </S.Container>
  )
}
