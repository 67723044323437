import styled, { css } from 'styled-components'

import resultSmile from 'assets/images/result-smile.png'

import { Color } from 'root-constants'

const commonLabelStyles = css`
  min-width: 130px;
  min-height: 34px;
  position: relative;
  margin-top: -18px;
  border-radius: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledReviewsNoOnboarding = {
  Root: styled.div`
    background: #f4f2ed;
    border-radius: 24px;
    padding: 24px 7px 16px 7px;
    margin-bottom: 40px;

    & div.swiper-pagination {
      transform: translateY(15px);
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.ORANGE};
    }
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 15px;
    text-align: center;

    & strong {
      color: ${Color.ORANGE};
    }
  `,
  Review: styled.div`
    padding-bottom: 24px;
  `,
  Cards: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(50% - 5px);
    z-index: 2;
  `,
  ImageContainer: styled.div`
    border: 4px solid #e9e7e2;
    border-radius: 16px;
    overflow: hidden;
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
  LabelBefore: styled.p`
    ${commonLabelStyles};
    background-color: ${Color.WHITE};
  `,
  LabelAfter: styled.p`
    ${commonLabelStyles};
    background: linear-gradient(180deg, #ffc530 0%, #ef843b 152.02%), #ffc530;
    color: ${Color.WHITE};

    &::after {
      content: '';
      position: absolute;
      right: -12px;
      bottom: 16px;
      z-index: 1;
      width: 34px;
      height: 34px;
      background-image: url(${resultSmile});
      background-size: cover;
    }
  `,
  Description: styled.div`
    padding: 0 13px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    margin-left: 5px;
    vertical-align: text-top;
  `,
  Reviewer: styled.div`
    display: flex;
    align-items: center;
  `,
  ReviewerName: styled.span`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    flex-grow: 1;
    color: ${Color.DARK};
  `,
  ReviewerIcon: styled.img`
    width: 19px;
    height: 18px;
  `,
}
