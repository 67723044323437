import React, { useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  CANCEL_OFFER_DEFAULT_DISCOUNT,
} from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectHighestDiscountPercentage } from 'modules/purchase/redux/selects/common'

import giftPinnedPng from 'assets/images/cancel-offer-gift-pinned.png'
import giftV2Png from 'assets/images/cancel-offer-gift-v2.png'
import giftV2Webp from 'assets/images/cancel-offer-gift-v2.webp'
import giftPng from 'assets/images/cancel-offer-gift.png'
import giftWebp from 'assets/images/cancel-offer-gift.webp'
import fireIcon from 'assets/images/sprite/fire.svg'

import { Cohort, GROWTHBOOK_EXPERIMENT, MAIN_GOALS } from 'root-constants'

import { StyledPersonalPlan as S } from './PersonalPlan.styles'

type TProps = {
  externalDiscountPercentage?: number | null
  onButtonClick: (event) => void
}

export const PersonalPlan: React.FC<TProps> = ({
  externalDiscountPercentage,
  onButtonClick,
}) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const highestDiscountPercentage = useSelector(selectHighestDiscountPercentage)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const { title } = useDynamicPaywallConfig()
  const { isTTClearFlow } = useCohortInfo()
  const isCancelOfferTestActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const elemForComparisonRef = useRef<HTMLDivElement>(null)

  const cohortToUse = parentCohort || cohort

  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const titleToShow = useMemo(() => {
    if (isClearCohort) {
      return 'purchaseCancelOffer.personalPlan.titleClearEdition'
    }

    if (isTTClearFlow) {
      return 'purchaseCancelOffer.personalPlan.getPlan'
    }
    return 'purchaseCancelOffer.personalPlan.title'
  }, [isClearCohort, isTTClearFlow])

  if (isCancelOfferTestActive) {
    const previousDiscount = externalDiscountPercentage
      ? externalDiscountPercentage - CANCEL_OFFER_ADDITIONAL_DISCOUNT
      : highestDiscountPercentage - CANCEL_OFFER_DEFAULT_DISCOUNT

    return (
      <div>
        {isButtonVisible && (
          <S.PinnedBanner>
            <S.Image src={giftPinnedPng} />
            <Button
              type="button"
              data-order="1"
              data-text="get_my_plan"
              onClick={onButtonClick}
            >
              <Trans i18nKey="actions.getMyPlan" />
            </Button>
          </S.PinnedBanner>
        )}
        <S.SpecialOffer>
          <Trans i18nKey="purchaseCancelOffer.personalPlan.specialOffer" />
          <SvgImage svg={fireIcon} width={24} height={24} />
        </S.SpecialOffer>
        <S.WrapperV2>
          <div>
            <S.PreviousDiscount>
              <Trans
                i18nKey="purchaseCancelOffer.personalPlan.previousDiscount"
                values={{
                  discountAmount: previousDiscount,
                }}
                components={{ span: <span /> }}
              />
            </S.PreviousDiscount>
            <S.TitleV2>
              {title || (
                <Trans
                  i18nKey="purchaseCancelOffer.personalPlan.title"
                  values={{
                    context: MAIN_GOALS.LOSE_WEIGHT,
                  }}
                  components={[<br />]}
                />
              )}
            </S.TitleV2>
            <S.DiscountTextV2 ref={elemForComparisonRef}>
              <Trans
                i18nKey="purchaseCancelOffer.personalPlan.upToDiscountText"
                values={{
                  discountAmount:
                    externalDiscountPercentage || highestDiscountPercentage,
                }}
              />
            </S.DiscountTextV2>
          </div>
          <S.PictureV2>
            <source srcSet={giftV2Webp} type="image/webp" />
            <img src={giftV2Png} alt="gift" />
          </S.PictureV2>
        </S.WrapperV2>
      </div>
    )
  }

  return (
    <S.Wrapper>
      <div>
        <S.Title>
          {title || (
            <Trans
              i18nKey={titleToShow}
              values={{
                context:
                  isStayFit && cohortToUse !== Cohort.DB_BELLY
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
              }}
              components={[<br />]}
            />
          )}
        </S.Title>
        <S.DiscountText>
          <Trans
            i18nKey="purchaseCancelOffer.personalPlan.upToDiscountText"
            values={{
              discountAmount:
                externalDiscountPercentage || highestDiscountPercentage,
            }}
          />
        </S.DiscountText>
      </div>
      <S.Picture>
        <source srcSet={giftWebp} type="image/webp" />
        <img src={giftPng} alt="gift" />
      </S.Picture>
    </S.Wrapper>
  )
}
