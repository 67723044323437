import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Slider } from 'components/Slider'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledCurrentWeight as S } from './CurrentWeightWithRuler.styles'
import { MinMaxWeight } from './constants'

type TProps = {
  isMetricSystemSelected?: boolean
  onChange: ({ target: { value, validity } }) => void
  value: string
}

export const CurrentWeightWithRuler: React.FC<TProps> = ({
  isMetricSystemSelected,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  useDisableOverscroll()

  const handleChange = useCallback(
    (newValue: number) => {
      onChange({
        target: {
          value: newValue.toString(),
          validity: { valid: true },
        },
      })
    },
    [onChange],
  )

  return (
    <S.Container>
      <S.WeightValue $isAgeFlow={isAgeFlow}>
        {value} {t(`commonComponents.${currentMeasurementSystem}`)}
      </S.WeightValue>

      <Slider
        value={Number(value)}
        onChange={handleChange}
        min={
          isMetricSystemSelected
            ? MinMaxWeight.MIN_WEIGHT_KG
            : MinMaxWeight.MIN_WEIGHT_LB
        }
        max={
          isMetricSystemSelected
            ? MinMaxWeight.MAX_WEIGHT_KG
            : MinMaxWeight.MAX_WEIGHT_LB
        }
        step={1}
        gap={10}
      />
    </S.Container>
  )
}
