import styled from 'styled-components'

import bgImage from 'assets/images/featured-bg.png'

export const StyledFeaturedBlock = {
  Title: styled.h2`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
    padding-left: 16px;
  `,
  Container: styled.div`
    background: url(${bgImage}) no-repeat center;
    background-size: cover;
    padding: 130px 8px 8px;
    border-radius: 30px;
    margin-bottom: 40px;
  `,
  Img: styled.img`
    padding-right: 8px;
  `,
  CarouselContainer: styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.7);
    overflow: hidden;

    & .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }
  `,
}
