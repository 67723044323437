import styled from 'styled-components'

import { LoadingButton } from 'components/LoadingButton'
import { OuterLink } from 'components/OuterLink'
import {
  TMenuProps,
  backdropStyles,
  menuCloseButtonStyles,
  sidebarStyles,
} from 'components/styles'

import { Color } from 'root-constants'

export const StyledHeader = {
  Root: styled.header`
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
  `,
  WhiteLogo: styled.img`
    max-width: 94px;
  `,
  BurgerWhite: styled.button`
    width: 18px;
    height: 14px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    & span {
      background: ${Color.WHITE};
      border-radius: 1px;
      width: 100%;
      height: 2px;
    }
  `,
  Backdrop: styled.div<TMenuProps>`
    ${backdropStyles}
  `,
  Menu: styled.nav<TMenuProps>`
    ${sidebarStyles}
  `,
  MenuCloseButton: styled.button`
    ${menuCloseButtonStyles}
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  LoadingButton: styled(LoadingButton)`
    border: 1px solid ${Color.WHITE};
  `,
  SupportAction: styled(OuterLink)`
    min-width: 88px;
    border: 1px solid ${Color.WHITE};
    background-color: transparent;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.WHITE};
    margin-right: 11px;
    cursor: pointer;
  `,
  HeaderButtons: styled.div`
    display: flex;
    align-items: center;
  `,
}
