import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledNavigationButton = {
  Root: styled.div<{
    $isAgeFlow: boolean
    $isStuck?: boolean
  }>`
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 30px 0;

    &[stuck] {
      background: ${({ $isAgeFlow }) =>
        $isAgeFlow
          ? 'linear-gradient(0deg, #e2f5fe 79.17%, rgba(226, 245, 254, 0) 100%)'
          : 'linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0.25%, #ffffff 26.14%)'};
    }
  `,

  NavigationButtonContainer: styled(Container)`
    min-width: 360px;
  `,
}
