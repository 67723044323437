import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Option, OptionType } from 'components/Option'

import {
  GET_CART_LIST,
  getSubscriptionWithCartAction,
  stopFetching,
} from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserCart } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { getAxonItem } from 'helpers/getAxonItem'

import { SubscriptionItemIntroOfferJa } from 'modules/purchase/components/SubscriptionItemIntroOfferJa'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
  selectSubscriptionPlanId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { GROWTHBOOK_EXPERIMENT, SubscriptionTags } from 'root-constants'

import { CartBlock } from '../CartBlock'
import { StyledSubscriptionsBlockIntroOffer as S } from './SubscriptionsBlockIntroOfferJa.styles'

type TProps = {
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  isCancelOffer?: boolean
  hasInnerDisclaimer?: boolean
  hasExternalDisclaimer?: boolean
  children: React.ReactNode
  customTitle?: React.ReactNode
  withTitle?: boolean
  className?: string
}

export const SubscriptionsBlockIntroOfferJa: React.FC<TProps> = ({
  elemForComparisonRef,
  isCancelOffer = false,
  hasInnerDisclaimer = true,
  hasExternalDisclaimer = true,
  children,
  customTitle,
  withTitle = true,
  className = '',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const userCart = useSelector(selectUserCart)
  const selectedSubscriptionPlanId = useSelector(selectSubscriptionPlanId)

  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)

  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  const tagForCartProducts = `${SubscriptionTags.NO_TAX},${SubscriptionTags.CART}`

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  useEffect(() => {
    if (selectedSubscriptionPlanId && hasObUpsellTest) {
      dispatch(
        getSubscriptionWithCartAction(
          selectedSubscriptionPlanId,
          tagForCartProducts,
        ),
      )
    }

    if (!hasObUpsellTest) {
      dispatch(stopFetching(GET_CART_LIST))
    }
  }, [
    dispatch,
    hasObUpsellTest,
    selectedSubscriptionPlanId,
    tagForCartProducts,
  ])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.WrapperContainer>
      <S.Wrapper className={className}>
        {(subscriptionBlockTitle && (
          <S.Title>{subscriptionBlockTitle}</S.Title>
        )) ||
          (withTitle && customTitle) ||
          (withTitle && (
            <S.Title
              ref={elemForComparisonRef}
            >{t`purchase1.subscription.title`}</S.Title>
          ))}
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.planId}
            onChange={handleChange}
            withoutMargin
          >
            <SubscriptionItemIntroOfferJa
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
              isCancelOffer={isCancelOffer}
            />
          </Option>
        ))}

        {hasObUpsellTest && userCart.length > 0 && <CartBlock />}
        <S.ButtonContainer>{children}</S.ButtonContainer>

        {hasObUpsellTest && userCart.length > 0 && <S.CartDisclaimer />}

        {hasInnerDisclaimer && !(hasObUpsellTest && userCart.length > 0) && (
          <S.SubscriptionDisclaimer />
        )}
      </S.Wrapper>
      {hasExternalDisclaimer && !(hasObUpsellTest && userCart.length > 0) && (
        <S.SubscriptionDisclaimer />
      )}
    </S.WrapperContainer>
  )
}
