import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setTestEnvironmentQueryParamAction } from 'root-redux/actions/user'

import { TEST_ENV_QUERY_PARAM } from 'root-constants'

export const useTestEnvQueryParameter = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    const URLParams = new URLSearchParams(document.location.search)
    const hasTestEnvQueryParam = URLParams.has(TEST_ENV_QUERY_PARAM)
    const testEnvQueryParamFromUrl = hasTestEnvQueryParam
      ? `&${TEST_ENV_QUERY_PARAM}`
      : ''

    dispatch(setTestEnvironmentQueryParamAction(testEnvQueryParamFromUrl))
  }, [dispatch])
}
