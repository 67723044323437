import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { REVIEWS_MAP } from 'pages/questions/PreparePlanBelly/constants'
import Swiper, { Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'

import { useViewportValue } from 'hooks/useViewportValue'

import { BodyAgeV2 } from 'modules/purchase/components/BodyAgeV2'
import { HelpsBenefits } from 'modules/purchase/components/HelpsBenefits'
import { HELPS_BENEFITS_DATA } from 'modules/purchase/components/HelpsBenefits/constants'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfoUpgrade } from 'modules/purchase/components/SecurityInfoUpgrade'
import { SubscriptionUpgradeBlock } from 'modules/purchase/components/SubscriptionUpgradeBlock'
import { WhatYouGet } from 'modules/purchase/components/WhatYouGet'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { purchaseUpgrade } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import ratingImage from 'assets/images/five-stars.png'
import arrowSvg from 'assets/images/sprite/right-yellow-arrow.svg'
import starSvg from 'assets/images/sprite/star.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  ScreenId,
  ScreenName,
  SubscriptionListType,
} from 'root-constants'

import { StyledPurchaseLiveChat as S } from './PurchaseLiveChat.styles'
import { REVIEW_SLIDES } from './constants'

Swiper.use([Pagination])

const TWO_YEAR = 24

export const PurchaseLiveChat: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const currency = useSelector(selectUserPaymentCurrency)

  const [isPricesFetchingStarted, setIsPricesFetchingStarted] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )

  const {
    fetchingActionsList,
    periodQuantity,
    amountToPay,
    upgradeFullPrice,
    subscriptions,
  } = usePurchaseStore()

  usePurchaseAnalytics({
    screenId: ScreenId.UPGRADE_PDF,
  })

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useViewportValue(ScreenName.LIVECHAT_UPGRADE)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(setScreenIdAction(ScreenId.UPGRADE_PDF))
    dispatch(getSubscriptionListAction(SubscriptionListType.UPGRADE))
    setIsPricesFetchingStarted(true)
  }, [dispatch])

  useEffect(() => {
    if (!isPricesFetchingStarted || !hasPrices) return

    if (!subscriptions.length) {
      goTo({
        pathname: PageId.DOWNLOAD,
        search,
      })
    }
  }, [subscriptions, search, isPricesFetchingStarted, hasPrices])

  useEffect(() => {
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  useEffect(() => {
    if (upgradeFullPrice && periodQuantity) {
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          subscription_upgrade_price: amountToPay || upgradeFullPrice,
          subscription_upgrade_duration: periodQuantity,
        }),
      )
    }
  }, [upgradeFullPrice, amountToPay, dispatch, currency, periodQuantity])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const upgradePlan = useCallback(
    (event) => {
      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.LIVECHAT_UPGRADE,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      addToCart()
    },
    [addToCart],
  )

  return (
    <>
      <S.Background>
        <S.Title>
          <Trans i18nKey="purchaseLiveChat.title" />
        </S.Title>

        <S.Users>
          <Trans i18nKey="purchaseLiveChat.users" />
        </S.Users>

        <S.UsersDescription>
          <Trans i18nKey="purchaseLiveChat.usersDescription" />
        </S.UsersDescription>

        <S.CarouselContainer>
          <Carousel
            spaceBetween={20}
            slidesPerView={1}
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
          >
            {REVIEW_SLIDES.map(({ img, name, review, weight, isBigReview }) => (
              <SwiperSlide key={name}>
                <S.Review>
                  <S.Image src={img} />

                  <S.ReviewText $isBigReview={!!isBigReview}>
                    <S.BeforeAfterBlock>
                      <span>
                        <Trans
                          i18nKey={weight}
                          values={{ context: 'beforeLbs' }}
                        />
                      </span>

                      <SvgImage svg={arrowSvg} width={16} height={20} />

                      <span>
                        <Trans
                          i18nKey={weight}
                          values={{ context: 'afterLbs' }}
                        />
                      </span>
                    </S.BeforeAfterBlock>

                    <S.Reviewer>
                      <S.Name>
                        <Trans i18nKey={name} />
                      </S.Name>

                      <S.Rating>
                        <SvgImage svg={starSvg} width={16} height={16} />
                        <SvgImage svg={starSvg} width={16} height={16} />
                        <SvgImage svg={starSvg} width={16} height={16} />
                        <SvgImage svg={starSvg} width={16} height={16} />
                        <SvgImage svg={starSvg} width={16} height={16} />
                      </S.Rating>
                    </S.Reviewer>

                    <S.Comment>
                      <Trans i18nKey={review} />
                    </S.Comment>
                  </S.ReviewText>
                </S.Review>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.CarouselContainer>
      </S.Background>

      <Container isLarge>
        <S.FindSuccess>
          <Trans i18nKey="purchaseLiveChat.findSuccess" />
        </S.FindSuccess>

        <SubscriptionUpgradeBlock>
          <S.Button
            type="button"
            data-order="1"
            data-text="get_my_plan"
            onClick={upgradePlan}
          >
            <Trans i18nKey="actions.continue" />
          </S.Button>
        </SubscriptionUpgradeBlock>

        <WhatYouGet isCardVisible={periodQuantity === TWO_YEAR} />

        <BodyAgeV2 />

        <S.Button
          type="button"
          data-order="2"
          data-text="get_my_plan"
          onClick={upgradePlan}
        >
          <Trans i18nKey="actions.continue" />
        </S.Button>

        <S.BenefitsTitle>
          {t`purchaseUpgrade.helpsBenefits.mainTitle`}
        </S.BenefitsTitle>
        {HELPS_BENEFITS_DATA.map(({ iconSrc, title, description }) => (
          <HelpsBenefits
            iconSrc={iconSrc}
            title={t(title)}
            description={t(description)}
            key={title}
          />
        ))}
      </Container>

      <S.ReviewBlock>
        <S.ReviewTitle>
          <Trans i18nKey="purchaseLiveChat.joinMillions" />

          <S.AppsRating>
            <S.Summary>
              <SvgImage svg={starSvg} width={16} height={16} />
              <S.App>
                <Trans i18nKey="purchaseLiveChat.appStoreRating" />
              </S.App>
            </S.Summary>

            <S.Summary>
              <SvgImage svg={starSvg} width={16} height={16} />
              <S.App>
                <Trans i18nKey="purchaseLiveChat.googlePlayRating" />
              </S.App>
            </S.Summary>
          </S.AppsRating>
        </S.ReviewTitle>

        <S.ReviewContainer>
          <Carousel
            slidesPerView={1}
            loop
            spaceBetween={8}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
          >
            {Object.entries(
              REVIEWS_MAP[Cohort.DANCEBIT_CHAT_UPGRADE_ANNUALS_PDF],
            ).map(([key, review]) => (
              <SwiperSlide key={key}>
                <S.Card>
                  <S.CardReviewer>
                    {t(`onboarding.preparePlanBelly.${key}Reviewer`)}
                    <S.CardRating src={ratingImage} alt="five stars rating" />
                  </S.CardReviewer>

                  <S.CardReview>{t(review)}</S.CardReview>
                </S.Card>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.ReviewContainer>
      </S.ReviewBlock>

      <Container isLarge>
        <SubscriptionUpgradeBlock>
          <S.Button
            type="button"
            data-order="3"
            data-text="get_my_plan"
            onClick={upgradePlan}
          >
            <Trans i18nKey="actions.continue" />
          </S.Button>
        </SubscriptionUpgradeBlock>

        <SecurityInfoUpgrade />

        <S.FeaturedBlock hasTitle={false} />
      </Container>

      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        tryAgain={() => addToCart()}
      />
    </>
  )
}
