import React from 'react'

import styled from 'styled-components'

import paypalLogo from 'assets/images/paypal.png'

import { Color } from 'root-constants'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $isTouched: boolean
  $isBellyFlow: boolean
}

export const StyledPayPalButton = {
  Root: styled.div`
    z-index: 13;
  `,
  Container: styled.div`
    width: 100%;
    max-width: 320px;
    min-height: 320px;
    max-height: 360px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};
    margin: 0 20px;
  `,
  ButtonsContainer: styled.div<{ $isBellyFlow: boolean }>`
    position: relative;
    z-index: 1;
    min-height: 55px;
    max-height: 55px;
  `,
  FakePaypalButton: styled.button<IButtonProps>`
    width: 100%;
    height: 55px;
    line-height: 55px;
    border-radius: ${({ $isBellyFlow }) => ($isBellyFlow ? `30px` : '16px')};
    border: none;
    background: #f9c456 url(${paypalLogo}) no-repeat center;
    background-size: 102px 28px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: 0;
    z-index: 1;
    filter: ${(props) =>
      props.$isTouched ? 'brightness(0.95)' : 'brightness(1)'};
  `,
}
