import styled from 'styled-components'

type THelpsBenefitsStyles = {
  $icon: string
}

export const StyledHelpsBenefits = {
  Wrapper: styled.div``,
  ListBody: styled.ul`
    margin-top: 30px;
  `,
  ListItem: styled.li<THelpsBenefitsStyles>`
    position: relative;
    padding-left: 54px;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 4px;
      width: 32px;
      height: 32px;
      background: ${(props) => `url(${props.$icon})`};
      background-size: contain;
    }
  `,
  ListItemTitle: styled.h3`
    padding-right: 13px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  `,
  ListItemText: styled.p`
    padding-right: 13px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  `,
}
