export const QUESTION = 'How active is your lifestyle?'

export const OPTION_VALUES = [
  {
    title: 'onboarding.activityLevel.sedentary',
    description: 'onboarding.activityLevel.sedentaryDescription',
    value: 'sedentary',
  },
  {
    title: 'onboarding.activityLevel.occasionallyActive',
    description: 'onboarding.activityLevel.occasionallyActiveDescription',
    value: 'occasionallyActive',
  },
  {
    title: 'onboarding.activityLevel.moderatelyActive',
    description: 'onboarding.activityLevel.moderatelyActiveDescription',
    value: 'moderatelyActive',
  },
  {
    title: 'onboarding.activityLevel.veryActive',
    description: 'onboarding.activityLevel.veryActiveDescription',
    value: 'veryActive',
  },
]
