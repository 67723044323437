import styled from 'styled-components'

import { Button } from 'components/Button'

import { Reviews } from 'modules/purchase/components/Reviews'

import { Color } from 'root-constants'

export const StyledUpsell = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px 20px;
  `,
  Title: styled.h1`
    padding: 5px 0 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  Subtitle: styled.h2`
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  `,
  Button: styled(Button)`
    max-width: 328px;
    margin: 16px 0 12px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  Link: styled.a`
    display: block;
    padding-bottom: 40px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-decoration: underline;
    color: ${Color.DISABLED};
    cursor: pointer;
  `,
  Disclaimer: styled.p`
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #51555b;
  `,
  SubscriptionDescription: styled.p`
    margin-bottom: 16px;
    padding: 24px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DISABLED};
    text-align: center;
    box-shadow: 0 8px 20px #e1e3ec;
    border-radius: 16px;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  UpsellItem: styled.div`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  PlanTitle: styled.div`
    padding: 24px 16px;
    border: 1px solid ${Color.PRIMARY};
    border-radius: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  `,
  AdditionalDiscount: styled.p`
    margin-top: 8px;
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #7ad164;
  `,
  DiscountBadge: styled.div`
    border-radius: 16px;
    background: #f2faf0;
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 24px 0;
    display: flex;

    strong {
      color: #7ad164;
    }

    svg {
      margin-right: 16px;
      width: 66px;
      height: 44px;
    }
  `,
  Price: styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.PRIMARY};
  `,
  OldPrice: styled.span`
    color: ${Color.DISABLED};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  PlanBenefitCard: styled.div`
    position: relative;
    border-radius: 24px;
    background: ${Color.LIGHT};
    padding: 24px 16px;
    margin-bottom: 16px;
    width: 328px;
    min-height: 226px;

    &:last-child {
      margin-bottom: 24px;
    }
  `,
  CardTitle: styled.h3`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${Color.DARK};
    max-width: 256px;
    margin-bottom: 8px;
  `,
  CardDescription: styled.p`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 162px;
  `,
  Img: styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 130px;
    width: 150px;
  `,
  BenefitsTitle: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  `,
  Reviews: styled(Reviews)`
    margin-bottom: 40px;
  `,
}
