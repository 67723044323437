import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

export const StyledDownload = {
  Title: styled.h1`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 16px 0 32px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Button: styled.button`
    ${StyledButtonBase};
    font-weight: 600;
    text-transform: uppercase;
  `,
  NoLink: styled.a`
    display: block;
    color: #81848c;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 24px;
    cursor: pointer;
    text-transform: uppercase;
  `,
  NoLinkBelly: styled.a`
    display: block;
    color: ${Color.GRAY_60};
    text-align: center;
    font-style: normal;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    padding: 10px;
    margin-top: 10px;
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)<{
    $isNewAdultFlow: boolean
  }>`
    height: calc(100% - 191px);

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{
    $isNewAdultFlow: boolean
  }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;

        button {
          text-transform: capitalize;
        }

        a {
          font-size: 13px;
          color: ${Color.GRAY_60};
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      `}
  `,
}
