import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import alertIcon from 'assets/images/alert-circle.svg'
import lockIcon from 'assets/images/protected-payment-icon.svg'

import { Color, INPUT_TEXT_COLOR, MediaBreakpoint } from 'root-constants'

type TPrimerInputProps = {
  $isInvalid: boolean
  $isBellyFlow: boolean
}

export const commonInputStyles = css<TPrimerInputProps>`
  color: ${Color.DARK};
  height: 51px;
  line-height: 51px;
  border-radius: 10px;
  background-color: transparent;
  border: ${({ $isBellyFlow }) =>
    $isBellyFlow ? `1px solid ${Color.GRAY_60}` : '1px solid #eaeaec'};
  font-size: 16px;
  font-weight: ${({ $isBellyFlow }) => ($isBellyFlow ? 400 : 500)};
  margin-bottom: 8px;
  transition: border-color 0.2s ease-out;
  width: 100%;
  outline: none;
  box-shadow: none;
  font-family: sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;

  &::placeholder {
    font-family: sans-serif;
    color: ${({ $isBellyFlow }) =>
      $isBellyFlow ? Color.GRAY_60 : INPUT_TEXT_COLOR};
    font-weight: ${({ $isBellyFlow }) => ($isBellyFlow ? 400 : 500)};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    ` 
          position: relative;
          color: #ff8484;
          border-color: #ff8484;
          background-color: #fff3f3;
          
           &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background: url(${alertIcon}) no-repeat;
            background-size: contain;
          }
    `}
`

export const StyledPrimerPaymentForm = {
  Wrapper: styled.div``,
  Form: styled.form`
    position: static;
  `,
  TitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  PaymentInfo: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
  `,
  Label: styled.span`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #b8bbc1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      width: 12px;
      height: 14px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
  InputContainer: styled.div<TPrimerInputProps>`
    ${commonInputStyles};
    display: flex;
    flex-direction: column;
  `,
  CardHolderInputContainer: styled.div<TPrimerInputProps>`
    position: relative;
    margin-bottom: 15px;

    input {
      padding-left: 15px;
      ${commonInputStyles};
    }

    ${({ $isInvalid }) =>
      $isInvalid &&
      ` 
           &::before {
            content: '';
            position: absolute;
            top: 17px;
            z-index: 1;
            right: 17px;
            width: 18px;
            height: 18px;
            background: url(${alertIcon}) no-repeat;
            background-size: contain;
          }
    `}
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardHolderInput: styled.input`
    margin-bottom: 0;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  SubmitButton: styled(Button)`
    max-width: 320px;
    margin-top: 15px;
  `,
  GooglePayButtonContainer: styled.div`
    & div {
      height: 56px;
    }

    & button {
      padding: 15px 15% 15px !important;
      box-shadow: none;
      outline: none !important;
      border-radius: 16px;
      font-size: 16px;
      min-width: 320px !important;
      background-color: ${Color.DARK} !important;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 0;
    }
  `,
  ApplePayButtonContainer: styled.div`
    & button {
      height: 56px !important;
      border-radius: 16px;
      font-size: 16px;
      min-width: 320px !important;

      @media (min-width: ${MediaBreakpoint.TABLET}px) {
        width: 320px !important;
        margin-top: 0;
      }
    }
  `,
  PayPalButton: styled.div<{ $isVisible: boolean; $isBellyFlow: boolean }>`
    border-radius: ${({ $isBellyFlow }) => ($isBellyFlow ? '30px' : '16px')};
    overflow: hidden;
    position: relative;
    z-index: 14;
    height: ${({ $isVisible }) => ($isVisible ? '55px' : 0)};

    &:active {
      opacity: 0.7;
    }
  `,
  PaymentButtonsContainer: styled.div`
    position: static;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: initial;
      display: flex;
    }
  `,
  MethodContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
