import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import {
  DEFAULT_PLAN_BENEFITS,
  SOMATIC_PLAN_BENEFITS,
} from 'modules/purchase/components/ReceivedPlan/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { Cohort, GROWTHBOOK_EXPERIMENT, MAIN_GOALS } from 'root-constants'

import { StyledReceivedPlan as S } from './ReceivedPlan.styles'

type TProps = {
  className?: string
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
}
export const ReceivedPlan: FC<TProps> = ({
  className = '',
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { isSomaticFlow } = useCohortInfo()

  const hasPurchaseFreeOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_988)

  const { benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  const isFlowTwoCohort = useMemo(() => cohortToUse === Cohort.DB_FLOW_2, [
    cohortToUse,
  ])

  const isStayFitFlow = useMemo(() => isStayFitGoal && !isFlowTwoCohort, [
    isFlowTwoCohort,
    isStayFitGoal,
  ])

  const title = useMemo(() => {
    if (benefitsBlockTitle) return benefitsBlockTitle

    if (isSomaticFlow) {
      return <Trans i18nKey="purchaseCancelOffer.personalPlan.titleSomatic" />
    }

    if (isFlowTwoCohort) {
      return <Trans i18nKey="purchaseCancelOfferMealPlan.products.title" />
    }

    return (
      <Trans
        i18nKey="purchase1.programDescriptionTitle"
        values={{
          context: isStayFitFlow ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT,
        }}
      />
    )
  }, [benefitsBlockTitle, isFlowTwoCohort, isSomaticFlow, isStayFitFlow])

  const benefitsList = useMemo(() => {
    if (isSomaticFlow) return SOMATIC_PLAN_BENEFITS

    return DEFAULT_PLAN_BENEFITS
  }, [isSomaticFlow])

  return (
    <S.ProgramPlanContainer className={className} ref={elemForComparisonRef}>
      <S.ProgramPlanTitle>{title}</S.ProgramPlanTitle>
      <ul>
        {benefits.length > 0 ? (
          benefits.map(({ id, text }) => (
            <S.ListItem key={id}>{text}</S.ListItem>
          ))
        ) : (
          <>
            <S.ListItem>
              <Trans
                i18nKey={benefitsList.first}
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
                components={[<strong />]}
              />
            </S.ListItem>
            {hasPurchaseFreeOfferTest && (
              <S.ListItem>
                <Trans i18nKey="purchaseWithoutIntro.whatYouGet.list.tonedLegs" />
              </S.ListItem>
            )}
            <S.ListItem>
              <Trans
                i18nKey={benefitsList.second}
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
              />
            </S.ListItem>
            <S.ListItem>
              <Trans i18nKey={benefitsList.third} />
            </S.ListItem>

            <S.ListItem>{t(benefitsList.fourth)}</S.ListItem>
            <S.ListItem>{t(benefitsList.fifth)}</S.ListItem>
          </>
        )}
      </ul>
    </S.ProgramPlanContainer>
  )
}
