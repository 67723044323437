import { useLayoutEffect } from 'react'

export const useDisableOverscroll = (): void => {
  useLayoutEffect(() => {
    document.body.style.overscrollBehavior = 'none'
    document.body.style.touchAction = 'none'

    return () => {
      document.body.style.overscrollBehavior = 'auto'
      document.body.style.touchAction = 'auto'
    }
  }, [])
}
