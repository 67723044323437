import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/purchase-price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-purchase-price-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected?: boolean
}

type TPriceProps = { $hasMxnCurrency: boolean }

const commonPriceStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

const crossedPriceStyles = css`
  color: ${Color.GRAYISH_BLUE};
  text-decoration: line-through;
  text-decoration-color: ${Color.GRAYISH_BLUE};
`

export const StyledSubscriptionItemVariant2 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 16px;
    background: ${Color.WHITE};

    border-radius: 16px;

    border: 1px solid #e3e4e8;

    ${({ $isSelected }) =>
      $isSelected &&
      `
      border: 1px solid ${Color.PRIMARY};

        `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<TProps>`
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${Color.GRAYISH_BLUE};
    padding-bottom: 2px;

    ${({ $isSelected }) =>
      $isSelected &&
      `
            color: ${Color.DARK}
        `};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span`
    ${crossedPriceStyles};

    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    text-decoration-color: #ff4329;
  `,
  PreviousPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    ${crossedPriceStyles};

    padding-right: 6px;
    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      `
      font-size: 12px;
  `};
  `,
  CurrentPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    color: ${Color.GRAYISH_BLUE};

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      `
      font-size: 12px;
  `};
  `,
  CustomPrice: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 92px;
    min-height: 42px;
    padding: 8px 4px;
    color: ${Color.GRAYISH_BLUE};
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ $isSelected }) =>
      $isSelected &&
      `
      background-image: url(${selectedPriceBadgeUrl});
      color: ${Color.DARK};
      
      & > span:first-child {
        color: ${Color.DARK};;
      }
  
  `};
  `,
  PriceValue: styled.span`
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    padding: 2px 0;
    display: flex;
  `,
  Currency: styled.span<TPriceProps>`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      `
      font-size: 12px;
  `}
  `,
  CentsBlock: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Cents: styled.span`
    font-size: 16px;
    font-weight: 700;
  `,
  Zero: styled.span<TPriceProps>`
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      `
      font-size: 26px;
  `}
  `,
  Period: styled.span<TPriceProps>`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      `
      margin-right: 0;
  `}
  `,
}
