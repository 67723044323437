import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { CircleProgress } from 'storybook-ui'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import ratingImage from 'assets/images/five-stars.png'
import firstReviewImgJa from 'assets/images/flow-belly/first-review-ja.png'
import firstReviewImg from 'assets/images/flow-belly/first-review.png'
import secondReviewImgJa from 'assets/images/flow-belly/second-review-ja.png'
import secondReviewImg from 'assets/images/flow-belly/second-review.png'
import thirdReviewImgJa from 'assets/images/flow-belly/third-review-ja.png'
import thirdReviewImg from 'assets/images/flow-belly/third-review.png'

import { Cohort, Color, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledPreparePlanBelly as S } from './PreparePlanBelly.styles'
import { QUESTION, REVIEWS_MAP, REVIEWS_MAP_CLEAR } from './constants'

export const PreparePlanBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const {
    isLegsFlow,
    isSomaticFlow,
    isMenopauseFlow,
    isTTClearFlow,
    isNewAdultFlow,
  } = useCohortInfo()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const review = useMemo(() => {
    if (isSomaticFlow) {
      return REVIEWS_MAP[Cohort.DB_SOMATIC]
    }

    if (isTTClearFlow) {
      return REVIEWS_MAP_CLEAR
    }

    return REVIEWS_MAP[Cohort.DB_BELLY]
  }, [isTTClearFlow, isSomaticFlow])

  const title = useMemo(() => {
    if (isSomaticFlow) {
      return 'onboarding.preparePlanV2.progressSubtitleSomatic'
    }

    if (isLegsFlow) {
      return 'onboarding.preparePlanV2.progressSubtitleLegs'
    }

    if (isMenopauseFlow) {
      return 'onboarding.preparePlanV2.progressSubtitleMenopause'
    }

    return 'onboarding.preparePlanV2.progressSubtitle'
  }, [isMenopauseFlow, isLegsFlow, isSomaticFlow])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const goToNextPage = useCallback(() => {
    handleContinue(QUESTION)
  }, [handleContinue])

  const { counter } = useCounter(true, {
    duration: 6000,
    callback: goToNextPage,
  })

  return (
    <S.Container $isJapanese={isJapaneseFlow} $isNewAdultFlow={isNewAdultFlow}>
      <S.ProgressContainer>
        <CircleProgress
          value={counter}
          height="120px"
          margin="16px"
          strokeColorEmpty={isNewAdultFlow ? Color.WHITE : Color.GRAY_10}
          strokeColorFilled={Color.PRIMARY}
          fontSize="24px"
          percentageFontSize="17px"
          fontWeight="700"
          percentageFontWeight="600"
        />
        <p>
          <Trans i18nKey={title} />
        </p>
      </S.ProgressContainer>

      <S.PeopleChooseTitle>
        <strong>
          <Trans i18nKey="onboarding.tenMillionPeople.question" />
        </strong>
        <br />
        {t`onboarding.tenMillionPeople.subtitle`}
      </S.PeopleChooseTitle>

      <Carousel
        slidesPerView={1}
        loop
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        spaceBetween={8}
      >
        <SwiperSlide>
          <S.Card $isJapanese={isJapaneseFlow}>
            <S.ReviewTitle>
              <S.CardReviewerImage
                src={isJapaneseFlow ? firstReviewImgJa : firstReviewImg}
                width={48}
              />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.firstReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>
              {t(review.first, {
                context: measurementSystem,
              })}
            </S.CardReview>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card $isJapanese={isJapaneseFlow}>
            <S.ReviewTitle>
              <S.CardReviewerImage
                src={isJapaneseFlow ? secondReviewImgJa : secondReviewImg}
                width={48}
              />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.secondReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>{t(review.second)}</S.CardReview>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card $isJapanese={isJapaneseFlow}>
            <S.ReviewTitle>
              <S.CardReviewerImage
                src={isJapaneseFlow ? thirdReviewImgJa : thirdReviewImg}
                width={48}
              />
              <S.CardReviewer>
                {t`onboarding.preparePlanBelly.thirdReviewer`}
                <S.CardRating src={ratingImage} alt="five stars rating" />
              </S.CardReviewer>
            </S.ReviewTitle>
            <S.CardReview>{t(review.third)}</S.CardReview>
          </S.Card>
        </SwiperSlide>
      </Carousel>
    </S.Container>
  )
}
