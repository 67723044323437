import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'
import { CustomerReviews } from 'components/CustomerReviews'

import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledPurchaseIntroOffer = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 0;
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 50px auto;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 38px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  IntroOfferDisclaimer: styled(IntroOfferDisclaimer)`
    margin-top: 16px;
  `,
  SubscriptionsBlockIntroOffer: styled(SubscriptionsBlockIntroOffer)`
    margin-bottom: 0;
  `,
  FeaturedBlockContainer: styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  ButtonContainer: styled.div`
    padding-bottom: 24px;
  `,
  CustomerReviews: styled(CustomerReviews)`
    margin-bottom: 30px;
  `,
  TimerSubheaderWithTimer: styled(SubheaderWithTimer)`
    margin: 18px auto 50px;
    padding: 20px 16px;

    & span:first-child {
      display: flex;
    }

    button {
      width: 94px;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
      padding: 5px 10px;
      line-height: 15px;
    }
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
