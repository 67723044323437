import workPressureJa from 'assets/images/flow-belly/icons/briefcase-ja.svg'
import busyLifeJa from 'assets/images/flow-belly/icons/busy-life-ja.svg'
import busyLife from 'assets/images/flow-belly/icons/busy-life.svg'
import covidJa from 'assets/images/flow-belly/icons/covid-ja.svg'
import covid from 'assets/images/flow-belly/icons/covid.svg'
import divorceJa from 'assets/images/flow-belly/icons/divorce-ja.svg'
import divorce from 'assets/images/flow-belly/icons/divorce.svg'
import financialChallengesJa from 'assets/images/flow-belly/icons/financial-challenges-ja.svg'
import financialChallenges from 'assets/images/flow-belly/icons/financial-challenges.svg'
import injuryJa from 'assets/images/flow-belly/icons/injury-ja.svg'
import injury from 'assets/images/flow-belly/icons/injury.svg'
import noneJa from 'assets/images/flow-belly/icons/none-ja.svg'
import none from 'assets/images/flow-belly/icons/none.svg'
import otherJa from 'assets/images/flow-belly/icons/other-events-ja.svg'
import other from 'assets/images/flow-belly/icons/other-events.svg'
import pregnancyJa from 'assets/images/flow-belly/icons/pregnancy-ja.svg'
import pregnancy from 'assets/images/flow-belly/icons/pregnancy.svg'
import slowMetabolismJa from 'assets/images/flow-belly/icons/slow-metabolism-ja.svg'
import slowMetabolism from 'assets/images/flow-belly/icons/slow-metabolism.svg'
import workPressure from 'assets/images/flow-belly/icons/work-pressure.svg'

export const QUESTION =
  'Have any of the following events led to weight gain in the last few years?'

export const SELECT_NONE = 'none'

export const OPTION_VALUES = [
  {
    text: 'onboarding.weightGainEvents.workPressure',
    value: 'workPressure',
    imageUrl: workPressure,
  },
  {
    text: 'onboarding.weightGainEvents.busyLife',
    value: 'busyLife',
    imageUrl: busyLife,
  },
  {
    text: 'onboarding.weightGainEvents.divorce',
    value: 'divorce',
    imageUrl: divorce,
  },
  {
    text: 'onboarding.weightGainEvents.slowMetabolism',
    value: 'slowMetabolism',
    imageUrl: slowMetabolism,
  },
  {
    text: 'onboarding.weightGainEvents.pregnancy',
    value: 'pregnancy',
    imageUrl: pregnancy,
  },
  {
    text: 'onboarding.weightGainEvents.financialChallenges',
    value: 'financialChallenges',
    imageUrl: financialChallenges,
  },
  {
    text: 'onboarding.weightGainEvents.covid',
    value: 'covid',
    imageUrl: covid,
  },
  {
    text: 'onboarding.weightGainEvents.injury',
    value: 'injury',
    imageUrl: injury,
  },
  {
    text: 'onboarding.weightGainEvents.other',
    value: 'other',
    imageUrl: other,
  },
  {
    text: 'onboarding.weightGainEvents.none',
    value: SELECT_NONE,
    imageUrl: none,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    text: 'onboarding.weightGainEvents.workPressure',
    value: 'workPressure',
    imageUrl: workPressureJa,
  },
  {
    text: 'onboarding.weightGainEvents.busyLife',
    value: 'busyLife',
    imageUrl: busyLifeJa,
  },
  {
    text: 'onboarding.weightGainEvents.divorce',
    value: 'divorce',
    imageUrl: divorceJa,
  },
  {
    text: 'onboarding.weightGainEvents.slowMetabolism',
    value: 'slowMetabolism',
    imageUrl: slowMetabolismJa,
  },
  {
    text: 'onboarding.weightGainEvents.pregnancy',
    value: 'pregnancy',
    imageUrl: pregnancyJa,
  },
  {
    text: 'onboarding.weightGainEvents.financialChallenges',
    value: 'financialChallenges',
    imageUrl: financialChallengesJa,
  },
  {
    text: 'onboarding.weightGainEvents.covid',
    value: 'covid',
    imageUrl: covidJa,
  },
  {
    text: 'onboarding.weightGainEvents.injury',
    value: 'injury',
    imageUrl: injuryJa,
  },
  {
    text: 'onboarding.weightGainEvents.other',
    value: 'other',
    imageUrl: otherJa,
  },
  {
    text: 'onboarding.weightGainEvents.none',
    value: SELECT_NONE,
    imageUrl: noneJa,
  },
]
