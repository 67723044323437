import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { IInputFieldState } from 'models/inputField.model'

import { PageId } from 'page-constants'
import { ALPHABETIC_SYMBOLS_REGEXP, MIN_NAME_LENGTH } from 'root-constants'

export type TInputName = Omit<IInputFieldState, 'firebaseError'>

type TInputNameHook = [TInputName, Dispatch<SetStateAction<TInputName>>]

export const useNameField = (): TInputNameHook => {
  const { t } = useTranslation()
  const userAnswersCollection = useSelector(selectAnswers)

  const [userName, setUserName] = useState<TInputName>({
    value: '',
    isValid: true,
    isFocused: false,
    validationText: '',
  })

  useEffect(() => {
    if (userAnswersCollection && userAnswersCollection[PageId.NAME]) {
      setUserName((prevState) => ({
        ...prevState,
        value: userAnswersCollection[PageId.NAME] as string,
        isValid: true,
      }))
    }
  }, [userAnswersCollection])

  useEffect(() => {
    if (
      userName.value.trim() !== '' &&
      !ALPHABETIC_SYMBOLS_REGEXP.test(userName.value) &&
      userName.value.trim().length === MIN_NAME_LENGTH
    ) {
      setUserName((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: t`login.invalidName`,
      }))
    } else {
      setUserName((prevState) => ({
        ...prevState,
        isValid: true,
        validationText: '',
      }))
    }
  }, [userName.value, t])

  return [userName, setUserName]
}
