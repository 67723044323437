import { TRecommendationCard } from 'components/RecommendationCard/constants'

import checkMarkIcon from 'assets/images/flow-belly/icons/check-mark-icon-yellow.svg'
import icon from 'assets/images/flow-belly/icons/toning-pilates.svg'
import image from 'assets/images/flow-belly/ultimate-toning-pilates-image.png'

import { Color } from 'root-constants'

export const QUESTION = 'Ultimate Toning Pilates'

export const cardStyleParams: TRecommendationCard = {
  backgroundColor: 'linear-gradient(58deg, #8378EF -24.03%, #C9C6F6 47.34%)',
  headerBackgroundColor: '#FFD684',
  badgeImage: icon,
  headerTextColor: '#2E3258',
  headerText: 'onboarding.toningPilates.headerText',
  title: 'onboarding.toningPilates.cardTitle',
  titleColor: Color.WHITE,
  image,
  checkMarkImage: checkMarkIcon,
  points: [
    'onboarding.toningPilates.benefitPoints.ultimateToningPilates',
    'onboarding.toningPilates.benefitPoints.weightLoss',
    'onboarding.toningPilates.benefitPoints.increaseMuscle',
  ],
}
