import { useEffect, useRef } from 'react'

import { eventLogger } from 'services/eventLogger.service'

import { browserHistory } from 'browser-history'

export const useBackNavigationTracking = (): void => {
  const historyLength = useRef(window.history?.state?.idx)

  useEffect(() => {
    browserHistory.listen((event) => {
      if (event.action === 'PUSH') {
        historyLength.current = window.history?.state?.idx
      }
    })
  }, [])

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.idx < historyLength.current) {
        eventLogger.logQuestionPageBackTap()
      }

      historyLength.current = event.state?.idx
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])
}
