import React, { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import commentWithHeartPng from 'assets/images/comment-with-heart.png'
import commentWithHeartWebp from 'assets/images/comment-with-heart.webp'
import starSvg from 'assets/images/sprite/star.svg'

import { CUSTOMER_REVIEWS } from 'root-constants'

import { StyledReviewsCancelOffer as S } from './ReviewsCancelOffer.styles'

type TProps = {
  children: ReactNode
}

export const ReviewsCancelOffer: React.FC<TProps> = ({ children }) => {
  const { t } = useTranslation()
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          {customerReviewsTitle || (
            <Trans
              i18nKey="purchaseCancelOffer.customerReviews.title"
              components={[<strong />]}
            />
          )}
        </S.Title>
        <S.Picture>
          <source srcSet={commentWithHeartWebp} type="image/webp" />
          <img src={commentWithHeartPng} alt="comment-with-heart" />
        </S.Picture>
      </S.Header>

      {(customerReviews.length ? customerReviews : CUSTOMER_REVIEWS).map(
        ({ author, review }) => (
          <S.Comment key={author}>
            <S.Author>{t(author)}</S.Author>
            <S.Rating>
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
            </S.Rating>
            <S.Text>{t(review)}</S.Text>
          </S.Comment>
        ),
      )}

      {children}
    </S.Wrapper>
  )
}
