import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'

import blueCheck from 'assets/images/check-icon-rounded.svg'
import cardBgSmall from 'assets/images/image-with-wallet-small.png'

import { Color } from 'root-constants'

export const StyledPurchaseCancelOfferRef = {
  Container: styled(Container)`
    &:last-child {
      padding-bottom: 20px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    margin-top: 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  AppDescription: styled.h1`
    margin-bottom: 24px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 0 auto 50px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 17px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  FeaturedBlockContainer: styled.div`
    margin: 50px auto 40px;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.GRAY_60};
  `,
  RefundCardSmall: styled.div`
    position: relative;
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(124deg, #a6f1df 11.31%, #50b5c3 77.01%);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    min-height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: 50px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${cardBgSmall});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  PhotoResultV3: styled(PhotoResultV3)`
    padding: 0;
    margin: 0 auto 50px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
