import gladIcon from 'assets/images/glad-icon.svg'
import irritableIcon from 'assets/images/irritable-icon.svg'
import meditationIcon from 'assets/images/meditation-icon.svg'
import sadIcon from 'assets/images/sad-icon.svg'

export const QUESTION = 'Do you get stuck in negative emotions?'

export const OPTION_VALUES = [
  {
    value: 'yes',
    text: 'onboarding.storeEmotion.yes',
    iconSrc: irritableIcon,
  },
  {
    value: 'sometimes',
    text: 'onboarding.storeEmotion.sometimes',
    iconSrc: sadIcon,
  },
  {
    value: 'rarely',
    text: 'onboarding.storeEmotion.rarely',
    iconSrc: meditationIcon,
  },
  {
    value: 'no',
    text: 'onboarding.storeEmotion.no',
    iconSrc: gladIcon,
  },
]
