import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReachTarget = {
  Wrapper: styled.div`
    position: relative;
    height: 275px;
    margin: 0 auto 40px;
  `,
  TextImage: styled.img`
    position: absolute;
    height: 65px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  `,
  Text: styled.p`
    position: absolute;
    width: 100%;
    text-align: center;
    top: 8px;
    z-index: 2;
    color: ${Color.WHITE};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  WeightBox: styled.div`
    position: absolute;
    z-index: 1;
    right: 24px;
    top: 95px;
    display: flex;
    width: 157px;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 4px solid #fff7a5;
    background: linear-gradient(
      251deg,
      #636363 0%,
      #a8a8a8 49.85%,
      #555 90.51%
    );
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  Weight: styled.div`
    color: #eeee84;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
  `,
  BgImage: styled.img`
    position: absolute;
    right: 0;
    top: 39px;
    height: 228px;
    z-index: 0;
    border: 8px solid transparent;
    background: linear-gradient(to right, white, white),
      linear-gradient(to right, #e43991, #ffc5e3);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  `,
}
