import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIconAndCheckbox, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { StyledHealthCondition as S } from './HealthCondition.styles'
import {
  OPTION_VALUES,
  OPTION_VALUES_LEGS,
  QUESTION,
  SELECT_NONE,
} from './constants'

export const HealthCondition: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const { optionData } = useDynamicOBConfig()
  const { isLegsFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked && answer === SELECT_NONE) {
          setAnswers([answer])
          return
        }

        if (isChecked) {
          const newAnswers = answers.filter((item) => item !== SELECT_NONE)
          setAnswers([...newAnswers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    return isLegsFlow ? OPTION_VALUES_LEGS : OPTION_VALUES
  }, [isLegsFlow, optionData])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={8}>
            {t('onboarding.injuriesIssues.title')}
          </PageTitle>
          <S.Subtitle>{t('onboarding.injuriesIssues.description')}</S.Subtitle>

          {options.map(({ imageUrl, text, value }) => (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={value}
              value={value}
              imageSrc={imageUrl}
              theme={answerTheme.DANCEBIT}
              borderRadius="16px"
              height="100px"
              padding="16px 16px 16px 8px"
              maxWidth="345px"
              imageAlignSelf="center"
              imageHeight="100x"
              imageWidth="100px"
              margin="0 0 16px 0"
              iconSrc={checkIcon}
              checkboxWidth="24px"
              checked={answers.includes(value)}
            >
              <Trans>{text}</Trans>
            </AnswerWithIconAndCheckbox>
          ))}
        </Container>
      </ContainerBeforePinnedButton>
      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
