import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { SubscriptionItemIntroOfferV2 } from 'modules/purchase/components/SubscriptionItemIntroOfferV2'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { SubscriptionsBlockIntroOfferV2 as S } from './SubscriptionsBlockIntroOfferV2.styles'

type TProps = {
  children?: React.ReactNode
}

export const SubscriptionsBlockIntroOfferV2: React.FC<TProps> = ({
  children,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      <S.Title>
        {subscriptionBlockTitle || t`purchase1.subscription.title`}
      </S.Title>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItemIntroOfferV2
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}
      {children}
    </S.Wrapper>
  )
}
