import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import { ButtonJa } from 'components/ButtonJa'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow, selectUserAge } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { PersonalPlanJa } from 'modules/purchase/components/PersonalPlanJa'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultJa/constant'
import { ReviewsJa } from 'modules/purchase/components/ReviewsJa'
import { SubscriptionsBlockIntroOfferJa } from 'modules/purchase/components/SubscriptionsBlockIntroOfferJa'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'
import arrowIcon from 'assets/images/sprite/arrow-right.svg'

import { PageId } from 'page-constants'
import {
  ABC_TEST_VARIANTS,
  GROWTHBOOK_EXPERIMENT,
  Locale,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutModal } from '../CheckoutModal'
import { StyledPurchaseCancelOfferRef as S } from './PurchaseCancelOfferRefJapan.styles'

export const PurchaseCancelOfferRefJapan: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isStayFit = useSelector(selectIsStayFitFlow)
  const age = useSelector(selectUserAge)

  const {
    currentMeasurementSystem,
    fetchingActionsList,
    subscriptions,
    userAnswers,
    selectedSubscriptionId,
    language,
  } = usePurchaseStore()

  const {
    subtitle,
    benefits,
    benefitsBlockTitle,
    buttonText,
  } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenId: ScreenId.CANCEL_DEFAULT,
    isAmplitudeEvent: true,
  })

  const hasIncludedVat = useVatInfo()
  const { isTTClearFlow } = useCohortInfo()
  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)
  const hasCancelOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const hasIncreasedPricesEn = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1294)
  const hasIncreasedPricesLocales = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1295,
  )
  const variantOfModalCheckout = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_1291,
    ABC_TEST_VARIANTS.VARIANT_A,
  )

  const isFitMePrices = language === Locale.ENGLISH || hasIncreasedPricesLocales

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.CANCEL_OFFER, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasIncreasedPricesEn) {
      pricesTags = `${SubscriptionTags.INCREASED_INTRO},${pricesTags}`
    }

    if (isFitMePrices) {
      pricesTags = `${SubscriptionTags.INCREASED},${pricesTags}`
    }

    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))
    dispatch(setScreenIdAction(ScreenId.CANCEL_DEFAULT))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
      ),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [
    age,
    dispatch,
    hasIncreasedPricesEn,
    hasHighPrices,
    hasIncludedVat,
    isFitMePrices,
  ])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.CANCEL_OFFER,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <PersonalPlanJa />
          <S.Container>
            <S.AppDescription>
              {subtitle || (
                <Trans
                  i18nKey="purchaseCancelOffer.appDescription"
                  components={[<strong />, <br />]}
                />
              )}
            </S.AppDescription>

            <SubscriptionsBlockIntroOfferJa
              hasInnerDisclaimer={false}
              isCancelOffer
            >
              <S.Button
                data-order={hasCancelOfferTest ? '2' : '1'}
                data-text="get_my_plan"
                type="button"
                onClick={handleShowPayment}
              >
                <S.ButtonText>
                  {buttonText || t`actions.getMyPlan`}
                  <SvgImage svg={arrowIcon} height={24} />
                </S.ButtonText>
              </S.Button>
            </SubscriptionsBlockIntroOfferJa>

            <S.RefundCardSmall>
              <Trans i18nKey="purchaseRef.getRefund" />
            </S.RefundCardSmall>

            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || t`purchaseCancelOffer.appBenefits.title`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ text, id }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey={
                          isTTClearFlow
                            ? 'purchaseCancelOffer.appBenefits.targetWeightClear'
                            : 'purchaseCancelOffer.appBenefits.targetWeight'
                        }
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                          weight: userAnswers?.[PageId.GOAL_WEIGHT],
                          unit: t(
                            `commonComponents.${currentMeasurementSystem}`,
                          ),
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.sustainSuccess" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.moreEnergy" />
                    </S.ListItem>
                    {!isStayFit && (
                      <S.ListItem>
                        <Trans i18nKey="purchaseCancelOffer.appBenefits.betterHealth" />
                      </S.ListItem>
                    )}
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.motivation" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.happierYou" />
                    </S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
          </S.Container>

          <HowItWorks
            buttonOrder={hasCancelOfferTest ? 3 : 2}
            onButtonClick={handleShowPayment}
          />

          <S.Container>
            <S.PhotoResult
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <S.FAQList />
            <ReviewsJa />
            <ButtonJa
              type="button"
              data-order={hasCancelOfferTest ? '4' : '3'}
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              <S.ButtonText>
                {buttonText || t`actions.getMyPlan`}
                <SvgImage svg={arrowIcon} height={24} />
              </S.ButtonText>
            </ButtonJa>

            <S.FeaturedBlockContainer>
              <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
              <picture>
                <source srcSet={featuredImageWebp} type="image/webp" />
                <img src={featuredImagePng} alt="body" />
              </picture>
            </S.FeaturedBlockContainer>
            <IntroOfferDisclaimer />
          </S.Container>
        </S.Wrapper>
      )}

      {variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_A ? (
        <S.CheckoutContainer $isVisible={isCheckoutShown}>
          <CheckoutSeparateMethods
            isCheckoutShown={isCheckoutShown}
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOfferPage
            key={selectedSubscriptionId}
          />
        </S.CheckoutContainer>
      ) : (
        <S.CheckoutContainer $isVisible={isCheckoutShown}>
          <CheckoutModal
            key={selectedSubscriptionId}
            isCheckoutShown={isCheckoutShown}
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOfferPage
          />
        </S.CheckoutContainer>
      )}
    </>
  )
}
