import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import cardIcon from 'assets/images/card-icon.svg'
import alertIcon from 'assets/images/red-alert-circle.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TPrimerInputProps = {
  $isInvalid: boolean
}

export const commonInputStyles = css<TPrimerInputProps>`
  position: relative;
  height: 56px;
  border-radius: 8px;
  background-color: #2f3643;
  border: none;
  transition: border-color 0.2s ease-out;
  width: 100%;
  outline: none;
  box-shadow: none;
  font-family: sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;

  &::placeholder {
    font-family: sans-serif;
    color: ${Color.WHITE};
    font-weight: 600;
    line-height: 24px;
    margin-top: 10px;
  }

  &::before {
    content: attr(data-placeholder);
    position: absolute;
    top: 4px;
    left: 16px;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.GRAY_60};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    ` 
          color: #E74C3C;
          
           &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background: url(${alertIcon}) no-repeat;
            background-size: contain;
          }
    `}
`

export const StyledPrimerPaymentDarkForm = {
  Wrapper: styled.div`
    position: relative;
    border-radius: 12px;
    background: linear-gradient(106deg, #3d4554 1.61%, #333a49 93.48%);
    padding: 54px 8px 8px;
    margin-bottom: 16px;

    &::before {
      content: '';
      width: 36px;
      height: 22px;
      position: absolute;
      top: 16px;
      right: 8px;
      background-image: ${`url(${cardIcon})`};
      background-repeat: no-repeat;
      background-size: 100%;
    }
  `,
  Form: styled.form`
    position: static;
  `,
  PaymentMethodImageContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  `,
  PaymentMethodImage: styled.img`
    width: 292px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 16px;
  `,
  Input: styled.div<TPrimerInputProps>`
    position: absolute;
    top: 6px;
    line-height: 56px;
    font-size: 17px;
    font-weight: 600;
    color: ${Color.WHITE};
  `,
  InputContainer: styled.div<TPrimerInputProps>`
    ${commonInputStyles};
    display: flex;
    flex-direction: column;
  `,
  CardHolderInputContainer: styled.div<TPrimerInputProps>`
    position: relative;
    margin-bottom: 15px;

    input {
      padding-left: 15px;
      ${commonInputStyles};
    }

    ${({ $isInvalid }) =>
      $isInvalid &&
      ` 
    &::before {
      content: '';
      position: absolute;
      top: 17px;
      z-index: 1;
      right: 17px;
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
      }
      `}
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
  `,
  CardHolderInput: styled.input`
    margin-bottom: 0;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  InfoIcon: styled.img`
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
  `,
  TooltipContainer: styled.div`
    position: absolute;
    bottom: -79px;
    right: -11px;
    z-index: 2;
    width: 183px;
    height: 105px;
  `,
  Tooltip: styled.img`
    width: 100%;
    height: 100%;
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  SubmitButton: styled(Button)<{ disabledBackgroundColor: string }>`
    max-width: 320px;
    margin-top: 15px;

    &:disabled {
      background-color: ${({ disabledBackgroundColor }) =>
        `${disabledBackgroundColor} !important`};
    }
  `,
  GooglePayButtonContainer: styled.div`
    & div {
      height: 56px;
    }

    & button {
      padding: 15px 15% 15px !important;
      box-shadow: none;
      outline: none !important;
      border-radius: 16px;
      font-size: 16px;
      min-width: 320px !important;
      background-color: ${Color.DARK} !important;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 0;
    }
  `,
  ApplePayButtonContainer: styled.div`
    & button {
      height: 56px !important;
      border-radius: 16px;
      font-size: 16px;
      min-width: 320px !important;

      @media (min-width: ${MediaBreakpoint.TABLET}px) {
        width: 320px !important;
        margin-top: 0;
      }
    }
  `,
  ButtonTitle: styled.span`
    width: 100%;
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
  `,
  PayPalButton: styled.div<{
    $isVisible: boolean
    $isBellyFlow: boolean
  }>`
    border-radius: ${({ $isBellyFlow }) => ($isBellyFlow ? '30px' : '16px')};
    overflow: hidden;
    position: relative;
    z-index: 14;
    height: ${({ $isVisible }) => ($isVisible ? '56px' : 0)};
    width: 100%;
    margin-top: 16px;

    &:active {
      opacity: 0.7;
    }
  `,
  PaymentButtonsContainer: styled.div`
    position: static;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: initial;
      display: flex;
    }
  `,
  MethodContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    width: 100%;
  `,
}
