import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledEmailBelly = {
  InputContainer: styled.div`
    width: 100%;
    margin-bottom: 24px;
  `,
  Disclaimer: styled.p`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY_80};
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 8px;
  `,
  Container: styled(Container)`
    margin-top: 16px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
