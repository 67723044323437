import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledScratch = {
  Wrapper: styled.div<{ $hasMarginTop?: boolean; $isJapanese: boolean }>`
    margin-top: ${({ $hasMarginTop }) => $hasMarginTop && '16px'};

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
  `,
  Label: styled.div`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-top: 32px;
  `,
  Modal: styled.div<{ $isShown: boolean }>`
    position: fixed;
    top: 0;
    z-index: ${({ $isShown }) => ($isShown ? 2 : -1)};
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
    transition-duration: 250ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-delay: 1s;
  `,
  Animation: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    max-width: 375px;
    min-width: 375px;
  `,
  ModalContent: styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px 24px 0 0;
    background: ${Color.WHITE};
    height: 408px;
    width: 100%;
    padding: 40px 16px 24px 16px;

    strong {
      color: #e74c3c;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 32px;
    }

    svg {
      margin-bottom: 24px;
      min-width: 48px;
      min-height: 48px;
    }
  `,
  ModalTitle: styled.h1`
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
  `,
  ModalSubtitle: styled.h3`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  Disclaimer: styled.p`
    color: ${Color.GRAY_80};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    padding-top: 16px;
    margin-bottom: 32px;
    border-top: 1px solid ${Color.GRAY_20};
  `,
  ButtonContainer: styled.div`
    position: relative;
    z-index: 3;
    width: 327px;
    margin: 0 auto;
  `,
}
