import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIconAndCheckbox, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { IMPROVE_AREAS_VALUES } from '../ImproveAreas/constants'
import { OPTION_VALUES, QUESTION } from './constants'

export const ImproveAreasSomatic: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const { optionData } = useDynamicOBConfig()

  const countOfAllOptions = IMPROVE_AREAS_VALUES.FULL_BODY.split(',').length

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        const options = value.split(',')
        if (isChecked) {
          options.length === countOfAllOptions
            ? setAnswers([...options])
            : setAnswers([...answers, ...options])
        } else {
          const newValues =
            options.length === countOfAllOptions
              ? []
              : answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, countOfAllOptions, pageId],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    return OPTION_VALUES
  }, [optionData])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={24}>
            {t('onboarding.improveAreas.question')}
          </PageTitle>

          {options.map(({ imageUrl, text, value }) => (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={value}
              value={value}
              imageSrc={imageUrl}
              theme={answerTheme.DANCEBIT}
              borderRadius="16px"
              height="100px"
              padding="16px 16px 16px 8px"
              maxWidth="345px"
              imageAlignSelf="center"
              imageHeight="100x"
              imageWidth="100px"
              margin="0 0 16px 0"
              iconSrc={checkIcon}
              checkboxWidth="24px"
              checked={
                answers.includes(value) || answers.length === countOfAllOptions
              }
            >
              <Trans>{text}</Trans>
            </AnswerWithIconAndCheckbox>
          ))}
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
