import curvyImgLegs from 'assets/images/flow-belly/dream-body-curvy-legs.png'
import curvyImg from 'assets/images/flow-belly/dream-body-curvy.png'
import healthyImgLegs from 'assets/images/flow-belly/dream-body-healthy-legs.png'
import healthyImg from 'assets/images/flow-belly/dream-body-healthy.png'
import thinImgLegs from 'assets/images/flow-belly/dream-body-thin-legs.png'
import thinImg from 'assets/images/flow-belly/dream-body-thin.png'
import tonedImgLegs from 'assets/images/flow-belly/dream-body-toned-legs.png'
import tonedImg from 'assets/images/flow-belly/dream-body-toned.png'

export const QUESTION = 'What is your dream body?'

export const OPTION_VALUES = [
  { value: 'thin', text: 'onboarding.dreamBodyBelly.thin', imageUrl: thinImg },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImg,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImg,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImg,
  },
]

export const OPTION_VALUES_LEGS = [
  {
    value: 'thin',
    text: 'onboarding.dreamBodyBelly.thin',
    imageUrl: thinImgLegs,
  },
  {
    value: 'toned',
    text: 'onboarding.dreamBodyBelly.toned',
    imageUrl: tonedImgLegs,
  },
  {
    value: 'curvy',
    text: 'onboarding.dreamBodyBelly.curvy',
    imageUrl: curvyImgLegs,
  },
  {
    value: 'healthy',
    text: 'onboarding.dreamBodyBelly.healthy',
    imageUrl: healthyImgLegs,
  },
]
