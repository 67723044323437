import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

export const StyledName = {
  InputContainer: styled.div`
    margin-top: 24px;
  `,
  Container: styled(Container)`
    margin-top: 16px;
  `,
  PageDescription: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
