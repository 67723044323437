import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { CharacteristicCard } from 'components/CharacteristicCard'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSocialProof as S } from './SocialProof.styles'
import { CHARACTERISTIC_COMMENTS, QUESTION } from './constants'

export const SocialProof: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { title, optionData } = useDynamicOBConfig()

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <Container>
      <PageTitle>
        {title ? (
          <Trans>{title}</Trans>
        ) : (
          <Trans i18nKey="onboarding.socialProof.title" components={[<br />]} />
        )}
      </PageTitle>

      <S.CardsContainer>
        {optionData?.length
          ? optionData.map(({ imageUrl, text, additionalText }) => (
              <CharacteristicCard
                key={text}
                img={imageUrl}
                name={text}
                text={additionalText}
              />
            ))
          : CHARACTERISTIC_COMMENTS.map(({ name, danceStyles, img, text }) => (
              <CharacteristicCard
                key={name}
                danceStyles={danceStyles}
                img={img}
                name={name}
                text={text}
              />
            ))}
      </S.CardsContainer>

      <NavigationButton type="button" onClick={handleClick}>
        <Trans i18nKey="onboarding.socialProof.continue" />
      </NavigationButton>
    </Container>
  )
}
