import styled from 'styled-components'

type TAccordionProps = {
  $isOpen: boolean
}

export const AccordionStyles = {
  Accordion: styled.div`
    margin-bottom: 50px;
  `,
  AccordionItem: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    border-bottom: 1px solid #dde0e6;
  `,
  AccordionButton: styled.button<TAccordionProps>`
    width: 100%;
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
    align-items: flex-start;
    background-color: transparent;
    border: none;

    svg {
      transform: rotate(${({ $isOpen }) => ($isOpen ? '-180deg' : 'none')});
      transition: all 0.3s ease;
    }
  `,
  AccordionTitle: styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  `,
  AccordionContent: styled.p<TAccordionProps>`
    overflow: hidden;
    max-height: ${({ $isOpen }) => ($isOpen ? '300px' : '0')};
    margin-bottom: ${({ $isOpen }) => ($isOpen ? '16px' : '0')};
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    transition: all 0.3s ease;
  `,
}
