import iconFifth from 'assets/images/free-offer-review-icon-fifth.png'
import iconFirst from 'assets/images/free-offer-review-icon-first.png'
import iconFourth from 'assets/images/free-offer-review-icon-fourth.png'
import iconSecond from 'assets/images/free-offer-review-icon-second.png'
import iconSeventh from 'assets/images/free-offer-review-icon-seventh.png'
import iconSixth from 'assets/images/free-offer-review-icon-sixth.png'
import iconThird from 'assets/images/free-offer-review-icon-third.png'
import imgFifth from 'assets/images/free-offer-review-photo-fifth.png'
import imgFirst from 'assets/images/free-offer-review-photo-first.png'
import imgFourth from 'assets/images/free-offer-review-photo-fourth.png'
import imgSecond from 'assets/images/free-offer-review-photo-second.png'
import imgSeventh from 'assets/images/free-offer-review-photo-seventh.png'
import imgSixth from 'assets/images/free-offer-review-photo-sixth.png'
import imgThird from 'assets/images/free-offer-review-photo-third.png'

export const CUSTOMER_REVIEWS = [
  {
    icon: iconFirst,
    name: 'purchaseFreeOffer.reviews.first.name',
    date: 'purchaseFreeOffer.reviews.first.date',
    image: imgFirst,
    likesCount: 132,
    commentsCount: 36,
  },
  {
    icon: iconSecond,
    name: 'purchaseFreeOffer.reviews.second.name',
    date: 'purchaseFreeOffer.reviews.second.date',
    image: imgSecond,
    likesCount: 74,
    commentsCount: 17,
  },
  {
    icon: iconThird,
    name: 'purchaseFreeOffer.reviews.third.name',
    date: 'purchaseFreeOffer.reviews.third.date',
    image: imgThird,
    likesCount: 47,
    commentsCount: 21,
  },
  {
    icon: iconFourth,
    name: 'purchaseFreeOffer.reviews.fourth.name',
    date: 'purchaseFreeOffer.reviews.fourth.date',
    image: imgFourth,
    likesCount: 236,
    commentsCount: 54,
  },
  {
    icon: iconFifth,
    name: 'purchaseFreeOffer.reviews.fifth.name',
    date: 'purchaseFreeOffer.reviews.fifth.date',
    image: imgFifth,
    likesCount: 18,
    commentsCount: 8,
  },
  {
    icon: iconSixth,
    name: 'purchaseFreeOffer.reviews.sixth.name',
    date: 'purchaseFreeOffer.reviews.sixth.date',
    image: imgSixth,
    likesCount: 108,
    commentsCount: 27,
  },
  {
    icon: iconSeventh,
    name: 'purchaseFreeOffer.reviews.seventh.name',
    date: 'purchaseFreeOffer.reviews.seventh.date',
    image: imgSeventh,
    likesCount: 9,
    commentsCount: 3,
  },
]
