import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import arrowImg from 'assets/images/arrow-down.svg'

import { CDN_FOLDER_LINK, Locale, MeasurementSystem } from 'root-constants'

import { StyledLoseWeightGraphAge as S } from './LoseWeightGraphBelly.styles'
import { ANIMATION_PATH } from './constants'

type TProps = {
  className?: string
}

export const LoseWeightGraphBelly: FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const lang = useSelector(selectLanguage)

  const [graphContentRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
    animationName: 'goalChart',
  })

  const isImperial = useMemo(
    () => answers?.measurementSystem === MeasurementSystem.IMPERIAL,
    [answers?.measurementSystem],
  )

  const [tipBefore, tipAfter] = useMemo(() => {
    const currentWeight = answers?.currentWeight || '55'
    const goalWeight = answers?.goalWeight || '50'

    const unit = isImperial
      ? t('commonComponents.imperial')
      : t('commonComponents.metric')

    return [`${currentWeight} ${unit}`, `${goalWeight} ${unit}`]
  }, [answers?.currentWeight, answers?.goalWeight, isImperial, t])

  return (
    <S.Wrapper className={className}>
      <S.GraphContent>
        <div ref={graphContentRef} />

        <S.TipNow>{tipBefore}</S.TipNow>
        <S.TipAfter>{tipAfter}</S.TipAfter>
        <S.WrapperWeightLoss>
          <Trans i18nKey="result.ageLoseWeightChart.weightLoss" />
        </S.WrapperWeightLoss>
        <S.WeightStaysOff $isSpanish={lang === Locale.SPANISH}>
          <Trans i18nKey="result.ageLoseWeightChart.weightStaysOff" />
        </S.WeightStaysOff>
        <S.ExpectedResultsContainer>
          <S.ExpectedResults>{t`result.ageLoseWeightChart.expectedResults`}</S.ExpectedResults>
          <S.GraphImg src={arrowImg} />
        </S.ExpectedResultsContainer>
      </S.GraphContent>
    </S.Wrapper>
  )
}
