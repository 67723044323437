import styled from 'styled-components'

export const StyledSleep = {
  OptionsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  `,
  Img: styled.img`
    margin: 0 auto 24px;
    min-height: 200px;
  `,
}
