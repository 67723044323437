import styled from 'styled-components'

import { Color } from 'root-constants'

type TUpsellSubscriptionItemV2Props = {
  $isChecked: boolean
  $hasMaxWidth?: boolean
}

export const StyledUpsellSubscriptionItemV2 = {
  Wrapper: styled.div<TUpsellSubscriptionItemV2Props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: ${({ $isChecked }) =>
      $isChecked ? `2px solid ${Color.PRIMARY}` : '2px solid #E9E9E9'};
    transition: border 0.2s ease-out;
    padding: 12px;
    height: 48px;
    margin-bottom: 8px;
  `,
  ToggleContainer: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
  `,
  PlanName: styled.span<TUpsellSubscriptionItemV2Props>`
    max-width: ${({ $hasMaxWidth }) => ($hasMaxWidth ? '178px' : 'none')};
    margin-left: 12px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ $isChecked }) => ($isChecked ? Color.DARK : Color.GRAY_60)};
    transition: color 0.2s ease-out;
  `,

  PriceBlock: styled.span<TUpsellSubscriptionItemV2Props>`
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ $isChecked }) => ($isChecked ? Color.DARK : Color.GRAY_60)};
    transition: color 0.2s ease-out;
  `,
}
