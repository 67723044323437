import React, { useCallback, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { PageTitle } from 'components/PageTitle'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useSkipStep } from 'hooks/useSkipStep'

import { TPageProps } from 'models/common.model'

import graphImage from 'assets/images/intensity-graph.png'

import { PageId } from 'page-constants'

import { StyledBalance as S } from './Balance.styles'
import { QUESTION } from './constants'

export const Balance: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const skipStep = useSkipStep({
    nextPagePath,
    pageId,
  })

  useLayoutEffect(() => {
    if (answers?.[PageId.ENERGY] === 'high') {
      skipStep('')
    }
  }, [answers, nextPagePath, pageId, skipStep])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={24}>
            <Trans i18nKey="onboarding.balance.title" />
          </PageTitle>
          <S.GraphTitle>{t('onboarding.balance.graphTitle')}</S.GraphTitle>
        </S.Container>

        <S.ContentWrapper>
          <img src={graphImage} alt="High intensity" />
          <S.Intensity>{t('onboarding.balance.intensity')}</S.Intensity>
        </S.ContentWrapper>

        <S.Container>
          <S.Description>
            <Trans i18nKey="onboarding.balance.description" />
          </S.Description>
        </S.Container>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        {t`purchaseCancelOffer.questionPage.gotIt`}!
      </S.NavigationButtonBelly>
    </>
  )
}
