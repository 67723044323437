import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants'

import {
  OPTION_VALUES,
  OPTION_VALUES_LEGS,
  OPTION_VALUES_MENOPAUSE,
  QUESTION,
} from './constants'

export const PhysicalBuild: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { isLegsFlow, isMenopauseFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isLegsFlow) return OPTION_VALUES_LEGS

    if (isMenopauseFlow) return OPTION_VALUES_MENOPAUSE

    return OPTION_VALUES
  }, [isLegsFlow, isMenopauseFlow, optionData])

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t(
          isLegsFlow
            ? 'onboarding.physicalBuild.titleLegs'
            : 'onboarding.physicalBuild.title',
        )}
      </PageTitle>
      {options.map(({ value, text, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          theme={answerWithIconTheme.DANCEBIT}
          margin="0 0 16px 0"
          borderRadius="16px"
          color={Color.DARK}
          iconWidth={isMenopauseFlow ? '152px' : '100px'}
          key={value}
          value={value}
          iconAlignSelf="flex-start"
          iconSrc={imageUrl}
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
