import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import halfStarIcon from 'assets/images/sprite/half-star.svg'
import peopleIcon from 'assets/images/sprite/people.svg'
import rewardsIcon from 'assets/images/sprite/rewards.svg'
import starIcon from 'assets/images/sprite/star-smooth.svg'

import { StyledAppStats as S } from './AppStats.styles'

Swiper.use([Autoplay])

export const AppStats: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <S.Title>
        <Trans
          i18nKey="purchaseNoOnboarding.appStats.title"
          components={[<br />]}
        />
      </S.Title>
      <Carousel
        slidesPerView={1}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        spaceBetween={8}
      >
        <SwiperSlide>
          <S.Card>
            <S.Image>
              <SvgImage
                style={{ width: '16px', height: '16px' }}
                svg={starIcon}
              />
              <SvgImage
                style={{ width: '16px', height: '16px' }}
                svg={starIcon}
              />
              <SvgImage
                style={{ width: '16px', height: '16px' }}
                svg={starIcon}
              />
              <SvgImage
                style={{ width: '16px', height: '16px' }}
                svg={starIcon}
              />
              <SvgImage
                style={{ width: '18px', height: '18px' }}
                svg={halfStarIcon}
              />
            </S.Image>
            <S.CardTitle>
              {t('purchaseNoOnboarding.appStats.rating.title')}
            </S.CardTitle>
            <S.CardFootnote>
              {t('purchaseNoOnboarding.appStats.rating.details')}
            </S.CardFootnote>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card>
            <S.Image>
              <SvgImage
                style={{ width: '16px', height: '27px' }}
                svg={rewardsIcon}
              />
            </S.Image>
            <S.CardTitle>
              {t('purchaseNoOnboarding.appStats.rewards.title')}
            </S.CardTitle>
            <S.CardText>
              {t('purchaseNoOnboarding.appStats.rewards.details')}
            </S.CardText>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card>
            <S.Image>
              <SvgImage
                style={{ width: '40px', height: '19px' }}
                svg={peopleIcon}
              />
            </S.Image>
            <S.CardTitle>
              {t('purchaseNoOnboarding.appStats.downloads.title')}
            </S.CardTitle>
            <S.CardText>
              {t('purchaseNoOnboarding.appStats.downloads.details')}
            </S.CardText>
          </S.Card>
        </SwiperSlide>
      </Carousel>
    </S.Root>
  )
}
