import styled from 'styled-components'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
}

export const StyledTimer = {
  TimerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Timer: styled.div<TTimerContainerProps>`
    display: flex;
    font-size: ${({ $isButtonVisible }) =>
      $isButtonVisible ? '18px' : '20px'};
  `,
  TimerSign: styled.div<TTimerContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      display: ${({ $isButtonVisible }) =>
        $isButtonVisible ? 'none' : 'flex'};
      font-size: 8px;
      font-weight: 400;
    }
  `,
}
