import React, { FC } from 'react'

import { StylesProgressDate as S } from './ProgressDate.styles'

export const ProgressDate: FC<{ dates: string[] }> = ({ dates }) => (
  <S.Wrapper>
    {dates.map((date, index) => {
      const isLastItem = index === dates.length - 1
      return (
        <S.Date
          key={date}
          style={{ animationDelay: `${index * 0.5}s` }}
          $isLastItem={isLastItem}
        >
          {date}
        </S.Date>
      )
    })}
  </S.Wrapper>
)
