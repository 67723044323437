import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pt'

import {
  selectAnswers,
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectCurrentUserMeasurementSystem,
  selectLanguage,
} from 'root-redux/selects/common'

import {
  DAY_MONTH_FORMAT,
  DEFAULT_DATE_FORMAT,
  FULL_MONTH_FORMAT,
  LOCALIZED_DATE_FORMAT,
  addDaysToCurrentDate,
  getFormattedTodaysDate,
} from 'helpers/datesForGraph'

import { LOST_WEIGHT_PER_WEEK } from 'root-constants'

const SEVEN_DAYS = 7

export const useEventWeightInfo = () => {
  const answers = useSelector(selectAnswers)
  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const language = useSelector(selectLanguage)

  const getDaysToGoal = useCallback(() => {
    const weightDifference = parseFloat(currentWeight) - parseFloat(goalWeight)
    const lostWeightPerWeek = LOST_WEIGHT_PER_WEEK[measurementSystem]

    const countOfWeeks = weightDifference / lostWeightPerWeek
    const countOfDays = countOfWeeks * SEVEN_DAYS

    const roundedCountOfDays = Number(countOfDays.toFixed(1))

    return {
      daysToGoal: roundedCountOfDays,
      goalDate: dayjs().add(roundedCountOfDays, 'day'),
    }
  }, [currentWeight, goalWeight, measurementSystem])

  const getWeightToEvent = useCallback(
    (eventDate: Dayjs) => {
      const currentDate = dayjs()

      const daysDifference = eventDate.diff(currentDate, 'day')

      const weeksNeeded = daysDifference / SEVEN_DAYS

      const weightLossGoal =
        weeksNeeded * LOST_WEIGHT_PER_WEEK[measurementSystem]

      return weightLossGoal.toFixed(1)
    },
    [measurementSystem],
  )

  return useMemo(() => {
    const eventDate = (answers?.eventDate as string) || ''

    const weightDifference = getWeightToEvent(dayjs(eventDate))

    const { daysToGoal, goalDate } = getDaysToGoal()

    const fullGoalDate = addDaysToCurrentDate(daysToGoal)
    const contributingEventDate = dayjs(eventDate, DEFAULT_DATE_FORMAT)

    const goalMonth = getFormattedTodaysDate(
      FULL_MONTH_FORMAT,
      dayjs(fullGoalDate),
      language,
    )

    const goalMonthAndDay = getFormattedTodaysDate(
      DAY_MONTH_FORMAT,
      dayjs(fullGoalDate),
      language,
    )
    const goalMonthAndYear = getFormattedTodaysDate(
      LOCALIZED_DATE_FORMAT,
      dayjs(fullGoalDate),
      language,
    )

    return {
      fullGoalDate,
      eventDate,
      currentWeight,
      goalWeight,
      weightDifference,
      contributingEventDate,
      goalMonth,
      goalMonthAndDay,
      daysToGoal,
      goalMonthAndYear,
      goalDate,
    }
  }, [
    answers?.eventDate,
    currentWeight,
    getDaysToGoal,
    getWeightToEvent,
    goalWeight,
    language,
  ])
}
