import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import textBgImage from 'assets/images/reach-target-bg.png'
import bgImage from 'assets/images/reach-target-main-bg.png'

import { MeasurementSystem } from 'root-constants'

import { StyledReachTarget as S } from './ReachTargetJa.styles'

export const ReachTargetJa: FC = () => {
  const { t } = useTranslation()

  const userAnswer = useSelector(selectAnswers)
  const isMetricSystemSelected = useMemo(
    () => userAnswer?.measurementSystem === MeasurementSystem.METRIC,
    [userAnswer],
  )

  return (
    <S.Wrapper>
      <S.Text>
        <Trans i18nKey="purchaseUpgrade.targetWeight" />
      </S.Text>
      <S.TextImage src={textBgImage} alt="reach target goal" />
      <S.WeightBox>
        <S.Weight>
          {userAnswer?.goalWeight || 77}{' '}
          {isMetricSystemSelected
            ? t('commonComponents.metric')
            : t('commonComponents.imperial')}
        </S.Weight>
        {t('purchaseFreeOffer.subtitle')}
      </S.WeightBox>

      <S.BgImage src={bgImage} />
    </S.Wrapper>
  )
}
