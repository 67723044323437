import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import imageBg from 'assets/images/flow-belly/weight-fluctuations-bg.png'
import imageBgLegs from 'assets/images/flow-belly/weight-fluctuations-legs-bg.png'
import imageBgJa from 'assets/images/weight-fluctuations-ja.png'

import { GROWTHBOOK_EXPERIMENT, Gender } from 'root-constants'

import { WeightFluctuationsStyles as S } from './WeightFluctuations.styles'
import { OPTION_VALUES, OPTION_VALUES_CLEAR, QUESTION } from './constants'

export const WeightFluctuations: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()
  const { isTTClearFlow, isLegsFlow, isNewAdultFlow } = useCohortInfo()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const bgImage = useMemo(() => {
    if (imageUrl) return imageUrl

    if (isLegsFlow) return imageBgLegs

    if (isJapaneseFlow) return imageBgJa

    return imageBg
  }, [imageUrl, isJapaneseFlow, isLegsFlow])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container
      isLarge
      $bgImageUrl={bgImage}
      $isJapanese={isJapaneseFlow}
      $isAdult={isNewAdultFlow}
    >
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.weightFluctuations.title" />
      </PageTitle>

      {(isTTClearFlow ? OPTION_VALUES_CLEAR : OPTION_VALUES).map(
        ({ value, title }) => (
          <Answer
            {...optionProps}
            theme={theme}
            maxWidth="180px"
            margin="0 0 16px 0"
            disabled={isAnswersDisabled}
            value={value}
            key={value}
          >
            {t(title)}
          </Answer>
        ),
      )}
    </S.Container>
  )
}
