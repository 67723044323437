import React from 'react'
import { useSelector } from 'react-redux'

import { DEFAULT_DISCOUNT_NAME } from 'components/Ticker/constants'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledSale as S } from './Sale.styles'

export const Sale: React.FC = () => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return (
    <S.Root $theme={dynamicDiscount?.theme}>
      <S.Text>{dynamicDiscount?.name || DEFAULT_DISCOUNT_NAME}</S.Text>
    </S.Root>
  )
}
