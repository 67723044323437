import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useViewportValue } from 'hooks/useViewportValue'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { PersonalizedPlan } from '../../components/PersonalizedPlan'
import { StyledPurchaseVariantEmail as S } from './PurchaseVariantEmail.styles'

export const PurchaseVariantEmail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const {
    title,
    subtitle,
    buttonText,
    customerReviewsTitle,
    subscriptionBlockTitle,
    benefits,
    benefitsBlockTitle,
  } = useDynamicPaywallConfig()

  const {
    currency,
    fetchingActionsList,
    oldPriceBefore50PercentDiscount,
    periodQuantity,
    fullPrice,
    subscriptions,
    selectedSubscriptionId,
  } = usePurchaseStore()

  usePurchaseAnalytics({
    screenName: ScreenName.EMAIL_OFFER,
    screenId: ScreenId.EMAIL,
  })

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.EMAIL_OFFER, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const currentPriceWithFractionalPart = useMemo(() => fullPrice.toFixed(2), [
    fullPrice,
  ])

  const oldPriceWithFractionalPart = useMemo(
    () => oldPriceBefore50PercentDiscount?.fullPrice.toFixed(2),
    [oldPriceBefore50PercentDiscount],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.EMAIL_OFFER))
    dispatch(setScreenIdAction(ScreenId.EMAIL))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTags.NO_TAX,
      ),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch])

  useEffect(() => {
    const defaultSubscription =
      subscriptions.find(({ isDefault }) => isDefault) || subscriptions[0]
    dispatch(
      setSelectedSubscriptionAction(defaultSubscription as ISubscription),
    )
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.EMAIL_OFFER,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <S.Wrapper>
            <S.MainTitle>{title || t`purchaseVariantEmail.title`}</S.MainTitle>
            <S.MainSubtitle>
              {subtitle || (
                <Trans
                  i18nKey="purchaseVariantEmail.subtitle"
                  components={[<br />]}
                  values={{
                    context: isStayFit
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              )}
            </S.MainSubtitle>
            <PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle ref={elemForComparisonRef}>
                {benefitsBlockTitle || t`purchaseVariantEmail.programPlanTitle`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ id, text }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchase1.personalPlan"
                        components={[<strong />]}
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchase1.effectiveWorkouts"
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>{t`purchase1.specialPrograms`}</S.ListItem>
                    <S.ListItem>{t`purchase1.practiceAnywhere`}</S.ListItem>
                    <S.ListItem>{t`purchase1.popularDanceStyles`}</S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
            <S.Prices>
              <S.PricesTitle>
                {subscriptionBlockTitle || t`purchaseVariantEmail.priceTitle`}
              </S.PricesTitle>
              <S.PricesTable>
                <S.PricesRow>
                  <span>
                    {t('purchaseIntroOffer.monthlyPeriod', {
                      count: periodQuantity,
                    })}
                  </span>
                  <S.PriceTotal>
                    <Trans
                      i18nKey="purchase1.checkout.price"
                      values={{
                        price: currentPriceWithFractionalPart,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.PriceTotal>
                </S.PricesRow>
                <S.PricesRow>
                  <S.Discount>{t`purchaseVariantEmail.discount`}</S.Discount>
                  <S.OldPrice>
                    <Trans
                      i18nKey="purchase1.checkout.price"
                      values={{
                        price: oldPriceWithFractionalPart,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.OldPrice>
                </S.PricesRow>
              </S.PricesTable>
            </S.Prices>
            <S.CustomerReviewsTitle>
              {customerReviewsTitle ||
                t`purchaseVariantEmail.customerReviewsTitle`}
            </S.CustomerReviewsTitle>
            <CustomerReviews isHeaderVisible={false} />
            <S.ButtonContainer>
              <Button
                type="button"
                data-order="1"
                data-text="continue"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.continue`}
              </Button>
            </S.ButtonContainer>
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
