import { DynamicTitles } from 'hooks/useTitleFromUrl'

interface IGetPathParams {
  pageId: string
  cohort: string | null
  uuid: string
  language: string
  currentSearch: string
}

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
}: IGetPathParams): string => {
  const searchParams = new URLSearchParams(currentSearch)
  const lang = searchParams.get('lang')

  if (!lang) {
    searchParams.delete(DynamicTitles.HEADLINE)
    searchParams.delete(DynamicTitles.SUBTEXT)
  }

  searchParams.delete('cohort')
  searchParams.delete('uuid')
  searchParams.delete('lang')

  return `${pageId}?uuid=${uuid}&cohort=${cohort}&lang=${language}&${searchParams.toString()}`
}
