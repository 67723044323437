import styled from 'styled-components'

import { BackButton } from 'components/BackButton'
import { SvgImage } from 'components/SvgImage'
import {
  TMenuProps,
  backdropStyles,
  menuCloseButtonStyles,
  sidebarStyles,
} from 'components/styles'

import btnIcon from 'assets/images/flow-belly/icons/close-btn.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  $hasWidget: boolean
}

export const StyledHeader = {
  Wrapper: styled.header<TWrapperProps>`
    position: relative;
    z-index: 1;
    padding: ${({ $hasWidget }) =>
      $hasWidget ? '8px 10px 8px 20px' : '8px 0'};
    width: 100%;
    height: 52px;
    margin: 0 auto;
    display: flex;
    justify-content: ${({ $hasWidget }) =>
      $hasWidget ? 'space-between' : 'center'};
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: ${Color.WHITE};
  `,
  Logo: styled.div`
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    color: ${Color.BLACK};
  `,
  HeaderBackButton: styled(BackButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  `,
  SvgLogo: styled(SvgImage)`
    height: 36px;
    width: 36px;
    margin-right: 12px;
    border-radius: 11px;
    box-shadow: 0 2px 10px 0 rgba(167, 167, 167, 0.3);
  `,
  ButtonContainer: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    display: flex;
    align-items: center;
  `,
  SupportAction: styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    color: #2d3240;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
  `,
  Burger: styled.button`
    height: 24px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: ${Color.WHITE};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Backdrop: styled.div<TMenuProps>`
    ${backdropStyles};
  `,
  Menu: styled.nav<TMenuProps>`
    ${sidebarStyles};
    width: 295px;
    padding: 100px 25px;
  `,
  MenuCloseButton: styled.button`
    ${menuCloseButtonStyles};
    width: 40px;
    height: 40px;
    background-color: ${Color.GRAY_10};
    background-image: url(${btnIcon});
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 32px;

    &::after,
    &::before {
      display: none;
    }
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: #2d3240;
    margin-bottom: 24px;
    font-weight: 600;

    a {
      color: inherit;
    }
  `,
}
