import React, { ButtonHTMLAttributes } from 'react'

import { Color } from 'root-constants'

import { StyledNavigationButton as S } from './NavigationButtonBelly.styles'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  onClick?: () => void
  className?: string
  additionalContent?: React.ReactNode
}

export const NavigationButtonBelly: React.FC<IButtonProps> = ({
  disabled,
  onClick,
  children,
  additionalContent,
  className,
  ...props
}) => (
  <S.ButtonContainer className={className}>
    <S.Button
      disabled={disabled}
      onClick={onClick}
      disabledBackgroundColor={Color.GRAY_40}
      {...props}
    >
      {children}
    </S.Button>
    {additionalContent}
  </S.ButtonContainer>
)
