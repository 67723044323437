import styled from 'styled-components'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledPlanHighlights = {
  Wrapper: styled.div`
    padding: 0 10px 32px;
  `,
  Title: styled.h2`
    padding: 32px 0 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 16px;
    padding-left: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center no-repeat;
      background-size: contain;
    }
  `,
}
