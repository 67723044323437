export const QUESTION = 'How often do you feel exhausted?'

export const OPTION_VALUES_SOMATIC = [
  {
    value: 'almostAlways',
    title: 'onboarding.exhausted.almostAlways',
  },
  {
    value: 'quiteOften',
    title: 'onboarding.stress.quiteOften',
  },
  {
    value: 'rarely',
    title: 'onboarding.stress.rarely',
  },
  {
    value: 'almostNever',
    title: 'onboarding.stress.almostNever',
  },
]
