import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import colonIcon from 'assets/images/sprite/colon-icon.svg'

import { StyledHeaderWithTimer as S } from './HeaderWithTimerSomatic.styles'

const ButtonStyles = {
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

type TProps = {
  onButtonClick: (event) => void
  className?: string
}

export const HeaderWithTimerSomatic: FC<TProps> = ({
  onButtonClick,
  className,
}) => {
  const { t } = useTranslation()

  const timerElementHourRef = useRef<HTMLSpanElement>(null)
  const timerElementMinRef = useRef<HTMLSpanElement>(null)
  const timerElementSecRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
    }
  }, [])

  return (
    <S.TimerContainer className={className}>
      <S.Wrapper>
        <S.TextContainer>
          <S.Timer>
            <S.TimerSign>
              <strong ref={timerElementMinRef} />
              <span>{t('commonComponents.minute')}</span>
            </S.TimerSign>
            <SvgImage svg={colonIcon} width={4} />
            <S.TimerSign>
              <strong ref={timerElementSecRef} />
              <span>{t('commonComponents.second')}</span>
            </S.TimerSign>
          </S.Timer>
        </S.TextContainer>

        <S.ButtonContainer>
          <S.Button
            type="button"
            data-order="1"
            data-text="get_my_plan"
            style={ButtonStyles}
            onClick={onButtonClick}
          >
            {t('actions.getMyPlan')}
          </S.Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </S.TimerContainer>
  )
}
