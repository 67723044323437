import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseCancelOfferMealPlan = {
  Hero: styled.div`
    position: relative;
    max-width: ${MediaBreakpoint.TABLET}px;
    margin: 0 auto;
    aspect-ratio: 375/341;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 37px;
      background: ${Color.WHITE};
      border-radius: 24px 24px 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `,
  HeroImage: styled.img`
    vertical-align: top;
  `,
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px 1px 16px;
  `,
  Products: styled.div`
    margin-bottom: 24px;
  `,
  ProductsTitle: styled.h3`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  Product: styled.li`
    display: flex;
    align-items: center;
    border-radius: 24px;
    padding: 8px;
    background-color: ${Color.LIGHT};
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  ProductImage: styled.img`
    margin-right: 12px;
    border-radius: 14px;
    max-width: 112px;
  `,
  ProductTitle: styled.h4`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  ProductText: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #131d3080;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
    margin-bottom: 32px;
  `,
  MoneyBackContainer: styled.div`
    padding-bottom: 8px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
