import gladIcon from 'assets/images/glad-icon.svg'
import happyIcon from 'assets/images/happy-icon.svg'
import normalIcon from 'assets/images/normal-icon.svg'
import sadIcon from 'assets/images/sad-icon.svg'

export const QUESTION = 'When was the last time felt relaxed and peaceful?'

export const OPTION_VALUES = [
  {
    value: 'recently',
    text: 'onboarding.relaxed.recently',
    iconSrc: happyIcon,
  },
  {
    value: 'lastMonth',
    text: 'onboarding.relaxed.lastMonth',
    iconSrc: gladIcon,
  },
  {
    value: 'whileAgo',
    text: 'onboarding.relaxed.whileAgo',
    iconSrc: normalIcon,
  },
  {
    value: 'dontRemember',
    text: 'onboarding.relaxed.dontRemember',
    iconSrc: sadIcon,
  },
]
