import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { CurrentWeightWithRuler } from 'pages/questions/CurrentWeightWithRuller'
import { DefaultWeight } from 'pages/questions/CurrentWeightWithRuller/constants'

import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { Toggle } from 'components/Toggle'

import { setWeightAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { TAnswer, TPageProps } from 'models/common.model'

import { goTo } from 'browser-history'
import {
  ADULT_COHORT,
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  Cohort,
  FLOAT_NUMBER_REGEX,
  GROWTHBOOK_EXPERIMENT,
  MeasurementSystem,
} from 'root-constants'

import { StyledCurrentWeight as S } from './CurrentWeight.styles'
import { MinMaxWeight, QUESTION } from './constants'

export const CurrentWeight: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const hasRulerTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_455)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const [weight, setWeight] = useState<{
    value: string
    unit: TAnswer
    isValid: boolean
  }>({
    value: '',
    unit: currentMeasurementSystem,
    isValid: false,
  })

  const isMetricSystemSelected = useMemo(
    () => weight.unit === MeasurementSystem.METRIC,
    [weight.unit],
  )

  const isCountryWithImperialSystem = useMemo(
    () => COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(userCountryCode),
    [userCountryCode],
  )

  const handleMeasurementChange = useCallback(
    (isChecked) =>
      setWeight({
        value: '',
        isValid: false,
        unit: isChecked ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
      }),
    [],
  )
  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(
        setWeightAnswerAction({
          question: QUESTION,
          answers: {
            [pageId]: weight.value,
            measurementSystem: weight.unit,
          },
        }),
      )
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [weight, pageId, dispatch, nextPagePath],
  )

  useEffect(() => {
    const initialMeasurementSystem = isCountryWithImperialSystem
      ? MeasurementSystem.IMPERIAL
      : MeasurementSystem.METRIC

    const initialWeight =
      currentWeightValue ||
      (isMetricSystemSelected ? DefaultWeight.KG : DefaultWeight.LB)

    setWeight((prevState) => ({
      ...prevState,
      value: hasRulerTest ? initialWeight : '',
      unit: currentMeasurementSystem || initialMeasurementSystem,
    }))
  }, [
    currentMeasurementSystem,
    currentWeightValue,
    hasRulerTest,
    isCountryWithImperialSystem,
    isMetricSystemSelected,
  ])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FLOAT_NUMBER_REGEX.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  return (
    <form onSubmit={handleClick}>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle
          isAgeFlow={isAgeFlow}
          marginBottom={10}
        >{t`onboarding.currentWeight.question`}</PageTitle>
        <PageTitleDescription marginBottom={30}>
          <Trans
            i18nKey={
              isAgeFlow
                ? 'onboarding.currentWeight.subtitleAgeFlow'
                : 'onboarding.currentWeight.subtitle'
            }
            components={[<br />]}
          />
        </PageTitleDescription>
        <S.Actions>
          {currentMeasurementSystem ? (
            <S.WeightLabel>
              {t(`commonComponents.${currentMeasurementSystem}`)}
            </S.WeightLabel>
          ) : (
            <Toggle
              data-testid="weight-toggle"
              labels={[
                t('commonComponents.metric'),
                t('commonComponents.imperial'),
              ]}
              checked={!isMetricSystemSelected}
              onChange={handleMeasurementChange}
            />
          )}
          {hasRulerTest ? (
            <CurrentWeightWithRuler
              value={weight.value}
              onChange={handleChange}
              isMetricSystemSelected={isMetricSystemSelected}
            />
          ) : (
            <Input
              data-testid="weight-input"
              value={weight.value}
              isAgeFlow={isAgeFlow}
              type="number"
              min={
                isMetricSystemSelected
                  ? MinMaxWeight.MIN_WEIGHT_KG
                  : MinMaxWeight.MIN_WEIGHT_LB
              }
              max={
                isMetricSystemSelected
                  ? MinMaxWeight.MAX_WEIGHT_KG
                  : MinMaxWeight.MAX_WEIGHT_LB
              }
              lang="en"
              step={isMetricSystemSelected ? '0.1' : '1'}
              onChange={handleChange}
              allowFloatNumbers={isMetricSystemSelected}
              style={{ textAlign: 'center' }}
            />
          )}
        </S.Actions>
      </S.Container>

      <NavigationButton
        type="submit"
        isAgeFlow={isAgeFlow}
        disabled={!weight.isValid}
      >
        {t`actions.continue`}
      </NavigationButton>
    </form>
  )
}
