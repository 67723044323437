import styled from 'styled-components'

import { BodyAgeV2 } from 'modules/purchase/components/BodyAgeV2'
import { PhotoResultV2 } from 'modules/purchase/components/PhotoResultV2'

import { Color } from 'root-constants'

export const StyledPurchaseUpgradeMealplan = {
  PhotoResult: styled(PhotoResultV2)`
    margin-bottom: 0;
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Subtitle: styled.p`
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  `,
  UserWithMealPlan: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 32px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 72px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  BenefitsWrapper: styled.div``,
  FeaturedBlockContainer: styled.div`
    padding-bottom: 40px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 32px;
  `,
  BodyAge: styled(BodyAgeV2)`
    & > ul > li {
      margin-bottom: 32px;
    }
  `,
}
