import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  selectAnswers,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { BeforeAfterIntro } from 'modules/purchase/components/BeforeAfterIntro'
import { ConfidenceBlock } from 'modules/purchase/components/ConfidenceBlock'
import { HeaderWithTimer } from 'modules/purchase/components/HeaderWithTimer'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import { PurchasePhoneSlider } from 'modules/purchase/components/PurchasePhoneSlider'
import { ReviewV2 } from 'modules/purchase/components/ReviewV2/ReviewV2'
import { SubscriptionsBlockV2 } from 'modules/purchase/components/SubscriptionsBlockV2'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import { CheckoutWithoutIntroOffer } from 'modules/purchase/pages/CheckoutWithoutIntroOffer'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'
import goalIcon from 'assets/images/goal.png'
import weigherIcon from 'assets/images/scale.png'

import {
  Cohort,
  Locale,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseVariant2 as S } from './PurchaseVariant2.styles'
import { SLIDES_FEMALE, SLIDES_MALE } from './constants'

export const PurchaseVariant2: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { buttonText } = useDynamicPaywallConfig()

  const userAnswers = useSelector(selectAnswers)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  const firstElementRef = useRef<HTMLDivElement>(null)
  const secondElementRef = useRef<HTMLButtonElement>(null)
  const thirdElementRef = useRef<HTMLDivElement>(null)

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const hasIncludedVat = useVatInfo()
  const {
    subscriptions,
    fetchingActionsList,
    language,
    selectedSubscriptionId,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const goalWeight = userAnswers?.goalWeight || '50'

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
    screenId: ScreenId.BETTERME,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const hasNewCheckout = useMemo(
    () => cohortToUse === Cohort.DB_ADULT && language === Locale.ENGLISH,
    [cohortToUse, language],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.BETTERME))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          <HeaderWithTimer
            onButtonClick={handleShowPayment}
            firstElementRef={firstElementRef}
            secondElementRef={secondElementRef}
            thirdElementRef={thirdElementRef}
          />

          <BeforeAfterIntro />

          <S.Container>
            <ConfidenceBlock />

            <S.Title>
              <Trans
                i18nKey="purchaseV2.title"
                values={{
                  context: isStayFitFlow
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
                }}
              />
            </S.Title>

            <S.GoalBlock>
              {!isStayFitFlow && (
                <S.GoalCard $isStayfit={isStayFitFlow}>
                  <S.GoalImg>
                    <img src={weigherIcon} alt="scale" width={24} />
                  </S.GoalImg>

                  <S.GoalText>
                    <span>{t('purchaseV2.targetWeight')}</span>
                    <p>
                      {goalWeight}{' '}
                      {t(`commonComponents.${currentMeasurementSystem}`)}
                    </p>
                  </S.GoalText>
                </S.GoalCard>
              )}

              <S.GoalCard $isStayfit={isStayFitFlow}>
                <S.GoalImg>
                  <img src={goalIcon} alt="goal" width={24} />
                </S.GoalImg>

                <S.GoalText>
                  <span>
                    <Trans i18nKey="purchaseV2.goal" />
                  </span>

                  <p>
                    <Trans
                      i18nKey={
                        isStayFitGoal
                          ? 'onboarding.mainGoal.stayFit'
                          : 'onboarding.mainGoal.loseWeight'
                      }
                    />
                  </p>
                </S.GoalText>
              </S.GoalCard>
            </S.GoalBlock>

            <SubscriptionsBlockV2 elemForComparisonRef={firstElementRef}>
              <Button
                type="button"
                data-order="2"
                data-text="get_my_plan"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </SubscriptionsBlockV2>

            <PurchasePhoneSlider />

            <S.Wreath>
              <S.NumberOne>
                #<strong>1</strong>
              </S.NumberOne>
              <Trans i18nKey="purchaseV2.numberOne" />
            </S.Wreath>

            <Button
              type="button"
              data-order="3"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {t('actions.getMyPlan')}
            </Button>

            <S.FeaturedBlockContainer>
              <S.FeaturedBlockTitle>
                {t('purchase1.featuredBlockTitle')}
              </S.FeaturedBlockTitle>
              <picture>
                <source srcSet={featuredImageWebp} type="image/webp" />
                <img src={featuredImagePng} alt="body" />
              </picture>
            </S.FeaturedBlockContainer>

            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                <Trans
                  i18nKey="purchase1.programDescriptionTitle"
                  values={{
                    context: isStayFitFlow
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              </S.ProgramPlanTitle>
              <ul>
                <S.ListItem>
                  <Trans
                    i18nKey="purchaseV2.plan.personalWeightLoss"
                    values={{
                      context: isStayFitFlow
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                </S.ListItem>
                <S.ListItem>
                  <Trans
                    i18nKey="purchaseV2.plan.shortEffective"
                    values={{
                      context: isStayFitFlow
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                </S.ListItem>
                <S.ListItem>
                  <Trans i18nKey="purchaseV2.plan.danceWorkouts" />
                </S.ListItem>
                <S.ListItem>
                  <Trans i18nKey="purchaseV2.plan.possabilityPractice" />
                </S.ListItem>
                <S.ListItem>
                  <Trans i18nKey="purchaseV2.plan.mostPopular" />
                </S.ListItem>
                <S.ListItem>
                  <Trans i18nKey="purchaseV2.plan.simpleFun" />
                </S.ListItem>
              </ul>

              <Button
                type="button"
                data-order="4"
                data-text="get_my_plan"
                onClick={handleShowPayment}
                buttonRef={secondElementRef}
              >
                <Trans i18nKey="actions.getMyPlan" />
              </Button>
            </S.ProgramPlanContainer>

            <S.SuccessTitle>
              <Trans i18nKey="purchaseV2.resultTitle" />
            </S.SuccessTitle>
            <S.Subtitle>
              <Trans i18nKey="purchaseV2.resultSubtitle" />
            </S.Subtitle>

            <PhotoResultV3
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
              hasTitle={false}
              hasBackground={false}
            />

            <S.FAQList hasNewQuestionIcon />
            <ReviewV2 />

            <S.Title> {t('purchaseV2.getResultTitle')}</S.Title>

            <SubscriptionsBlockV2 elemForComparisonRef={thirdElementRef}>
              <Button
                type="button"
                data-order="5"
                data-text="get_my_plan"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </SubscriptionsBlockV2>

            <S.MoneyBackGuarantee />

            <S.SecurityInfo />
          </S.Container>
        </div>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <>
          {hasNewCheckout && (
            <CheckoutSeparateMethods
              key={selectedSubscriptionId}
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
            />
          )}
          {!hasNewCheckout && (
            <CheckoutWithoutIntroOffer
              key={selectedSubscriptionId}
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
            />
          )}
        </>
      </S.CheckoutContainer>
    </>
  )
}
