import styled from 'styled-components'

import bgImage from 'assets/images/reach-target-clear.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReachTarget = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    background: url(${bgImage}) no-repeat center;
    background-size: cover;
    margin: 0 auto 8px;
    min-height: 222px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Text: styled.p`
    color: ${Color.WHITE};
    text-shadow: 0 3px 9px rgba(22, 49, 101, 0.3);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    max-width: 200px;
    margin: 54px 0 0 20px;
  `,
}
