import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import { StyledTimerWithDiscount as S } from './TimerWithDiscount.styles.'

type TProps = {
  className?: string
}

export const TimerWithDiscount: FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const timerElementHourRef = useRef<HTMLSpanElement>(null)
  const timerElementMinRef = useRef<HTMLSpanElement>(null)
  const timerElementSecRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
    }
  }, [])

  return (
    <S.TimerContainer className={className}>
      <S.DiscountColumn>
        <S.TimerText>{t`purchaseWithoutIntro.discountApplied`}</S.TimerText>
        <S.DiscountBlock>
          <S.DiscountAmount>
            {t('purchaseVariantEmail.discount')}
          </S.DiscountAmount>
          {t('purchaseWithoutIntro.sarah')}
        </S.DiscountBlock>
      </S.DiscountColumn>
      <S.DiscountColumn>
        <S.TimerText>{t`purchaseWithoutIntro.applicableFor`}</S.TimerText>
        <S.Timer>
          <S.TimerSign>
            <strong ref={timerElementMinRef} />
            <span>{t('commonComponents.minute')}</span>
          </S.TimerSign>
          <S.TimerSign>
            <strong ref={timerElementSecRef} />
            <span>{t('commonComponents.second')}</span>
          </S.TimerSign>
        </S.Timer>
      </S.DiscountColumn>
    </S.TimerContainer>
  )
}
