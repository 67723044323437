import styled from 'styled-components'

import blueStar from 'assets/images/blue-star.svg'

export const StyledHormonalChanges = {
  List: styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,
  ListItem: styled.div`
    position: relative;
    padding-left: 24px;
    font-size: 14px;
    line-height: 18px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background-image: url(${blueStar});
      background-repeat: no-repeat;
    }
  `,
}
