import styled from 'styled-components'

export const StyledOurPrograms = {
  Wrapper: styled.div``,
  ShieldIcon: styled.div`
    max-width: 58px;
    max-height: 72px;
    margin: 0 auto;
  `,
  Title: styled.h3`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: 16px 0;
  `,
  Card: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 24px;
    }
  `,
  CardImg: styled.img`
    width: 112px;
    height: 138px;
    margin-right: 16px;
  `,
  CardText: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  CardTitle: styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
  CardDescription: styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}
