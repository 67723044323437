import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledUpgradeLife as S } from './UpgradeLife.styles'
import { QUESTION } from './constants'

export const UpgradeLife: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const animationRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}/animations/socialProofAnimation.json`,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <Container>
      <PageTitle marginBottom={10}>
        <Trans i18nKey="onboarding.upgradeLife.title" />
      </PageTitle>
      <S.Subtitle>{t('onboarding.upgradeLife.subtitle')}</S.Subtitle>
      <S.AnimationContainer ref={animationRef} />

      <NavigationButton
        type="button"
        onClick={handleClick}
      >{t`actions.haveFun`}</NavigationButton>
    </Container>
  )
}
