import styled from 'styled-components'

import { Option } from 'components/Option'

import introDesktopBg from 'assets/images/intro-desktop.jpg'
import introBg from 'assets/images/intro-mobile.jpg'
import stickerImg from 'assets/images/sale-sticker-2.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledMainGoal = {
  Root: styled.div<{
    $uploadedBackgroundImage: string
    $uploadedBackgroundImageDesktop: string
  }>`
    position: relative;
    background-image: ${({ $uploadedBackgroundImage }) =>
      $uploadedBackgroundImage
        ? `url(${$uploadedBackgroundImage})`
        : `url(${introBg})`};
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: ${({ $uploadedBackgroundImageDesktop }) =>
        $uploadedBackgroundImageDesktop
          ? `url(${$uploadedBackgroundImageDesktop})`
          : `url(${introDesktopBg})`};
    }
  `,
  Filter: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--full-height) - 56px);
    height: 100%;
    padding: 0 14px;
    position: relative;
    z-index: 2;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      justify-content: center;
    }
  `,
  Subtitle: styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 16px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 320px;
      margin: 0 auto 16px auto;
    }
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${Color.WHITE};
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    margin: 0 auto 56px auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px auto;
    }

    strong {
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(91deg, #6beafc 65%, #00affe 90.72%);
    }
  `,
  RefTitleContainer: styled.div`
    max-width: 240px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 100%;
    }
  `,
  ActionCall: styled.h3`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${Color.WHITE};
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 618px;
      margin: 0 auto 16px auto;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex: 1;
    border-radius: 10px;
    border: 2px solid ${Color.PRIMARY};
    background-color: ${({ $isChecked }) =>
      $isChecked ? Color.WHITE : Color.PRIMARY};
    color: ${({ $isChecked }) => ($isChecked ? Color.PRIMARY : Color.WHITE)};

    &:first-child {
      margin-right: 12px;
    }
  `,
  Action: styled.div`
    width: 100%;
    min-width: 156px;
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
  `,
  Terms: styled.div`
    z-index: 2;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #e7eaf0;
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: #e7eaf0;
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: #e7eaf0;
    margin: 0 5px;
    vertical-align: middle;
  `,
  Sticker: styled.div<{ $uploadedSticker: string }>`
    position: absolute;
    top: calc(50% - 230px);
    left: calc(50% - 170px);
    width: 96px;
    height: 112px;
    background: ${({ $uploadedSticker }) =>
      $uploadedSticker ? `url(${$uploadedSticker})` : `url(${stickerImg})`};
    z-index: 2;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      color: ${Color.WHITE};
      text-align: center;
      font-size: 18px;
      font-style: italic;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      transform: rotate(-20deg);
      max-width: 72px;
      padding-bottom: 24px;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      top: calc(50% - 260px);
      left: calc(50% - 150px);
    }
  `,
}
