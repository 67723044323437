import bellyPlanImg from 'assets/images/belly-fat-burner.png'
import confidenceCardioImg from 'assets/images/confidence-cardio.png'
import extraChallengesImg from 'assets/images/extra-challenge.png'
import bodyProgramImg from 'assets/images/full-body-program.png'
import liveEventsImg from 'assets/images/live-events.png'
import unleashFemininityImg from 'assets/images/unleash-femininity.png'

import { MAIN_GOALS } from 'root-constants'

export const UPSELL_CANCEL_OFFER_REVIEWS = [
  {
    author: 'upsellCancelOffer.customerReviews.firstAuthor',
    review: 'upsellCancelOffer.customerReviews.firstReview',
  },
  {
    author: 'upsellCancelOffer.customerReviews.secondAuthor',
    review: 'upsellCancelOffer.customerReviews.secondReview',
  },
  {
    author: 'upsellCancelOffer.customerReviews.thirdAuthor',
    review: 'upsellCancelOffer.customerReviews.thirdReview',
  },
]

export const PLAN_BENEFITS = {
  [MAIN_GOALS.STAY_FIT]: [
    {
      id: '1',
      img: unleashFemininityImg,
      title: 'upsellCancelOffer.unleashFemininity',
      description: 'upsellCancelOffer.unleashFemininityDescription',
    },
    {
      id: '2',
      img: confidenceCardioImg,
      title: 'upsellCancelOffer.confidenceProgram',
      description: 'upsellCancelOffer.confidenceProgramDescription',
    },
    {
      id: '3',
      img: liveEventsImg,
      title: 'upsellCancelOffer.liveEvents',
      description: 'upsellCancelOffer.liveEventsDescription',
    },
    {
      id: '4',
      img: extraChallengesImg,
      title: 'upsellCancelOffer.extraChallengesV2',
      description: 'upsellCancelOffer.extraChallengesDescription',
    },
  ],
  [MAIN_GOALS.LOSE_WEIGHT]: [
    {
      id: '1',
      img: bellyPlanImg,
      title: 'upsellCancelOffer.bellyFat',
      description: 'upsellCancelOffer.bellyFatDescription',
    },
    {
      id: '2',
      img: bodyProgramImg,
      title: 'upsellCancelOffer.fullBodyProgram',
      description: 'upsellCancelOffer.fullBodyProgramDescription',
    },
    {
      id: '3',
      img: liveEventsImg,
      title: 'upsellCancelOffer.liveEvents',
      description: 'upsellCancelOffer.liveEventsDescription',
    },
    {
      id: '4',
      img: extraChallengesImg,
      title: 'upsellCancelOffer.extraChallengesV2',
      description: 'upsellCancelOffer.extraChallengesDescription',
    },
  ],
}
