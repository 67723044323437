import styled from 'styled-components'

import { IntroOfferDisclaimerV2 } from 'modules/purchase/components/IntroOfferDisclaimerV2'

import { Color } from 'root-constants'

export const StyledSubscriptionsBlockIntroOfferAdult = {
  WrapperContainer: styled.div`
    margin: 0 0 40px;
  `,
  Wrapper: styled.div`
    margin: 24px 0;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;

    button {
      max-width: 100%;
    }
  `,
  SubscriptionDisclaimer: styled(IntroOfferDisclaimerV2)`
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
    }
  `,
}
