import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { getAppNameFromInAppBrowser } from 'helpers/getAppNameFromInAppBrowser'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { AppWithInAppBrowser, PlatformOS } from 'root-constants'

import { StyledOuterLinkIframe } from './OuterLink.styles'

type TProps = {
  href: string
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}

export const OuterLink: React.FC<TProps> = ({
  className = '',
  href,
  onClick,
  children,
}) => {
  const [isIframeShown, setIsIframeShown] = useState(false)

  const isAndroidInstagramOrFb = useMemo(() => {
    const appName = getAppNameFromInAppBrowser()
    const isInstagramOrFb =
      appName === AppWithInAppBrowser.FACEBOOK ||
      appName === AppWithInAppBrowser.INSTAGRAM
    const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

    return isInstagramOrFb && isAndroid
  }, [])

  useEffect(() => {
    if (!isAndroidInstagramOrFb) return

    const backButtonEventCallback = () => setIsIframeShown(false)

    window.addEventListener('popstate', backButtonEventCallback)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('popstate', backButtonEventCallback)
    }
  }, [isAndroidInstagramOrFb])

  const handleClick = useCallback(
    (event) => {
      onClick && onClick()

      if (isAndroidInstagramOrFb) {
        event.preventDefault()
        setIsIframeShown(true)
      }
    },
    [isAndroidInstagramOrFb, onClick],
  )

  return (
    <>
      <a
        className={className}
        onClick={handleClick}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
      {isIframeShown &&
        isAndroidInstagramOrFb &&
        createPortal(
          <StyledOuterLinkIframe
            title="outer link"
            src={href}
            frameBorder="0"
          />,
          document.querySelector('body') as HTMLElement,
        )}
    </>
  )
}
