import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IMPROVE_AREAS_VALUES } from 'pages/questions/ImproveAreas/constants'

import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrow from 'assets/images/sprite/goal-arrow.svg'

import { PageId } from 'page-constants'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  DynamicDiscountType,
  Gender,
  PagesSource,
} from 'root-constants'

import { StyledGender as S } from './WeightLossPlan.styles'
import {
  OPTION_VALUES_IMPERIAL,
  OPTION_VALUES_METRIC,
  QUESTION,
} from './constants'

export const WeightLossPlan: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const userCountryCode = useSelector(selectUserCountryCode)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const { search } = useLocation()
  const { isSaleFlow } = useCohortInfo()
  const dispatch = useDispatch()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const hasImperialSystem = COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(
    userCountryCode,
  )

  const pagePath = getPathFromPageId({
    pageId: PageId.CHOOSE_AGE,
    cohort,
    uuid,
    language,
    currentSearch: search,
  })

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: pagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            {
              eventID: isPersonalDataAllowed ? uuid : '',
            },
          )
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        dispatch(
          setAnswersAction({
            answers: Gender.FEMALE,
            pageId: PageId.GENDER,
          }),
        )
        dispatch(
          setAnswersAction({
            answers: [IMPROVE_AREAS_VALUES.BELLY],
            pageId: PageId.IMPROVE_AREAS,
          }),
        )

        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      isPersonalDataAllowed,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <>
      {isSaleFlow && (
        <S.TickerContainer>
          {dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}
          {dynamicDiscount?.type !== DynamicDiscountType.STATIC && <Ticker />}
        </S.TickerContainer>
      )}
      <S.Container isLarge>
        <PageTitle
          marginBottom={8}
          marginTop={16}
        >{t`onboarding.welcomeV2.title`}</PageTitle>
        <S.Subtitle>{t`onboarding.welcomeV2.subtitle`}</S.Subtitle>

        {(hasImperialSystem
          ? OPTION_VALUES_IMPERIAL
          : OPTION_VALUES_METRIC
        ).map(({ value, text }) => (
          <Option
            key={value}
            {...optionProps}
            disabled={isAnswersDisabled}
            value={value}
          >
            <S.AnswerButton>
              <S.Text>{t(text, { weight: value })}</S.Text>
              <S.ArrowContainer>
                <SvgImage svg={arrow} height={12} />
              </S.ArrowContainer>
            </S.AnswerButton>
          </Option>
        ))}
      </S.Container>
      <S.Terms>
        <p>{t`footer.disclaimer`}</p>
        <TermsOfUseLink source={PagesSource.LANDING} />
        <S.Separator />
        <PrivacyPolicyLink source={PagesSource.LANDING} />
        <p>
          {t('footer.allRightsReserved', {
            year: new Date().getFullYear(),
          })}
        </p>
      </S.Terms>
    </>
  )
}
