import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionItemInfo = {
  PricesContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  PeriodContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
  `,
  NewPriceContainer: styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  `,
  Discount: styled.div`
    padding: 4px 8px;
    background: ${Color.GREEN};
    border-radius: 11px;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    color: ${Color.WHITE};
  `,
  AdditionalInfoBorder: styled.p`
    padding-bottom: 16px;
    border-top: 1px solid ${Color.PRIMARY};
  `,
  Period: styled.div`
    padding: 2px 12px;
    background: #eaf7ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    color: #03aaf5;
  `,
  Old: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
  `,
  NewPrice: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
  `,
  OldPrice: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #e74c3c;
    text-decoration: line-through;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 32px;
  `,
}
