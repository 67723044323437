import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { MAIN_GOALS } from 'root-constants'

import { StyledHowMotivated as S } from './HowMotivated.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const HowMotivated: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container>
      <PageTitle isAgeFlow marginBottom={32}>
        {t('onboarding.howMotivated.question', {
          context: isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT,
        })}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.READY}
      >
        <AnswerButton
          isAgeFlow
        >{t`onboarding.howMotivated.ready`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.HOPEFUL}
      >
        <AnswerButton
          isAgeFlow
        >{t`onboarding.howMotivated.hopeful`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.HESITANT}
      >
        <AnswerButton
          isAgeFlow
        >{t`onboarding.howMotivated.hesitant`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TAKING_EASY}
      >
        <AnswerButton
          isAgeFlow
        >{t`onboarding.howMotivated.takingEasy`}</AnswerButton>
      </Option>
    </S.Container>
  )
}
