import styled from 'styled-components'

import stars from 'assets/images/stars-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroMediaV2 = {
  Wrapper: styled.div`
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      padding: 0 16px;
    }
  `,
  TitleContainer: styled.div`
    background-color: ${Color.PRIMARY};
    padding-top: 19px;
    padding-bottom: 21px;
    border-radius: 0 0 12px 12px;
    background-image: url(${stars});
    background-repeat: round;
  `,
  Title: styled.h1`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  Arrow: styled.div`
    background-color: ${Color.PRIMARY};
    margin-bottom: 24px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 8px solid ${Color.PRIMARY};
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }
  `,
  Subtitle: styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-top: 4px;
    color: ${Color.WHITE};
  `,
}
