import styled from 'styled-components'

import { Container } from 'components/Container'

import introDesktopBg from 'assets/images/new-adult-desktop-intro.jpg'
import introBg from 'assets/images/new-adult-mobile-intro.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledWelcomeAdult = {
  Root: styled.div`
    position: relative;
    background-image: ${`url(${introBg})`};
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: ${`url(${introDesktopBg})`};
    }
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(var(--full-height) - 56px);
    height: 100%;
    padding: 80px 14px 0;
    position: relative;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      padding-top: 0;
      justify-content: space-around;
    }
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    text-transform: uppercase;

    strong {
      color: #ffe065;
    }
  `,
  Subtitle: styled.span`
    display: block;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 16px;
    }
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    gap: 11px;
    width: 100%;
    margin-bottom: 26px;
    margin-top: 12px;
  `,
  Action: styled.div`
    position: absolute;
    left: 4px;
    bottom: 8px;
    width: calc(100% - 8px);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    color: ${Color.WHITE};
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  `,
  Terms: styled.div`
    z-index: 2;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #e7eaf0;
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: #e7eaf0;
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: #e7eaf0;
    margin: 0 5px;
    vertical-align: middle;
  `,
}
