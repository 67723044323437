import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

export const DEFAULT_INTRO_OFFER_SUBSCRIPTION_ITEM = 'variant_3'
export const DEFAULT_SUBSCRIPTION_ITEM = 'variant_1'

export const useDynamicPaywallConfig = () => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()

  return useMemo(() => {
    const dynamicPaywallConfig = steps[currentPageIndex]?.paywallDynamicConfig
    return {
      title: dynamicPaywallConfig?.[language]?.title,
      subtitle: dynamicPaywallConfig?.[language]?.subtitle,
      buttonText: dynamicPaywallConfig?.[language]?.buttonText,
      subscriptionBlockTitle:
        dynamicPaywallConfig?.[language]?.subscriptionBlockTitle,
      subscriptionBlockType:
        dynamicPaywallConfig?.[language]?.subscriptionBlockType,
      benefitsBlockTitle: dynamicPaywallConfig?.[language]?.benefitsBlockTitle,
      benefits: dynamicPaywallConfig?.[language]?.benefits || [],
      customerReviewsTitle:
        dynamicPaywallConfig?.[language]?.customerReviewsTitle,
      customerReviews: dynamicPaywallConfig?.[language]?.customerReviews || [],
    }
  }, [currentPageIndex, language, steps])
}
