import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { Timer } from 'modules/purchase/components/Timer'
import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import boostIcon from 'assets/images/sprite/boost-icon.svg'

import { MAIN_GOALS } from 'root-constants'

import { StyledOfferCard as S } from './OfferCard.styles'

type TProps = {
  className?: string
}

const OFFER_PRICE_MX = 624.41
const OFFER_PRICE = 34

export const OfferCard: React.FC<TProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const isStayFitFlow = useSelector(selectIsStayFitFlow)
  const currency = useSelector(selectCurrency)

  return (
    <S.Wrapper className={className}>
      <S.CardContainer>
        <S.Plan>
          <SvgImage svg={boostIcon} width={24} height={24} />
          <S.OfferInfo>
            <S.Title>
              {t('purchaseWithoutIntro.freeOfferTitle', {
                context: isStayFitFlow
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              })}
            </S.Title>

            {t('purchaseWithoutIntro.freeOfferText', {
              context: isStayFitFlow
                ? MAIN_GOALS.STAY_FIT
                : MAIN_GOALS.LOSE_WEIGHT,
            })}
          </S.OfferInfo>
        </S.Plan>

        <S.Price $isMXN={currency === Currency.MXN}>
          <S.PriceTitle $isMXN={currency === Currency.MXN}>
            {t('purchase1.checkout.price', {
              price: 0,
              currency: CURRENCY_SYMBOLS[currency],
            })}
          </S.PriceTitle>
          <span>
            {t('purchase1.checkout.price', {
              price: currency === Currency.MXN ? OFFER_PRICE_MX : OFFER_PRICE,
              currency: CURRENCY_SYMBOLS[currency],
            })}
          </span>
        </S.Price>
      </S.CardContainer>

      <S.TimerRow>
        {t('purchaseWithoutIntro.expiresIn')}
        <Timer />
      </S.TimerRow>
    </S.Wrapper>
  )
}
