import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledCurrentWeight = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  WeightLabel: styled.div`
    width: 90px;
    height: 34px;
    margin: 0 auto 30px;
    border-radius: 25px;
    background-color: ${Color.LIGHT};
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    color: ${Color.DARK};
    text-align: center;
  `,
  Container: styled(Container)<{ $isAgeFlow: boolean }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
