import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTimerWithPersonalization as S } from './TimerWithPersonalization.styles'

type TProps = {
  className?: string
}

export const TimerWithPersonalization: FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.GradientContainer>
      <S.Wrapper className={className}>
        <S.Title>{t('purchaseTwoPlans.timer.title')}</S.Title>
        <S.Subtitle>{t('purchaseTwoPlans.timer.subtitle')}</S.Subtitle>

        <S.TimerContainer>
          <S.Text>{t('purchaseTwoPlans.timer.text')}</S.Text>
          <S.Timer />
        </S.TimerContainer>
      </S.Wrapper>
    </S.GradientContainer>
  )
}
