import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useElements, useStripe } from '@stripe/react-stripe-js'

import { PaymentMethod } from 'modules/purchase/constants'
import { setPaymentMethodAction } from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { StyledPaymentElementButton as S } from './PaymentElementButton.styles'

type TProps = {
  disabled?: boolean
  paymentMethod: PaymentMethod
  setIsUpdated: (state: boolean) => void
  onClick?: () => void
}

const COLORS_MAP = {
  [PaymentMethod.LINK]: '#61d17a',
  [PaymentMethod.AMAZON_PAY]: '#f5d48c',
  [PaymentMethod.REVOLUT_PAY]: '#191c1f',
  [PaymentMethod.CASHAPP]: '#191c1f',
  [PaymentMethod.BOLETO]: '#191c1f',
}

export const PaymentElementButton: React.FC<TProps> = ({
  paymentMethod,
  setIsUpdated,
  disabled = false,
  onClick,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const selectedSubscription = useSelector(selectSubscription)

  const handleClick = async (e) => {
    e.preventDefault()
    onClick && onClick()

    if (!elements || !stripe || !selectedSubscription) return

    dispatch(setPaymentMethodAction(paymentMethod))

    elements.update({
      mode: 'subscription',
      paymentMethodTypes: [paymentMethod],
      amount: Math.round(selectedSubscription.mainPrices.fullPrice * 100),
      currency: selectedSubscription.currency,
    })

    setIsUpdated(true)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }

  return (
    <S.Button
      $type={paymentMethod}
      disabled={disabled}
      onClick={handleClick}
      backgroundColor={COLORS_MAP[paymentMethod]}
    />
  )
}
