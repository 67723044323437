import React, { useCallback, useState } from 'react'

import { AccordionItem } from 'components/Accordion/AccordionItem'
import { ACCORDION_CONTENT } from 'components/Accordion/constants'

import { AccordionStyles as S } from './Accordion.styles'

type TAccordionData = {
  title: string
  content: string
}

type TAccordionProps = {
  accordionData?: TAccordionData[]
}

export const Accordion: React.FC<TAccordionProps> = ({
  accordionData = ACCORDION_CONTENT,
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleItemClick = useCallback((index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }, [])

  return (
    <S.Accordion>
      {accordionData.map((item, index) => (
        <AccordionItem
          key={item.title}
          title={item.title}
          content={item.content}
          isOpen={index === openIndex}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </S.Accordion>
  )
}
