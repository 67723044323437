import styled, { createGlobalStyle, css, keyframes } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import appleIcon from 'assets/images/continue-with-apple.svg'
import emailIcon from 'assets/images/continue-with-email.svg'
import facebookIcon from 'assets/images/continue-with-facebook.svg'
import crossIcon from 'assets/images/cross.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export type TMenuProps = {
  $isMenuVisible: boolean
}

export const GlobalFontStyle = createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
    --primary-dark-color: #17202a;
  }
`

const StyledLoginButtonBase = css`
  ${StyledButtonBase};
  width: 100%;
  max-width: 328px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: 20px center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const baseMediaStyles = css`
  width: 300px;

  @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    width: 340px;
  }
`

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  ${baseMediaStyles};
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseInputStyles = css`
  display: block;
  width: 100%;
  height: 60px;
  padding: 20px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: #163b75;
  outline: none;
  transition: border 0.2s ease-out;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: white;
    opacity: 0.3;
  }
`

export const dotsAnimation = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
`

export const dotsAnimationStyles = css`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: ${Color.GRAY};
  animation: ${dotsAnimation} 1.5s infinite ease-in-out;
  border-radius: 50%;

  &:nth-child(2) {
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }
`

export const backdropStyles = css<TMenuProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 11;
  backdrop-filter: blur(3px);
  opacity: ${({ $isMenuVisible }) => ($isMenuVisible ? '1' : '0')};
  visibility: ${({ $isMenuVisible }) => ($isMenuVisible ? 'visible' : 'none')};
  pointer-events: ${({ $isMenuVisible }) =>
    $isMenuVisible ? 'default' : 'none'};
  transition: opacity 0.25s;
`

export const sidebarStyles = css<TMenuProps>`
  width: 280px;
  height: var(--full-height, 100vh);
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  position: absolute;
  z-index: 11;
  padding: 80px 25px;
  transition: transform 0.25s;
  transform: ${({ $isMenuVisible }) =>
    $isMenuVisible ? 'translateX(0)' : 'translateX(100%)'};
`

export const menuCloseButtonStyles = css`
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #bfbfbf;
  position: absolute;
  top: 25px;
  left: 25px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &::after,
  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${Color.WHITE};
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 10px);
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`

export const CloseButton = styled.button`
  box-sizing: content-box;
  width: 34px;
  height: 34px;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${crossIcon});
  background-size: 24px 24px;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const PageHeader = styled.header`
  min-height: 96px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`

export const StyledLoginButton = {
  Email: styled.button`
    ${StyledLoginButtonBase};
    background-image: url(${emailIcon});

    &[data-icon='false'] {
      background-image: none;
    }
  `,
  Facebook: styled.button`
    ${StyledLoginButtonBase};
    padding-left: 30px;
    background-image: url(${facebookIcon});
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledLoginButtonBase};
    background-image: url(${appleIcon});
    background-color: #010002;
  `,
}
