import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-pink.svg'
import priceBadge from 'assets/images/price-badge-ja.svg'
import selectedPriceBadge from 'assets/images/selected-price-badge-ja.svg'
import stripedBg from 'assets/images/striped-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected: boolean
}

type TPriceProps = { $hasSmallPrices: boolean }

const commonPriceStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

const crossedPriceStyles = css`
  color: ${Color.GRAY_80};
  text-decoration: line-through;
  text-decoration-color: ${Color.RED};
`

export const StyledSubscriptionItemIntroOffer = {
  Wrapper: styled.div<TProps & { $isDefault: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 8px 0;
    background: ${Color.WHITE};
    border-radius: 4px;
    border: 1px solid #f4d267;
    outline: 1px solid transparent;

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        outline: 1px solid #e43a91;
        border: 1px solid #e43a91;
      `}

    ${({ $isDefault }) =>
      $isDefault &&
      css`
        border-top: 20px solid #e43a91;
      `}
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 22px;
      height: 22px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #e43a91;
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -29px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 20px;
    text-align: center;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    background: url(${stripedBg});
    background-repeat: round;
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 56px;
  `,
  PlanPeriod: styled.span<TProps>`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    padding-bottom: 2px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        color: #e43a91;
      `}
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span`
    ${crossedPriceStyles};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  `,
  PreviousPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    ${crossedPriceStyles};
    padding-right: 6px;
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CurrentPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    color: ${Color.GRAY_80};
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 88px;
    min-height: 57px;
    margin-right: 16px;
    padding: 8px 4px;
    color: ${Color.GRAY_80};
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadge});
    background-size: 100%;

    ${({ $isSelected }) =>
      $isSelected &&
      `
        background-image: url(${selectedPriceBadge});
        color: #ffffff;
        
        & > span:first-child {
          color: rgba(255, 255, 255, 0.5);
        }
    
    `};
  `,
  PriceValue: styled.span`
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    padding: 2px 0;
  `,
  Period: styled.span`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
  `,
}
