import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AnswerWithIconAndCheckbox, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { MAIN_GOALS } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const MainGoalMenopause: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers,
    })

    dispatch(
      setAnswersAction({
        answers: [MAIN_GOALS.LOSE_WEIGHT],
        pageId: PageId.MAIN_GOAL,
      }),
    )

    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }, [answers, dispatch, nextPagePath])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={24}>
            {t('onboarding.mainGoal.titleWithAge')}
          </PageTitle>
          {OPTION_VALUES.map(({ value, title, img }) => (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={value}
              value={value}
              imageSrc={img}
              theme={answerTheme.DANCEBIT}
              borderRadius="16px"
              height="100px"
              padding="16px 16px 16px 8px"
              maxWidth="345px"
              imageAlignSelf="center"
              imageHeight="84x"
              imageWidth="84px"
              margin="0 0 16px 0"
              iconSrc={checkIcon}
              checkboxWidth="24px"
              checked={answers.includes(value)}
            >
              <Trans>{title}</Trans>
            </AnswerWithIconAndCheckbox>
          ))}
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </NavigationButtonBelly>
    </>
  )
}
