import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router'

import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const PurchaseProvider: FC = () => {
  const { search } = useLocation()
  const { isRefCohort } = useCohortInfo()

  const hasPurchaseFreeOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_988)
  const hasPurchaseFreeOfferBellyTest = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1042,
  )
  const hasNewPaywallTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1267)
  const hasJapaneseFlowTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const growthBookState = useGrowthBook()

  useEffect(() => {
    if (!growthBookState?.ready) return

    let nextPage = isRefCohort
      ? PageId.PURCHASE_REF
      : PageId.PURCHASE_WITHOUT_INTRO

    if (hasPurchaseFreeOfferTest || hasPurchaseFreeOfferBellyTest) {
      nextPage = PageId.PURCHASE_FREE_OFFER
    }

    if (hasJapaneseFlowTest) {
      nextPage = PageId.PURCHASE_JAPAN
    }

    if (hasNewPaywallTest) {
      nextPage = PageId.PURCHASE_NEW
    }

    goTo({
      pathname: nextPage,
      search,
    })
  }, [
    isRefCohort,
    growthBookState,
    hasNewPaywallTest,
    hasPurchaseFreeOfferBellyTest,
    hasPurchaseFreeOfferTest,
    search,
    hasJapaneseFlowTest,
  ])

  return <Spinner />
}
