import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import {
  DEFAULT_SUBSCRIPTION_ITEM,
  useDynamicPaywallConfig,
} from 'hooks/useDynamicPaywallConfig'

import { SUBSCRIPTION_ITEMS } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionsBlock as S } from './SubscriptionsBlock.styles'

type TProps = {
  buttonWrapperElementRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}

export const SubscriptionsBlock: React.FC<TProps> = ({
  children,
  buttonWrapperElementRef,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const {
    subscriptionBlockTitle,
    subscriptionBlockType = DEFAULT_SUBSCRIPTION_ITEM,
  } = useDynamicPaywallConfig()

  const SubscriptionItem = SUBSCRIPTION_ITEMS[subscriptionBlockType]

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      <S.Title>
        {subscriptionBlockTitle || t`purchase1.subscription.title`}
      </S.Title>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}
      <S.ButtonContainer ref={buttonWrapperElementRef}>
        {children}
      </S.ButtonContainer>
    </S.Wrapper>
  )
}
