import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import barBg from 'assets/images/flow-belly/bmi-bar-bg.png'
import labelConnector from 'assets/images/flow-belly/label-connector.png'

import { Color } from 'root-constants'

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const leftRightAnimation = keyframes`
  from {
    left: 0;
  }
`

export const StyledFitnessProfile = {
  Container: styled(Container)`
    margin: 16px auto 24px;
  `,
  CardContainer: styled.div<{ $bgImage: string }>`
    position: relative;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
    min-height: 488px;

    &::after {
      position: absolute;
      content: '';
      width: 151px;
      height: 198px;
      background: ${({ $bgImage }) => `url(${$bgImage}) no-repeat`};
      background-size: contain;
      bottom: 0;
      right: 0;
    }
  `,
  CardHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 54px;
  `,
  Label: styled.div<{ $color: string }>`
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px 6px;
    border-radius: 6px;
    margin-left: 4px;
    color: ${Color.WHITE};
    background: ${({ $color }) => $color};
  `,
  BmiCard: styled.div<{ $color: string }>`
    position: relative;
    border-radius: 16px;
    padding: 16px 16px 16px 48px;
    background: ${({ $color }) => $color};
    color: ${Color.GRAY_100};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 24px 0;
  `,
  Icon: styled(SvgImage)<{ $color: string }>`
    position: absolute;
    top: 16px;
    left: 16px;
    width: 22px;
    height: 22px;
    color: ${({ $color }) => $color};
  `,
  BmiLabel: styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  `,
  BmiBar: styled.div`
    position: relative;
    width: 100%;
    height: 42px;
    background: url(${barBg}) no-repeat;
    background-size: contain;

    &::before {
      content: '';
      position: absolute;
      height: 6px;
      width: 100%;
      border-radius: 26px;
      background: linear-gradient(
        90deg,
        #88d7ef 8.91%,
        #34c759 39.59%,
        #ffdf5d 71.14%,
        #ff2d55 98.99%
      );
      top: 22px;
      left: 0;
    }
  `,
  UserGoal: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;

    svg {
      fill: ${Color.GRAY_10};
      margin-right: 8px;
    }
  `,
  Value: styled.div`
    position: absolute;
    border-radius: 32px;
    background: ${Color.DARK};
    color: ${Color.WHITE};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    padding: 5px 9px;
    min-width: 73px;
    width: max-content;
    top: -38px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-duration: 200ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      height: 20px;
      width: 8px;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      background: url(${labelConnector}) no-repeat;
      background-size: contain;
    }
  `,
  MarkPointIcon: styled(SvgImage)`
    height: 50px;
    width: 50px;
  `,
  MarkPoint: styled.div<{ $left: string }>`
    position: absolute;
    animation-name: ${leftRightAnimation};
    animation-timing-function: linear;
    animation-duration: 900ms;
    animation-fill-mode: forwards;
    top: 3px;
    left: ${({ $left }) => `calc(${$left} - 20px)`};
  `,
  GoalValue: styled.div`
    & > p {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      &:first-child {
        color: ${Color.GRAY_60};
      }
    }
  `,
  BmiValuesList: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 6px;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 16px;
  `,
  BmiValue: styled.div<{ $isSelected: boolean }>`
    color: ${({ $isSelected }) => ($isSelected ? Color.DARK : Color.GRAY_60)};
  `,
}
