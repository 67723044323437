import styled, { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

import { ThemeTimerConfig } from './constants'

export const StyledDynamicTimer = {
  Container: styled.div<{ $theme?: string }>`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    justify-content: center;
    align-items: baseline;
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};
    margin-bottom: 32px;

    ${({ $theme }) =>
      $theme ? ThemeTimerConfig[$theme] : ThemeTimerConfig.violet}
  `,
  Text: styled.div<{ $theme?: string }>`
    display: flex;
    align-items: center;

    & > div {
      margin: 0 4px;
    }

    ${({ $theme }) =>
      $theme === DynamicDiscountTheme.BLACK &&
      css`
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(
          270deg,
          #ffdd63 3.41%,
          #ee9700 112.07%
        );
      `}
  `,
}
