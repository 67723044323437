import React from 'react'

import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { FreeOfferReviewCard } from 'modules/purchase/components/FreeOfferReviewCard'

import { StyledReviewsFreeOffer as S } from './ReviewsFreeOffer.styles'
import { CUSTOMER_REVIEWS } from './constants'

type TProps = {
  className?: string
  reviews?: {
    icon: string
    name: string
    date: string
    image: string
    likesCount: number
    commentsCount: number
  }[]
}

Swiper.use([Autoplay])

export const ReviewsFreeOffer: React.FC<TProps> = ({
  className = '',
  reviews = CUSTOMER_REVIEWS,
}) => (
  <S.Wrapper className={className}>
    <Carousel
      slidesPerView={1}
      loop
      autoplay={{
        disableOnInteraction: false,
      }}
    >
      {reviews.map(({ icon, date, name, image, likesCount, commentsCount }) => (
        <SwiperSlide key={name}>
          <FreeOfferReviewCard
            icon={icon}
            date={date}
            name={name}
            image={image}
            likesCount={likesCount}
            commentsCount={commentsCount}
          />
        </SwiperSlide>
      ))}
    </Carousel>
  </S.Wrapper>
)
