import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectHasUpsellOffer } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { eventLogger } from 'services/eventLogger.service'

import burgerSvg from 'assets/images/sprite/burger.svg'
import logoSvg from 'assets/images/sprite/logo.svg'

import { PageId } from 'page-constants'
import { PagesSource, SUPPORT_LINK } from 'root-constants'

import { StyledHeader as S } from './Header.styles'
import { PAGES_WITHOUT_BACK_BUTTON } from './constants'

type TProps = {
  showHeader?: boolean
}

export const Header: React.FC<TProps> = ({ showHeader = false }) => {
  const { t } = useTranslation()

  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const userStatus = useSelector(selectUserStatus)

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const { currentPageId, hasHeader, hasBurgerMenu } = useGetPageInfo()

  const isLoginWithoutBackButton = useMemo(() => {
    const hasUpsellStep = currentPageId === PageId.LOGIN && !hasUpsellOffer

    const hasUpsellTried =
      currentPageId === PageId.LOGIN &&
      (userStatus?.upsell.isUpsellTried ||
        !userStatus?.upsell.isUpsellAvailable)

    return hasUpsellStep || hasUpsellTried
  }, [currentPageId, hasUpsellOffer, userStatus?.upsell])

  const isPageWithoutBackButton = useMemo(
    () =>
      PAGES_WITHOUT_BACK_BUTTON.includes(currentPageId as PageId) ||
      isLoginWithoutBackButton,
    [currentPageId, isLoginWithoutBackButton],
  )

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  if (!hasHeader && !showHeader) return null

  return (
    <S.Wrapper $hasWidget={hasBurgerMenu}>
      {!isPageWithoutBackButton && <S.HeaderBackButton />}

      <S.Logo>
        <S.SvgLogo svg={logoSvg} />
        <span>Dancebit</span>
      </S.Logo>

      {hasBurgerMenu && (
        <>
          <S.ButtonContainer>
            <S.SupportAction
              href={SUPPORT_LINK}
              onClick={() => eventLogger.logNeedHelpClicked()}
            >
              {t`actions.needHelp`}
            </S.SupportAction>

            <S.Burger type="button" onClick={toggleMenuVisibility}>
              <SvgImage svg={burgerSvg} height={14} />
            </S.Burger>
          </S.ButtonContainer>
          <S.Backdrop
            $isMenuVisible={isMenuVisible}
            onClick={toggleMenuVisibility}
          />
          <S.Menu $isMenuVisible={isMenuVisible}>
            <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
            <S.StyledMenuLinkContainer>
              <TermsOfUseLink source={PagesSource.LANDING} />
            </S.StyledMenuLinkContainer>
            <S.StyledMenuLinkContainer>
              <PrivacyPolicyLink source={PagesSource.LANDING} />
            </S.StyledMenuLinkContainer>
          </S.Menu>
        </>
      )}
    </S.Wrapper>
  )
}
