import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentUserMeasurementSystem,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import heroBg from 'assets/images/upsell-hero-image.png'

import { CDN_FOLDER_LINK, LOST_WEIGHT } from 'root-constants'

import { StyledUpsellCancelOfferHero as S } from './UpsellCancelOfferHero.styles'

export const UpsellCancelOfferHero: React.FC = () => {
  const { t } = useTranslation()
  const isStayFitFlow = useSelector(selectIsStayFitFlow)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  return isStayFitFlow ? (
    <S.VideoWrapper>
      <video
        muted
        loop
        autoPlay
        controls={false}
        playsInline
        width={328}
        height={204}
        poster={`${CDN_FOLDER_LINK}/videos/upsell-hero.png`}
      >
        <source
          src={`${CDN_FOLDER_LINK}/videos/upsell-hero.mp4`}
          type="video/mp4"
        />
      </video>
    </S.VideoWrapper>
  ) : (
    <>
      <S.ImageWrapper>
        <S.Img src={heroBg} />
        <S.Result>
          <Trans
            i18nKey="upsellCancelOffer.result"
            values={{
              lostWeight: LOST_WEIGHT[currentMeasurementSystem as string],
              weightUnit: t(`commonComponents.${currentMeasurementSystem}`),
            }}
            components={{ highlighted: <span /> }}
          />
        </S.Result>
      </S.ImageWrapper>
      <S.Disclaimer>{t('upsellCancelOffer.photoDesc')}</S.Disclaimer>
    </>
  )
}
