import { StyledJapaneseBorder } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'
import { ReachGraphBelly } from 'components/ReachGraphBelly'

import { Color } from 'root-constants'

export const StylesReach = {
  Title: styled(PageTitle)`
    margin-top: 16px;

    & strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.PRIMARY};
    }
  `,

  Badge: styled.div<{
    $hasMotivationEvent: boolean
    $isJapanese: boolean
    $isAdult: boolean
  }>`
    max-width: ${({ $hasMotivationEvent }) =>
      $hasMotivationEvent ? '260px' : '145px'};
    width: 100%;
    text-align: center;
    margin: 12px auto 24px;
    padding: 4px 8px;
    border-radius: 4px;
    background: ${({ $isJapanese }) =>
      $isJapanese
        ? 'linear-gradient(90deg, #FFFCE0 0%, #FFF7A5 100%)'
        : Color.GRAY_10};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    strong {
      text-transform: lowercase;
    }

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        background: ${Color.WHITE};
      `}
  `,
  Description: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 43px;
  `,
  ReachGraphBelly: styled(ReachGraphBelly)<{
    $isJapanese: boolean
    $isAdult: boolean
  }>`
    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-color: ${Color.PRIMARY};
        ${StyledJapaneseBorder};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border: 1px solid #c6e0f4;
      `}
  `,
  Container: styled(Container)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: 0.5px solid #a7cdea;
        background-color: #d5ebff;
      `},
  `,
}
