import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IMPROVE_AREAS_VALUES } from 'pages/questions/ImproveAreas/constants'
import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowSvg from 'assets/images/sprite/arrow-button-with-bg.svg'

import { PageId } from 'page-constants'
import {
  Cohort,
  Color,
  DynamicDiscountType,
  Gender,
  PagesSource,
} from 'root-constants'

import { DEFAULT_OPTIONS, OPTION_VALUES } from '../Welcome/constants'
import { WelcomeBellyStyles as S } from './WelcomeBelly.styles'
import { QUESTION, QUESTION_LEGS, QUESTION_SOMATIC } from './constants'

export const WelcomeBelly: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { title, subtitle, stickerText, optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const { isLegsFlow, isSomaticFlow, isSaleFlow } = useCohortInfo()

  useStartSession()

  const question = useMemo(() => {
    if (isLegsFlow) return QUESTION_LEGS

    if (isSomaticFlow) return QUESTION_SOMATIC

    return QUESTION
  }, [isLegsFlow, isSomaticFlow])

  const handleContinue = useNextStep({
    pageId,
    question,
    nextPagePath,
  })

  const translationPrefix = useMemo(() => {
    if (isLegsFlow) {
      return 'onboarding.welcomeLegs'
    }

    if (isSomaticFlow) {
      return 'onboarding.welcomeSomatic'
    }

    return 'onboarding.welcomeBelly'
  }, [isSomaticFlow, isLegsFlow])

  const cards = useMemo(() => {
    if (optionData && optionData.length) return optionData

    return OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS
  }, [optionData, cohortToUse])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            {
              eventID: isPersonalDataAllowed ? uuid : '',
            },
          )
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question,
          answers: value,
        })

        dispatch(
          setAnswersAction({
            answers: Gender.FEMALE,
            pageId: PageId.GENDER,
          }),
        )
        dispatch(
          setAnswersAction({
            answers: isLegsFlow
              ? [IMPROVE_AREAS_VALUES.LEGS, IMPROVE_AREAS_VALUES.HIPS]
              : [IMPROVE_AREAS_VALUES.BELLY],
            pageId: PageId.IMPROVE_AREAS,
          }),
        )

        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      isPersonalDataAllowed,
      uuid,
      question,
      isLegsFlow,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Root>
      {dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}

      {isSaleFlow && dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
        <Ticker />
      )}

      <S.Container isLarge>
        {stickerText && (
          <S.Sticker>
            <p>{stickerText}</p>
          </S.Sticker>
        )}
        <S.Title>
          <Trans i18nKey={title || `${translationPrefix}.title`} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey={subtitle || `${translationPrefix}.subtitle`} />
        </S.Subtitle>

        <S.Actions $isLegsFlow={isLegsFlow}>
          {cards.map(({ value, imageUrl, text, from, to }) => (
            <Answer
              {...optionProps}
              theme={answerTheme.DANCEBIT}
              backgroundColor={Color.WHITE}
              height="auto"
              padding="5px"
              maxWidth="150px"
              boxShadow="none"
              value={value}
              minHeight="168px"
              minWidth="160px"
              disabled={isAnswersDisabled}
              key={value}
            >
              <S.Image $isLegsFlow={isLegsFlow} src={imageUrl} alt={value} />
              {text ? (
                <S.Action>
                  <Trans>{text}</Trans>{' '}
                  <SvgImage svg={arrowSvg} height={24} width={24} />
                </S.Action>
              ) : (
                <S.Action>
                  <Trans
                    i18nKey="onboarding.welcome.optionTitle"
                    values={{
                      context: from,
                    }}
                    components={{
                      age: to
                        ? t('onboarding.welcome.age', {
                            from,
                            to,
                          })
                        : from,
                      icon: <SvgImage svg={arrowSvg} height={24} width={24} />,
                    }}
                  />
                </S.Action>
              )}
            </Answer>
          ))}
        </S.Actions>

        <S.Terms>
          <p>{t('footer.disclaimer')}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
