import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledHowMotivated = {
  Container: styled(Container)`
    ${StyledAgeFlowContainer};
    padding-top: 8px;
  `,
}
