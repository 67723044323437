import React from 'react'
import { Trans } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { PRIVACY_POLICY_LINK } from 'root-constants'

import { OuterLink } from './OuterLink'

export const PrivacyPolicyLink: React.FC<{
  source: string
  text?: string
}> = ({ text, source }) => (
  <OuterLink
    href={PRIVACY_POLICY_LINK}
    onClick={() => eventLogger.logPrivacyPolicyClicked(source)}
  >
    <Trans i18nKey={text || 'commonComponents.privacyPolicy'} />
  </OuterLink>
)
