import {
  INCHES_IN_FOOT,
  INITIAL_INCHES_VALUE,
  MAX_HEIGHT_INCHES,
} from 'root-constants'

export const convertFeetToInches = (feet, inches) => {
  const heightInInches =
    Number(feet) * INCHES_IN_FOOT + Number(inches || INITIAL_INCHES_VALUE)
  return heightInInches > MAX_HEIGHT_INCHES ? MAX_HEIGHT_INCHES : heightInInches
}
