import React, { FC } from 'react'

import { StyledToggleV2 as S } from './ToggleV2.styles'

type TProps = {
  isChecked: boolean
  onChange: (event) => void
  value: string
}

export const ToggleV2: FC<TProps> = ({
  isChecked = false,
  onChange,
  value,
}) => (
  <S.Wrapper data-testid="toggle">
    <S.Toggle
      value={value}
      checked={isChecked}
      onChange={() => onChange(value)}
      type="checkbox"
    />
    <S.Button />
    <S.Track />
  </S.Wrapper>
)
