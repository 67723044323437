import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import alertIcon from 'assets/images/alert-circle.svg'
import lockIcon from 'assets/images/protected-payment-icon.svg'

import { Color, INPUT_TEXT_COLOR } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '51px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: INPUT_TEXT_COLOR,
      fontWeight: 500,
    },
  },
  invalid: {
    color: '#ff8484',
  },
}

export const stripeElementStyleBelly: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '51px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.GRAY_60,
      fontWeight: 400,
    },
  },
  invalid: {
    color: '#ff8484',
  },
}

export const commonInputStyles = css<{ $isBellyFlow?: boolean }>`
  color: ${Color.DARK};
  height: 51px;
  background-color: ${Color.WHITE};
  line-height: 51px;
  border-radius: 14px;
  border: ${({ $isBellyFlow }) =>
    $isBellyFlow ? `1px solid ${Color.GRAY_60}` : '1px solid #eaeaec'};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    position: relative;
    border-color: #ff8484;
    background-color: #fff3f3;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
    }
  }
`

export const StyledCreditCardFormCancelOffer = {
  TitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  PaymentInfo: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
  `,
  Label: styled.span`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #b8bbc1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      width: 12px;
      height: 14px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  CardExpiryElement: styled(CardExpiryElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  CardCvcElement: styled(CardCvcElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  CardholderInput: styled.input<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles};
    width: 100%;
    margin-bottom: 24px;
    font-family: ${({ $isBellyFlow }) =>
      $isBellyFlow ? 'Open Sans, sans-serif' : 'Roboto, sans-serif'};
    outline: none;
    box-shadow: none;

    &::placeholder {
      color: ${({ $isBellyFlow }) =>
        $isBellyFlow ? Color.GRAY_60 : INPUT_TEXT_COLOR};
      font-weight: ${({ $isBellyFlow }) => ($isBellyFlow ? 400 : 500)};
    }
  `,
  Button: styled(Button)`
    max-width: 320px;
  `,
}
