import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledMainGoalAgeFlow = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isLegsFlow: boolean
    $bgImageUrl?: string
    $isJapanese: boolean
  }>`
    background-image: ${({ $bgImageUrl }) =>
      $bgImageUrl && `url(${$bgImageUrl})`};
    background-size: ${({ $isLegsFlow }) =>
      $isLegsFlow ? '202px 475px' : '375px 460px'};
    background-repeat: no-repeat;
    background-position: bottom right;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-position: top right;
        background-size: 375px 560px;
        ${StyledJapaneseContainer};
      `}
  `,
}
