import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserTTCLID } from 'root-redux/actions/user'
import { selectTiktokPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useTiktokPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldTiktokPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [isEUUser, userCookieConsentAnswer, hasAdvertizingAndMarketingCookie],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) return

    const tiktokPixelScript = document.createElement('script')

    tiktokPixelScript.type = 'text/javascript'
    tiktokPixelScript.text = `
     !function (w, d, t) {
          w.TiktokAnalyticsObject=t;
          var ttq=w[t]=w[t]||[];
          ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
          ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)));};};
          for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
          ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e;},
          ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},
          ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a);};
          }(window, document, "ttq");
    `
    document.body.appendChild(tiktokPixelScript)

    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page({ event_id: uuid })

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid) dispatch(sendUserTTCLID(ttclid))
  }, [
    uuid,
    email,
    tiktokPixelId,
    dispatch,
    userCountryCode,
    shouldTiktokPixelBePaused,
    isPersonalDataAllowed,
  ])
}
