import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { FreeOfferCard } from 'modules/purchase/components/FreeOfferCard'
import { PhotoResultFreeOffer } from 'modules/purchase/components/PhotoResultFreeOffer'
import { ReviewsFreeOffer } from 'modules/purchase/components/ReviewsFreeOffer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubheaderWithTimerFreeOffer } from 'modules/purchase/components/SubheaderWithTimerFreeOffer'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import whiteCheck from 'assets/images/sprite/white-check.svg'

import {
  Color,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseFreeOffer as S } from './PurchaseFreeOffer.styles'

export const PurchaseFreeOffer: FC = () => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const goalWeight = useSelector(selectCurrentUserGoalWeight) || 44

  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
  } = usePurchaseStore()
  const { buttonText } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
    screenId: ScreenId.PAYWALL_LEGS,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const hasIncludedVat = useVatInfo()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.PAYWALL_LEGS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    window.axon && window.axon('track', 'view_item')

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.RootWrapper>
          <S.BannerImage>
            <S.TitleText>{t`purchaseFreeOffer.title`}</S.TitleText>
            <S.GoalWeight>
              {goalWeight} {t(`commonComponents.${currentMeasurementSystem}`)}
            </S.GoalWeight>
            <S.SubtitleText>{t`purchaseFreeOffer.subtitle`}</S.SubtitleText>
          </S.BannerImage>
          <S.DiscountActivated $marginBottom={32}>
            {t('purchaseFreeOffer.discountActivated')}
            <SvgImage svg={whiteCheck} width={20} height={20} />
          </S.DiscountActivated>
          <div>
            <S.Wrapper isLarge>
              <ReviewsFreeOffer />
            </S.Wrapper>

            <SubheaderWithTimerFreeOffer
              elemForComparisonRef={buttonWrapperElementRef}
              onButtonClick={handleShowPayment}
            />

            <S.Wrapper isLarge>
              <S.ReceivedPlan elemForComparisonRef={buttonWrapperElementRef} />
              <FreeOfferCard />
              <S.SubscriptionsBlockIntroOffer disclaimerVisible={false}>
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </S.SubscriptionsBlockIntroOffer>
              <S.IntroOfferDisclaimer $marginBottom={40} />
              <S.Container>
                <FeaturedBlock />
              </S.Container>
              <S.MoneyBackGuarantee customBackground={Color.WHITE} />
              <PhotoResultFreeOffer />
              <S.FAQList />
            </S.Wrapper>

            <S.DiscountActivated $marginBottom={40}>
              {t('purchaseFreeOffer.discountActivated')}
              <SvgImage svg={whiteCheck} width={20} height={20} />
            </S.DiscountActivated>

            <S.Wrapper>
              <S.SubscriptionsBlockIntroOffer disclaimerVisible={false}>
                <S.OfferCard />
                <S.Button
                  type="button"
                  data-order="3"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </S.SubscriptionsBlockIntroOffer>
              <S.IntroOfferDisclaimer $marginBottom={60} />
              <SecurityInfo />
            </S.Wrapper>
          </div>
        </S.RootWrapper>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
