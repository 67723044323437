import styled, { css } from 'styled-components'

import closedEyeIcon from 'assets/images/close-eye.svg'
import openedEyeIcon from 'assets/images/open-eye.svg'

import { Color } from 'root-constants'

type TInputProps = {
  $hasLabel: boolean
  $hasButton: boolean
  $isAgeFlow: boolean
  $isContentCentered: boolean
}

export const StyledInput = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    &::after {
      display: block;
      position: absolute;
      left: 16px;
      color: #f37264;
      font-size: 13px;
      line-height: 20px;
    }
    &[data-valid='false'] {
      &::after {
        content: attr(data-validation-text);
      }
    }
  `,
  Input: styled.input<TInputProps>`
    display: block;
    width: 100%;
    height: 64px;
    padding: 8px 25px;
    ${({ $hasLabel }) =>
      $hasLabel &&
      css`
        padding-top: 32px;
      `};
    ${({ $hasButton }) =>
      $hasButton &&
      css`
        padding-right: 52px;
      `};
    ${({ $isContentCentered }) =>
      $isContentCentered &&
      css`
        text-align: center;
      `};
    color: ${Color.DARK};
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    border-radius: 16px;
    border: none;
    background-color: ${Color.LIGHT};
    outline: none;
    transition: border 0.2s ease-out;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        background-color: ${Color.WHITE};
        border: 1px solid ${Color.PRIMARY_LIGHT};
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
      `};

    &:valid,
    &:focus {
      & + span {
        top: 10px;
        color: ${Color.DARK};
        font-size: 13px;
        line-height: 1;
      }
    }

    &[type='number'] {
      /* Hide arrows */
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &::placeholder {
      font-weight: 500;
      line-height: 20px;
      color: rgba(19, 29, 48, 0.5);
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 20px;
    left: 25px;
    color: #81848c;
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  VisibilityButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 60px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    &[data-visible='true'] {
      background-image: url(${openedEyeIcon});
    }
    &[data-visible='false'] {
      background-image: url(${closedEyeIcon});
    }
  `,
}
