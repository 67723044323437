import styled, { keyframes } from 'styled-components'

import { OuterLink } from 'components/OuterLink'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TMenuProps = {
  $isMenuVisible: boolean
}

const flickering = keyframes`
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
`

export const StyledPurchaseNoOnboarding = {
  Wrapper: styled.div`
    position: relative;
  `,
  Discount: styled.div`
    display: flex;
    background: linear-gradient(
      276.96deg,
      #ffc530 12.51%,
      #ffe8ae 77.39%,
      #ffe8ae 77.39%
    );
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    text-align: center;
    padding: 16px 30px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  `,
  DiscountText: styled.div`
    max-width: 115px;
    text-align: left;
  `,
  DiscountTitle: styled.h3`
    font-weight: 800;
    font-size: 28px;
    line-height: 22px;
    margin-bottom: 2px;
  `,
  DiscountDescription: styled.p`
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 30px;
  `,
  Burger: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 14px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: ${Color.WHITE};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Logo: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.DARK};

    svg {
      flex-shrink: 0;
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  `,
  HelpAction: styled(OuterLink)`
    background-color: transparent;
    border: none;
    padding: 0;
    color: rgb(45, 50, 64);
    font-size: 20px;
    line-height: 24px;
  `,
  Loading: styled.span`
    animation: ${flickering} 0.75s linear infinite;
    font-size: 20px;
    line-height: 24px;
  `,
  Action: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.WHITE};
    width: 128px;
    height: 40px;
    background-color: ${Color.PRIMARY};
    margin: 0 0 0 auto;
    border: none;
    padding: 0;
    border-radius: 8px;
  `,
  Backdrop: styled.div<TMenuProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 11;
    backdrop-filter: blur(3px);
    opacity: ${({ $isMenuVisible }) => ($isMenuVisible ? '1' : '0')};
    visibility: ${({ $isMenuVisible }) =>
      $isMenuVisible ? 'visible' : 'none'};
    pointer-events: ${({ $isMenuVisible }) =>
      $isMenuVisible ? 'default' : 'none'};
    transition: opacity 0.25s;
  `,
  Menu: styled.nav<TMenuProps>`
    width: 280px;
    height: var(--full-height, 100vh);
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    position: absolute;
    z-index: 11;
    padding: 80px 25px;
    transition: transform 0.25s;
    transform: ${({ $isMenuVisible }) =>
      $isMenuVisible ? 'translateX(0)' : 'translateX(100%)'};
  `,
  MenuCloseButton: styled.button`
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: #bfbfbf;
    position: absolute;
    top: 25px;
    left: 25px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::after,
    &::before {
      display: block;
      content: '';
      width: 20px;
      height: 2px;
      background-color: ${Color.WHITE};
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 10px);
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  Hero: styled.div`
    position: relative;
    margin-bottom: 24px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 24px auto;
    }
  `,
  Ratio: styled.div`
    padding-top: 129.44%;
  `,
  HeroImage: styled.img`
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: ${Color.DARK};
    text-align: center;
  `,
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  `,
  SubscriptionsListTitle: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.PRIMARY};
    margin-bottom: 24px;
    text-align: center;
  `,
  DailyBenefitsContainer: styled.div`
    margin-bottom: 24px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 40px;
  `,
  BottomContainer: styled.div`
    background: #403d44;
    border-top: 16px solid #03aaf5;
    border-radius: 60px 60px 0 0;
    padding: 64px 20px 32px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  BottomRow: styled.div`
    max-width: 296px;
    margin: 0 auto;
  `,
  ProgramPlanContainer: styled.div`
    margin-bottom: 24px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 18px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 18px;
    padding-left: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  SubscriptionContainer: styled.div`
    margin-bottom: 48px;
  `,
}
