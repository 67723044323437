import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionDisclaimer = {
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: ${Color.LIGHT_GREY};

    a {
      color: ${Color.LIGHT_GREY};
      text-decoration: underline;
    }
  `,
}
