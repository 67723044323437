import { AnyAction } from 'redux'

import { SET_HIGHEST_DISCOUNT, SET_TAX_AMOUNT } from 'root-redux/actions/common'

import { PaymentMethod, TCardPaymentSystem } from 'modules/purchase/constants'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchase/types'

import { IDynamicDiscount } from 'models/payment.model'
import { ISubscription, IUpgradeSubscription } from 'models/subscriptions.model'

import { PlanAddition } from 'root-constants'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG,
  SET_BILLING_DETAILS,
  SET_CREATED_SUBSCRIPTION_ID,
  SET_DYNAMIC_DISCOUNT,
  SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  SET_IS_SUBSCRIPTION_UPGRADED,
  SET_PAYMENT_CLIENT_SECRET,
  SET_PAYMENT_METHOD,
  SET_PLAN_ADDITIONS,
  SET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_SUBSCRIPTION,
  SET_TRIAL_PERIOD_DAYS,
  UPGRADE_SUBSCRIPTION,
} from '../actions/common'

export interface IBillingDetails {
  name: string
  address: string
  city: string
  postalCode: string
  state: string
  taxId: string
}

export interface IPaymentState {
  upgradeSubscription?: IUpgradeSubscription | null
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  createdSubscriptionId: string
  planAdditions: PlanAddition[]
  isSubscriptionUpgraded?: boolean
  isFirstPaymentRetryPassed: boolean
  taxAmount: number
  highestDiscountPercentage: number
  paymentSystem?: TCardPaymentSystem
  paymentMethod: PaymentMethod
  primerClientSessionToken: string
  backupPrimerSubscriptionConfig: IPrimerSubscriptionBackupConfig | null
  dynamicDiscount?: IDynamicDiscount | null
  billingDetails?: IBillingDetails
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  createdSubscriptionId: '',
  planAdditions: [],
  taxAmount: 0,
  highestDiscountPercentage: 0,
  primerClientSessionToken: '',
  paymentMethod: PaymentMethod.CREDIT_CARD,
  backupPrimerSubscriptionConfig: null,
  isFirstPaymentRetryPassed: false,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_CREATED_SUBSCRIPTION_ID: {
      return {
        ...state,
        createdSubscriptionId: payload,
      }
    }
    case UPGRADE_SUBSCRIPTION: {
      return {
        ...state,
        upgradeSubscription: payload,
      }
    }
    case SET_PLAN_ADDITIONS: {
      return {
        ...state,
        planAdditions: payload,
      }
    }
    case SET_IS_SUBSCRIPTION_UPGRADED: {
      return {
        ...state,
        isSubscriptionUpgraded: payload,
      }
    }
    case SET_TAX_AMOUNT: {
      return {
        ...state,
        taxAmount: payload,
      }
    }
    case SET_HIGHEST_DISCOUNT: {
      return {
        ...state,
        highestDiscountPercentage: payload,
      }
    }
    case SET_PRIMER_CLIENT_SESSION_TOKEN: {
      return {
        ...state,
        primerClientSessionToken: payload,
      }
    }
    case SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG: {
      return {
        ...state,
        backupPrimerSubscriptionConfig: payload,
      }
    }
    case SET_IS_FIRST_PAYMENT_RETRY_PASSED: {
      return {
        ...state,
        isFirstPaymentRetryPassed: payload,
      }
    }
    case SET_DYNAMIC_DISCOUNT: {
      return {
        ...state,
        dynamicDiscount: payload,
      }
    }
    case SET_BILLING_DETAILS: {
      return {
        ...state,
        billingDetails: payload,
      }
    }
    default:
      return state
  }
}
