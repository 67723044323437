import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeaturedBlockV2 = {
  Container: styled.div``,
  Title: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  Disclaimer: styled.p`
    padding: 30px 0 40px;
    font-size: 13px;
    line-height: 17px;
    color: ${Color.LIGHT_GREY};
    text-align: center;

    a {
      color: ${Color.LIGHT_GREY};
      text-decoration: underline;
    }
  `,
}
