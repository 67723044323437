import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { OptionType } from 'components/Option'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { Gender } from 'root-constants'

import { StyledBannerWithButton as S } from './BannerWithButton.styles'

type TProps = {
  isAnswersDisabled: boolean
  optionProps: {
    name: string
    type: OptionType
    onChange: (value: string) => void
  }
  elemForComparisonRef: React.RefObject<HTMLDivElement>
}

export const BannerWithButton: FC<TProps> = ({
  isAnswersDisabled,
  elemForComparisonRef,
  optionProps,
}) => {
  const isVisable = useTargetHiddenOnScroll(elemForComparisonRef)

  return (
    <S.Banner $isVisable={isVisable}>
      <S.Container>
        <S.Title>
          <Trans i18nKey="onboarding.personalHistory.bannerTitle" />
        </S.Title>

        <S.ButtonContainer>
          <S.StyledOption
            {...optionProps}
            withoutMargin
            disabled={isAnswersDisabled}
            value={Gender.FEMALE}
          >
            <S.Action>
              <Trans i18nKey="onboarding.gender.female" />
            </S.Action>
          </S.StyledOption>
          <S.StyledOption
            {...optionProps}
            withoutMargin
            disabled={isAnswersDisabled}
            value={Gender.MALE}
          >
            <S.Action>
              <Trans i18nKey="onboarding.gender.male" />
            </S.Action>
          </S.StyledOption>
        </S.ButtonContainer>
      </S.Container>
    </S.Banner>
  )
}
