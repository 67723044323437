import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

export const StyledAgeBelly = {
  Form: styled.form<{ $isJapanese: boolean; $isAdult: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  InputContainer: styled.div`
    margin-top: 30px;
  `,
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
