import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import harvardGazette from 'assets/images/sprite/harvard-gazette.svg'
import quotesSvg from 'assets/images/sprite/quotes.svg'

import { PageId } from 'page-constants'

import { StyledDoctor as S } from './Doctor.styles'
import { QUESTION } from './constants'

export const Doctor: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const ageRange = answers?.[PageId.WELCOME_ADULT]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <S.Container isLarge>
          <SvgImage svg={harvardGazette} width={69} />
          <S.Title>
            <Trans
              i18nKey="onboarding.doctor.title"
              values={{
                context: ageRange,
              }}
            />
          </S.Title>
          <S.Subtitle>
            <SvgImage svg={quotesSvg} width={21} />
            <br />
            <Trans
              i18nKey="onboarding.doctor.text"
              values={{
                context: ageRange,
              }}
            />
          </S.Subtitle>
        </S.Container>
      </S.ContainerBeforePinnedButton>

      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </>
  )
}
