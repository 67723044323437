import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AgeWithScroll } from 'pages/questions/AgeWithScroll'
import { DEFAULT_AGE } from 'pages/questions/AgeWithScroll/constants'

import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { PageDescription } from 'components/PageDescription'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectUserAge,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  ADULT_COHORT,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  INTEGER_NUMBER_REGEX,
  MAX_AGE,
  MIN_AGE,
} from 'root-constants'

import { StyledAge as S } from './Age.styles'
import { QUESTION } from './constants'

export const Age: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [age, setAge] = useState({
    value: '',
    isValid: false,
  })

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort
  const userAge = useSelector(selectUserAge)

  const hasRulerTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_455)

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  useEffect(() => {
    if (hasRulerTest) {
      setAge((prevState) => ({
        ...prevState,
        value: (userAge || DEFAULT_AGE).toString(),
      }))
    }
  }, [hasRulerTest, userAge])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || INTEGER_NUMBER_REGEX.test(value)) {
      setAge({
        value,
        isValid: validity.valid,
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    (event) => {
      event.preventDefault()
      handleContinue(age.value)
      eventLogger.logSelectedAge(age.value)
    },
    [age.value, handleContinue],
  )

  return (
    <form onSubmit={handleNextClick}>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle
          marginBottom={10}
          isAgeFlow={isAgeFlow}
        >{t`onboarding.age.question`}</PageTitle>
        <PageDescription>
          {t(
            isAgeFlow
              ? 'onboarding.age.descriptionAgeFlow'
              : 'onboarding.age.description',
          )}
        </PageDescription>
        {hasRulerTest ? (
          <AgeWithScroll onChange={handleChange} />
        ) : (
          <S.InputContainer>
            <Input
              data-testid="age-input"
              type="number"
              isAgeFlow={isAgeFlow}
              min={MIN_AGE}
              max={MAX_AGE}
              pattern="\d*"
              value={age.value}
              onChange={handleChange}
              allowFloatNumbers={false}
              isContentCentered
            />
          </S.InputContainer>
        )}
      </S.Container>

      <NavigationButton
        type="submit"
        isAgeFlow={isAgeFlow}
        disabled={!age.isValid}
      >
        {t`actions.continue`}
      </NavigationButton>
    </form>
  )
}
