import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledHappyWomen = {
  Container: styled(Container)`
    &::before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          180deg,
          rgba(242, 249, 255, 0) 61.7%,
          #d5ebff 72.96%
        ),
        radial-gradient(
          114.31% 54.07% at 44.53% 57.76%,
          #f3f9ff 0%,
          #e0f0ff 50.72%,
          #a4ceff 98.16%
        );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      min-height: 210px;
      margin: 32px auto 24px;
    }
  `,
  PageTitle: styled(PageTitle)`
    text-align: center;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    text-align: center;
    color: ${Color.GRAY_80};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: 0.5px solid #a7cdea;
    background: #d5ebff;
  `,
}
