import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

type TSvgImageProps = {
  $top?: number
  $left?: number
  $isAgeFlow: boolean
}

type TOptionContentProps = {
  $isChecked: boolean
  $isAgeFlow: boolean
  $top?: string
  $img?: string
  $right?: string
  $height?: string
  $width?: string
}
export const StyledImproveAreas = {
  TitleContainer: styled.div`
    padding: 0 5px;
  `,
  Row: styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  `,
  Image: styled.img`
    max-width: 188px;
  `,
  Col: styled.div`
    margin-left: 8px;
    flex-shrink: 0;
  `,
  OptionContent: styled(AnswerButton)<TOptionContentProps>`
    position: relative;
    min-width: 132px;
    padding: 0;
    margin-bottom: 12px;
    border-radius: 16px;
    background-color: ${Color.LIGHT};
    border: none;
    box-shadow: none;

    &::after {
      right: 12px;
    }

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        background-color: ${Color.WHITE};
        border: 1px solid ${Color.PRIMARY_LIGHT};
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
      `}

    svg {
      stroke: ${({ $isAgeFlow }) =>
        `${$isAgeFlow ? Color.PRIMARY_LIGHT : Color.LIGHT}`};
    }

    ${({ $isChecked, $top, $img, $right, $height, $width }) =>
      $isChecked &&
      css`
        background-color: ${Color.PRIMARY};
        color: ${Color.WHITE};

        svg {
          stroke: ${Color.PRIMARY};
          --fill-color: ${Color.PRIMARY};
          --stroke-color: ${Color.WHITE};
        }

        &::before {
          content: '';
          position: absolute;
          transform: translateX(-200%);
          background-repeat: no-repeat;
          top: ${$top};
          right: ${$right};
          height: ${$height};
          width: ${$width};
          background-image: ${$img};
        }
      `}
  `,
  SvgImage: styled(SvgImage)<TSvgImageProps>`
    position: absolute;
    transform: translateX(-100%);
    color: ${({ $isAgeFlow }) =>
      `${$isAgeFlow ? Color.PRIMARY_LIGHT : '#e3e4e8'}`};
    top: ${({ $top }) => `${$top}px`};
    left: ${({ $left }) => `${$left}px`};
    stroke: ${({ $isAgeFlow }) =>
      `${$isAgeFlow ? Color.PRIMARY_LIGHT : '#e3e4e8'}`};
    --fill-color: none;
    --stroke-color: currentaColor;
  `,
  OptionText: styled.p`
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  `,
  ProgramDescription: styled.p<{
    $isAgeFlow: boolean
  }>`
    text-align: center;
    line-height: 20px;
    color: ${Color.DISABLED};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-size: 18px;
        line-height: 22px;
        color: ${Color.DARK};
      `}
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 0;
    z-index: 2;
    max-width: 285px;
    margin-top: 20px;
    padding-bottom: 30px;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 40%
    );
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
        padding: 0 16px;
      `}
  `,
}
