import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledDifferentDanceStyles as S } from './DifferentDanceStyles.styles'
import { QUESTION } from './constants'

export const DifferentDanceStyles: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <S.Root>
      <S.Container isLarge>
        <S.Title>
          <Trans i18nKey="onboarding.dancingStylesIntro.title" />
        </S.Title>
        <div>
          <S.Subtitle>
            <Trans i18nKey="onboarding.dancingStylesIntro.subtitle" />
          </S.Subtitle>
        </div>
      </S.Container>
      <S.NavigationButtonBelly type="button" onClick={handleNextClick}>
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Root>
  )
}
