import styled from 'styled-components'

export const StyledGoalWeightGraph = {
  Wrapper: styled.div`
    margin-bottom: 32px;
  `,
  GraphRef: styled.div`
    display: flex;
    min-height: 157px;
  `,
}
