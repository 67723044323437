import styled, { css, keyframes } from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { CartDisclaimer } from 'modules/purchase/components/CartDisclaimer'
import { FAQList } from 'modules/purchase/components/FAQList'
import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'

import cardBgSmall from 'assets/images/image-with-wallet-small.png'
import cardBg from 'assets/images/image-with-wallet.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const titleAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const animatedTitleStyles = css<{ $isVisible: boolean }>`
  & > div {
    overflow: hidden;
    visibility: hidden;

    ${({ $isVisible }) =>
      $isVisible &&
      css`
        visibility: visible;
      `}
    & > div {
      ${({ $isVisible }) =>
        $isVisible &&
        css`
          animation: ${titleAnimation} 0.5s ease-in-out;
        `}
    }
  }
`

export const StyledPurchaseWithoutIntro = {
  Wrapper: styled(Container)`
    h1 > strong {
      color: ${Color.PRIMARY};
    }
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    margin-bottom: 50px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimer)`
    margin-bottom: 50px;
  `,
  CartDisclaimer: styled(CartDisclaimer)`
    margin-bottom: 50px;
  `,
  FAQList: styled(FAQList)`
    p {
      color: ${Color.LIGHT_GREY};
      line-height: 18px;
      padding-right: 16px;
      margin-bottom: 40px;
    }
  `,
  PurchaseButton: styled(Button)`
    margin: 0 auto 24px !important;
  `,
  TimerSubheaderWithTimer: styled(SubheaderWithTimer)<{
    $isBellyFlow?: boolean
    $hasSmallText?: boolean
    $isMenopauseFlow: boolean
  }>`
    margin: 18px auto 50px;
    padding: 20px 16px;

    ${({ $isMenopauseFlow }) =>
      $isMenopauseFlow &&
      css`
        background-color: #e74c3c;
        color: ${Color.WHITE};
      `}
    & span:first-child {
      display: flex;
    }

    button {
      width: 94px;
      height: 40px;
      font-size: ${({ $isBellyFlow }) => ($isBellyFlow ? `15px` : '16px')};
      font-weight: 700;
      line-height: 16px;
      padding: 5px 10px;

      ${({ $hasSmallText }) =>
        $hasSmallText &&
        css`
          font-size: 13px;
        `}
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    strong {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
    }
  `,
  Content: styled.div<{ $isCheckoutShown: boolean }>`
    padding-bottom: 40px;
    background: linear-gradient(
        179deg,
        #e8f2fb 20.92%,
        rgba(197, 223, 255, 0.5) 23.38%,
        rgba(197, 223, 255, 0) 25.54%
      ),
      linear-gradient(
        180deg,
        rgba(236, 245, 255, 0) 88.29%,
        #edf6ff 91.61%,
        #b3d4ff 100%
      ),
      linear-gradient(4deg, #c5dfff 19.34%, rgba(197, 223, 255, 0) 22.68%),
      linear-gradient(0deg, rgba(228, 241, 255, 0) 48.88%, #ecf5ff 64.02%),
      linear-gradient(
        178deg,
        rgba(255, 255, 255, 0.3) 47.79%,
        rgba(255, 255, 255, 0) 56.03%
      ),
      linear-gradient(
        187deg,
        rgba(161, 160, 255, 0.18) 53.51%,
        rgba(255, 219, 245, 0.36) 67.34%,
        rgba(224, 239, 255, 0) 97.55%
      ),
      radial-gradient(
        120.74% 88.55% at 61.65% 48.06%,
        #f5f9fe 0%,
        #e0f0ff 39.74%,
        #a4ceff 100%
      );

    ${({ $isCheckoutShown }) =>
      $isCheckoutShown &&
      css`
        background: ${Color.WHITE};
      `}
  `,
  RefundCard: styled.div`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 30px;
    background: linear-gradient(128deg, #a6f1df 4.73%, #50b5c3 79.07%);
    width: 100%;
    min-height: 152px;
    padding: 64px 12px 16px;
    margin-top: 24px;
    margin-bottom: 24px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: -28px;
      width: 100%;
      height: 180px;
      background-image: url(${cardBg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  RefundCardSmall: styled.div<{ $marginBottom: number }>`
    position: relative;
    color: ${Color.WHITE};
    border-radius: 30px;
    background: linear-gradient(124deg, #a6f1df 11.31%, #50b5c3 77.01%);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    min-height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: ${({ $marginBottom }) => `${$marginBottom}px`};

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${cardBgSmall});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  HowItWorks: styled(HowItWorks)`
    margin-bottom: 40px;
    padding-bottom: 24px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    li {
      border-radius: 30px;
    }

    button {
      max-width: 343px;
    }
  `,
}
