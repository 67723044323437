import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledTrialSubscriptionItemInfo = {
  PriceTitle: styled.span`
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.DARK};
    padding-bottom: 16px;
    margin-bottom: 10px;
    margin-top: 24px;
    border-bottom: 1px solid ${Color.LIGHT};
  `,
  PriceName: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: ${Color.DARK};
  `,
  SvgImage: styled(SvgImage)`
    height: 12px;
    width: 12px;
    padding-right: 2px;
  `,
  PurchasedLabel: styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.DARK};
    padding: 2px 8px 2px 6px;
    background: #eaf7ff;
    border-radius: 16px;
  `,
  TrialPricesContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  TrialDiscount: styled.div`
    padding: 4px 12px;
    background: ${Color.GREEN};
    border-radius: 11px;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    color: ${Color.WHITE};
    margin-bottom: 2px;
  `,
  AdditionalInfoBorder: styled.p`
    padding-bottom: 16px;
    border-top: 1px solid ${Color.PRIMARY};
  `,
  NewPrice: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
  `,
  TrialOldPrice: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: line-through;
    color: ${Color.DISABLED};
  `,
}
