import styled from 'styled-components'

export const StyledHealthCondition = {
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
  `,
}
