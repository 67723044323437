import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnswerWithCheckbox, answerWithCheckboxTheme } from 'storybook-ui'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Gender, MAIN_GOALS } from 'root-constants'

import { StyledMainGoalAgeFlow as S } from './MainGoalBelly.styles'
import {
  OPTION_VALUES_BELLY,
  OPTION_VALUES_LEGS,
  QUESTION,
  SELECT_ALL,
} from './constants'

export const MainGoalBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()
  const { isLegsFlow } = useCohortInfo()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const options = useMemo(
    () => (isLegsFlow ? OPTION_VALUES_LEGS : OPTION_VALUES_BELLY),
    [isLegsFlow],
  )

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleSelectAll = useCallback(
    (_: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers(options.map(({ value }) => value))
      } else {
        setAnswers([])
      }
    },
    [options],
  )

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers,
    })

    dispatch(
      setAnswersAction({
        answers: [MAIN_GOALS.LOSE_WEIGHT],
        pageId: PageId.MAIN_GOAL,
      }),
    )

    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }, [answers, dispatch, nextPagePath])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge $isLegsFlow={isLegsFlow} $bgImageUrl={imageUrl}>
          <PageTitle marginBottom={24}>
            {t('onboarding.mainGoal.titleBelly')}
          </PageTitle>
          {options.map(({ value, title }) => (
            <AnswerWithCheckbox
              {...optionProps}
              theme={answerWithCheckboxTheme.DANCEBIT}
              maxWidth="218px"
              margin="0 0 16px 0"
              borderRadius="16px"
              iconSrc={checkIcon}
              key={value}
              value={value}
              checked={answers.includes(value)}
            >
              <Trans>{title}</Trans>
            </AnswerWithCheckbox>
          ))}

          <AnswerWithCheckbox
            {...optionProps}
            theme={answerWithCheckboxTheme.DANCEBIT}
            checkboxWidth="24px"
            maxWidth="218px"
            margin="0 0 16px 0"
            borderRadius="16px"
            iconSrc={checkIcon}
            key={SELECT_ALL.value}
            value={SELECT_ALL.value}
            checked={answers.length === options.length}
            onChange={handleSelectAll}
          >
            <Trans i18nKey={SELECT_ALL.title} />
          </AnswerWithCheckbox>
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </NavigationButtonBelly>
    </>
  )
}
