import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContactWithSupport } from 'components/ContactWithSupport'

import { selectLanguage } from 'root-redux/selects/common'

import { CURRENCY_SYMBOLS, STRIPE_IMAGE_PATH } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { CDN_FOLDER_LINK, PagesSource, TERMS_OF_USE_LINK } from 'root-constants'

import { StyledSecurityInfoV2 as S } from './SecurityInfoV2.styles'
import { PRICE_DESCRIPTION_KEYS } from './constants'

export const SecurityInfoV2: React.FC = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const productId = useSelector(selectProductId)
  const currency = useSelector(selectCurrency)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  const secureBadgeImg = useMemo(
    () => `${CDN_FOLDER_LINK}${STRIPE_IMAGE_PATH}_${language}.png`,
    [language],
  )

  return (
    <S.Wrapper>
      <S.SecureBadge src={secureBadgeImg} alt="Secure badge" />
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textFirst`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textSecond`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleFourth`}</S.Title>
      <S.Text>
        {t(PRICE_DESCRIPTION_KEYS[productId], {
          trialPeriodDays,
          periodQuantity,
          price: fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
        })}
        <S.TermsLink
          href={TERMS_OF_USE_LINK}
          onClick={() => eventLogger.logTermsOfUseClicked(PagesSource.PAYWALL)}
        >
          {t('commonComponents.termsOfUse')}.
        </S.TermsLink>
      </S.Text>
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>

      <S.Text>
        <ContactWithSupport
          text={t('commonComponents.contactSupport')}
          source={PagesSource.PAYWALL}
        />
      </S.Text>
    </S.Wrapper>
  )
}
