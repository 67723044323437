/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import {
  GET_CART_LIST,
  GET_PAYMENT_CONFIG,
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { UPDATE_PRODUCT_CART } from 'root-redux/actions/user'
import { selectStripePaymentMethods } from 'root-redux/selects/common'
import { selectUserCart } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCancelPrimerDiscount } from 'hooks/useCancelPrimerDiscount'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createUpsellProductId } from 'helpers/createUpsellProductId'
import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { LinkPaymentButton } from 'modules/purchase/components/LinkPaymentButton'
import { PaymentElementButton } from 'modules/purchase/components/PaymentElementButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  Currency,
  PaymentMethod,
  WALLET_PAYMENT_METHODS,
} from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { useGetSubscription } from 'modules/purchase/hooks/useGetSubscription'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { SECRET_GIFT_COST } from 'modules/purchase/pages/PurchaseWithoutIntro/constant'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  GET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_PAYMENT_FORM_IS_LOADING,
  paymentElementPurchaseAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectPrimerClientSessionToken,
  selectSubscriptionLookupKey,
  selectSubscriptionTrialLookupKey,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ABC_TEST_VARIANTS,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  PlatformOS,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
} from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import {
  EU_PM,
  PAYMENT_METHODS_IMAGES,
  PAYMENT_METHODS_TO_SHOW,
  USA_PM,
  WALLET_IMAGES,
  WW_PM,
} from './constants'

type TCheckoutExtended = {
  setIsCheckoutShown: (value: boolean) => void
  isCancelOfferPage?: boolean
  isCheckoutShown: boolean
}

const USA_COUNTRY_CODE = 'US'
const MINIMAL_AVAILABLE_PAYMENT_METHODS = 1

export const CheckoutSeparateMethods: React.FC<TCheckoutExtended> = ({
  isCheckoutShown,
  setIsCheckoutShown,
  isCancelOfferPage = false,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  const allowedPaymentMethods = useSelector(selectStripePaymentMethods)
  const userCart = useSelector(selectUserCart)
  const priceId = useSelector(selectSubscriptionLookupKey)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)

  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)
  const isPPHidden = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1048)
  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)
  const isCancelOfferTestActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const activeTestVariant = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_924,
    ABC_TEST_VARIANTS.VARIANT_A,
  )

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)
  const hasPaypalButton = useHasPayPalButton()

  const { isPersonalDataAllowed, isEUUser } = useCookieConsentAnswer()

  const paymentFormRef = useRef<HTMLDivElement>(null)

  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[] | PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [selectedPaymentMethodTab, setSelectedPaymentMethodTab] = useState<
    PaymentMethod
  >(PaymentMethod.CREDIT_CARD)

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const {
    isSaleFlow,
    isTrialCohort,
    isSevenDayPlanCohort,
    isNewYearFlow,
    isGiftFlow,
    isNewClearFlow,
  } = useCohortInfo()

  const {
    uuid,
    subscriptions,
    periodQuantity,
    fullPrice,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
    discountAmount,
    trialPeriodDays,
    hasCancelOffer,
    screenName,
    screenId,
    selectedSubscription,
    fetchingActionsList,
    threeDSecureIframeUrl,
    countryCode,
    fullPriceTax,
    oldPriceBeforeCustomPercentDiscount,
    taxAmount,
    dynamicDiscount,
  } = usePurchaseStore()

  const showGiftInfo = isGiftFlow && periodQuantity === 3

  const [isUpdated, setIsUpdated] = useState(false)

  const isTrial = useMemo(() => {
    if (isNewYearFlow) {
      return !!selectedSubscription?.trialPrices.fullPrice
    }

    return isTrialCohort
  }, [isNewYearFlow, isTrialCohort, selectedSubscription])

  const isCartReady = useMemo(() => {
    if (hasObUpsellTest) {
      return (
        !fetchingActionsList.includes(GET_CART_LIST) &&
        !fetchingActionsList.includes(UPDATE_PRODUCT_CART)
      )
    }
    return true
  }, [fetchingActionsList, hasObUpsellTest])

  const additionalPaymentMethods = useMemo(() => {
    if (isEUUser) return EU_PM

    if (countryCode === USA_COUNTRY_CODE) return USA_PM

    return WW_PM
  }, [countryCode, isEUUser])

  const hasAnyRequiredMethod = useMemo(
    () =>
      WALLET_PAYMENT_METHODS.some((method) =>
        allowedPaymentMethods.includes(method),
      ),
    [allowedPaymentMethods],
  )

  const isCardVisible = useMemo(
    () =>
      isCheckoutShown &&
      isScrolled &&
      selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD),
    [isCheckoutShown, isScrolled, selectedPaymentMethods],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING) &&
      !fetchingActionsList.includes(GET_PRIMER_CLIENT_SESSION_TOKEN) &&
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(GET_PAYMENT_CONFIG),
    [fetchingActionsList],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const productId = useMemo(
    () =>
      createIntroOfferProductId({
        priceId,
        trialPriceId,
        trialPeriodQuantity: trialPeriodDays,
      }),
    [priceId, trialPriceId, trialPeriodDays],
  )

  const upsellProducts = useMemo(
    () =>
      userCart.map(({ mainPrices, trialPrices }) =>
        createUpsellProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
          trialPrice: trialPrices?.fullPrice,
          periodDays: trialPrices?.durationDays,
        }),
      ),
    [userCart],
  )

  const paymentMethodsTabs = useMemo(() => {
    if (
      activeTestVariant === ABC_TEST_VARIANTS.VARIANT_C &&
      !hasAnyRequiredMethod
    ) {
      return PAYMENT_METHODS_TO_SHOW[ABC_TEST_VARIANTS.VARIANT_A]
    }

    return PAYMENT_METHODS_TO_SHOW[activeTestVariant]
  }, [activeTestVariant, hasAnyRequiredMethod])

  const isSevenDayPlan = useMemo(
    () =>
      isSevenDayPlanCohort &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [isSevenDayPlanCohort, periodQuantity],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value && currency !== Currency.MXN
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [currency, countryCode, hasVatInfo, taxAmount],
  )

  const subscription = useGetSubscription({
    isSevenDayPlan,
    isCancelOfferPage,
    hasExcludedVat,
    getCurrentPrice,
    isTrial,
  })

  const totalDiscount = useMemo(() => {
    const discountValue =
      isSaleFlow || isNewYearFlow
        ? subscription.discountAmount
        : getCurrentPrice(
            isTrial
              ? discountAmount
              : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
          )

    return `-${t('checkoutExtended.price', {
      price: formatJaPriceWithCommas(discountValue, currency),
      currency: currencyLabel,
    })}`
  }, [
    isSaleFlow,
    isNewYearFlow,
    subscription.discountAmount,
    getCurrentPrice,
    isTrial,
    discountAmount,
    oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
    t,
    currency,
    currencyLabel,
  ])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  const addAvailablePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (availablePaymentMethods.includes(paymentMethod)) return

      setAvailablePaymentMethods((currentValue) => [
        ...currentValue,
        paymentMethod,
      ])
    },
    [availablePaymentMethods],
  )

  useLayoutEffect(() => {
    if (!isPPHidden && hasPaypalButton) {
      addAvailablePaymentMethod(PaymentMethod.PAYPAL)
    }
  }, [hasPaypalButton, addAvailablePaymentMethod, isPPHidden])

  useEffect(() => {
    if (
      hasAnyRequiredMethod &&
      activeTestVariant === ABC_TEST_VARIANTS.VARIANT_C
    ) {
      addAvailablePaymentMethod(PaymentMethod.WALLETS)
      return
    }

    if (
      additionalPaymentMethods.length &&
      activeTestVariant !== ABC_TEST_VARIANTS.VARIANT_A
    ) {
      additionalPaymentMethods.forEach((pm) => {
        if (allowedPaymentMethods.includes(pm)) {
          addAvailablePaymentMethod(pm as PaymentMethod)
        }
      })
    }
  }, [
    addAvailablePaymentMethod,
    additionalPaymentMethods,
    activeTestVariant,
    allowedPaymentMethods,
    hasAnyRequiredMethod,
  ])

  const { cancelDiscount } = useCancelPrimerDiscount()

  const handlePaymentMethodTabClick = useCallback(
    (paymentMethod: PaymentMethod) => {
      setIsScrolled(false)
      setSelectedPaymentMethodTab(paymentMethod)

      if (paymentMethod === PaymentMethod.WALLETS) {
        const paymentMethodsToShow = allowedPaymentMethods.filter(
          (pm) => pm !== PaymentMethod.CREDIT_CARD,
        )

        if (isPPHidden) {
          setSelectedPaymentMethods([...paymentMethodsToShow])
        } else {
          setSelectedPaymentMethods([
            ...paymentMethodsToShow,
            PaymentMethod.PAYPAL,
          ])
        }

        return
      }

      setSelectedPaymentMethods([paymentMethod])
    },
    [allowedPaymentMethods, isPPHidden],
  )

  const addPrimaryPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      addAvailablePaymentMethod(paymentMethod)
      handlePaymentMethodTabClick(paymentMethod)
    },
    [addAvailablePaymentMethod, handlePaymentMethodTabClick],
  )

  useEffect(() => {
    if (!isCheckoutReady || !isCheckoutShown || !primerClientSessionToken) {
      return
    }

    eventLogger.logPaymentMethodSelected(selectedPaymentMethodTab)
  }, [
    isCheckoutReady,
    isCheckoutShown,
    selectedPaymentMethodTab,
    primerClientSessionToken,
  ])

  useEffect(() => {
    if (
      productId &&
      isCheckoutShown &&
      isCheckoutReady &&
      selectedSubscription &&
      isCartReady &&
      !isLogged
    ) {
      setIsLogged(true)
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        screenId,
        accountId: stripeAccountId,
        accountName: stripeAccountName,
        upsellProducts,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        selectedSubscription,
        isPersonalDataAllowed,
      })
    }
  }, [
    isCheckoutReady,
    isLogged,
    isCartReady,
    upsellProducts,
    isPersonalDataAllowed,
    uuid,
    subscriptions,
    isCheckoutShown,
    productId,
    screenId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  ])

  useEffect(() => {
    const { current: elem } = paymentFormRef
    setIsScrolled(false)

    if (
      elem &&
      isCheckoutReady &&
      selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD)
    ) {
      elem.scrollIntoView({
        behavior: 'smooth',
      })
      setIsScrolled(true)
    }
  }, [isCheckoutReady, selectedPaymentMethods])

  const handlePaymentElementClick = async () => {
    if (!elements || !stripe) return

    const { error } = await elements.submit()

    if (error) {
      console.error(error)
    }

    dispatch(
      paymentElementPurchaseAction({
        stripe,
        elements,
      }),
    )
  }

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: isCancelOfferTestActive
        ? PageId.CANCEL_OFFER_QUESTION_V2
        : PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    isCancelOfferTestActive,
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  const getPaymentMethodTabImageCover = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (paymentMethod !== PaymentMethod.WALLETS) {
        return PAYMENT_METHODS_IMAGES[paymentMethod]
      }

      if (isEUUser) return WALLET_IMAGES.EU

      if (countryCode === USA_COUNTRY_CODE) return WALLET_IMAGES.US

      return WALLET_IMAGES.WW
    },
    [countryCode, isEUUser],
  )

  const getCalculatedTotalPrice = () => {
    const subscriptionTotalPrice =
      isSevenDayPlan || !isTrial
        ? fullPrice
        : selectedSubscription?.trialPrices?.fullPrice

    const cartProductsTotalPrice = userCart.reduce(
      (acc, product) => acc + (product.mainPrices?.fullPrice ?? 0),
      0,
    )

    if (!subscriptionTotalPrice) return 0

    return Number((subscriptionTotalPrice + cartProductsTotalPrice).toFixed(2))
  }

  const getPricePerDay = () => {
    if (!selectedSubscription) return 0

    const total = getCalculatedTotalPrice()

    return (total / selectedSubscription.trialPrices.durationDays).toFixed(2)
  }

  const giftPrice = SECRET_GIFT_COST[currency] || SECRET_GIFT_COST[Currency.USD]

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  if (!selectedSubscription || !isCartReady) return null

  return (
    <S.Root $isJapanese={isJapaneseFlow}>
      {!isCheckoutReady && <Spinner />}
      <S.ContentContainer
        $isVisible={isCheckoutReady}
        $hasBottomPadding={
          isAndroid && selectedPaymentMethodTab === PaymentMethod.CREDIT_CARD
        }
      >
        <S.PaymentMethodsTitle>
          {availablePaymentMethods.length > MINIMAL_AVAILABLE_PAYMENT_METHODS
            ? t('checkoutExtended.selectMethods')
            : t('checkoutExtended.checkoutTitle')}
        </S.PaymentMethodsTitle>
        <S.CloseButton onClick={handleCloseCheckout} />
        {availablePaymentMethods.length > MINIMAL_AVAILABLE_PAYMENT_METHODS && (
          <S.PaymentMethodsWrapper $isJapaneseFlow={isJapaneseFlow}>
            {paymentMethodsTabs?.map((paymentMethod: PaymentMethod) => {
              if (!availablePaymentMethods.includes(paymentMethod)) {
                return null
              }

              if (paymentMethod === PaymentMethod.PAYPAL) {
                return (
                  <S.PaymentMethod
                    key={paymentMethod}
                    $isSelected={paymentMethod === selectedPaymentMethodTab}
                    onClick={() => {
                      handlePaymentMethodTabClick(paymentMethod)
                      cancelDiscount()
                    }}
                  >
                    <img
                      src={
                        getPaymentMethodTabImageCover(paymentMethod)[
                          paymentMethod === selectedPaymentMethodTab
                            ? 'active'
                            : 'inactive'
                        ]
                      }
                      width={
                        getPaymentMethodTabImageCover(paymentMethod).imageWidth
                      }
                      alt={paymentMethod}
                    />
                  </S.PaymentMethod>
                )
              }

              return (
                <S.PaymentMethod
                  key={paymentMethod}
                  $isSelected={paymentMethod === selectedPaymentMethodTab}
                  onClick={() => handlePaymentMethodTabClick(paymentMethod)}
                >
                  {paymentMethod === PaymentMethod.CREDIT_CARD && (
                    <S.PaymentMethodText $isJapaneseFlow={isJapaneseFlow}>
                      {t('checkoutExtended.creditCard')}
                    </S.PaymentMethodText>
                  )}

                  {paymentMethod === PaymentMethod.WALLETS && (
                    <S.PaymentMethodText $isJapaneseFlow={isJapaneseFlow}>
                      {t('checkoutExtended.wallets')}
                    </S.PaymentMethodText>
                  )}

                  <img
                    src={
                      getPaymentMethodTabImageCover(paymentMethod)[
                        paymentMethod === selectedPaymentMethodTab
                          ? 'active'
                          : 'inactive'
                      ]
                    }
                    width={
                      getPaymentMethodTabImageCover(paymentMethod).imageWidth
                    }
                    alt={paymentMethod}
                  />
                </S.PaymentMethod>
              )
            })}
          </S.PaymentMethodsWrapper>
        )}

        <S.PriceDescription $isJapaneseFlow={isJapaneseFlow}>
          <S.PriceInfo>
            <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
              <Trans i18nKey="purchaseCancelOffer.checkout.personalizedPlan" />
            </S.PriceBlockText>
            <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
              <Trans
                i18nKey="checkoutExtended.price"
                values={{
                  price: formatJaPriceWithCommas(
                    subscription.totalAmount,
                    currency,
                  ),
                  currency: currencyLabel,
                }}
              />
            </S.PriceBlockText>
          </S.PriceInfo>

          {!isSevenDayPlan && isTrial && (
            <S.PriceInfo>
              <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                {dynamicDiscount?.checkout && isSaleFlow ? (
                  <Trans
                    i18nKey="checkoutExtended.discount"
                    values={{
                      percentage: subscription.percentage,
                      checkout: dynamicDiscount.checkout,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="checkoutExtended.introductoryDiscount"
                    values={{
                      discountPercentage: subscription.percentage,
                    }}
                  />
                )}
              </S.PriceBlockText>
              <S.TotalDiscount>{totalDiscount}</S.TotalDiscount>
            </S.PriceInfo>
          )}

          {hasObUpsellTest &&
            userCart.map((product) => (
              <S.PriceInfo key={product.planId}>
                <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                  {t(`purchaseWithCart.cartProducts.${product.product}.title`)}
                </S.PriceBlockText>
                <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                  <Trans
                    i18nKey="checkoutExtended.price"
                    values={{
                      price: formatJaPriceWithCommas(
                        product.mainPrices.fullPrice,
                        currency,
                      ),
                      currency: currencyLabel,
                    }}
                  />
                </S.PriceBlockText>
              </S.PriceInfo>
            ))}

          {hasExcludedVat && (
            <S.PriceInfo>
              <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                {t(`purchase1.valueAddedTax`, { context: countryCode })}
              </S.PriceBlockText>
              <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                <Trans
                  i18nKey="checkoutExtended.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      isSevenDayPlan || !isTrial
                        ? fullPriceTax
                        : selectedSubscription?.trialPrices.fullPriceTax,
                      currency,
                    ),
                    currency: currencyLabel,
                  }}
                />
              </S.PriceBlockText>
            </S.PriceInfo>
          )}

          {showGiftInfo && (
            <S.PriceInfo>
              <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                {t('purchaseWithoutIntro.secretGift')}
              </S.PriceBlockText>

              <S.PriceBlockText $isJapaneseFlow={isJapaneseFlow}>
                <S.OldPrice>
                  {formatJaPriceWithCommas(giftPrice, currency)} {currencyLabel}
                </S.OldPrice>
                0 {currencyLabel}
              </S.PriceBlockText>
            </S.PriceInfo>
          )}
        </S.PriceDescription>

        {!isNewClearFlow && (
          <S.DailyPayment $isJapaneseFlow={isJapaneseFlow}>
            <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
            <S.DailyAmount $isJapaneseFlow={isJapaneseFlow}>
              <Trans
                i18nKey="checkoutExtended.price"
                values={{
                  price: formatJaPriceWithCommas(
                    hasObUpsellTest
                      ? getPricePerDay()
                      : getCurrentPrice(
                          isSevenDayPlan || !isTrial
                            ? selectedSubscription.mainPrices.daily
                            : selectedSubscription.trialPrices.daily,
                        ),
                    currency,
                  ),
                  currency: currencyLabel,
                }}
              />
            </S.DailyAmount>
          </S.DailyPayment>
        )}

        <S.TotalPayment>
          <S.TotalDescription>
            <S.TotalText>
              {t(
                isNewClearFlow
                  ? 'checkoutExtended.totalToday'
                  : 'checkoutExtended.total',
              )}
            </S.TotalText>
            <S.TotalAmount>
              <Trans
                i18nKey="checkoutExtended.totalPrice"
                values={{
                  totalAmount: formatJaPriceWithCommas(
                    getCalculatedTotalPrice(),
                    currency,
                  ),
                  currency: currencyLabel,
                  periodQuantity,
                  context:
                    isSevenDayPlan || !isTrial
                      ? periodQuantity
                      : selectedSubscription.trialPrices.durationDays,
                }}
              />
            </S.TotalAmount>
          </S.TotalDescription>
          {!isSevenDayPlan && isTrial && !isNewClearFlow && (
            <>
              {showGiftInfo ? (
                <S.SaveText>
                  <Trans
                    i18nKey="checkoutExtended.savedAmountWithoutDiscount"
                    values={{
                      savedAmount: formatJaPriceWithCommas(
                        (
                          Number(subscription.discountAmount) + giftPrice
                        ).toFixed(2),
                        currency,
                      ),
                      currency: currencyLabel,
                    }}
                  />
                </S.SaveText>
              ) : (
                <S.SaveText>
                  <Trans
                    i18nKey="checkoutExtended.savedAmount"
                    values={{
                      savedAmount: formatJaPriceWithCommas(
                        subscription.discountAmount,
                        currency,
                      ),
                      currency: currencyLabel,
                      discountPercentage: subscription.percentage,
                    }}
                  />
                </S.SaveText>
              )}
            </>
          )}
        </S.TotalPayment>

        <S.PaymentFormWrapper ref={paymentFormRef}>
          <S.PaymentMethodContainer
            $isVisible={
              (selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD) &&
                isPrimerActive) ||
              selectedPaymentMethods.includes(PaymentMethod.PAYPAL)
            }
          >
            {isCheckoutShown && (
              <PrimerPaymentForm
                key={selectedSubscription?.id}
                showPayPalButton={selectedPaymentMethods.includes(
                  PaymentMethod.PAYPAL,
                )}
                showCardForm={selectedPaymentMethods.includes(
                  PaymentMethod.CREDIT_CARD,
                )}
              />
            )}
          </S.PaymentMethodContainer>

          {!isPrimerActive && (
            <S.PaymentMethodContainer
              $isVisible={selectedPaymentMethods.includes(
                PaymentMethod.CREDIT_CARD,
              )}
            >
              <CreditCardFormCancelOffer isCheckoutVisible={isCardVisible} />
            </S.PaymentMethodContainer>
          )}

          {selectedPaymentMethods.map((paymentMethod) => (
            <S.PaymentMethodContainer
              key={paymentMethod}
              $isVisible={additionalPaymentMethods.includes(paymentMethod)}
            >
              <PaymentElementButton
                paymentMethod={paymentMethod}
                setIsUpdated={setIsUpdated}
              />
            </S.PaymentMethodContainer>
          ))}

          <S.PaymentMethodContainer
            $isVisible={
              selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
              selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
            }
          >
            <StripeSeparatePaymentRequestButton
              isVisible={
                selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
                selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
              }
              setAlternativePaymentMethodCallback={addPrimaryPaymentMethod}
            />
          </S.PaymentMethodContainer>

          <S.PaymentMethodContainer
            $isVisible={selectedPaymentMethods.includes(PaymentMethod.LINK)}
          >
            <LinkPaymentButton
              isVisible={selectedPaymentMethods.includes(PaymentMethod.LINK)}
              setAlternativePaymentMethodCallback={addAvailablePaymentMethod}
            />
          </S.PaymentMethodContainer>
        </S.PaymentFormWrapper>

        {isUpdated && (
          <div
            style={{
              display: 'none',
            }}
          >
            <PaymentElement
              onReady={(element) => element.blur()}
              onChange={handlePaymentElementClick}
            />
          </div>
        )}

        <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
        <S.PaymentsSystemText>
          {t('purchase1.checkout.moneyBackGuarantee')}
        </S.PaymentsSystemText>
      </S.ContentContainer>

      {isCheckoutShown &&
        (isPrimerActive ? (
          <PaymentPrimerWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ) : (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ))}
    </S.Root>
  )
}
