import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

type TOptionData = {
  id: number
  value: string
  text: string
  additionalText: string
  imageUrl: string
  imageWidth: number
  imageHeight: number
  nextPageId: string
}

type TDynamicProps = {
  title?: string
  subtitle?: string
  buttonText?: string
  stickerText?: string
  imageUrls: string[] | null
  pageTexts: string[] | null
  personalization: Record<string, Record<string, string | number>>
  optionData: TOptionData[] | null
}

export const useDynamicOBConfig = (): TDynamicProps => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()

  return useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      title: dynamicOBConfig?.[language]?.title,
      subtitle: dynamicOBConfig?.[language]?.subtitle,
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      stickerText: dynamicOBConfig?.[language]?.stickerText,
      imageUrls: dynamicOBConfig?.[language]?.imageUrls ?? null,
      pageTexts: dynamicOBConfig?.[language]?.pageTexts ?? null,
      personalization: dynamicOBConfig?.[language]?.personalization ?? {},
      optionData: dynamicOBConfig?.[language]?.optionData ?? null,
    }
  }, [currentPageIndex, language, steps])
}
