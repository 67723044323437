import styled, { css } from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

export const StyledHelpedMillionsPeople = {
  Wrapper: styled.div`
    height: 100%;
    padding-top: 16px;
    background-color: #ecf2fa;
  `,
  CarouselContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-container {
      height: 385px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.2;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 0.7;
    }
  `,
  Review: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.img`
    max-height: 216px;
  `,
  ReviewText: styled.div<{ $isBigReview: boolean }>`
    width: 100%;
    position: absolute;
    bottom: ${({ $isBigReview }) => ($isBigReview ? '-140px' : '-120px')};
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 24px;
    background: ${Color.WHITE};
    box-shadow: 0px 5px 20px 0px rgba(166, 179, 194, 0.25);
  `,
  BeforeAfterBlock: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffa800;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
  `,
  Reviewer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Name: styled.span`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
    padding: 8px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Comment: styled.div<{ $isOpen: boolean; $isBigReview: boolean }>`
    max-width: 300px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    transition: all 0.3s ease-in-out;
    overflow: ${({ $isBigReview }) => ($isBigReview ? 'hidden' : 'visible')};

    ${({ $isOpen, $isBigReview }) =>
      $isOpen &&
      $isBigReview &&
      css`
        height: 160px;
      `}

    ${({ $isOpen, $isBigReview }) =>
      !$isOpen &&
      $isBigReview &&
      css`
        height: 48px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      `}
  `,
  ViewMore: styled.button`
    width: 100%;
    background: transparent;
    border: none;
    text-align: center;
    font-size: 13px;
    color: ${Color.PRIMARY};
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: #ecf2fa;
  `,
}
