import styled from 'styled-components'

import { animatedTitleStyles } from 'modules/purchase/pages/PurchaseNew/PurchaseNew.styles'

import { Color } from 'root-constants'

export const StyledReceivedPlan = {
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    border-radius: 30px;
    border: 1px solid #d3e6ff;
    background: ${Color.WHITE};
    box-shadow: 0 16px 40px 0 rgba(14, 45, 88, 0.12);
    margin-bottom: 32px;
  `,
  ProgramPlanTitle: styled.h2<{ $isVisible: boolean }>`
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    ${animatedTitleStyles};
  `,
  ListItem: styled.li`
    margin-bottom: 16px;
    display: flex;

    svg {
      min-width: 32px;
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    strong {
      font-weight: 700;
      color: ${Color.DARK};
    }
  `,
}
