import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { OuterLink } from 'components/OuterLink'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUserPaymentCurrency,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'

import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { UpsellWithSwitcherSubscriptionsBlock } from 'modules/purchase/components/UpsellWithSwitcherSubscriptionsBlock'
import { CURRENCY_SYMBOLS, PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'
import {
  selectPaymentMethod,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ButtonTapType,
  GROWTHBOOK_EXPERIMENT,
  ONBOARDING_GOAL_EVENT,
  PagesSource,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  TERMS_OF_USE_LINK,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsell as S } from './UpsellWithSwitcher.styles'

export const UpsellWithSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const accountId = useSelector(selectStripeAccountId)
  const accountName = useSelector(selectStripeAccountName)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const selectedSubscription = useSelector(selectSubscription)

  const isPaidUpsell = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_474)

  const {
    currency,
    currentMeasurementSystem,
    fetchingActionsList,
    fullPrice,
    subscriptions,
    trialPrice,
    trialPeriodDays,
    cohort,
  } = usePurchaseStore()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)
  const [isEventLogged, setIsEventLogged] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )

  const cohortToUse = parentCohort || cohort

  usePaymentElementStatus()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const goal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  const isAvailableToSendEvent = useMemo(
    () =>
      !isEventLogged &&
      isPricesStartedFetching &&
      arePricesReady &&
      selectedSubscription &&
      selectedSubscription.currency === paymentCurrency &&
      (paymentSystem === PaymentSystem.STRIPE
        ? accountId && accountName
        : true),
    [
      isEventLogged,
      isPricesStartedFetching,
      arePricesReady,
      selectedSubscription,
      paymentCurrency,
      paymentSystem,
      accountId,
      accountName,
    ],
  )

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const tags = useMemo(() => {
    const tagList = [SubscriptionTags.NO_TAX, SubscriptionTags.SWITCHER]

    if (isPaidUpsell && trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
      tagList.push(SubscriptionTags.PAID_UPSELL)
    }

    return tagList.join(',')
  }, [isPaidUpsell, trialPeriodDays])

  const bothUpsellsProductId = subscriptions.find(
    (subs) => subs.product === UpsellProduct.BOTH_UPSELLS_FREE,
  )
    ? UpsellProduct.BOTH_UPSELLS_FREE
    : UpsellProduct.BOTH_UPSELLS

  const slimmerBellyAndFullBody = subscriptions.find(
    (subscription) => subscription.product === bothUpsellsProductId,
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.UPSELL_SWITCHER))
    dispatch(setScreenIdAction(ScreenId.UPSELL_SWITCHER))

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
    dispatch(getPaymentConfigAction())

    setIsPricesStartedFetching(true)
  }, [dispatch, tags])

  useEffect(() => {
    if (!isPricesStartedFetching || !arePricesReady) return

    const [upsell] = subscriptions

    if (!subscriptions.length || paymentCurrency !== upsell?.currency) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    paymentCurrency,
  ])

  useEffect(() => {
    if (isAvailableToSendEvent) {
      eventLogger.logUpsellPurchaseShown({
        screenName: ScreenName.UPSELL_SWITCHER,
        screenId: ScreenId.UPSELL_SWITCHER,
        goal,
        accountId,
        accountName,
      })

      setIsEventLogged(true)
    }
  }, [
    accountId,
    accountName,
    cohortToUse,
    dispatch,
    goal,
    isAvailableToSendEvent,
    paymentMethod,
    paymentSystem,
  ])

  useEffect(() => {
    const defaultUpsell = subscriptions.find(
      (subscription) => subscription.product === UpsellProduct.SLIMMER_BELLY,
    )

    defaultUpsell && dispatch(setSelectedSubscriptionAction(defaultUpsell))
  }, [dispatch, subscriptions])

  const handleContinue = useCallback(() => {
    eventLogger.logUpsellPurchaseClose({
      screenName: ScreenName.UPSELL_SWITCHER,
      screenId: ScreenId.UPSELL_SWITCHER,
      buttonNumber: 4,
      goal,
      tap: ButtonTapType.NO_THANKS,
    })

    goTo({
      pathname: PageId.LOGIN,
      search,
    })
  }, [goal, search])

  const acceptAll = useCallback(() => {
    if (slimmerBellyAndFullBody) {
      dispatch(setSelectedSubscriptionAction(slimmerBellyAndFullBody))
    }

    dispatch(makeUpsellAction())
  }, [dispatch, slimmerBellyAndFullBody])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="upsell.title" components={[<br />]} />
      </S.Title>
      <S.Subtitle>{t`upsell.subtitle`}</S.Subtitle>

      <UpsellWithSwitcherSubscriptionsBlock />

      <S.Disclaimer>
        {t('upsell.disclaimer', {
          context: currentMeasurementSystem,
        })}
      </S.Disclaimer>

      <S.UpsellPlansDescription />

      <S.Description>
        <Trans i18nKey="upsellV2.successfullyCompleted" />{' '}
      </S.Description>

      <S.UpsellBenefits title="upsell.upsellBenefits.title">
        <Button onClick={acceptAll}>{t('upsellV2.acceptAllLink')}</Button>
      </S.UpsellBenefits>

      <S.Link onClick={handleContinue}>{t`upsell.noThanksLink`}</S.Link>

      <S.SubscriptionDescription>
        {t('upsell.upsellDescription.subscriptionDescriptionSwitcher', {
          trialPrice,
          price: fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
        })}
        <OuterLink
          href={TERMS_OF_USE_LINK}
          onClick={() => eventLogger.logTermsOfUseClicked(PagesSource.PAYWALL)}
        >
          {t`commonComponents.termsOfUse`}
        </OuterLink>{' '}
        {t('commonComponents.or')}{' '}
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupportDisclaimer')}
        />
        {t`upsell.upsellDescription.learnMore`}
      </S.SubscriptionDescription>

      {isUpsellInProgress && <Spinner />}

      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
      />
    </S.Wrapper>
  )
}
