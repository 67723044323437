import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSwitch = {
  Root: styled.label`
    display: flex;
    cursor: pointer;
    width: 44px;
    height: 24px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(100%);
    }

    &:checked ~ div {
      background-color: ${Color.PRIMARY};
      border: 1px solid ${Color.PRIMARY};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    background: #e5e7eb;
    transition-property: background, border;
  `,
  Button: styled.span`
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
    transition-property: transform;
  `,
}
