import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { StyledRecommendationCard as S } from './RecommendationCard.styles'
import { TRecommendationCard } from './constants'

export const RecommendationCard: FC<TRecommendationCard> = ({
  backgroundColor,
  badgeImage,
  headerBackgroundColor,
  headerTextColor,
  headerText,
  title,
  titleColor,
  image,
  hasImagePadding,
  checkMarkImage,
  points,
}) => (
  <S.Container $background={backgroundColor}>
    <S.Header $headerBackground={headerBackgroundColor}>
      <S.Icon $iconSrc={badgeImage} />

      <S.HeaderText $headerTextColor={headerTextColor}>
        <Trans i18nKey={headerText} />
      </S.HeaderText>
    </S.Header>

    <S.TitleContainer>
      <S.Title $color={titleColor}>
        <Trans i18nKey={title} />
      </S.Title>

      <S.Image src={image} $hasImagePadding={hasImagePadding} />
    </S.TitleContainer>

    <S.BenefitList>
      {points?.map((point) => (
        <S.BenefitItem key={point}>
          <S.CheckMark src={checkMarkImage} />
          <S.BenefitText>
            <Trans i18nKey={point} />
          </S.BenefitText>
        </S.BenefitItem>
      ))}
    </S.BenefitList>
  </S.Container>
)
