import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledActivityLevel = {
  Title: styled.h1`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  Subtitle: styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 0 16px;
        ${StyledAgeFlowContainer};
      `}
  `,
}
