import React, { useCallback, useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useIsInViewport } from 'hooks/useIsInViewport'

import { getAxonItem } from 'helpers/getAxonItem'

import { SubscriptionItemIntroOfferNew } from 'modules/purchase/components/SubscriptionItemIntroOfferNew'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionsBlockIntroOffer as S } from './SubscriptionsBlockIntroOfferNew.styles'

type TProps = {
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  isCancelOffer?: boolean
  children: React.ReactNode
  className?: string
}

export const SubscriptionsBlockIntroOfferNew: React.FC<TProps> = ({
  elemForComparisonRef,
  isCancelOffer = false,
  children,
  className = '',
}) => {
  const dispatch = useDispatch()
  const titleRef = useRef(null)

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const isVisible = useIsInViewport(titleRef)

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.WrapperContainer>
      <S.Wrapper className={className}>
        <S.Title $isVisible={isVisible} ref={titleRef}>
          <Trans
            i18nKey="purchaseTwoPlans.subscriptions.titleNew"
            components={{
              div: <div />,
            }}
          />
        </S.Title>
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.planId}
            onChange={handleChange}
            withoutMargin
          >
            <SubscriptionItemIntroOfferNew
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
              isCancelOffer={isCancelOffer}
            />
          </Option>
        ))}

        <S.ButtonContainer ref={elemForComparisonRef}>
          {children}
        </S.ButtonContainer>

        <S.SubscriptionDisclaimer />
      </S.Wrapper>
    </S.WrapperContainer>
  )
}
