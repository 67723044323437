import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { StyledReceivedPlan as S } from './ReceivedPlanBelly.styles'

type TProps = {
  className?: string
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
}
export const ReceivedPlanBelly: FC<TProps> = ({
  className = '',
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()

  const { benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  return (
    <S.ProgramPlanContainer className={className} ref={elemForComparisonRef}>
      <S.ProgramPlanTitle>
        {benefitsBlockTitle ? (
          <Trans>{benefitsBlockTitle}</Trans>
        ) : (
          <Trans i18nKey="purchaseFreeOfferBelly.receivedPlanTitle" />
        )}
      </S.ProgramPlanTitle>
      <ul>
        {benefits.length > 0 ? (
          benefits.map(({ id, text }) => (
            <S.ListItem key={id}>{text}</S.ListItem>
          ))
        ) : (
          <>
            <S.ListItem>
              <Trans
                i18nKey="purchaseFreeOfferBelly.fatBurnPlan"
                components={[<strong />]}
              />
            </S.ListItem>
            <S.ListItem>{t('purchaseFreeOfferBelly.danceWorkouts')}</S.ListItem>
            <S.ListItem>
              {t('purchaseFreeOfferBelly.boostConfidence')}
            </S.ListItem>
            <S.ListItem>
              {t('purchaseFreeOfferBelly.lowImpactDances')}
            </S.ListItem>
            <S.ListItem>{t('purchaseFreeOfferBelly.shortWorkouts')}</S.ListItem>
            <S.ListItem>
              {t('purchaseFreeOfferBelly.charismaticInstructors')}
            </S.ListItem>
          </>
        )}
      </ul>
    </S.ProgramPlanContainer>
  )
}
