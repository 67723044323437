import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MIN_KG_DIFF, MIN_LB_DIFF } from 'pages/questions/GoalWeight/constants'

import { Slider } from 'components/Slider'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'

import { ADULT_COHORT, Cohort, MeasurementSystem } from 'root-constants'

import { StyledGoalWeight as S } from './GoalWeightWithRuler.styles'
import { MinMaxWeight } from './constants'

type TProps = {
  isMetricSystem: boolean
  onChange: ({ target: { value, validity } }) => void
  value: string
}

export const GoalWeightWithRuler: React.FC<TProps> = ({
  isMetricSystem,
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  useDisableOverscroll()

  const handleChange = useCallback(
    (newValue) => {
      onChange({
        target: {
          value: newValue.toString(),
          validity: { valid: true },
        },
      })
    },
    [onChange],
  )

  return (
    <>
      <S.WeightValue $isAgeFlow={isAgeFlow}>
        {value}{' '}
        {t(
          `commonComponents.${
            isMetricSystem
              ? MeasurementSystem.METRIC
              : MeasurementSystem.IMPERIAL
          }`,
        )}
      </S.WeightValue>

      <Slider
        value={Number(value)}
        onChange={handleChange}
        min={
          isMetricSystem
            ? MinMaxWeight.MIN_WEIGHT_KG
            : MinMaxWeight.MIN_WEIGHT_LB
        }
        max={
          Number(currentWeightValue) -
          (isMetricSystem ? MIN_KG_DIFF : MIN_LB_DIFF)
        }
        step={1}
        gap={10}
      />
    </>
  )
}
