import { GoalChart } from 'components/GoalChart'
import { GoalChartAge } from 'components/GoalChartAge'
import { LoseWeightGraph } from 'components/LoseWeightGraph'
import { LoseWeightGraphAge } from 'components/LoseWeightGraphAge'

import { Cohort, MAIN_GOALS } from 'root-constants'

export const QUESTION = 'Get your Personal Dance Weight Loss Plan'

export const DEFAULT_TITLE = 'result.title'

export const TITLE_PATHS: Partial<Record<Cohort, string>> = {
  [Cohort.DANCEBIT_CLEAR_FLOW]: 'result.titleClearEdition',
  [Cohort.DB_CLEAR_INTRO_FAST]: 'result.titleClearEdition',
}

export const ANIMATION_PATH = '/animations/graphGoal.json'

export const GRAPH = {
  oldFlow: {
    [MAIN_GOALS.STAY_FIT]: GoalChart,
    [MAIN_GOALS.LOSE_WEIGHT]: LoseWeightGraph,
  },
  ageFlow: {
    [MAIN_GOALS.STAY_FIT]: GoalChartAge,
    [MAIN_GOALS.LOSE_WEIGHT]: LoseWeightGraphAge,
  },
}
