import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWorldWideUsers = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Picture: styled.figure`
    display: block;

    & img {
      vertical-align: top;
      margin-bottom: 16px;
    }
  `,
  Description: styled.figcaption`
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
}
