import result1FemaleAfter from 'assets/images/result1-female-after.png'
import result1FemaleAfterWebp from 'assets/images/result1-female-after.webp'
import result1FemaleBefore from 'assets/images/result1-female-before.png'
import result1FemaleBeforeWebp from 'assets/images/result1-female-before.webp'
import result1MaleAfter from 'assets/images/result1-male-after.png'
import result1MaleAfterWebp from 'assets/images/result1-male-after.webp'
import result1MaleBefore from 'assets/images/result1-male-before.png'
import result1MaleBeforeWebp from 'assets/images/result1-male-before.webp'
import result2FemaleAfter from 'assets/images/result2-female-after.png'
import result2FemaleAfterWebp from 'assets/images/result2-female-after.webp'
import result2FemaleBefore from 'assets/images/result2-female-before.png'
import result2FemaleBeforeWebp from 'assets/images/result2-female-before.webp'
import result2MaleAfter from 'assets/images/result2-male-after.png'
import result2MaleAfterWebp from 'assets/images/result2-male-after.webp'
import result2MaleBefore from 'assets/images/result2-male-before.png'
import result2MaleBeforeWebp from 'assets/images/result2-male-before.webp'
import result3FemaleAfter from 'assets/images/result3-female-after.png'
import result3FemaleAfterWebp from 'assets/images/result3-female-after.webp'
import result3FemaleBefore from 'assets/images/result3-female-before.png'
import result3FemaleBeforeWebp from 'assets/images/result3-female-before.webp'

export const SLIDES_FEMALE = [
  {
    id: '1',
    img: {
      before: result1FemaleBefore,
      after: result1FemaleAfter,
    },
    imgWebp: {
      before: result1FemaleBeforeWebp,
      after: result1FemaleAfterWebp,
    },
    date: {
      before: 'purchase1.photoResult.slideDateBeforeFirst',
      after: 'purchase1.photoResult.slideDateAfterFirst',
    },
  },
  {
    id: '2',
    img: {
      before: result2FemaleBefore,
      after: result2FemaleAfter,
    },
    imgWebp: {
      before: result2FemaleBeforeWebp,
      after: result2FemaleAfterWebp,
    },
    date: {
      before: 'purchase1.photoResult.slideDateBeforeSecond',
      after: 'purchase1.photoResult.slideDateAfterSecond',
    },
  },
  {
    id: '3',
    img: {
      before: result3FemaleBefore,
      after: result3FemaleAfter,
    },
    imgWebp: {
      before: result3FemaleBeforeWebp,
      after: result3FemaleAfterWebp,
    },
    date: {
      before: 'purchase1.photoResult.slideDateBeforeThird',
      after: 'purchase1.photoResult.slideDateAfterThird',
    },
  },
]

export const SLIDES_MALE = [
  {
    id: '1',
    img: {
      before: result1MaleBefore,
      after: result1MaleAfter,
    },
    imgWebp: {
      before: result1MaleBeforeWebp,
      after: result1MaleAfterWebp,
    },
    date: {
      before: 'purchase1.photoResult.slideDateBeforeFirst',
      after: 'purchase1.photoResult.slideDateAfterFirst',
    },
  },
  {
    id: '2',
    img: {
      before: result2MaleBefore,
      after: result2MaleAfter,
    },
    imgWebp: {
      before: result2MaleBeforeWebp,
      after: result2MaleAfterWebp,
    },
    date: {
      before: 'purchase1.photoResult.slideDateBeforeSecond',
      after: 'purchase1.photoResult.slideDateAfterSecond',
    },
  },
]
