import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  $isDefault: boolean
  $isSelected: boolean
}

const commonTextStyles = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

export const StyledChooseTrialPrice = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px 30px;
  `,
  Title: styled.h2`
    padding: 16px 0;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  `,
  Description: styled.p`
    ${commonTextStyles};
    padding-bottom: 10px;
  `,
  PriceDescription: styled.p`
    ${commonTextStyles};
    padding-bottom: 40px;
    color: ${Color.PRIMARY};
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 78px);
    grid-column-gap: 16px;
    justify-content: center;
    margin-bottom: 48px;
  `,
  OptionButton: styled.div<TProps>`
    ${commonTextStyles};
    width: 100%;
    padding: 22px 0;
    background-color: ${Color.LIGHT};
    transition: all 0.2s ease-out;
    border-radius: 16px;
    text-align: center;

    ${({ $isDefault }) =>
      $isDefault &&
      `
      border: 1px solid ${Color.PRIMARY};
      `}

    ${({ $isSelected }) =>
      $isSelected &&
      `
      background: ${Color.PRIMARY};
      color: ${Color.WHITE};
      `}
  `,
  BadgeContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
  `,
  Line: styled.div`
    width: 1px;
    height: 16px;
    margin-bottom: 8px;
    background: ${Color.PRIMARY};
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Color.PRIMARY};
  `,
}
