import styled, { css } from 'styled-components'

import { ThemeTimerConfig } from 'modules/purchase/components/DynamicTimer/constants'

import { Color, DynamicDiscountTheme } from 'root-constants'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
  $theme?: DynamicDiscountTheme
}

export const StyledTimerWithDiscount = {
  TimerContainer: styled.div<TTimerContainerProps>`
    border-radius: 16px;
    padding: 16px;
    margin: 18px auto 24px;
    background: radial-gradient(
      1446.02% 200.66% at 7.36% 145%,
      #ff0031 0%,
      #ffbc14 100%
    );
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${({ $theme }) =>
      $theme &&
      css`
        ${ThemeTimerConfig[$theme]};
        margin: 0 auto 24px;
      `}
  `,
  TimerText: styled.div<{ $isGerman?: boolean }>`
    color: ${Color.WHITE};
    text-align: ${({ $isGerman }) => ($isGerman ? 'right' : 'center')};
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
  `,
  DiscountColumn: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  DiscountAmount: styled.div<{ $theme?: DynamicDiscountTheme }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background: ${Color.DARK};
    color: ${Color.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;

    ${({ $theme }) =>
      $theme === DynamicDiscountTheme.BLACK &&
      css`
        color: ${Color.BLACK};
        background: linear-gradient(270deg, #ee9700 3.55%, #ffdd63 95.75%);
      `}
  `,
  DiscountBlock: styled.div`
    position: relative;
    display: flex;
    height: 62px;
    width: 140px;
    padding: 22px 24px 8px 24px;
    justify-content: center;
    align-items: center;
    background: ${Color.WHITE};
    color: ${Color.GRAY_100};
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 8px;
    text-transform: uppercase;
    overflow: hidden;
  `,
  Timer: styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
  `,
  TimerSign: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;
    border-radius: 8px;
    min-width: 46px;
    background: ${Color.WHITE};

    strong {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.GRAY_100};
    }

    span {
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      color: ${Color.DARK};
      text-transform: lowercase;
    }
  `,
}
