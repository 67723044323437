import React from 'react'
import { Trans } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { TERMS_OF_USE_LINK } from 'root-constants'

import { OuterLink } from './OuterLink'

export const TermsOfUseLink: React.FC<{
  source: string
  text?: string
}> = ({ text, source }) => (
  <OuterLink
    href={TERMS_OF_USE_LINK}
    onClick={() => eventLogger.logTermsOfUseClicked(source)}
  >
    <Trans i18nKey={text || 'commonComponents.termsOfUse'} />
  </OuterLink>
)
