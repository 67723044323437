export function createIntroOfferProductId({
  priceId,
  trialPriceId,
  trialPeriodQuantity,
}: {
  priceId: string
  trialPriceId: string | null
  trialPeriodQuantity: number | null
}): string {
  return trialPriceId
    ? `${priceId};${trialPriceId};${trialPeriodQuantity}`
    : `${priceId}`
}
