import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  $withCheckbox?: boolean
  $isAgeFlow: boolean
}

type TContentProps = {
  $withDetailedAnswer?: boolean
}

export const StyledAnswerButton = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    background-color: #f5f6f7;
    color: ${Color.DARK};
    padding: ${({ $withCheckbox }) =>
      $withCheckbox ? '8px 45px 8px 15px' : '8px 24px'};
    position: relative;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        margin: 0 auto;
        background-color: ${Color.WHITE};
        border: 1px solid ${Color.PRIMARY_LIGHT};
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
      `}
    &::after {
      display: ${({ $withCheckbox }) => ($withCheckbox ? 'block' : 'none')};
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      background: transparent;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    input:checked + & {
      background: ${Color.PRIMARY};
      color: #fff;
    }

    input:checked + & h3 {
      font-weight: 600;
    }

    input:checked + & p {
      color: ${Color.WHITE};
    }

    input:checked + &::after {
      opacity: 1;
      border-color: #fff;
      background: #fff url(${checkIcon}) no-repeat center;
      background-size: 11px 8.5px;
    }
  `,
  Content: styled.div<TContentProps>`
    min-height: ${({ $withDetailedAnswer }) =>
      $withDetailedAnswer ? '74px' : '56px'};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
    overflow-wrap: break-word;
  `,
  Text: styled.div<TWrapperProps>`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    flex-grow: 1;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      `}
  `,
  Icon: styled.img`
    margin-right: 10px;
  `,
}
