import styled, { css } from 'styled-components'

import ResultPersonalizedProgramBg from 'assets/images/result-personal-program-bg.jpg'

import { Color } from 'root-constants'

type TResultPersonalizedProgramProps = {
  $isAgeFlow: boolean
}

export const StyledResultPersonalizedProgram = {
  Wrapper: styled.div<TResultPersonalizedProgramProps>`
    padding: 26px 15px 15px;
    border-radius: 24px;
    background-image: url(${ResultPersonalizedProgramBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        background-image: none;
        background-color: #f4faff;
      `}
  `,
  Inner: styled.div`
    position: relative;
    z-index: 1;
  `,
  Title: styled.h2<TResultPersonalizedProgramProps>`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    text-decoration: underline;
    color: ${Color.DARK};

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        margin-bottom: 16px;

        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px;
        text-decoration: none;
      `}
  `,
  Subtitle: styled.h3`
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Cards: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  `,
  Card: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 14px 10px 18px;
    border-radius: 14px;
  `,
  CardImage: styled.img`
    object-fit: cover;
    width: 116px;
    margin-bottom: 30px;
  `,
  CardTitle: styled.p`
    width: 100%;
    min-height: 34px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK};
  `,
}
