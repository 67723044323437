import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badgeImg from 'assets/images/sprite/money-back-new.svg'

import { PagesSource } from 'root-constants'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuaranteeNew.styles'

export type TMoneyBackGuaranteeProps = {
  className?: string
}

export const MoneyBackGuaranteeNew: React.FC<TMoneyBackGuaranteeProps> = ({
  className = '',
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper className={className}>
      <S.Badge svg={badgeImg} />
      <S.Title>{t`purchase1.moneyBackGuarantee.title`}</S.Title>
      <S.Text>
        {t`purchase1.moneyBackGuarantee.text`}{' '}
        <TermsOfUseLink source={PagesSource.PAYWALL} />
      </S.Text>
    </S.Wrapper>
  )
}
