import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'

import {
  selectIsStayFitFlow,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { UpsellSubscriptionItemV2 } from 'modules/purchase/components/UpsellSubscriptionItemV2'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { makeUpsellAction } from 'modules/purchase/redux/actions/upsell'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import bellyPlanImg from 'assets/images/upsell-belly-plan.png'
import bodyProgramImg from 'assets/images/upsell-body-program.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ButtonTapType,
  Color,
  GROWTHBOOK_EXPERIMENT,
  ONBOARDING_GOAL_EVENT,
  ScreenId,
  ScreenName,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellDescription as S } from './UpsellWithSwitcherSubscriptionsBlock.styles'

export const UpsellWithSwitcherSubscriptionsBlock: React.FC = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { t } = useTranslation()

  const [selectedPlans, setSelectedPlans] = useState({
    [UpsellProduct.SLIMMER_BELLY]: true,
    [UpsellProduct.FULL_BODY]: false,
  })

  const isPaidUpsell = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_474)

  const currency = useSelector(selectCurrency)
  const subscriptions = useSelector(selectSubscriptionList)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const bothUpsellsProductId = subscriptions.find(
    (subs) => subs.product === UpsellProduct.BOTH_UPSELLS_FREE,
  )
    ? UpsellProduct.BOTH_UPSELLS_FREE
    : UpsellProduct.BOTH_UPSELLS

  const slimmerBellyPlan = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.SLIMMER_BELLY,
  )

  const fullBodyProgram = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.FULL_BODY,
  )

  const slimmerBellyAndFullBody = subscriptions.find(
    (subscription) => subscription.product === bothUpsellsProductId,
  )

  const goal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  useEffect(() => {
    if (
      (selectedPlans[UpsellProduct.SLIMMER_BELLY] &&
        selectedPlans[UpsellProduct.FULL_BODY]) ||
      (!selectedPlans[UpsellProduct.SLIMMER_BELLY] &&
        !selectedPlans[UpsellProduct.FULL_BODY])
    ) {
      slimmerBellyAndFullBody &&
        dispatch(setSelectedSubscriptionAction(slimmerBellyAndFullBody))

      return
    }

    if (selectedPlans[UpsellProduct.SLIMMER_BELLY]) {
      slimmerBellyPlan &&
        dispatch(setSelectedSubscriptionAction(slimmerBellyPlan))
    }

    if (selectedPlans[UpsellProduct.FULL_BODY]) {
      fullBodyProgram &&
        dispatch(setSelectedSubscriptionAction(fullBodyProgram))
    }
  }, [
    dispatch,
    fullBodyProgram,
    selectedPlans,
    slimmerBellyAndFullBody,
    slimmerBellyPlan,
  ])

  const isFree = useMemo(
    () =>
      !isPaidUpsell &&
      subscriptions.find(
        (subs) => subs.product === UpsellProduct.BOTH_UPSELLS_FREE,
      ),
    [isPaidUpsell, subscriptions],
  )

  const handleChange = useCallback((key: UpsellProduct) => {
    setSelectedPlans((prev) => {
      const toggleStatus = !prev[key]

      toggleStatus
        ? eventLogger.logUpsellToggleEnable(key)
        : eventLogger.logUpsellToggleDisable(key)

      return {
        ...prev,
        [key]: toggleStatus,
      }
    })
  }, [])

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction())
  }, [dispatch])

  const handleContinue = useCallback(() => {
    eventLogger.logUpsellPurchaseClose({
      screenName: ScreenName.UPSELL_SWITCHER,
      screenId: ScreenId.UPSELL_SWITCHER,
      buttonNumber: 2,
      goal,
      tap: ButtonTapType.NO_THANKS,
    })

    goTo({
      pathname: PageId.LOGIN,
      search,
    })
  }, [goal, search])

  const handleContinueWithAll = useCallback(() => {
    setSelectedPlans({
      [UpsellProduct.SLIMMER_BELLY]: true,
      [UpsellProduct.FULL_BODY]: true,
    })

    if (slimmerBellyAndFullBody) {
      dispatch(setSelectedSubscriptionAction(slimmerBellyAndFullBody))
    }

    makeUpsell()
  }, [slimmerBellyAndFullBody, makeUpsell, dispatch])

  const hasBothPlansSelected =
    selectedPlans[UpsellProduct.SLIMMER_BELLY] &&
    selectedPlans[UpsellProduct.FULL_BODY]

  const hasOnePlanSelected =
    selectedPlans[UpsellProduct.SLIMMER_BELLY] ||
    selectedPlans[UpsellProduct.FULL_BODY]

  return (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="upsell.upsellDescription.title" />
        </S.Title>
        <S.TextContainer>
          <S.Image src={bellyPlanImg} alt="belly-plan" />
          <S.Text>{t`upsell.upsellDescription.bellyPlan`}</S.Text>
        </S.TextContainer>

        <S.TextContainer>
          <S.Image src={bodyProgramImg} alt="body-program" />
          <S.Text>{t`upsell.upsellDescription.bodyProgram`}</S.Text>
        </S.TextContainer>

        {!isFree && (
          <S.GetAll>
            <S.DiscountBadge>{t('upsellV2.discount.badge')}</S.DiscountBadge>
            <Trans
              i18nKey="upsellV2.discount.text"
              values={{
                price: slimmerBellyAndFullBody?.mainPrices.fullPrice,
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.GetAll>
        )}

        {slimmerBellyPlan && (
          <UpsellSubscriptionItemV2
            subscription={slimmerBellyPlan}
            onChange={handleChange}
            isSelected={selectedPlans[UpsellProduct.SLIMMER_BELLY]}
            showPrice={!isFree}
            name={t('upsellV2.plans.slimmerBellyPlan')}
          />
        )}

        {fullBodyProgram && (
          <UpsellSubscriptionItemV2
            subscription={fullBodyProgram}
            onChange={handleChange}
            isSelected={selectedPlans[UpsellProduct.FULL_BODY]}
            showPrice={!isFree}
            name={t('upsellV2.plans.fullBodyProgram')}
          />
        )}

        {isFree && (
          <S.PriceBadge>
            <span>
              <Trans
                i18nKey="upsell.upsellDescription.priceBadge"
                components={[<strong />]}
                values={{
                  price: slimmerBellyAndFullBody?.mainPrices.fullPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </span>

            <S.TrialPrice>
              {t('upsell.upsellDescription.trialPrice', {
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.TrialPrice>
          </S.PriceBadge>
        )}
      </S.Wrapper>
      <S.ButtonContainer>
        <Button onClick={handleContinueWithAll}>
          <Trans i18nKey="upsellV2.acceptAll" />
        </Button>
        {!hasBothPlansSelected && (
          <S.SecondaryButton
            backgroundColor={Color.GRAY_40}
            onClick={hasOnePlanSelected ? makeUpsell : handleContinue}
          >
            {hasOnePlanSelected
              ? t('upsellV2.acceptSelected')
              : t('upsellV2.continueWithout')}
          </S.SecondaryButton>
        )}
      </S.ButtonContainer>
    </>
  )
}
