import React from 'react'

import { StyledErrorNotification as S } from './Notification.styles'

type TProps = {
  text: string
}

export const Notification: React.FC<TProps> = ({ text }) => (
  <S.Wrapper $text={text}>
    <S.Text>{text}</S.Text>
  </S.Wrapper>
)
