import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import diagramPicture from 'assets/images/diagram-minv3.svg'
import drNockSmeetonIcon from 'assets/images/purchaseNoOnboarding/dr-nick-smeeton-min.png'
import healthAndHumanDepartmentIcon from 'assets/images/purchaseNoOnboarding/health-and-human-service-min.png'

import { StyledBodyAgeV3 as S } from './BodyAgeV3.styles'

type TProps = {
  className?: string
}

export const BodyAgeV3: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.Root className={className}>
      <S.Title>{t(`onboarding.welcomeWeightLoss.programsDeveloped`)}</S.Title>
      <S.Picture
        src={diagramPicture}
        alt="Stay young as long and possible diagram"
      />
      <ul>
        <S.Card>
          <S.Icon
            src={healthAndHumanDepartmentIcon}
            alt="United States Department of Health and Human Services"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseUpgrade.bodyAge.text1"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
        <S.Card>
          <S.Icon
            src={drNockSmeetonIcon}
            alt="Dr Nick Smeeton from Stanford University"
          />
          <S.Text>
            <Trans
              i18nKey="purchaseUpgrade.bodyAge.text2"
              components={[<strong />]}
            />
          </S.Text>
        </S.Card>
      </ul>
    </S.Root>
  )
}
