import styled from 'styled-components'

import { LoseWeightGraphBelly } from 'components/LoseWeightGraphBelly'

import { animatedTitleStyles } from 'modules/purchase/pages/PurchaseNew/PurchaseNew.styles'

import { Color } from 'root-constants'

export const StyledGraphNew = {
  Graph: styled(LoseWeightGraphBelly)`
    width: 304px;
    height: 132px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -12px;
  `,
  GraphContainer: styled.div`
    position: relative;
    width: 343px;
    height: 220px;
    border-radius: 30px;
    border: 1px solid #d3e6ff;
    background: ${Color.WHITE};
    box-shadow: 0 16px 40px 0 rgba(14, 45, 88, 0.12);
    overflow: hidden;
    margin-bottom: 32px;
  `,
  UserRecommended: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 16px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 92px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  PageTitle: styled.h2<{ $isVisible: boolean }>`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;

    strong {
      color: ${Color.PRIMARY};
    }

    ${animatedTitleStyles};
  `,
}
