export const PEOPLE_ASK = [
  {
    question: 'purchaseNoOnboarding.peopleAsk.question1.question',
    answer: 'purchaseNoOnboarding.peopleAsk.question1.answer',
  },
  {
    question: 'purchaseNoOnboarding.peopleAsk.question2.question',
    answer: 'purchaseNoOnboarding.peopleAsk.question2.answer',
  },
  {
    question: 'purchaseNoOnboarding.peopleAsk.question3.question',
    answer: 'purchaseNoOnboarding.peopleAsk.question3.answer',
  },
]
