import styled, { css } from 'styled-components'

import {
  SubscriptionItemBadge,
  SubscriptionItemBase,
} from 'modules/purchase/components/SubscriptionItemNewYear/constants'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/subscription-item-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $availableSpins?: number
}

type TPriceProps = { $hasSmallPrices: boolean }

const commonPriceStyles = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

const crossedPriceStyles = css`
  color: ${Color.GRAY_80};
  text-decoration: line-through;
  text-decoration-color: ${Color.RED};
`

export const StyledSubscriptionItemIntroOffer = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px 0;
    border-radius: 16px;
    background: ${Color.WHITE};
    border: 1px solid ${Color.GRAY_40};

    ${({ $isSelected, $availableSpins = 0 }) =>
      $isSelected &&
      `
        border-radius: 16px;
        border: 1px solid ${SubscriptionItemBase[$availableSpins].color};
        background: ${SubscriptionItemBase[$availableSpins].background};
        `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: end;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected, $availableSpins = 0 }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${SubscriptionItemBase[$availableSpins].color};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div<{ $availableSpins: number }>`
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 12px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    background-color: ${({ $availableSpins }) =>
      SubscriptionItemBase[$availableSpins].color};
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<{ $isSelected: boolean }>`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: ${({ $isSelected }) => ($isSelected ? Color.DARK : Color.GRAY_80)};
    padding-bottom: 2px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 17px;
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span<TPriceProps>`
    ${crossedPriceStyles};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  PreviousPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    ${crossedPriceStyles};
    padding-right: 6px;
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
        padding-right: 4px;
    `};
  `,
  CurrentPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    color: ${Color.GRAY_80};
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CustomPrice: styled.p<TProps & TPriceProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 77px;
    min-height: 44px;
    margin-right: 8px;
    padding: 8px;
    color: ${Color.GRAY_80};
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});

    ${({ $isSelected, $availableSpins }) =>
      $isSelected && SubscriptionItemBadge[$availableSpins as number]};

    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        min-width: 82px;
    `};
  `,
  PriceValue: styled.span<TPriceProps>`
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    padding: 2px 0;

    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 13px;
    `};
  `,
  Period: styled.span`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
  `,
}
