import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledWelcomeInfluencer = {
  Container: styled(Container)`
    padding: 20px 16px;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        94.88% 89.69% at 62.13% 37.01%,
        #f3f9ff 0%,
        #e0f0ff 39.74%,
        #a4ceff 100%
      );
      background-size: contain;
      left: 0;
      top: 0;
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0 auto 8px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    color: ${Color.GRAY_80};
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  `,
  Description: styled.div`
    display: flex;
    margin-bottom: 32px;
    gap: 12px;
  `,
  InfluencerTitle: styled.p`
    color: ${Color.DARK};
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  `,
  InfluencerText: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY_80};
  `,
  StarJourney: styled.p`
    color: ${Color.DARK};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 32px;
  `,
  Terms: styled.div`
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: ${Color.GRAY_80};
    margin: 38px auto 0;
    padding-bottom: 14px;

    a {
      color: ${Color.GRAY_80};

      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 14px;
    width: 1px;
    background-color: ${Color.GRAY_80};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
