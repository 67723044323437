import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  EMPTY_FIELD_ERROR,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchase/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchase/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects/common'
import { TCreditCardField, TPaymentErrorState } from 'modules/purchase/types'

import walletIcon from 'assets/images/wallet.png'

import { CardPaymentFieldName } from 'root-constants'

import {
  StyledCreditCardPaymentForm as S,
  stripeElementStyle,
} from './CreditCardPaymentForm.styles'

export const CreditCardPaymentForm: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')
  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)
      const currentPageId = getPageIdFromPathName(pathname)

      if (!stripe || !card || !currentPageId) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      name,
      pathname,
      errors,
    ],
  )

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit}>
        <S.InputContainer>
          <S.Label>{t`purchase1.paymentForm.cardNumber`}</S.Label>
          <S.CardNumberElement
            onReady={(elem) => {
              elem.focus()
              cardNumberElemRef.current = elem
            }}
            options={{
              showIcon: false,
              placeholder: '1234 1234 1234 1234',
              style: stripeElementStyle,
              disableLink: true,
            }}
            onChange={({ empty, error, complete }) => {
              handleChange({
                fieldName: CardPaymentFieldName.NUMBER,
                isEmpty: empty,
                hasError: !!error,
                isComplete: complete,
                nextElemRef: cardExpiryElemRef,
              })
            }}
          />
          <S.CvvExpiryInputContainer>
            <S.CardExpiryContainer>
              <S.Label>{t`purchase1.paymentForm.cardExpiry`}</S.Label>
              <S.CardExpiryElement
                onReady={(elem) => {
                  cardExpiryElemRef.current = elem
                }}
                options={{
                  placeholder: t`purchase1.paymentForm.cardExpiryPlaceholder`,
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.EXPIRY,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cvcElemRef,
                  })
                }}
              />
            </S.CardExpiryContainer>

            <S.CardCvcContainer>
              <S.Label>{t`purchase1.paymentForm.securityNumber`}</S.Label>
              <S.CardCvcElement
                onReady={(elem) => {
                  cvcElemRef.current = elem
                }}
                options={{
                  placeholder: '***',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.CVC,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cardholderNameElemRef,
                  })
                }}
              />
              <S.CardCvcElementIcon src={walletIcon} alt="wallet" />
            </S.CardCvcContainer>
          </S.CvvExpiryInputContainer>
          <S.Label>{t`purchase1.paymentForm.cardholderName`}</S.Label>
          <S.CardholderInput
            ref={cardholderNameElemRef}
            type="text"
            placeholder={t`purchase1.paymentForm.cardholderNamePlaceholder`}
            data-invalid={!!errors[CardPaymentFieldName.NAME].error}
            onChange={(event) => {
              const value = event.target.value.trim()

              setName(value)

              handleChange({
                fieldName: CardPaymentFieldName.NAME,
                isEmpty: false,
                hasError: false,
                isComplete: true,
              })
            }}
          />
        </S.InputContainer>
        <S.Button
          type="submit"
          disabled={!stripe || !isFormValid}
          disabledBackgroundColor="rgba(205, 206, 209, 0.85)"
        >
          {t`actions.continue`}
        </S.Button>
      </S.Form>
    </S.Wrapper>
  )
}
