import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  GET_CART_LIST,
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantParentCohort,
  selectLanguage,
  selectUserAge,
} from 'root-redux/selects/common'
import { selectUserCart } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { DynamicTimer } from 'modules/purchase/components/DynamicTimer'
import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { HeaderWithTimerV2 } from 'modules/purchase/components/HeaderWithTimerV2'
import { LimitedDeal } from 'modules/purchase/components/LimitedDeal'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_FEMALE_CLEAR,
  SLIDES_FEMALE_SOMATIC,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReachTarget } from 'modules/purchase/components/ReachTarget'
import { ReachTargetClear } from 'modules/purchase/components/ReachTargetClear'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { ReceivedWeeklyPlan } from 'modules/purchase/components/ReceivedWeeklyPlan'
import { Reviews } from 'modules/purchase/components/Reviews'
import { SecretGiftCards } from 'modules/purchase/components/SecretGiftCards'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'
import { TimerWithDiscount } from 'modules/purchase/components/TimerWithDiscount'
import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { Checkout } from 'modules/purchase/pages/Checkout'
import {
  CUSTOMER_REVIEWS_SOMATIC,
  SECRET_GIFT_COST,
} from 'modules/purchase/pages/PurchaseWithoutIntro/constant'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  CUSTOMER_REVIEWS,
  Cohort,
  DynamicDiscountType,
  GROWTHBOOK_EXPERIMENT,
  Locale,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutSeparateMethods } from '../CheckoutSeparateMethods'
import { StyledPurchaseWithoutIntro as S } from './PurchaseWithoutIntro.styles'

export const PurchaseWithoutIntro: FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const age = useSelector(selectUserAge)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const userCart = useSelector(selectUserCart)
  const lang = useSelector(selectLanguage)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
    cohort,
    currency,
  } = usePurchaseStore()

  const { buttonText } = useDynamicPaywallConfig()
  const {
    isTrialCohort,
    isLegsFlow,
    isSaleFlow,
    isSomaticFlow,
    isInfluencerFlow,
    isGiftFlow,
    isNewClearFlow,
    isMenopauseFlow,
    isTTClearFlow,
  } = useCohortInfo()

  const cohortToUse = parentCohort || cohort
  const isJaLocale = lang === Locale.JAPANESE

  usePaymentElementStatus()

  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)
  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)
  const hasBellyPricesTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1264)

  const hasIncludedVat = useVatInfo()

  usePurchaseAnalytics({
    screenId: ScreenId.BEFORE_AFTER_PHOTOS,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const isCartReady = useMemo(
    () => !fetchingActionsList?.includes(GET_CART_LIST),
    [fetchingActionsList],
  )

  const isBellyFlow = useMemo(
    () =>
      cohortToUse === Cohort.DB_BELLY ||
      cohortToUse === Cohort.DB_MENOPAUSE ||
      cohortToUse === Cohort.DB_MENOPAUSE_REF,
    [cohortToUse],
  )

  const isWeekSaleFlow = useMemo(() => cohortToUse === Cohort.DB_BELLY_WEEK, [
    cohortToUse,
  ])

  const femaleSlides = useMemo(() => {
    if (isSomaticFlow) return SLIDES_FEMALE_SOMATIC

    if (isTTClearFlow) return SLIDES_FEMALE_CLEAR

    return SLIDES_FEMALE
  }, [isSomaticFlow, isTTClearFlow])

  useEffect(() => {
    if (!selectedSubscriptionId || !isCartReady || !arePricesReady) return

    dispatch(
      setScreenNameAction(
        userCart.length > 0
          ? ScreenName.ONBOARDING_WITH_UPSELL
          : ScreenName.ONBOARDING,
      ),
    )
  }, [
    arePricesReady,
    dispatch,
    isCartReady,
    selectedSubscriptionId,
    userCart.length,
  ])

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasHighPrices && age > 39) {
      pricesTags = `${SubscriptionTags.AGE_40_70},${pricesTags}`
    }

    if (hasBellyPricesTest) {
      pricesTags = `${SubscriptionTags.DISCOUNT_70_OFF},${pricesTags}`
    }

    dispatch(startFetching(GET_CART_LIST))

    dispatch(setScreenIdAction(ScreenId.BEFORE_AFTER_PHOTOS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setScreenNameAction(null))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [age, dispatch, hasBellyPricesTest, hasHighPrices, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  const Disclaimer = () => {
    if (hasObUpsellTest && userCart.length > 0) {
      return <S.CartDisclaimer />
    }

    if (isTrialCohort) {
      return <S.SubscriptionIntroDisclaimer />
    }

    return <S.SubscriptionDisclaimer />
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          {isSaleFlow && <Ticker />}

          {(isSaleFlow || isInfluencerFlow) && (
            <HeaderWithTimerV2 onButtonClick={handleShowPayment} />
          )}

          {isTTClearFlow ? <ReachTargetClear /> : <ReachTarget />}
          <S.Wrapper isLarge={isBellyFlow || isLegsFlow}>
            {isWeekSaleFlow ? (
              <ReceivedWeeklyPlan>
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </ReceivedWeeklyPlan>
            ) : (
              <ReceivedPlan />
            )}
          </S.Wrapper>

          {!isSaleFlow && !isInfluencerFlow && (
            <S.TimerSubheaderWithTimer
              $hasSmallText={isJaLocale}
              $isBellyFlow={isBellyFlow || isLegsFlow}
              $isMenopauseFlow={isMenopauseFlow}
              elemForComparisonRef={buttonWrapperElementRef}
              onButtonClick={handleShowPayment}
              isInfluencerFlow
            />
          )}

          <S.Wrapper isLarge={isBellyFlow || isLegsFlow}>
            {isSaleFlow && dynamicDiscount?.promoText ? (
              <TimerWithDiscount />
            ) : (
              <>
                {isSaleFlow &&
                  dynamicDiscount?.type === DynamicDiscountType.STATIC &&
                  !isWeekSaleFlow && <LimitedDeal />}

                {isSaleFlow &&
                  !isWeekSaleFlow &&
                  dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
                    <DynamicTimer />
                  )}
              </>
            )}

            {isInfluencerFlow && <TimerWithDiscount />}

            {isGiftFlow && (
              <S.Title>
                <Trans
                  i18nKey="purchaseWithoutIntro.titleGift"
                  values={{
                    currency: CURRENCY_SYMBOLS[currency],
                    price:
                      SECRET_GIFT_COST[currency] ||
                      SECRET_GIFT_COST[Currency.USD],
                  }}
                />
              </S.Title>
            )}

            {isTrialCohort && !isWeekSaleFlow && (
              <SubscriptionsBlockIntroOffer
                elemForComparisonRef={buttonWrapperElementRef}
                hasInnerDisclaimer={isGiftFlow || isNewClearFlow}
                hasExternalDisclaimer={!isGiftFlow && !isNewClearFlow}
                clearBackground={isGiftFlow}
                withTitle={!isGiftFlow}
              >
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </SubscriptionsBlockIntroOffer>
            )}

            {isGiftFlow && <SecretGiftCards />}

            {!isTrialCohort && !isWeekSaleFlow && (
              <SubscriptionsBlock
                buttonWrapperElementRef={buttonWrapperElementRef}
              >
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </SubscriptionsBlock>
            )}
            <S.Container>
              <FeaturedBlock />
            </S.Container>
            {!isGiftFlow && <MoneyBackGuarantee />}
            <PhotoResultV3
              femaleSlides={femaleSlides}
              maleSlides={SLIDES_MALE}
            />
            <S.FAQList />
            <Reviews
              reviews={
                isSomaticFlow ? CUSTOMER_REVIEWS_SOMATIC : CUSTOMER_REVIEWS
              }
            />
            <S.PurchaseButton
              type="button"
              data-order="3"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.PurchaseButton>
            <Disclaimer />
            <SecurityInfo />
          </S.Wrapper>
        </div>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        {isTrialCohort ? (
          <CheckoutSeparateMethods
            key={selectedSubscriptionId}
            isCheckoutShown={isCheckoutShown}
            setIsCheckoutShown={setIsCheckoutShown}
          />
        ) : (
          <Checkout
            key={selectedSubscriptionId}
            isCheckoutShown={isCheckoutShown}
            setIsCheckoutShown={setIsCheckoutShown}
          />
        )}
      </S.CheckoutContainer>

      {!isCheckoutShown && (
        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        />
      )}
    </>
  )
}
