import styled from 'styled-components'

import cross from 'assets/images/flow-belly/icons/close-btn-light.svg'

import { Color } from 'root-constants'

export const StyledCartBlock = {
  Container: styled.div`
    position: relative;
    display: flex;
    padding: 11px 12px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #05ad5c;
    background: #f2faf0;
    margin-top: 24px;
  `,
  Badge: styled.div`
    position: absolute;
    top: -12px;
    display: flex;
    height: 22px;
    padding: 0 8px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #05ad5c;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.5px;
    color: ${Color.WHITE};
  `,
  Product: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  ProductImage: styled.img`
    width: 40px;
    height: 40px;
    border-radius: 8px;
  `,
  ProductDescription: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  Text: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProductTitle: styled.span`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  `,
  LearnMore: styled.button`
    border: none;
    background: none;
    color: #7c8187;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    padding: 0;
    cursor: pointer;
  `,
  Cross: styled.button`
    cursor: pointer;
    background-color: transparent;
    background-image: ${`url(${cross})`};
    background-repeat: no-repeat;
    border: none;
    width: 16px;
    height: 16px;
  `,
}
