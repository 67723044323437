import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import {
  CURRENCY_SYMBOLS,
  Currency,
  GeneralProduct,
} from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionItemIntroOfferAdult as S } from './SubscriptionItemIntroOfferAdult.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemIntroOfferAdult: React.FC<TProps> = ({
  subscription: {
    id,
    product,
    introDiff,
    mainPrices: { fullPrice, daily: fullDailyPrice },
    trialPrices: {
      fullPrice: trialCurrentPrice,
      daily: trialDailyPrice,
      durationDays,
      oldPrices,
    },
    isDefault,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPriceWithFractionalPart = useMemo(() => {
    if (product === GeneralProduct.SEVEN_DAY || !trialCurrentPrice) {
      return oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [product, trialCurrentPrice, fullPrice, oldPrices])

  const customPriceWithFractionalPart = useMemo(
    () => (trialDailyPrice || fullDailyPrice)?.toFixed(2),
    [trialDailyPrice, fullDailyPrice],
  )

  const oldPriceSecondWithFractionalPart = useMemo(
    () => oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [oldPrices],
  )

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const trialDurationInWeeks = useMemo(() => Math.round(durationDays / 7), [
    durationDays,
  ])

  const hasMxnCurrency = currency === Currency.MXN

  return (
    <S.MostPopularWrapper $isDefault={isDefault}>
      <S.Wrapper key={id} $isSelected={isSelected} $isDefault={isDefault}>
        {isDefault && (
          <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
        )}
        <S.Content $isSelected={isSelected}>
          <S.PlanContainer>
            <S.PlanPeriod>
              <Trans
                i18nKey="purchaseWeeklyOffer.paymentForm.planDescription"
                values={{ count: trialDurationInWeeks }}
              />
            </S.PlanPeriod>

            <S.PriceContainer>
              <S.PreviousPrice $hasMxnCurrency={hasMxnCurrency}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price:
                      oldPriceWithFractionalPart &&
                      formatJaPriceWithCommas(
                        oldPriceWithFractionalPart,
                        currency,
                      ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.PreviousPrice>

              <S.CustomPreviousPrice $hasMxnCurrency={hasMxnCurrency}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      currentPriceWithFractionalPart,
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.CustomPreviousPrice>
            </S.PriceContainer>
          </S.PlanContainer>

          <S.CustomPrice>
            <S.Period>
              {t`purchase1.subscription.interval`}{' '}
              {t(`purchase1.subscription.day`)}
            </S.Period>
            <S.PricesBox $hasMxnCurrency={hasMxnCurrency}>
              <S.PreviousPrice $hasMxnCurrency={hasMxnCurrency}>
                {oldPriceSecondWithFractionalPart && (
                  <Trans
                    i18nKey="purchase1.checkout.price"
                    values={{
                      price: formatJaPriceWithCommas(
                        oldPriceSecondWithFractionalPart,
                        currency,
                      ),
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                )}
              </S.PreviousPrice>

              <S.PriceValue $hasMxnCurrency={hasMxnCurrency}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      customPriceWithFractionalPart,
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.PriceValue>
            </S.PricesBox>
            <S.Discount>
              {t('purchase3.subscriptionsBlock.discount', {
                discount: introDiff?.discountPercentage,
              })}
            </S.Discount>
          </S.CustomPrice>
        </S.Content>
      </S.Wrapper>
    </S.MostPopularWrapper>
  )
}
