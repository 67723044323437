import styled from 'styled-components'

import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { animatedTitleStyles } from 'modules/purchase/pages/PurchaseNew/PurchaseNew.styles'

import subscriptionListBackground from 'assets/images/subscriptions-block-bg.png'

import { Color } from 'root-constants'

export const StyledSubscriptionsBlockIntroOffer = {
  WrapperContainer: styled.div`
    background: url(${subscriptionListBackground}) center right no-repeat;
    background-size: cover;
    width: 100%;
    margin: 0 auto 40px;
    padding: 80px 16px 0;
    max-width: 375px;
  `,
  Wrapper: styled.div``,
  Title: styled.div<{ $isVisible: boolean }>`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-left: 16px;
    margin-bottom: 40px;
    overflow: hidden;

    ${animatedTitleStyles};
  `,
  ButtonContainer: styled.div``,
  SubscriptionDisclaimer: styled(IntroOfferDisclaimer)`
    margin-top: 24px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY_60};

    a {
      color: ${Color.GRAY_60};
    }
  `,
}
