import React from 'react'
import { Trans } from 'react-i18next'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledGoalChart as S } from './GoalChart.styles'
import { ANIMATION_PATH } from './constants'

type TProps = {
  className?: string
}

export const GoalChart: React.FC<TProps> = ({ className }) => {
  const [graphContentRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
    animationName: 'goalChart',
  })

  return (
    <S.Wrapper className={className}>
      <S.Title>
        <Trans i18nKey="result.goalChart.title" />
      </S.Title>
      <S.GraphContent>
        <div ref={graphContentRef} />

        <S.TipNow>
          <Trans i18nKey="result.goalChart.label1" />
        </S.TipNow>
        <S.TipAfter>
          <Trans i18nKey="result.goalChart.label2" />
        </S.TipAfter>
      </S.GraphContent>
    </S.Wrapper>
  )
}
