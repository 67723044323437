import { PageId } from 'page-constants'

export const PAGES_WITHOUT_BACK_BUTTON = [
  PageId.PERSONAL_HISTORY,
  PageId.EMAIL_CONSENT,
  PageId.ENTER_EMAIL_NO_ONBOARDING,
  PageId.PREPARE_PLAN,
  PageId.SOCIAL_PROOF,
  PageId.NICE_TO_MEET,
  PageId.FORGET_PLANKS,
  PageId.DANCE_VS_EXERCISES,
  PageId.FITNESS_PROFILE,
  PageId.REACH,
  PageId.DANCING_STYLES_INTRO,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.CANCEL_OFFER_QUESTION_V2,
  PageId.PURCHASE_CANCEL_OFFER,
  PageId.PURCHASE_CANCEL_OFFER_SOMATIC,
  PageId.PURCHASE_FREE_OFFER,
  PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  PageId.PURCHASE_CANCEL_OFFER_FREE,
  PageId.PURCHASEV2,
  PageId.PURCHASE_REF,
  PageId.UPSELL,
  PageId.UPSELL_CANCEL_OFFER,
  PageId.DOWNLOAD,
  PageId.UPGRADE_LIFE,
  PageId.TEN_MILLION_PEOPLE,
  PageId.PREPAYWALL,
  PageId.DESIGN_NEW_LIFE,
  PageId.WELCOME,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.PLAN_IS_READY,
  PageId.NAME,
  PageId.ENTER_EMAIL,
  PageId.ACHIEVE_YOUR_GOAL,
  PageId.STAY_CONSISTENT,
  PageId.BETTER_RESULT,
  PageId.GRIP_OF_STRESS,
  PageId.SUGAR_CRAVINGS,
  PageId.WELCOME_INFLUENCER,
  PageId.PURCHASE_CANCEL_OFFER_REF,
  PageId.HEALTH_PROFILE,
  PageId.SUPERCHARGE_YOURSELF,
  PageId.TONING_PILATES,
  PageId.BOOST_YOUR_HEALTH,
  PageId.HELPED_MILLIONS_PEOPLE,
  PageId.HELP_ACHIEVE_RESULTS,
  PageId.DANCEBIT_USERS,
  PageId.RESULT_IN_MIRROR,
  PageId.CHOOSE_TRIAL,
  PageId.PURCHASE_TRIAL_TWO_WEEKS,
  PageId.PURCHASE_NEW_YEAR,
  PageId.PURCHASE_UPGRADE_LIVECHAT,
  PageId.SAY_GOODBYE,
  PageId.TEN_MILLION_PEOPLE_MENOPAUSE,
  PageId.HORMONAL_CHANGES,
  PageId.CHANGE_FOR_BETTER,
  PageId.QUOTATION,
  PageId.FITNESS_EXPERIENCE,
]
