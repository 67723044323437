import styled, { css, keyframes } from 'styled-components'

import { Color } from 'root-constants'

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const tipStyles = css`
  position: absolute;
  padding: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  text-align: center;
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
`

type TGoalChartAgeProps = {
  $isLongLabel?: boolean
  $isGermanOrItalian?: boolean
}

export const StyledGoalChartAge = {
  Wrapper: styled.div`
    overflow: hidden;
    min-height: 325px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  GraphContent: styled.div`
    position: relative;
  `,
  CurrentMe: styled.div`
    ${tipStyles};
    top: 150px;
    left: 10px;
    color: #eb3524;
    animation-delay: 500ms;
  `,
  BoostMetabolism: styled.div<TGoalChartAgeProps>`
    ${tipStyles};
    top: 100px;
    left: 60px;
    border-radius: 4px;
    background-color: #ffa337;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    color: ${Color.WHITE};
    animation-delay: 800ms;

    ${({ $isLongLabel }) =>
      $isLongLabel &&
      css`
        top: 100px;
        left: 35px;
      `};

    ${({ $isGermanOrItalian }) =>
      $isGermanOrItalian &&
      css`
        top: 85px;
        left: 70px;
      `};
  `,
  ToneMuscles: styled.div<TGoalChartAgeProps>`
    ${tipStyles};
    top: 80px;
    left: 170px;
    padding: 4px;
    border-radius: 4px;
    background: #f5f6f7;
    color: #8e9199;
    animation-delay: 1200ms;

    ${({ $isLongLabel }) =>
      $isLongLabel &&
      css`
        top: 75px;
        left: 130px;
      `};

    ${({ $isGermanOrItalian }) =>
      $isGermanOrItalian &&
      css`
        top: 65px;
        left: 185px;
      `};
  `,
  BurstWithEnergy: styled.div<TGoalChartAgeProps>`
    ${tipStyles};
    top: 40px;
    left: 255px;
    max-width: 70px;
    padding: 4px;
    border-radius: 4px;
    background: #57d26e;
    color: ${Color.WHITE};
    animation-delay: 1600ms;

    ${({ $isLongLabel }) =>
      $isLongLabel &&
      css`
        top: 35px;
        left: 250px;
      `};
  `,
}
