import { css } from 'styled-components'

import blackBg from 'assets/images/static-sale-bg-black.jpg'
import coralBg from 'assets/images/static-sale-bg-coral.jpg'
import violetBg from 'assets/images/static-sale-bg-purple.jpg'
import yellowBg from 'assets/images/static-sale-bg-yellow.jpg'

import { DynamicDiscountTheme } from 'root-constants'

export const ThemeSaleConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background-image: url(${coralBg});
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background-image: url(${yellowBg});
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background-image: url(${violetBg});
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background-image: url(${blackBg});
    color: #ffef87;
  `,
}
