import normalBgJa from 'assets/images/flow-belly/normal-ja.png'
import normalLegsBg from 'assets/images/flow-belly/normal-legs.png'
import normalMenopause from 'assets/images/flow-belly/normal-menopause.png'
import normalBg from 'assets/images/flow-belly/normal.png'
import obeseBgJa from 'assets/images/flow-belly/obese-ja.png'
import obeseLegsBg from 'assets/images/flow-belly/obese-legs.png'
import obeseMenopause from 'assets/images/flow-belly/obese-menopause.png'
import obeseBg from 'assets/images/flow-belly/obese.png'
import overweightBgJa from 'assets/images/flow-belly/overweight-ja.png'
import overweightLegsBg from 'assets/images/flow-belly/overweight-legs.png'
import overweightMenopause from 'assets/images/flow-belly/overweight-menopause.png'
import overweightBg from 'assets/images/flow-belly/overweight.png'
import underweightBgJa from 'assets/images/flow-belly/underweight-ja.png'
import underweightLegsBg from 'assets/images/flow-belly/underweight-legs.png'
import underweightMenopause from 'assets/images/flow-belly/underweight-menopause.png'
import underweightBg from 'assets/images/flow-belly/underweight.png'
import normalIcon from 'assets/images/sprite/normal.svg'
import riskIcon from 'assets/images/sprite/risk.svg'

import { Color } from 'root-constants'

export const QUESTION = 'Your Fitness Profile'

export const BMI_VALUES = {
  UNDERWEIGHT: 'underweight',
  NORMAL: 'normal',
  OVERWEIGHT: 'overweight',
  OBESE: 'obese',
}

export const BMI_OPTIONS = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: Color.PRIMARY,
    bgColor: '#eaf7ff',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightBg,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55d16f',
    bgColor: '#f0faee',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalBg,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#f90',
    bgColor: '#fff4e3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightBg,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#e74c3c',
    bgColor: '#fdedeb',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseBg,
  },
}
export const BMI_OPTIONS_LEGS = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: Color.PRIMARY,
    bgColor: '#eaf7ff',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightLegsBg,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55d16f',
    bgColor: '#f0faee',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalLegsBg,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#f90',
    bgColor: '#fff4e3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightLegsBg,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#e74c3c',
    bgColor: '#fdedeb',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseLegsBg,
  },
}
export const BMI_OPTIONS_JAPANESE = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: Color.PRIMARY,
    bgColor: '#eaf7ff',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightBgJa,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55d16f',
    bgColor: '#f0faee',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalBgJa,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#f90',
    bgColor: '#fff4e3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightBgJa,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#e74c3c',
    bgColor: '#fdedeb',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseBgJa,
  },
}
export const BMI_OPTIONS_MENOPAUSE = {
  [BMI_VALUES.UNDERWEIGHT]: {
    value: BMI_VALUES.UNDERWEIGHT,
    color: Color.PRIMARY,
    bgColor: '#eaf7ff',
    label: 'onboarding.fitnessProfile.underweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionUnderweight',
    icon: riskIcon,
    bgImage: underweightMenopause,
  },
  [BMI_VALUES.NORMAL]: {
    value: BMI_VALUES.NORMAL,
    color: '#55d16f',
    bgColor: '#f0faee',
    label: 'onboarding.fitnessProfile.normal',
    title: 'onboarding.fitnessProfile.healthyBmi',
    description: 'onboarding.fitnessProfile.healthyBmiDescription',
    icon: normalIcon,
    bgImage: normalMenopause,
  },
  [BMI_VALUES.OVERWEIGHT]: {
    value: BMI_VALUES.OVERWEIGHT,
    color: '#f90',
    bgColor: '#fff4e3',
    label: 'onboarding.fitnessProfile.overweight',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: overweightMenopause,
  },
  [BMI_VALUES.OBESE]: {
    value: BMI_VALUES.OBESE,
    color: '#e74c3c',
    bgColor: '#fdedeb',
    label: 'onboarding.fitnessProfile.obese',
    title: 'onboarding.fitnessProfile.highRisk',
    description: 'onboarding.fitnessProfile.highRiskDescriptionObese',
    icon: riskIcon,
    bgImage: obeseMenopause,
  },
}
