import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { Locale } from 'root-constants'

export const useHasPayPalButton = (): boolean => {
  const countryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  return useMemo(
    () =>
      countryCode.toLowerCase() === Locale.SPANISH ||
      language !== Locale.SPANISH,
    [countryCode, language],
  )
}
