import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useStripe } from '@stripe/react-stripe-js'
import { StripeErrorType } from '@stripe/stripe-js'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUUID,
  selectUserConfig,
  selectUserOnboardingEmail,
  selectUserStatus,
} from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createProductId } from 'helpers/createProductId'

import { PaymentSystem } from 'modules/purchase/constants'
import {
  logFailedPayment,
  logSuccessfulPayment,
} from 'modules/purchase/helpers/rootHelpers'
import { PURCHASE } from 'modules/purchase/redux/actions/common'
import {
  selectCreatedSubscriptionId,
  selectPaymentMethod,
} from 'modules/purchase/redux/selects/common'

import { browserHistory } from 'browser-history'
import { ONBOARDING_GOAL_EVENT, TimeInterval } from 'root-constants'

const CLIENT_SECRET = 'payment_intent_client_secret'
const REDIRECT_STATUS = 'redirect_status'
const PAYMENT_INTENT = 'payment_intent'

export const usePaymentElementStatus = () => {
  const stripe = useStripe()
  const paymentMethod = useSelector(selectPaymentMethod)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)
  const config = useSelector(selectUserConfig)
  const subscriptionId = useSelector(selectCreatedSubscriptionId)
  const accountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)
  const userStatus = useSelector(selectUserStatus)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    const handlePageReturn = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const clientSecret = urlParams.get(CLIENT_SECRET)

      if (!stripe || !clientSecret) return

      dispatch(startFetching(PURCHASE))

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

      if (!paymentIntent) return

      const productId = config?.trial_price_id
        ? createIntroOfferProductId({
            priceId: config?.price_id || '',
            trialPriceId: config?.trial_price_id || '',
            trialPeriodQuantity: Number(config?.trial_period),
          })
        : createProductId({
            periodName: config?.period_name as TimeInterval,
            periodQuantity: Number(config?.period_quantity),
            price: Number(config?.subscription_price),
          })

      const commonEventParams = {
        accountId,
        currency: paymentIntent.currency,
        email,
        isPersonalDataAllowed,
        productName: config?.product_name,
        screenId: config?.screen_id,
        stripeAccountName,
        uuid,
        productId,
        periodName: config?.period_name,
        periodQuantity: config?.period_quantity,
        screenName: config?.screen_name,
        trialPrice: config?.trial_price,
        trialPeriodDays: config?.trial_period,
        price: config?.subscription_price,
        priceId: config?.price_id,
        product_id: productId,
        trialPriceId: config?.trial_price_id,
        paymentMethod: config?.payment_method,
        discount_applied: config?.discount_applied,
        subscriptionId,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      }

      if (paymentIntent.last_payment_error) {
        dispatch(
          setErrorAction(
            paymentIntent.last_payment_error.message ||
              t('login.somethingWentWrongError'),
          ),
        )

        // @ts-ignore
        logFailedPayment({
          ...commonEventParams,
          accountName: stripeAccountName,
          paymentResponse: {
            type: paymentIntent.last_payment_error.type as StripeErrorType,
            code: paymentIntent.last_payment_error.code,
            message: paymentIntent.last_payment_error.message,
            decline_code: paymentIntent.last_payment_error.decline_code,
          },
          priceDetails: {
            price: Number(config?.subscription_price),
            trial: !!config?.trial_price,
            currency: paymentIntent.currency,
          },
        })
      }

      if (!paymentIntent.last_payment_error && userStatus?.hasSubscription) {
        dispatch(startFetching(PURCHASE))

        // @ts-ignore
        logSuccessfulPayment(commonEventParams)
      }

      if (!paymentIntent.last_payment_error && !userStatus?.hasSubscription) {
        dispatch(setErrorAction(t('login.somethingWentWrongError')))

        // @ts-ignore
        logFailedPayment({
          ...commonEventParams,
          accountName: stripeAccountName,
          priceDetails: {
            price: Number(config?.subscription_price),
            trial: !!config?.trial_price,
            currency: paymentIntent.currency,
          },
        })
      }

      dispatch(stopFetching(PURCHASE))

      urlParams.delete(CLIENT_SECRET)
      urlParams.delete(REDIRECT_STATUS)
      urlParams.delete(PAYMENT_INTENT)

      browserHistory.replace({
        pathname: window.location.pathname,
        search: urlParams.toString(),
      })
    }

    handlePageReturn()
  }, [
    dispatch,
    config,
    isStayFitGoal,
    paymentMethod,
    stripe,
    uuid,
    accountId,
    email,
    isPersonalDataAllowed,
    stripeAccountName,
    subscriptionId,
    userStatus,
    t,
  ])
}
