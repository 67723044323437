import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import likesIcon from 'assets/images/sprite/likes.svg'
import badgeIcon from 'assets/images/sprite/star-badge-red.svg'
import worldIcon from 'assets/images/sprite/world.svg'

import { StyledReviewsFreeOffer as S } from './FreeOfferReviewCard.styles'

type TProps = {
  className?: string
  icon: string
  date: string
  name: string
  image: string
  likesCount: number
  commentsCount: number
  text?: string
}

export const FreeOfferReviewCard: React.FC<TProps> = ({
  className = '',
  icon,
  date = 'today',
  name,
  image,
  likesCount,
  commentsCount,
  text,
}) => {
  const { t } = useTranslation()

  return (
    <S.ReviewFrame key={name} className={className}>
      <S.ReviewHeader>
        <S.Author src={icon} />
        <div>
          <S.Name>
            {t(name)}
            <SvgImage svg={badgeIcon} width={19} height={17} />
          </S.Name>
          <S.Time>
            {t(date)}
            <SvgImage svg={worldIcon} width={10} height={10} />
          </S.Time>
        </div>
      </S.ReviewHeader>
      {text && (
        <S.Text>
          <Trans i18nKey={text} />
        </S.Text>
      )}
      <S.Image src={image} />
      <S.Footer>
        <S.Likes>
          <SvgImage svg={likesIcon} width={38} height={18} />
          {likesCount}
        </S.Likes>
        <S.Comments>
          {commentsCount} {t('purchaseFreeOffer.comments')}
        </S.Comments>
      </S.Footer>
    </S.ReviewFrame>
  )
}
