import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledTenMillionWomen as S } from './TenMillionWomen.styles'
import { QUESTION } from './constants'

export const TenMillionWomen: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <S.Root>
      <S.Container isLarge>
        <S.TextBlock>
          <S.Title>
            <Trans i18nKey="onboarding.tenMillionWomen.title" />
          </S.Title>
          <div>
            <S.Subtitle>
              <Trans i18nKey="onboarding.tenMillionWomen.subtitle" />
            </S.Subtitle>
          </div>
        </S.TextBlock>
      </S.Container>

      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Root>
  )
}
