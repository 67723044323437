import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowAnimation from 'assets/animation/arrowAnimationNew.json'
import introSmallImg from 'assets/images/intro-new-small.png'
import introImg from 'assets/images/intro-new.png'

import { PageId } from 'page-constants'
import {
  MAIN_GOALS,
  MINIMUM_HEIGHT_BREAKPOINT,
  PagesSource,
} from 'root-constants'

import { StyledMainGoal as S } from './MainGoal2024.styles'
import { QUESTION } from './constants'

export const MainGoal2024: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const [answer, setAnswer] = useState<string>('')
  const [height, setHeight] = useState<number>(window.innerHeight)

  const animationRef = useRef<HTMLDivElement>(null)
  const { title, subtitle } = useDynamicOBConfig()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId: PageId.MAIN_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            { eventID: isPersonalDataAllowed ? uuid : '' },
          )
        window.pintrk && window.pintrk('track', 'custom')
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleChange([value], handleContinue)
        setAnswer(value)
        setIsAnswersDisabled(true)
      },
    }),
    [
      isPersonalDataAllowed,
      uuid,
      pageId,
      setIsAnswersDisabled,
      handleContinue,
      handleChange,
      dispatch,
    ],
  )

  const imgSrc = useMemo(
    () => (height > MINIMUM_HEIGHT_BREAKPOINT ? introImg : introSmallImg),
    [height],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => setHeight(window.innerHeight))

    return () => {
      window.addEventListener('resize', () => setHeight(window.innerHeight))
    }
  }, [])

  return (
    <S.Root>
      <S.ImageContainer>
        <S.Subtitle>
          {subtitle || t`onboarding.mainGoal.subtitleNew`}
        </S.Subtitle>
        <S.Image src={imgSrc} />
      </S.ImageContainer>

      <Container>
        <S.Title>{title || t`onboarding.mainGoal.title`}</S.Title>
        <S.ActionCall>{t`onboarding.mainGoal.questionNew`}</S.ActionCall>
        <S.Arrow ref={animationRef} />
        <S.Actions>
          <S.StyledOption
            {...optionProps}
            withoutMargin
            value={MAIN_GOALS.STAY_FIT}
            disabled={isAnswersDisabled}
            $isChecked={answer === MAIN_GOALS.STAY_FIT}
          >
            <S.Action>{t`onboarding.mainGoal.stayFit`}</S.Action>
          </S.StyledOption>
          <S.StyledOption
            {...optionProps}
            withoutMargin
            value={MAIN_GOALS.LOSE_WEIGHT}
            disabled={isAnswersDisabled}
            $isChecked={answer === MAIN_GOALS.LOSE_WEIGHT}
          >
            <S.Action>{t`onboarding.mainGoal.loseWeight`}</S.Action>
          </S.StyledOption>
        </S.Actions>
      </Container>
      <S.Terms>
        <p>{t`footer.disclaimer`}</p>
        <TermsOfUseLink source={PagesSource.LANDING} />
        <S.Separator />
        <PrivacyPolicyLink source={PagesSource.LANDING} />
        <p>
          {t('footer.allRightsReserved', {
            year: new Date().getFullYear(),
          })}
        </p>
      </S.Terms>
    </S.Root>
  )
}
