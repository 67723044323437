import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import whiteLogo from 'assets/images/dancebit-logo-white.png'

import { Cohort, PagesSource, SUPPORT_LINK } from 'root-constants'

import { StyledHeader as S } from './HeaderV2.styles'

export const HeaderV2: React.FC = () => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const cohortToUse = parentCohort || cohort

  const isBellyRefFlow = useMemo(() => cohortToUse === Cohort.DB_BELLY_REF, [
    cohortToUse,
  ])

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  return (
    <S.Root>
      <S.WhiteLogo src={whiteLogo} alt="" />

      <S.HeaderButtons>
        {!isBellyRefFlow && (
          <S.SupportAction
            href={SUPPORT_LINK}
            onClick={() => eventLogger.logNeedHelpClicked()}
          >
            {t`actions.needHelp`}
          </S.SupportAction>
        )}
        <S.BurgerWhite type="button" onClick={toggleMenuVisibility}>
          <span />
          <span />
          <span />
        </S.BurgerWhite>
      </S.HeaderButtons>
      <S.Backdrop
        $isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu $isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink source={PagesSource.LANDING} />
        </S.StyledMenuLinkContainer>
        {isBellyRefFlow && (
          <S.StyledMenuLinkContainer>
            <ContactWithSupport
              source={PagesSource.LANDING}
              text={t(`actions.needHelp`)}
            />
          </S.StyledMenuLinkContainer>
        )}
      </S.Menu>
    </S.Root>
  )
}
