import { IRequestOptions, IResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import {
  IBindUserResponseRaw,
  IGetUserStatusResponseRaw,
} from 'models/user.model'
import {
  IGetUserInfoResponseRaw,
  IPredictedLtvResponseRaw,
} from 'models/userInfo.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants'

export class UserApi {
  private readonly baseUrlV2 = 'v2/leads'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  bindUser(payload: {
    token: string
    uuid: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token: payload.token,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>(
      `${this.baseUrlV2}/${payload.uuid}/${APP_NAME}/signup`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<string>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}`,
      options,
    )
  }

  getUUID({
    cohort,
    appId,
    locale,
    promoCode = '',
    isTestEnvironment,
    utm,
  }: {
    cohort: string
    isTestEnvironment: boolean
    appId: string
    locale: string
    promoCode?: string
    utm: {
      [key: string]: string
    }
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        locale,
        app_id: appId,
        app_name: APP_NAME,
        utm,
        is_test_environment: isTestEnvironment,
        promo_code: promoCode,
      },
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      this.baseUrlV2,
      options,
    )
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/facebook`,
      options,
    )
  }

  saveUserAnswers({
    uuid,
    answers,
    isFinished = false,
    isSkipped = false,
  }: {
    uuid: string
    answers: TAnswers
    isFinished?: boolean
    isSkipped?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: answers,
        version: '2.0',
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/quiz`,
      options,
    )
  }

  saveUserConfig({
    uuid,
    config,
  }: {
    uuid: string
    config: Record<string, string | string[] | boolean | number>
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        config,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/config`,
      options,
    )
  }

  saveUserEmail({
    uuid,
    email,
    consentRequired,
  }: {
    uuid: string
    email: string
    consentRequired?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email,
        consent_required: consentRequired,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/email`,
      options,
    )
  }

  saveUserEmailConsent({
    uuid,
    consented,
  }: {
    uuid: string
    consented: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        consented,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/email/consent`,
      options,
    )
  }

  getUserInfo(params: {
    uuid: string
    secret: string
  }): Promise<IResponseResult<IGetUserInfoResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params,
    }

    return this.api.makeRequest<IGetUserInfoResponseRaw>(
      `v1/manage/info`,
      options,
    )
  }

  savePlanAdditions({
    uuid,
    planAdditions,
  }: {
    uuid: string
    planAdditions: string[]
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { addons: planAdditions },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/addons`,
      options,
    )
  }

  getUserUUIDAndSecret(
    token: string,
  ): Promise<IResponseResult<{ secret: string; uuid: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { token },
    }

    return this.api.makeRequest<{ secret: string; uuid: string }>(
      `v1/manage/login`,
      options,
    )
  }

  getAutologinToken(
    uuid: string,
    // eslint-disable-next-line camelcase
  ): Promise<IResponseResult<{ status: boolean; custom_token: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid,
        app_name: APP_NAME,
      },
    }

    return this.api.makeRequest<never>(`v1/flow/login`, options)
  }

  saveUserTTCLID({
    ttclid,
    uuid,
  }: {
    ttclid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ttclid },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/tiktok`, options)
  }

  saveUserSCCID({
    sccid,
    scid,
    uuid,
  }: {
    sccid: string
    scid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: {
        sccid,
        uuid_c1: scid,
      },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/snapchat`, options)
  }

  getPredictedLtv({
    uuid,
  }: {
    uuid: string
  }): Promise<IResponseResult<IPredictedLtvResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IPredictedLtvResponseRaw>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/predicted_ltv`,
      options,
    )
  }

  addProductToCart({
    uuid,
    productName,
  }: {
    uuid: string
    productName: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        product_name: productName,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/cart/add`,
      options,
    )
  }

  removeProductFromCart({
    uuid,
    productName,
  }: {
    uuid: string
    productName: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        product_name: productName,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/cart/remove`,
      options,
    )
  }
}
