import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

const INPUT_PLACEHOLDER_COLOR = '#81848c'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '50px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: INPUT_PLACEHOLDER_COLOR,
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

export const commonInputStyles = css`
  color: ${Color.DARK};
  height: 50px;
  background-color: ${Color.LIGHT};
  line-height: 50px;
  border-radius: 16px;
  border: 1px solid ${Color.GRAY};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border-color: ${Color.ERROR};
  }
`

export const StyledCreditCardPaymentForm = {
  Wrapper: styled.div``,
  Form: styled.form``,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `,
  Label: styled.p`
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles}
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles}
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles}
  `,
  CardCvcElementIcon: styled.img`
    position: absolute;
    top: 38px;
    right: 16px;
    max-width: 30px;
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    width: 100%;
    margin-bottom: 30px;
    outline: none;
    box-shadow: none;
    font-weight: 500;

    &::placeholder {
      color: ${INPUT_PLACEHOLDER_COLOR};
      font-weight: 400;
    }
  `,
  Button: styled(Button)`
    max-width: 320px;
  `,
}
