import styled from 'styled-components'

import { ButtonJa } from 'components/ButtonJa'
import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { PhotoResultJa } from 'modules/purchase/components/PhotoResultJa'

import blueCheck from 'assets/images/check-icon-square.svg'
import cardBgSmall from 'assets/images/image-with-wallet-small.png'

import { Color } from 'root-constants'

export const StyledPurchaseCancelOfferRef = {
  Wrapper: styled.div`
    background: linear-gradient(173deg, #fbf3ff 16.12%, #ecf6ff 95.61%);
  `,
  Container: styled(Container)`
    &:last-child {
      padding-bottom: 20px;
    }
  `,
  AppDescription: styled.h1`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 0 auto 40px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 24px;
    color: #e43a91;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 12px;
    padding-left: 40px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  Button: styled(ButtonJa)`
    max-width: 328px;
  `,
  FeaturedBlockContainer: styled.div`
    margin: 50px auto 40px;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  RefundCardSmall: styled.div`
    position: relative;
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(124deg, #a6f1df 11.31%, #50b5c3 77.01%);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    min-height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: 50px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${cardBgSmall});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  PhotoResult: styled(PhotoResultJa)`
    padding: 0;
    margin: 0 auto 50px;
  `,
  FAQList: styled(FAQList)`
    h2 {
      color: #e43a91;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    h3 {
      color: ${Color.PRIMARY};
      font-size: 17px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 40px;
    }
  `,
  ButtonText: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 8px;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
