import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import { CDN_FOLDER_LINK, MeasurementSystem } from 'root-constants'

import { StyledLoseWeightraph as S } from './LoseWeightGraph.styles'
import { ANIMATION_PATH } from './constants'

export const LoseWeightGraph: React.FC = () => {
  const { t } = useTranslation()
  const [graphContentRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
    animationName: 'loseWeightGraph',
  })
  const answers = useSelector(selectAnswers)

  const [percentageText, tipBefore, tipAfter] = useMemo(() => {
    const currentWeight = answers?.currentWeight || '55'
    const goalWeight = answers?.goalWeight || '50'
    const unit =
      answers?.measurementSystem === MeasurementSystem.IMPERIAL
        ? t('commonComponents.imperial')
        : t('commonComponents.metric')
    const percentage = `${
      100 -
      Math.round(
        (parseFloat(goalWeight as string) /
          parseFloat(currentWeight as string)) *
          100,
      )
    }`

    return [percentage, `${currentWeight} ${unit}`, `${goalWeight} ${unit}`]
  }, [
    answers?.currentWeight,
    answers?.goalWeight,
    answers?.measurementSystem,
    t,
  ])
  return (
    <S.Wrapper>
      <S.Title>
        <Trans
          components={[<br />, <strong />]}
          values={{ percentageText }}
          i18nKey="result.graph.title"
        />
      </S.Title>
      <S.GraphContent>
        <div ref={graphContentRef} />
        <S.TipNow>{tipBefore}</S.TipNow>
        <S.TipAfter>{tipAfter}</S.TipAfter>
      </S.GraphContent>
      <S.Labels>
        <S.Label>{t`result.graph.label1`}</S.Label>
        <S.Label>{t`result.graph.label2`}</S.Label>
      </S.Labels>
    </S.Wrapper>
  )
}
