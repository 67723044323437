import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BELLY_ANIMATION_PATH, CDN_FOLDER_LINK } from 'root-constants'

import { StyledDancingVsExercises as S } from './DancingVsExercisesBelly.styles'
import { QUESTION } from './constants'

export const DancingVsExercisesBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)

  const language = useSelector(selectLanguage)

  const { imageUrls } = useDynamicOBConfig()

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}${BELLY_ANIMATION_PATH}_${language}.json`,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [language])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={8}>
            {t`onboarding.dancingVsExercises.question`}
          </PageTitle>
          <S.AnimationContainer>
            <S.AnimationTitle>
              {t`onboarding.dancingVsExercises.animationTitle`}
            </S.AnimationTitle>
            <S.Animation>
              {imageUrls?.length ? (
                <img src={imageUrls[0]} alt="Dancing vs Exercises" />
              ) : (
                <div ref={animationRef} />
              )}
            </S.Animation>
          </S.AnimationContainer>
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleNextClick}>
        {t`actions.gotIt`}
      </NavigationButtonBelly>
    </>
  )
}
