import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div<{ $margin?: string }>`
    position: relative;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.7);
    padding: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Text: styled.p`
    padding-bottom: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY_80};

    &:last-child {
      padding-bottom: 0;
    }

    a {
      font-weight: 600;
      color: ${Color.PRIMARY};
      white-space: nowrap;
    }
  `,
  SecureBadge: styled.img`
    width: 192px;
    padding-top: 16px;
  `,
}
