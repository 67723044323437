import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledHelpAchieveResults = {
  Root: styled.div<{ $isNewAdultFlow: boolean }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        height: calc(100% - 50px);
        ${StyledNewAdultContainer}
      `}
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
  `,
  TextBlock: styled.div`
    position: absolute;
    width: 100%;
    height: -webkit-fill-available;
    max-width: 375px;
    left: 50%;
    top: 250px;
    transform: translateX(-50%);
    display: flex;
    padding: 24px 0 0 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    border-radius: 24px 24px 0px 0px;
    background: ${Color.WHITE};
  `,
  Container: styled(Container)<{ $isNewAdultFlow: boolean }>`
    padding: 0 16px;
    background: ${Color.WHITE};
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: -23px;
      height: 24px;
      width: 100%;
      background: ${Color.WHITE};
      border-radius: 24px 24px 0 0;
    }

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        height: -webkit-fill-available;
      `}
  `,
  Description: styled.p`
    color: ${Color.GRAY_80};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;

    strong {
      color: ${Color.DARK};
      font-weight: 600;
    }
  `,
  Image: styled.img`
    width: 375px;
    height: 271px;
    min-height: 271px;
  `,
  Title: styled(PageTitle)`
    text-align: left;
  `,
  Subtitle: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    text-transform: uppercase;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{
    $isNewAdultFlow: boolean
  }>`
    position: absolute;
  `,
}
