import styled from 'styled-components'

import { Option } from 'components/Option'

import {
  Color,
  MINIMUM_HEIGHT_BREAKPOINT,
  MediaBreakpoint,
} from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledMainGoal = {
  Root: styled.div`
    position: relative;
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  Image: styled.img`
    width: 100%;
    margin: 0 auto 16px;

    @media screen and (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      margin: 0 auto 24px;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Subtitle: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 8px 0;
    background-color: rgba(117, 42, 221, 0.7);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
    text-align: center;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media screen and (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      padding: 12px 0;
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    max-width: 260px;
    margin: 0 auto 12px;

    @media screen and (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      margin: 0 auto 16px;
    }
  `,
  ActionCall: styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${Color.DISABLED};
    margin-bottom: 4px;
    position: relative;
    cursor: pointer;

    @media screen and (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      margin-bottom: 8px;
    }
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 12px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 618px;
      margin: 0 auto 16px auto;
    }

    @media screen and (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      margin-bottom: 16px;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex: 1;
    border-radius: 10px;
    border: 2px solid ${Color.PRIMARY};
    background-color: ${({ $isChecked }) =>
      $isChecked ? Color.WHITE : Color.PRIMARY};
    color: ${({ $isChecked }) => ($isChecked ? Color.PRIMARY : Color.WHITE)};
    font-weight: 600;
    line-height: 20px;

    &:first-child {
      margin-right: 12px;
    }
  `,
  Action: styled.div`
    width: 100%;
    min-width: 156px;
    min-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
  `,
  Terms: styled.div`
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
