import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import dancingStyleIntroNewAgeFlow from 'assets/images/dancing-style-intro-new-age-flow.png'

import { MediaBreakpoint } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDifferentDanceStyles = {
  Root: styled.div`
    position: relative;
    background-image: url(${dancingStyleIntroNewAgeFlow});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100% - 50px);

    ${StyledNewAdultContainer}

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-size: contain;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 8px;

    strong {
      color: #456d98;
    }
  `,
  Subtitle: styled.h3`
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100% - 90px);
    padding-top: 16px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: transparent;
    border: none;
  `,
}
