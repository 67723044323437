import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledContainer as S } from './ContainerBeforePinnedButton.styles'

type TProps = {
  className?: string
  children?: React.ReactNode
}

export const ContainerBeforePinnedButton: React.FC<TProps> = ({
  children,
  className = '',
}) => {
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  return (
    <S.Container className={className} $isJapanese={isJapaneseFlow}>
      {children}
    </S.Container>
  )
}
