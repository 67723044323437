export const REVIEWS = [
  {
    name: 'purchaseNew.reviews.first.name',
    title: 'purchaseNew.reviews.first.title',
    date: 'purchaseNew.reviews.first.date',
    text: 'purchaseNew.reviews.first.text',
  },
  {
    name: 'purchaseNew.reviews.second.name',
    title: 'purchaseNew.reviews.second.title',
    date: 'purchaseNew.reviews.second.date',
    text: 'purchaseNew.reviews.second.text',
  },
  {
    name: 'purchaseNew.reviews.third.name',
    title: 'purchaseNew.reviews.third.title',
    date: 'purchaseNew.reviews.third.date',
    text: 'purchaseNew.reviews.third.text',
  },
  {
    name: 'purchaseNew.reviews.fourth.name',
    title: 'purchaseNew.reviews.fourth.title',
    date: 'purchaseNew.reviews.fourth.date',
    text: 'purchaseNew.reviews.fourth.text',
  },
]
