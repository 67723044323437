import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledEmailNoOnboarding = {
  Wrapper: styled(Container)``,
  Title: styled.header`
    padding: 40px 0 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Input: styled(InputWithFloatPlaceholder)`
    height: 56px;
    margin-bottom: 16px;
    border: 1px solid #eaeaec;

    & + span {
      font-weight: 400;
      color: #b8bbc1;
    }
  `,
  Disclaimer: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #b8bbc1;
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 10px;
  `,
  Button: styled(Button)`
    position: relative;
    max-width: 328px;
    margin-bottom: 32px;
  `,
}
