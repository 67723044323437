import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledPhysicalBuild as S } from './PhysicalBuild.styles'
import {
  OPTION_VALUES,
  OPTION_VALUES_ADULT,
  OPTION_VALUES_JAPANESE,
  OPTION_VALUES_LEGS,
  OPTION_VALUES_MENOPAUSE,
  QUESTION,
} from './constants'

export const PhysicalBuild: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { optionData } = useDynamicOBConfig()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { isLegsFlow, isMenopauseFlow, isNewAdultFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isLegsFlow) return OPTION_VALUES_LEGS

    if (isMenopauseFlow) return OPTION_VALUES_MENOPAUSE

    if (isJapaneseFlow) return OPTION_VALUES_JAPANESE

    if (isNewAdultFlow) return OPTION_VALUES_ADULT

    return OPTION_VALUES
  }, [isJapaneseFlow, isLegsFlow, isMenopauseFlow, isNewAdultFlow, optionData])

  const title = useMemo(() => {
    if (isLegsFlow) return t('onboarding.physicalBuild.titleLegs')

    if (isNewAdultFlow) return t('onboarding.physicalBuildAdult.question')

    return t('onboarding.physicalBuild.title')
  }, [isLegsFlow, isNewAdultFlow, t])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerWithIconTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerWithIconTheme.DANCEBIT_JAPANESE
    }

    return answerWithIconTheme.DANCEBIT
  })()

  return (
    <S.Root $isAdult={isNewAdultFlow}>
      <S.Container isLarge $isJapanese={isJapaneseFlow}>
        <PageTitle marginBottom={24}>{title}</PageTitle>
        {options.map(({ value, text, imageUrl }) => (
          <AnswerWithIcon
            {...optionProps}
            disabled={isAnswersDisabled}
            theme={theme}
            margin="0 0 16px 0"
            borderRadius="16px"
            iconWidth={isMenopauseFlow ? '152px' : '100px'}
            key={value}
            value={value}
            iconAlignSelf="flex-start"
            iconSrc={imageUrl}
          >
            <Trans>{text}</Trans>
          </AnswerWithIcon>
        ))}
      </S.Container>
    </S.Root>
  )
}
