import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'

import { StyledTenMillionPeople as S } from './TenMillionPeopleMenopause.styles'
import { QUESTION } from './constants'

export const TenMillionPeopleMenopause: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <S.Root>
      <S.Container isLarge>
        <S.Title>
          <Trans i18nKey="onboarding.tenMillionPeople.questionWomen" />
        </S.Title>
        <div>
          <S.Subtitle>
            <Trans
              i18nKey="onboarding.tenMillionPeople.designedToMeet"
              values={{
                age: answers?.[PageId.CHOOSE_AGE],
              }}
            />
          </S.Subtitle>
          <Button type="button" onClick={handleClick}>
            {t`actions.continue`}
          </Button>
        </div>
      </S.Container>
    </S.Root>
  )
}
