import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContactWithSupport } from 'components/ContactWithSupport'

import secureBadge from 'assets/images/security-badge-upgrade.png'

import { PagesSource } from 'root-constants'

import { StyledSecurityInfoUpgrade as S } from './SecurityInfoUpgrade.styles'

export const SecurityInfoUpgrade: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.SecureBadge src={secureBadge} alt="Secure badge" />
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.SafeText>{t`purchase1.securityInfo.textFirst`}</S.SafeText>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.SecureText>{t`purchase1.securityInfo.textSecond`}</S.SecureText>
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>

      <S.Text>
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupport')}
        />
      </S.Text>
    </S.Wrapper>
  )
}
