import { PageId } from 'page-constants'

export const PAGES_WITHOUT_BACK_BUTTON = [
  PageId.PERSONAL_HISTORY,
  PageId.EMAIL_CONSENT,
  PageId.ENTER_EMAIL_NO_ONBOARDING,
  PageId.PREPARE_PLAN,
  PageId.SOCIAL_PROOF,
  PageId.NICE_TO_MEET,
  PageId.FORGET_PLANKS,
  PageId.DANCE_VS_EXERCISES,
  PageId.FITNESS_PROFILE,
  PageId.REACH,
  PageId.DANCING_STYLES_INTRO,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
  PageId.PURCHASE_CANCEL_OFFER_SOMATIC,
  PageId.PURCHASE_FREE_OFFER,
  PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  PageId.PURCHASE_CANCEL_OFFER_FREE,
  PageId.PURCHASEV2,
  PageId.UPSELL,
  PageId.UPSELL_CANCEL_OFFER,
  PageId.DOWNLOAD,
  PageId.UPGRADE_LIFE,
  PageId.TEN_MILLION_PEOPLE,
  PageId.PREPAYWALL,
  PageId.DESIGN_NEW_LIFE,
  PageId.WELCOME,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.PLAN_IS_READY,
  PageId.NAME,
  PageId.ENTER_EMAIL,
  PageId.ACHIEVE_YOUR_GOAL,
  PageId.STAY_CONSISTENT,
  PageId.BETTER_RESULT,
  PageId.GRIP_OF_STRESS,
  PageId.SUGAR_CRAVINGS,
  PageId.WELCOME_INFLUENCER,
  PageId.PURCHASE_CANCEL_OFFER_REF,
]
