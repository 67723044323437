import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledPeopleAsk as S } from './PeopleAsk.styles'
import { PEOPLE_ASK } from './constants'

export const PeopleAsk: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>{t('purchaseNoOnboarding.peopleAsk.title')}</S.Title>
      {PEOPLE_ASK.map(({ question, answer }) => (
        <S.Card key={question}>
          <S.Question>{t(question)}</S.Question>
          <S.Answer>
            {t(answer)}
            <S.DashedDecoration />
          </S.Answer>
        </S.Card>
      ))}
    </S.Root>
  )
}
