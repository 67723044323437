import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentFlow,
  PaymentMethodInfo,
  PaymentMethodType,
  Primer,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import {
  resetErrorAction,
  setErrorAction,
  setIsPrimerRetryProcessing,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { updateUserConfigAction } from 'root-redux/actions/user'
import {
  selectError,
  selectIsPrimerRetryProcessing,
} from 'root-redux/selects/common'

import { useCancelPrimerDiscount } from 'hooks/useCancelPrimerDiscount'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'

import {
  PaymentMethod,
  PrimerPaymentMethodsMap,
} from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { useInitPrimerCardForm } from 'modules/purchase/hooks/useInitPrimerCardForm'
import { useInitPrimerPayPal } from 'modules/purchase/hooks/useInitPrimerPayPal'
import { usePrimerAnalytics } from 'modules/purchase/hooks/usePrimerAnalytics'
import {
  PURCHASE,
  SET_PAYMENT_FORM_IS_LOADING,
  getPrimerClientSessionTokenAction,
  primerPurchaseAction,
  primerResumePurchaseAction,
  setIsFirstPaymentRetryPassedAction,
  setPaymentMethodAction,
  setPrimerClientSessionTokenAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectIsFirstPaymentRetryPassed,
  selectPrimerClientSessionToken,
  selectSubscriptionLookupKey,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import tooltipInfoImage from 'assets/images/cvv-info.png'
import infoIcon from 'assets/images/information-icon.svg'
import paymentMethodImg from 'assets/images/payment-method.png'

import {
  CardPaymentFieldName,
  Color,
  INITIAL_PRIMER_CONFIG,
  PRIMER_CARD_CVC_ID,
  PRIMER_CARD_EXPIRY_ID,
  PRIMER_CARD_NUMBER_ID,
  PRIMER_FORM_COMMON_STYLES_ABC_TEST,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_PAY_PAL_ID,
} from 'root-constants'

import { StyledPrimerPaymentDarkForm as S } from './PrimerPaymentDarkForm.styles'

type TProps = {
  showPayPalButton?: boolean
  showCardForm?: boolean
}

export const PrimerPaymentDarkForm: FC<TProps> = ({
  showPayPalButton = false,
  showCardForm = true,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const primerRef = useRef<PrimerHeadlessCheckout | null>(null)
  const primerFormRef = useRef<HTMLFormElement>(null)

  const isFirstPaymentRetryPassed = useSelector(selectIsFirstPaymentRetryPassed)
  const priceLookupKey = useSelector(selectSubscriptionLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)
  const error = useSelector(selectError)

  const { isBellyFlow } = useCohortInfo()

  const hasPayPalButton = useHasPayPalButton()

  const [hasPayPal, setHasPayPal] = useState(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const { logPaymentStarted, logPaypalPaymentStarted } = usePrimerAnalytics()

  const {
    initCardForm,
    cardManagerRef,
    errors,
    isFormValid,
  } = useInitPrimerCardForm({
    primerRef,
    formStyles: PRIMER_FORM_COMMON_STYLES_ABC_TEST,
    shouldAutofocus: true,
  })

  const { initPayPalButton } = useInitPrimerPayPal({
    primerRef,
  })

  const { cancelDiscount } = useCancelPrimerDiscount()

  useLayoutEffect(() => {
    if (!primerRef.current) {
      dispatch(startFetching(SET_PAYMENT_FORM_IS_LOADING))
    }

    dispatch(stopFetching(SET_PAYMENT_FORM_IS_LOADING))
    cardManagerRef.current?.reset()
  }, [cardManagerRef, dispatch])

  useEffect(() => {
    if (priceLookupKey) {
      dispatch(getPrimerClientSessionTokenAction())
    }

    return () => {
      dispatch(setPrimerClientSessionTokenAction(''))
      dispatch(updateUserConfigAction({ payment_id: '' }))
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }
  }, [dispatch, priceLookupKey])

  const retryPaymentCallback = useCallback(() => {
    if (!isFirstPaymentRetryPassed) {
      dispatch(setIsFirstPaymentRetryPassedAction(true))

      const submit = new Event('submit', {
        cancelable: true,
        bubbles: true,
      })

      primerFormRef.current?.dispatchEvent(submit)
      return
    }

    dispatch(setErrorAction(error || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
    dispatch(setIsPrimerRetryProcessing(false))
    dispatch(stopFetching(PURCHASE))
  }, [dispatch, error, isFirstPaymentRetryPassed, t])

  const initPrimer = useCallback(async () => {
    const headless = await Primer.createHeadless(primerClientSessionToken)
    primerRef.current = headless

    headless.configure({
      ...INITIAL_PRIMER_CONFIG,
      paymentHandling: 'MANUAL',
      paypal: {
        paymentFlow: PaymentFlow.PREFER_VAULT,
      },

      onAvailablePaymentMethodsLoad(paymentMethods: PaymentMethodInfo[]) {
        paymentMethods.forEach(({ type }) => {
          if (type === PaymentMethodType.PAYPAL && hasPayPalButton) {
            initPayPalButton()
            setHasPayPal(true)
          }

          if (type === PaymentMethodType.PAYMENT_CARD) {
            initCardForm()
          }
        })
      },
      onTokenizeSuccess(token, handler) {
        const currentMethod =
          PrimerPaymentMethodsMap[token.paymentInstrumentType]

        currentMethod === PaymentMethod.PAYPAL && logPaypalPaymentStarted()

        dispatch(
          primerPurchaseAction(token.token, handler, retryPaymentCallback),
        )
      },
      onTokenizeError(clientError) {
        dispatch(stopFetching(PURCHASE))
        dispatch(
          setErrorAction(clientError || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)),
        )
      },
      onResumeSuccess({ paymentId, resumeToken }, handler) {
        dispatch(
          primerResumePurchaseAction(
            paymentId,
            resumeToken,
            handler,
            retryPaymentCallback,
          ),
        )
      },
    })

    await headless.start()
  }, [
    primerClientSessionToken,
    hasPayPalButton,
    initPayPalButton,
    initCardForm,
    logPaypalPaymentStarted,
    dispatch,
    retryPaymentCallback,
    t,
  ])

  useEffect(() => {
    if (!primerClientSessionToken || primerRef.current) {
      return
    }

    initPrimer()
  }, [initPrimer, primerClientSessionToken])

  const handleSubmitClick = useCallback(() => {
    if (isButtonDisabled) return

    eventLogger.logPaymentMethodSelected(PaymentMethod.CREDIT_CARD)
    dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    logPaymentStarted(PaymentMethod.CREDIT_CARD)
    dispatch(startFetching(PURCHASE))
  }, [dispatch, isButtonDisabled, logPaymentStarted])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      if (isButtonDisabled) return

      event.preventDefault()
      await cancelDiscount()
      dispatch(resetErrorAction())

      if (!isFormValid || !cardManagerRef.current) {
        dispatch(stopFetching(PURCHASE))
        return
      }

      setIsButtonDisabled(true)
      const { valid } = await cardManagerRef.current.validate()

      if (valid) {
        await cardManagerRef.current.submit()
        setIsButtonDisabled(false)
      } else {
        dispatch(stopFetching(PURCHASE))
        setIsButtonDisabled(false)
      }
    },
    [cancelDiscount, cardManagerRef, dispatch, isButtonDisabled, isFormValid],
  )

  return (
    <div>
      <S.MethodContainer $isVisible={showCardForm}>
        <S.Form ref={primerFormRef} onSubmit={handleSubmit}>
          <S.PaymentMethodImageContainer>
            <S.PaymentMethodImage src={paymentMethodImg} />
          </S.PaymentMethodImageContainer>
          <S.Wrapper>
            <S.InputContainer
              $isInvalid={
                !!errors[CardPaymentFieldName.NUMBER].error &&
                !errors[CardPaymentFieldName.NUMBER].isFocused
              }
              data-placeholder={t`purchase1.paymentForm.cardNumber`}
            >
              <S.Input
                id={PRIMER_CARD_NUMBER_ID}
                $isInvalid={
                  !!errors[CardPaymentFieldName.NUMBER].error &&
                  !errors[CardPaymentFieldName.NUMBER].isFocused
                }
              />
            </S.InputContainer>

            <S.CvvExpiryInputContainer>
              <S.CardExpiryContainer>
                <S.InputContainer
                  $isInvalid={
                    !!errors[CardPaymentFieldName.EXPIRY].error &&
                    !errors[CardPaymentFieldName.EXPIRY].isFocused
                  }
                  data-placeholder={t`purchase1.paymentForm.expiryDate`}
                >
                  <S.Input
                    $isInvalid={
                      !!errors[CardPaymentFieldName.EXPIRY].error &&
                      !errors[CardPaymentFieldName.EXPIRY].isFocused
                    }
                    id={PRIMER_CARD_EXPIRY_ID}
                  />
                </S.InputContainer>
              </S.CardExpiryContainer>

              <S.CardCvcContainer>
                {!(
                  !!errors[CardPaymentFieldName.CVC].error &&
                  !errors[CardPaymentFieldName.CVC].isFocused
                ) && (
                  <S.InfoIcon
                    src={infoIcon}
                    onClick={() => setIsTooltipVisible((prev) => !prev)}
                    alt="info"
                  />
                )}
                {isTooltipVisible && (
                  <S.TooltipContainer>
                    <S.Tooltip src={tooltipInfoImage} />
                  </S.TooltipContainer>
                )}

                <S.InputContainer
                  $isInvalid={
                    !!errors[CardPaymentFieldName.CVC].error &&
                    !errors[CardPaymentFieldName.CVC].isFocused
                  }
                  data-placeholder={t`purchase1.paymentForm.cvv`}
                >
                  <S.Input
                    $isInvalid={
                      !!errors[CardPaymentFieldName.CVC].error &&
                      !errors[CardPaymentFieldName.CVC].isFocused
                    }
                    id={PRIMER_CARD_CVC_ID}
                  />
                </S.InputContainer>
              </S.CardCvcContainer>
            </S.CvvExpiryInputContainer>
          </S.Wrapper>
          <S.SubmitButton
            type="submit"
            disabled={
              !isFormValid || isPrimerRetryProcessing || isButtonDisabled
            }
            disabledBackgroundColor={
              isFormValid ? Color.PRIMARY : Color.GRAY_40
            }
            onClick={handleSubmitClick}
          >
            {t`actions.continue`}
          </S.SubmitButton>
        </S.Form>
      </S.MethodContainer>

      <S.MethodContainer $isVisible={showPayPalButton}>
        <S.ButtonTitle>
          <Trans i18nKey="purchaseRef.checkout.advantages" />
        </S.ButtonTitle>
        <S.PayPalButton
          id={PRIMER_PAY_PAL_ID}
          $isVisible={hasPayPal}
          $isBellyFlow={isBellyFlow}
        />
      </S.MethodContainer>
    </div>
  )
}
