import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import dayjs from 'dayjs'
import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledConfident as S } from './Confident.styles'
import { OPTION_VALUES, OPTION_VALUES_JAPANESE, QUESTION } from './constants'

export const Confident: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)

  const { optionData } = useDynamicOBConfig()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const { isNewAdultFlow } = useCohortInfo()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const {
    goalWeight,
    goalDate,
    goalMonthAndDay,
    goalMonthAndYear,
  } = useEventWeightInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const unit = useMemo(() => t(`commonComponents.${measurementSystem}`), [
    measurementSystem,
    t,
  ])

  const isPredictableYearCurrent = useMemo(
    () => dayjs(goalDate).year() > dayjs().year(),
    [goalDate],
  )

  const goalDateInTitle = useMemo(
    () => (isPredictableYearCurrent ? goalMonthAndYear : goalMonthAndDay),
    [goalMonthAndDay, goalMonthAndYear, isPredictableYearCurrent],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isJapaneseFlow || isNewAdultFlow) return OPTION_VALUES_JAPANESE

    return OPTION_VALUES
  }, [isJapaneseFlow, isNewAdultFlow, optionData])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={24}>
        <Trans
          i18nKey="onboarding.confident.title"
          values={{
            weight: goalWeight,
            date: goalDateInTitle,
            unit,
          }}
        />
      </PageTitle>

      {options.map(({ text, value, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          key={value}
          value={value}
          iconSrc={imageUrl}
          theme={theme}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          height="80px"
          padding="16px 7px 16px 16px"
          maxWidth="345px"
          iconAlignSelf="center"
          iconWidth="40px"
          margin="0 0 16px 0"
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
