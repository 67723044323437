import { useCallback, useEffect, useRef, useState } from 'react'

import { easeInOutQuadWithGap } from 'helpers/animationFunctions'

const frameDuration = 1000 / 60
export const useProgressAnimation = (animation: {
  duration: number
  from?: number
  to?: number
  animationFunction?: (t: number) => number
  callback?: () => void
}): {
  counter: number
  isFinished: boolean
  progressAnimationPlayback: (stopAt?: number) => void
} => {
  const {
    to: countTo = 100,
    duration: animationDuration,
    animationFunction = easeInOutQuadWithGap,
    callback,
  } = animation

  const [counterValue, setCounterValue] = useState(0)
  const frame = useRef(1)

  const progressAnimationPlayback = useCallback(
    (stopAt?: number) => {
      const totalFrames = Math.round(animationDuration / frameDuration)
      const counter = setInterval(() => {
        const result = countTo * animationFunction(frame.current / totalFrames)

        if (!result) return
        if (stopAt && Math.round(result) === stopAt + 1) {
          clearInterval(counter)

          return
        }

        frame.current += 1
        setCounterValue(Math.round(result))

        const isAnimationInProgress = frame?.current !== totalFrames
        if (isAnimationInProgress) return

        clearInterval(counter)
      }, frameDuration)
    },
    [animationDuration, countTo, animationFunction],
  )

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    if (counterValue >= countTo && callback) {
      timerId = setTimeout(() => {
        callback()
      }, 0)
    }
    return () => clearTimeout(timerId)
  }, [counterValue, callback, countTo])

  useEffect(() => () => setCounterValue(0), [])

  return {
    counter: counterValue,
    isFinished: counterValue === countTo,
    progressAnimationPlayback,
  }
}
