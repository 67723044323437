import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DANCING_LEVEL_POINTS } from 'pages/questions/DancingLevel/constants'

import {
  selectCurrentUserGender,
  selectUserDancingLevel,
} from 'root-redux/selects/common'

import { StyledConfidenceBlock as S } from './ConfidenceBlock.styles'

export const ConfidenceBlock: FC = () => {
  const { t } = useTranslation()

  const dancingLevel = useSelector(selectUserDancingLevel)
  const currentGender = useSelector(selectCurrentUserGender)

  const beforePointsNumber = DANCING_LEVEL_POINTS[dancingLevel]
  const afterPointsNumber = DANCING_LEVEL_POINTS[dancingLevel] + 1

  return (
    <S.Wrapper>
      <S.Container>
        <S.Block>
          <h3>{t('purchaseV2.confidenceBlock.titleConfidence')}</h3>
          <span>{t('purchaseV2.confidenceBlock.notSetting')}</span>
        </S.Block>

        <S.Block>
          <h3>{t('purchaseV2.confidenceBlock.danceLvl')}</h3>
          <span>{t(`purchaseV2.confidenceBlock.before.${dancingLevel}`)}</span>

          <S.DanceLvlBar>
            <S.DanceLvlItem data-active={beforePointsNumber >= 1} />
            <S.DanceLvlItem data-active={beforePointsNumber >= 2} />
            <S.DanceLvlItem data-active={beforePointsNumber >= 3} />
            <S.DanceLvlItem data-active={beforePointsNumber >= 4} />
          </S.DanceLvlBar>
        </S.Block>
      </S.Container>

      <S.Container>
        <S.Block>
          <h3>{t('purchaseV2.confidenceBlock.titleConfidence')}</h3>
          <span>
            {t('purchaseV2.confidenceBlock.fit', {
              context: currentGender,
            })}
          </span>
        </S.Block>

        <S.Block>
          <h3>{t('purchaseV2.confidenceBlock.danceLvl')}</h3>
          <span>{t(`purchaseV2.confidenceBlock.after.${dancingLevel}`)}</span>
          <S.DanceLvlBar>
            <S.DanceLvlItem data-active={afterPointsNumber >= 1} />
            <S.DanceLvlItem data-active={afterPointsNumber >= 2} />
            <S.DanceLvlItem data-active={afterPointsNumber >= 3} />
            <S.DanceLvlItem data-active={afterPointsNumber >= 4} />
          </S.DanceLvlBar>
        </S.Block>
      </S.Container>
    </S.Wrapper>
  )
}
