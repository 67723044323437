export const QUESTION = 'What do you want to achieve?'

export const OPTION_VALUES = [
  {
    value: 'loseWeight',
    text: 'onboarding.mainGoal.loseWeight',
  },
  {
    value: 'improveHeart',
    text: 'onboarding.mainGoal.improveHeart',
  },
  {
    value: 'developFlex',
    text: 'onboarding.mainGoal.developFlex',
  },
  {
    value: 'loveBody',
    text: 'onboarding.mainGoal.loveBody',
  },
  {
    value: 'learnDance',
    text: 'onboarding.mainGoal.learnDance',
  },
  {
    value: 'stayFit',
    text: 'onboarding.mainGoal.stayFit',
  },
  {
    value: 'reduceStress',
    text: 'onboarding.mainGoal.reduceStress',
  },
  {
    value: 'getFirm',
    text: 'onboarding.mainGoal.getFirm',
  },
]
