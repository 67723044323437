import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { Locale } from 'root-constants'

import { StyledSubscriptionItem as S } from './SubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItem: React.FC<TProps> = ({
  subscription: {
    id,
    mainPrices: { periodQuantity, oldPrices, weekly },
    isDefault,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const oldPriceWithFractionalPart = useMemo(
    () => oldPrices.beforeCustomDiscount?.weekly.toFixed(2),
    [oldPrices],
  )

  const isDutch = useMemo(() => language === Locale.DEUTSCH, [language])

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  return (
    <S.Wrapper key={id}>
      <S.Content $isSelected={isSelected}>
        {isDefault && (
          <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
        )}
        <S.PlanPeriod $isDutch={isDutch}>
          <Trans
            i18nKey="purchase1.paymentForm.planDescription_interval"
            values={{ count: periodQuantity, postProcess: 'interval' }}
            components={[<br />]}
          />
        </S.PlanPeriod>
        <S.PriceContainer>
          <S.PreviousPrice $isDutch={isDutch}>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: getCurrentPrice(oldPriceWithFractionalPart),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.PreviousPrice>
          <S.CurrentPrice
            $isSelected={isSelected}
            $isMexican={currency === Currency.MXN}
          >
            <S.PriceValue>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(weekly),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PriceValue>
            <S.Period>
              {t`purchase1.subscription.interval`}{' '}
              {t(`purchase1.subscription.week`)}
            </S.Period>
          </S.CurrentPrice>
        </S.PriceContainer>
      </S.Content>
    </S.Wrapper>
  )
}
