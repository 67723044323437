import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import imageUrl from 'assets/images/flow-belly/pie-chart-health-profile.png'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ButtonTapType,
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  UpsellScreenId,
} from 'root-constants'

import { StyledHealthProfile as S } from './HealthProfile.styles'

export const HealthProfile: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)

  useEffect(() => {
    if (!hasObUpsellTest) {
      replaceHistory({
        pathname: PageId.UPGRADE_LIFE,
        search,
      })

      return
    }

    eventLogger.logUpsellPurchaseShown({
      screenName: ScreenName.UPSELL_ONBOARDING,
      screenId: ScreenId.HEALTH_PROFILE,
      goal: MAIN_GOALS.LOSE_WEIGHT,
      screen: UpsellScreenId.SCREEN_1,
    })
  }, [hasObUpsellTest, search])

  const handleClick = useCallback(() => {
    eventLogger.logUpsellPurchaseButtonTap({
      screenName: ScreenName.UPSELL_ONBOARDING,
      screenId: ScreenId.HEALTH_PROFILE,
      tap: ButtonTapType.REVEAL_RECOMMENDATIONS,
      screen: UpsellScreenId.SCREEN_1,
    })

    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={8}>
            {t('onboarding.healthProfile.title')}
          </PageTitle>
          <S.Subtitle>{t('onboarding.healthProfile.subtitle')}</S.Subtitle>

          <S.ChartContainer>
            <S.ParamsTop>
              <S.Activity>{t('onboarding.healthProfile.activity')}</S.Activity>
              <S.MentalHealth>
                {t('onboarding.healthProfile.mentalHealth')}
              </S.MentalHealth>
            </S.ParamsTop>
            <S.Img src={imageUrl} alt="Your Holistic Health Profile" />
            <S.ParamsBottom>
              <S.StressManagement>
                {t('onboarding.healthProfile.stressManagement')}
              </S.StressManagement>
            </S.ParamsBottom>
          </S.ChartContainer>
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.revealRecommendations`}
      </NavigationButtonBelly>
    </>
  )
}
