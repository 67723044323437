import beHealthierIcon from 'assets/images/flow-belly/icons/be-healthier.svg'
import feelConfidentIcon from 'assets/images/flow-belly/icons/feel-confident.svg'
import getInShapeIcon from 'assets/images/flow-belly/icons/get-in-shape.svg'
import otherIcon from 'assets/images/flow-belly/icons/other.svg'

export const QUESTION = 'Whats your biggest motivation?'

export const OPTION_VALUES = [
  {
    title: 'onboarding.motivation.feelConfident',
    value: 'feelConfident',
    icon: feelConfidentIcon,
  },
  {
    title: 'onboarding.motivation.beHealthier',
    value: 'beHealthier',
    icon: beHealthierIcon,
  },
  {
    title: 'onboarding.motivation.getInShape',
    value: 'getInShape',
    icon: getInShapeIcon,
  },
  {
    title: 'onboarding.motivation.other',
    value: 'other',
    icon: otherIcon,
  },
]
