import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Input } from 'storybook-ui'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { Toggle } from 'components/Toggle'

import { setWeightAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserMeasurementSystem,
  selectUserHeight,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { convertFeetToInches } from 'helpers/convertFootsToInches'
import { getIsImperialHeightValid } from 'helpers/getIsImperialHeightValid'

import { TPageProps } from 'models/common.model'

import { goTo } from 'browser-history'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  FLOAT_NUMBER_REGEX,
  MeasurementSystem,
} from 'root-constants'

import { StyledCurrentHeightBelly as S } from './CurrentHeightBelly.styles'
import {
  INITIAL_STATE,
  MinMaxHeight,
  QUESTION,
  TInitialState,
} from './constants'

export const CurrentHeightBelly: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userCountryCode = useSelector(selectUserCountryCode)
  const selectedHeight = useSelector(selectUserHeight)
  const selectedMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  const firstSuffix = useRef<HTMLSpanElement>(null)
  const secondSuffix = useRef<HTMLSpanElement>(null)

  const [height, setHeight] = useState<TInitialState>(() => {
    const isCountryWithImperialSystem = COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(
      userCountryCode,
    )

    return {
      ...INITIAL_STATE,
      isCountryWithImperialSystem,
      unit: isCountryWithImperialSystem
        ? MeasurementSystem.IMPERIAL
        : MeasurementSystem.METRIC,
    }
  })

  const isMetricSystemSelected = useMemo(
    () => height.unit === MeasurementSystem.METRIC,
    [height.unit],
  )

  useEffect(() => {
    const initialMeasurementSystem = height.isCountryWithImperialSystem
      ? MeasurementSystem.IMPERIAL
      : MeasurementSystem.METRIC

    setHeight((prevState) => ({
      ...prevState,
      valueIn: '',
      unit: initialMeasurementSystem,
    }))
  }, [
    height.isCountryWithImperialSystem,
    selectedHeight,
    selectedMeasurementSystem,
  ])

  const handleMeasurementChange = useCallback((isChecked) => {
    setHeight(() => ({
      ...INITIAL_STATE,

      valueIn: '',
      unit: isChecked ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
    }))
  }, [])

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()

      const inputValue = isMetricSystemSelected
        ? height.valueIn
        : String(convertFeetToInches(height.valueIn, height.valueFt))

      dispatch(
        setWeightAnswerAction({
          question: QUESTION,
          answers: {
            [pageId]: inputValue,
            measurementSystem: isMetricSystemSelected
              ? MeasurementSystem.METRIC
              : MeasurementSystem.IMPERIAL,
          },
        }),
      )
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [
      isMetricSystemSelected,
      height.valueIn,
      height.valueFt,
      dispatch,
      pageId,
      nextPagePath,
    ],
  )

  const handleIntegerChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FLOAT_NUMBER_REGEX.test(value)) {
      setHeight((prevState) => ({
        ...prevState,
        valueIn: value,
        isValid: validity.valid,
      }))
    }
  }, [])

  const handleFractionalChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FLOAT_NUMBER_REGEX.test(value)) {
        setHeight((prevState) => ({
          ...prevState,
          valueFt: value,
          isValid: validity.valid,
        }))
      }
    },
    [],
  )

  const isImperialHeightValid = useCallback(
    () =>
      getIsImperialHeightValid({
        fractional: height.valueFt,
        integer: height.valueIn,
      }),
    [height.valueFt, height.valueIn],
  )

  return (
    <S.Form onSubmit={handleClick}>
      <Container isLarge>
        <PageTitle
          marginBottom={8}
        >{t`onboarding.currentHeight.question`}</PageTitle>

        <S.Subtitle marginBottom={24}>
          {t('onboarding.currentHeight.subtitle')}
        </S.Subtitle>

        <S.Actions>
          <Toggle
            isFlat
            data-testid="weight-toggle"
            labels={[
              t('commonComponents.centimeter'),
              t('commonComponents.feet'),
            ]}
            checked={!isMetricSystemSelected}
            onChange={handleMeasurementChange}
          />

          <S.InputContainer>
            <S.InputWrapper>
              <Input
                theme="dancebitNumber"
                type="number"
                value={height.valueIn}
                isValid={height.isValid}
                lang="en"
                maxWidth={isMetricSystemSelected ? '100%' : '150px'}
                step="0.1"
                allowFloatNumbers={isMetricSystemSelected}
                onChange={handleIntegerChange}
                min={
                  isMetricSystemSelected
                    ? MinMaxHeight.MIN_HEIGHT_CM
                    : MinMaxHeight.MIN_HEIGHT_FT
                }
                max={
                  isMetricSystemSelected
                    ? MinMaxHeight.MAX_HEIGHT_CM
                    : MinMaxHeight.MAX_HEIGHT_FT
                }
                isContentCentered
                padding={`0 ${firstSuffix?.current?.clientWidth}px 0 0`}
              />

              <S.Suffix $isFirstLabel ref={firstSuffix}>
                {isMetricSystemSelected ? '' : t('commonComponents.feet')}
              </S.Suffix>
            </S.InputWrapper>

            {!isMetricSystemSelected && (
              <S.InputWrapper>
                <Input
                  theme="dancebitNumber"
                  type="number"
                  maxWidth="150px"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  value={height.valueFt}
                  required={false}
                  isValid={height.isValid}
                  lang="en"
                  step="1"
                  allowFloatNumbers={false}
                  onChange={handleFractionalChange}
                  min={MinMaxHeight.MIN_HEIGHT_IN}
                  max={MinMaxHeight.MAX_HEIGHT_IN}
                  padding={`0 ${secondSuffix?.current?.clientWidth}px 0 0`}
                  isContentCentered
                />

                <S.Suffix ref={secondSuffix}>
                  {t('commonComponents.in')}
                </S.Suffix>
              </S.InputWrapper>
            )}
          </S.InputContainer>
        </S.Actions>
      </Container>

      <S.NavigationButtonBelly
        type="submit"
        disabled={
          isMetricSystemSelected ? !height.isValid : !isImperialHeightValid()
        }
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Form>
  )
}
