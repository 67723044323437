import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Input } from 'storybook-ui'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  Color,
  GROWTHBOOK_EXPERIMENT,
  INTEGER_NUMBER_REGEX,
  MAX_AGE,
  MIN_AGE,
} from 'root-constants'

import { StyledAgeBelly as S } from './AgeBelly.styles'
import { QUESTION } from './constants'

export const AgeBelly: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [age, setAge] = useState({
    value: '',
    isValid: false,
  })
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const { isNewAdultFlow } = useCohortInfo()

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || INTEGER_NUMBER_REGEX.test(value)) {
      setAge({
        value,
        isValid: validity.valid,
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    (event) => {
      event.preventDefault()
      handleContinue(age.value)
      eventLogger.logSelectedAge(age.value)
    },
    [age.value, handleContinue],
  )

  return (
    <S.Form
      onSubmit={handleNextClick}
      $isJapanese={isJapaneseFlow}
      $isAdult={isNewAdultFlow}
    >
      <Container>
        <PageTitle marginBottom={8}>{t`onboarding.age.question`}</PageTitle>
        <S.Subtitle marginBottom={24}>
          {t('onboarding.ageBelly.subtitle')}
        </S.Subtitle>

        <S.InputContainer>
          <Input
            theme="dancebitNumber"
            type="number"
            min={MIN_AGE}
            max={MAX_AGE}
            border={
              isNewAdultFlow
                ? '1px solid #C6E0F4'
                : `1px solid ${Color.GRAY_40}`
            }
            focusedBorderColor={isNewAdultFlow ? '#C6E0F4' : Color.GRAY_40}
            pattern="\d*"
            value={age.value}
            onChange={handleChange}
            allowFloatNumbers={false}
            isContentCentered
          />
        </S.InputContainer>
      </Container>

      <S.NavigationButtonBelly
        hasClearBackground
        type="submit"
        disabled={!age.isValid}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Form>
  )
}
