import { PaymentIntentResult, StripeError } from '@stripe/stripe-js'

import { createProductId } from 'helpers/createProductId'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  CardPaymentFieldName,
  ScreenId,
  ScreenName,
  TimeInterval,
} from 'root-constants'

import {
  EMPTY_FIELD_ERROR,
  PaymentDeclineReason,
  PaymentMethod,
  PaymentSystem,
  STRIPE_SOFT_DECLINE_REASONS,
  StripeErrorCode,
  StripeSoftDeclineReason,
} from '../constants'
import { TPaymentErrorState } from '../types'

export const getPaymentErrorStateBySubmitWithUntouchedFields = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )

export const getDefaultPaymentErrorsState = (): Record<
  CardPaymentFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
    isFocused: boolean
  }
> => ({
  [CardPaymentFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
    isFocused: false,
  },
  [CardPaymentFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
  [CardPaymentFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
  [CardPaymentFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
})

export const getRedirectUrl = (): string => `${window.location.href}`

export const checkIsRetryAllowed = (
  confirmCardPaymentResponse: PaymentIntentResult,
): boolean => {
  const declineCode =
    confirmCardPaymentResponse?.error?.decline_code ||
    confirmCardPaymentResponse?.error?.code

  return STRIPE_SOFT_DECLINE_REASONS.includes(
    declineCode as StripeSoftDeclineReason,
  )
}

export const getPaymentFailedError = (
  error?: StripeSoftDeclineReason | PaymentDeclineReason | string,
): string => {
  switch (error) {
    case StripeSoftDeclineReason.INSUFFICIENT_FUNDS:
      return 'purchase1.insufficientFunds'
    case StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED:
      return 'purchase1.velocityExceeded'
    case PaymentDeclineReason.STOLEN_CARD:
    case PaymentDeclineReason.LOST_CARD:
    case PaymentDeclineReason.GENERIC_DECLINE:
      return 'purchase1.genericDecline'
    default:
      return 'login.somethingWentWrong'
  }
}

export const logSuccessfulPayment = ({
  productId,
  productName,
  price,
  predictedLtv,
  trialPrice,
  trialPeriodDays,
  trialPriceId,
  subscriptionId,
  discountApplied,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  currency,
  screenName,
  screenId,
  email,
  stripeAccountName,
  utmSource,
  isUpgraded = false,
  amountToPay,
  paymentSystem,
  accountId,
  goal,
  isPersonalDataAllowed,
  isUpsell,
}: {
  productId: TProductId
  productName: TProductId
  price: number
  predictedLtv?: number | null
  trialPrice: number
  trialPeriodDays: number
  trialPriceId: string
  subscriptionId: string
  discountApplied?: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number
  paymentMethod?: PaymentMethod
  currency: string
  screenName: ScreenName
  screenId: ScreenId
  email: string
  stripeAccountName: string
  utmSource?: string
  isUpgraded?: boolean
  amountToPay?: string
  paymentSystem: PaymentSystem
  accountId: string
  goal: string
  isPersonalDataAllowed: boolean
  isUpsell?: boolean
}): void => {
  if (!isUpsell) {
    if (trialPeriodDays) {
      window.fbq &&
        window.fbq(
          'track',
          'StartTrial',
          {
            value: trialPrice,
            currency,
            subscription_id: subscriptionId,
            // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
            subscription_sid: subscriptionId,
          },
          { eventID: isPersonalDataAllowed ? uuid : '' },
        )
    }
    window.ttq &&
      window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq &&
      window.ttq.track('CompletePayment', {
        value: trialPriceId ? trialPrice : price,
        currency: currency.toUpperCase(),
        content_id: subscriptionId,
        event_id: isPersonalDataAllowed ? uuid : '',
      })
    window.fbq &&
      window.fbq(
        'track',
        'Purchase',
        {
          value: predictedLtv || trialPrice || price,
          currency,
          subscription_id: subscriptionId,
          subscription_sid: subscriptionId,
        },
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    window.axon &&
      window.axon('track', 'purchase', {
        value: trialPrice || price,
        currency,
        user_id: isPersonalDataAllowed ? uuid : '',
      })
    window.snaptr &&
      window.snaptr('track', 'PURCHASE', {
        price: trialPriceId ? trialPrice : price,
        currency,
        client_dedup_id: subscriptionId,
        transaction_id: subscriptionId,
        user_email: isPersonalDataAllowed ? email : '',
      })
    window.obApi &&
      window.obApi('track', 'Purchase', {
        orderValue: trialPriceId ? trialPrice : price,
        currency: currency.toUpperCase(),
        orderId: subscriptionId,
      })
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: 'make_purchase',
        revenue: trialPrice || price,
        orderId: subscriptionId,
        currency: currency.toUpperCase(),
      })
    window.rdt &&
      window.rdt('track', 'Purchase', {
        value: trialPriceId ? trialPrice : price,
        currency,
        transactionId: subscriptionId,
        email: isPersonalDataAllowed ? email : '',
        externalId: isPersonalDataAllowed ? uuid : '',
        ...(!isPersonalDataAllowed && {
          em: '',
          uuid: '',
        }),
      })
    window.fbq &&
      window.fbq(
        'track',
        'Subscribe',
        {
          value: trialPriceId ? trialPrice : price,
          currency,
          subscription_id: subscriptionId,
          subscription_sid: subscriptionId,
        },
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    window.pintrk &&
      window.pintrk('track', 'checkout', {
        value: price,
        currency: currency.toUpperCase(),
        order_id: uuid,
        line_items: [
          {
            product_category: 'wellness',
            product_name: createProductId({
              periodName,
              periodQuantity,
              price,
            }),
            product_id: createProductId({
              periodName,
              periodQuantity,
              price,
            }),
            product_price: price,
          },
        ],
      })

    googleAnalyticsLogger.logPurchaseCompleted({
      subscriptionId,
      price,
      periodName,
      periodQuantity,
      currency,
      screenName,
    })
  }

  eventLogger.logPurchaseCompleted({
    productId,
    productName,
    priceDetails: {
      price,
      trial: !!trialPeriodDays,
      currency,
    },
    paymentMethod,
    screenName,
    screenId,
    discountApplied,
    stripeAccountName,
    utmSource,
    isUpgraded,
    amountToPay,
    paymentSystem,
    accountId,
    goal,
  })
}

export const logFailedPayment = ({
  productId,
  priceDetails,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  screenName,
  screenId,
  accountId,
  accountName,
  paymentSystem,
  goal,
}: {
  productId: TProductId
  priceDetails: {
    price: number
    trial: boolean
    currency: string
  }
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  screenName: ScreenName
  screenId: ScreenId
  accountId: string
  accountName: string
  paymentSystem: PaymentSystem
  goal: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    priceDetails,
    error: {
      type,
      code: errorCode,
      description: message,
    },
    paymentMethod,
    screenName,
    screenId,
    accountId,
    accountName,
    paymentSystem,
    goal,
  })
}

export const getDecoratedTimerValue = (
  valueInSeconds: number,
  withHour: boolean,
): {
  hours: string
  minutes: string
  seconds: string
} => {
  const hours = Math.trunc(valueInSeconds / 3600)
  const minutes = Math.trunc(valueInSeconds / 60)
  const seconds = valueInSeconds - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return {
    hours: withHour ? get2DigitValue(hours) : '',
    minutes: get2DigitValue(minutes),
    seconds: get2DigitValue(seconds),
  }
}
