import React, { useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort, Gender } from 'root-constants'

import { StyledDynamicPageWithImage as S } from './styles'

// todo
export const PageWithImage: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
    handleContinue,
  } = useDynamicPage(props)

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const gender = useSelector(selectCurrentUserGender)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const { imageUrl } = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const [text] = pageTexts || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return {
      imageUrl: personalizedImg || femaleImage,
      info: text,
    }
  }, [imageUrls, gender, pageTexts])

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <div>
      {!subtitle && (
        <S.PictureContainer>
          <img src={imageUrl} alt={title} />
        </S.PictureContainer>
      )}
      <S.Container $isAgeFlow={isAgeFlow}>
        {!subtitle && <S.PageSubtitle>{title}</S.PageSubtitle>}

        {subtitle && (
          <>
            <PageTitle marginBottom={isAgeFlow ? 16 : 10} isAgeFlow={isAgeFlow}>
              <Trans>{title}</Trans>
            </PageTitle>

            <S.AnimationContainer>
              <S.AnimationTitle>
                <Trans>{subtitle}</Trans>
              </S.AnimationTitle>
              <S.Animation src={imageUrl} alt={title} $isAgeFlow={isAgeFlow} />
            </S.AnimationContainer>
          </>
        )}

        <S.ButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {buttonText}
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </div>
  )
}
