import styled from 'styled-components'

import { Color } from 'root-constants'

type TTitleProps = {
  $gridArea: string
}
type TTextProps = TTitleProps & {
  $textAlign?: string
}

export const StyledSubscriptionsBlockTrial = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'priceToday priceToday'
      'trialText trialPrice'
      'trialPriceVatInfo trialPriceVatInfo'
      'priceAfter priceAfter'
      'fullPriceText fullPrice'
      'fullPriceDiscount fullPriceVatInfo';
    padding: 0 13px 40px;
  `,
  PriceTitle: styled.p<TTitleProps>`
    grid-area: ${({ $gridArea }) => $gridArea};
    padding-bottom: 16px;
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid ${Color.LIGHT};

    &:last-of-type {
      padding-top: 24px;
      margin-top: 4px;
      border-top: 1px solid ${Color.LIGHT};
    }
  `,
  Text: styled.span<TTextProps>`
    grid-area: ${({ $gridArea }) => $gridArea};
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    min-height: 10px;
    text-align: ${({ $textAlign }) => $textAlign || 'left'};
  `,
  Discount: styled.div`
    padding: 4px 12px;
    margin-top: 2px;
    background-color: ${Color.PRIMARY};
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: ${Color.WHITE};
    width: fit-content;
  `,
  VatInfo: styled.span`
    text-align: right;
    color: #b4b9c0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
}
