import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { CANCEL_OFFER_ADDITIONAL_DISCOUNT } from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import highVoltage from 'assets/images/sprite/high-voltage.svg'

import { DynamicDiscountTheme } from 'root-constants'

import { StyledLimitedDeal as S } from './LimitedDeal.styles'

export const LimitedDeal: React.FC<{ isCancelOffer?: boolean }> = ({
  isCancelOffer = false,
}) => {
  const { t } = useTranslation()

  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const isBlackTheme = useMemo(
    () => dynamicDiscount?.theme === DynamicDiscountTheme.BLACK,
    [dynamicDiscount?.theme],
  )

  return (
    <S.Container $theme={dynamicDiscount?.theme}>
      <S.DiscountLabel $theme={dynamicDiscount?.theme}>
        {t('purchaseCancelOffer.personalPlan.discountText', {
          discountAmount:
            isCancelOffer && dynamicDiscount?.amount
              ? dynamicDiscount.amount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
              : dynamicDiscount?.amount,
        })}
      </S.DiscountLabel>

      <S.Text $theme={dynamicDiscount?.theme}>
        {t('purchaseWithoutIntro.limitedDeal')}
      </S.Text>

      {isBlackTheme && <SvgImage svg={highVoltage} width={24} height={24} />}
    </S.Container>
  )
}
