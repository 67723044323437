import { useEffect } from 'react'

import firebase from 'firebase/app'

export const useGetRedirectResult = (
  callback: (token: string) => void,
  callbackIsDataLoading: (isLoading: boolean) => void,
): void => {
  useEffect(() => {
    callbackIsDataLoading(true)
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          return result.user.getIdToken()
        }
        return null
      })
      .then((token) => token && callback(token))
      .finally(() => callbackIsDataLoading(false))
  }, [callback, callbackIsDataLoading])
}
