import React from 'react'

import { StyledPageDescription as S } from './PageDescription.styles'

type TPageDescriptionProps = {
  marginBottom?: number
  className?: string
  children?: React.ReactNode
}
export const PageDescription: React.FC<TPageDescriptionProps> = ({
  children,
  marginBottom,
  className,
}) => (
  <S.PageDescription $marginBottom={marginBottom} className={className}>
    {children}
  </S.PageDescription>
)
