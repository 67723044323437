import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import arrowImg from 'assets/images/arrow-down.svg'

import { CDN_FOLDER_LINK, Locale, MeasurementSystem } from 'root-constants'

import { StyledLoseWeightGraphAge as S } from './LoseWeightGraphAge.styles'
import { ANIMATION_PATH } from './constants'

type TProps = {
  hasTitle?: boolean
  className?: string
}

export const LoseWeightGraphAge: FC<TProps> = ({
  hasTitle = true,
  className,
}) => {
  const { t } = useTranslation()

  const language = useSelector(selectLanguage)
  const answers = useSelector(selectAnswers)

  const [graphContentRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
    animationName: 'goalChart',
  })

  const isImperial = useMemo(
    () => answers?.measurementSystem === MeasurementSystem.IMPERIAL,
    [answers?.measurementSystem],
  )

  const [percentageText, tipBefore, tipAfter] = useMemo(() => {
    const currentWeight = answers?.currentWeight || '55'
    const goalWeight = answers?.goalWeight || '50'

    const unit = isImperial
      ? t('commonComponents.imperial')
      : t('commonComponents.metric')

    const percentage = `${
      100 -
      Math.round(
        (parseFloat(goalWeight as string) /
          parseFloat(currentWeight as string)) *
          100,
      )
    }`

    return [percentage, `${currentWeight} ${unit}`, `${goalWeight} ${unit}`]
  }, [answers?.currentWeight, answers?.goalWeight, isImperial, t])

  const isGerman = useMemo(() => language === Locale.DEUTSCH, [language])
  const isFrenchOrSpanish = useMemo(
    () => language === Locale.FRENCH || language === Locale.SPANISH,
    [language],
  )

  return (
    <S.Wrapper className={className}>
      {hasTitle && (
        <S.Title>
          <Trans
            i18nKey="result.ageLoseWeightChart.title"
            values={{ percentageText }}
          />
        </S.Title>
      )}
      <S.GraphContent>
        <div ref={graphContentRef} />

        <S.TipNow $isGerman={isGerman} $isImperial={isImperial}>
          {tipBefore}
        </S.TipNow>
        <S.TipAfter $isGerman={isGerman}>{tipAfter}</S.TipAfter>
        <S.WrapperWeightLoss $isFrenchOrSpanish={isFrenchOrSpanish}>
          <Trans i18nKey="result.ageLoseWeightChart.weightLoss" />
        </S.WrapperWeightLoss>
        <S.WeightStaysOff $isFrenchOrSpanish={isFrenchOrSpanish}>
          <Trans i18nKey="result.ageLoseWeightChart.weightStaysOff" />
        </S.WeightStaysOff>
        <S.ExpectedResultsContainer>
          <S.ExpectedResults>{t`result.ageLoseWeightChart.expectedResults`}</S.ExpectedResults>
          <S.GraphImg src={arrowImg} />
        </S.ExpectedResultsContainer>
      </S.GraphContent>
    </S.Wrapper>
  )
}
