import { countriesListWithExcludedVat } from 'modules/purchase/constants'

export function getCalculatedPrice(
  price: number | string,
  taxAmount: number,
  country: string,
): string | number {
  if (countriesListWithExcludedVat.includes(country)) {
    return ((+price * 100) / (100 + taxAmount)).toFixed(2)
  }

  return price
}
