import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubheaderWithTimerV2 = {
  Wrapper: styled.div`
    margin-bottom: 32px;
    padding: 24px 16px;
    background-color: rgba(253, 235, 233, 0.5);
    border: 1px solid #ff8276;
    border-radius: 20px;
    text-align: center;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
  Text: styled.p`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  `,
  Time: styled.div`
    max-width: 119px;
    min-height: 46px;
    margin: 0 auto;
    padding: 8px 24px;
    background: #ff8276;
    border-radius: 8px;
    color: ${Color.WHITE};

    & div,
    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
  `,
}
