import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { GoalWeightWithRuler } from 'pages/questions/GoalWeightWithRuler'

import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useSkipStep } from 'hooks/useSkipStep'

import { calculateWeightDifference } from 'helpers/calculateWeightDifference'

import { TAnswer, TPageProps } from 'models/common.model'

import easyWin from 'assets/images/sprite/easy-win.svg'
import someEffort from 'assets/images/sprite/some-effort.svg'

import {
  ADULT_COHORT,
  Cohort,
  FLOAT_NUMBER_REGEX,
  GROWTHBOOK_EXPERIMENT,
  MeasurementSystem,
} from 'root-constants'

import { StyledGoalWeight as S } from './GoalWeight.styles'
import {
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
  QUESTION,
} from './constants'

const enum MAIN_COLOR {
  EASY_WIN = '#7ad164',
  SOME_EFFORT = '#ffa337',
}

const enum MAIN_BG_COLOR {
  EASY_WIN = '#eeffd9',
  SOME_EFFORT = '#fff5da',
}

const MINIMUM_WEIGHT_LOSS_THRESHOLD = 10.5

export const GoalWeight: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const hasRulerTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_455)
  const cohortToUse = parentCohort || cohort

  const disclaimerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [weight, setWeight] = useState<{
    value: string
    unit: TAnswer
    isValid: boolean
  }>({
    value: '',
    unit: currentMeasurementSystem,
    isValid: false,
  })
  const [weightLossPercentage, setWeightLossPercentage] = useState(0)

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isMetricSystem = useMemo(
    () => weight.unit === MeasurementSystem.METRIC,
    [weight.unit],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const minValue = useMemo(
    () => (isMetricSystem ? MIN_WEIGHT_KG : MIN_WEIGHT_LB),
    [isMetricSystem],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const skipStep = useSkipStep({
    nextPagePath,
    pageId,
  })

  useLayoutEffect(() => {
    if (isStayFitFlow) {
      skipStep(currentWeightValue)
    }
  }, [currentWeightValue, isStayFitFlow, skipStep])

  useLayoutEffect(() => {
    if (!hasRulerTest) return

    setWeight((prevState) => ({
      ...prevState,
      value: (
        Number(currentWeightValue) -
        (isMetricSystem ? MIN_KG_DIFF : MIN_LB_DIFF)
      ).toString(),
    }))
  }, [currentWeightValue, hasRulerTest, isMetricSystem])

  useEffect(() => {
    if (weight.isValid && disclaimerRef.current) {
      disclaimerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      })

      inputRef.current && inputRef.current.blur()
    }
  }, [weight.isValid])

  useEffect(() => {
    if (currentMeasurementSystem) {
      setWeight((prevState) => ({
        ...prevState,
      }))
    }
  }, [currentMeasurementSystem])

  const handleChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FLOAT_NUMBER_REGEX.test(value)) {
        setWeight((prevState) => ({
          ...prevState,
          value,
          isValid: validity.valid,
        }))

        const difference = calculateWeightDifference(
          Number(currentWeightValue),
          value,
        )
        setWeightLossPercentage(difference)
      }
    },
    [currentWeightValue],
  )

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      handleContinue(weight.value)
    },
    [handleContinue, weight],
  )

  return (
    <form onSubmit={handleClick}>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle
          marginBottom={10}
        >{t`onboarding.goalWeight.question`}</PageTitle>
        <PageTitleDescription marginBottom={30}>
          <Trans
            i18nKey="onboarding.goalWeight.subtitle"
            components={[<br />]}
          />
        </PageTitleDescription>
        <S.WeightLabel>
          {t(`commonComponents.${currentMeasurementSystem}`)}
        </S.WeightLabel>
        {hasRulerTest ? (
          <GoalWeightWithRuler
            value={weight.value}
            onChange={handleChange}
            isMetricSystem={isMetricSystem}
          />
        ) : (
          <Input
            inputRef={inputRef}
            style={{ textAlign: 'center' }}
            isAgeFlow={isAgeFlow}
            type="number"
            min={minValue}
            max={
              currentWeightValue &&
              parseFloat(currentWeightValue as string) -
                (isMetricSystem ? MIN_KG_DIFF : MIN_LB_DIFF)
            }
            lang="en"
            step={isMetricSystem ? '0.1' : '1'}
            value={weight.value}
            onChange={handleChange}
            allowFloatNumbers={isMetricSystem}
          />
        )}

        {weight.isValid && (
          <>
            {weightLossPercentage < MINIMUM_WEIGHT_LOSS_THRESHOLD ? (
              <S.WeightDisclaimer
                $bgColor={MAIN_BG_COLOR.EASY_WIN}
                ref={disclaimerRef}
              >
                <S.Title $color={MAIN_COLOR.EASY_WIN}>
                  <S.Icon svg={easyWin} width={16} />
                  {t('onboarding.goalWeight.easyWin')}
                </S.Title>
                <Trans
                  i18nKey="onboarding.goalWeight.youWillLose"
                  values={{ percentage: weightLossPercentage.toFixed(2) }}
                  components={[<strong />]}
                />
                <S.Description>
                  {t('onboarding.goalWeight.easyWinText')}
                </S.Description>
              </S.WeightDisclaimer>
            ) : (
              <S.WeightDisclaimer
                $bgColor={MAIN_BG_COLOR.SOME_EFFORT}
                ref={disclaimerRef}
              >
                <S.Title $color={MAIN_COLOR.SOME_EFFORT}>
                  <S.Icon svg={someEffort} width={16} />
                  {t('onboarding.goalWeight.someEffort')}
                </S.Title>
                <Trans
                  i18nKey="onboarding.goalWeight.youWillLose"
                  values={{ percentage: weightLossPercentage.toFixed(2) }}
                  components={[<strong />]}
                />
                <S.Description>
                  {t('onboarding.goalWeight.someEffortText')}
                </S.Description>
              </S.WeightDisclaimer>
            )}
          </>
        )}
      </S.Container>

      <NavigationButton
        type="submit"
        isAgeFlow={isAgeFlow}
        disabled={!weight.isValid && Number(weight.value) !== minValue}
      >{t`actions.continue`}</NavigationButton>
    </form>
  )
}
