import React, { FC, useCallback, useLayoutEffect } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useSkipStep } from 'hooks/useSkipStep'

import { TPageProps } from 'models/common.model'

import weightLossLifecycle from 'assets/images/weight-loss-lifecycle.png'

import { OPTIONS_VALUE } from '../Menopause/constants'
import { StyledWeightLossLifecycle as S } from './WeightLossLifecycle.styles'
import { QUESTION } from './constants'

export const WeightLossLifecycle: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const userAnswers = useSelector(selectAnswers)

  const skipStep = useSkipStep({
    nextPagePath,
    pageId,
  })

  useLayoutEffect(() => {
    if (userAnswers?.menopause === OPTIONS_VALUE.NO) {
      skipStep('')
    }
  }, [userAnswers?.menopause, nextPagePath, pageId, skipStep])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <S.Root>
      <S.ImageContainer>
        <S.Image src={weightLossLifecycle} alt="Weight Loss Lifecycle" />
      </S.ImageContainer>
      <S.TextBlock>
        <Container isLarge>
          <S.TextContainer>
            <S.Title>
              <Trans i18nKey="onboarding.weightLossLifecycle.title" />
            </S.Title>

            <S.Paragraph>
              <Trans i18nKey="onboarding.weightLossLifecycle.firstParagraph" />
            </S.Paragraph>
            <S.Paragraph>
              <Trans i18nKey="onboarding.weightLossLifecycle.secondParagraph" />
            </S.Paragraph>
          </S.TextContainer>
        </Container>
        <NavigationButtonBelly type="button" onClick={handleNextClick}>
          <Trans i18nKey="purchaseCancelOffer.questionPage.gotIt" />
        </NavigationButtonBelly>
      </S.TextBlock>
    </S.Root>
  )
}
