import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FAQList } from 'modules/purchase/components/FAQList'
import { FreePlanCancelOfferTimer } from 'modules/purchase/components/FreePlanCancelOfferTimer'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { MoneyBackCancelOffer } from 'modules/purchase/components/MoneyBackCancelOffer'
import { PhotoResultFreeOffer } from 'modules/purchase/components/PhotoResultFreeOffer'
import { ReviewsFreeOffer } from 'modules/purchase/components/ReviewsFreeOffer'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'
import presentImg from 'assets/images/present.png'

import { PageId } from 'page-constants'
import {
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseCancelOffer as S } from './PurchaseCancelOfferFree.styles'

export const PurchaseCancelOfferFree: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const {
    currentMeasurementSystem,
    fetchingActionsList,
    subscriptions,
    userAnswers,
    discountPercentage,
  } = usePurchaseStore()

  const {
    subtitle,
    benefits,
    benefitsBlockTitle,
    buttonText,
  } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenName: ScreenName.CANCEL_OFFER,
    screenId: ScreenId.CANCEL_LEGS,
    isAmplitudeEvent: true,
  })

  const hasIncludedVat = useVatInfo()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.CANCEL_OFFER, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))
    dispatch(setScreenIdAction(ScreenId.CANCEL_LEGS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
      ),
    )

    window.axon && window.axon('track', 'view_item')

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback(async (event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.CANCEL_OFFER,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          <S.Wrapper>
            <S.PlanBanner>
              <S.Image src={presentImg} />
              <S.Heading>
                <Trans
                  i18nKey="purchaseFreeOffer.getDiscount"
                  components={{
                    before: <S.Before>{discountPercentage - 15}%</S.Before>,
                    after: <S.After>{discountPercentage}%</S.After>,
                  }}
                />
              </S.Heading>
            </S.PlanBanner>
            <S.Title>{subtitle || t('purchaseFreeOffer.grabPlan')}</S.Title>
          </S.Wrapper>
          <FreePlanCancelOfferTimer
            onButtonClick={handleShowPayment}
            elemForComparisonRef={buttonWrapperElementRef}
          />
          <S.Wrapper>
            <S.SubscriptionsBlockIntroOffer
              elemForComparisonRef={buttonWrapperElementRef}
              isCancelOffer
              disclaimerVisible={false}
            >
              <S.OfferCard />
              <S.Button
                data-order="2"
                data-text="get_my_plan"
                type="button"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </S.Button>
            </S.SubscriptionsBlockIntroOffer>
            <MoneyBackCancelOffer />
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || t`purchaseCancelOffer.appBenefits.title`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ text, id }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchaseCancelOffer.appBenefits.targetWeight"
                        values={{
                          context: MAIN_GOALS.LOSE_WEIGHT,
                          weight: userAnswers?.[PageId.GOAL_WEIGHT],
                          unit: t(
                            `commonComponents.${currentMeasurementSystem}`,
                          ),
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.sustainSuccess" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.moreEnergy" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.betterHealth" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.motivation" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.happierYou" />
                    </S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
            <ReviewsFreeOffer />
            <FAQList />
          </S.Wrapper>
          <S.WrapperWithBackground>
            <PhotoResultFreeOffer />
          </S.WrapperWithBackground>
          <S.Wrapper>
            <S.FeaturedBlockContainer>
              <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
              <picture>
                <source srcSet={featuredImageWebp} type="image/webp" />
                <img src={featuredImagePng} alt="body" />
              </picture>
            </S.FeaturedBlockContainer>
            <IntroOfferDisclaimer />
          </S.Wrapper>
        </div>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
          isCancelOfferPage
        />
      </S.CheckoutContainer>
    </>
  )
}
