export const QUESTION = 'Which of these dancing styles would you like to try?'

export const OPTION_VALUES = {
  CARDIO: 'cardio',
  LATIN: 'latin',
  DANCE_FITNESS: 'danceFitness',
  AFRO: 'afro',
  HIP_HOP: 'hipHop',
  HIGH_HEELS: 'highHeels',
  AEROBICS: 'aerobics',
  KIAT_JUD_DAI: 'kiatJudDai',
  BOLLYWOOD: 'bollywood',
}
export const NEW_DANCE_STYLES = [
  OPTION_VALUES.HIGH_HEELS,
  OPTION_VALUES.AFRO,
  OPTION_VALUES.CARDIO,
]
