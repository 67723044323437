import styled from 'styled-components'

import { Button } from 'components/Button'

import { FreeOfferCard } from 'modules/purchase/components/FreeOfferCard'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'

import blueCheck from 'assets/images/check-icon-rounded.svg'
import arrowIcon from 'assets/images/crossing-arrow.svg'

import { Color } from 'root-constants'

export const StyledPurchaseCancelOffer = {
  Wrapper: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 24px;

    &:last-child {
      padding-bottom: 20px;
    }
  `,
  WrapperWithBackground: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 24px;
    border-radius: 24px;
    background: ${Color.GRAY_10};
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 0 auto 40px;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 17px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  FeaturedBlockContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 40px;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  OfferCard: styled(FreeOfferCard)`
    gap: 6px;
    border: 1px solid #ffdc9c;
    background: #fff4df;
    margin: 24px 0;

    p {
      font-size: 12px;
    }
  `,
  PlanBanner: styled.div`
    padding: 16px;
    border-radius: 16px;
    background: #fef1d7;
    display: flex;
    align-items: center;
    margin: 26px 0 16px;
  `,
  Heading: styled.h1`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  Image: styled.img`
    width: 50px;
    height: 54px;
    margin-right: 12px;
  `,
  Before: styled.span`
    position: relative;
    color: #4f6ca3;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    padding-right: 16px;

    &:before {
      content: '';
      position: absolute;
      width: 46px;
      height: 12px;
      background-image: url(${arrowIcon});
      background-size: contain;
      left: 0;
      top: 4px;
    }
  `,
  After: styled.span`
    padding: 2px 8px;
    border-radius: 8px;
    background: linear-gradient(
      291deg,
      #f8002f 2.25%,
      #ff782a 52.07%,
      #ffb52a 98.08%
    );
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  SubscriptionsBlockIntroOffer: styled(SubscriptionsBlockIntroOffer)`
    margin-bottom: 32px;
  `,
}
