export const QUESTION = 'Have you tried belly workouts before?'
export const QUESTION_LEGS = 'Have you tried lower body workouts before?'
export const QUESTION_SOMATIC = 'Have you tried somatic workouts before?'

export const OPTION_VALUES = [
  {
    value: 'notReally',
    title: 'onboarding.tryWorkouts.notReally',
  },
  {
    value: 'haveExperience',
    title: 'onboarding.tryWorkouts.haveExperience',
  },
  {
    value: 'triedEverything',
    title: 'onboarding.tryWorkouts.triedEverything',
  },
]
