import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { GeneralProduct } from 'modules/purchase/constants'
import {
  selectProductId,
  selectSubscriptionPeriodQuantity,
} from 'modules/purchase/redux/selects/common'

import { StyledIntroMediaV2 as S } from './IntroMediaV2.styles'

export const IntroMediaV2: React.FC = () => {
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const productId = useSelector(selectProductId)
  const isYearProduct = productId === GeneralProduct.ONE_YEAR
  const { title, subtitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.Title>
          {title || <Trans i18nKey="purchaseUpgrade.introMediaTitleV2" />}
        </S.Title>
        <S.Subtitle>
          {subtitle ||
            (isYearProduct ? (
              <Trans i18nKey="purchaseUpgrade.introMediaSubtitleYear" />
            ) : (
              <Trans
                i18nKey="purchaseUpgrade.introMediaSubtitle"
                values={{ periodQuantity }}
              />
            ))}
        </S.Subtitle>
      </S.TitleContainer>
      <S.Arrow />
    </S.Wrapper>
  )
}
