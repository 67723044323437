import { StyledJapaneseBorder } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDancingVsExercises = {
  AnimationContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  `,
  AnimationTitle: styled.h2`
    margin-bottom: 40px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  Animation: styled.div<{ $isJapanese: boolean }>`
    min-height: 263px;
    min-width: 328px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    overflow: hidden;
    padding: 10px 12px;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseBorder};
      `}
  `,
  ButtonContainer: styled.div`
    padding-bottom: 30px;
  `,
  Container: styled(Container)<{ $isNewAdultFlow: boolean }>`
    padding-top: 16px;

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{
    $isNewAdultFlow: boolean
  }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;

        button {
          text-transform: capitalize;
        }
      `}
  `,
}
