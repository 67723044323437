import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import resultGenderFemalePng from 'assets/images/result-gender-female.png'
import resultGenderFemaleWebp from 'assets/images/result-gender-female.webp'
import resultGenderMalePng from 'assets/images/result-gender-male.png'
import resultGenderMaleWebp from 'assets/images/result-gender-male.webp'
import resultInfluencer from 'assets/images/result-influencer.jpg'
import resultInfluencerWebp from 'assets/images/result-influencer.webp'

import { PageId } from 'page-constants'
import { Cohort, Gender, MAIN_GOALS } from 'root-constants'

import { StyledIntroMedia as S } from './IntroMedia.styles'

export const IntroMedia: React.FC = () => {
  const userAnswers = useSelector(selectAnswers)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohort = useSelector(selectCurrentVariantCohort)

  const cohortToUse = parentCohort || cohort

  const { title } = useDynamicPaywallConfig()

  const isMale = useMemo(() => userAnswers?.[PageId.GENDER] === Gender.MALE, [
    userAnswers,
  ])
  const isInfluencerFlow = useMemo(() => cohortToUse === Cohort.YARINAS, [
    cohortToUse,
  ])

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.Title>
          {title || (
            <Trans
              i18nKey={
                isInfluencerFlow
                  ? 'purchase1.introMediaTitleInfluencer'
                  : 'purchase1.introMediaTitle'
              }
              components={[<br />]}
              values={{
                context: isStayFit
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          )}
        </S.Title>
      </S.TitleContainer>
      <S.MainImageContainer>
        {isInfluencerFlow ? (
          <S.MainImage>
            <source srcSet={resultInfluencerWebp} type="image/webp" />
            <img src={resultInfluencer} alt="Yarina’s dance moves" />
          </S.MainImage>
        ) : (
          <S.MainImage>
            <source
              srcSet={isMale ? resultGenderMaleWebp : resultGenderFemaleWebp}
              type="image/webp"
            />
            <img
              src={isMale ? resultGenderMalePng : resultGenderFemalePng}
              alt="body"
            />
          </S.MainImage>
        )}
      </S.MainImageContainer>
    </S.Wrapper>
  )
}
