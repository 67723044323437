import styled from 'styled-components'

import calendarIcon from 'assets/images/flow-belly/icons/calendar.svg'
import arrowIcon from 'assets/images/flow-belly/icons/next-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCustomDatePicker = {
  WrapperDataField: styled.div`
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.LIGHT};
    display: flex;
    justify-content: space-between;
    width: 175px;
    height: 80px;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
  `,
  DateField: styled.div`
    user-select: none;
  `,
  CalendarIcon: styled.div`
    width: 24px;
    height: 24px;
    right: calc(50% - 66px);
    bottom: 17px;
    background: url(${calendarIcon}) no-repeat center;

    path {
      display: none;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .react-datepicker__input-container {
      position: relative;
      display: flex;
      justify-content: center;

      svg {
        display: none;
      }
    }

    .react-datepicker {
      padding: 12px;
      min-width: 345px;
      background: ${Color.WHITE};
      box-shadow: 0px 8px 20px 0px ${Color.LIGHT};
      border-radius: 16px;
      border: none;
      color: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }

    .react-datepicker__navigation {
      width: 24px;
      height: 24px;
      top: 24px;
    }

    .react-datepicker__navigation--next {
      height: 24px;
      background: url(${arrowIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      right: 12px;

      span {
        display: none;
      }
    }

    .react-datepicker__navigation--previous {
      height: 24px;
      transform: rotate(-180deg);
      background: url(${arrowIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      left: 12px;

      span {
        display: none;
      }
    }

    .react-datepicker__current-month {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 16px;
      color: ${Color.DARK};
    }

    .react-datepicker__day-name {
      font-weight: 600;
      font-size: 14px;
      color: ${Color.GRAY_60};
      margin: 6px;
    }

    .react-datepicker__day--disabled {
      color: ${Color.GRAY_60};
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }

    .react-datepicker__week {
      .react-datepicker__day--today {
        color: ${Color.PRIMARY};
      }

      .react-datepicker__day--selected {
        background: #eaf7ff;
        border: 2px solid ${Color.PRIMARY};
        border-radius: 12px;
        color: ${Color.PRIMARY};
        outline: none;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
      }

      .react-datepicker__day {
        width: 36px;
        height: 36px;
        line-height: 30px;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      padding-top: 0;
      transform: translate3d(23px, 0px, 0px) !important;
      left: -37px !important;
      z-index: 5;

      @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
        transform: translate3d(43px, 190px, 0px);
      }
    }

    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      padding-top: 13px !important;
      padding-bottom: 0;
      background-color: transparent;
      border: none;
    }

    .react-datepicker__month {
      margin: 16px 12px;
    }

    .react-datepicker__month-container {
      width: 328px;
    }

    .react-datepicker__day--outside-month {
      color: ${Color.GRAY_60};
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: ${Color.DARK};
    }
  `,
}
