import { TRecommendationCard } from 'components/RecommendationCard/constants'

import checkMarkIcon from 'assets/images/flow-belly/icons/check-mark-icon-pink.svg'
import icon from 'assets/images/flow-belly/icons/flesh.svg'
import image from 'assets/images/flow-belly/supercharge-yourself-image.png'

import { Color } from 'root-constants'

export const QUESTION = 'Supercharge yourself'

export const cardStyleParams: TRecommendationCard = {
  backgroundColor:
    'radial-gradient(120.26% 112.91% at 93% 33.94%, #FFEAF0 1.55%, #F2B3C5 70.82%, #FBA6BE 88.05%)',
  headerBackgroundColor: '#FF5777',
  badgeImage: icon,
  headerTextColor: Color.WHITE,
  headerText: 'onboarding.superchargeYourself.headerText',
  title: 'onboarding.superchargeYourself.cardTitle',
  image,
  hasImagePadding: true,
  checkMarkImage: checkMarkIcon,
  points: [
    'onboarding.superchargeYourself.benefitPoints.superchargeYourself',
    'onboarding.superchargeYourself.benefitPoints.immerseYourself',
    'onboarding.superchargeYourself.benefitPoints.chargeYourself',
  ],
}
