import { Locale } from 'root-constants'

export const START_WORKING_HOURS = 13
export const END_WORKING_HOURS = 3
export const USER_GROUP_ID_PROD = {
  [Locale.ENGLISH]: '29',
  [Locale.SPANISH]: '86',
  [Locale.DEUTSCH]: '112',
  [Locale.FRENCH]: '129',
}

export const USER_GROUP_ID_DEV = {
  [Locale.ENGLISH]: '71',
  [Locale.SPANISH]: '90',
  [Locale.DEUTSCH]: '110',
  [Locale.FRENCH]: '128',
}
