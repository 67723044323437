import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  $color?: string
  $background?: string
}

const progressValueStyles = css<IProps>`
  background-color: currentColor;
  transition: all 0.2s ease-out;
  border-radius: 0 8px 8px 0;
`

export const StyledOnboardingProgressBar = {
  Wrapper: styled(Container)<IProps>`
    position: relative;
    margin: 16px auto;
    display: flex;
    justify-content: center;
    z-index: 1;
  `,
  StepBlock: styled.div`
    position: relative;

    min-width: 65px;
    width: 100%;

    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 8px;
    }
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    background-color: ${({ $background }) => $background || '#ecf0f7'};
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    color: ${({ $color }) => $color || Color.PRIMARY};
    border-radius: 8px;

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background-color: #ecf0f7;
    }

    &::-webkit-progress-value {
      background-color: ${Color.PRIMARY};
      border-radius: 8px;
    }

    &[data-active='true'] {
      &::-webkit-progress-value {
        background-color: ${Color.PRIMARY};
        border-radius: 8px;
      }
    }
  `,
}
