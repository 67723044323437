import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  $isSelected?: boolean
  $isMexican?: boolean
}

const PreviousPriceCommonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: ${Color.DISABLED};
  font-feature-settings: 'clig' off, 'liga' off;
  margin-bottom: 6px;
`

export const StyledSubscriptionItemWithLabel = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    border-radius: 16px;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    border: 2px solid #e3e4e8;
    padding: 15px;

    ${({ $isSelected }) =>
      $isSelected &&
      `
        border-radius: 16px;
        border: 2px solid ${Color.PRIMARY};
        `};

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-color: ${Color.WHITE};
          border: 8px solid ${Color.PRIMARY};
        `};
    }
  `,
  SpecialOffer: styled.div`
    width: 100%;
    max-width: 200px;
    display: inline-flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    align-items: flex-start;
    text-transform: uppercase;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    border-radius: 6px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    padding: 5px 10px;
  `,
  PlanPeriod: styled.span`
    padding-left: 36px;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    text-transform: uppercase;
    color: ${Color.DARK};
    white-space: nowrap;
  `,
  PlanContainer: styled.div`
    max-width: 180px;
  `,
  PriceContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > span {
      text-align: right;
    }
  `,
  FullPrices: styled.div`
    display: flex;
    padding-left: 36px;
  `,
  PreviousPriceCrossed: styled.span`
    ${PreviousPriceCommonStyles};
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  PreviousPrice: styled.span`
    ${PreviousPriceCommonStyles};
    margin-left: 4px;
  `,
  CurrentPrice: styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    min-height: 40px;
    color: ${Color.DARK};
  `,
  PriceValue: styled.span<TProps>`
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;

    ${({ $isMexican }) =>
      $isMexican &&
      `
          max-width: 100px;
          font-size: 22px;
          
      `}
  `,
  Period: styled.span`
    font-size: 14px;
    line-height: 14px;
    color: ${Color.DISABLED};
  `,
}
