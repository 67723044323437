import styled from 'styled-components'

import { Container } from 'components/Container'

import bgImage from 'assets/images/main-goal-bg-image-somatic.png'

import { Color } from 'root-constants'

export const StyledMainGoalAgeFlow = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{ $bgImageUrl?: string }>`
    background-image: ${({ $bgImageUrl }) =>
      ($bgImageUrl && `url(${$bgImageUrl})`) || `url(${bgImage})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    padding: 0 16px 140px;
  `,
}
