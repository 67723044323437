import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTenMillionPeople = {
  Image: styled.img`
    min-height: 224px;
    width: 100%;
    padding-bottom: 24px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 16px;
    margin-top: 16px;
  `,
  Subtitle: styled.div`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      font-weight: 700;
    }

    p:first-child {
      margin-bottom: 8px;
    }
  `,
}
