import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCharacteristicCard = {
  Card: styled.div`
    min-height: 100%;

    display: flex;
    align-items: stretch;

    padding: 12px;

    align-self: stretch;

    border-radius: 20px;

    color: ${Color.DARK};
    background-color: ${Color.LAVENDER_LIGHT};

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  `,
  ImgContainer: styled.div`
    min-width: 68px;
    max-width: 68px;
    min-height: 112px;
    margin-right: 12px;
  `,
  Img: styled.img`
    width: 100%;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  `,
  Title: styled.div`
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    & svg {
      margin-left: 4px;
    }
  `,
  Description: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  DanceStyleContainer: styled.div`
    display: flex;
  `,
  DanceStyle: styled.div`
    display: flex;
    align-items: flex-start;

    padding: 4px 8px;

    border-radius: 4px;

    font-size: 12px;
    font-weight: 700;
    line-height: 12px;

    background-color: #e6f7fe;
    color: ${Color.PRIMARY};

    &:not(:last-child) {
      margin-right: 4px;
    }
  `,
}
