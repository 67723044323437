import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledNavigationButton = {
  ButtonContainer: styled.div<{
    $isJapanese: boolean
    $hasClearBackground?: boolean
  }>`
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        border-top: 0.5px solid #cacaca;
        background: #faebff;
      `}
    ${({ $hasClearBackground }) =>
      $hasClearBackground &&
      css`
        background: transparent;
      `}
  `,
  Button: styled(Button)<{
    $isJapanese: boolean
    $disabled: boolean
  }>`
    max-width: 328px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border-radius: 30px;

    ${({ $isJapanese, $disabled }) =>
      $isJapanese &&
      css`
        border-radius: 30px;
        background: ${$disabled
          ? Color.GRAY_40
          : 'linear-gradient(90deg,#4ac7ff 0%,#03aaf5 79.2%,#01a3eb 100%)'};
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      `}
  `,
}
