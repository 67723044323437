import styled from 'styled-components'

import questionIcon from 'assets/images/purchaseNoOnboarding/question-icon.png'

import { Color } from 'root-constants'

export const StyledPeopleAsk = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
  `,
  Card: styled.div`
    padding-left: 52px;
    position: relative;
    margin-bottom: 40px;

    &:last-child {
      p span {
        display: none;
      }
    }

    &::after {
      content: '';
      width: 36px;
      height: 36px;
      border-radius: 12px;
      background: ${Color.PRIMARY} url(${questionIcon}) no-repeat center;
      background-size: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Question: styled.h3`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  Answer: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.GRAY_80};
    position: relative;
  `,
  DashedDecoration: styled.span`
    height: 90%;
    width: 2px;
    position: absolute;
    left: -34px;
    top: 30px;
    overflow: hidden;
    background-image: linear-gradient(
      to bottom,
      ${Color.PRIMARY},
      ${Color.PRIMARY} 60%,
      transparent 60%,
      transparent 100%
    );
    background-size: 20px 12px;

    &::after,
    &::before {
      display: block;
      content: '';
      width: 2px;
      height: 3px;
      background-color: ${Color.WHITE};
      position: absolute;
      left: 0;
      z-index: 2;
    }

    &::after {
      top: 0;
    }

    &::before {
      bottom: 0;
    }
  `,
}
