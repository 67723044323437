import { Cohort } from 'root-constants'

export const QUESTION = 'Creating your personalized plan...'

export const STATIC_ANIMATION_DURATION = 4000

export const REVIEWS_MAP = {
  [Cohort.DB_SOMATIC]: {
    first: 'onboarding.preparePlanSomatic.firstReview',
    second: 'onboarding.preparePlanSomatic.secondReview',
    third: 'onboarding.preparePlanSomatic.thirdReview',
  },
  [Cohort.DB_BELLY]: {
    first: 'onboarding.preparePlanV2.review',
    second: 'onboarding.preparePlanBelly.secondReview',
    third: 'onboarding.preparePlanBelly.thirdReview',
  },
}
