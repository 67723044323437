import i18n from 'i18next'

export function getMergedFocusAreas(focusAreas: string[]): string {
  return (focusAreas || ['legs']).reduce((text, value, index, arr) => {
    if (!index) return value

    const joint = index < arr.length - 1 ? ', ' : i18n.t('result.and')
    return `${text}${joint}${value}`
  }, '')
}
