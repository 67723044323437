/* eslint-disable max-lines */
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { PrimerPaymentDarkForm } from 'components/PrimerPaymentDarkForm'
import { Spinner } from 'components/Spinner'

import {
  GET_PAYMENT_CONFIG,
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { CreditCardFormCancelOfferDark } from 'modules/purchase/components/CreditCardFormCancelOfferDark'
import { PaymentElementButton } from 'modules/purchase/components/PaymentElementButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import { PaymentMethod } from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { useGetSubscription } from 'modules/purchase/hooks/useGetSubscription'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  GET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_PAYMENT_FORM_IS_LOADING,
  paymentElementPurchaseAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectPrimerClientSessionToken,
  selectSubscriptionLookupKey,
  selectSubscriptionTrialLookupKey,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ABC_TEST_VARIANTS,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  PlatformOS,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
} from 'root-constants'

import { EU_PM, USA_PM, WW_PM } from '../CheckoutSeparateMethods/constants'
import { StyledCheckoutModal as S } from './CheckoutModal.styles'

const USA_COUNTRY_CODE = 'US'

type TCheckoutExtended = {
  setIsCheckoutShown: (value: boolean) => void
  isCancelOfferPage?: boolean

  isCheckoutShown: boolean
}

export const CheckoutModal: FC<TCheckoutExtended> = ({
  isCancelOfferPage = false,
  setIsCheckoutShown,
  isCheckoutShown,
}) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  const paymentFormRef = useRef<HTMLDivElement>(null)

  const priceId = useSelector(selectSubscriptionLookupKey)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)

  const [isUpdated, setIsUpdated] = useState(false)
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[] | PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])
  const [selectedPaymentMethodTab, setSelectedPaymentMethodTab] = useState<
    PaymentMethod
  >(PaymentMethod.CREDIT_CARD)

  const isPPHidden = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1048)
  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)
  const isCancelOfferTestActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const variantOfModalCheckout = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_1291,
    ABC_TEST_VARIANTS.VARIANT_A,
  )

  const { isTrialCohort, isSevenDayPlanCohort, isSaleFlow } = useCohortInfo()
  const { isEUUser } = useCookieConsentAnswer()
  const {
    uuid,
    subscriptions,
    periodQuantity,
    trialPeriodDays,
    currency,
    stripeAccountId,
    stripeAccountName,
    screenName,
    screenId,
    selectedSubscription,
    countryCode,
    taxAmount,
    hasCancelOffer,
    dynamicDiscount,
    cohort,
    discountAmount,
    fullPriceTax,
    oldPriceBeforeCustomPercentDiscount,
    fullPrice,
    fetchingActionsList,
  } = usePurchaseStore()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)
  const hasPaypalButton = useHasPayPalButton()

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  const isCardVisible = useMemo(
    () =>
      isCheckoutShown &&
      isScrolled &&
      selectedPaymentMethodTab === PaymentMethod.CREDIT_CARD,
    [isCheckoutShown, isScrolled, selectedPaymentMethodTab],
  )

  const isHidePaymentMethodToggle = useMemo(
    () =>
      availablePaymentMethods.length === 1 &&
      availablePaymentMethods[0] === PaymentMethod.CREDIT_CARD,
    [availablePaymentMethods],
  )

  const productId = useMemo(
    () =>
      createIntroOfferProductId({
        priceId,
        trialPriceId,
        trialPeriodQuantity: trialPeriodDays,
      }),
    [priceId, trialPriceId, trialPeriodDays],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const isSevenDayPlan = useMemo(
    () =>
      isSevenDayPlanCohort &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [isSevenDayPlanCohort, periodQuantity],
  )

  const additionalPaymentMethods = useMemo(() => {
    if (isEUUser) return EU_PM

    if (countryCode === USA_COUNTRY_CODE) return USA_PM

    return WW_PM
  }, [countryCode, isEUUser])

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [countryCode, hasVatInfo, taxAmount],
  )

  const addAvailablePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (availablePaymentMethods.includes(paymentMethod)) return

      setAvailablePaymentMethods((currentValue) => [
        ...currentValue,
        paymentMethod,
      ])
    },
    [availablePaymentMethods],
  )

  useLayoutEffect(() => {
    if (!isPPHidden && hasPaypalButton) {
      addAvailablePaymentMethod(PaymentMethod.PAYPAL)
    }
  }, [hasPaypalButton, addAvailablePaymentMethod, isPPHidden])

  const subscription = useGetSubscription({
    isSevenDayPlan,
    isCancelOfferPage,
    hasExcludedVat,
    getCurrentPrice,
    isTrial: isTrialCohort,
  })

  const totalDiscount = useMemo(() => {
    const discountValue = isSaleFlow
      ? subscription.discountAmount
      : getCurrentPrice(
          isTrialCohort
            ? discountAmount
            : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
        )

    return `-${t('checkoutExtended.price', {
      price: formatJaPriceWithCommas(discountValue, currency),
      currency: currencyLabel,
    })}`
  }, [
    isSaleFlow,
    subscription.discountAmount,
    getCurrentPrice,
    isTrialCohort,
    discountAmount,
    oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
    t,
    currency,
    currencyLabel,
  ])

  const getCalculatedTotalPrice = () => {
    const subscriptionTotalPrice =
      isSevenDayPlan || !isTrialCohort
        ? fullPrice
        : selectedSubscription?.trialPrices?.fullPrice
    if (!subscriptionTotalPrice) return 0

    return Number(subscriptionTotalPrice.toFixed(2))
  }

  const isCheckoutReady = useMemo(
    () =>
      !!primerClientSessionToken &&
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING) &&
      !fetchingActionsList.includes(GET_PRIMER_CLIENT_SESSION_TOKEN) &&
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(GET_PAYMENT_CONFIG),
    [fetchingActionsList, primerClientSessionToken],
  )

  useEffect(() => {
    if (!isCheckoutReady || !isCheckoutShown) return

    eventLogger.logPaymentMethodSelected(selectedPaymentMethodTab)
  }, [isCheckoutReady, isCheckoutShown, selectedPaymentMethodTab])

  useEffect(() => {
    const { current: elem } = paymentFormRef
    setIsScrolled(false)

    if (
      elem &&
      isCheckoutReady &&
      selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD)
    ) {
      elem.scrollIntoView({
        behavior: 'smooth',
      })
      setIsScrolled(true)
    }
  }, [isCheckoutReady, selectedPaymentMethods])

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        screenId,
        accountId: stripeAccountId,
        accountName: stripeAccountName,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        selectedSubscription,
        isPersonalDataAllowed,
      })
    }
  }, [
    isPersonalDataAllowed,
    uuid,
    subscriptions,
    isCheckoutShown,
    productId,
    screenId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  ])

  const handlePaymentElementClick = async () => {
    if (!elements || !stripe) return

    const { error } = await elements.submit()

    if (error) {
      console.error(error)
    }

    dispatch(
      paymentElementPurchaseAction({
        stripe,
        elements,
      }),
    )
  }

  const handlePaymentMethodTabClick = useCallback(
    (paymentMethod: PaymentMethod) => {
      setIsScrolled(false)
      setSelectedPaymentMethodTab(paymentMethod)

      setSelectedPaymentMethods([paymentMethod])
    },
    [setIsScrolled, setSelectedPaymentMethodTab, setSelectedPaymentMethods],
  )

  const addPrimaryPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      addAvailablePaymentMethod(paymentMethod)
      handlePaymentMethodTabClick(paymentMethod)
    },
    [addAvailablePaymentMethod, handlePaymentMethodTabClick],
  )

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: isCancelOfferTestActive
        ? PageId.CANCEL_OFFER_QUESTION_V2
        : PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    isCancelOfferTestActive,
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  if (!selectedSubscription) return null

  return (
    <>
      <S.Wrapper>
        {!isCheckoutReady && <Spinner />}
        <S.Container
          $hasBottomPadding={
            isAndroid && selectedPaymentMethodTab === PaymentMethod.CREDIT_CARD
          }
        >
          <S.ModalHeader>
            <S.ModalName>
              <Trans i18nKey="purchaseRef.checkout.modalName" />
            </S.ModalName>
            <S.Cross onClick={handleCloseCheckout} />
          </S.ModalHeader>
          <S.Title>
            <Trans
              i18nKey="purchaseRef.checkout.title"
              values={{
                count: trialPeriodDays,
              }}
            />
          </S.Title>
          {variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_B && (
            <S.Total>
              <span>
                <Trans
                  i18nKey={
                    hasExcludedVat
                      ? 'purchaseRef.checkout.totalWithVat'
                      : 'purchaseRef.checkout.total'
                  }
                  values={{ context: countryCode }}
                />
              </span>

              <span>
                <Trans
                  i18nKey="checkoutExtended.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      getCalculatedTotalPrice(),
                      currency,
                    ),
                    currency: currencyLabel,
                  }}
                />
              </span>
            </S.Total>
          )}
          {variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_C && (
            <>
              <S.PriceDescription>
                <S.PriceInfo>
                  <S.PriceBlockText>
                    <Trans i18nKey="purchaseCancelOffer.checkout.personalizedPlan" />
                  </S.PriceBlockText>
                  <S.PriceBlockText>
                    <Trans
                      i18nKey="checkoutExtended.price"
                      values={{
                        price: formatJaPriceWithCommas(
                          subscription.totalAmount,
                          currency,
                        ),
                        currency: currencyLabel,
                      }}
                    />
                  </S.PriceBlockText>
                </S.PriceInfo>

                {!isSevenDayPlan && isTrialCohort && (
                  <S.PriceInfo>
                    <S.PriceBlockText>
                      {dynamicDiscount?.checkout && isSaleFlow ? (
                        <Trans
                          i18nKey="checkoutExtended.discount"
                          values={{
                            percentage: subscription.percentage,
                            checkout: dynamicDiscount.checkout,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="checkoutExtended.introductoryDiscount"
                          values={{
                            discountPercentage: subscription.percentage,
                          }}
                        />
                      )}
                    </S.PriceBlockText>
                    <S.TotalDiscount>{totalDiscount}</S.TotalDiscount>
                  </S.PriceInfo>
                )}

                {hasExcludedVat && (
                  <S.PriceInfo>
                    <S.PriceBlockText>
                      {t(`purchase1.valueAddedTax`, { context: countryCode })}
                    </S.PriceBlockText>
                    <S.PriceBlockText>
                      <Trans
                        i18nKey="checkoutExtended.price"
                        values={{
                          price: formatJaPriceWithCommas(
                            trialPeriodDays
                              ? selectedSubscription?.trialPrices.fullPriceTax
                              : fullPriceTax,
                            currency,
                          ),
                          currency: currencyLabel,
                        }}
                      />
                    </S.PriceBlockText>
                  </S.PriceInfo>
                )}
              </S.PriceDescription>

              <S.DailyPayment>
                <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
                <S.DailyAmount>
                  <Trans
                    i18nKey="checkoutExtended.price"
                    values={{
                      price: formatJaPriceWithCommas(
                        getCurrentPrice(
                          isSevenDayPlan || !isTrialCohort
                            ? selectedSubscription.mainPrices.daily
                            : selectedSubscription.trialPrices.daily,
                        ),
                        currency,
                      ),
                      currency: currencyLabel,
                    }}
                  />
                </S.DailyAmount>
              </S.DailyPayment>

              <S.TotalPayment>
                <S.TotalDescription>
                  <S.TotalText>{t('checkoutExtended.total')}</S.TotalText>
                  <S.TotalAmount>
                    <Trans
                      i18nKey="checkoutExtended.totalPrice"
                      values={{
                        totalAmount: formatJaPriceWithCommas(
                          getCalculatedTotalPrice(),
                          currency,
                        ),
                        currency: currencyLabel,
                        periodQuantity,
                        context:
                          isSevenDayPlan || !isTrialCohort
                            ? periodQuantity
                            : selectedSubscription.trialPrices.durationDays,
                      }}
                    />
                  </S.TotalAmount>
                </S.TotalDescription>
                {!isSevenDayPlan && isTrialCohort && (
                  <>
                    <S.SaveText>
                      <Trans
                        i18nKey="checkoutExtended.savedAmount"
                        values={{
                          savedAmount: formatJaPriceWithCommas(
                            subscription.discountAmount,
                            currency,
                          ),
                          currency: currencyLabel,
                          discountPercentage: subscription.percentage,
                        }}
                      />
                    </S.SaveText>
                  </>
                )}
              </S.TotalPayment>
            </>
          )}

          <S.PayFastTitle
            $isHidePaymentMethodToggle={isHidePaymentMethodToggle}
          >
            <Trans i18nKey="purchaseRef.checkout.paymentTitle" />
          </S.PayFastTitle>

          {!isHidePaymentMethodToggle && (
            <S.PaymentMethodToggle
              availablePaymentMethods={availablePaymentMethods}
              selectedPaymentMethodTab={selectedPaymentMethodTab}
              handlePaymentMethodTabClick={handlePaymentMethodTabClick}
            />
          )}

          <S.PaymentFormWrapper
            $isHidePaymentMethodToggle={isHidePaymentMethodToggle}
            ref={paymentFormRef}
          >
            <S.PaymentMethodContainer
              $isVisible={
                (selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD) &&
                  isPrimerActive) ||
                selectedPaymentMethods.includes(PaymentMethod.PAYPAL)
              }
            >
              {isCheckoutShown && (
                <PrimerPaymentDarkForm
                  key={selectedSubscription?.id}
                  showPayPalButton={selectedPaymentMethods.includes(
                    PaymentMethod.PAYPAL,
                  )}
                  showCardForm={selectedPaymentMethods.includes(
                    PaymentMethod.CREDIT_CARD,
                  )}
                />
              )}
            </S.PaymentMethodContainer>

            {selectedPaymentMethods.map((paymentMethod) => (
              <S.PaymentMethodContainer
                key={paymentMethod}
                $isVisible={additionalPaymentMethods.includes(paymentMethod)}
              >
                <PaymentElementButton
                  paymentMethod={paymentMethod}
                  setIsUpdated={setIsUpdated}
                />
              </S.PaymentMethodContainer>
            ))}

            {!isPrimerActive && (
              <S.PaymentMethodContainer
                $isVisible={selectedPaymentMethods.includes(
                  PaymentMethod.CREDIT_CARD,
                )}
              >
                <CreditCardFormCancelOfferDark
                  isCheckoutVisible={isCardVisible}
                />
              </S.PaymentMethodContainer>
            )}

            <S.ButtonTitle
              $isVisible={
                selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
                selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
              }
            >
              <Trans i18nKey="purchaseRef.checkout.advantages" />
            </S.ButtonTitle>

            <S.PaymentMethodContainer
              $isVisible={
                selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
                selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
              }
            >
              <StripeSeparatePaymentRequestButton
                isVisible={
                  selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
                  selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
                }
                setAlternativePaymentMethodCallback={addPrimaryPaymentMethod}
              />
            </S.PaymentMethodContainer>
          </S.PaymentFormWrapper>

          {isUpdated && (
            <div
              style={{
                display: 'none',
              }}
            >
              <PaymentElement
                onReady={(element) => element.blur()}
                onChange={handlePaymentElementClick}
              />
            </div>
          )}
        </S.Container>
      </S.Wrapper>

      {isCheckoutShown &&
        (isPrimerActive ? (
          <PaymentPrimerWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ) : (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ))}
    </>
  )
}
