import styled from 'styled-components'

import { Container } from 'components/Container'

import bgImage from 'assets/images/exhausted-bg-image-somatic.png'

export const StyledExhausted = {
  Container: styled(Container)<{ $bgImageUrl?: string }>`
    padding: 0;
  `,
  ImageContainer: styled.div<{ $bgImageUrl?: string }>`
    background-image: ${({ $bgImageUrl }) =>
      ($bgImageUrl && `url(${$bgImageUrl})`) || `url(${bgImage})`};
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 375px 500px;
    padding: 0 16px 105px;
  `,
}
