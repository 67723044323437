import styled from 'styled-components'

import leftBranch from 'assets/images/left-branch.svg'
import rightBranch from 'assets/images/right-branch.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTenMillionPeople = {
  Title: styled.h2<{ $isJapanese: boolean }>`
    position: relative;
    font-size: ${({ $isJapanese }) => ($isJapanese ? '24px' : '32px')};
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;
    color: ${Color.PRIMARY};
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 34px;
      height: 57px;
      background: url(${rightBranch});
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 34px;
      height: 57px;
      background: url(${leftBranch});
    }
  `,
  Subtitle: styled.h3`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  PictureContainer: styled.div`
    margin: 0 auto 32px auto;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 327px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
}
