import midSizedJa from 'assets/images/mid-size-ja.png'
import midSizedLegs from 'assets/images/mid-sized-legs.png'
import midSizedAdult from 'assets/images/mid-sized-physical-build.png'
import midSized from 'assets/images/mid-sized.png'
import midSizedMenopause from 'assets/images/midsize-menopause.png'
import overweightJa from 'assets/images/overweight-ja.png'
import overweightLegs from 'assets/images/overweight-legs.png'
import overweightMenopause from 'assets/images/overweight-menopause.png'
import overweightAdult from 'assets/images/overweight-physical-build.png'
import overweight from 'assets/images/overweight.png'
import plusSizedJa from 'assets/images/plus-size-ja.png'
import plusSizedMenopause from 'assets/images/plus-size-menopause.png'
import plusSizedLegs from 'assets/images/plus-sized-legs.png'
import plusSizedAdult from 'assets/images/plus-sized-physical-build.png'
import plusSized from 'assets/images/plus-sized.png'
import slimJa from 'assets/images/slim-ja.png'
import slimLegs from 'assets/images/slim-legs.png'
import slimMenopause from 'assets/images/slim-menopause.png'
import slimAdult from 'assets/images/slim-physical-build.png'
import slim from 'assets/images/slim.png'

export const QUESTION = 'What image describes your physical build and belly?'

export const OPTION_VALUES = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slim,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSized,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSized,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweight,
  },
]
export const OPTION_VALUES_MENOPAUSE = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slimMenopause,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSizedMenopause,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSizedMenopause,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweightMenopause,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slimJa,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSizedJa,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSizedJa,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweightJa,
  },
]
export const OPTION_VALUES_LEGS = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slimLegs,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSizedLegs,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSizedLegs,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweightLegs,
  },
]

export const OPTION_VALUES_ADULT = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slimAdult,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSizedAdult,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSizedAdult,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweightAdult,
  },
]
