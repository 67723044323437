import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import {
  BUTTON_WITH_ICON_STYLE_PROPS,
  Color,
  GROWTHBOOK_EXPERIMENT,
} from 'root-constants'

import { StyledWorkScheduleBelly as S } from './WorkScheduleBelly.styles'
import { OPTION_VALUES, OPTION_VALUES_JAPANESE, QUESTION } from './constants'

export const WorkScheduleBelly: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const { isNewAdultFlow } = useCohortInfo()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...BUTTON_WITH_ICON_STYLE_PROPS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={24}>
        {t('onboarding.workSchedule.question')}
      </PageTitle>

      {(isJapaneseFlow || isNewAdultFlow
        ? OPTION_VALUES_JAPANESE
        : OPTION_VALUES
      ).map(({ value, title, iconSrc }) => (
        <AnswerWithIcon
          {...optionProps}
          theme={theme}
          value={value}
          iconSrc={iconSrc}
          padding="16px 7px 16px 16px"
          color={Color.DARK}
          key={value}
          disabled={isAnswersDisabled}
        >
          {t(title)}
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
