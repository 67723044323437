import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButton } from 'components/NavigationButton'

import { Color } from 'root-constants'

type TBoostUnlockedProps = {
  $isAgeFlow: boolean
}

export const StyledBoostUnlocked = {
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)<
    TBoostUnlockedProps
  >`
    height: ${({ $isAgeFlow }) => $isAgeFlow && 'auto'};
  `,
  Container: styled(Container)<TBoostUnlockedProps>`
    ${({ $isAgeFlow }) => $isAgeFlow && StyledAgeFlowContainer}

    padding-top: 8px;
  `,
  Graph: styled.div`
    min-width: 328px;
    min-height: 196px;
    padding: 10px 8px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: ${Color.WHITE};
  `,
  Text: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    strong {
      font-weight: 700;
    }

    &:first-of-type {
      margin-bottom: 16px;
    }
  `,
  ButtonContainer: styled.div<TBoostUnlockedProps>`
    ${({ $isAgeFlow }) =>
      !$isAgeFlow &&
      css`
        width: 100%;
        background-color: ${Color.WHITE};
        box-shadow: 0px 0.5px 0px 0px ${Color.GRAY_40} inset;
      `}
  `,
  NavigationButton: styled(NavigationButton)<TBoostUnlockedProps>`
    padding: ${({ $isAgeFlow }) => !$isAgeFlow && '16px 0'};
  `,
}
