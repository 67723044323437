import styled from 'styled-components'

type TContainerProps = {
  $padding: number
}

export const StyledDisclaimer = {
  Container: styled.p<TContainerProps>`
    position: relative;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #b8bbc1;
    padding: ${({ $padding }) => `${$padding}px 0`};

    a {
      color: #b8bbc1;
      text-decoration: underline;
    }
  `,
}
