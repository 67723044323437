import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledSubscriptionItemLiveChat = {
  Container: styled.div<{ $isSelected: boolean }>`
    width: 343px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #e2e4e6;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(166, 179, 194, 0.3);

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        border: 2px solid ${Color.PRIMARY};
        background: #eaf7ff;
      `};
  `,
  YearPrice: styled.div<{ $isSelected: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      border: 2px solid #e3e4e8;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-radius: 50%;
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
              background-image: url(${checkIcon});
              background-position: center center;
              background-repeat: no-repeat;
              background-color: ${Color.PRIMARY};
              border: none;
            `};
    }
  `,
  PlanDuration: styled.div`
    flex: 1 0 0;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    padding-left: 37px;
  `,
  TrialPrice: styled.div`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  DiscountBlock: styled.div`
    display: flex;
    width: 271px;
    justify-content: space-between;
    align-items: center;
  `,
  Badge: styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    display: flex;
    padding: 4px 10px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 35px;
    background: linear-gradient(270deg, #59d7fc 2.08%, #0398f5 95.88%);
    color: ${Color.WHITE};
  `,
  FullPrice: styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #f83b00;
    text-align: right;
    text-decoration: line-through;
  `,
  BonusContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-top: 12px;
    border-top: 1px solid #e9e9e9;
  `,
  BonusTitle: styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;

    & strong {
      color: ${Color.PRIMARY};
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  `,
  BonusPrice: styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.PRIMARY};
  `,
}
