import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { PagesSource } from 'root-constants'

import { StyledSubscriptionDisclaimer as S } from './Disclaimer.styles'

type TProps = {
  className?: string
  disclaimerText: React.ReactNode
}

export const Disclaimer: React.FC<TProps> = ({
  className = '',
  disclaimerText,
}) => {
  const { t } = useTranslation()

  return (
    <S.Disclaimer className={className} data-testid="disclaimer-text">
      {disclaimerText}
      <TermsOfUseLink source={PagesSource.PAYWALL} /> {t('commonComponents.or')}{' '}
      <ContactWithSupport
        source={PagesSource.PAYWALL}
        text={t('commonComponents.contactSupportDisclaimer')}
      />
    </S.Disclaimer>
  )
}
