import React from 'react'

import { StyledContainer as S } from './Container.styles'

type TProps = {
  className?: string
  isLarge?: boolean
  children?: React.ReactNode
}

export const Container: React.FC<TProps> = ({
  children,
  isLarge,
  className = '',
}) => (
  <S.Container className={className} $isLarge={isLarge}>
    {children}
  </S.Container>
)
