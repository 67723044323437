import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { SubscriptionItemMealPlan } from 'modules/purchase/components/SubscriptionItemMealPlan'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import mealPlanCheck from 'assets/images/meal-plan-check.svg'

import { StyledSubscriptionsBlockMealPlan as S } from './SubscriptionsBlockMealPlan.styles'

export const SubscriptionsBlockMealPlan: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { subscriptionBlockTitle, title, subtitle } = useDynamicPaywallConfig()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      <S.Title>{title || t`purchaseCancelOfferMealPlan.title`}</S.Title>
      <S.Subtitle>
        {subtitle || t`purchaseCancelOfferMealPlan.subtitle`}
      </S.Subtitle>
      <S.Plans>
        <S.PlansTitle>
          {subscriptionBlockTitle ||
            t`purchaseCancelOfferMealPlan.subscriptions.title`}
        </S.PlansTitle>
        <S.Options>
          {subscriptions.map((subscription) => (
            <Option
              type={OptionType.RADIO}
              name="subscription"
              value={subscription.id}
              key={subscription.id}
              onChange={handleChange}
              withoutMargin
            >
              <SubscriptionItemMealPlan
                subscription={subscription}
                isSelected={subscription.id === selectedSubscriptionId}
              />
            </Option>
          ))}
          <S.MealPlan>
            <S.MealPlanTitle>{t`purchaseCancelOfferMealPlan.subscriptions.mealPlanTitle`}</S.MealPlanTitle>
            <S.MealPlanCard>
              <S.MealPlanCardIcon src={mealPlanCheck} alt="" />
              <S.MealPlanCardText>{t`purchaseCancelOfferMealPlan.subscriptions.mealPlanDescription`}</S.MealPlanCardText>
              <S.MealPlanCardLabel>{t`purchaseCancelOfferMealPlan.subscriptions.mealPlanLabel`}</S.MealPlanCardLabel>
            </S.MealPlanCard>
          </S.MealPlan>
        </S.Options>
      </S.Plans>
    </S.Wrapper>
  )
}
