import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender } from 'root-constants'

import { StyledExhausted as S } from './Exhausted.styles'
import { OPTION_VALUES_SOMATIC, QUESTION } from './constants'

export const Exhausted: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()
  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container isLarge>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.exhausted.question" />
      </PageTitle>

      <S.ImageContainer $bgImageUrl={imageUrl}>
        {OPTION_VALUES_SOMATIC.map(({ value, title }) => (
          <Answer
            {...optionProps}
            theme={answerTheme.DANCEBIT}
            maxWidth="180px"
            margin="0 0 16px 0"
            disabled={isAnswersDisabled}
            value={value}
            key={value}
          >
            {t(title)}
          </Answer>
        ))}
      </S.ImageContainer>
    </S.Container>
  )
}
