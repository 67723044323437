import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Input } from 'storybook-ui'

import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import {
  ALPHABETIC_SYMBOLS_REGEXP,
  Color,
  GROWTHBOOK_EXPERIMENT,
} from 'root-constants'

import { StyledName as S } from './NameBelly.styles'
import { QUESTION } from './constants'

export const NameBelly: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const [name, setName] = useState({ value: '', isValid: false })

  const { isNewAdultFlow } = useCohortInfo()

  const handleChange = useCallback(({ target: { value, validity } }) => {
    const isNameValid = !value.match(ALPHABETIC_SYMBOLS_REGEXP)
    if (isNameValid) {
      setName({
        value,
        isValid: validity.valid && value.trim(),
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(name.value)
  }, [name.value, handleContinue])

  return (
    <>
      <S.Container
        isLarge
        $isJapanese={isJapaneseFlow}
        $isNewAdultFlow={isNewAdultFlow}
      >
        <PageTitle marginBottom={8}>{t('onboarding.name.question')}</PageTitle>
        <S.PageDescription>
          {t('onboarding.name.description')}
        </S.PageDescription>
        <S.InputContainer>
          <Input
            theme="dancebitText"
            type="text"
            placeholder={t('onboarding.name.placeholder')}
            placeholderColor={Color.GRAY_60}
            border={
              isNewAdultFlow
                ? '1px solid #C6E0F4'
                : `1px solid ${Color.GRAY_40}`
            }
            value={name.value}
            onChange={handleChange}
          />
        </S.InputContainer>
      </S.Container>

      <S.NavigationButtonBelly
        type="submit"
        hasClearBackground
        disabled={!name.isValid}
        onClick={handleNextClick}
      >
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </>
  )
}
