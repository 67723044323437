import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledNavigationButton = {
  ButtonContainer: styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};
  `,
  Button: styled(Button)`
    max-width: 328px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border-radius: 30px;
  `,
}
