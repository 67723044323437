import styled from 'styled-components'

import iconQuestion from 'assets/images/icon-question.svg'
import newIconQuestion from 'assets/images/question-symbol.svg'

type TFAQListProps = { $hasNewQuestionIcon: boolean }

export const StyledFAQList = {
  Wrapper: styled.div``,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  ListBody: styled.ul`
    margin-top: 30px;
  `,
  ListItem: styled.li<TFAQListProps>`
    position: relative;
    padding-left: 50px;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 8px;
      width: 32px;
      height: 32px;
      background: ${({ $hasNewQuestionIcon }) =>
        $hasNewQuestionIcon
          ? `url(${newIconQuestion})`
          : `url(${iconQuestion})`};
      background-size: contain;
    }
  `,
  ListItemTitle: styled.h3`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  `,
  ListItemText: styled.p`
    margin-bottom: 44px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
}
