import birthdayParty from 'assets/images/flow-belly/icons/birthday-party.svg'
import familyGathering from 'assets/images/flow-belly/icons/family-gathering.svg'
import feelMyBest from 'assets/images/flow-belly/icons/feel-my-best.svg'
import otherOccasion from 'assets/images/flow-belly/icons/other-occasion.svg'
import schoolReunion from 'assets/images/flow-belly/icons/school-reunion.svg'
import sportsEvent from 'assets/images/flow-belly/icons/sport.svg'
import summer from 'assets/images/flow-belly/icons/summer.svg'
import vacation from 'assets/images/flow-belly/icons/vacation.svg'
import wedding from 'assets/images/flow-belly/icons/wedding.svg'

export const QUESTION =
  'Is there any specific event motivating you to lose weight right now?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.motivatingEvent.vacation',
    value: 'vacation',
    imageUrl: vacation,
  },
  {
    text: 'onboarding.motivatingEvent.wedding',
    value: 'wedding',
    imageUrl: wedding,
  },
  {
    text: 'onboarding.motivatingEvent.sportsEvent',
    value: 'sportsEvent',
    imageUrl: sportsEvent,
  },
  {
    text: 'onboarding.motivatingEvent.summer',
    value: 'summer',
    imageUrl: summer,
  },
  {
    text: 'onboarding.motivatingEvent.schoolReunion',
    value: 'schoolReunion',
    imageUrl: schoolReunion,
  },
  {
    text: 'onboarding.motivatingEvent.familyGathering',
    value: 'familyGathering',
    imageUrl: familyGathering,
  },
  {
    text: 'onboarding.motivatingEvent.birthdayParty',
    value: 'birthdayParty',
    imageUrl: birthdayParty,
  },
  {
    text: 'onboarding.motivatingEvent.otherOccasion',
    value: 'otherOccasion',
    imageUrl: otherOccasion,
  },
  {
    text: 'onboarding.motivatingEvent.feelMyBest',
    value: 'feelMyBest',
    imageUrl: feelMyBest,
  },
]
