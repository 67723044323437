import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

import { IProps as IProgressProps } from './Progress'

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledProgress = styled.progress<IProgressProps>`
  appearance: none;
  border: none;
  display: block;
  width: 100%;
  height: 100%;
  background: ${({ $background }) => $background || Color.WHITE};
  color: ${({ color }) => color || Color.PRIMARY};

  &::-webkit-progress-bar {
    background: ${({ $background }) => $background || Color.WHITE};
    color: ${({ color }) => color || Color.PRIMARY};
  }
  &::-webkit-progress-value {
    ${progressValueStyles}
  }
  &::-moz-progress-bar {
    ${progressValueStyles}
  }
  &::-ms-fill {
    ${progressValueStyles}
  }
`
