import React, { FC, RefObject, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useIsInViewport } from 'hooks/useIsInViewport'

import { ButtonWithAnimation } from '../ButtonWithAnimation'
import { StyledHeaderWithTimer as S } from './HeaderWithTimer.styles'

type TProps = {
  onButtonClick: (event) => void
  firstElementRef: RefObject<HTMLElement>
  secondElementRef: RefObject<HTMLElement>
  thirdElementRef: RefObject<HTMLElement>
  className?: string
}

export const HeaderWithTimer: FC<TProps> = ({
  firstElementRef,
  secondElementRef,
  thirdElementRef,
  onButtonClick,
  className,
}) => {
  const { t } = useTranslation()

  const isFirstElementVisible = useIsInViewport(firstElementRef)
  const isSecondElementVisible = useIsInViewport(secondElementRef)
  const isThirdElementVisible = useIsInViewport(thirdElementRef)

  const isTimerVisible = useMemo(
    () =>
      !(
        isFirstElementVisible ||
        isSecondElementVisible ||
        isThirdElementVisible
      ),
    [isFirstElementVisible, isSecondElementVisible, isThirdElementVisible],
  )

  return (
    <S.TimerContainer $isVisible={isTimerVisible} className={className}>
      <S.TextContainer>
        <S.Text>
          <Trans
            i18nKey="purchaseV2.timerText"
            values={{
              discount: 50,
            }}
          />
        </S.Text>
        <S.Timer />
      </S.TextContainer>

      <S.ButtonContainer>
        <ButtonWithAnimation />
        <S.Button
          type="button"
          data-order="1"
          data-text="get_my_plan"
          onClick={onButtonClick}
        >
          {t('actions.getMyPlan')}
        </S.Button>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
