import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/price-badge-new.svg'
import selectedPriceBadgeCancelOfferUrl from 'assets/images/selected-price-badge-cancel-offer.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge-wide.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $isCancelOffer: boolean
  $isBellyFlow: boolean
}

type TWrapperProps = {
  $isSelected: boolean
  $isCancelOffer?: boolean
}

type TPriceProps = { $hasSmallPrices: boolean; $isBellyFlow: boolean }

const commonPriceStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

const crossedPriceStyles = css<{ $isSelected: boolean }>`
  color: ${Color.GRAY_60};
  text-decoration: line-through;
  text-decoration-color: ${({ $isSelected }) =>
    $isSelected ? Color.RED : Color.GRAY_60};
`

export const StyledSubscriptionItemIntroOffer = {
  Wrapper: styled.div<TWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 10px 0;
    border-radius: 24px;
    background: ${Color.WHITE};

    ${({ $isSelected }) =>
      `
         ${$isSelected && `border: 2px solid ${Color.PRIMARY}`};
        
        ${
          !$isSelected &&
          `
          padding: 11px 1px;
          border: 1px solid rgba(255, 255, 255, 0.80);
          background: rgba(255, 255, 255, 0.40);
          backdrop-filter: blur(4px);
        `
        }
      `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 2px solid ${Color.GRAY_40};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected, $isCancelOffer }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${$isCancelOffer ? '#b1446b' : Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -10px;
    left: 55px;
    transform: translateY(-50%);
    padding: 3px 10px;
    border-radius: 16px 1px;
    background: linear-gradient(270deg, #59d7fc 2.08%, #0398f5 95.88%);
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<TWrapperProps>`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${Color.GRAY_60};
    padding-bottom: 2px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }

    ${({ $isSelected }) =>
      `
         ${$isSelected && `color: ${Color.DARK};`};
      `};
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span<{
    $isSelected: boolean
  }>`
    ${crossedPriceStyles};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  `,
  PreviousPrice: styled.span<TPriceProps & { $isSelected: boolean }>`
    ${commonPriceStyles};
    ${crossedPriceStyles};
    padding-right: 6px;
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CurrentPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    color: ${Color.GRAY_60};
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 88px;
    min-height: 57px;
    margin-right: 10px;
    padding: 8px 4px;
    color: ${Color.GRAY_60};
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ $isSelected, $isCancelOffer }) =>
      $isSelected &&
      `
        background-image: url(${
          $isCancelOffer
            ? selectedPriceBadgeCancelOfferUrl
            : selectedPriceBadgeUrl
        });
        color: #ffffff;
        
        & > span:first-child {
        color: rgba(255, 255, 255, 0.80);
        text-decoration-color: ${Color.RED};
        }
    
    `};
  `,
  PriceValue: styled.span<{ $isClearFlow: boolean }>`
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    padding: 2px 0;

    ${({ $isClearFlow }) =>
      $isClearFlow &&
      `
        font-size: 14px;
    `};
  `,
  Period: styled.span<{ $isSelected: boolean }>`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    color: ${({ $isSelected }) =>
      $isSelected ? 'rgba(255, 255, 255, 0.8)' : Color.GRAY_60};
  `,
}
