import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { FixedButton } from 'components/FixedButton'
import { ResultMotivationStats } from 'components/ResultMotivationStats'
import { ResultPersonalizedProgram } from 'components/ResultPersonalizedProgram'
import { ResultSlider } from 'components/ResultSlider'
import { ResultTrainingCard } from 'components/ResultTrainingCard'
import { Spinner } from 'components/Spinner'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'
import { selectHasEmail } from 'root-redux/selects/user'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePaypalSubscriptionAfterRedirect } from 'hooks/usePaypalSubscriptionAfterRedirect'

import { PaymentStatusModal } from 'modules/purchase/components/PaymentStatusModal'

import { eventLogger } from 'services/eventLogger.service'

import blogPhoto1 from 'assets/images/result-age-blog-1.jpg'
import blogPhotoWebp1 from 'assets/images/result-age-blog-1.webp'
import blogPhoto2 from 'assets/images/result-age-blog-2.jpg'
import blogPhotoWebp2 from 'assets/images/result-age-blog-2.webp'
import blogPhoto3 from 'assets/images/result-age-blog-3.jpg'
import blogPhotoWebp3 from 'assets/images/result-age-blog-3.webp'
import enjoyDancingAge from 'assets/images/result-enjoy-dancing-age.png'
import enjoyDancingAgeWebp from 'assets/images/result-enjoy-dancing-age.webp'
import resultGenderAgeFemale from 'assets/images/result-gender-female-age.png'
import resultGenderAgeFemaleWebp from 'assets/images/result-gender-female-age.webp'
import resultGenderAgeMale from 'assets/images/result-gender-male-age.png'
import resultGenderAgeMaleWebp from 'assets/images/result-gender-male-age.webp'

import { goTo } from 'browser-history'
import {
  ADULT_COHORT,
  Cohort,
  GenderStepOption,
  MAIN_GOALS,
} from 'root-constants'

import { StyledResult as S } from './Result.styles'
import { DEFAULT_TITLE, GRAPH, TITLE_PATHS } from './constants'

export const Result: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const userAnswers = useSelector(selectAnswers)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const hasEmail = useSelector(selectHasEmail)

  const cohortToUse = parentCohort || cohort

  const { currentSubscriptionPageId } = useGetPageInfo()
  const { imageUrls } = useDynamicOBConfig()
  const { buttonText, title, subtitle } = useDynamicPaywallConfig()

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const { personalCardImg, bannerImg, anyLevelImg } = useMemo(() => {
    const urls = imageUrls || []

    return {
      personalCardImg: urls[0],
      bannerImg: urls[1],
      anyLevelImg: urls[2],
    }
  }, [imageUrls])

  const isFemale = useMemo(
    () => userAnswers?.gender === GenderStepOption.FEMALE,
    [userAnswers],
  )

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const goal = useMemo(
    () => (isStayFitFlow ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitFlow],
  )

  const SelectedGraphComponent = useMemo(() => GRAPH.ageFlow[goal], [goal])

  const resultSubtitle = useMemo(() => {
    if (isClearCohort) return 'result.subtitleClearEdition'

    return 'result.subtitleAgeFlow'
  }, [isClearCohort])

  usePaypalSubscriptionAfterRedirect()

  useEffect(() => {
    eventLogger.logResultPageShown()
  }, [])

  const handleClick = useCallback(() => {
    eventLogger.logResultPageCompleted()

    goTo({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [currentSubscriptionPageId, search])

  return (
    <>
      {!hasEmail ? (
        <Spinner />
      ) : (
        <S.Root>
          <S.IntroMediaContainer>
            <S.Title>
              {title || (
                <Trans
                  i18nKey={TITLE_PATHS[cohortToUse as Cohort] || DEFAULT_TITLE}
                  components={[<br />]}
                  values={{
                    context: isStayFitFlow
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              )}
            </S.Title>

            <S.MainImageContainer>
              {bannerImg ? (
                <S.Image src={bannerImg} alt="Body before and after" />
              ) : (
                <S.MainImage>
                  <source
                    srcSet={
                      isFemale
                        ? resultGenderAgeFemaleWebp
                        : resultGenderAgeMaleWebp
                    }
                    type="image/webp"
                  />
                  <S.Image
                    src={isFemale ? resultGenderAgeFemale : resultGenderAgeMale}
                    alt="Body before and after"
                  />
                </S.MainImage>
              )}
            </S.MainImageContainer>
          </S.IntroMediaContainer>

          <S.Container>
            {SelectedGraphComponent && <SelectedGraphComponent />}

            <S.ResultButton onClick={handleClick}>
              {buttonText || t`result.action`}
            </S.ResultButton>

            <S.Section>
              <S.Subtitle>
                {subtitle || (
                  <Trans
                    i18nKey={resultSubtitle}
                    components={[<br />]}
                    values={{
                      context: isStayFitFlow
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                )}
              </S.Subtitle>

              <S.ProgramAdvantage>
                <Trans i18nKey="result.advantageAge" />
              </S.ProgramAdvantage>

              <picture>
                <source srcSet={enjoyDancingAgeWebp} type="image/webp" />
                <S.Image src={enjoyDancingAge} alt="Enjoy Dancing" />
              </picture>
            </S.Section>

            <S.Section>
              <ResultMotivationStats />
            </S.Section>

            <S.Section>
              <ResultTrainingCard personImg={personalCardImg} />
              <FixedButton onClick={handleClick}>
                {buttonText || t`result.action`}
              </FixedButton>
            </S.Section>

            <S.Section>
              <ResultPersonalizedProgram />
            </S.Section>
          </S.Container>
          <S.Container>
            <ResultSlider />
          </S.Container>

          <S.Container>
            <S.Section>
              <S.ImageContainer>
                <picture>
                  <source srcSet={blogPhoto1} type="image/webp" />
                  <S.Image src={blogPhotoWebp1} alt="Do it at any place" />
                </picture>
              </S.ImageContainer>
              <S.Subtitle>
                <Trans
                  i18nKey={
                    isAgeFlow ? 'result.subtitleAge2' : 'result.subtitle2'
                  }
                  components={[<br />]}
                />
              </S.Subtitle>
              <S.Text>
                <Trans
                  i18nKey={
                    isAgeFlow
                      ? 'result.sectionDescriptionAge2'
                      : 'result.sectionDescription2'
                  }
                  components={[<br />]}
                />
              </S.Text>
            </S.Section>
            <S.Section>
              {anyLevelImg ? (
                <S.Image src={anyLevelImg} alt="Classes for any level" />
              ) : (
                <S.ImageContainer>
                  <picture>
                    <source srcSet={blogPhoto2} type="image/webp" />
                    <S.Image src={blogPhotoWebp2} alt="Classes for any level" />
                  </picture>
                </S.ImageContainer>
              )}
              <S.Subtitle>{t`result.subtitle3`}</S.Subtitle>
              <S.Text>
                <Trans
                  i18nKey={
                    isAgeFlow
                      ? 'result.sectionDescriptionAge3'
                      : 'result.sectionDescription3'
                  }
                  components={[<br />]}
                />
              </S.Text>
            </S.Section>
            <S.Section>
              <S.ImageContainer>
                <picture>
                  <source srcSet={blogPhoto3} type="image/webp" />
                  <S.Image src={blogPhotoWebp3} alt="Online support" />
                </picture>
              </S.ImageContainer>
              <S.Subtitle>{t`result.subtitle4`}</S.Subtitle>
              <S.Text>
                <Trans
                  i18nKey={
                    isAgeFlow
                      ? 'result.sectionDescriptionAge4'
                      : 'result.sectionDescription4'
                  }
                  components={[<br />]}
                />
              </S.Text>
            </S.Section>
          </S.Container>
          <PaymentStatusModal hasDisclaimer />
        </S.Root>
      )}
    </>
  )
}
