import styled from 'styled-components'

import { Button } from 'components/Button'

import { IntroOfferDisclaimer } from '../IntroOfferDisclaimer'

export const StyledSubscriptionsBlockV2 = {
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  SubscriptionDisclaimer: styled(IntroOfferDisclaimer)`
    margin-top: 24px;
  `,
}
