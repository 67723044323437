import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  $text: string
}

export const StyledErrorNotification = {
  Wrapper: styled.div<TWrapperProps>`
    position: absolute;
    top: -10px;
    left: 50%;
    width: 100%;
    min-width: 85px;
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    background-color: ${Color.DARK};
    border-radius: 6px;
    opacity: ${({ $text }) => ($text ? 1 : 0)};
    transform: ${({ $text }) =>
      $text ? 'translate(-50%, -100%)' : 'translateX(-50%)'};
    transition: all 0.3s ease-out;
  `,
  Text: styled.p`
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.WHITE};
  `,
}
