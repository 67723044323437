import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDancingVsExercises = {
  AnimationContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  `,
  AnimationTitle: styled.h2`
    margin-bottom: 40px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  Animation: styled.div`
    min-height: 263px;
    min-width: 328px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    overflow: hidden;
    padding: 10px 12px;
  `,
  ButtonContainer: styled.div`
    padding-bottom: 30px;
  `,
  Container: styled(Container)`
    padding-top: 16px;
  `,
}
