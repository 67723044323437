import { PageId } from 'page-constants'
import { Cohort } from 'root-constants'

export const QUESTION_IMAGE_PATH = '/images/cancel-offer-question'

export const OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
}

export const CANCEL_OFFER_PAYWALLS: Partial<Record<Cohort, PageId>> = {
  [Cohort.DANCEBIT_FLOW_1]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_1]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_2]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_EMAIL_3]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_20_30_40]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DANCEBIT_CLEAR_FLOW]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_FLOW_3D]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_FLOW_7D]: PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  [Cohort.DB_SOMATIC]: PageId.PURCHASE_CANCEL_OFFER_SOMATIC,
  [Cohort.DB_REF]: PageId.PURCHASE_CANCEL_OFFER_REF,
  [Cohort.DB_BELLY_REF]: PageId.PURCHASE_CANCEL_OFFER_REF,
}
