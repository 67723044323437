import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import starBadge from 'assets/images/sprite/star-badge.svg'

import { StyledCharacteristicCard as S } from './CharacteristicCard.styles'

type TProps = {
  img: string
  name: string
  text: string
  danceStyles?: string[]
}

export const CharacteristicCard: FC<TProps> = ({
  danceStyles,
  img,
  name,
  text,
}) => (
  <S.Card>
    <S.ImgContainer>
      <S.Img src={img} alt={name} />
    </S.ImgContainer>

    <S.Container>
      <div>
        <S.Title>
          <Trans i18nKey={name} />
          <SvgImage svg={starBadge} width={14} />
        </S.Title>
        <S.Description>
          <Trans i18nKey={text} />
        </S.Description>
      </div>

      <S.DanceStyleContainer>
        {danceStyles?.map((danceStyle) => (
          <S.DanceStyle key={danceStyle}>
            <Trans i18nKey={danceStyle} />
          </S.DanceStyle>
        ))}
      </S.DanceStyleContainer>
    </S.Container>
  </S.Card>
)
