import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  InputWithFloatPlaceholder,
  InputWithFloatPlaceholderTheme,
} from 'storybook-ui'

import { PageTitle } from 'components/PageTitle'

import { sendUserEmailAction } from 'root-redux/actions/user'
import { selectAnswers, selectUserName } from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import checkIconSvg from 'assets/images/black-check.svg'
import incorrectEmailIcon from 'assets/images/flow-belly/icons/incorrect-input-icon.svg'
import lockIconSvg from 'assets/images/sprite/lock.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Color, MeasurementSystem } from 'root-constants'

import { StyledEmailBelly as S } from './EmailBelly.styles'
import { PREPAYWALL_PAGES } from './constants'

export const EmailBelly: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { search } = useLocation()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const userStatus = useSelector(selectUserStatus)
  const uuid = useSelector(selectUUID)
  const name = useSelector(selectUserName)
  const userAnswer = useSelector(selectAnswers)
  const {
    isLegsFlow,
    isSomaticFlow,
    isMenopauseFlow,
    isTTClearFlow,
  } = useCohortInfo()

  const [email, setEmail] = useEmailInputField()

  const { title } = useDynamicOBConfig()

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const errorText = useMemo(
    () => (!email.isFocused && !email.isValid ? email.validationText : ''),
    [email.isValid, email.validationText, email.isFocused],
  )

  const titleText = useMemo(() => {
    if (title) return title

    if (isLegsFlow) return 'onboarding.email.titleLegs'

    if (isMenopauseFlow) return 'onboarding.email.titleMenopause'

    if (isTTClearFlow) return 'onboarding.email.titleClear'

    if (isSomaticFlow) {
      return 'onboarding.email.titleSomatic'
    }

    return 'onboarding.email.titleBelly'
  }, [isTTClearFlow, isMenopauseFlow, isSomaticFlow, isLegsFlow, title])

  const isMetricSystemSelected = useMemo(
    () => userAnswer?.measurementSystem === MeasurementSystem.METRIC,
    [userAnswer],
  )

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo({
        pathname: userStatus?.email.isEmailConsentTried
          ? PREPAYWALL_PAGES.PREPAYWALL
          : PageId.EMAIL_CONSENT,
        search,
      })
    }
  }, [userStatus?.email, search])

  useEffect(() => {
    !userStatus?.email.hasEmail && eventLogger.logEmailPageShown()
  }, [userStatus?.email])

  const handleSubmit = useCallback(() => {
    if (!email.isValid) return

    goTo({
      pathname: PageId.EMAIL_CONSENT,
      search,
    })
    dispatch(
      sendUserEmailAction({
        email: email.value,
        isConsentRequired: true,
      }),
    )

    googleAnalyticsLogger.logEnterEmailPageCompleted()
    eventLogger.logEmailPageCompleted({
      email: isPersonalDataAllowed ? email.value : '',
    })
    window.axon &&
      window.axon('track', 'sign_up', {
        user_id: isPersonalDataAllowed ? uuid : '',
        method: 'email',
      })
    window.fbq &&
      window.fbq(
        'track',
        'Lead',
        {},
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    window.rdt &&
      window.rdt('track', 'Lead', {
        email: isPersonalDataAllowed ? email.value : '',
        externalId: isPersonalDataAllowed ? uuid : '',
        ...(!isPersonalDataAllowed && {
          em: '',
          uuid: '',
        }),
      })
    window.pintrk && window.pintrk('track', 'lead')
    window.ttq &&
      window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
    window.ttq &&
      window.ttq.track('CompleteRegistration', {
        event_id: isPersonalDataAllowed ? uuid : '',
      })
    window.snaptr &&
      window.snaptr('track', 'SIGN_UP', {
        user_email: isPersonalDataAllowed ? email.value : '',
        firstname: isPersonalDataAllowed ? name : '',
      })
    window.obApi && window.obApi('track', 'Lead')
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: 'complete_registration',
      })
  }, [
    name,
    email.isValid,
    email.value,
    search,
    dispatch,
    isPersonalDataAllowed,
    uuid,
  ])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  if (userStatus?.email.hasEmail) return null

  return (
    <>
      <S.Container isLarge>
        <PageTitle marginBottom={24}>
          <Trans
            i18nKey={titleText}
            values={{
              target: userAnswer?.goalWeight || 77,
              measurementSystem: isMetricSystemSelected
                ? t('commonComponents.metric')
                : t('commonComponents.imperial'),
            }}
          />
        </PageTitle>
        <S.InputContainer>
          <InputWithFloatPlaceholder
            value={email.value}
            isValid={isEmailValid}
            border={
              isEmailValid
                ? `1px solid ${Color.GRAY_40}`
                : `1px solid ${Color.DARK_RED}`
            }
            isFullWidth
            checkIconSvg={checkIconSvg}
            maxWidth="100%"
            validationText={errorText}
            label={t`login.emailPlaceholder`}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            theme={InputWithFloatPlaceholderTheme.DANCEBIT}
            crossIconSvg={incorrectEmailIcon}
          />
        </S.InputContainer>
        <S.Disclaimer>
          <S.LockIcon svg={lockIconSvg} width={20} />
          <Trans
            i18nKey="onboarding.email.disclaimerBelly"
            components={[<br />]}
          />
        </S.Disclaimer>
      </S.Container>

      <S.NavigationButtonBelly
        type="submit"
        disabled={!email.value || !isEmailValid}
        onClick={handleSubmit}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
