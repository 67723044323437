import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledAge = {
  WheelContainer: styled.div<{ $isAgeFlow: boolean }>`
    margin-top: 52px;
    position: relative;

    .swiper-container {
      height: 192px;
      min-height: 192px;
    }

    &::before {
      position: absolute;
      content: '';
      top: 52px;
      border-radius: 16px;
      border: 2px solid ${Color.PRIMARY};
      width: 100%;
      height: 64px;
      padding: 8px 16px;
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 8px 0;
      color: ${Color.PRIMARY};
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      user-select: none;

      ${({ $isAgeFlow }) =>
        $isAgeFlow &&
        css`
          border: 1px solid ${Color.PRIMARY_LIGHT};
          background: ${Color.WHITE};
          box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
        `}
    }
  `,
  NextAge: styled.div<{ $isActive: boolean }>`
    color: #9498a5;
    text-align: center;
    height: 64px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    min-height: 24px;
    margin: 8px 0;

    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${Color.PRIMARY};
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      `}
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
