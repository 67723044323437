import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Container } from 'components/Container'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'
import {
  selectHasEmail,
  selectIsEmailConsentTried,
} from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ADULT_COHORT, Cohort, MAIN_GOALS } from 'root-constants'

import { StyledDownload as S } from './EmailConsent.styles'
import { QUESTION, USER_ANSWERS } from './constants'

export const EmailConsent: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const isEmailConsentTried = useSelector(selectIsEmailConsentTried)
  const hasEmail = useSelector(selectHasEmail)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const goal = useMemo(
    () => (isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitGoal],
  )

  useLayoutEffect(() => {
    if (hasEmail && isEmailConsentTried) {
      goTo({
        pathname: ADULT_COHORT.includes(cohortToUse as Cohort)
          ? PageId.RESULT
          : PageId.PREPAYWALL,
        search,
      })
    }
  }, [cohortToUse, hasEmail, isEmailConsentTried, search])

  useEffect(() => {
    eventLogger.logEmailConsentPageShown()
  }, [])

  const handleClick = useCallback(
    (value: boolean) => {
      goTo({
        pathname: ADULT_COHORT.includes(cohortToUse as Cohort)
          ? PageId.RESULT
          : PageId.PREPAYWALL,
        search,
      })

      eventLogger.logQuestion({
        question: QUESTION,
        answers: value ? USER_ANSWERS.YES : USER_ANSWERS.NO,
      })

      dispatch(sendUserEmailConsentAction(value))
    },
    [cohortToUse, dispatch, search],
  )

  if (hasEmail && isEmailConsentTried) return null

  return (
    <Container>
      <S.Title>
        <Trans
          i18nKey="onboarding.emailConsent.title"
          values={{ context: goal }}
        />
      </S.Title>

      <S.Button type="button" onClick={() => handleClick(true)}>
        {t('onboarding.emailConsent.yes')}
      </S.Button>

      <S.NoLink onClick={() => handleClick(false)}>
        {t('onboarding.emailConsent.no')}
      </S.NoLink>
    </Container>
  )
}
