import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import chatIcon from 'assets/images/purchaseNoOnboarding/chat.svg'

import { StyledReviewsNoOnboarding as S } from './ReviewsNoOnboarding.styles'
import { REVIEWS_LIST } from './constants'

Swiper.use([Pagination, Autoplay])

export const ReviewsNoOnboarding: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>
        <Trans
          i18nKey="purchaseNoOnboarding.reviews.title"
          components={[<br />, <strong />]}
        />
      </S.Title>

      <Carousel
        slidesPerView={1}
        loop
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {REVIEWS_LIST.map(
          ({
            imageBefore,
            imageAfter,
            imageBeforePng,
            imageAfterPng,
            review,
            name,
            startDate,
            endDate,
            reviewIcon,
          }) => (
            <SwiperSlide key={review}>
              <S.Review>
                <S.Cards>
                  <S.Card>
                    <S.ImageContainer>
                      <picture>
                        <source srcSet={imageBefore} type="image/webp" />
                        <S.Image src={imageBeforePng} alt="before" />
                      </picture>
                    </S.ImageContainer>
                    <S.LabelBefore>{t(startDate)}</S.LabelBefore>
                  </S.Card>

                  <S.Card>
                    <S.ImageContainer>
                      <picture>
                        <source srcSet={imageAfter} type="image/webp" />
                        <S.Image src={imageAfterPng} alt="after" />
                      </picture>
                    </S.ImageContainer>
                    <S.LabelAfter>{t(endDate)}</S.LabelAfter>
                  </S.Card>
                </S.Cards>
                <S.Description>
                  <S.Text>
                    {t(review)}
                    {reviewIcon && <S.Emoji src={reviewIcon} alt="" />}
                  </S.Text>
                  <S.Reviewer>
                    <S.ReviewerName>{t(name)}</S.ReviewerName>
                    <S.ReviewerIcon src={chatIcon} alt="" />
                  </S.Reviewer>
                </S.Description>
              </S.Review>
            </SwiperSlide>
          ),
        )}
      </Carousel>
    </S.Root>
  )
}
