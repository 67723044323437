import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Slider } from 'components/Slider'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledCurrentHeight as S } from './CurrentHeightWithRuler.styles'
import { MinMaxHeight } from './constants'

type TProps = {
  isMetricSystemSelected: boolean
  onChange: ({ target: { value, validity } }) => void
  value: string
}

export const CurrentHeightWithRuler: FC<TProps> = ({
  isMetricSystemSelected,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort
  const rulerRef = useRef<HTMLDivElement>(null)

  useDisableOverscroll()

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  useEffect(() => {
    if (rulerRef.current) {
      rulerRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
  }, [])

  const handleChange = useCallback(
    (newValue) => {
      onChange({
        target: {
          value: newValue.toString(),
          validity: { valid: true },
        },
      })
    },
    [onChange],
  )

  return (
    <S.InputContainer ref={rulerRef}>
      <Slider
        value={Number(value)}
        onChange={handleChange}
        min={
          isMetricSystemSelected
            ? MinMaxHeight.MIN_HEIGHT_CM
            : MinMaxHeight.MIN_IMP_HEIGHT
        }
        max={
          isMetricSystemSelected
            ? MinMaxHeight.MAX_HEIGHT_CM
            : MinMaxHeight.MAX_IMP_HEIGHT
        }
        step={1}
        gap={10}
        stepsAmount={isMetricSystemSelected ? 10 : 12}
        formatNumber={isMetricSystemSelected ? undefined : (v) => `${v / 12}`}
        isHorizontal={false}
      />
      <S.HeightValue $isAgeFlow={isAgeFlow}>
        {isMetricSystemSelected
          ? `${value} ${t('commonComponents.centimeter')}`
          : `${Math.floor(Number(value) / 12)}’${Number(value) % 12}”`}
      </S.HeightValue>
    </S.InputContainer>
  )
}
