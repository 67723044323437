import styled from 'styled-components'

import { Button } from 'components/Button'

import discountBadge from 'assets/images/upsell-discount-badge.png'

import { Color } from 'root-constants'

export const StyledUpsellDescription = {
  Wrapper: styled.div`
    padding: 16px;
    border-radius: 24px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e1e3ec;
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  `,
  TextContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 48px;
  `,
  Text: styled.p`
    padding-left: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  PriceBadge: styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    background: #eaf7ff;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.PRIMARY};
    text-align: center;

    strong {
      text-decoration: line-through;
      text-decoration-color: ${Color.DANGER};
    }
  `,
  TrialPrice: styled.span`
    margin-left: 6px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.DANGER};
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > button {
      max-width: 100%;
    }
  `,
  SecondaryButton: styled(Button)`
    border-radius: 30px;
    background: ${Color.GRAY_40};
  `,
  GetAll: styled.div`
    position: relative;
    border-radius: 16px;
    border: 2px solid ${Color.PRIMARY};
    background: #eaf7ff;
    padding-left: 74px;
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.PRIMARY};
    margin-bottom: 8px;
  `,
  DiscountBadge: styled.div`
    position: absolute;
    display: flex;
    text-align: center;
    padding-top: 2px;
    bottom: 7px;
    left: 18px;
    width: 44px;
    height: 47px;
    background-image: url(${discountBadge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
}
