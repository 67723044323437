import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useViewportValue } from 'hooks/useViewportValue'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReachTarget } from 'modules/purchase/components/ReachTarget'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { Reviews } from 'modules/purchase/components/Reviews'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockIntroOfferV2 } from 'modules/purchase/components/SubscriptionsBlockIntroOfferV2'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutWithoutIntroOffer } from 'modules/purchase/pages/CheckoutWithoutIntroOffer'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseWithoutIntro as S } from './PurchaseWeeklyOffer.styles'

export const PurchaseWeeklyOffer: FC = () => {
  const { t } = useTranslation()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
  } = usePurchaseStore()

  usePurchaseAnalytics({
    screenName: ScreenName.PURCHASE_NO_INTRO,
  })
  const { buttonText } = useDynamicPaywallConfig()

  useViewportValue(ScreenName.PURCHASE_NO_INTRO, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE_NO_INTRO))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTags.NO_TAX,
      ),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.PURCHASE_NO_INTRO,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <ReachTarget />
          <S.Wrapper>
            <ReceivedPlan />
          </S.Wrapper>

          <S.TimerSubheaderWithTimer
            elemForComparisonRef={buttonWrapperElementRef}
            onButtonClick={handleShowPayment}
            isInfluencerFlow
          />

          <S.Wrapper>
            <SubscriptionsBlockIntroOfferV2>
              <S.ButtonContainer ref={buttonWrapperElementRef}>
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </S.ButtonContainer>
            </SubscriptionsBlockIntroOfferV2>
            <S.Container>
              <FeaturedBlock />
            </S.Container>
            <MoneyBackGuarantee />
            <PhotoResultV3
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <S.FAQList />
            <Reviews />
            <S.PurchaseButton
              type="button"
              data-order="3"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.PurchaseButton>
            <S.IntroOfferDisclaimerV2 />
            <SecurityInfo />
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutWithoutIntroOffer
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
