import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledClimbStairs = {
  Subtitle: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  Container: styled(Container)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
}
