import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectHasUpsellOffer,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'
import { Cohort, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const { steps, currentSubscriptionPageId } = useGetPageInfo()

  const cohortToUse = parentCohort || cohort

  const isUpsellAvailable = useMemo(
    () =>
      hasUpsellOffer &&
      userStatus?.upsell.isUpsellAvailable &&
      !userStatus?.upsell.isUpsellTried,
    [hasUpsellOffer, userStatus],
  )

  if (userStatus?.account.hasAccount) {
    return PageId.DOWNLOAD
  }

  if (
    userStatus?.hasSubscription &&
    userStatus?.upgrade.isUpgradeAvailable &&
    !userStatus?.upgrade.isUpgradePassed &&
    !userStatus.upsell.isUpsellTried &&
    cohortToUse === Cohort.DB_INTRO_DOP &&
    trialPeriodDays === SEVEN_DAY_TRIAL_DURATION
  ) {
    return PageId.PURCHASE_DOP
  }

  if (
    (hasUpsellOffer && userStatus?.upsell.isUpsellPurchased) ||
    (userStatus?.hasSubscription && !isUpsellAvailable)
  ) {
    return PageId.LOGIN
  }

  if (userStatus?.hasSubscription) {
    return PageId.UPSELL
  }

  if (userStatus?.email.hasEmail && userStatus?.email.isEmailConsentTried) {
    return currentSubscriptionPageId || steps[0].id
  }

  if (userStatus?.email.hasEmail && !userStatus?.email.isEmailConsentTried) {
    return PageId.EMAIL_CONSENT
  }

  return ''
}
