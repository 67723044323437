import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { AccountLink } from 'components/AccountLink'
import { Button } from 'components/Button'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectHasUpsellOffer,
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { usePurchaseUpgradeAnalytics } from 'modules/purchase/hooks/usePurchaseUpgradeAnalytics'
import { purchaseUpgradeV2 } from 'modules/purchase/redux/actions/common'
import {
  selectSubscriptionFullPrice,
  selectUpgradeDiffSubscriptionAmountPaid,
  selectUpgradeDiffSubscriptionAmountToPay,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import graphImg from 'assets/images/dop-graph.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  MAIN_GOALS,
  PagesSource,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseUpgrade as S } from './PurchaseDop.styles'

export const PurchaseDop: React.FC = () => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const amountToPay = useSelector(selectUpgradeDiffSubscriptionAmountToPay)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const paidPrice = useSelector(selectUpgradeDiffSubscriptionAmountPaid)
  const hasUpsell = useSelector(selectHasUpsellOffer)
  const accountId = useSelector(selectStripeAccountId)
  const accountName = useSelector(selectStripeAccountName)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const { search } = useLocation()
  const dispatch = useDispatch()
  const hasVatInfo = useVatInfo()

  const { fetchingActionsList, currency } = usePurchaseStore()

  usePurchaseUpgradeAnalytics(
    ScreenName.ADDITIONAL_OFFER,
    ScreenId.ADDITIONAL_OFFER,
  )

  useViewportValue(ScreenName.ADDITIONAL_OFFER)

  const oldFullPrice = useMemo(() => fullPrice * 2, [fullPrice])

  const goal = useMemo(
    () => (isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitGoal],
  )

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(getPaymentConfigAction())

    dispatch(setScreenNameAction(ScreenName.ADDITIONAL_OFFER))
    dispatch(setScreenIdAction(ScreenId.ADDITIONAL_OFFER))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.UPGRADE,
        `${hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX}`,
      ),
    )
  }, [dispatch, hasVatInfo])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgradeV2())
  }, [dispatch])

  const handleSkip = useCallback(() => {
    if (hasUpsell) {
      eventLogger.logUpgradePageCompleted(
        ScreenName.ADDITIONAL_OFFER,
        goal,
        accountId,
        accountName,
      )
      goTo({
        pathname: PageId.UPSELL,
        search,
      })
      return
    }

    goTo({
      pathname: PageId.LOGIN,
      search,
    })
  }, [accountId, accountName, goal, hasUpsell, search])

  const upgradePlan = useCallback(
    (event) => {
      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.ADDITIONAL_OFFER,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      addToCart()
    },
    [addToCart],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="purchaseDop.title" values={{ context: goal }} />
        </S.Title>
        <S.Subtitle>{t('purchaseDop.subtitle', { context: goal })}</S.Subtitle>

        <S.GraphContainer>
          <S.GraphTitle>
            {t('purchaseDop.graphTitle', { context: goal })}
          </S.GraphTitle>
          <img src={graphImg} width={296} alt="graph" />

          <S.PriceOld>
            {t('purchase1.checkout.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: Math.floor(oldFullPrice),
            })}
          </S.PriceOld>
          <S.PriceNow>
            {t('purchase1.checkout.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: Math.floor(fullPrice),
            })}
          </S.PriceNow>

          <S.GraphText>
            <p>{t('purchaseDop.oneMonthNow')}</p>
            <p>{t('purchaseDop.oneMonthLimited')}</p>
          </S.GraphText>
        </S.GraphContainer>

        <S.SaveBigTitle>{t('purchaseDop.saveBig')}</S.SaveBigTitle>
        <S.SubscriptionDetails>
          <S.Price>
            <p>{t('purchaseDop.oneMonthNow')}</p>
            <span>
              {t('purchase1.checkout.price', {
                currency: CURRENCY_SYMBOLS[currency],
                price: oldFullPrice,
              })}
            </span>
          </S.Price>
          <S.HighlightedPrice>
            <p>{t('purchaseDop.limitedOfferUpgrade')}</p>
            <span>
              -
              {t('purchase1.checkout.price', {
                currency: CURRENCY_SYMBOLS[currency],
                price: fullPrice,
              })}
            </span>
          </S.HighlightedPrice>
          <S.Price>
            <p>{t('purchaseDop.sevenDayRefund')}</p>
            <span>
              -
              {t('purchase1.checkout.price', {
                currency: CURRENCY_SYMBOLS[currency],
                price: paidPrice,
              })}
            </span>
          </S.Price>
        </S.SubscriptionDetails>
        <S.TotalPrice>
          <p>{t('purchaseDop.total')}</p>
          <span>
            {t('purchase1.checkout.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: amountToPay,
            })}
          </span>
        </S.TotalPrice>

        <S.Disclaimer>
          <Trans
            i18nKey="purchaseDop.disclaimer"
            values={{
              amountToPay,
              fullPrice,
              trialPrice: paidPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
            components={{ linkTag: <AccountLink /> }}
          />
          <TermsOfUseLink source={PagesSource.PAYWALL} />{' '}
          {t`commonComponents.or`}{' '}
          <ContactWithSupport
            source={PagesSource.PAYWALL}
            text={t('commonComponents.contactSupportDisclaimer')}
          />
          .
        </S.Disclaimer>

        <Button
          onClick={upgradePlan}
          data-order="1"
          data-text="stick_to_my_plan"
        >
          {t('purchaseDop.update', {
            price: fullPrice,
            currency: CURRENCY_SYMBOLS[currency],
          })}
        </Button>
        <S.SkipBtn onClick={handleSkip}>
          {t('purchaseDop.stickToPlan')}
        </S.SkipBtn>
      </S.Wrapper>
      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
      />
    </>
  )
}
