import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledCompositeAnswerButton = {
  Root: styled.div<{ $isAgeFlow: boolean }>`
    width: 100%;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    background-color: #f5f6f7;
    color: ${Color.DARK};
    padding: 16px 24px;
    position: relative;

    input:checked + & {
      background: ${Color.PRIMARY};
      color: ${Color.WHITE};
    }

    input:checked + & p {
      color: ${Color.WHITE};
    }

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 8px 24px;
        background-color: ${Color.WHITE};
        border: 1px solid ${Color.PRIMARY_LIGHT};
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);

        h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
      `}
  `,

  Content: styled.div`
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
    overflow-wrap: break-word;
  `,
  Title: styled.h3`
    margin-bottom: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
  `,
  Text: styled.p`
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
}
