import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

export const StyledContainer = {
  Container: styled.div<{ $isJapanese: boolean }>`
    height: calc(100% - 141px);
    overflow-y: scroll;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}
  `,
}
