import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Swiper, { Controller, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import { SubscriptionTwoPlansItem } from '../SubscriptionTwoPlansItem'
import { SubscriptionTwoPlansBlockStyles as S } from './SubscriptionTwoPlansBlock.styles'

Swiper.use([Pagination, Navigation, Controller])

type TProps = {
  controller: Swiper | undefined
  onSwiperInit: (swiper: Swiper) => void
  children: React.ReactNode
}

export const SubscriptionTwoPlansBlock: React.FC<TProps> = ({
  children,
  controller,
  onSwiperInit,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const swiperRef = useRef<Swiper | null>(null)

  const subscriptions = useSelector(selectSubscriptionList)

  const onClickNextSlide = useCallback(() => swiperRef.current?.slideNext(), [])

  const onClickPrevSlide = useCallback(() => swiperRef.current?.slidePrev(), [])

  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  useEffect(() => {
    if (!swiperRef.current) return

    swiperRef.current.controller.control = controller
  }, [controller])

  useEffect(() => {
    const selectedSubscription =
      subscriptions.find(({ isDefault }) => isDefault) || subscriptions[0]
    dispatch(setSelectedSubscriptionAction(selectedSubscription))
  }, [dispatch, subscriptions])

  const handleChange = useCallback(
    (value: string) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      if (checkedSubscription) {
        dispatch(setSelectedSubscriptionAction(checkedSubscription))
      }
    },
    [dispatch, subscriptions],
  )

  const handleSlideChange = useCallback(
    (swiperCore: Swiper) => {
      const { activeIndex } = swiperCore
      const activeSlide = swiperCore.slides[activeIndex]
      const subscriptionId = activeSlide.id
      handleChange(subscriptionId)
    },
    [handleChange],
  )

  return (
    <S.Wrapper>
      <Carousel
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        spaceBetween={20}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        onBeforeInit={(swiper: Swiper) => {
          swiperRef.current = swiper
          onSwiperInit(swiper)
        }}
      >
        <S.Title>
          {subscriptionBlockTitle || t('purchaseTwoPlans.subscriptions.title')}
        </S.Title>

        {subscriptions.map((subscription) => (
          <SwiperSlide key={subscription.product} id={subscription.id}>
            <SubscriptionTwoPlansItem
              subscription={subscription}
              onNavigationPrev={onClickPrevSlide}
              onNavigationNext={onClickNextSlide}
            >
              {children}
            </SubscriptionTwoPlansItem>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Wrapper>
  )
}
