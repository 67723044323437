import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { CreditCardPaymentForm } from 'modules/purchase/components/CreditCardPaymentForm'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import securitySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckout as S } from './Checkout.styles'

type TProps = {
  setIsCheckoutShown: (value: boolean) => void
  isCheckoutShown: boolean
}

export const Checkout: React.FC<TProps> = ({
  setIsCheckoutShown,
  isCheckoutShown,
}) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const {
    uuid,
    cohort,
    countryCode,
    subscriptions,
    currency,
    fetchingActionsList,
    hasCancelOffer,
    periodQuantity,
    periodName,
    fullPrice,
    screenName,
    screenId,
    threeDSecureIframeUrl,
    stripeAccountName,
    stripeAccountId,
    selectedSubscription,
  } = usePurchaseStore()

  const hasExcludedVat = useVatInfo(true)
  const hasPayPalButton = useHasPayPalButton()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      }),
    [periodName, periodQuantity, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        accountName: stripeAccountName,
        accountId: stripeAccountId,
        productId,
        screenName,
        screenId,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        isPersonalDataAllowed,
      })
    }
  }, [
    isPersonalDataAllowed,
    subscriptions,
    uuid,
    isCheckoutShown,
    selectedSubscription,
    productId,
    screenId,
    screenName,
    stripeAccountId,
    stripeAccountName,
  ])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenId,
      screenName,
    })

    if (!hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    dispatch,
    productId,
    screenId,
    screenName,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  return (
    <>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper $isVisible={isCheckoutReady}>
            <S.Title>{t`purchase1.checkout.title`}</S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            <S.TotalAmount>
              <Trans
                i18nKey="purchase1.checkout.totalAmount_interval"
                components={[<strong />]}
                values={{
                  price: fullPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                  count: periodQuantity,
                  inclVat: hasExcludedVat
                    ? t('purchase1.inclVat', { context: countryCode })
                    : '',
                  postProcess: 'interval',
                }}
              />
            </S.TotalAmount>
            <S.PaymentContainer>
              {isCheckoutReady && <CreditCardPaymentForm />}
              {hasPayPalButton && isCheckoutShown && (
                <>
                  <Separator />
                  <PayPalButton />
                </>
              )}
              <PaymentRequestButton />

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase1.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </>
  )
}
