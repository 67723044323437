import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { Color } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledChooseAge = {
  Container: styled(Container)`
    padding: 0 16px;
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    position: relative;
    border-radius: 16px;
    border: 1px solid #c7e5ff;
    background: #def;
    margin: 16px 0 2px;
    width: 162px;
    height: 168px;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        outline: 2px solid ${Color.PRIMARY};
      `}
    &:last-child {
      margin-bottom: 2px;
    }

    &:nth-child(2n) {
      margin-left: 16px;
    }
  `,
  Image: styled.img<TRadioButtonProps>`
    position: absolute;
    bottom: 44px;
    left: 0;
    width: 153px;
    height: 124px;
    object-fit: cover;
  `,
  Action: styled.div<TRadioButtonProps>`
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 153px;
    height: 40px;
    border-radius: 16px;
    background: ${Color.WHITE};
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 12px;

    svg {
      width: 24px;
      height: 24px;
      padding: 7px;
      background-color: ${Color.PRIMARY};
      border-radius: 50%;
    }
  `,
}
