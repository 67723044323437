import styled, { keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

const spinKeyframes = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const StyledPreparePlanAdult = {
  Root: styled.div`
    position: absolute;
    top: 52px;
    width: 100%;
    height: calc(100% - 52px);
    background: linear-gradient(180deg, #f2f9ff 17.31%, #c6e5ff 99.98%);

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  `,
  SpinnerContainer: styled.div`
    margin: 24px 0 40px;
    text-align: center;
  `,
  Text: styled.p`
    font-size: 17px;
    color: ${Color.GRAY_80};
    font-weight: 400;
    line-height: 24px;
  `,
  Circle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
  ImageContainer: styled.div`
    max-width: 280px;
    max-height: 237px;
    margin-bottom: 24px;
  `,
  Image: styled.img`
    max-width: 100%;
  `,
  SlideText: styled.p`
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  `,
}
