import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Input } from 'storybook-ui'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { calculateWeightDifference } from 'helpers/calculateWeightDifference'

import { TAnswer, TPageProps } from 'models/common.model'

import easyWin from 'assets/images/sprite/easy-win.svg'
import someEffort from 'assets/images/sprite/some-effort.svg'

import { Color, FLOAT_NUMBER_REGEX, MeasurementSystem } from 'root-constants'

import { StyledGoalWeightBelly as S } from './GoalWeightBelly.styles'
import {
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
  QUESTION,
} from './constants'

const enum MAIN_COLOR {
  EASY_WIN = '#55d16f',
  SOME_EFFORT = '#ffa337',
}

const enum MAIN_BG_COLOR {
  EASY_WIN = '#f0faee',
  SOME_EFFORT = '#fff4e3',
}

const MINIMUM_WEIGHT_LOSS_THRESHOLD = 10.5

export const GoalWeightBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  const disclaimerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [weight, setWeight] = useState<{
    value: string
    unit: TAnswer
    isValid: boolean
  }>({
    value: '',
    unit: currentMeasurementSystem,
    isValid: false,
  })

  const [weightLossPercentage, setWeightLossPercentage] = useState(0)

  const isMetricSystem = useMemo(
    () => weight.unit === MeasurementSystem.METRIC,
    [weight.unit],
  )

  const minValue = useMemo(
    () => (isMetricSystem ? MIN_WEIGHT_KG : MIN_WEIGHT_LB),
    [isMetricSystem],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (weight.isValid && disclaimerRef.current) {
      disclaimerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      })

      inputRef.current && inputRef.current.blur()
    }
  }, [weight.isValid])

  useEffect(() => {
    if (currentMeasurementSystem) {
      setWeight((prevState) => ({
        ...prevState,
      }))
    }
  }, [currentMeasurementSystem])

  const handleChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FLOAT_NUMBER_REGEX.test(value)) {
        setWeight((prevState) => ({
          ...prevState,
          value,
          isValid: validity.valid,
        }))

        const difference = calculateWeightDifference(
          Number(currentWeightValue),
          value,
        )
        setWeightLossPercentage(difference)
      }
    },
    [currentWeightValue],
  )

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      handleContinue(weight.value)
    },
    [handleContinue, weight],
  )

  return (
    <S.Form onSubmit={handleClick}>
      <Container isLarge>
        <PageTitle
          marginBottom={8}
        >{t`onboarding.goalWeight.question`}</PageTitle>
        <S.Subtitle marginBottom={24}>
          <Trans
            i18nKey="onboarding.goalWeightBelly.subtitle"
            components={[<br />]}
          />
        </S.Subtitle>
        <S.WeightLabel>
          {t(`commonComponents.${currentMeasurementSystem}`)}
        </S.WeightLabel>

        <Input
          theme="dancebitNumber"
          inputRef={inputRef}
          style={{ textAlign: 'center' }}
          type="number"
          color={weight.isValid ? Color.DARK : Color.GRAY_60}
          min={minValue}
          max={
            currentWeightValue &&
            parseFloat(currentWeightValue as string) -
              (isMetricSystem ? MIN_KG_DIFF : MIN_LB_DIFF)
          }
          lang="en"
          step={isMetricSystem ? '0.1' : '1'}
          value={weight.value}
          onChange={handleChange}
          allowFloatNumbers={isMetricSystem}
        />

        <div>
          {weight.isValid && (
            <Container isLarge>
              {weightLossPercentage < MINIMUM_WEIGHT_LOSS_THRESHOLD ? (
                <S.WeightDisclaimer
                  $bgColor={MAIN_BG_COLOR.EASY_WIN}
                  ref={disclaimerRef}
                >
                  <S.Title $color={MAIN_COLOR.EASY_WIN}>
                    <S.Icon svg={easyWin} width={16} />
                    {t('onboarding.goalWeight.easyWin')}
                  </S.Title>
                  <Trans
                    i18nKey="onboarding.goalWeight.youWillLose"
                    values={{ percentage: weightLossPercentage.toFixed(2) }}
                    components={[<strong />]}
                  />
                  <S.Description>
                    {t('onboarding.goalWeight.easyWinText')}
                  </S.Description>
                </S.WeightDisclaimer>
              ) : (
                <S.WeightDisclaimer
                  $bgColor={MAIN_BG_COLOR.SOME_EFFORT}
                  ref={disclaimerRef}
                >
                  <S.Title $color={MAIN_COLOR.SOME_EFFORT}>
                    <S.Icon svg={someEffort} width={16} />
                    {t('onboarding.goalWeight.someEffort')}
                  </S.Title>
                  <Trans
                    i18nKey="onboarding.goalWeight.youWillLose"
                    values={{ percentage: weightLossPercentage.toFixed(2) }}
                    components={[<strong />]}
                  />
                  <S.Description>
                    {t('onboarding.goalWeight.someEffortText')}
                  </S.Description>
                </S.WeightDisclaimer>
              )}
            </Container>
          )}
        </div>
      </Container>

      <S.NavigationButtonBelly
        type="submit"
        disabled={!weight.isValid && Number(weight.value) !== minValue}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Form>
  )
}
