import emojiJa from 'assets/images/flow-belly/icons/emoji-ja.svg'
import emoji from 'assets/images/flow-belly/icons/emoji.svg'
import gobletJa from 'assets/images/flow-belly/icons/goblet-ja.svg'
import goblet from 'assets/images/flow-belly/icons/goblet.svg'
import muscleJa from 'assets/images/flow-belly/icons/muscles-ja.svg'
import muscle from 'assets/images/flow-belly/icons/muscles.svg'

export const QUESTION =
  'How confident are you in reaching goal weight kg by event?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.confident.canDoIt',
    value: 'canDoIt',
    imageUrl: goblet,
  },
  {
    text: 'onboarding.confident.willTry',
    value: 'willTry',
    imageUrl: muscle,
  },
  {
    text: 'onboarding.confident.notSure',
    value: 'notSure',
    imageUrl: emoji,
  },
]

export const OPTION_VALUES_JAPANESE = [
  {
    text: 'onboarding.confident.canDoIt',
    value: 'canDoIt',
    imageUrl: gobletJa,
  },
  {
    text: 'onboarding.confident.willTry',
    value: 'willTry',
    imageUrl: muscleJa,
  },
  {
    text: 'onboarding.confident.notSure',
    value: 'notSure',
    imageUrl: emojiJa,
  },
]
