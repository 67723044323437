import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setDynamicDiscountAction } from 'modules/purchase/redux/actions/common'

import { DynamicDiscountTheme, DynamicDiscountType } from 'root-constants'

export const useDynamicDiscount = (): void => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(search)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())

  useEffect(() => {
    if (
      utmTagsFromUrl.discount_type &&
      utmTagsFromUrl.discount_checkout &&
      utmTagsFromUrl.discount_amount &&
      utmTagsFromUrl.discount_name &&
      utmTagsFromUrl.discount_theme
    ) {
      dispatch(
        setDynamicDiscountAction({
          type: utmTagsFromUrl.discount_type as DynamicDiscountType,
          checkout: utmTagsFromUrl.discount_checkout,
          amount: Number(utmTagsFromUrl.discount_amount),
          name: utmTagsFromUrl.discount_name.replace('٪', '%'),
          theme: utmTagsFromUrl.discount_theme as DynamicDiscountTheme,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
