import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'

export const StyledHealthCondition = {
  Subtitle: styled.p`
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  `,
  Container: styled(Container)`
    ${StyledAgeFlowContainer}
  `,
  AnswerButton: styled(AnswerButton)`
    padding: 0 45px 0 8px;
  `,
}
