import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTaboolaPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useTaboolaPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const taboolaPixelId = useSelector(selectTaboolaPixelId)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldTaboolaPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [
      isEUUser,
      userCookieConsentAnswer?.length,
      hasAdvertizingAndMarketingCookie,
    ],
  )

  useLayoutEffect(() => {
    if (
      !uuid ||
      !userCountryCode ||
      shouldTaboolaPixelBePaused ||
      !taboolaPixelId
    ) {
      return
    }

    const taboolaScript = document.createElement('script')

    taboolaScript.type = 'text/javascript'
    taboolaScript.text = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: ${taboolaPixelId}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/${taboolaPixelId}/tfa.js',
    'tb_tfa_script');
    `

    document.body.appendChild(taboolaScript)
  }, [shouldTaboolaPixelBePaused, taboolaPixelId, userCountryCode, uuid])
}
