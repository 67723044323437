import styled from 'styled-components'

export const StyledUpsellBenefits = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  `,
  TextContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 48px;
  `,
  Text: styled.p`
    padding-left: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
}
