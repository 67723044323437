import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { AnswerWithIcon } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BUTTON_WITH_ICON_STYLE_PROPS, Color } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const SeeYourself: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const {
    handleChange,
    setIsAnswersDisabled,
    isAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...BUTTON_WITH_ICON_STYLE_PROPS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={24} highlightColor={Color.PRIMARY}>
        <Trans i18nKey="onboarding.seeYourself.question" />
      </PageTitle>

      {OPTION_VALUES.map(({ text, value, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          key={value}
          value={value}
          iconSrc={imageUrl}
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
