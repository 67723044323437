import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import {
  selectAnswers,
  selectCurrentUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { TSlide } from 'modules/purchase/constants'

import starSvg from 'assets/images/sprite/star.svg'

import { PageId } from 'page-constants'
import { Gender, MeasurementSystem } from 'root-constants'

import { StyledPhotoResultV3 as S } from './PhotoResultV3.styles'

Swiper.use([Pagination, Autoplay])

type TProps = {
  femaleSlides: TSlide[]
  maleSlides: TSlide[]
  hasTitle?: boolean
  hasBackground?: boolean
  isUpgrade?: boolean
  className?: string
}

export const PhotoResultV3: FC<TProps> = ({
  femaleSlides,
  maleSlides,
  hasTitle = true,
  hasBackground = true,
  isUpgrade = false,
  className = '',
}) => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const userAnswer = useSelector(selectAnswers)
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  const isMale = useMemo(() => userAnswer?.[PageId.GENDER] === Gender.MALE, [
    userAnswer,
  ])

  const slideList = useMemo(() => (isMale ? maleSlides : femaleSlides), [
    femaleSlides,
    isMale,
    maleSlides,
  ])

  const isMetricSystemSelected = useMemo(
    () => measurementSystem === MeasurementSystem.METRIC,
    [measurementSystem],
  )
  const title = useMemo(() => {
    if (!isUpgrade) return null

    return customerReviewsTitle
  }, [customerReviewsTitle, isUpgrade])

  const reviews = useMemo(() => {
    if (!isUpgrade) return slideList

    return (customerReviews.length ? customerReviews : slideList) as TSlide[]
  }, [customerReviews, isUpgrade, slideList])

  return (
    <S.Wrapper className={className}>
      {hasTitle && (
        <S.TitleContainer>
          <S.Title>
            {title || t`purchaseWithoutIntro.photoResult.title`}
          </S.Title>
          <S.Description>{t`purchaseWithoutIntro.photoResult.description`}</S.Description>
        </S.TitleContainer>
      )}

      {!hasTitle && title && (
        <S.CustomTitle>{customerReviewsTitle}</S.CustomTitle>
      )}

      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {reviews.map(({ imageUrl, id, author, review, weight }) => (
            <SwiperSlide key={id}>
              <S.PhotoResultWrapper $hasBackground={hasBackground}>
                <S.Image src={imageUrl} alt="before" />
                <S.CardWrapper $hasBackground={hasBackground} key={author}>
                  {weight && (
                    <S.ResultContainer $hasBackground={hasBackground}>
                      <S.ResultText>
                        {t(weight, {
                          context: isMetricSystemSelected
                            ? 'beforeKg'
                            : 'beforeLbs',
                        })}
                      </S.ResultText>
                      <S.ResultSvgContainer />
                      <S.ResultText>
                        {t(weight, {
                          context: isMetricSystemSelected
                            ? 'afterKg'
                            : 'afterLbs',
                        })}
                      </S.ResultText>
                    </S.ResultContainer>
                  )}
                  <S.Card>
                    <S.Author>{t(author)}</S.Author>
                    <S.Rating>
                      <SvgImage svg={starSvg} />
                      <SvgImage svg={starSvg} />
                      <SvgImage svg={starSvg} />
                      <SvgImage svg={starSvg} />
                      <SvgImage svg={starSvg} />
                    </S.Rating>
                    <S.Text>{t(review, { context: measurementSystem })}</S.Text>
                  </S.Card>
                </S.CardWrapper>
              </S.PhotoResultWrapper>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
