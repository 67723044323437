export const QUESTION = 'Personal weight-loss plan according to your goal'

export const OPTION_VALUES_METRIC = [
  {
    value: '1-10',
    text: 'onboarding.welcomeV2.loseKg',
  },
  {
    value: '11-20',
    text: 'onboarding.welcomeV2.loseKg',
  },
  {
    value: '20',
    text: 'onboarding.welcomeV2.loseOverKg',
  },
  {
    value: 'notDecided',
    text: 'onboarding.welcomeV2.notDecided',
  },
]

export const OPTION_VALUES_IMPERIAL = [
  {
    value: '1-20',
    text: 'onboarding.welcomeV2.loseLb',
  },
  {
    value: '21-50',
    text: 'onboarding.welcomeV2.loseLb',
  },
  {
    value: '50',
    text: 'onboarding.welcomeV2.loseOverLb',
  },
  {
    value: 'notDecided',
    text: 'onboarding.welcomeV2.notDecided',
  },
]
