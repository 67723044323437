import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import loseWeightIcon from 'assets/images/lose-weight-goal.png'
import goalIcon from 'assets/images/main-goal-icon.png'

import { PageId } from 'page-constants'
import { MAIN_GOALS } from 'root-constants'

import { StyledTenMillionPeople as S } from './AchieveYourGoal.styles'
import { QUESTION } from './constants'

export const AchieveYourGoal: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const goal = answers?.[PageId.MAIN_GOAL_SOMATIC]
  const ageRange = answers?.[PageId.WELCOME]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <Container>
          <S.Image
            src={goal === MAIN_GOALS.LOSE_WEIGHT ? loseWeightIcon : goalIcon}
          />
          <S.Title>{t('onboarding.achieveYourGoal.title')}</S.Title>
          <S.Subtitle>
            <Trans
              i18nKey={`onboarding.achieveYourGoal.text_${goal}`}
              values={{ ageRange: t(`onboarding.achieveYourGoal.${ageRange}`) }}
            />
          </S.Subtitle>
        </Container>
      </S.ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
