// eslint-disable-line max-lines
import { CustomerData } from '@livechat/widget-react'
import { userApi } from 'api'
import i18n from 'i18next'

import {
  setAnswersFromBackendAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectUtmTags,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { SET_IS_SUBSCRIPTION_UPGRADED } from 'modules/purchase/redux/actions/common'
import { selectPlanAdditions } from 'modules/purchase/redux/selects/common'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { Cohort, SKIPPED_ONBOARDING_COHORTS } from 'root-constants'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const SET_SECRET = `${MODULE_NAME}/SET_SECRET`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const UPDATE_USER_CONFIG = `${MODULE_NAME}/UPDATE_USER_CONFIG`
export const SET_USER_INFO = `${MODULE_NAME}/SET_USER_INFO`
export const SET_AUTH_TOKEN = `${MODULE_NAME}/SET_AUTH_TOKEN`
export const SET_USER_SUBSCRIPTION_INFO = `${MODULE_NAME}/SET_USER_SUBSCRIPTION_INFO`
export const SET_USER_CONTACT_EMAIL = `${MODULE_NAME}/SET_USER_CONTACT_EMAIL`
export const SET_DELETED_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_DELETED_SUBSCRIPTION_ID`
export const SET_TEST_ENVIRONMENT_QUERY_PARAM = `${MODULE_NAME}/SET_TEST_ENVIRONMENT_QUERY_PARAM`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_EMAIL_CONSENT = `${MODULE_NAME}/SEND_USER_EMAIL_CONSENT`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
export const SET_LIVECHAT_PROPS = `${MODULE_NAME}/SET_LIVECHAT_PROPS`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SEND_FACEBOOK_PARAMS`
export const GET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/GET_AUTOLOGIN_TOKEN`
export const SET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/SET_AUTOLOGIN_TOKEN`
export const SET_IS_PERSONAL_DATA_ALLOWED = `${MODULE_NAME}/SET_IS_PERSONAL_DATA_ALLOWED`
const SAVE_PLAN_ADDITIONS = `${MODULE_NAME}/SAVE_PLAN_ADDITIONS`
const BIND_USER = `${MODULE_NAME}/BIND_USER`
const SEND_USER_TTCLID = `${MODULE_NAME}/SEND_USER_TTCLID`
const SEND_USER_SCCID = `${MODULE_NAME}/SEND_USER_SCCID`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setIsSubscriptionUpgradedAction(
  isUpgraded: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_SUBSCRIPTION_UPGRADED,
    payload: isUpgraded,
  }
}

export function updateUserConfigAction(
  value: Record<string, string | string[] | number>,
): IAction<Record<string, string | string[] | number>> {
  return {
    type: UPDATE_USER_CONFIG,
    payload: value,
  }
}

export function setLiveChatPropsAction(
  value: CustomerData,
): IAction<CustomerData> {
  return {
    type: SET_LIVECHAT_PROPS,
    payload: value,
  }
}

export function setSecretAction(secret: string): IAction<string> {
  return {
    type: SET_SECRET,
    payload: secret,
  }
}

export function setAuthTokenAction(authToken: string): IAction<string> {
  return {
    type: SET_AUTH_TOKEN,
    payload: authToken,
  }
}

export function setUserContactEmailAction(
  customerContactEmail: string,
): IAction<string> {
  return {
    type: SET_USER_CONTACT_EMAIL,
    payload: customerContactEmail,
  }
}

export function setDeletedSubscriptionIdAction(
  subscriptionId: string,
): IAction<string> {
  return {
    type: SET_DELETED_SUBSCRIPTION_ID,
    payload: subscriptionId,
  }
}

export function setTestEnvironmentQueryParamAction(
  testEnvironmentQueryParam: string,
): IAction<string> {
  return {
    type: SET_TEST_ENVIRONMENT_QUERY_PARAM,
    payload: testEnvironmentQueryParam,
  }
}

export const setUpUUIDAction = ({
  cohort,
  uuid: uuidFromUrl,
  utmTags: utmTagsFromUrl,
  isTestEnvironment,
  giaApiKey,
  language,
}: {
  cohort: string
  isTestEnvironment: boolean
  uuid: string | null
  utmTags: TUtmTags | null
  giaApiKey: string
  language: string
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuidFromStore = selectUUID(state)
  const utmTagsFromStore = selectUtmTags(state)

  const uuid = uuidFromUrl || uuidFromStore
  const utmTags = utmTagsFromUrl || utmTagsFromStore

  if (uuid) {
    dispatch(setUUIDAction(uuid))
    return
  }

  const response = await userApi.getUUID({
    cohort,
    appId: giaApiKey,
    locale: language,
    utm: utmTags,
    isTestEnvironment,
  })

  if (response.success) {
    const responseUUID = (response.data as IGetUUIDResponseRaw).value
    dispatch(setUUIDAction(responseUUID))
  } else {
    console.error('error')
  }
}

export const sendFacebookParamsAction = (): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)
  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')

  dispatch(startFetching(SEND_FACEBOOK_PARAMS))

  const response = await userApi.saveFacebookParams({
    uuid,
    fbp,
    fbc,
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction(i18n.t('login.haveAccountError')))
    } else {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }
  }

  dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
}

export function bindUserAction(token: string): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.bindUser({
      token,
      uuid,
    })

    if (response.success && response.data) {
      const userStatusResponse = await userApi.getUserStatus(uuid)
      const userStatus = getUserStatusFromRawUserStatus(
        userStatusResponse.data.state,
      )

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
      googleAnalyticsLogger.logAccountCreated()
    } else {
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? i18n.t('login.refundSubscriptionError')
          : i18n.t('login.haveAccountError')

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)
    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction = (
  unsuccessCallback?: (() => void) | null,
  isFinished = false,
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const answers = selectAnswers(state) as TAnswers
  const cohort = selectCurrentVariantCohort(state) as Cohort
  const uuid = selectUUID(state)
  const parentCohort = selectCurrentVariantCohort(state)

  const cohortToUse = parentCohort || cohort

  dispatch(startFetching(SEND_USER_ANSWERS))

  const response = await userApi.saveUserAnswers({
    uuid,
    answers,
    isFinished,
    ...(SKIPPED_ONBOARDING_COHORTS.includes(cohortToUse) && {
      isSkipped: true,
    }),
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction(i18n.t('login.haveAccountError')))
    } else {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    unsuccessCallback && unsuccessCallback()
  }

  dispatch(stopFetching(SEND_USER_ANSWERS))
}

export const sendUserConfigAction = (
  config: Record<string, string | string[] | boolean | number>,
  unsuccessCallback?: (() => void) | null,
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_CONFIG))

  const response = await userApi.saveUserConfig({
    uuid,
    config,
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction(i18n.t('login.haveAccountError')))
    } else {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    unsuccessCallback && unsuccessCallback()
  }

  dispatch(stopFetching(SEND_USER_CONFIG))
}

export const sendUserEmailAction = ({
  email,
  unsuccessCallback,
  isConsentRequired,
}: {
  email: string
  unsuccessCallback?: (() => void) | null
  isConsentRequired?: boolean
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_EMAIL))

  const response = await userApi.saveUserEmail({
    uuid,
    consentRequired: isConsentRequired,
    email,
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction(i18n.t('login.haveAccountError')))
    } else {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    unsuccessCallback && unsuccessCallback()
    dispatch(stopFetching(SEND_USER_ANSWERS))
    return
  }

  dispatch(getUserStatusAction(uuid))

  dispatch(stopFetching(SEND_USER_EMAIL))
}

export const sendUserEmailConsentAction = (consented: boolean): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_EMAIL_CONSENT))

  const response = await userApi.saveUserEmailConsent({
    uuid,
    consented,
  })

  if (!response.success) {
    dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

    dispatch(stopFetching(SEND_USER_ANSWERS))
    return
  }

  dispatch(getUserStatusAction(uuid))

  dispatch(stopFetching(SEND_USER_EMAIL_CONSENT))
}

export const sendUserTTCLID = (
  ttclid: string,
  unsuccessCallback?: (() => void) | null,
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_TTCLID))

  const response = await userApi.saveUserTTCLID({
    ttclid,
    uuid,
  })

  if (!response.success) {
    dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    unsuccessCallback && unsuccessCallback()
    dispatch(stopFetching(SEND_USER_TTCLID))
    return
  }

  dispatch(stopFetching(SEND_USER_TTCLID))
}

export const sendUserSCCID = (
  sccid: string,
  scid: string,
  unsuccessCallback?: (() => void) | null,
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_SCCID))

  const response = await userApi.saveUserSCCID({
    sccid,
    uuid,
    scid,
  })

  if (!response.success) {
    dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    unsuccessCallback && unsuccessCallback()
    dispatch(stopFetching(SEND_USER_SCCID))
    return
  }

  dispatch(stopFetching(SEND_USER_SCCID))
}

export const savePlanAdditionsAction = (
  unsuccessCallback?: (() => void) | null,
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)
  const planAdditions = selectPlanAdditions(state)

  if (!planAdditions.length) return

  dispatch(startFetching(SAVE_PLAN_ADDITIONS))

  const response = await userApi.savePlanAdditions({
    uuid,
    planAdditions,
  })

  if (!response.success) {
    const errorMessage =
      response?.data?.error || i18n.t('login.somethingWentWrongError')

    dispatch(setErrorAction(errorMessage))

    unsuccessCallback && unsuccessCallback()
    dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
    return
  }

  dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
}

export function setAutologinTokenAction(payload: string): IAction<string> {
  return {
    type: SET_AUTOLOGIN_TOKEN,
    payload,
  }
}

export function setIsPersonalDataAllowedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PERSONAL_DATA_ALLOWED,
    payload,
  }
}

export function getAutologinTokenAction(
  unsuccessCallback?: () => void,
): TAppActionThunk<string> {
  return async (
    dispatch: TAppDispatchThunk<string>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(GET_AUTOLOGIN_TOKEN))

    const autologinTokenResponse = await userApi.getAutologinToken(uuid)

    if (!autologinTokenResponse.success || !autologinTokenResponse.data) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
      unsuccessCallback && unsuccessCallback()
    }

    dispatch(setAutologinTokenAction(autologinTokenResponse.data.custom_token))

    dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
  }
}

export async function fetchPredictedLtv(uuid: string): Promise<number | null> {
  try {
    const ltvResponse = await userApi.getPredictedLtv({ uuid })
    if (ltvResponse?.success && ltvResponse.status === 200) {
      return ltvResponse.data?.predicted_ltv ?? null
    }

    return null
  } catch (error) {
    console.error(error)
    return null
  }
}
