import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

export const StyledGripOfStress = {
  Paragraph: styled.p`
    font-size: 17px;
    display: block;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    &:nth-of-type(1) {
      margin-bottom: 8px;
    }
  `,
  Title: styled(PageTitle)`
    margin-top: 16px;
  `,
}
