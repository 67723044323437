import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

export const StyledEnergy = {
  QuestionDescription: styled.div`
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 0 16px;
        ${StyledAgeFlowContainer};
      `}
  `,
}
