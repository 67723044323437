import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'
import { ADULT_COHORT, Cohort, GenderStepOption } from 'root-constants'

import { StyledResultPersonalizedProgram as S } from './ResultPersonalizedProgram.styles'
import {
  IMPROVE_AREAS_CARD_FEMALE,
  IMPROVE_AREAS_CARD_FEMALE_AGE,
  IMPROVE_AREAS_CARD_MALE,
  IMPROVE_AREAS_CARD_MALE_AGE,
} from './constants'

type TProps = {
  className?: string
}

export const ResultPersonalizedProgram: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const answers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { currentPageId } = useGetPageInfo()

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const [maleCards, femaleCards] = useMemo(
    () =>
      isAgeFlow
        ? [IMPROVE_AREAS_CARD_MALE_AGE, IMPROVE_AREAS_CARD_FEMALE_AGE]
        : [IMPROVE_AREAS_CARD_MALE, IMPROVE_AREAS_CARD_FEMALE],
    [isAgeFlow],
  )

  const areas = useMemo(() => {
    const areasAccordingToGender =
      answers?.gender === GenderStepOption.FEMALE ? femaleCards : maleCards

    const improveAreasFromUserAnswers =
      (answers?.improveAreas as string[]) || []

    return improveAreasFromUserAnswers.map(
      (area) => areasAccordingToGender[area],
    )
  }, [answers?.gender, answers?.improveAreas, femaleCards, maleCards])

  return (
    <S.Wrapper $isAgeFlow={isAgeFlow} className={className}>
      <S.Inner>
        <S.Title
          $isAgeFlow={isAgeFlow}
        >{t`result.personalizedProgram.title`}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey={
              isAgeFlow || currentPageId === PageId.PREPAYWALL
                ? 'result.personalizedProgram.subtitleAge'
                : 'result.personalizedProgram.subtitle'
            }
            components={[<strong />]}
          />
        </S.Subtitle>
        <S.Cards>
          {!!areas.length &&
            areas.map((area) => {
              if (!area) return null

              return (
                <S.Card key={`${area.title}-key`}>
                  <S.CardImage src={area.image} alt={area.title} />
                  <S.CardTitle>{t(area.title)}</S.CardTitle>
                </S.Card>
              )
            })}
        </S.Cards>
      </S.Inner>
    </S.Wrapper>
  )
}
