import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Input } from 'storybook-ui'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { Toggle } from 'components/Toggle'

import { setWeightAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserMeasurementSystem,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TAnswer, TPageProps } from 'models/common.model'

import { goTo } from 'browser-history'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  Color,
  FLOAT_NUMBER_REGEX,
  GROWTHBOOK_EXPERIMENT,
  MeasurementSystem,
} from 'root-constants'

import { StyledCurrentWeightBelly as S } from './CurrentWeightBelly.styles'
import { MinMaxWeight, QUESTION } from './constants'

export const CurrentWeightBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)

  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)
  const { isNewAdultFlow } = useCohortInfo()

  const [weight, setWeight] = useState<{
    value: string
    unit: TAnswer
    isValid: boolean
  }>({
    value: '',
    unit: currentMeasurementSystem,
    isValid: false,
  })

  const isMetricSystemSelected = useMemo(
    () => weight.unit === MeasurementSystem.METRIC,
    [weight.unit],
  )

  const isCountryWithImperialSystem = useMemo(
    () => COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(userCountryCode),
    [userCountryCode],
  )

  const handleMeasurementChange = useCallback(
    (isChecked) =>
      setWeight({
        value: '',
        isValid: false,
        unit: isChecked ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
      }),
    [],
  )
  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(
        setWeightAnswerAction({
          question: QUESTION,
          answers: {
            [pageId]: weight.value,
            measurementSystem: weight.unit,
          },
        }),
      )
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [weight, pageId, dispatch, nextPagePath],
  )

  useEffect(() => {
    const initialMeasurementSystem = isCountryWithImperialSystem
      ? MeasurementSystem.IMPERIAL
      : MeasurementSystem.METRIC

    setWeight((prevState) => ({
      ...prevState,
      value: '',
      unit: currentMeasurementSystem || initialMeasurementSystem,
    }))
  }, [
    currentMeasurementSystem,
    currentWeightValue,
    isCountryWithImperialSystem,
    isMetricSystemSelected,
  ])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FLOAT_NUMBER_REGEX.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  return (
    <S.Form
      onSubmit={handleClick}
      $isJapanese={isJapaneseFlow}
      $isAdult={isNewAdultFlow}
    >
      <Container isLarge>
        <PageTitle
          marginBottom={8}
        >{t`onboarding.currentWeight.question`}</PageTitle>
        <S.Subtitle marginBottom={24}>
          <Trans
            i18nKey="onboarding.currentWeightBelly.subtitle"
            components={[<br />]}
          />
        </S.Subtitle>
        <S.Actions>
          {currentMeasurementSystem ? (
            <S.WeightLabel>
              {t(`commonComponents.${currentMeasurementSystem}`)}
            </S.WeightLabel>
          ) : (
            <Toggle
              isFlat
              data-testid="weight-toggle"
              labels={[
                t('commonComponents.metric'),
                t('commonComponents.imperial'),
              ]}
              checked={!isMetricSystemSelected}
              onChange={handleMeasurementChange}
            />
          )}
          <Input
            theme="dancebitNumber"
            value={weight.value}
            type="number"
            min={
              isMetricSystemSelected
                ? MinMaxWeight.MIN_WEIGHT_KG
                : MinMaxWeight.MIN_WEIGHT_LB
            }
            max={
              isMetricSystemSelected
                ? MinMaxWeight.MAX_WEIGHT_KG
                : MinMaxWeight.MAX_WEIGHT_LB
            }
            lang="en"
            border={
              isNewAdultFlow
                ? '1px solid #C6E0F4'
                : `1px solid ${Color.GRAY_40}`
            }
            focusedBorderColor={isNewAdultFlow ? '#C6E0F4' : Color.GRAY_40}
            step={isMetricSystemSelected ? '0.1' : '1'}
            onChange={handleChange}
            allowFloatNumbers={isMetricSystemSelected}
            style={{ textAlign: 'center' }}
          />
        </S.Actions>
      </Container>

      <S.NavigationButtonBelly
        hasClearBackground
        type="submit"
        disabled={!weight.isValid}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </S.Form>
  )
}
