import React from 'react'
import { useTranslation } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { ACCOUNT_LINK } from 'root-constants'

import { OuterLink } from './OuterLink'

export const AccountLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <OuterLink
      href={ACCOUNT_LINK}
      onClick={() => eventLogger.logAccountLoginShown('paywall')}
    >
      {t`commonComponents.website`}
    </OuterLink>
  )
}
