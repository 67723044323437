import styled from 'styled-components'

import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

import cardBgSmall from 'assets/images/image-with-wallet-small.png'
import cardBg from 'assets/images/image-with-wallet.png'

import { Color } from 'root-constants'

export const StyledPurchaseWithoutIntro = {
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  ContentWrapper: styled.div`
    background: linear-gradient(173deg, #fbf3ff 16.12%, #ecf6ff 95.61%);
    padding-top: 16px;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    margin-bottom: 50px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimer)`
    margin-bottom: 50px;
    margin-top: 24px;
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
    }
  `,
  FAQList: styled(FAQList)`
    h2 {
      color: #e43a91;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    h3 {
      color: ${Color.PRIMARY};
      font-size: 17px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 40px;
    }
  `,
  ButtonText: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 8px;
    }
  `,
  RefundCard: styled.div`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 16px;
    background: linear-gradient(128deg, #a6f1df 4.73%, #50b5c3 79.07%);
    width: 100%;
    min-height: 152px;
    padding: 64px 12px 16px;
    margin-top: 24px;
    margin-bottom: 16px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: -28px;
      width: 100%;
      height: 180px;
      background-image: url(${cardBg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  RefundCardSmall: styled.div`
    position: relative;
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(124deg, #a6f1df 11.31%, #50b5c3 77.01%);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    min-height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: 50px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${cardBgSmall});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    background: ${Color.WHITE};
    margin-top: 50px;

    p,
    a {
      color: ${Color.GRAY_80};
    }
  `,
  SecurityInfo: styled(SecurityInfo)`
    p {
      color: ${Color.GRAY_80};
    }
  `,
}
