import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledBonesProblems = {
  Title: styled.h1`
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin: 0 -10px 16px;
  `,
  Subtitle: styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 30px;
  `,
  Container: styled(Container)`
    ${StyledAgeFlowContainer};
    padding-bottom: 32px;
  `,
}
