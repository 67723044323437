import easily from 'assets/images/climb-easily.png'
import outOfBreath from 'assets/images/out-of-breath.png'
import okay from 'assets/images/tired-but-okay.png'

export const QUESTION = 'How do you feel after climbing some stairs?'

export const OPTION_VALUES = [
  {
    value: 'outOfBreath',
    text: 'onboarding.climbStairs.outOfBreath',
    imageUrl: outOfBreath,
  },
  {
    value: 'okay',
    text: 'onboarding.climbStairs.okay',
    imageUrl: okay,
  },
  {
    value: 'easily',
    text: 'onboarding.climbStairs.easily',
    imageUrl: easily,
  },
]
