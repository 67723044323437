import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { StyledButtonBase } from 'components/Button/Button.styles'
import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'

import { Color } from 'root-constants'

type TButtonTextSize = {
  $isSmall?: boolean
}

export const StyledPurchaseWithoutIntro2 = {
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    margin-bottom: 50px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimer)`
    margin-bottom: 50px;
  `,
  FAQList: styled(FAQList)`
    p {
      color: ${Color.LIGHT_GREY};
      line-height: 18px;
      padding-right: 16px;
      margin-bottom: 40px;
    }
  `,
  PurchaseButton: styled(Button)`
    margin-bottom: 24px;
  `,
  TimerSubheaderWithTimer: styled(SubheaderWithTimer)<TButtonTextSize>`
    margin: 18px auto 50px;

    button {
      ${({ $isSmall }) =>
        $isSmall &&
        css`
          font-size: 11px;
        `}
    }
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
