import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBodyAgeV3 = {
  Root: styled.div`
    margin-bottom: 32px;
    padding: 32px 16px;
    background-color: ${Color.LIGHT};
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 27px;
    text-align: center;
  `,
  Picture: styled.img`
    display: block;
    margin-bottom: 24px;
  `,
  Card: styled.li`
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    border-radius: 24px;

    gap: 8px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  `,
  Icon: styled.img`
    max-width: 64px;
    height: auto;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DARK};
  `,
}
