import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledLogin = {
  Wrapper: styled(Container)``,
  Title: styled.h1`
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};
  `,

  Button: styled(Button)`
    border-radius: 30px;
    max-width: 328px;
  `,
  DisclaimerContainer: styled.div`
    position: relative;
    margin-bottom: 36px;
  `,
}
