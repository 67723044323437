import styled from 'styled-components'

import promocodeBg from 'assets/images/promo-code-bg.png'

import { Color } from 'root-constants'

export const StyledPromoCodeWithTimer = {
  TimerContainer: styled.div`
    width: 343px;
    height: 131px;
    background-image: url(${promocodeBg});
    background-size: contain;
    padding: 16px;
  `,
  Title: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 16px;
    margin-bottom: 16px;

    svg {
      margin-right: 8px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed #b1d493;
    }
  `,
  TextContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Promocode: styled.div`
    border-radius: 14px;
    border: 1px solid #b1d493;
    background: ${Color.WHITE};
    display: flex;
    height: 44px;
    min-width: 196px;
    padding: 0 10px 0 8px;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;

    svg {
      margin-right: 4px;
    }
  `,
  Timer: styled.div`
    display: flex;
    padding: 4px 10px;
    border-radius: 14px;
    gap: 6px;
    background: rgba(255, 255, 255, 0.6);

    svg {
      color: #50ab64;
    }
  `,
  TimerSign: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      color: #50ab64;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    span {
      font-size: 9px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: lowercase;
    }
  `,
}
