import { CDN_FOLDER_LINK } from 'root-constants'

export const DEFAULT_IMAGE_PATH = '/images/result-collection-device-slider'

export const SLIDES = [
  {
    title: 'result.phoneSlider.planWithChallenge',
    img: `${CDN_FOLDER_LINK}/images/result-collection-device-slider-1`,
    subtitle: 'result.phoneSlider.subtitle',
    alt: '28-day dancing challenge',
  },
  {
    title: 'result.phoneSlider.planWithPrograms',
    img: `${CDN_FOLDER_LINK}/images/result-collection-device-slider-2`,
    subtitle: '',
    alt: 'Set of programs and live events',
  },
  {
    title: 'result.phoneSlider.planWithCollection',
    img: `${CDN_FOLDER_LINK}/images/result-collection-device-slider-3`,
    subtitle: '',
    alt: 'Collection of workouts',
  },
]
