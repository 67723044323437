import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import checkIconSvg from 'assets/images/sprite/check-icon-rounded.svg'

import { StyledTrialSubscriptionItemInfo as S } from './TrialSubscriptionItemInfo.styles'

type TProps = {
  className?: string
}

export const TrialSubscriptionItemInfo: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const { periodQuantity, upgradeFullPrice, currency } = usePurchaseStore()

  const calculatedOldPrice = useMemo(() => (upgradeFullPrice * 2).toFixed(2), [
    upgradeFullPrice,
  ])

  return (
    <div className={className}>
      <S.PriceTitle>{t('purchaseUpgrade.priceToday')}</S.PriceTitle>

      <S.TrialPricesContainer>
        <S.PriceName>
          {t('purchase3.subscriptionsBlock.trialText', { trialPeriodDays })}
        </S.PriceName>
        <S.PurchasedLabel>
          <S.SvgImage svg={checkIconSvg} />
          {t('purchaseUpgrade.alreadyPurchased')}
        </S.PurchasedLabel>
      </S.TrialPricesContainer>

      <S.PriceTitle>{t('purchaseUpgrade.priceAfterTrial')}</S.PriceTitle>

      <S.TrialPricesContainer>
        <S.PriceName>
          {t('purchaseUpgrade.period', {
            periodQuantity,
          })}
        </S.PriceName>
        <S.NewPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: upgradeFullPrice.toFixed(2),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.NewPrice>
      </S.TrialPricesContainer>
      <S.TrialPricesContainer>
        <S.TrialDiscount>{t('purchaseUpgrade.discount')}</S.TrialDiscount>
        <S.TrialOldPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: calculatedOldPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.TrialOldPrice>
      </S.TrialPricesContainer>
      <S.AdditionalInfoBorder />
    </div>
  )
}
