import styled from 'styled-components'

import { Color } from 'root-constants'

type TUpsellPlansDescriptionProps = {
  $withLabel: boolean
}

export const StyledUpsellPlansDescription = {
  Wrapper: styled.div``,
  Card: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 8px 12px 8px 8px;
    background-color: ${Color.LIGHT};
    border-radius: 24px;
  `,
  Image: styled.img`
    max-width: 112px;
  `,
  TextContainer: styled.div`
    padding-left: 12px;
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
  Label: styled.div<TUpsellPlansDescriptionProps>`
    max-width: 55px;
    display: ${({ $withLabel }) => ($withLabel ? 'flex' : 'none')};
    padding: 6px;
    justify-content: center;
    align-items: center;

    margin-bottom: 8px;

    text-align: center;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;
    color: #eb3b26;
    text-transform: uppercase;

    border-radius: 4px;
    background: ${Color.WHITE};
    backdrop-filter: blur(20px);
  `,
}
