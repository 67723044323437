import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import lottie from 'lottie-web/build/player/lottie_light'
import {
  MOTIVATING_EVENTS,
  NO_MOTIVATING_EVENT,
} from 'pages/questions/Reach/constants'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentUserMotivationEvent,
  selectLanguage,
} from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'

import {
  DAY_MONTH_FORMAT,
  DEFAULT_DATE_FORMAT,
  LOCALIZED_DATE_FORMAT,
  POLAND_DECLINATION,
  SLASH_DATE_FORMAT,
  getDatesArray,
  getFormattedDate,
} from 'helpers/datesForGraph'

import { CDN_FOLDER_LINK, Locale } from 'root-constants'

import { StyledReachGraphBelly as S } from './ReachGraphBelly.styles'

export const ReachGraphBelly: React.FC<{ className?: string }> = ({
  className = '',
}) => {
  const { t } = useTranslation()

  const graphContentRef = useRef<HTMLDivElement | null>(null)

  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const motivationEvent = useSelector(selectCurrentUserMotivationEvent)
  const language = useSelector(selectLanguage)

  const {
    eventDate,
    currentWeight,
    goalWeight,
    goalDate,
  } = useEventWeightInfo()

  const hasMotivationEvent = useMemo(
    () => motivationEvent !== NO_MOTIVATING_EVENT,
    [motivationEvent],
  )

  useEffect(() => {
    if (graphContentRef.current) {
      lottie.loadAnimation({
        container: graphContentRef.current,
        path: `${CDN_FOLDER_LINK}/animations/graphAnimationNoEvent.json`,
        loop: false,
        name: 'graphAnimation',
      })
    }

    return () => lottie.destroy('graphAnimation')
  }, [])

  const isAnswerShown = useMemo(() => eventDate && hasMotivationEvent, [
    eventDate,
    hasMotivationEvent,
  ])

  const answerToShow = useMemo(
    () => (hasMotivationEvent ? t(MOTIVATING_EVENTS[motivationEvent]) : ''),
    [hasMotivationEvent, motivationEvent, t],
  )

  const isPredictableYearCurrent = useMemo(
    () => dayjs(goalDate).year() > dayjs().year(),
    [goalDate],
  )

  const datesArray = useMemo(() => getDatesArray(goalDate, language), [
    goalDate,
    language,
  ])

  const format = useMemo(() => {
    switch (language) {
      case Locale.POLISH:
        return POLAND_DECLINATION
      case Locale.JAPANESE:
        return SLASH_DATE_FORMAT
      default:
        return dayjs(eventDate, DEFAULT_DATE_FORMAT).year() > dayjs().year()
          ? LOCALIZED_DATE_FORMAT
          : DAY_MONTH_FORMAT
    }
  }, [eventDate, language])

  const event = useMemo(() => {
    if (eventDate && motivationEvent) {
      return `${t(MOTIVATING_EVENTS[motivationEvent])} ･ ${getFormattedDate(
        format,
        DEFAULT_DATE_FORMAT,
        eventDate,
        language,
      )}`
    }

    return null
  }, [eventDate, language, motivationEvent, t])

  return (
    <S.Wrapper>
      <S.GraphContent className={className}>
        <S.CurrentWeight>
          <span>
            {t(`commonComponents.weight`, {
              weight: currentWeight,
              measurementSystem: t(`commonComponents.${measurementSystem}`),
            })}
          </span>
        </S.CurrentWeight>

        <S.GoalWeight>
          <span>
            {t(`commonComponents.weight`, {
              weight: goalWeight,
              measurementSystem: t(`commonComponents.${measurementSystem}`),
            })}
          </span>
        </S.GoalWeight>

        <div ref={graphContentRef} />

        <S.DateLabels $isDateWithYear={isPredictableYearCurrent}>
          {datesArray.map((item, index) =>
            isPredictableYearCurrent && index === datesArray.length - 1 ? (
              <S.WithYear>
                <S.Label key={item}>{item}</S.Label>
                <S.Label>{dayjs(goalDate).year()}</S.Label>
              </S.WithYear>
            ) : (
              <S.Label key={item}>{item}</S.Label>
            ),
          )}
        </S.DateLabels>

        {isAnswerShown && (
          <S.MiddleLabel $answer={answerToShow}>
            <S.LabelTitle $answer={answerToShow}>{event}</S.LabelTitle>
          </S.MiddleLabel>
        )}
      </S.GraphContent>
    </S.Wrapper>
  )
}
