import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { OuterLink } from 'components/OuterLink'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUserPaymentCurrency,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { UpsellBenefits } from 'modules/purchase/components/UpsellBenefits'
import { UpsellDescription } from 'modules/purchase/components/UpsellDescription'
import { UpsellPlansDescription } from 'modules/purchase/components/UpsellPlansDescription'
import { CURRENCY_SYMBOLS, PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'
import {
  selectPaymentMethod,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ButtonTapType,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  ONBOARDING_GOAL_EVENT,
  PagesSource,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  TERMS_OF_USE_LINK,
} from 'root-constants'

import { StyledUpsell as S } from './Upsell.styles'

export const Upsell: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const accountId = useSelector(selectStripeAccountId)
  const accountName = useSelector(selectStripeAccountName)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const selectedSubscription = useSelector(selectSubscription)

  const isPaidUpsell = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_474)

  const {
    currency,
    currentMeasurementSystem,
    fetchingActionsList,
    fullPrice,
    subscriptions,
    trialPrice,
    trialPriceId,
    trialPeriodDays,
    cohort,
  } = usePurchaseStore()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)
  const [isEventLogged, setIsEventLogged] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )

  const cohortToUse = parentCohort || cohort

  usePaymentElementStatus()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const goal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  const isAvailableToSendEvent = useMemo(
    () =>
      !isEventLogged &&
      isPricesStartedFetching &&
      arePricesReady &&
      selectedSubscription &&
      selectedSubscription.currency === paymentCurrency &&
      (paymentSystem === PaymentSystem.STRIPE
        ? accountId && accountName
        : true),
    [
      isEventLogged,
      isPricesStartedFetching,
      arePricesReady,
      selectedSubscription,
      paymentCurrency,
      paymentSystem,
      accountId,
      accountName,
    ],
  )

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const tags = useMemo(() => {
    const tagList = [SubscriptionTags.NO_TAX]

    if (isPaidUpsell && trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
      tagList.push(SubscriptionTags.PAID_UPSELL)
    }

    return tagList.join(',')
  }, [isPaidUpsell, trialPeriodDays])

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.UPSELL_ONE_PLAN))
    dispatch(setScreenIdAction(ScreenId.UPSELL_FIRST_PLAN))

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
    dispatch(getPaymentConfigAction())

    setIsPricesStartedFetching(true)
  }, [dispatch, tags])

  useEffect(() => {
    if (!isPricesStartedFetching || !arePricesReady) return

    const [upsell] = subscriptions

    if (!subscriptions.length || paymentCurrency !== upsell?.currency) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    paymentCurrency,
  ])

  useEffect(() => {
    if (isAvailableToSendEvent) {
      eventLogger.logUpsellPurchaseShown({
        screenName: ScreenName.UPSELL_ONE_PLAN,
        screenId: ScreenId.UPSELL_FIRST_PLAN,
        goal,
        accountId,
        accountName,
      })

      if (cohortToUse === Cohort.CANCEL_INTRO_FAST) {
        dispatch(
          sendUserConfigAction({
            payment_method: paymentMethod,
            payment_system: paymentSystem,
          }),
        )
      }

      setIsEventLogged(true)
    }
  }, [
    accountId,
    accountName,
    cohortToUse,
    dispatch,
    goal,
    isAvailableToSendEvent,
    paymentMethod,
    paymentSystem,
  ])

  useEffect(() => {
    const [upsellSubscription] = subscriptions

    dispatch(setSelectedSubscriptionAction(upsellSubscription))
  }, [dispatch, subscriptions])

  const handleContinue = useCallback(
    (buttonNumber: number) => {
      eventLogger.logUpsellPurchaseClose({
        screenName: ScreenName.UPSELL_ONE_PLAN,
        screenId: ScreenId.UPSELL_FIRST_PLAN,
        buttonNumber,
        goal,
        tap: ButtonTapType.NO_THANKS,
      })

      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    },
    [goal, search],
  )

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction())
  }, [dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="upsell.title" components={[<br />]} />
      </S.Title>
      <S.Subtitle>{t`upsell.subtitle`}</S.Subtitle>

      <UpsellDescription />

      <S.Button onClick={makeUpsell}>{t`actions.continue`}</S.Button>
      <S.Link
        onClick={() => handleContinue(2)}
      >{t`upsell.noThanksLink`}</S.Link>
      <S.Disclaimer>
        {t('upsell.disclaimer', {
          context: currentMeasurementSystem,
        })}
      </S.Disclaimer>

      <UpsellPlansDescription />
      <UpsellBenefits title="upsell.upsellBenefits.title" />

      <S.SubscriptionDescription>
        {t(
          !trialPriceId
            ? 'upsell.upsellDescription.subscriptionDescription'
            : 'upsell.upsellDescription.subscriptionDescriptionTrial',
          {
            trialPrice: formatJaPriceWithCommas(trialPrice, currency),
            price: formatJaPriceWithCommas(fullPrice, currency),
            currency: CURRENCY_SYMBOLS[currency],
          },
        )}
        <OuterLink
          href={TERMS_OF_USE_LINK}
          onClick={() => eventLogger.logTermsOfUseClicked(PagesSource.PAYWALL)}
        >
          {t`commonComponents.termsOfUse`}
        </OuterLink>{' '}
        {t('commonComponents.or')}{' '}
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupportDisclaimer')}
        />
        {t`upsell.upsellDescription.learnMore`}
      </S.SubscriptionDescription>

      <S.Button onClick={makeUpsell}>{t`actions.continue`}</S.Button>
      <S.Link
        onClick={() => handleContinue(4)}
      >{t`upsell.noThanksLink`}</S.Link>

      {isUpsellInProgress && <Spinner />}

      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
      />
    </S.Wrapper>
  )
}
