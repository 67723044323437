import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { AccountLink } from 'components/AccountLink'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { OuterLink } from 'components/OuterLink'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectIsStayFitFlow,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'

import { useLockScroll } from 'hooks/useLockScroll'
import { useScrolledPercentage } from 'hooks/useScrolledPercentage'

import { GetDiscountPopup } from 'modules/purchase/components/GetDiscountPopup'
import { UpsellCancelOfferBenefits } from 'modules/purchase/components/UpsellCancelOfferBenefits'
import { UpsellCancelOfferHero } from 'modules/purchase/components/UpsellCancelOfferHero'
import { CURRENCY_SYMBOLS, PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  PLAN_BENEFITS,
  UPSELL_CANCEL_OFFER_REVIEWS,
} from 'modules/purchase/pages/UpsellWithCancelOffer/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import discountBadge from 'assets/images/sprite/discount-badge.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  MAIN_GOALS,
  ONBOARDING_GOAL_EVENT,
  PagesSource,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  TERMS_OF_USE_LINK,
} from 'root-constants'

import { StyledUpsell as S } from './UpsellWithCancelOffer.styles'

type TProps = {
  isCancelOffer?: boolean
}

export const UpsellWithCancelOffer: React.FC<TProps> = ({
  isCancelOffer = false,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const accountId = useSelector(selectStripeAccountId)
  const accountName = useSelector(selectStripeAccountName)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const selectedSubscription = useSelector(selectSubscription)

  const firstElRef = useRef(null)
  const secondElRef = useRef(null)
  const thirdElRef = useRef(null)

  const containerRef = useRef(null)

  const scrolledPercentage = useScrolledPercentage(
    firstElRef,
    secondElRef,
    thirdElRef,
  )

  const {
    currency,
    fetchingActionsList,
    trialPrice,
    subscriptions,
    oldPriceBeforeCustomPercentDiscount,
    oldPriceBefore50PercentDiscount,
    paymentSystem,
  } = usePurchaseStore()

  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)
  const [isEventLogged, setIsEventLogged] = useState(false)

  useLockScroll(isPopupVisible, containerRef)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const goal = useMemo(
    () => (isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT),
    [isStayFitGoal],
  )

  const eventGoal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  const isAvailableToSendEvent = useMemo(
    () =>
      !isEventLogged &&
      isPricesStartedFetching &&
      arePricesReady &&
      selectedSubscription &&
      selectedSubscription.currency === paymentCurrency &&
      (paymentSystem === PaymentSystem.STRIPE
        ? accountId && accountName
        : true),
    [
      paymentSystem,
      isEventLogged,
      isPricesStartedFetching,
      arePricesReady,
      selectedSubscription,
      paymentCurrency,
      accountId,
      accountName,
    ],
  )

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const tags = useMemo(() => {
    const tagList = [SubscriptionTags.NO_TAX, SubscriptionTags.UPSELL_LONG]

    if (isCancelOffer) {
      tagList.push(SubscriptionTags.CANCEL_OFFER)
    }

    return tagList.join(',')
  }, [isCancelOffer])

  useLayoutEffect(() => {
    setIsPopupVisible(false)

    dispatch(setScreenNameAction(ScreenName.UPSELL_LONG))
    dispatch(setScreenIdAction(ScreenId.UPSELL_LONG))

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
    dispatch(getPaymentConfigAction())

    setIsPricesStartedFetching(true)
  }, [dispatch, tags])

  useEffect(() => {
    if (isAvailableToSendEvent) {
      eventLogger.logUpsellPurchaseShown(
        ScreenName.UPSELL_LONG,
        ScreenId.UPSELL_LONG,
        eventGoal,
        accountId,
        accountName,
        isCancelOffer,
      )
      setIsEventLogged(true)
    }
  }, [isCancelOffer, accountId, accountName, isAvailableToSendEvent, eventGoal])

  useEffect(() => {
    if (!isPricesStartedFetching || !arePricesReady) return

    const [upsell] = subscriptions

    if (!subscriptions.length || paymentCurrency !== upsell?.currency) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    paymentCurrency,
  ])

  useEffect(() => {
    scrolledPercentage &&
      eventLogger.logUpsellPageScrolled(
        ScreenName.UPSELL_LONG,
        ScreenId.UPSELL_LONG,
        eventGoal,
        isCancelOffer,
        `${scrolledPercentage}%`,
      )
  }, [isCancelOffer, eventGoal, scrolledPercentage, subscriptions])

  useEffect(() => {
    const [upsellSubscription] = subscriptions

    dispatch(setSelectedSubscriptionAction(upsellSubscription))
  }, [dispatch, subscriptions])

  const handleContinue = useCallback(() => {
    if (isCancelOffer) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
      return
    }

    setIsPopupVisible(true)
  }, [isCancelOffer, search])

  const makeUpsell = useCallback(
    (buttonNumber: number) => {
      dispatch(makeUpsellAction())

      eventLogger.logUpsellPurchaseButtonTap(
        ScreenName.UPSELL_LONG,
        ScreenId.UPSELL_LONG,
        eventGoal,
        isCancelOffer,
        buttonNumber,
      )
    },
    [dispatch, isCancelOffer, eventGoal],
  )

  const UpsellPlan = () => (
    <S.UpsellItem>
      <Trans
        i18nKey="upsellCancelOffer.tryIt"
        values={{ discount: isCancelOffer ? 60 : 50 }}
        components={{ percentage: isCancelOffer ? <strong /> : <span /> }}
      />
      <S.PlanTitle>
        <Trans
          i18nKey={
            isStayFitGoal
              ? 'upsellCancelOffer.fitnessAndConfidenceBooster'
              : 'upsellCancelOffer.weightLossBooster'
          }
        />
        <S.Price>
          {CURRENCY_SYMBOLS[currency]}
          {trialPrice}
        </S.Price>
        <S.OldPrice>
          {CURRENCY_SYMBOLS[currency]}
          {oldPriceBeforeCustomPercentDiscount.fullPrice ||
            oldPriceBefore50PercentDiscount.fullPrice}
        </S.OldPrice>
      </S.PlanTitle>
      {isCancelOffer && (
        <S.AdditionalDiscount>
          {t('upsellCancelOffer.discountApplied')}
        </S.AdditionalDiscount>
      )}
    </S.UpsellItem>
  )

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper ref={containerRef}>
        {isCancelOffer && (
          <S.DiscountBadge>
            <SvgImage svg={discountBadge} />
            <p>
              <Trans i18nKey="upsellCancelOffer.discountJustForYou" />
            </p>
          </S.DiscountBadge>
        )}
        <S.Title>
          <Trans
            i18nKey="upsellCancelOffer.title"
            components={[<br />]}
            values={{
              context: goal,
            }}
          />
        </S.Title>
        <S.Subtitle>
          <Trans
            i18nKey="upsellCancelOffer.subtitle"
            values={{
              context: goal,
            }}
          />
        </S.Subtitle>
        <UpsellCancelOfferHero />
        <UpsellCancelOfferBenefits />
        <UpsellPlan />
        <S.Button
          buttonRef={firstElRef}
          onClick={() => makeUpsell(1)}
        >{t`upsellCancelOffer.addToPlan`}</S.Button>
        <S.Link onClick={handleContinue}>{t`upsell.noThanksLink`}</S.Link>
        <S.BenefitsTitle>
          <Trans
            i18nKey="upsellCancelOffer.whyTry"
            values={{
              context: goal,
            }}
          />
        </S.BenefitsTitle>
        <div>
          {PLAN_BENEFITS[goal].map(({ id, img, title, description }) => (
            <S.PlanBenefitCard key={id}>
              <S.CardTitle>{t(title)}</S.CardTitle>
              <S.CardDescription>{t(description)}</S.CardDescription>
              <S.Img src={img} />
            </S.PlanBenefitCard>
          ))}
        </div>
        <S.Button
          buttonRef={secondElRef}
          onClick={() => makeUpsell(2)}
        >{t`upsellCancelOffer.addToPlan`}</S.Button>
        <S.Link onClick={handleContinue}>{t`upsell.noThanksLink`}</S.Link>
        <S.Reviews reviews={UPSELL_CANCEL_OFFER_REVIEWS} />
        <S.BenefitsTitle>
          <Trans
            i18nKey="upsellCancelOffer.whyTryV2"
            values={{
              context: goal,
            }}
          />
        </S.BenefitsTitle>
        <UpsellCancelOfferHero />
        <UpsellCancelOfferBenefits />
        <UpsellPlan />
        <S.Button
          buttonRef={thirdElRef}
          onClick={() => makeUpsell(3)}
        >{t`upsellCancelOffer.addToPlan`}</S.Button>
        <S.Link onClick={handleContinue}>{t`upsell.noThanksLink`}</S.Link>
        <S.SubscriptionDescription>
          <Trans
            i18nKey="upsellCancelOffer.disclaimer"
            values={{
              price: trialPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
            components={{ linkTag: <AccountLink /> }}
          />
          <OuterLink
            href={TERMS_OF_USE_LINK}
            onClick={() =>
              eventLogger.logTermsOfUseClicked(PagesSource.PAYWALL)
            }
          >
            {t`upsell.termsOfUse`}
          </OuterLink>{' '}
          {t('commonComponents.or')}{' '}
          <ContactWithSupport
            source={PagesSource.PAYWALL}
            text={t('commonComponents.contactSupportDisclaimer')}
          />
          {t`upsell.upsellDescription.learnMore`}
        </S.SubscriptionDescription>
        {isUpsellInProgress && <Spinner />}
      </S.Wrapper>
      {isPopupVisible && <GetDiscountPopup />}
    </>
  )
}
