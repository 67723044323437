/* eslint-disable max-lines */
import { Download } from 'pages/download'
import { Email, EmailBelly, EmailNoOnboarding } from 'pages/email'
import { EmailConsent, EmailConsentBelly } from 'pages/emailConsent'
import { Prepaywall } from 'pages/prepaywall'
import {
  AchieveYourGoal,
  ActivityLevel,
  AdditionalGoals,
  Age,
  AgeBelly,
  BestShape,
  BetterResult,
  BoostUnlocked,
  BoostYourHealth,
  ClimbStairs,
  Confident,
  CurrentHeight,
  CurrentHeightBelly,
  CurrentWeight,
  CurrentWeightBelly,
  DailyWater,
  DancebitUsers,
  DancingLevel,
  DancingLevelBelly,
  DancingStyle,
  DancingStyleBelly,
  DancingStylesIntro,
  DancingVsExercises,
  DancingVsExercisesBelly,
  DesignNewLife,
  Diet,
  DreamBody,
  EasilyGiveUp,
  Energy,
  EnergyBelly,
  EventDate,
  Exhausted,
  FitnessProfile,
  ForgetPlanks,
  Gender,
  GoalWeight,
  GoalWeightBelly,
  GripOfStress,
  HealthProfile,
  HelpAchieveResults,
  HowLongTrain,
  HowMotivated,
  HowQuickly,
  ImproveAreas,
  InfluencerWelcome,
  LessonLength,
  Lifestyle,
  MainGoal,
  MainGoal2024,
  MainGoalAgeFlow,
  MainGoalBelly,
  MainGoalSomatic,
  MainGoalSomaticV2,
  MainGoalVideo,
  MotivatingEvent,
  Motivation,
  MotivationBelly,
  Name,
  NameBelly,
  NiceToMeet,
  NoIdeaToPickUp,
  OptionsLeftToRight,
  PersonalHistory,
  PhysicalBuild,
  PlanPageIsReady,
  PreparePlan,
  PreparePlanBelly,
  Reach,
  Relaxed,
  ResultInMirror,
  RewardYourself,
  Satisfied,
  SeatedForwardBend,
  SeeYourself,
  Sleep,
  SocialProof,
  StayConsistent,
  StoreEmotion,
  Stress,
  SugarCravings,
  SuperchargeYourself,
  TenMillionPeople,
  TenMillionPeopleBelly,
  TimesPerWeek,
  ToningPilates,
  TryWorkouts,
  UnsatisfiedBody,
  UpgradeLife,
  WeightFluctuations,
  WeightGainEvents,
  Welcome,
  WelcomeBelly,
  WelcomeBellyRef,
  WelcomeInfluencer,
  WelcomeWeightLoss,
  WhatsHoldYou,
  WorkSchedule,
  WorkScheduleBelly,
  WorldwideAchieving,
  WorldwideAchievingBelly,
} from 'pages/questions'
import { PageWithImage } from 'pages/questions/Dynamic'
import { HealthCondition } from 'pages/questions/HealthCondition'
import { HealthConditionAgeFlow } from 'pages/questions/HealthConditionAgeFlow'
import { HelpedMillionsPeople } from 'pages/questions/HelpedMillionsPeople'
import { Result } from 'pages/result'

import { Login } from 'modules/login'
import { CancelOfferQuestion } from 'modules/purchase/pages/CancelOfferQuestion'
import { CheckoutNoOnboarding } from 'modules/purchase/pages/CheckoutNoOnboarding'
import { ChooseTrialPrice } from 'modules/purchase/pages/ChooseTrialPrice'
import { PurchaseCancelOffer } from 'modules/purchase/pages/PurchaseCancelOffer'
import { PurchaseCancelOfferFree } from 'modules/purchase/pages/PurchaseCancelOfferFree'
import { PurchaseCancelOfferMealPlan } from 'modules/purchase/pages/PurchaseCancelOfferMealPlan'
import { PurchaseCancelOfferRef } from 'modules/purchase/pages/PurchaseCancelOfferRef'
import { PurchaseCancelOfferSomatic } from 'modules/purchase/pages/PurchaseCancelOfferSomatic'
import { PurchaseDop } from 'modules/purchase/pages/PurchaseDop'
import { PurchaseFreeOffer } from 'modules/purchase/pages/PurchaseFreeOffer'
import { PurchaseIntroOffer } from 'modules/purchase/pages/PurchaseIntroOffer'
import { PurchaseNoOnboarding } from 'modules/purchase/pages/PurchaseNoOnboarding'
import { PurchaseProvider } from 'modules/purchase/pages/PurchaseProvider'
import { PurchaseRef } from 'modules/purchase/pages/PurchaseRef'
import { PurchaseTrial } from 'modules/purchase/pages/PurchaseTrial'
import { PurchaseTwoPlans } from 'modules/purchase/pages/PurchaseTwoPlans'
import { PurchaseUpgrade } from 'modules/purchase/pages/PurchaseUpgrade'
import { PurchaseUpgradeMealplan } from 'modules/purchase/pages/PurchaseUpgradeMealplan'
import { PurchaseVariant1 } from 'modules/purchase/pages/PurchaseVariant1'
import { PurchaseVariant2 } from 'modules/purchase/pages/PurchaseVariant2'
import { PurchaseVariantEmail } from 'modules/purchase/pages/PurchaseVariantEmail'
import { PurchaseWeeklyOffer } from 'modules/purchase/pages/PurchaseWeeklyOffer'
import { PurchaseWithoutIntro } from 'modules/purchase/pages/PurchaseWithoutIntro'
import { PurchaseWithoutIntro2 } from 'modules/purchase/pages/PurchaseWithoutIntro2'
import { Upsell } from 'modules/purchase/pages/Upsell'

import { TIdToPageMap } from 'models/common.model'

import { Cohort, GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const enum CustomPageId {}

export const enum PageId {
  WELCOME = 'welcome',
  PERSONAL_HISTORY = 'personalHistory',
  MAIN_GOAL = 'mainGoal',
  MAIN_GOAL_2024 = 'mainGoal2024',
  MAIN_GOAL_SOMATIC = 'mainGoalSomatic',
  MAIN_GOAL_VIDEO = 'mainGoalVideo',
  GENDER = 'gender',
  IMPROVE_AREAS = 'improveAreas',
  NAME = 'name',
  NICE_TO_MEET = 'niceToMeet',
  CURRENT_WEIGHT = 'currentWeight',
  CURRENT_HEIGHT = 'currentHeight',
  GOAL_WEIGHT = 'goalWeight',
  AGE = 'age',
  DANCING_LEVEL = 'dancingLevel',
  ENERGY = 'energy',
  DANCING_STYLES_INTRO = 'dancingStylesIntro',
  DANCING_STYLE = 'dancingStyle',
  DANCE_VS_EXERCISES = 'dancingVsExercises',
  LESSON_LENGTH = 'lessonLength',
  MOTIVATION = 'motivation',
  PREPARE_PLAN = 'preparePlan',
  ENTER_EMAIL = 'enterEmail',
  ENTER_EMAIL_NO_ONBOARDING = 'enterEmailNoOnboarding',
  TEN_MILLION_PEOPLE = 'tenMillionPeople',
  ACTIVITY_LEVEL = 'activityLevel',
  SOCIAL_PROOF = 'socialProof',
  ADDITIONAL_GOALS = 'additionalGoals',
  WHATS_HOLD_YOU = 'whatsHoldYou',
  WORLDWIDE_ACHIEVING = 'worldwideAchieving',
  WORK_SCHEDULE = 'workSchedule',
  RESULT = 'result',
  PURCHASE = 'purchase',
  PURCHASE_TRIAL = 'purchaseTrial',
  PURCHASE_NO_ONBOARDING = 'purchaseNoOnboarding',
  PURCHASE_EMAIL = 'purchaseEmail',
  PURCHASE_CANCEL_OFFER = 'purchaseCancelOffer',
  PURCHASE_CANCEL_OFFER_SOMATIC = 'purchaseCancelOfferSomatic',
  PURCHASE_CANCEL_OFFER_FREE = 'purchaseCancelOfferFree',
  PURCHASE_CANCEL_OFFER_REF = 'purchaseCancelOfferRef',
  PURCHASE_CANCEL_OFFER_MEAL_PLAN = 'purchaseCancelOfferMealPlan',
  PURCHASE_INTRO_OFFER = 'purchaseIntroOffer',
  PURCHASE_DOP = 'purchaseDop',
  PURCHASE_REF = 'purchaseRef',
  PURCHASE_WITHOUT_INTRO = 'purchaseWithoutIntro',
  PURCHASE_WITHOUT_INTRO2 = 'purchaseWithoutIntro2',
  PURCHASE_WEEKLY_OFFER = 'purchaseWeeklyOffer',
  PURCHASEV2 = 'purchaseV2',
  PURCHASE_TWO_PLANS = 'purchaseTwoPlans',
  PURCHASE_UPGRADE_PDF = 'purchaseUpgradePdf',
  PURCHASE_UPGRADE = 'purchaseUpgrade',
  PURCHASE_UPGRADE_MEAL_PLAN = 'purchaseUpgradeMealplan',
  PURCHASE_FREE_OFFER = 'purchaseFreeOffer',
  UPGRADE_LIFE = 'upgradeLife',
  CHOOSE_TRIAL_PRICE = 'choose_trial_price',
  CANCEL_OFFER_QUESTION = 'cancelOfferQuestion',
  CHECKOUT_NO_ONBOARDING = 'checkoutNoOnboarding',
  UPSELL = 'upsell',
  UPSELL_CANCEL_OFFER = 'upsellCancelOffer',
  LOGIN = 'login',
  EMAIL_CONSENT = 'emailConsent',
  DOWNLOAD = 'download',
  PROXY_TO_START = 'proxyToStart',
  PREPAYWALL = 'prepaywall',
  WELCOME_WEIGHT_LOSS = 'welcomeWeightLoss',
  FORGET_PLANKS = 'forgetPlanks',
  DESIGN_NEW_LIFE = 'designNewLife',
  PLAN_IS_READY = 'yourPlanIsReady',
  REACH = 'reach',
  FITNESS_PROFILE = 'fitnessProfile',
  ACHIEVE_YOUR_GOAL = 'achieveYourGoal',
  STRESS = 'stress',
  BETTER_RESULT = 'betterResult',
  STAY_CONSISTENT = 'stayConsistent',
  GRIP_OF_STRESS = 'gripOfStress',
  SUGAR_CRAVINGS = 'sugarCravings',
  WELCOME_INFLUENCER = 'welcomeInfluencer',
  PHYSICAL_BUILD = 'physicalBuild',
  DREAM_BODY = 'dreamBody',
  BEST_SHAPE = 'bestShape',
  WEIGHT_FLUCTUATIONS = 'weightFluctuations',
  TRY_WORKOUTS = 'tryWorkouts',
  BOOST_YOUR_HEALTH = 'boostYourHealth',
  TIMES_PER_WEEK = 'timesPerWeek',
  HOW_LONG_TRAIN = 'howLongTrain',
  HEALTH_CONDITION = 'healthCondition',
  SEATED_FORWARD_BEND = 'seatedForwardBend',
  CLIMB_STAIRS = 'climbStairs',
  HELPED_MILLIONS_PEOPLE = 'helpedMillionsPeople',
  LIFESTYlE = 'lifestyle',
  DAILY_WATER = 'dailyWater',
  SLEEP = 'sleep',
  WEIGHT_GAIN_EVENTS = 'weightGainEvents',
  HELP_ACHIEVE_RESULTS = 'helpAchieveResults',
  MOTIVATING_EVENT = 'motivatingEvent',
  EVENT_DATE = 'eventDate',
  CONFIDENT = 'confident',
  UNSATISFIED_BODY = 'unsatisfiedBody',
  NO_IDEA_TO_PICK_UP = 'noIdeaToPickUp',
  EASILY_GIVE_UP = 'easilyGiveUp',
  DANCEBIT_USERS = 'dancebitUsers',
  REWARD_YOURSELF = 'rewardYourself',
  SEE_YOURSELF = 'seeYourself',
  RESULT_IN_MIRROR = 'resultInMirror',
  TONING_PILATES = 'toningPilates',
  PURCHASE_WITH_CART = 'purchaseWithCart',
  SUPERCHARGE_YOURSELF = 'superchargeYourself',
  HEALTH_PROFILE = 'healthProfile',
  WELCOME_BELLY_REF = 'welcomeBellyRef',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  dynamic_options: OptionsLeftToRight,
  dynamic_image: PageWithImage,
  mainGoal: MainGoal,
  mainGoal2024: MainGoal2024,
  mainGoalSomatic: MainGoalSomatic,
  mainGoalVideo: MainGoalVideo,
  gender: Gender,
  improveAreas: ImproveAreas,
  name: Name,
  niceToMeet: NiceToMeet,
  currentWeight: CurrentWeight,
  currentHeight: CurrentHeight,
  goalWeight: GoalWeight,
  age: Age,
  dancingLevel: DancingLevel,
  energy: Energy,
  diet: Diet,
  dancingStylesIntro: DancingStylesIntro,
  dancingStyle: DancingStyle,
  dancingVsExercises: DancingVsExercises,
  lessonLength: LessonLength,
  motivation: Motivation,
  preparePlan: PreparePlan,
  enterEmail: Email,
  enterEmailNoOnboarding: EmailNoOnboarding,
  result: Result,
  prepaywall: Prepaywall,
  choose_trial_price: ChooseTrialPrice,
  purchase: PurchaseVariant1,
  purchaseTrial: PurchaseTrial,
  purchaseNoOnboarding: PurchaseNoOnboarding,
  purchaseEmail: PurchaseVariantEmail,
  cancelOfferQuestion: CancelOfferQuestion,
  purchaseCancelOffer: PurchaseCancelOffer,
  purchaseCancelOfferRef: PurchaseCancelOfferRef,
  purchaseCancelOfferMealPlan: PurchaseCancelOfferMealPlan,
  purchaseIntroOffer: PurchaseIntroOffer,
  checkoutNoOnboarding: CheckoutNoOnboarding,
  upsell: Upsell,
  login: Login,
  emailConsent: EmailConsent,
  download: Download,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePdf: PurchaseUpgrade,
  purchaseWithoutIntro: PurchaseWithoutIntro,
  purchaseWithoutIntro2: PurchaseWithoutIntro2,
  purchaseWeeklyOffer: PurchaseWeeklyOffer,
  mainGoalAgeFlow: MainGoalAgeFlow,
  welcome: Welcome,
  purchaseProvider: PurchaseProvider,
  purchaseDop: PurchaseDop,
  personalHistory: PersonalHistory,
  tenMillionPeople: TenMillionPeople,
  activityLevel: ActivityLevel,
  healthCondition: HealthConditionAgeFlow,
  howQuickly: HowQuickly,
  influencerWelcome: InfluencerWelcome,
  socialProof: SocialProof,
  purchaseV2: PurchaseVariant2,
  additionalGoals: AdditionalGoals,
  whatsHoldYou: WhatsHoldYou,
  worldwideAchieving: WorldwideAchieving,
  purchaseUpgradeMealplan: PurchaseUpgradeMealplan,
  workSchedule: WorkSchedule,
  upgradeLife: UpgradeLife,
  purchaseTwoPlans: PurchaseTwoPlans,
  welcomeWeightLoss: WelcomeWeightLoss,
  purchaseRef: PurchaseRef,
  howMotivated: HowMotivated,
  boostUnlocked: BoostUnlocked,
}
export const ID_TO_PAGE_MAP_V_2: TIdToPageMap = {
  dynamic_options: OptionsLeftToRight,
  dynamic_image: PageWithImage,
  welcome: WelcomeBelly,
  welcomeBellyRef: WelcomeBellyRef,
  welcomeInfluencer: WelcomeInfluencer,
  tenMillionPeople: TenMillionPeopleBelly,
  achieveYourGoal: AchieveYourGoal,
  mainGoal: MainGoalBelly,
  mainGoalSomatic: MainGoalSomaticV2,
  physicalBuild: PhysicalBuild,
  dreamBody: DreamBody,
  bestShape: BestShape,
  stress: Stress,
  exhausted: Exhausted,
  storeEmotion: StoreEmotion,
  relaxed: Relaxed,
  weightFluctuations: WeightFluctuations,
  tryWorkouts: TryWorkouts,
  boostYourHealth: BoostYourHealth,
  stayConsistent: StayConsistent,
  betterResult: BetterResult,
  healthProfile: HealthProfile,
  forgetPlanks: ForgetPlanks,
  toningPilates: ToningPilates,
  superchargeYourself: SuperchargeYourself,
  timesPerWeek: TimesPerWeek,
  howLongTrain: HowLongTrain,
  healthCondition: HealthCondition,
  seatedForwardBend: SeatedForwardBend,
  climbStairs: ClimbStairs,
  helpedMillionsPeople: HelpedMillionsPeople,
  workSchedule: WorkScheduleBelly,
  lifestyle: Lifestyle,
  energy: EnergyBelly,
  dailyWater: DailyWater,
  gripOfStress: GripOfStress,
  satisfied: Satisfied,
  sugarCravings: SugarCravings,
  sleep: Sleep,
  helpAchieveResults: HelpAchieveResults,
  unsatisfiedBody: UnsatisfiedBody,
  noIdeaToPickUp: NoIdeaToPickUp,
  easilyGiveUp: EasilyGiveUp,
  dancebitUsers: DancebitUsers,
  rewardYourself: RewardYourself,
  seeYourself: SeeYourself,
  resultInMirror: ResultInMirror,
  upgradeLife: DesignNewLife,
  dancingLevel: DancingLevelBelly,
  dancingStyle: DancingStyleBelly,
  dancingVsExercises: DancingVsExercisesBelly,
  weightGainEvents: WeightGainEvents,
  motivation: MotivationBelly,
  worldwideAchieving: WorldwideAchievingBelly,
  currentHeight: CurrentHeightBelly,
  currentWeight: CurrentWeightBelly,
  goalWeight: GoalWeightBelly,
  age: AgeBelly,
  fitnessProfile: FitnessProfile,
  motivatingEvent: MotivatingEvent,
  eventDate: EventDate,
  reach: Reach,
  confident: Confident,
  danceStyles: DancingStyle,
  gender: Gender,
  improveAreas: ImproveAreas,
  yourPlanIsReady: PlanPageIsReady,
  niceToMeet: NiceToMeet,
  diet: Diet,
  dancingStylesIntro: DancingStylesIntro,
  lessonLength: LessonLength,
  preparePlan: PreparePlanBelly,
  boostUnlocked: BoostUnlocked,
  enterEmail: EmailBelly,
  name: NameBelly,
  result: Result,
  prepaywall: Prepaywall,
  cancelOfferQuestion: CancelOfferQuestion,
  purchaseCancelOffer: PurchaseCancelOffer,
  purchaseCancelOfferSomatic: PurchaseCancelOfferSomatic,
  purchaseCancelOfferMealPlan: PurchaseCancelOfferMealPlan,
  purchaseCancelOfferRef: PurchaseCancelOfferRef,
  purchaseIntroOffer: PurchaseIntroOffer,
  purchaseFreeOffer: PurchaseFreeOffer,
  purchaseCancelOfferFree: PurchaseCancelOfferFree,
  purchaseProvider: PurchaseProvider,
  purchaseRef: PurchaseRef,
  upsell: Upsell,
  login: Login,
  emailConsent: EmailConsentBelly,
  download: Download,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePdf: PurchaseUpgrade,
  purchaseWithoutIntro: PurchaseWithoutIntro,
}

export const DYNAMIC_PAGES_SET_VARIANT_2_B: PageId[] = [
  PageId.WELCOME_BELLY_REF,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL,
  PageId.PHYSICAL_BUILD,
  PageId.DREAM_BODY,
  PageId.BEST_SHAPE,
  PageId.WEIGHT_FLUCTUATIONS,
  PageId.TRY_WORKOUTS,
  PageId.BOOST_YOUR_HEALTH,
  PageId.TIMES_PER_WEEK,
  PageId.HOW_LONG_TRAIN,
  PageId.HEALTH_CONDITION,
  PageId.SEATED_FORWARD_BEND,
  PageId.CLIMB_STAIRS,
  PageId.HELPED_MILLIONS_PEOPLE,
  PageId.WORK_SCHEDULE,
  PageId.LIFESTYlE,
  PageId.ENERGY,
  PageId.DAILY_WATER,
  PageId.SLEEP,
  PageId.UPGRADE_LIFE,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.WEIGHT_GAIN_EVENTS,
  PageId.MOTIVATION,
  PageId.HELP_ACHIEVE_RESULTS,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.FITNESS_PROFILE,
  PageId.MOTIVATING_EVENT,
  PageId.EVENT_DATE,
  PageId.REACH,
  PageId.CONFIDENT,
  PageId.UNSATISFIED_BODY,
  PageId.NO_IDEA_TO_PICK_UP,
  PageId.EASILY_GIVE_UP,
  PageId.DANCEBIT_USERS,
  PageId.REWARD_YOURSELF,
  PageId.SEE_YOURSELF,
  PageId.RESULT_IN_MIRROR,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.NAME,
  PageId.PLAN_IS_READY,
  PageId.PURCHASE_REF,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER_REF,
]

export const DYNAMIC_PAGES_SET_VARIANT_2: PageId[] = [
  PageId.WELCOME_BELLY_REF,
  PageId.TEN_MILLION_PEOPLE,
  PageId.MAIN_GOAL,
  PageId.PHYSICAL_BUILD,
  PageId.DREAM_BODY,
  PageId.BEST_SHAPE,
  PageId.WEIGHT_FLUCTUATIONS,
  PageId.TRY_WORKOUTS,
  PageId.FORGET_PLANKS,
  PageId.TIMES_PER_WEEK,
  PageId.HOW_LONG_TRAIN,
  PageId.HEALTH_CONDITION,
  PageId.WORK_SCHEDULE,
  PageId.LIFESTYlE,
  PageId.ENERGY,
  PageId.DAILY_WATER,
  PageId.SLEEP,
  PageId.UPGRADE_LIFE,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.WEIGHT_GAIN_EVENTS,
  PageId.MOTIVATION,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.FITNESS_PROFILE,
  PageId.MOTIVATING_EVENT,
  PageId.EVENT_DATE,
  PageId.REACH,
  PageId.CONFIDENT,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.NAME,
  PageId.PLAN_IS_READY,
  PageId.PURCHASE_REF,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER_REF,
]

export const DYNAMIC_PAGES_SET_VARIANT_1_C: PageId[] = [
  PageId.MAIN_GOAL,
  PageId.ADDITIONAL_GOALS,
  PageId.WHATS_HOLD_YOU,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.GENDER,
  PageId.IMPROVE_AREAS,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.SOCIAL_PROOF,
  PageId.DANCING_LEVEL,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.ACTIVITY_LEVEL,
  PageId.WORK_SCHEDULE,
  PageId.ENERGY,
  PageId.UPGRADE_LIFE,
  PageId.LESSON_LENGTH,
  PageId.MOTIVATION,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.PREPAYWALL,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]
export const DYNAMIC_PAGES_SET_VARIANT_1: PageId[] = [
  PageId.MAIN_GOAL,
  PageId.ADDITIONAL_GOALS,
  PageId.WHATS_HOLD_YOU,
  PageId.WORLDWIDE_ACHIEVING,
  PageId.GENDER,
  PageId.IMPROVE_AREAS,
  PageId.NAME,
  PageId.NICE_TO_MEET,
  PageId.CURRENT_HEIGHT,
  PageId.CURRENT_WEIGHT,
  PageId.GOAL_WEIGHT,
  PageId.AGE,
  PageId.SOCIAL_PROOF,
  PageId.DANCING_LEVEL,
  PageId.ENERGY,
  PageId.DANCING_STYLES_INTRO,
  PageId.DANCING_STYLE,
  PageId.DANCE_VS_EXERCISES,
  PageId.LESSON_LENGTH,
  PageId.MOTIVATION,
  PageId.PREPARE_PLAN,
  PageId.ENTER_EMAIL,
  PageId.EMAIL_CONSENT,
  PageId.PREPAYWALL,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.PURCHASE_CANCEL_OFFER,
]

export const FEATURE_ID_TO_PAGES_MAP: Record<string, PageId[]> = {
  [GROWTHBOOK_EXPERIMENT.DAN_340]: DYNAMIC_PAGES_SET_VARIANT_1_C,
  [GROWTHBOOK_EXPERIMENT.DAN_1051]: DYNAMIC_PAGES_SET_VARIANT_2_B,
}

export const FEATURE_ID_TO_DEFAULT_PAGES_MAP: Record<string, PageId[]> = {
  [GROWTHBOOK_EXPERIMENT.DAN_340]: DYNAMIC_PAGES_SET_VARIANT_1,
  [GROWTHBOOK_EXPERIMENT.DAN_1051]: DYNAMIC_PAGES_SET_VARIANT_2,
}

export const COHORTS_WITH_V2_PAGES_MAP = [
  Cohort.DB_BELLY,
  Cohort.DB_LEGS,
  Cohort.DB_SOMATIC,
  Cohort.DB_INFLUENCER,
  Cohort.DB_BELLY_REF,
]
