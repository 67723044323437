import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledHeaderWithTimer = {
  TimerContainer: styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: ${Color.WHITE};
    z-index: 3;
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    margin-bottom: 16px;
  `,
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 8px 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ButtonContainer: styled.div`
    width: 100%;
    max-width: 160px;
    max-height: 48px;
  `,
  Text: styled.span`
    color: ${Color.DARK};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  Timer: styled.div`
    display: flex;
    font-size: 24px;
  `,
  TimerSign: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;

    strong {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      color: ${Color.PRIMARY};
    }

    span {
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: lowercase;
    }
  `,
  Button: styled(Button)`
    max-height: 48px;
    height: 48px;
  `,
}
