import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { ButtonJa } from 'components/ButtonJa'
import { Option, OptionType } from 'components/Option'

import { getSubscriptionHighestDiscountAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectLanguage,
} from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useVatInfo'

import {
  DISCOUNT_VALUES,
  DiscountValue,
} from 'modules/purchase/components/PersonalPlan/constants'
import { CANCEL_OFFER_ADDITIONAL_DISCOUNT } from 'modules/purchase/constants'
import {
  REF_CHILD_COHORTS,
  useCohortInfo,
} from 'modules/purchase/hooks/useCohortInfo'
import {
  selectDynamicDiscount,
  selectHighestDiscountPercentage,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import cancelOfferQuestionClearFlowBg from 'assets/images/cancel-offer-question-bg-clear-flow.png'
import cancelOfferQuestionBg from 'assets/images/cancel-offer-question-bg.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledCancelOfferQuestion as S } from './CancelOfferQuestion.styles'
import {
  CANCEL_OFFER_PAYWALLS,
  OPTION_VALUES,
  QUESTION_IMAGE_PATH,
} from './constants'

export const CancelOfferQuestion: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const offerRef = useRef<HTMLDivElement>(null)

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const language = useSelector(selectLanguage)
  const highestDiscountPercentage = useSelector(selectHighestDiscountPercentage)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const hasPurchaseFreeOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_988)
  const hasPurchaseFreeOfferBellyTest = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1042,
  )
  const hasBellyPricesTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1264)
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const hasIncludedVat = useVatInfo()
  const {
    isSaleFlow,
    isBellyFlow,
    isTrialCohort,
    isTTClearFlow,
  } = useCohortInfo()

  const cohortToUse = (parentCohort || cohort) as Cohort
  const [answer, setAnswer] = useState<string>('')

  const discountPercentage = useMemo(
    () =>
      dynamicDiscount?.amount && isSaleFlow
        ? dynamicDiscount.amount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : highestDiscountPercentage ||
          DISCOUNT_VALUES[cohortToUse] ||
          DiscountValue.SIXTY_FIVE,
    [
      isSaleFlow,
      dynamicDiscount?.amount,
      highestDiscountPercentage,
      cohortToUse,
    ],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.CANCEL_OFFER_QUESTION,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        eventLogger.logCancelOfferPageAnswer(value)
        setAnswer(value)
      },
    }),
    [],
  )

  const questionImageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${QUESTION_IMAGE_PATH}_${language}.png`,
    [language],
  )

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const offerTitle = useMemo(() => {
    if (isTTClearFlow) return 'purchaseCancelOffer.questionPage.offerTitleClear'

    switch (cohortToUse) {
      case Cohort.DANCEBIT_FLOW_1:
        return 'purchaseCancelOffer.questionPage.offerTitleFixedDiscount'
      case Cohort.DANCEBIT_CLEAR_FLOW:
        return 'purchaseCancelOffer.questionPage.offerTitleClearFlow'
      default:
        return 'purchaseCancelOffer.questionPage.offerTitle'
    }
  }, [cohortToUse, isTTClearFlow])

  const offerDescription = useMemo(() => {
    if (isClearCohort) {
      return 'purchaseCancelOffer.questionPage.willHelpToUnderstandClearFlow'
    }

    if (isTTClearFlow) {
      return 'purchaseCancelOffer.questionPage.willHelpToUnderstandClear'
    }

    return 'purchaseCancelOffer.questionPage.willHelpToUnderstand'
  }, [isClearCohort, isTTClearFlow])

  const paywallPath = useMemo(() => {
    if (
      (hasPurchaseFreeOfferTest || hasPurchaseFreeOfferBellyTest) &&
      cohort !== Cohort.DB_BELLY
    ) {
      return PageId.PURCHASE_CANCEL_OFFER_FREE
    }

    if (isJapaneseFlow) {
      return PageId.PURCHASE_CANCEL_OFFER_JAPAN
    }

    if (REF_CHILD_COHORTS.includes(cohort as Cohort)) {
      return PageId.PURCHASE_CANCEL_OFFER_REF
    }

    return (
      (CANCEL_OFFER_PAYWALLS[cohortToUse as Cohort] as PageId) ||
      PageId.PURCHASE_CANCEL_OFFER
    )
  }, [
    hasPurchaseFreeOfferTest,
    hasPurchaseFreeOfferBellyTest,
    cohort,
    isJapaneseFlow,
    cohortToUse,
  ])

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  useLayoutEffect(() => {
    if (isTrialCohort) {
      let pricesTags: string = hasIncludedVat
        ? SubscriptionTags.TAX
        : SubscriptionTags.NO_TAX

      if (hasBellyPricesTest) {
        pricesTags = `${SubscriptionTags.DISCOUNT_70_OFF},${pricesTags}`
      }

      dispatch(
        getSubscriptionHighestDiscountAction(
          SubscriptionListType.PURCHASE,
          `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
        ),
      )
    }
  }, [dispatch, isTrialCohort, hasBellyPricesTest, hasIncludedVat, cohortToUse])

  useEffect(() => {
    if (offerRef.current && answer) {
      offerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [answer])

  const handleNextPage = useCallback(() => {
    eventLogger.logCancelOfferPageClosed()

    goTo({
      pathname: paywallPath,
      search,
    })
  }, [paywallPath, search])

  return (
    <>
      <S.Root
        data-testid="cancel-offer-container"
        $isJapaneseFlow={isJapaneseFlow}
      >
        <S.ImageContainer>
          <S.Image src={questionImageLink} alt="main-image" />
        </S.ImageContainer>
        <S.CancelOfferQuestionContainer>
          <S.Title>{t`purchaseCancelOffer.questionPage.doYouBelieve`}</S.Title>
          <S.List>
            <S.Option>{t`purchaseCancelOffer.questionPage.needDailyDance`}</S.Option>
            <S.Option>
              {t`purchaseCancelOffer.questionPage.danceWorkouts`}
            </S.Option>
            <S.Option>
              {t`purchaseCancelOffer.questionPage.dancingExercises`}
            </S.Option>
            <S.Option>{t`purchaseCancelOffer.questionPage.proDancer`}</S.Option>
          </S.List>
          <S.Subtitle>
            <Trans
              i18nKey={
                isTTClearFlow
                  ? 'purchaseCancelOffer.questionPage.wantToGetInShape'
                  : 'purchaseCancelOffer.questionPage.wantToLoseWeight'
              }
              values={{
                context: isStayFit
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </S.Subtitle>
          <S.Actions>
            <Option
              withoutMargin
              {...optionProps}
              value={OPTION_VALUES.YES}
              checked={answer === OPTION_VALUES.YES}
              disabled={!!answer}
            >
              <S.Button
                $isJapanese={isJapaneseFlow}
                $isBellyFlow={isBellyFlow}
              >{t`purchaseCancelOffer.questionPage.yes`}</S.Button>
            </Option>

            <Option
              withoutMargin
              {...optionProps}
              value={OPTION_VALUES.NO}
              checked={answer === OPTION_VALUES.NO}
              disabled={!!answer}
            >
              <S.Button
                $isJapanese={isJapaneseFlow}
                $isBellyFlow={isBellyFlow}
              >{t`purchaseCancelOffer.questionPage.no`}</S.Button>
            </Option>
          </S.Actions>
          {!!answer && (
            <S.Offer ref={offerRef}>
              {answer === OPTION_VALUES.NO && (
                <S.OfferTitle>{t`purchaseCancelOffer.questionPage.totallyRight`}</S.OfferTitle>
              )}
              {answer === OPTION_VALUES.YES && (
                <S.OfferTitle>
                  <Trans
                    i18nKey="purchaseCancelOffer.questionPage.nothingButMyths"
                    components={[<br />]}
                  />
                </S.OfferTitle>
              )}
              <S.OfferSubtitle>
                <S.OfferSubtitleTop>{t`purchaseCancelOffer.questionPage.wantToLearnMore`}</S.OfferSubtitleTop>
                <br />
                {isClearCohort
                  ? t`purchaseCancelOffer.questionPage.createdUniqueClearFlow`
                  : t`purchaseCancelOffer.questionPage.createdUnique`}
              </S.OfferSubtitle>
              <S.OfferContainer>
                <S.OfferImage
                  src={
                    cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW
                      ? cancelOfferQuestionClearFlowBg
                      : cancelOfferQuestionBg
                  }
                  alt="label"
                />
                <S.OfferText>
                  <Trans
                    i18nKey={offerTitle}
                    components={{ span: <span /> }}
                    values={{
                      discount: discountPercentage,
                      context: isStayFit
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                </S.OfferText>
              </S.OfferContainer>
              <S.OfferDescription>
                <Trans
                  i18nKey={offerDescription}
                  values={{
                    context: isStayFit
                      ? MAIN_GOALS.STAY_FIT
                      : MAIN_GOALS.LOSE_WEIGHT,
                  }}
                />
              </S.OfferDescription>
            </S.Offer>
          )}
        </S.CancelOfferQuestionContainer>
      </S.Root>
      {!!answer && (
        <S.ButtonContainer $isJapanese={isJapaneseFlow}>
          {isJapaneseFlow ? (
            <ButtonJa
              disabled={!answer}
              onClick={handleNextPage}
            >{t`actions.continue`}</ButtonJa>
          ) : (
            <Button
              disabled={!answer}
              onClick={handleNextPage}
            >{t`actions.continue`}</Button>
          )}
        </S.ButtonContainer>
      )}
    </>
  )
}
