import styled from 'styled-components'

import { Button } from 'components/Button'

import linkIcon from 'assets/images/link-pay.png'

type TWrapperProps = {
  buttonHeight: number
  borderRadius: number
}

export const StyledLinkPaymentButton = {
  Wrapper: styled.div<TWrapperProps>`
    height: ${({ buttonHeight }) => `${buttonHeight}px`};

    & * {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    }
  `,
  Button: styled(Button)`
    position: relative;
    background: #61d17a;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 62px;
      height: 21px;
      background-image: url(${linkIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background: #55c56e;
    }

    &:active,
    &:focus-visible {
      background: #8af0a1;
    }
  `,
}
