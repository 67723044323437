import { RefObject, useEffect } from 'react'

export const useLockScroll = (
  isModalShown: boolean,
  ref?: RefObject<HTMLElement>,
): void => {
  useEffect(() => {
    const lockScroll = (event) => event.preventDefault()
    const elem = ref?.current || document

    if (isModalShown) {
      elem.addEventListener('touchmove', lockScroll, { passive: false })
    }

    return () => elem.removeEventListener('touchmove', lockScroll, false)
  }, [isModalShown, ref])
}
