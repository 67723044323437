import armsFemale from 'assets/images/arms-issue-female.png'
import armsMale from 'assets/images/arms-issue-male.png'
import elbowsFemale from 'assets/images/elbows-issue-female.png'
import elbowsMale from 'assets/images/elbows-issue-male.png'
import feetFemale from 'assets/images/feet-issue-female.png'
import feetMale from 'assets/images/feet-issue-male.png'
import kneesFemale from 'assets/images/knees-issue-female.png'
import kneesMale from 'assets/images/knees-issue-male.png'
import legsFemale from 'assets/images/legs-issue-female.png'
import legsMale from 'assets/images/legs-issue-male.png'
import neckFemale from 'assets/images/neck-issue-female.png'
import neckMale from 'assets/images/neck-issue-male.png'
import noneFemale from 'assets/images/none-issue-female.png'
import noneMale from 'assets/images/none-issue-male.png'
import shouldersFemale from 'assets/images/shoulders-issue-female.png'
import shouldersMale from 'assets/images/shoulders-issue-male.png'

export const QUESTION =
  'Do you have problems with your bones, muscles or joints?'

export const SELECT_NONE = 'noIssues'

export const OPTION_VALUES_MALE = [
  {
    text: `onboarding.injuriesIssues.${SELECT_NONE}`,
    value: SELECT_NONE,
    imageUrl: noneMale,
  },
  {
    text: 'onboarding.injuriesIssues.knees',
    value: 'knees',
    imageUrl: kneesMale,
  },
  {
    text: 'onboarding.injuriesIssues.shoulders',
    value: 'shoulders',
    imageUrl: shouldersMale,
  },
  {
    text: 'onboarding.injuriesIssues.neck',
    value: 'neck',
    imageUrl: neckMale,
  },
  {
    text: 'onboarding.injuriesIssues.arms',
    value: 'arms',
    imageUrl: armsMale,
  },
  {
    text: 'onboarding.injuriesIssues.elbows',
    value: 'elbows',
    imageUrl: elbowsMale,
  },
  {
    text: 'onboarding.injuriesIssues.legs',
    value: 'legs',
    imageUrl: legsMale,
  },
  {
    text: 'onboarding.injuriesIssues.feet',
    value: 'feet',
    imageUrl: feetMale,
  },
]

export const OPTION_VALUES_FEMALE = [
  {
    value: SELECT_NONE,
    text: `onboarding.injuriesIssues.${SELECT_NONE}`,
    imageUrl: noneFemale,
  },
  {
    value: 'knees',
    text: 'onboarding.injuriesIssues.knees',
    imageUrl: kneesFemale,
  },
  {
    value: 'shoulders',
    text: 'onboarding.injuriesIssues.shoulders',
    imageUrl: shouldersFemale,
  },
  {
    value: 'neck',
    text: 'onboarding.injuriesIssues.neck',
    imageUrl: neckFemale,
  },
  {
    value: 'arms',
    text: 'onboarding.injuriesIssues.arms',
    imageUrl: armsFemale,
  },
  {
    value: 'elbows',
    text: 'onboarding.injuriesIssues.elbows',
    imageUrl: elbowsFemale,
  },
  {
    value: 'legs',
    text: 'onboarding.injuriesIssues.legs',
    imageUrl: legsFemale,
  },
  {
    value: 'feet',
    text: 'onboarding.injuriesIssues.feet',
    imageUrl: feetFemale,
  },
]
