import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectUserAge,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { DynamicTimer } from 'modules/purchase/components/DynamicTimer'
import { FAQList } from 'modules/purchase/components/FAQList'
import { HeaderWithTimerV2 } from 'modules/purchase/components/HeaderWithTimerV2'
import { IntroMedia } from 'modules/purchase/components/IntroMedia'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { LimitedDeal } from 'modules/purchase/components/LimitedDeal'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PhotoResult } from 'modules/purchase/components/PhotoResult'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { TimerWithDiscount } from 'modules/purchase/components/TimerWithDiscount'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectDynamicDiscount,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import {
  Cohort,
  DynamicDiscountType,
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseIntroOffer as S } from './PurchaseIntroOffer.styles'
import { SCREEN_ID_MAP } from './constants'

export const PurchaseIntroOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const { buttonText, benefits, benefitsBlockTitle } = useDynamicPaywallConfig()

  const isStayFit = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const age = useSelector(selectUserAge)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { fetchingActionsList, subscriptions, cohort } = usePurchaseStore()
  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)

  const cohortToUse = parentCohort || cohort
  const screenId = SCREEN_ID_MAP[cohortToUse as Cohort] || ScreenId.OLD

  const { isNewClearFlow, isSaleFlow } = useCohortInfo()

  usePurchaseAnalytics({
    screenId,
  })

  const hasIncludedVat = useVatInfo()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasHighPrices && age > 39) {
      pricesTags = `${SubscriptionTags.AGE_40_70},${pricesTags}`
    }

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(screenId))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [age, dispatch, hasHighPrices, hasIncludedVat, screenId])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          {isSaleFlow && <Ticker />}

          {isSaleFlow && (
            <HeaderWithTimerV2 onButtonClick={handleShowPayment} />
          )}

          <IntroMedia />
          <S.Wrapper>
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || (
                  <Trans
                    i18nKey={
                      isClearCohort
                        ? `purchase1.programDescriptionTitleClearEdition`
                        : `purchase1.programDescriptionTitle`
                    }
                    values={{
                      context: isStayFit
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                )}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ id, text }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchase1.personalPlan"
                        components={[<strong />]}
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchase1.effectiveWorkouts"
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>{t`purchase1.specialPrograms`}</S.ListItem>
                    <S.ListItem>{t`purchase1.practiceAnywhere`}</S.ListItem>
                    <S.ListItem>{t`purchase1.popularDanceStyles`}</S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
          </S.Wrapper>

          {!isSaleFlow && (
            <S.TimerSubheaderWithTimer
              elemForComparisonRef={buttonWrapperElementRef}
              onButtonClick={handleShowPayment}
            />
          )}

          <S.Wrapper>
            {dynamicDiscount?.promoText ? (
              <TimerWithDiscount />
            ) : (
              <>
                {isSaleFlow &&
                  dynamicDiscount?.type === DynamicDiscountType.STATIC && (
                    <LimitedDeal />
                  )}

                {isSaleFlow &&
                  dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
                    <DynamicTimer />
                  )}
              </>
            )}
            <S.SubscriptionsBlockIntroOffer
              elemForComparisonRef={buttonWrapperElementRef}
              hasInnerDisclaimer={isNewClearFlow}
              hasExternalDisclaimer={!isNewClearFlow}
            >
              <S.Button
                data-order="2"
                data-text="get_my_plan"
                type="button"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </S.Button>
            </S.SubscriptionsBlockIntroOffer>

            <S.FeaturedBlockContainer>
              <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
              <picture>
                <source srcSet={featuredImageWebp} type="image/webp" />
                <img src={featuredImagePng} alt="body" />
              </picture>
            </S.FeaturedBlockContainer>

            <MoneyBackGuarantee />
            <SecurityInfo />
            <PhotoResult />
            <FAQList />
            <S.CustomerReviews />

            <S.ButtonContainer>
              <Button
                type="button"
                data-order="3"
                data-text="get_my_plan"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            <IntroOfferDisclaimer />
          </S.Wrapper>
        </div>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
