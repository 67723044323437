import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCancelOfferHero = {
  VideoWrapper: styled.div`
    border-radius: 16px;
    width: 328px;
    height: 204px;
    min-height: 204px;
    overflow: hidden;
    margin-bottom: 40px;
  `,
  ImageWrapper: styled.div`
    position: relative;
    border-radius: 16px;
    background: radial-gradient(
      141.87% 100.54% at 80.34% 8.33%,
      #eefaff 9.75%,
      #aadffd 75.43%,
      #9cdbff 99.11%
    );
    width: 328px;
    height: 276px;
  `,
  Img: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 328px;
    height: 276px;
  `,
  Result: styled.div`
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 12px;
    border-radius: 12px;
    background: ${Color.WHITE};
    text-align: center;
    width: 304px;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;

    span {
      color: ${Color.PRIMARY};
      font-weight: 700;
    }
  `,
  Disclaimer: styled.p`
    color: #51555b;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
    margin-bottom: 40px;
  `,
}
