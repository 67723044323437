import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledToggle = {
  Wrapper: styled.label<{ $isFlat: boolean }>`
    display: inline-block;
    position: relative;
    width: 180px;
    height: 34px;
    background-color: ${Color.LIGHT};
    cursor: pointer;
    border-radius: 25px;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 34px;
    -webkit-tap-highlight-color: transparent;

    ${({ $isFlat }) =>
      $isFlat &&
      css`
        padding: 8px;
        width: 160px;
        height: 48px;
        border-radius: 16px;
        margin-bottom: 16px;
      `}
  `,
  Input: styled.input<{ $isFlat: boolean }>`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + span {
        left: ${({ $isFlat }) => ($isFlat ? 'calc(50% - 8px)' : '50%')};
      }
      & + span + span {
        &:before {
          color: ${Color.DARK};
        }
        &:after {
          color: ${Color.WHITE};
        }
      }
    }
  `,
  Toggle: styled.span<{ $isFlat: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.2s;
    border-radius: 25px;
    background-color: ${Color.PRIMARY};
    width: 50%;
    height: 100%;

    ${({ $isFlat }) =>
      $isFlat &&
      css`
        top: 50%;
        left: 8px;
        right: 8px;
        transform: translateY(-50%);
        border-radius: 12px;
        height: 70%;
      `}
  `,
  Value: styled.span<{ $labels: [string, string]; $isFlat: boolean }>`
    &:before,
    &:after {
      color: ${Color.DARK};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 0;
      width: 50%;
      height: 100%;
      z-index: 1;
      transition: color 0.2s;

      ${({ $isFlat }) =>
        $isFlat &&
        css`
          left: calc(50% - 8px);
          text-transform: uppercase;
          font-size: 13px;
        `}
    }
    &:after {
      content: '${({ $labels }) => $labels[1]}';
    }
    &:before {
      content: '${({ $labels }) => $labels[0]}';
      color: ${Color.WHITE};
      left: ${({ $isFlat }) => ($isFlat ? '8px' : '0')};
    }
  `,
}
