import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContactWithSupport } from 'components/ContactWithSupport'

import secureBadgeImg from 'assets/images/stripe-image.png'

import { PagesSource } from 'root-constants'

import { StyledSecurityInfo as S } from './SecurityInfoNew.styles'

export type TSecurityInfoProps = {
  className?: string
  margin?: string
}

export const SecurityInfoNew: React.FC<TSecurityInfoProps> = ({
  className = '',
  margin,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper className={className} $margin={margin}>
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textFirst`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.Text>
        {t`purchase1.securityInfo.textSecond`}
        <S.SecureBadge src={secureBadgeImg} alt="Secure badge" />
      </S.Text>
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>
      <S.Text>
        <ContactWithSupport
          text={t('commonComponents.contactSupport')}
          source={PagesSource.PAYWALL}
        />
      </S.Text>
    </S.Wrapper>
  )
}
