import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { StyledPurchasePhoneSlider as S } from './PurchasePhoneSlider.styles'
import { SLIDES } from './constants'

export const PurchasePhoneSlider: FC = () => {
  Swiper.use([Pagination, Autoplay])

  return (
    <S.Wrapper>
      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {SLIDES.map(({ img, subtitle, title, alt }) => (
            <SwiperSlide key={alt}>
              <S.Card>
                <S.TopLabel>
                  <Trans i18nKey={title} components={{ span: <span /> }} />
                </S.TopLabel>

                <S.Image src={img} alt={alt} />

                {subtitle && (
                  <S.Subtitle>
                    <Trans i18nKey={subtitle} />
                  </S.Subtitle>
                )}
              </S.Card>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
