import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIconAndCheckbox, answerTheme } from 'storybook-ui'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { Color, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledHealthCondition as S } from './HealthCondition.styles'
import {
  OPTION_VALUES,
  OPTION_VALUES_JAPANESE,
  OPTION_VALUES_LEGS,
  QUESTION,
  SELECT_NONE,
} from './constants'

export const HealthCondition: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const { optionData } = useDynamicOBConfig()
  const { isLegsFlow, isNewAdultFlow } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (answer: string, isChecked: boolean) => {
        if (isChecked && answer === SELECT_NONE) {
          setAnswers([answer])
          return
        }

        if (isChecked) {
          const newAnswers = answers.filter((item) => item !== SELECT_NONE)
          setAnswers([...newAnswers, answer])
        } else {
          setAnswers(answers.filter((item) => item !== answer))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    answers,
    handleContinue,
  ])

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isLegsFlow) return OPTION_VALUES_LEGS

    if (isJapaneseFlow) return OPTION_VALUES_JAPANESE

    return OPTION_VALUES
  }, [isJapaneseFlow, isLegsFlow, optionData])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge $isAdult={isNewAdultFlow}>
          <PageTitle marginBottom={8}>
            {t('onboarding.injuriesIssues.title')}
          </PageTitle>
          <S.Subtitle>{t('onboarding.injuriesIssues.description')}</S.Subtitle>

          {options.map(({ imageUrl, text, value }) => (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={value}
              value={value}
              imageSrc={imageUrl}
              theme={theme}
              borderRadius="16px"
              height={isJapaneseFlow ? '106px' : '100px'}
              padding="16px 16px 16px 8px"
              maxWidth="345px"
              imageAlignSelf="center"
              imageHeight="100x"
              imageWidth="100px"
              margin="0 0 16px 0"
              checkboxActiveBorder={`2px solid ${Color.PRIMARY}`}
              iconSrc={checkIcon}
              checkboxWidth="24px"
              checked={answers.includes(value)}
            >
              <Trans>{text}</Trans>
            </AnswerWithIconAndCheckbox>
          ))}
        </S.Container>
      </ContainerBeforePinnedButton>
      <S.NavigationButtonBelly
        type="button"
        $isAdult={isNewAdultFlow}
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
