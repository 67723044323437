import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import chart from 'assets/images/sprite/hormonal-changes-chart.svg'

import { StyledHormonalChanges as S } from './HormonalChanges.styles'
import { QUESTION } from './constants'

export const HormonalChanges: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginTop={16} marginBottom={16}>
            <Trans i18nKey="onboarding.hormonalChanges.title" />
          </PageTitle>

          <SvgImage svg={chart} />

          <S.List>
            <S.ListItem>
              <Trans i18nKey="onboarding.hormonalChanges.struggleWithWeight" />
            </S.ListItem>
            <S.ListItem>
              <Trans i18nKey="onboarding.hormonalChanges.changingEstrogen" />
            </S.ListItem>
          </S.List>
        </Container>
      </ContainerBeforePinnedButton>
      <NavigationButtonBelly type="button" onClick={handleClick}>
        <Trans i18nKey="actions.continue" />
      </NavigationButtonBelly>
    </>
  )
}
