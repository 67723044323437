import { LoginMethod } from 'root-constants'

export const START_IMAGE = '/images/start'

export const POPUP_DISAPPEARANCE_TIME = 3000

export const LOGIN_METHOD_INSTRUCTION_LINK_MAP = {
  [LoginMethod.EMAIL]:
    'https://dancebit.onelink.me/cJoT?pid=web2web_payment_success&c=Dancebit_DL_Password&deep_link_value=custom_token&af_dp=harmonybitdancebit%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
  [LoginMethod.FACEBOOK]:
    'https://dancebit.onelink.me/cJoT?pid=web2web_payment_success&c=Dancebit_DL_Facebook&deep_link_value=custom_token&af_dp=harmonybitdancebit%3A%2F%2F%3Flogin%3D&deep_link_sub1=facebook.com',
  [LoginMethod.APPLE]:
    'https://dancebit.onelink.me/cJoT?pid=web2web_payment_success&c=Dancebit_DL_Apple&deep_link_value=custom_token&af_dp=harmonybitdancebit%3A%2F%2F%3Flogin%3D&deep_link_sub1=apple.com',
}
