import styled, { css, keyframes } from 'styled-components'

import { Color } from 'root-constants'

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const tipStyles = css`
  position: absolute;
  padding: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  text-align: left;
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  color: white;
  border-radius: 7px;
  transform: translateX(-50%);
  white-space: nowrap;
`

export const StyledLoseWeightGraphAge = {
  Wrapper: styled.div`
    overflow: hidden;
    min-height: 290px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  GraphContent: styled.div`
    position: relative;
  `,
  TipNow: styled.p<{ $isGerman?: boolean; $isImperial?: boolean }>`
    ${tipStyles};

    top: 50px;
    left: 25px;
    border-radius: 4px;
    background: rgba(235, 53, 36, 0.16);
    color: #eb3524;
    animation-delay: 400ms;

    ${({ $isGerman, $isImperial }) =>
      $isGerman &&
      $isImperial &&
      css`
        left: 35px;
      `}
  `,
  TipAfter: styled.p<{ $isGerman?: boolean }>`
    ${tipStyles};

    top: 105px;
    left: 225px;
    border-radius: 4px;
    background: #55d16f;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    animation-delay: 1200ms;

    ${({ $isGerman }) =>
      $isGerman &&
      css`
        left: 225px;
      `}
  `,
  WrapperWeightLoss: styled.span<{ $isFrenchOrSpanish?: boolean }>`
    ${tipStyles};

    top: 85px;
    left: 140px;
    border-radius: 4px;
    background: #f5f6f7;
    color: #8e9199;
    animation-delay: 1000ms;

    ${({ $isFrenchOrSpanish }) =>
      $isFrenchOrSpanish &&
      css`
        left: 150px;
      `}
  `,
  WeightStaysOff: styled.span<{ $isFrenchOrSpanish?: boolean }>`
    ${tipStyles};

    top: 155px;
    left: 270px;
    padding: 0;
    color: #55d16f;
    text-align: right;
    animation-delay: 1500ms;

    ${({ $isFrenchOrSpanish }) =>
      $isFrenchOrSpanish &&
      css`
        left: 277px;
      `}
  `,
  ExpectedResultsContainer: styled.div`
    ${tipStyles};

    top: 50px;
    left: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation-delay: 1200ms;
  `,
  ExpectedResults: styled.div`
    color: #8e9199;
  `,
  GraphImg: styled.img``,
}
