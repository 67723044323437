import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

import blueCheck from 'assets/images/check-icon-rounded.svg'
import verticalBorder from 'assets/images/vertical-border-short.png'
import wreath from 'assets/images/wreath.png'

import { Color } from 'root-constants'

type TPurchaseVariant2Props = {
  $isStayfit?: boolean
}

export const StyledPurchaseVariant2 = {
  Container: styled(Container)`
    padding-bottom: 32px;
  `,
  Title: styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 24px;
  `,
  SuccessTitle: styled.h3`
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  `,
  Subtitle: styled.p`
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAYISH_BLUE};
  `,
  GoalBlock: styled.div`
    display: flex;
    margin-bottom: 24px;
  `,
  GoalCard: styled.div<TPurchaseVariant2Props>`
    display: flex;
    align-items: center;
    min-width: 166px;
    margin: 0 auto;

    &:first-child {
      ${({ $isStayfit }) =>
        !$isStayfit &&
        css`
          margin: 0 16px 0 0;
          border-image-source: url(${verticalBorder});
          border-width: 1px;
          border-style: solid;
          border-image-slice: 0 1 0 0;
          border-image-repeat: stretch;
        `}
    }
  `,
  GoalImg: styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-right: 8px;
    border-radius: 4px;
    background: rgba(174, 101, 231, 0.1);
  `,
  GoalText: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;

    & > span {
      font-weight: 400;
    }

    & > p {
      font-weight: 800;
    }
  `,
  NumberOne: styled.span`
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;

    & strong {
      font-size: 56px;
      font-weight: 600;
    }
  `,
  Wreath: styled.div`
    min-height: 182px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    background-image: url(${wreath});
    background-size: cover;
    background-repeat: no-repeat;
  `,
  FeaturedBlockContainer: styled.div`
    margin: 40px 0;
  `,
  FeaturedBlockTitle: styled.h2`
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 24px 16px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 8px 20px 0 #e1e3ec;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 16px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  FAQList: styled(FAQList)`
    h3 {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 28px;
      color: ${Color.GRAYISH_BLUE};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    ul {
      margin-top: 24px;
    }

    li {
      padding-left: 60px;

      &::before {
        left: 4px;
        top: -12px;
        height: 48px;
        width: 48px;
      }
    }
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    margin-top: 40px;
  `,
  SecurityInfo: styled(SecurityInfo)`
    margin: 0;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
