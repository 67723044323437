import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { setPlanAdditionsAction } from 'modules/purchase/redux/actions/common'

import { PLAN_ADDITIONS_MAP } from 'root-constants'

export const useSetPlanAdditions = (): void => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const currentPageId = getPageIdFromPathName(pathname)
    const planAdditions = PLAN_ADDITIONS_MAP[currentPageId] || []

    if (planAdditions) {
      dispatch(setPlanAdditionsAction(planAdditions))
    }
  }, [dispatch, pathname])
}
