import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import quotes from 'assets/images/bold-quotes.svg'
import backImg from 'assets/images/influencer-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledInfluencerWelcome = {
  BgImage: styled.div`
    position: absolute;
    z-index: -1;
    background-image: url(${backImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    left: 50%;
  `,
  Container: styled(Container)`
    position: relative;

    min-height: calc(var(--full-height) - 56px);
    height: 100%;

    padding: 0 16px 20px;
  `,
  AvatarContainer: styled.div`
    width: 255px;
    position: absolute;

    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  Avatar: styled.img`
    width: 215px;
    height: 215px;
  `,
  Label: styled.div`
    max-width: 125px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    padding: 6px 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
    background-color: ${Color.WHITE};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${Color.DARK};

    & span:last-child {
      margin-top: 2px;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0.5px;
      color: ${Color.GRAY_80};
    }
  `,
  QuoteContainer: styled.div`
    padding-top: 205px;
    margin-bottom: 32px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      padding-top: 190px;
    }
  `,
  Quote: styled.div`
    position: relative;
    display: flex;
    padding: 70px 8px 24px 8px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${Color.GRAY_80};

    box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
  `,
  QuoteText: styled.div`
    position: relative;
    max-width: 250px;
    margin-bottom: 10px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 22px;
      background-image: url(${quotes});
      background-repeat: no-repeat;
    }

    &::before {
      top: -10px;
      left: -25px;
    }

    &::after {
      bottom: -8px;
      right: -25px;
    }
  `,
  TakeOff: styled.div`
    padding: 8px 16px;
    border-radius: 30px;
    background-color: ${Color.GRAY_10};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;

    color: ${Color.GRAY_80};

    & strong {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;

      color: ${Color.PRIMARY};
    }
  `,
  WelcomeText: styled.div`
    margin-bottom: 8px;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
  `,
  Arrow: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ContinueButton: styled(NavigationButton)`
    max-width: none;
    padding: 8px 0 12px;

    & button {
      box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
    }
  `,
  Description: styled.span`
    display: flex;
    justify-content: center;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;

    color: ${Color.GRAY_80};
  `,
  Terms: styled.div`
    z-index: 2;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: ${Color.DISABLED};

      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
