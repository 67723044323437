import styled from 'styled-components'

import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'

import subscriptionListBackground from 'assets/images/subscriptions-block-bg-ja.png'

import { Color } from 'root-constants'

import { CartDisclaimer } from '../CartDisclaimer'

type TSubscriptionsBlockIntroOfferProps = {
  className?: string
}

export const StyledSubscriptionsBlockIntroOffer = {
  WrapperContainer: styled.div<TSubscriptionsBlockIntroOfferProps>`
    margin: 0 0 40px;
  `,
  Wrapper: styled.div<TSubscriptionsBlockIntroOfferProps>`
    margin: 0 0 16px;
    padding: 24px 16px;
    background: url(${subscriptionListBackground}) center center no-repeat;
    background-size: 343px 100%;
  `,
  Title: styled.h2`
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  SubscriptionDisclaimer: styled(IntroOfferDisclaimer)`
    margin-top: 16px;
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
    }
  `,
  CartDisclaimer: styled(CartDisclaimer)`
    margin-top: 16px;
  `,
}
