import styled from 'styled-components'

import { Color } from 'root-constants'

export const SubscriptionTwoPlansBlockStyles = {
  Wrapper: styled.div`
    margin-top: 32px;
    margin-bottom: 50px;

    .swiper-wrapper {
      margin-top: 40px;
    }

    .swiper-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      height: 10px;
      display: flex;
      justify-content: center;
      top: 45px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.2;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.PRIMARY};
      opacity: 1;
    }
  `,
  Title: styled.h3`
    width: 100%;
    max-width: 190px;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    line-height: 32px;
    z-index: 2;
  `,
}
