import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { IStepInfo, TAnswer } from 'models/common.model'

import { replaceHistory } from 'browser-history'

export const useSkipStep = ({
  pageId,
  nextPagePath,
  question,
}: IStepInfo): ((value: TAnswer) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (value: TAnswer) => {
      dispatch(
        setAnswersAction({
          question,
          answers: value,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      replaceHistory(nextPagePath)
    },
    [dispatch, nextPagePath, pageId, question],
  )
}
