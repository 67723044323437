import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  GET_CART_LIST,
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectUserAge,
} from 'root-redux/selects/common'
import { selectUserCart } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { CartDisclaimer } from 'modules/purchase/components/CartDisclaimer'
import { DynamicTimer } from 'modules/purchase/components/DynamicTimer'
import { FAQList } from 'modules/purchase/components/FAQList'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { IntroOfferDisclaimerV2 } from 'modules/purchase/components/IntroOfferDisclaimerV2'
import { LimitedDeal } from 'modules/purchase/components/LimitedDeal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PersonalPlan } from 'modules/purchase/components/PersonalPlan'
import { PhotoResult } from 'modules/purchase/components/PhotoResult'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE_CLEAR,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReviewsCancelOffer } from 'modules/purchase/components/ReviewsCancelOffer'
import { ReviewsNoOnboarding } from 'modules/purchase/components/ReviewsNoOnboarding'
import { SecretGiftCards } from 'modules/purchase/components/SecretGiftCards'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'
import { SubscriptionsBlockIntroOfferV2 } from 'modules/purchase/components/SubscriptionsBlockIntroOfferV2'
import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  CURRENCY_SYMBOLS,
  Currency,
} from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import { CheckoutWithoutIntroOffer } from 'modules/purchase/pages/CheckoutWithoutIntroOffer'
import { SECRET_GIFT_COST } from 'modules/purchase/pages/PurchaseWithoutIntro/constant'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { PageId } from 'page-constants'
import {
  Cohort,
  DynamicDiscountType,
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseCancelOffer as S } from './PurchaseCancelOffer.styles'

export const PurchaseCancelOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isStayFit = useSelector(selectIsStayFitFlow)
  const age = useSelector(selectUserAge)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const userCart = useSelector(selectUserCart)

  const {
    cohort,
    currentMeasurementSystem,
    fetchingActionsList,
    subscriptions,
    userAnswers,
    currency,
    periodQuantity,
    fullPrice,
    trialPeriodDays,
    trialPrice,
    lookupKey,
    dynamicDiscount,
  } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const {
    isSaleFlow,
    isShortFlow,
    isGiftFlow,
    isNewClearFlow,
    isTTClearFlow,
  } = useCohortInfo()

  usePaymentElementStatus()

  const {
    subtitle,
    benefits,
    benefitsBlockTitle,
    buttonText,
  } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenId: ScreenId.CANCEL_DEFAULT,
    isAmplitudeEvent: true,
  })

  const hasIncludedVat = useVatInfo()

  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)
  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)
  const hasCancelOfferTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1182)
  const hasBellyPricesTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1264)

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )

  useViewportValue(ScreenName.CANCEL_OFFER, !isCheckoutShown)

  const discountPercentage = useMemo(
    () =>
      dynamicDiscount?.amount && isSaleFlow
        ? dynamicDiscount.amount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : null,
    [isSaleFlow, dynamicDiscount?.amount],
  )

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const isCartReady = useMemo(
    () => !fetchingActionsList?.includes(GET_CART_LIST),
    [fetchingActionsList],
  )

  const isWeeklyCohort = useMemo(() => cohortToUse === Cohort.DB_ADULT_WEEK, [
    cohortToUse,
  ])

  useEffect(() => {
    if (!selectedSubscriptionId || !isCartReady || !arePricesReady) return

    dispatch(
      setScreenNameAction(
        userCart.length > 0
          ? ScreenName.CANCEL_OFFER_WITH_UPSELL
          : ScreenName.CANCEL_OFFER,
      ),
    )
  }, [
    arePricesReady,
    dispatch,
    isCartReady,
    selectedSubscriptionId,
    userCart.length,
  ])

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasHighPrices && age > 39) {
      pricesTags = `${SubscriptionTags.AGE_40_70},${pricesTags}`
    }

    if (hasBellyPricesTest) {
      pricesTags = `${SubscriptionTags.DISCOUNT_70_OFF},${pricesTags}`
    }

    dispatch(startFetching(GET_CART_LIST))

    dispatch(setScreenIdAction(ScreenId.CANCEL_DEFAULT))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
      ),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [age, dispatch, hasBellyPricesTest, hasHighPrices, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const Disclaimer = () => {
    if (hasObUpsellTest && userCart.length > 0) {
      return <CartDisclaimer />
    }

    if (cohortToUse === Cohort.DB_ADULT_WEEK) return <IntroOfferDisclaimerV2 />

    return <IntroOfferDisclaimer />
  }

  const Reviews = () => {
    if (isShortFlow) return <ReviewsNoOnboarding />

    if (isTTClearFlow) {
      return (
        <PhotoResultV3
          femaleSlides={SLIDES_FEMALE_CLEAR}
          maleSlides={SLIDES_MALE}
        />
      )
    }

    return <PhotoResult />
  }

  const handleShowPayment = useCallback(
    async (event) => {
      if (cohortToUse === Cohort.CANCEL_INTRO_FAST) {
        await dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            subscription_duration: `${periodQuantity}`,
            price_id: lookupKey,
            subscription_price: fullPrice,
            trial_period: trialPeriodDays,
            trial_price: trialPrice,
          }),
        )
      }

      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.CANCEL_OFFER,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      setIsCheckoutShown(true)
    },
    [
      cohortToUse,
      currency,
      dispatch,
      fullPrice,
      lookupKey,
      periodQuantity,
      trialPeriodDays,
      trialPrice,
    ],
  )

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          {isSaleFlow && <Ticker />}

          <PersonalPlan
            externalDiscountPercentage={discountPercentage}
            onButtonClick={handleShowPayment}
          />
          <S.Wrapper>
            <S.AppDescription>
              {subtitle || (
                <Trans
                  i18nKey="purchaseCancelOffer.appDescription"
                  components={[<strong />, <br />]}
                />
              )}
            </S.AppDescription>

            {isSaleFlow &&
              dynamicDiscount?.type === DynamicDiscountType.STATIC &&
              !isGiftFlow && <LimitedDeal isCancelOffer />}

            {isSaleFlow &&
              !isGiftFlow &&
              dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
                <DynamicTimer />
              )}

            {cohortToUse === Cohort.DB_ADULT_WEEK ? (
              <SubscriptionsBlockIntroOfferV2>
                <S.ButtonContainer>
                  <S.Button
                    data-order="1"
                    data-text="get_my_plan"
                    type="button"
                    onClick={handleShowPayment}
                  >
                    {buttonText || t`actions.getMyPlan`}
                  </S.Button>
                </S.ButtonContainer>
                <S.IntroOfferDisclaimerV2 />
              </SubscriptionsBlockIntroOfferV2>
            ) : (
              <>
                {isGiftFlow && (
                  <S.Title>
                    <Trans
                      i18nKey="purchaseWithoutIntro.titleGift"
                      values={{
                        currency: CURRENCY_SYMBOLS[currency],
                        price:
                          SECRET_GIFT_COST[currency] ||
                          SECRET_GIFT_COST[Currency.USD],
                      }}
                    />
                  </S.Title>
                )}
                <SubscriptionsBlockIntroOffer
                  hasInnerDisclaimer={isGiftFlow || isNewClearFlow}
                  hasExternalDisclaimer={!isGiftFlow && !isNewClearFlow}
                  withTitle={!isGiftFlow}
                  clearBackground={isGiftFlow}
                  isCancelOffer
                >
                  <S.Button
                    data-order={hasCancelOfferTest ? '2' : '1'}
                    data-text="get_my_plan"
                    type="button"
                    onClick={handleShowPayment}
                  >
                    {buttonText || t`actions.getMyPlan`}
                  </S.Button>
                </SubscriptionsBlockIntroOffer>
              </>
            )}

            {isGiftFlow && <SecretGiftCards />}

            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || t`purchaseCancelOffer.appBenefits.title`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ text, id }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey={
                          isTTClearFlow
                            ? 'purchaseCancelOffer.appBenefits.targetWeightClear'
                            : 'purchaseCancelOffer.appBenefits.targetWeight'
                        }
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                          weight: userAnswers?.[PageId.GOAL_WEIGHT],
                          unit: t(
                            `commonComponents.${currentMeasurementSystem}`,
                          ),
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.sustainSuccess" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.moreEnergy" />
                    </S.ListItem>
                    {!isStayFit && (
                      <S.ListItem>
                        <Trans i18nKey="purchaseCancelOffer.appBenefits.betterHealth" />
                      </S.ListItem>
                    )}
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.motivation" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.happierYou" />
                    </S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
            <Reviews />
            <FAQList />
          </S.Wrapper>
          <ReviewsCancelOffer>
            <Button
              data-order={hasCancelOfferTest ? '3' : '2'}
              data-text="get_my_plan"
              type="button"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </Button>
          </ReviewsCancelOffer>
          <S.FeaturedBlockContainer>
            <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
            <picture>
              <source srcSet={featuredImageWebp} type="image/webp" />
              <img src={featuredImagePng} alt="body" />
            </picture>
          </S.FeaturedBlockContainer>
          <S.Wrapper>
            <Disclaimer />
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <>
          {!isWeeklyCohort && (
            <CheckoutSeparateMethods
              key={selectedSubscriptionId}
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
              isCancelOfferPage
            />
          )}
          {isWeeklyCohort && (
            <CheckoutWithoutIntroOffer
              key={selectedSubscriptionId}
              isCheckoutShown={isCheckoutShown}
              setIsCheckoutShown={setIsCheckoutShown}
              isCancelOfferPage
            />
          )}
        </>
      </S.CheckoutContainer>

      {!isCheckoutShown && (
        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        />
      )}
    </>
  )
}
