import preparePlanImage1 from 'assets/images/prepare-plan-exercises-1.png'
import preparePlanImage2 from 'assets/images/prepare-plan-exercises-2.png'
import preparePlanImage3 from 'assets/images/prepare-plan-exercises-3.png'
import preparePlanImage4 from 'assets/images/prepare-plan-exercises-4.png'
import preparePlanImage5 from 'assets/images/prepare-plan-exercises-5.png'
import preparePlanImage6 from 'assets/images/prepare-plan-exercises-6.png'
import preparePlanImage7 from 'assets/images/prepare-plan-exercises-7.png'

export const QUESTION = 'Prepare Plan'
export const REDIRECT_DELAY = 3000

export const SLIDES_INFO = [
  {
    id: '1',
    imagePath: preparePlanImage1,
    textPath: 'onboarding.preparePlanAdult.slideTextFirst',
  },
  {
    id: '2',
    imagePath: preparePlanImage2,
    textPath: 'onboarding.preparePlanAdult.slideTextSecond',
  },
  {
    id: '3',
    imagePath: preparePlanImage3,
    textPath: 'onboarding.preparePlanAdult.slideTextThird',
  },
  {
    id: '4',
    imagePath: preparePlanImage4,
    textPath: 'onboarding.preparePlanAdult.slideTextFourth',
  },
  {
    id: '5',
    imagePath: preparePlanImage5,
    textPath: 'onboarding.preparePlanAdult.slideTextFifth',
  },
  {
    id: '6',
    imagePath: preparePlanImage6,
    textPath: 'onboarding.preparePlanAdult.slideTextSixth',
  },
  {
    id: '7',
    imagePath: preparePlanImage7,
    textPath: 'onboarding.preparePlanAdult.slideTextSeventh',
  },
]
