import React from 'react'
import { useTranslation } from 'react-i18next'

import { Timer } from '../Timer'
import { StyledSubheaderWithTimerV2 as S } from './SubheaderWithTimerV2.styles'

export const SubheaderWithTimerV2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`purchase3.timer.title`}</S.Title>
      <S.Text>{t`purchase3.timer.text`}</S.Text>
      <S.Time>
        <Timer />
      </S.Time>
    </S.Wrapper>
  )
}
