import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

export const WeightFluctuationsStyles = {
  Container: styled(Container)<{
    $isJapanese: boolean
    $bgImageUrl?: string
    $isAdult: boolean
  }>`
    min-height: 560px;
    background-image: ${({ $bgImageUrl }) =>
      $bgImageUrl && `url(${$bgImageUrl})`};
    background-size: 375px 500px;
    background-repeat: no-repeat;
    background-position: bottom;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
}
