import styled from 'styled-components'

import { Container } from 'components/Container'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledMenopause = {
  Subtitle: styled.h3`
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
  `,
  OptionTitle: styled.h3`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Container: styled(Container)`
    ${StyledNewAdultContainer}
  `,
}
