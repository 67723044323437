import dance from 'assets/images/sprite/dance.svg'
import heart from 'assets/images/sprite/heart.svg'
import house from 'assets/images/sprite/house.svg'
import scale from 'assets/images/sprite/scale.svg'
import watch from 'assets/images/sprite/watch.svg'

export const BENEFITS = [
  {
    id: 1,
    text: 'purchaseWithoutIntro.whatYouGet.list.mostPopularWeight_loseWeight',
    icon: scale,
  },
  {
    id: 2,
    text: 'purchaseWithoutIntro.whatYouGet.list.effectiveWorkouts_loseWeight',
    icon: watch,
  },
  {
    id: 3,
    text: 'purchaseWithoutIntro.whatYouGet.list.diverseWorkoutsNew',
    icon: heart,
  },
  {
    id: 4,
    text: 'purchaseWithoutIntro.whatYouGet.list.practiceAnywhere',
    icon: house,
  },
  {
    id: 5,
    text: 'purchaseWithoutIntro.whatYouGet.list.mostPopularDance',
    icon: dance,
  },
]
