import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { FreeOfferCard } from 'modules/purchase/components/FreeOfferCard'
import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'

import freeOfferBg from 'assets/images/free-offer-bg.png'
import bgImage from 'assets/images/free-offer-block-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseFreeOffer = {
  RootWrapper: styled.div`
    background-color: #fafaff;
  `,
  BannerImage: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background: url(${freeOfferBg}) no-repeat center;
    background-size: cover;
    margin: 0 auto;
    width: 100%;
    min-height: 281px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding-top: 44px;
    padding-left: 20px;
    color: ${Color.WHITE};

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    },
  `,
  TitleText: styled.h2`
    color: ${Color.WHITE};
    text-shadow: 0 3px 9px rgba(22, 49, 101, 0.3);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    max-width: 258px;
    margin-bottom: 16px;
  `,
  GoalWeight: styled.p`
    color: #ffe065;
    text-align: center;
    text-shadow: 0 3px 9px rgba(22, 49, 101, 0.3);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 4px 10px;
    border-radius: 12px;
    border: 1px solid #ffe065;
    margin-bottom: 8px;
  `,
  SubtitleText: styled.p`
    color: ${Color.WHITE};
    text-shadow: 0 3px 9px rgba(22, 49, 101, 0.3);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  `,
  DiscountActivated: styled.div<{ $marginBottom: number }>`
    display: flex;
    padding: 10px 16px;
    margin: ${({ $marginBottom }) => `0 auto ${$marginBottom}px`};
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      278deg,
      #f8002f 29.76%,
      #ff782a 59.72%,
      #ffb52a 87.4%
    );
    height: 47px;
    color: ${Color.WHITE};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    svg {
      margin-left: 8px;
    }
  `,
  ReceivedPlan: styled(ReceivedPlan)`
    padding: 0;
    margin-bottom: 40px;
  `,
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  OfferCard: styled(FreeOfferCard)`
    gap: 6px;
    border: 1px solid #ffdc9c;
    background: #fff4df;
    margin: 24px 0;

    p {
      font-size: 12px;
    }
  `,
  Container: styled.div`
    margin-bottom: 40px;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    margin-bottom: 40px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimer)`
    margin-bottom: 40px;
  `,
  FAQList: styled(FAQList)`
    p {
      color: ${Color.LIGHT_GREY};
      line-height: 18px;
      padding-right: 16px;
      margin-bottom: 40px;
    }
  `,
  PurchaseButton: styled(Button)`
    margin: 0 auto 24px !important;
  `,
  TimerSubheaderWithTimer: styled(SubheaderWithTimer)`
    margin: 18px auto 40px;
    padding: 20px 16px;

    & span:first-child {
      display: flex;
    }

    button {
      width: 94px;
      height: 40px;
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
      padding: 5px 10px;
    }
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    background-color: ${Color.WHITE};
  `,
  IntroOfferDisclaimer: styled(IntroOfferDisclaimer)<{ $marginBottom: number }>`
    margin-bottom: ${({ $marginBottom }) => `${$marginBottom}px`};
  `,
  SubscriptionsBlockIntroOffer: styled(SubscriptionsBlockIntroOffer)`
    margin-bottom: 24px;
    background: url(${bgImage}) center center no-repeat;
    background-size: 100% 100%;
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    filter: drop-shadow(0px 4px 20px rgba(59, 59, 62, 0.16));
  `,
}
