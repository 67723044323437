import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
  $isInfluencerFlow?: boolean
}

type TTimerTextProps = TTimerContainerProps
type TButtonContainerProps = TTimerContainerProps

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div<TTimerContainerProps>`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    justify-content: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'space-between' : 'center'};
    align-items: center;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    max-height: 60px;
    margin: 0 auto 20px;
    padding: 20px 0;
    background-color: ${({ $isInfluencerFlow }) =>
      $isInfluencerFlow ? '#FED7AA' : '#f7d046'};
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    z-index: 10;

    color: ${({ $isInfluencerFlow }) => $isInfluencerFlow && '#7C2D12'};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  TimerText: styled.span<TTimerTextProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'none' : 'inline-block'};
    max-width: ${({ $isButtonVisible }) => $isButtonVisible && '97px'};
  `,
  SubheaderTimer: styled(Timer)`
    min-width: 88px;
    padding: 0 10px 0 15px;

    & div {
      font-size: 34px;
      font-weight: 700;
      line-height: 38px;
    }
  `,
  ButtonContainer: styled.div<TButtonContainerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button<{ $isBellyFlow: boolean }>`
    height: 35px;
    margin: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: ${({ $isBellyFlow }) => ($isBellyFlow ? `30px` : '12px')};
    background-color: ${Color.PRIMARY};
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      padding: 5px 32px;
    }
  `,
}
