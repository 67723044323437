export const REVIEWS_LIST = [
  {
    id: '1',
    author: 'purchase3.reviews.firstAuthor',
    review: 'purchase3.reviews.firstText',
  },
  {
    id: '2',
    author: 'purchase3.reviews.secondAuthor',
    review: 'purchase3.reviews.secondText',
  },
  {
    id: '3',
    author: 'purchase3.reviews.thirdAuthor',
    review: 'purchase3.reviews.thirdText',
  },
  {
    id: '4',
    author: 'purchase3.reviews.fourthAuthor',
    review: 'purchase3.reviews.fourthText',
  },
]
