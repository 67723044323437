import styled from 'styled-components'

import { Color } from 'root-constants'

type TTypeProps = {
  $isCrossedOut: boolean
}

export const StyledUpsellDescription = {
  Wrapper: styled.div`
    padding: 16px 16px 24px;
    box-shadow: 0 8px 20px #e1e3ec;
    border-radius: 16px;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  `,
  TextContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 48px;
  `,
  Text: styled.p`
    padding-left: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
  PriceBadge: styled.p<TTypeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    background: #e7f8ff;
    border-radius: 14px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.PRIMARY};
    text-align: center;

    strong {
      text-decoration: ${({ $isCrossedOut }) =>
        $isCrossedOut ? 'line-through' : 'none'};
      text-decoration-color: ${Color.DANGER};
    }
  `,
  TrialPrice: styled.span`
    margin-left: 6px;
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    color: ${Color.DANGER};
  `,
  Disclaimer: styled.p`
    padding-top: 16px;
    margin: 0 -4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  `,
}
