import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { Color } from 'root-constants'

import { UpgradeDisclaimer } from '../UpgradeDisclaimer'

export const StyledSubscriptionUpgradeBlock = {
  Wrapper: styled.div`
    padding: 40px 0;
    border-radius: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 0;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  AchievedGoals: styled.div`
    margin-top: 24px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #81848c;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
    font-size: 17px;
    font-weight: 700;
    line-height: 54px;
    border-radius: 30px;
  `,
  UpgradeDisclaimer: styled(UpgradeDisclaimer)`
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  `,
  Notification: styled.div`
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid #cfe9d5;
    background: #def8e4;
  `,
  NotificationTitle: styled.div`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  DurationContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  `,
  Duration: styled.div`
    font-size: 15px;
    flex: 1 0 0;
    font-weight: 400;
    line-height: 20px;
  `,
  Badge: styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #50ab64;
  `,
  BadgeText: styled.div`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
  `,
  Subtitle: styled.div`
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 16px 0;
  `,
}
