import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import starSvg from 'assets/images/sprite/star.svg'

import { CUSTOMER_REVIEWS } from 'root-constants'

import { StyledReviewV2 as S } from './ReviewV2.styles'

export const ReviewV2: React.FC = () => {
  const { t } = useTranslation()
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          {customerReviewsTitle || (
            <Trans i18nKey="purchaseV2.reviews" components={[<strong />]} />
          )}
        </S.Title>
      </S.Header>

      {(customerReviews.length ? customerReviews : CUSTOMER_REVIEWS).map(
        ({ author, review }) => (
          <S.Comment key={author}>
            <S.Author>{t(author)}</S.Author>
            <S.Rating>
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
            </S.Rating>
            <S.Text>{t(review)}</S.Text>
          </S.Comment>
        ),
      )}
    </S.Wrapper>
  )
}
