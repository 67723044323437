import React, { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import { GET_CART_LIST } from 'root-redux/actions/common'
import { selectUserCart } from 'root-redux/selects/user'

import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import tickIcon from 'assets/images/flow-belly/icons/tick.svg'

import { CartProduct } from '../CartProduct.ts'
import { StyledCartBlock as S } from './CartBlock.styles'

type TCartBlockType = FC<PropsWithChildren>

export const CartBlock: TCartBlockType = () => {
  const { t } = useTranslation()

  const userCart = useSelector(selectUserCart)

  const { fetchingActionsList } = usePurchaseStore()

  const arePricesReady = (() => !fetchingActionsList?.includes(GET_CART_LIST))()

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Container>
      <S.Badge>
        <img src={tickIcon} alt="tick" />
        {t`purchaseWithCart.badge`}
      </S.Badge>
      {userCart.map((product, index) => {
        const isEven = (index + 1) % 2 === 0

        return (
          <CartProduct
            key={product.planId}
            price={product.mainPrices.fullPrice || 0}
            planId={product.planId}
            product={product.product}
            hasBorder={isEven}
          />
        )
      })}
    </S.Container>
  )
}
