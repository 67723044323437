import styled, { css } from 'styled-components'

import timerBg from 'assets/images/timer-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
}

type TTimerTextProps = TTimerContainerProps
type TButtonContainerProps = TTimerContainerProps

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div<TTimerContainerProps>`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    justify-content: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'space-between' : 'center'};
    align-items: center;
    max-width: 343px;
    max-height: 58px;
    margin: 0 auto 20px;
    padding: 14px 0 26px;
    background: ${({ $isButtonVisible }) =>
      $isButtonVisible
        ? 'linear-gradient(90deg, #FB978A 0%, #FF7085 100%)'
        : `url(${timerBg}) no-repeat`};
    background-size: cover;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    color: ${Color.WHITE};
    z-index: 10;

    ${({ $isButtonVisible }) =>
      $isButtonVisible &&
      css`
        max-width: ${MediaBreakpoint.MAX_PHONE}px;
        max-height: 64px;
        padding: 8px 16px;
        text-align: left;

        @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
          max-width: 375px;
        }
      `}
  `,
  TimerText: styled.span<TTimerTextProps>`
    max-width: ${({ $isButtonVisible }) => $isButtonVisible && '97px'};
  `,
  SubheaderTimer: styled(Timer)`
    min-width: 88px;
    padding: 0 10px 0 15px;

    & div {
      color: #eeee84;
      text-align: center;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
  `,
  ButtonContainer: styled.div<TButtonContainerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    max-width: 112px;
    margin: 5px;
    padding: 10px 16px;
    border: none;
    border-radius: 30px;
    background-color: ${Color.PRIMARY};
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  `,
}
