import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { LoseWeightGraphBelly } from 'components/LoseWeightGraphBelly'
import { PageTitle } from 'components/PageTitle'

import { selectUserName } from 'root-redux/selects/common'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import usersRecommended from 'assets/images/recommended-by.png'

import { goTo } from 'browser-history'
import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledDancingVsExercises as S } from './PlanPageIsReady.styles'

export const PlanPageIsReady: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const name = useSelector(selectUserName)
  const {
    isLegsFlow,
    isSomaticFlow,
    isMenopauseFlow,
    isNewAdultFlow,
  } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const title = useMemo(() => {
    if (isLegsFlow) {
      return 'onboarding.planPageIsReady.titleLegs'
    }

    if (isSomaticFlow) {
      return 'onboarding.planPageIsReady.titleSomatic'
    }

    if (isMenopauseFlow || isNewAdultFlow) {
      return 'onboarding.planPageIsReady.titleMenopause'
    }

    return 'onboarding.planPageIsReady.title'
  }, [isSomaticFlow, isMenopauseFlow, isLegsFlow, isNewAdultFlow])

  useEffect(() => {
    eventLogger.logResultPageShown()
  }, [])

  const handleNextClick = useCallback(() => {
    eventLogger.logResultPageCompleted()

    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge $isNewAdultFlow={isNewAdultFlow}>
          <PageTitle marginBottom={24}>
            <Trans
              i18nKey={title}
              values={{
                name,
              }}
            />
          </PageTitle>
          <S.AnimationContainer
            $isJapanese={isJapaneseFlow}
            $isNewAdultFlow={isNewAdultFlow}
          >
            <LoseWeightGraphBelly />
            <S.UserRecommended>
              <S.Img src={usersRecommended} alt="Users recommended" />
              <S.Text>{t('onboarding.planPageIsReady.recommendedBy')}</S.Text>
            </S.UserRecommended>
          </S.AnimationContainer>
        </S.Container>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        type="button"
        onClick={handleNextClick}
        $isNewAdultFlow={isNewAdultFlow}
      >
        {isNewAdultFlow
          ? t`onboarding.planPageIsReady.beginTransformation`
          : t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
