import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpgradeLife = {
  Subtitle: styled.h3`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 14px;
    margin-top: 16px;
    color: ${Color.DARK};
    text-align: center;
  `,
  AnimationContainer: styled.div``,
}
