import styled, { css } from 'styled-components'

import resultBackground from 'assets/images/photo-result-bg.png'
import resultSmile from 'assets/images/result-smile.png'

import { Color, MediaBreakpoint } from 'root-constants'

const commonLabelStyles = css`
  min-width: 130px;
  min-height: 34px;
  position: relative;
  margin-top: -18px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPhotoResult = {
  ResultContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 -20px 24px;
    padding: 30px 0;
    border-radius: 24px;
    background: url(${resultBackground}) center center no-repeat;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      margin: 0 0 24px;
    }
  `,
  ResultTitle: styled.h2`
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: ${Color.WHITE};
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
      text-transform: uppercase;
    }
  `,
  Cards: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 12px 30px;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(50% - 5px);
    z-index: 2;
  `,
  ImageContainer: styled.div`
    border: 4px solid rgba(3, 170, 245, 0.16);
    border-radius: 22px;
    overflow: hidden;
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
  LabelBefore: styled.p`
    ${commonLabelStyles};
    background-color: ${Color.WHITE};
  `,
  LabelAfter: styled.p`
    ${commonLabelStyles};
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};

    &::after {
      content: '';
      position: absolute;
      left: 108px;
      bottom: 10px;
      z-index: 1;
      width: 44px;
      height: 44px;
      background-image: url(${resultSmile});
      background-size: cover;
    }
  `,
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: #fff;
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
    }
  `,
  Footnote: styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding-top: 16px;
    color: ${Color.WHITE};
  `,
}
