import styled from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

export const StyledAgeBelly = {
  Form: styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  InputContainer: styled.div`
    margin-top: 30px;
  `,
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
