import React, { InputHTMLAttributes, useCallback, useState } from 'react'

import closeEyeIconSvg from 'assets/images/sprite/close-eye.svg'
import openEyeIconSvg from 'assets/images/sprite/open-eye.svg'

import { StyledPasswordInput as S } from './PasswordInput.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  labelName: string
  isValid?: boolean
  validationText?: string
  marginBottom?: number
  inputHeight?: number
  isAgeFlow?: boolean
}

export const PasswordInput: React.FC<TProps> = ({
  value,
  labelName,
  isValid = true,
  validationText = '',
  marginBottom = 0,
  inputHeight = 60,
  isAgeFlow = false,
  ...props
}) => {
  const [isEyeOpen, setIsEyeOpen] = useState(false)

  const toggleEye = useCallback(() => {
    setIsEyeOpen((prevState) => !prevState)
  }, [])

  return (
    <S.Wrapper
      data-valid={isValid}
      data-validation-text={validationText}
      $marginBottom={marginBottom}
    >
      <S.Input
        type={isEyeOpen ? 'text' : 'password'}
        $isAgeFlow={isAgeFlow}
        $inputHeight={inputHeight}
        value={value}
        required
        {...props}
      />
      <S.Label $value={value}>{labelName}</S.Label>

      <S.Eye type="button" onClick={toggleEye}>
        <S.SvgIcon svg={isEyeOpen ? openEyeIconSvg : closeEyeIconSvg} />
      </S.Eye>
    </S.Wrapper>
  )
}
