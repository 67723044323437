import React from 'react'

import { StyledHelpsBenefits as S } from './HelpsBenefits.styles'

type TBenefitsProps = {
  iconSrc: string
  title: string
  description: string
}

export const HelpsBenefits: React.FC<TBenefitsProps> = ({
  title,
  description,
  iconSrc,
}) => (
  <S.Wrapper>
    <S.ListBody>
      <S.ListItem $icon={iconSrc}>
        <S.ListItemTitle>{title}</S.ListItemTitle>
        <S.ListItemText>{description}</S.ListItemText>
      </S.ListItem>
    </S.ListBody>
  </S.Wrapper>
)
