import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReviewsFreeOffer = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto 40px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    margin-bottom: 0;
  `,
  ReviewFrame: styled.div`
    border-radius: 16px;
    border: 1px solid #e2e4e6;
    background: ${Color.WHITE};
    padding: 16px;
  `,
  Author: styled.img`
    width: 40px;
    height: 40px;
    margin-right: 12px;
  `,
  ReviewHeader: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Name: styled.p`
    color: ${Color.DARK};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;

    svg {
      margin-left: 6px;
    }
  `,
  Time: styled.p`
    color: ${Color.GRAY_80};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    svg {
      margin-left: 4px;
    }
  `,
  Image: styled.img`
    padding: 16px 0;
    min-height: 210px;
  `,
  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${Color.GRAY_80};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  Text: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;

    p:last-of-type {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 8px;
    }
  `,
  Likes: styled.div`
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  `,
  Comments: styled.div``,
}
