import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

export const StyledBalance = {
  GraphTitle: styled.h3`
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 6px;
  `,
  Description: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    & > p {
      margin-bottom: 16px;
    }
  `,
  Intensity: styled.h3`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center;
  `,
  ContentWrapper: styled.div`
    position: relative;
    margin: 0 auto 6px;
    min-height: 199px;
    width: 100%;
    max-width: 375px;
  `,
  Container: styled(Container)`
    margin-top: 16px;
    ${StyledNewAdultContainer};
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: 0.5px solid #a7cdea;
    background: #d5ebff;
  `,
}
