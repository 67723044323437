import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { selectHighestDiscountPercentage } from 'modules/purchase/redux/selects/common'

import giftPng from 'assets/images/cancel-offer-gift.png'
import giftWebp from 'assets/images/cancel-offer-gift.webp'

import { Cohort, MAIN_GOALS } from 'root-constants'

import { StyledPersonalPlan as S } from './PersonalPlan.styles'

type TProps = {
  externalDiscountPercentage?: number | null
}

export const PersonalPlan: React.FC<TProps> = ({
  externalDiscountPercentage,
}) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const highestDiscountPercentage = useSelector(selectHighestDiscountPercentage)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const { title } = useDynamicPaywallConfig()

  const cohortToUse = parentCohort || cohort

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  return (
    <S.Wrapper>
      <div>
        <S.Title>
          {title || (
            <Trans
              i18nKey={
                isClearCohort
                  ? 'purchaseCancelOffer.personalPlan.titleClearEdition'
                  : 'purchaseCancelOffer.personalPlan.title'
              }
              values={{
                context:
                  isStayFit && cohortToUse !== Cohort.DB_BELLY
                    ? MAIN_GOALS.STAY_FIT
                    : MAIN_GOALS.LOSE_WEIGHT,
              }}
              components={[<br />]}
            />
          )}
        </S.Title>
        <S.DiscountText>
          {cohortToUse ? (
            <Trans
              i18nKey="purchaseCancelOffer.personalPlan.upToDiscountText"
              values={{
                discountAmount:
                  externalDiscountPercentage || highestDiscountPercentage,
              }}
            />
          ) : null}
        </S.DiscountText>
      </div>
      <S.Picture>
        <source srcSet={giftWebp} type="image/webp" />
        <img src={giftPng} alt="gift" />
      </S.Picture>
    </S.Wrapper>
  )
}
