import styled from 'styled-components'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledReceivedPlan = {
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
  `,
  GrabPlanTitle: styled.h2`
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;

    strong {
      color: #ff7171;
    }
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
}
