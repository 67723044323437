import styled from 'styled-components'

export const StyledFooter = {
  Footer: styled.footer`
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  List: styled.ul`
    display: flex;
    justify-content: center;
    padding: 3px 0;
  `,
  ListItem: styled.li`
    a {
      color: rgba(19, 29, 48, 0.5);
      text-decoration: underline;
      text-decoration-color: rgba(19, 29, 48, 0.5);
    }
  `,
  ListItemSeparator: styled.li`
    padding: 0 5px;
  `,
}
