import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledToggleV2 = {
  Wrapper: styled.label`
    display: flex;
    cursor: pointer;
    width: 50px;
    height: 24px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Toggle: styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(calc(100% + 8px));
    }

    &:checked ~ div {
      background-color: ${Color.PRIMARY};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    border-radius: 16px;
    background: ${Color.GRAY_40};
  `,
  Button: styled.span`
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
  `,
}
