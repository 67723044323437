import styled from 'styled-components'

import { Button } from 'components/Button'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'

import bg from 'assets/images/purchase-live-chat-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseLiveChat = {
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding-top: 24px;
    margin-bottom: 16px;
  `,
  Users: styled.div`
    color: #ffcb78;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 8px;
  `,
  UsersDescription: styled.p`
    max-width: 343px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  CarouselContainer: styled.div`
    margin: 24px auto;
    height: 400px;
    max-width: 345px;
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-container {
      height: 100%;
    }

    & .swiper-pagination {
      bottom: -6px;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: #d1d8e1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Review: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.img`
    max-height: 216px;
  `,
  ReviewText: styled.div<{ $isBigReview: boolean }>`
    width: 100%;
    height: 210px;
    position: absolute;
    top: 170px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 24px;
    background: ${Color.WHITE};
    box-shadow: 0px 5px 20px 0px rgba(166, 179, 194, 0.25);
  `,
  BeforeAfterBlock: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffa800;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
  `,
  Reviewer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Name: styled.span`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
    padding: 8px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Comment: styled.div`
    max-width: 300px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    transition: all 0.3s ease-in-out;
  `,
  Background: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: ${`url(${bg})`};
    background-repeat: no-repeat;
    background-size: contain;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;

    @media screen and (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 375px;
    }
  `,
  FindSuccess: styled.p`
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    & strong {
      color: ${Color.DARK_RED};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  Button: styled(Button)<{ $marginBottom?: number }>`
    max-width: 328px;
  `,
  BenefitsTitle: styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 40px;
  `,
  ReviewBlock: styled.div`
    display: flex;
    width: 100%;
    padding: 32px 16px 0;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #eef0fb;
  `,
  ReviewTitle: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  AppsRating: styled.div`
    display: flex;
    width: 320px;
    padding: 0px 4px;
    align-items: flex-start;
    gap: 2px;
    margin-top: 24px;
  `,
  Summary: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
  `,
  App: styled.div`
    color: ${Color.GRAY_80};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  ReviewContainer: styled.div`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-container {
      min-height: 200px;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: #d1d8e1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Card: styled.div`
    width: 345px;
    height: 160px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 24px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(166, 179, 194, 0.3);
    margin: 0 auto;
  `,
  CardRating: styled.img`
    display: inline-block;
    width: 80px;
    height: auto;
  `,
  CardReview: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    flex-grow: 1;
  `,
  CardReviewer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  FeaturedBlock: styled(FeaturedBlock)`
    padding-bottom: 40px;
  `,
}
