import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import avatar from 'assets/images/influencer-sarah.png'

import { PagesSource } from 'root-constants'

import { StyledWelcomeInfluencer as S } from './WelcomeInfluencer.styles'
import { QUESTION } from './constants'

export const WelcomeInfluencer: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = () => {
    dispatch(sendFacebookParamsAction())
    window.fbq &&
      window.fbq(
        'track',
        'CustomizeProduct',
        {},
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: QUESTION,
    })
    handleContinue(QUESTION)
  }

  return (
    <>
      <S.Container isLarge>
        <S.Title>
          <Trans i18nKey="onboarding.welcomeInfluencer.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.welcomeInfluencer.subtitle" />
        </S.Subtitle>

        <S.Description>
          <img src={avatar} alt="influencer-avatar" height={152} width={152} />
          <div>
            <S.InfluencerTitle>
              {t('onboarding.welcomeInfluencer.textTitle')}
            </S.InfluencerTitle>
            <S.InfluencerText>
              {t('onboarding.welcomeInfluencer.text')}
            </S.InfluencerText>
          </div>
        </S.Description>

        <S.StarJourney>
          <Trans i18nKey="onboarding.welcomeInfluencer.startJourney" />
        </S.StarJourney>

        <Button
          theme={buttonTheme.DANCEBIT}
          type="button"
          onClick={handleClick}
        >
          <Trans i18nKey="onboarding.welcomeInfluencer.takeQuiz" />
        </Button>

        <S.Terms>
          <p>{t`footer.disclaimer`}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Container>
    </>
  )
}
