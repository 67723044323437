import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledMotivationStats = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Content: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 124px;
    background-color: ${Color.LIGHT};
    border-radius: 60px;
  `,
  Title: styled.h3`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 28px;
    text-align: center;
    color: ${Color.DARK};
  `,
  Percent: styled.div`
    width: 113px;
    height: 100px;
    border-radius: 52px;
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY};
    word-spacing: -5px;
    text-align: center;
    font-weight: bold;
    padding: 14px 0;
    margin: 12px;
  `,
  PercentValue: styled.span`
    font-size: 50px;
    letter-spacing: -2px;
  `,
  PercentSign: styled.span`
    font-size: 30px;
    padding-left: 2px;
  `,
  Text: styled.div<{ $isGerman?: boolean }>`
    flex: 1;
    padding-right: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: left;

    strong {
      color: ${Color.PRIMARY};
      ${({ $isGerman }) =>
        !$isGerman &&
        css`
          text-transform: lowercase;
        `};
    }
  `,
}
