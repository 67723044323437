import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledHowItWorks = {
  Wrapper: styled(Container)`
    background: linear-gradient(
      95deg,
      rgba(166, 241, 223, 0.3) 5.21%,
      rgba(85, 194, 209, 0.3) 97.05%
    );
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding: 24px 0 48px;
    margin-bottom: 50px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Description: styled.p`
    margin-bottom: 24px;
    text-align: center;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
  `,
  ListBody: styled.ul`
    margin-bottom: 24px;
  `,
  ListItem: styled.li`
    border-radius: 16px;
    padding: 16px;
    margin-top: 16px;
    background: rgba(255, 255, 255, 0.85);
    display: flex;

    svg {
      min-width: 40px;
      margin-right: 16px;
    }
  `,
  ListItemTitle: styled.h3`
    margin-bottom: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  ListItemText: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    a {
      font-weight: 600;
      text-decoration: underline;
      color: ${Color.PRIMARY};
    }
  `,
  ChallengeLabel: styled.div`
    border-radius: 20px;
    background: radial-gradient(
      306.92% 134.72% at 72.1% -23.61%,
      #22d7ff 0%,
      ${Color.PRIMARY} 100%
    );
    padding: 4px 12px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: fit-content;
    margin: 0 auto 16px;
  `,
  Disclaimer: styled.p`
    color: ${Color.LIGHT_GREY};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;

    a {
      color: ${Color.LIGHT_GREY};
      text-decoration: underline;
    }
  `,
}
