import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PageTitle } from 'components/PageTitle'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ANSWER_VALUES } from '../SeeYourself/constants'
import { StyledReachingGoalResult as S } from './ReachingGoalResult.styles'
import { OPTIONS_VALUES, QUESTION } from './constants'

export const ReachingGoalResult: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const answerValue = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  const { imageUrl, title, description } = OPTIONS_VALUES[
    answerValue?.seeYourself as ANSWER_VALUES
  ]

  return (
    <S.Container isLarge $imageUrl={imageUrl}>
      <S.Root>
        <S.TextBlock>
          <PageTitle marginBottom={8}>
            <Trans i18nKey={title} />
          </PageTitle>
          <S.Subtitle>
            <Trans i18nKey={description} />
          </S.Subtitle>
        </S.TextBlock>
      </S.Root>
      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </S.Container>
  )
}
