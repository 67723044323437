import React, { lazy, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { Download } from 'pages/download'
import { ProxyToStart } from 'pages/proxyToStart'

import { ProtectedRoute } from 'components/ProtectedRoute'
import SubscribeFlow from 'components/SubscribeFlow'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { growthBook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useUserStatus } from 'hooks/useUserStatus'

import { getDynamicPageId } from 'helpers/getDynamicPageId'
import { getPathFromPageId } from 'helpers/getPathFromPageId'
import { lazyRetry } from 'helpers/lazyRetry'

import { Login } from 'modules/login'
import { PurchaseDop } from 'modules/purchase/pages/PurchaseDop'
import { PurchaseUpgrade } from 'modules/purchase/pages/PurchaseUpgrade'
import { PurchaseUpgradeMealplan } from 'modules/purchase/pages/PurchaseUpgradeMealplan'
import { Upsell } from 'modules/purchase/pages/Upsell'
import { UpsellWithCancelOffer } from 'modules/purchase/pages/UpsellWithCancelOffer'

import { IStep } from 'models/variant.model'

import {
  COHORTS_WITH_V2_PAGES_MAP,
  ID_TO_PAGE_MAP,
  ID_TO_PAGE_MAP_V_2,
  PageId,
} from 'page-constants'
import {
  COHORT_TO_PAGES_TEST_MAP,
  Cohort,
  DYNAMIC_PAGE_ID_PREFIX,
  PUBLIC_PAGES,
} from 'root-constants'

const PaymentsProvider = lazy(() =>
  lazyRetry(() => import('components/PaymentsProvider')),
)

export const RouteList: React.FC = () => {
  const location = useLocation()
  const availableRoute = useUserStatus()
  const { i18n } = useTranslation()

  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const userCountryCode = useSelector(selectUserCountryCode)
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const { steps, currentSubscriptionPageId } = useGetPageInfo()

  const isRoutePublic = useMemo(() => cohortToUse === Cohort.DANCEBIT_SHORT, [
    cohortToUse,
  ])

  const pageIds = useMemo(() => {
    if (COHORTS_WITH_V2_PAGES_MAP.includes(cohortToUse as Cohort)) {
      return ID_TO_PAGE_MAP_V_2
    }
    return ID_TO_PAGE_MAP
  }, [cohortToUse])

  const { onboardingPages, subscriptionPages } = useMemo(
    () =>
      steps.reduce<{
        onboardingPages: IStep[]
        subscriptionPages: IStep[]
      }>(
        (accum, { isSubscriptions, isPayment }, ind, arr) => {
          if (isSubscriptions || isPayment) {
            accum.subscriptionPages.push(arr[ind])
            return accum
          }

          accum.onboardingPages.push(arr[ind])
          return accum
        },
        {
          onboardingPages: [],
          subscriptionPages: [],
        },
      ),
    [steps],
  )

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        language,
        currentSearch: location.search,
      }),
    [steps, cohort, uuid, language, location],
  )

  const isUpgradeAvailable = useMemo(
    () =>
      (userStatus?.upgrade.isUpgradeAvailable &&
        !userStatus?.upgrade.isUpgradePassed &&
        userStatus?.hasSubscription) ||
      false,
    [userStatus],
  )

  useScrollToTop()

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      url: `${location.pathname}${location.search}`,
      country: userCountryCode,
      cohort,
      language,
    })
  }, [cohort, language, location, userCountryCode])

  const routes = useRoutes([
    {
      index: true,
      element: <Navigate to={firstPagePath} />,
    },
    {
      element: <SubscribeFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const id = pageId.includes(DYNAMIC_PAGE_ID_PREFIX)
          ? getDynamicPageId(pageId)
          : pageId
        const CurrentPage = pageIds[id]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: location.search,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: location.search,
        })

        if (isRoutePublic || PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedRoute key={pageId} isAvailable={!availableRoute}>
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </ProtectedRoute>
          ),
        }
      }),
    },
    {
      path: PageId.PURCHASE_UPGRADE,
      element: (
        <ProtectedRoute isAvailable={isUpgradeAvailable}>
          <PurchaseUpgrade />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PURCHASE_DOP,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.PURCHASE_DOP}>
          <PurchaseDop />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PURCHASE_UPGRADE_PDF,
      element: (
        <ProtectedRoute isAvailable={isUpgradeAvailable}>
          <PurchaseUpgrade />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PURCHASE_UPGRADE_MEAL_PLAN,
      element: (
        <ProtectedRoute isAvailable={isUpgradeAvailable}>
          <PurchaseUpgradeMealplan />
        </ProtectedRoute>
      ),
    },
    ...subscriptionPages.map(({ id }, index, arr) => {
      const PurchasePage = pageIds[id]
      const nextPagePath = getPathFromPageId({
        pageId: arr[index + 1]?.id,
        cohort,
        uuid,
        language,
        currentSearch: location.search,
      })

      const alternativePageId = arr.find(
        (dynamicPage, dynamicPageIndex) =>
          !dynamicPage.isSkippable && dynamicPageIndex > index,
      )?.id

      const alternativePagePath = getPathFromPageId({
        pageId: alternativePageId || arr[index + 1]?.id,
        cohort,
        uuid,
        language,
        currentSearch: location.search,
      })

      return {
        path: id,
        element: (
          <ProtectedRoute
            isAvailable={availableRoute === currentSubscriptionPageId}
          >
            <PaymentsProvider>
              <PurchasePage
                pageId={id}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </PaymentsProvider>
          </ProtectedRoute>
        ),
      }
    }),
    {
      path: PageId.UPSELL,
      element: (
        <ProtectedRoute
          isAvailable={
            availableRoute === PageId.UPSELL ||
            availableRoute === PageId.PURCHASE_DOP
          }
        >
          <PaymentsProvider>
            <Upsell />
          </PaymentsProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.UPSELL_CANCEL_OFFER,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.PURCHASE_DOP}>
          <UpsellWithCancelOffer isCancelOffer />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.LOGIN,
      element: (
        <ProtectedRoute
          isAvailable={
            availableRoute === PageId.UPSELL ||
            availableRoute === PageId.LOGIN ||
            availableRoute === PageId.PURCHASE_DOP
          }
        >
          <Login />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <Download />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PROXY_TO_START,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.PROXY_TO_START}>
          <ProxyToStart />
        </ProtectedRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to={firstPagePath} />,
    },
  ])

  if (cohortToUse && COHORT_TO_PAGES_TEST_MAP[cohortToUse]) {
    return growthBook?.ready ? routes : null
  }

  return routes
}
