import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUpUUIDAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectLanguage,
} from 'root-redux/selects/common'

import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { TEST_ENV_QUERY_PARAM } from 'root-constants'

export const useSetUuid = (): void => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const language = useSelector(selectLanguage)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')
    const utmTags = getQueryParamsFromSearchString(document.location.search)
    const isTestEnvironment = searchParams.has(TEST_ENV_QUERY_PARAM)

    if (cohort && giaApiKeys && language) {
      dispatch(
        setUpUUIDAction({
          cohort,
          giaApiKey: giaApiKeys[language],
          uuid: uuidFromUrl,
          isTestEnvironment,
          language,
          utmTags,
        }),
      )
    }
  }, [dispatch, cohort, giaApiKeys, language])
}
