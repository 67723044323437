// eslint-disable-line max-lines
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'
import { getSubscriptionWithDynamicDiscounts } from 'helpers/getSubscriptionWithDynamicDiscounts'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  GET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_PAYMENT_FORM_IS_LOADING,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import securitySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  SEVEN_DAY_PLAN_COHORTS,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
  TRIAL_COHORT,
} from 'root-constants'

import { StyledCheckoutWithoutIntroOffer as S } from './CheckoutWithoutIntroOffer.styles'

type TProps = {
  setIsCheckoutShown: (value: boolean) => void
  isCancelOfferPage?: boolean
  isCheckoutShown: boolean
}

export const CheckoutWithoutIntroOffer: React.FC<TProps> = ({
  setIsCheckoutShown,
  isCheckoutShown,
  isCancelOfferPage = false,
}) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  const creditCardPaymentRef = useRef<HTMLDivElement>(null)

  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const {
    uuid,
    cohort,
    currency,
    fetchingActionsList,
    fullPrice,
    fullPriceTax,
    periodQuantity,
    oldPriceBeforeCustomPercentDiscount,
    oldTrialPriceBeforeCustomPercentDiscount,
    trialPeriodDays,
    selectedSubscription,
    periodName,
    screenName,
    screenId,
    discountAmount,
    hasCancelOffer,
    threeDSecureIframeUrl,
    stripeAccountName,
    stripeAccountId,
    countryCode,
    subscriptions,
    taxAmount,
    parentCohort,
    dynamicDiscount,
    discountPercentage,
  } = usePurchaseStore()

  const hasExcludedVat = useVatInfo(true)
  const hasVatInfo = useVatInfo()
  const hasPayPalButton = useHasPayPalButton()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const cohortToUse = parentCohort || cohort

  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const isSaleFlow = useMemo(
    () => cohort === Cohort.DB_BELLY_SALE || cohort === Cohort.DB_ADULT_SALE,
    [cohort],
  )

  const isTrialCohort = useMemo(
    () => TRIAL_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isSevenDayPlan = useMemo(
    () =>
      SEVEN_DAY_PLAN_COHORTS.includes(cohortToUse as Cohort) &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [cohortToUse, periodQuantity],
  )

  const hasTotal = useMemo(() => {
    if (periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION)) {
      return hasExcludedVat
    }

    return true
  }, [hasExcludedVat, periodQuantity])

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      }),
    [periodName, periodQuantity, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING) &&
      !fetchingActionsList.includes(GET_PRIMER_CLIENT_SESSION_TOKEN),
    [fetchingActionsList],
  )

  const isCardVisible = useMemo(() => isCheckoutShown && isScrolled, [
    isCheckoutShown,
    isScrolled,
  ])

  const oldTotalPrice = useMemo(() => {
    if (trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
      return oldTrialPriceBeforeCustomPercentDiscount?.fullPrice.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [trialPeriodDays, fullPrice, oldTrialPriceBeforeCustomPercentDiscount])

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [countryCode, hasVatInfo, taxAmount],
  )

  const subscription = useMemo(() => {
    if (!selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount: 0,
        discountAmount,
      }
    }

    if (isSaleFlow) {
      return getSubscriptionWithDynamicDiscounts(
        selectedSubscription,
        dynamicDiscount,
        isCancelOfferPage,
        hasExcludedVat,
        getCurrentPrice,
      )
    }

    return {
      percentage: isTrialCohort
        ? oldTrialPriceBeforeCustomPercentDiscount.percentOfDiscount
        : oldPriceBeforeCustomPercentDiscount.percentOfDiscount,
      totalAmount: getCurrentPrice(
        isTrialCohort
          ? oldTotalPrice
          : oldPriceBeforeCustomPercentDiscount.fullPrice,
      ),
      discountAmount: getCurrentPrice(
        isTrialCohort
          ? discountAmount
          : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
      ),
    }
  }, [
    selectedSubscription,
    isSaleFlow,
    isTrialCohort,
    oldTrialPriceBeforeCustomPercentDiscount.percentOfDiscount,
    oldPriceBeforeCustomPercentDiscount.percentOfDiscount,
    oldPriceBeforeCustomPercentDiscount.fullPrice,
    oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
    getCurrentPrice,
    oldTotalPrice,
    discountAmount,
    discountPercentage,
    dynamicDiscount,
    isCancelOfferPage,
    hasExcludedVat,
  ])

  const totalDiscount = useMemo(
    () =>
      isSaleFlow
        ? subscription.discountAmount
        : getCurrentPrice(
            isTrialCohort
              ? discountAmount
              : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
          ),
    [
      getCurrentPrice,
      isTrialCohort,
      oldPriceBeforeCustomPercentDiscount,
      discountAmount,
      isSaleFlow,
      subscription,
    ],
  )

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        accountName: stripeAccountName,
        accountId: stripeAccountId,
        screenName,
        screenId,
        productId,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        isPersonalDataAllowed,
      })
    }
  }, [
    isPersonalDataAllowed,
    subscriptions,
    uuid,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
    productId,
    screenId,
    screenName,
    isCheckoutShown,
  ])

  useEffect(() => {
    const { current: elem } = creditCardPaymentRef
    setIsScrolled(false)

    if (elem && isCheckoutShown && isCheckoutReady) {
      elem.scrollIntoView(true)
      setIsScrolled(true)
    }
  }, [isCheckoutReady, isCheckoutShown])

  const TrialDiscount = useCallback(
    () =>
      isSaleFlow && dynamicDiscount?.checkout ? (
        <Trans
          i18nKey="checkoutExtended.discount"
          values={{
            percentage: subscription.percentage,
            checkout: dynamicDiscount.checkout,
          }}
        />
      ) : (
        <Trans
          i18nKey="purchaseIntroOffer.checkout.offerDiscount"
          values={{
            percentDiscount: subscription.percentage,
          }}
        />
      ),
    [dynamicDiscount?.checkout, isSaleFlow, subscription.percentage],
  )

  const handleTryAgain = useCallback(() => {
    dispatch(getUserStatusAction(uuid))
  }, [dispatch, uuid])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  return (
    <>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper $isVisible={isCheckoutReady}>
            <S.Title>
              <Trans i18nKey="purchase1.checkout.title" />
            </S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            <div>
              <S.DescriptionContainer>
                <S.PersonalizedPlan>
                  {isTrialCohort ? (
                    <Trans i18nKey="purchaseCancelOffer.checkout.personalizedPlan" />
                  ) : (
                    <Trans
                      i18nKey="purchaseWithoutIntro.checkout.planTitle"
                      values={{ count: periodQuantity }}
                    />
                  )}
                </S.PersonalizedPlan>
                <S.PlanPrice>
                  <Trans
                    i18nKey="purchase1.checkout.fullPrice"
                    values={{
                      fullPrice: subscription.totalAmount,
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </S.PlanPrice>
              </S.DescriptionContainer>

              {!isSevenDayPlan && (
                <S.DescriptionContainer>
                  <S.PersonalizedPlan>
                    {isTrialCohort ? (
                      <TrialDiscount />
                    ) : (
                      <Trans
                        i18nKey="purchaseWithoutIntro.checkout.discountTitle"
                        values={{
                          discount: subscription.percentage,
                        }}
                      />
                    )}
                  </S.PersonalizedPlan>
                  <S.Discount>
                    -
                    <Trans
                      i18nKey="purchase1.checkout.discountAmount"
                      values={{
                        discountAmount: totalDiscount,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.Discount>
                </S.DescriptionContainer>
              )}
              {hasExcludedVat && (
                <S.DescriptionContainer>
                  <S.PersonalizedPlan>
                    {t(`purchase1.valueAddedTax`, { context: countryCode })}
                  </S.PersonalizedPlan>
                  <S.PlanPrice>
                    <Trans
                      i18nKey="purchaseIntroOffer.checkout.price"
                      values={{
                        price:
                          isTrialCohort && !isSevenDayPlan
                            ? selectedSubscription?.trialPrices.fullPriceTax
                            : fullPriceTax,
                        currency: CURRENCY_SYMBOLS[currency],
                      }}
                    />
                  </S.PlanPrice>
                </S.DescriptionContainer>
              )}
            </div>

            <S.Separator />

            {hasTotal && (
              <S.TotalAmountContainer>
                <span>{t`purchaseCancelOffer.checkout.total`}</span>
                <span>
                  <Trans
                    i18nKey="purchase1.checkout.price"
                    values={{
                      price:
                        isTrialCohort && !isSevenDayPlan
                          ? selectedSubscription?.trialPrices.fullPrice
                          : fullPrice,
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </span>
              </S.TotalAmountContainer>
            )}
            {!isSevenDayPlan && (
              <S.DiscountDescription>
                {t('purchaseCancelOffer.checkout.discountDescription', {
                  discount: subscription.discountAmount,
                  percentDiscount: subscription.percentage,
                  currency: CURRENCY_SYMBOLS[currency],
                })}
              </S.DiscountDescription>
            )}
            <S.PaymentContainer>
              <div ref={creditCardPaymentRef}>
                {isPrimerActive ? (
                  <>
                    {isCheckoutShown && (
                      <PrimerPaymentForm
                        key={selectedSubscription?.id}
                        showPayPalButton
                      />
                    )}
                  </>
                ) : (
                  <>
                    <CreditCardFormCancelOffer
                      isCheckoutVisible={isCardVisible}
                    />
                    {hasPayPalButton && isCheckoutShown && (
                      <>
                        <Separator />
                        <PayPalButton />
                      </>
                    )}
                  </>
                )}
                <S.ButtonContainer>
                  <PaymentRequestButton key={selectedSubscription?.id} />
                </S.ButtonContainer>
              </div>

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase1.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>

          {isPrimerActive ? (
            <PaymentPrimerWaitingModal
              isPaymentWaitingShown={isPaymentWaitingShown}
              setIsPaymentWaitingShown={setIsPaymentWaitingShown}
              tryAgain={handleTryAgain}
            />
          ) : (
            <PaymentWaitingModal
              isPaymentWaitingShown={isPaymentWaitingShown}
              setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            />
          )}
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </>
  )
}
