import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  INITIAL_PROGRESS_VALUE,
  PROGRESS_SECTION_STEP,
} from 'components/PostPaymentProgressBar/constants'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectHasUpsellOffer,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PageId } from 'page-constants'
import { Cohort } from 'root-constants'

import {
  PROGRESS_BAR_STEPS_MAP,
  UPSELL_PROGRESSBAR_STEPS,
} from '../../constants/progressSteps'
import { StyledPostPaymentProgressBar as S } from './PostPaymentProgressBar.styles'

export const PostPaymentProgressBar: React.FC = () => {
  const { pathname } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const currentPageId = useMemo(() => getPageIdFromPathName(pathname), [
    pathname,
  ])

  const stepsMap = useMemo(
    () =>
      hasUpsellOffer
        ? UPSELL_PROGRESSBAR_STEPS
        : PROGRESS_BAR_STEPS_MAP[cohortToUse as Cohort],
    [hasUpsellOffer, cohortToUse],
  )

  const currentSectionIndex = useMemo(() => {
    const index = stepsMap.findIndex(
      (block) => !!block.find((step) => step.id === currentPageId),
    )

    return index === -1 ? 0 : index
  }, [currentPageId, stepsMap])

  return (
    <S.Wrapper>
      {stepsMap.map(([{ id, text }], index, arr) => {
        const progressValue =
          currentSectionIndex >= index ? arr.length : INITIAL_PROGRESS_VALUE
        const nextSectionIndex = currentSectionIndex + PROGRESS_SECTION_STEP

        return (
          <S.StepBlock key={id}>
            <S.Step
              $isActive={nextSectionIndex === index}
              $isPassed={currentSectionIndex >= index}
            >
              <S.StepText>
                <Trans i18nKey={text} />
              </S.StepText>
              {currentSectionIndex < index && index + PROGRESS_SECTION_STEP}
            </S.Step>
            <S.StepBlockProgress
              value={progressValue}
              max={arr.length}
              $pointsNumber={arr.length}
            />
          </S.StepBlock>
        )
      })}

      <S.Step $isActive={currentPageId === PageId.DOWNLOAD}>
        <S.StepText>
          <Trans i18nKey="commonComponents.accessPlan" />
        </S.StepText>
        4
      </S.Step>
    </S.Wrapper>
  )
}
