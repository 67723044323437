import styled from 'styled-components'

import subscriptionListBackground from 'assets/images/subscription-list-bg.png'

export const StyledSubscriptionsBlock = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto 50px;
    padding: 30px 16px;
    border-radius: 24px;
    background: url(${subscriptionListBackground}) center center no-repeat;
    background-size: cover;
  `,
  Title: styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin-top: 30px;
  `,
}
