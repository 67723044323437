import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import dayjs from 'dayjs'

import {
  END_WORKING_HOURS,
  START_WORKING_HOURS,
} from 'components/LiveChat/constants'

import {
  selectCurrentVariantParentCohort,
  selectLiveChatWorkingHours,
} from 'root-redux/selects/common'
import {
  selectIsDiscountApplied,
  selectIsDownloadVisited,
  selectPeriodQuantity,
  selectUserPaymentCurrency,
  selectUserStatus,
} from 'root-redux/selects/user'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { Currency } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { selectIsSubscriptionUpgraded } from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import {
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  LIVECHAT_ENABLED_COHORTS,
  LIVECHAT_UPGRADE_COHORTS,
  Locale,
  MK_EMAIL_COHORTS,
  SEVEN_DAY_TRIAL_DURATION,
} from 'root-constants'

const DISABLED_CHILD_COHORTS = ['db_adult80', 'db_belly80']

const SUPPORTED_CURRENCIES: string[] = [
  Currency.USD,
  Currency.EUR,
  Currency.MXN,
  Currency.BRL,
  Currency.GBP,
]

export const useLiveChatAvailability = (): {
  hasLiveChat: boolean
  isLiveChatVisible: boolean
} => {
  const location = useLocation()
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const isDownloadVisited = useSelector(selectIsDownloadVisited)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)
  const liveChatWorkingHours = useSelector(selectLiveChatWorkingHours)
  const isUpgraded = useSelector(selectIsSubscriptionUpgraded)
  const userStatus = useSelector(selectUserStatus)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const periodQuantity = useSelector(selectPeriodQuantity)

  const { cohort, language } = usePurchaseStore()

  const hasDisablingRule = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_926)

  const cohortToUse = parentCohort || cohort

  const currentPageId = getPageIdFromPathName(location.pathname)

  const currentTime = Number(dayjs().tz('Europe/Kiev').format('H'))

  const isWorkingHours = useMemo(() => {
    if (liveChatWorkingHours) {
      if (liveChatWorkingHours.startTime > liveChatWorkingHours.endTime) {
        return (
          Number(liveChatWorkingHours.startTime) <= currentTime ||
          Number(liveChatWorkingHours.endTime) > currentTime
        )
      }

      return (
        Number(liveChatWorkingHours.startTime) <= currentTime &&
        currentTime < Number(liveChatWorkingHours.endTime)
      )
    }

    return START_WORKING_HOURS <= currentTime || END_WORKING_HOURS > currentTime
  }, [liveChatWorkingHours, currentTime])

  const isEnableLang =
    language === Locale.ENGLISH ||
    language === Locale.SPANISH ||
    language === Locale.DEUTSCH

  const isEmailCohort = MK_EMAIL_COHORTS.includes(cohortToUse as Cohort)

  const isSupportedCurrency =
    isEmailCohort || SUPPORTED_CURRENCIES.includes(paymentCurrency)

  const isUpgradeCohort = LIVECHAT_UPGRADE_COHORTS.includes(
    cohortToUse as Cohort,
  )

  const isSevenDayPlan = periodQuantity === SEVEN_DAY_TRIAL_DURATION

  const isLiveChatAvailable = useMemo(() => {
    if (hasDisablingRule) {
      if (cohort === Cohort.DB_ADULT || cohort === Cohort.DB_ADULT_MX) {
        return false
      }
    }

    if (DISABLED_CHILD_COHORTS.includes(cohort as string)) return false

    if (isUpgradeCohort) {
      return (
        (currentPageId === PageId.PURCHASE_UPGRADE ||
          currentPageId === PageId.PURCHASE_UPGRADE_PDF ||
          currentPageId === PageId.PURCHASE_UPGRADE_MEAL_PLAN) &&
        isUpgraded
      )
    }

    if (isEmailCohort) {
      return (
        currentPageId === PageId.PURCHASE_WITHOUT_INTRO ||
        (currentPageId === PageId.PURCHASE_CANCEL_OFFER &&
          !!userStatus?.hasSubscription)
      )
    }

    if (cohortToUse === Cohort.DB_INTRO_DOP) {
      return !isUpgraded
    }

    return !!(
      (currentPageId === PageId.LOGIN || currentPageId === PageId.DOWNLOAD) &&
      userStatus?.hasSubscription
    )
  }, [
    hasDisablingRule,
    cohort,
    isUpgradeCohort,
    isEmailCohort,
    cohortToUse,
    currentPageId,
    userStatus?.hasSubscription,
    isUpgraded,
  ])

  const isLiveChatVisible = useMemo(() => {
    if (isUpgradeCohort) return false

    if (isEmailCohort) {
      return (
        currentPageId === PageId.PURCHASE_INTRO_OFFER ||
        currentPageId === PageId.PURCHASE_WITHOUT_INTRO
      )
    }

    return currentPageId === PageId.DOWNLOAD
  }, [currentPageId, isEmailCohort, isUpgradeCohort])

  const isLiveChatEnabledCohort = useMemo(() => {
    if (!isEnableLang) return false

    const isLiveChatCohort = LIVECHAT_ENABLED_COHORTS[language]?.includes(
      cohortToUse as Cohort,
    )

    return isUpgradeCohort || isLiveChatCohort || isEmailCohort
  }, [cohortToUse, isEmailCohort, isEnableLang, isUpgradeCohort, language])

  const hasLiveChat = useMemo(
    () =>
      isWorkingHours &&
      isSupportedCurrency &&
      (isEmailCohort || !isDiscountApplied) &&
      isLiveChatEnabledCohort &&
      (isUpgradeCohort || !isDownloadVisited) &&
      !isSevenDayPlan &&
      isLiveChatAvailable,
    [
      isSevenDayPlan,
      isWorkingHours,
      isSupportedCurrency,
      isEmailCohort,
      isDiscountApplied,
      isLiveChatEnabledCohort,
      isUpgradeCohort,
      isDownloadVisited,
      isLiveChatAvailable,
    ],
  )

  return {
    hasLiveChat,
    isLiveChatVisible,
  }
}
