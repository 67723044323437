import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledSleep = {
  OptionsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  `,
  Img: styled.img`
    margin: 0 auto 24px;
    min-height: 200px;
  `,
  Container: styled(Container)<{ $isJapanese: boolean; $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}
  `,
}
