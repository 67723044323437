import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { Input } from 'components/Input'

import { Color } from 'root-constants'

import { StyledAgeFlowContainer } from '../Gender/Gender.styles'

type TCurrentHeightProps = {
  $isMetricSystemSelected: boolean
}

export const StyledCurrentHeight = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  Container: styled(Container)<{ $isAgeFlow: boolean }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
  InputContainer: styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 464px;
  `,
  InputWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  InputIn: styled(Input)<TCurrentHeightProps>`
    max-width: ${({ $isMetricSystemSelected }) =>
      !$isMetricSystemSelected && '160px'};
  `,
  InputFt: styled(Input)<TCurrentHeightProps>`
    max-width: ${({ $isMetricSystemSelected }) =>
      !$isMetricSystemSelected && '160px'};
    margin-left: 4px;
  `,
  Suffix: styled.span`
    position: absolute;
    top: 18px;
    right: 16px;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    color: ${Color.DISABLED};
  `,
}
