import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import cardIcon from 'assets/images/card-icon.svg'
import lockIcon from 'assets/images/protected-payment-icon.svg'
import alertIcon from 'assets/images/red-alert-circle.svg'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.WHITE,
    padding: '3px 0 0 0',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '51px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.WHITE,
      fontWeight: 600,
    },
  },
  invalid: {
    color: '#E74C3C',
  },
}

export const commonInputStyles = css<{ $isBellyFlow?: boolean }>`
  color: ${Color.WHITE};
  height: 56px;
  padding: 3px 0 0 0;
  background-color: #2f3643;
  line-height: 24px;
  border-radius: 8px;
  border: none;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
    }
  }
`

export const StyledCreditCardFormCancelOfferDark = {
  Form: styled.form`
    position: relative;
  `,
  Wrapper: styled.div`
    position: relative;
    border-radius: 12px;
    background: linear-gradient(106deg, #3d4554 1.61%, #333a49 93.48%);
    padding: 54px 8px 8px;
    margin-bottom: 16px;

    &::before {
      content: '';
      width: 36px;
      height: 22px;
      position: absolute;
      top: 16px;
      right: 8px;
      background-image: ${`url(${cardIcon})`};
      background-repeat: no-repeat;
      background-size: 100%;
    }
  `,
  InfoIcon: styled.img`
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 2;
    width: 20px;
    height: 20px;
  `,
  TooltipContainer: styled.div`
    position: absolute;
    bottom: -79px;
    right: -11px;
    z-index: 2;
    width: 183px;
    height: 105px;
  `,
  Tooltip: styled.img`
    position: absolute;
    bottom: -65px;
    right: -4px;
    width: 183px;
    height: 90px;
    z-index: 2;
  `,
  CardContainer: styled.div`
    margin-bottom: 2px;
    &::after {
      content: attr(data-placeholder);
      position: absolute;
      top: 4px;
      left: 16px;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      color: ${Color.GRAY_60};
    }
  `,
  PaymentMethodImageContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  `,
  PaymentMethodImage: styled.img`
    width: 292px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 16px;
  `,
  TitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  PaymentInfo: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
  `,
  Label: styled.span`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #b8bbc1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      width: 12px;
      height: 14px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
  InputContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  CardExpiryElement: styled(CardExpiryElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  CardCvcElement: styled(CardCvcElement)<{ $isBellyFlow?: boolean }>`
    ${commonInputStyles}
  `,
  Button: styled(Button)`
    max-width: 320px;
  `,
}
