import styled from 'styled-components'

export const StyledInContextControlPanel = {
  Wrapper: styled.div`
    position: absolute;
    top: 62px;
    left: 15px;
    z-index: 15;
  `,
  Select: styled.select`
    padding: 2px 10px;
  `,
  Button: styled.button`
    font-size: 14px;
    cursor: pointer;
  `,
}
