import { useMemo } from 'react'
import { useLocation } from 'react-router'

export const enum DynamicTitles {
  HEADLINE = 'headline',
  SUBTEXT = 'subtext',
}

const convertFromSnakeCase = (text: string | null): string =>
  text ? text.replace(/_/g, ' ') : ''

export const useTitleFromUrl = (): { headline: string; subtext: string } => {
  const { search } = useLocation()

  return useMemo(() => {
    const URLParams = new URLSearchParams(search)

    if (!URLParams.get('lang')) {
      return {
        headline: '',
        subtext: '',
      }
    }

    const headlineFromUrl = URLParams.get(DynamicTitles.HEADLINE)
    const subtextFromUrl = URLParams.get(DynamicTitles.SUBTEXT)

    return {
      headline: convertFromSnakeCase(headlineFromUrl),
      subtext: convertFromSnakeCase(subtextFromUrl),
    }
  }, [search])
}
