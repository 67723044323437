import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { DEFAULT_PLAN_BENEFITS } from 'modules/purchase/components/ReceivedPlan/constants'

import { MAIN_GOALS } from 'root-constants'

import { StyledReceivedPlan as S } from './ReceivedPlanJa.styles'

type TProps = {
  className?: string
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
}
export const ReceivedPlanJa: FC<TProps> = ({
  className = '',
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()

  const benefitsList = DEFAULT_PLAN_BENEFITS

  return (
    <S.ProgramPlanContainer className={className} ref={elemForComparisonRef}>
      <S.ProgramPlanTitle>
        <Trans
          i18nKey="purchase1.programDescriptionTitle"
          values={{
            context: MAIN_GOALS.LOSE_WEIGHT,
          }}
        />
      </S.ProgramPlanTitle>
      <ul>
        <S.ListItem>
          <Trans
            i18nKey={benefitsList.first}
            values={{
              context: MAIN_GOALS.LOSE_WEIGHT,
            }}
            components={[<strong />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey={benefitsList.second}
            values={{
              context: MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans i18nKey={benefitsList.third} />
        </S.ListItem>

        <S.ListItem>{t(benefitsList.fourth)}</S.ListItem>
        <S.ListItem>{t(benefitsList.fifth)}</S.ListItem>
      </ul>
    </S.ProgramPlanContainer>
  )
}
