import styled, { css } from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

export const StyledNewAdultContainer = css`
  &::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #f2f9ff 17.31%, #c6e5ff 99.98%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`
export const StyledWantToAchieve = {
  Root: styled.div`
    ${StyledNewAdultContainer}
  `,

  Subtitle: styled.p`
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: 0.5px solid #a7cdea;
    background: #d5ebff;
  `,
}
