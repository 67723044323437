import styled from 'styled-components'

import circleLine from 'assets/images/circle-line-yellow.svg'
import bgWithGradientStars from 'assets/images/paywall-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReachTarget = {
  Wrapper: styled.div`
    position: relative;
    margin: 0 auto 24px;
    padding: 24px 0 0;
    overflow: hidden;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 320px;
      background: url(${bgWithGradientStars}) no-repeat center;
      background-size: cover;
      margin: 0 auto 8px;
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
      border-radius: 0 0 120px 120px;
    }
  `,
  Text: styled.div`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 16px;

    span {
      display: block;
      text-transform: lowercase;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    strong {
      position: relative;
      color: #ffe065;

      &::after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url(${circleLine});
        top: -12px;
        left: -12px;
        width: 74px;
        height: 54px;
      }
  `,
  CarouselContainer: styled.div`
    width: 343px;
    min-height: 322px;
    margin: 0 auto;

    .swiper-container {
      overflow: visible;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: -22px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      opacity: 1;
      background: #d1d8e1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.PRIMARY};
      opacity: 1;
    }
  `,
  PhotoResultWrapper: styled.div`
    max-width: 343px;
    height: 274px;
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
}
