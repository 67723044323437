import styled from 'styled-components'

import cancelIcon from 'assets/images/flow-belly/icons/cancel.svg'

import { Color } from 'root-constants'

export const StyledPopupUpsellProgram = {
  Wrapper: styled.div<{ $isVisible: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ $isVisible }) => ($isVisible ? 15 : -1)};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;

    @media screen and (min-height: 745px) {
      padding-top: 0;
    }
  `,
  Content: styled.div`
    position: relative;
    border-radius: 30px 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding-bottom: 24px;
    margin-top: 25px;
    width: 100%;
    max-width: 375px;
    height: fit-content;
    min-height: calc(100vh - 25px);
    background: ${Color.WHITE};
  `,
  CloseButton: styled.button`
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    background-image: url(${cancelIcon});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 9.6px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
  `,
  ImageBlock: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 8px;
  `,
  Image: styled.img`
    width: 100%;
    min-height: 155px;
  `,
  ProgramTitle: styled.p`
    position: absolute;
    bottom: 24px;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 4px 6px rgba(85, 13, 30, 0.25);
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  `,
  ProgramContainer: styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  `,
  ProgramDescription: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    & strong {
      font-style: italic;
    }
  `,
  Rating: styled.div`
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 14px;
    background: #f6f8fb;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  `,
  Stars: styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
  `,
  ProgramOffers: styled.span`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  `,
  BenefitList: styled.ul<{ $borderColor: string; $backgroundColor: string }>`
    width: 100%;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
    background: ${({ $backgroundColor }) => $backgroundColor};
    backdrop-filter: blur(4px);
  `,
  BenefitItem: styled.li`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  `,
  CheckMark: styled.img`
    width: 20px;
    height: 20px;
  `,
  BenefitText: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    flex: 1 0 0;
  `,
  ProgramDescriptionText: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  BackButton: styled.button`
    display: flex;
    max-height: 56px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 30px;
    background-color: ${Color.PRIMARY};
    font-size: 17px;
    font-weight: 700;
    color: ${Color.WHITE};
    border: none;
    cursor: pointer;
  `,
}
