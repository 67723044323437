import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import { BackButton } from 'components/BackButton'

import { Color } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  $color?: string
  $background?: string
}

const progressValueStyles = css<IProps>`
  background-color: currentColor;
  transition: all 0.2s ease-out;
  border-radius: 0 8px 8px 0;
`

export const StyledOnboardingProgressBar = {
  Wrapper: styled.div<IProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 52px;
    z-index: 1;
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: ${Color.WHITE};
    margin-bottom: 16px;
  `,
  StepBlock: styled.div`
    position: relative;
    width: 42px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 8px;
    }
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    background-color: ${({ $background }) => $background || '#ecf0f7'};
    height: 4px;
    appearance: none;
    border: none;
    display: block;
    color: ${({ $color }) => $color || Color.PRIMARY};
    border-radius: 8px;

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background-color: #ecf0f7;
    }

    &::-webkit-progress-value {
      background-color: ${Color.PRIMARY};
      border-radius: 8px;
    }

    &[data-active='true'] {
      &::-webkit-progress-value {
        background-color: ${Color.PRIMARY};
        border-radius: 8px;
      }
    }
  `,
  HeaderBackButton: styled(BackButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  `,
}
