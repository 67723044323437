import { StyledJapaneseBorder } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDancingVsExercises = {
  Container: styled(Container)<{ $isNewAdultFlow: boolean }>`
    position: relative;
    margin-top: 16px;

    strong {
      color: ${Color.PRIMARY};
    }

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
  AnimationContainer: styled.div<{
    $isJapanese: boolean
    $isNewAdultFlow: boolean
  }>`
    min-width: 343px;
    min-height: 220px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    overflow: hidden;
    padding: 0 16px 20px;
    margin-bottom: 20px;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseBorder};
      `}

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        border: 1px solid #c6e0f4;
      `}
  `,
  UserRecommended: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 92px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{
    $isNewAdultFlow: boolean
  }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;
      `}
  `,
}
