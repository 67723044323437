import { PaymentMethod } from 'modules/purchase/constants'

import amazonPayActiveImage from 'assets/images/amazon-pay-active.png'
import amazonPayInactiveImage from 'assets/images/amazon-pay-inactive.png'
import applePayActiveImage from 'assets/images/apple-pay-active.png'
import applePayInactiveImage from 'assets/images/apple-pay-inactive.png'
import boletoActiveImage from 'assets/images/boleto-active.png'
import boletoInactiveImage from 'assets/images/boleto-inactive.png'
import cardActiveWithAmericanExpressImage from 'assets/images/card-icons-active-with-american-express.png'
import cardActiveImage from 'assets/images/card-icons-active.png'
import cardInactiveWithAmericanExpressImage from 'assets/images/card-icons-inactive-with-american-express.png'
import cardInactiveImage from 'assets/images/card-icons-inactive.png'
import cashappActiveImage from 'assets/images/cashapp-active.png'
import cashappInactiveImage from 'assets/images/cashapp-inactive.png'
import googlePayActiveImage from 'assets/images/google-pay-active.png'
import googlePayInactiveImage from 'assets/images/google-pay-inactive.png'
import linkActiveImage from 'assets/images/link-active.png'
import linkInactiveImage from 'assets/images/link-inactive.png'
import paypalActiveImage from 'assets/images/pay-pal-active.png'
import paypalInactiveImage from 'assets/images/pay-pal-inactive.png'
import revolutActiveImage from 'assets/images/revolut-active.png'
import revolutInactiveImage from 'assets/images/revolut-inactive.png'
import walletsEUActiveImage from 'assets/images/wallets-eu-active.png'
import walletsEUInactiveImage from 'assets/images/wallets-eu-inactive.png'
import walletsUSActiveImage from 'assets/images/wallets-us-active.png'
import walletsUSInactiveImage from 'assets/images/wallets-us-inactive.png'
import walletsWWActiveImage from 'assets/images/wallets-ww-active.png'
import walletsWWInactiveImage from 'assets/images/wallets-ww-inactive.png'

import { ABC_TEST_VARIANTS } from 'root-constants'

export const PAYMENT_METHODS_IMAGES = {
  [PaymentMethod.APPLE_PAY]: {
    active: applePayActiveImage,
    inactive: applePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    active: googlePayActiveImage,
    inactive: googlePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.CREDIT_CARD]: {
    active: cardActiveImage,
    inactive: cardInactiveImage,
    imageWidth: 94,
  },
  [PaymentMethod.PAYPAL]: {
    active: paypalActiveImage,
    inactive: paypalInactiveImage,
    imageWidth: 69,
  },
  [PaymentMethod.AMAZON_PAY]: {
    active: amazonPayActiveImage,
    inactive: amazonPayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.LINK]: {
    active: linkActiveImage,
    inactive: linkInactiveImage,
    imageWidth: 52,
  },
  [PaymentMethod.CASHAPP]: {
    active: cashappActiveImage,
    inactive: cashappInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.REVOLUT_PAY]: {
    active: revolutActiveImage,
    inactive: revolutInactiveImage,
    imageWidth: 61,
  },
  [PaymentMethod.BOLETO]: {
    active: boletoActiveImage,
    inactive: boletoInactiveImage,
    imageWidth: 72,
  },
}

export const PAYMENT_METHODS_IMAGES_ABC_TEST = {
  [PaymentMethod.APPLE_PAY]: {
    active: applePayActiveImage,
    inactive: applePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    active: googlePayActiveImage,
    inactive: googlePayInactiveImage,
    imageWidth: 72,
  },
  [PaymentMethod.CREDIT_CARD]: {
    active: cardActiveWithAmericanExpressImage,
    inactive: cardInactiveWithAmericanExpressImage,
    imageWidth: 94,
  },
  [PaymentMethod.PAYPAL]: {
    active: paypalActiveImage,
    inactive: paypalInactiveImage,
    imageWidth: 69,
  },
}

export const WALLET_IMAGES = {
  EU: {
    active: walletsEUActiveImage,
    inactive: walletsEUInactiveImage,
    imageWidth: 84,
  },
  US: {
    active: walletsUSActiveImage,
    inactive: walletsUSInactiveImage,
    imageWidth: 84,
  },
  WW: {
    active: walletsWWActiveImage,
    inactive: walletsWWInactiveImage,
    imageWidth: 94,
  },
}

export const PAYMENT_METHODS_TO_SHOW = {
  [ABC_TEST_VARIANTS.VARIANT_A]: [
    PaymentMethod.CREDIT_CARD,
    PaymentMethod.PAYPAL,
    PaymentMethod.APPLE_PAY,
    PaymentMethod.GOOGLE_PAY,
    PaymentMethod.BOLETO,
  ],
  [ABC_TEST_VARIANTS.VARIANT_B]: [
    PaymentMethod.CREDIT_CARD,
    PaymentMethod.PAYPAL,
    PaymentMethod.APPLE_PAY,
    PaymentMethod.GOOGLE_PAY,
    PaymentMethod.CASHAPP,
    PaymentMethod.REVOLUT_PAY,
    PaymentMethod.AMAZON_PAY,
    PaymentMethod.LINK,
  ],
  [ABC_TEST_VARIANTS.VARIANT_C]: [
    PaymentMethod.CREDIT_CARD,
    PaymentMethod.WALLETS,
    PaymentMethod.APPLE_PAY,
    PaymentMethod.GOOGLE_PAY,
  ],
}

export const WW_PM = ['amazon_pay']

export const USA_PM = ['cashapp', 'amazon_pay']

export const EU_PM = ['revolut_pay']
