import computerIcon from 'assets/images/computer.svg'
import coffeeIcon from 'assets/images/cup-of-coffee.svg'
import homeIcon from 'assets/images/home.svg'
import moonIcon from 'assets/images/moon.svg'
import palmIcon from 'assets/images/palm.svg'

export const QUESTION = 'What’s your work schedule like?'

export const VALUES = {
  NINE_TO_FIVE: 'nineToFive',
  FLEXIBLE: 'flexible',
  NIGHT_SHIFTS: 'nightShifts',
  NO_WORK: 'noWork',
  AT_HOME_PARENT: 'atHomeParent',
}

export const OPTION_VALUES = [
  {
    value: VALUES.NINE_TO_FIVE,
    text: 'onboarding.workSchedule.fromNineToFive',
    imageUrl: computerIcon,
  },
  {
    value: VALUES.FLEXIBLE,
    text: 'onboarding.workSchedule.flexible',
    imageUrl: coffeeIcon,
  },
  {
    value: VALUES.NIGHT_SHIFTS,
    text: 'onboarding.workSchedule.nightShifts',
    imageUrl: homeIcon,
  },
  {
    value: VALUES.NO_WORK,
    text: 'onboarding.workSchedule.noWork',
    imageUrl: moonIcon,
  },
  {
    value: VALUES.AT_HOME_PARENT,
    text: 'onboarding.workSchedule.atHomeParent',
    imageUrl: palmIcon,
  },
]
