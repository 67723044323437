import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledEmailBelly = {
  InputContainer: styled.div`
    width: 100%;
    margin-bottom: 24px;
  `,
  Disclaimer: styled.p`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY_80};
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 8px;
  `,
  Container: styled(Container)<{
    $isJapanese: boolean
    $isNewAdultFlow: boolean
  }>`
    margin-top: 16px;

    & strong {
      color: ${Color.PRIMARY};
    }

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
  Badge: styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #c6e9fe;
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
}
