import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'
import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { Gender, MAIN_GOALS } from 'root-constants'

import { StyledMainGoalAgeFlow as S } from './MainGoalSomaticV2.styles'
import { OPTION_VALUES_SOMATIC, QUESTION } from './constants'

export const MainGoalSomaticV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const dispatch = useDispatch()

  const { imageUrls } = useDynamicOBConfig()
  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)

        dispatch(
          setAnswersAction({
            answers: [MAIN_GOALS.LOSE_WEIGHT],
            pageId: PageId.MAIN_GOAL,
          }),
        )
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled, dispatch],
  )

  return (
    <S.Container isLarge $bgImageUrl={imageUrl}>
      <PageTitle marginBottom={24}>
        {t('onboarding.mainGoal.titleBelly')}
      </PageTitle>
      {OPTION_VALUES_SOMATIC.map(({ value, title }) => (
        <Answer
          {...optionProps}
          theme={answerTheme.DANCEBIT}
          maxWidth="218px"
          margin="0 0 16px 0"
          disabled={isAnswersDisabled}
          value={value}
          key={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
