import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectLanguage } from 'root-redux/selects/common'

import { StyledResultSlider as S } from './ResultSlider.styles'
import { SLIDES } from './constants'

export const ResultSlider: FC = () => {
  const language = useSelector(selectLanguage)

  Swiper.use([Pagination, Autoplay])

  return (
    <S.Wrapper>
      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {SLIDES.map(({ img, subtitle, title, alt }) => (
            <SwiperSlide key={img}>
              <S.Card>
                <S.TopLabel>
                  <Trans i18nKey={title} />
                </S.TopLabel>

                <S.Image src={`${img}_${language}.png`} alt={alt} />

                {subtitle && (
                  <S.Subtitle>
                    <Trans i18nKey={subtitle} />
                  </S.Subtitle>
                )}
              </S.Card>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
