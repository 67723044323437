import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockMealPlan } from 'modules/purchase/components/SubscriptionsBlockMealPlan'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutCancelOffer } from 'modules/purchase/pages/CheckoutCancelOffer'
import {
  DANCE_PROGRAM_IMAGE_LINK,
  HERO_IMAGE_LINK,
  MEAL_PLAN_IMAGE_LINK,
} from 'modules/purchase/pages/PurchaseCancelOfferMealPlan/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  CDN_FOLDER_LINK,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseCancelOfferMealPlan as S } from './PurchaseCancelOfferMealPlan.styles'

export const PurchaseCancelOfferMealPlan: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isStayFit = useSelector(selectIsStayFitFlow)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const { fetchingActionsList, language, subscriptions } = usePurchaseStore()

  usePurchaseAnalytics({
    screenId: ScreenId.CANCEL_MEALPLAN,
    isAmplitudeEvent: true,
  })

  const { buttonText, benefitsBlockTitle } = useDynamicPaywallConfig()

  const hasIncludedVat = useVatInfo()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.CANCEL_OFFER_MEALPLAN, !isCheckoutShown)

  const heroImage = useMemo(
    () => `${CDN_FOLDER_LINK}${HERO_IMAGE_LINK}_${language}.png`,
    [language],
  )

  const personalDanceProgram = useMemo(
    () => `${CDN_FOLDER_LINK}${DANCE_PROGRAM_IMAGE_LINK}_${language}.png`,
    [language],
  )

  const mealPlanProduct = useMemo(
    () => `${CDN_FOLDER_LINK}${MEAL_PLAN_IMAGE_LINK}_${language}.png`,
    [language],
  )

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useEffect(() => {
    eventLogger.logCancelOfferPageMealPlanShown()
  }, [])

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER_MEALPLAN))
    dispatch(setScreenIdAction(ScreenId.CANCEL_MEALPLAN))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${hasIncludedVat ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX},${
          SubscriptionTags.MEAL_PLAN
        },${SubscriptionTags.CANCEL_OFFER}`,
      ),
    )
    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.CANCEL_OFFER_MEALPLAN,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {isCheckoutShown && <Header showHeader />}
      {!isCheckoutShown && (
        <>
          <S.Hero>
            <S.HeroImage src={heroImage} alt="" />
          </S.Hero>
          <S.Wrapper>
            <SubscriptionsBlockMealPlan />
            <S.ProductsTitle>
              {benefitsBlockTitle ||
                t`purchaseCancelOfferMealPlan.products.title`}
            </S.ProductsTitle>
            <S.Products>
              <ul>
                <S.Product>
                  <S.ProductImage src={personalDanceProgram} alt="" />
                  <div>
                    <S.ProductTitle>
                      <Trans
                        i18nKey="purchaseCancelOfferMealPlan.products.danceProgramTitle"
                        components={[<br />]}
                      />
                    </S.ProductTitle>
                    <S.ProductText>
                      <Trans
                        i18nKey="purchaseCancelOfferMealPlan.products.danceProgramDescription"
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                        }}
                      />
                    </S.ProductText>
                  </div>
                </S.Product>
                <S.Product>
                  <S.ProductImage src={mealPlanProduct} alt="" />
                  <div>
                    <S.ProductTitle>
                      <Trans
                        i18nKey="purchaseCancelOfferMealPlan.products.mealPlanTitle"
                        components={[<br />]}
                      />
                    </S.ProductTitle>
                    <S.ProductText>
                      {t`purchaseCancelOfferMealPlan.products.mealPlanDescription`}
                    </S.ProductText>
                  </div>
                </S.Product>
              </ul>
            </S.Products>
            <S.Button
              type="button"
              data-order="1"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.Button>
            <S.MoneyBackContainer>
              <MoneyBackGuarantee />
            </S.MoneyBackContainer>
            <SecurityInfo />
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutCancelOffer
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
