import React from 'react'

import { eventLogger } from 'services/eventLogger.service'

import { SUPPORT_LINK } from 'root-constants'

import { OuterLink } from './OuterLink'

type TProps = {
  text: string
  source: string
}

export const ContactWithSupport: React.FC<TProps> = ({ text, source }) => (
  <OuterLink
    href={SUPPORT_LINK}
    onClick={() => eventLogger.logContactSupportClicked(source)}
  >
    {text}
  </OuterLink>
)
