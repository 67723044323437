import {
  Locale,
  PAID_USER_DE_GROUP_ID,
  PAID_USER_ES_GROUP_ID,
} from 'root-constants'

export const START_WORKING_HOURS = 13
export const END_WORKING_HOURS = 3

export const USER_GROUP_ID = {
  [Locale.SPANISH]: PAID_USER_ES_GROUP_ID,
  [Locale.DEUTSCH]: PAID_USER_DE_GROUP_ID,
}
