import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

export const StyledEasilyGiveUp = {
  QuestionBlock: styled.div`
    position: relative;
    margin-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 343px;
    height: 306px;
  `,
  QuestionContainer: styled.div`
    width: 100%;
    position: absolute;
    top: -55px;
    left: 21px;
  `,
  Question: styled.div<{ $isAdult: boolean }>`
    position: relative;
    background: linear-gradient(90deg, #e6f6fe 1.89%, #e6f0fe 97.8%);
    border-radius: 16px;
    padding: 12px;
    max-width: 300px;
    font-size: 17px;
    text-align: center;
    line-height: 24px;

    &::after {
      content: '';
      position: absolute;
      bottom: -9px;
      right: 35px;
      border-width: 10px 3px 0 15px;
      border-style: solid;
      border-color: #e6f2fe transparent transparent transparent;
    }

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        background: linear-gradient(90deg, #caf3ff 1.89%, #cfe3ff 97.8%);

        &::after {
          border-color: #cfe5ff transparent transparent transparent;
        }
      `}
  `,
  ButtonsBLock: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
  `,
  Container: styled(Container)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
}
