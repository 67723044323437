import React from 'react'
import SlideRule from 'react-slide-rule'

import { Color } from 'root-constants'

import { StyledSelect as S } from './Slider.styles'

type TSelectProps = {
  value: number
  min: number
  max: number
  step: number
  stepsAmount?: number
  formatNumber?: (value: number) => string
  gap: number
  isHorizontal?: boolean
  onChange: (event: number) => void
}

export const Slider: React.FC<TSelectProps> = ({
  value,
  onChange,
  min,
  max,
  step,
  stepsAmount = 10,
  gap,
  formatNumber,
  isHorizontal = true,
}) => (
  <S.Slider $isHorizontal={isHorizontal}>
    <SlideRule
      value={value}
      onChange={onChange}
      max={max}
      min={min}
      step={step}
      stepsAmount={stepsAmount}
      width={isHorizontal ? 360 : 94}
      height={isHorizontal ? 114 : undefined}
      gap={gap}
      markStyle={{
        color: '#b8bbc1',
        width: isHorizontal ? 2 : 54,
        height: isHorizontal ? 32 : 2,
      }}
      smallerMarkStyle={{
        top: isHorizontal ? 12 : 0,
        color: '#b8bbc1',
        width: isHorizontal ? 1 : 20,
        height: isHorizontal ? 20 : 1,
      }}
      numberStyle={{
        size: '18px',
        family: 'Roboto, sans-serif',
        color: Color.DARK,
        top: isHorizontal ? 64 : 0,
        left: isHorizontal ? 0 : 64,
        formatNumber,
      }}
      axis={isHorizontal ? 'x' : 'y'}
      cursor={<S.Cursor $isHorizontal={isHorizontal} />}
      style={{
        overflow: 'unset',
      }}
    />
  </S.Slider>
)
