import styled from 'styled-components'

import { Button } from 'components/Button'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledUpsellBenefits = {
  Wrapper: styled.div`
    max-width: 296px;
    margin: 0 auto 33px;
  `,
  Title: styled.h2`
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Text: styled.p`
    position: relative;
    padding-left: 34px;
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    margin-top: 25px;
  `,
  UpsellButton: styled(Button)`
    max-width: none;
  `,
}
