import styled from 'styled-components'

import { Button } from 'components/Button'
import { StyledButtonBase } from 'components/Button/Button.styles'

import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledPurchaseVariant1 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 20px;
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 50px auto;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 38px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  FeaturedBlockContainer: styled.div`
    margin-bottom: 50px;
  `,
  ButtonContainer: styled.div`
    padding-bottom: 24px;
  `,
  FixedButton: styled(Button)`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    max-width: 328px;
    z-index: 103;
  `,
  SubscriptionDisclaimer: styled(PurchaseDisclaimer)`
    font-size: 10px;
    line-height: 12px;

    br {
      display: none;
    }
  `,
  Label: styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 43px;
    background-color: #03aaf5;
    padding: 8px 15px;
    border-radius: 0 8px 8px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: white;
    line-height: 20px;
    margin-left: 42px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 35px;
      height: 81.4%;
      background-color: #03aaf5;
      position: absolute;
      left: 0;
      z-index: -1;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      border-radius: 8px;
    }
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
