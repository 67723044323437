import styled from 'styled-components'

import arrowSvg from 'assets/images/arrows-yellow.svg'

import { Color } from 'root-constants'

type TPhotoResultV3Props = {
  $hasBackground?: boolean
}

export const StyledPhotoResultV3 = {
  Wrapper: styled.div`
    padding-bottom: 37px;
  `,
  TitleContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Title: styled.h2`
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #e43a91;
    max-width: 283px;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
  `,
  ResultContainer: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;
    padding: 8px 0;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 16px;
    background: linear-gradient(90deg, #ffc5e3 0%, #e43991 100%);
  `,
  ResultText: styled.span`
    color: #fff7a5;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  ResultSvgContainer: styled.div`
    width: 40px;
    height: 20px;
    background-image: url(${arrowSvg});
    background-repeat: no-repeat;
  `,
  CardWrapper: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid transparent;
    background: linear-gradient(to right, white, white),
      linear-gradient(90deg, #ffc5e3 0%, #e43991 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    transition: none;
  `,
  Card: styled.div`
    max-width: 300px;
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Author: styled.h3`
    color: ${Color.DARK};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80px;
    padding: 8px 0;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  PhotoResultWrapper: styled.div<TPhotoResultV3Props>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
  `,
  Image: styled.img`
    width: 100%;
    margin-bottom: 16px;
  `,
  CarouselContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-container {
      padding-bottom: 25px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.2;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 0.7;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      height: auto;
    }
  `,
  CustomTitle: styled.h2`
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
}
