import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  GET_PAYMENT_CONFIG,
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenNameAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  sendFacebookParamsAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useViewportValue } from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { AppStats } from 'modules/purchase/components/AppStats'
import { BodyAge } from 'modules/purchase/components/BodyAge'
import { DailyBenefits } from 'modules/purchase/components/DailyBenefits'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PeopleAsk } from 'modules/purchase/components/PeopleAsk'
import { ReviewsNoOnboarding } from 'modules/purchase/components/ReviewsNoOnboarding'
import { SecurityInfoV2 } from 'modules/purchase/components/SecurityInfoV2'
import { SubscriptionsBlockIntroOffer } from 'modules/purchase/components/SubscriptionsBlockIntroOffer'
import { WorldWideUsers } from 'modules/purchase/components/WorldWideUsers'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionDiscountPercentage } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import hero from 'assets/images/short-purchase-hero-min.jpg'
import burgerSvg from 'assets/images/sprite/burger.svg'
import logoSvg from 'assets/images/sprite/logo.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  PagesSource,
  SUPPORT_LINK,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseNoOnboarding as S } from './PurchaseNoOnboarding.styles'
import { DAILY_BENEFITS_DATA } from './constants'

export const PurchaseNoOnboarding: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const discount = useSelector(selectSubscriptionDiscountPercentage)
  const uuid = useSelector(selectUUID)

  const {
    fetchingActionsList,
    paymentConfig,
    subscriptions,
    userStatus,
  } = usePurchaseStore()

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  useViewportValue(ScreenName.SHORT_FLOW)

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !fetchingActionsList?.includes(GET_PAYMENT_CONFIG),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(sendUserAnswersAction())
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  useLayoutEffect(() => {
    if (paymentConfig) {
      dispatch(setScreenNameAction(ScreenName.ONBOARDING))
      dispatch(
        getSubscriptionListAction(
          SubscriptionListType.PURCHASE,
          SubscriptionTags.NO_TAX,
        ),
      )
    }

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, paymentConfig])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map((subscription) =>
        createProductId({
          periodName: subscription.mainPrices.periodName,
          periodQuantity: subscription.mainPrices.periodQuantity,
          price: subscription.mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleClick = useCallback(
    (event) => {
      dispatch(sendFacebookParamsAction())
      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.SHORT_FLOW,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      window.ttq.track('AddToCart', { event_id: uuid })
      if (userStatus?.email.hasEmail) {
        goTo({
          pathname: PageId.CHECKOUT_NO_ONBOARDING,
          search,
        })
        return
      }
      goTo(nextPagePath)
    },
    [nextPagePath, userStatus, uuid, search, dispatch],
  )

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Header>
        <S.Logo>
          <SvgImage svg={logoSvg} />
          <span>Dancebit</span>
        </S.Logo>
        <S.Burger type="button" onClick={toggleMenuVisibility}>
          <SvgImage svg={burgerSvg} height={14} />
        </S.Burger>
      </S.Header>

      <S.Discount>
        <S.DiscountText>
          <S.DiscountTitle>
            {t('purchaseNoOnboarding.discount.title', { discount })}
          </S.DiscountTitle>
          <S.DiscountDescription>
            {t`purchaseNoOnboarding.discount.description`}
          </S.DiscountDescription>
        </S.DiscountText>
        <S.Action
          type="button"
          data-order="1"
          data-text="try_now"
          onClick={handleClick}
        >
          {t('purchaseNoOnboarding.ctaV2')}
        </S.Action>
      </S.Discount>

      <S.Hero>
        <S.Ratio />
        <S.HeroImage src={hero} alt="" />
      </S.Hero>

      <S.Container>
        <S.Title>
          <Trans i18nKey="purchaseNoOnboarding.title" component={[<br />]} />
        </S.Title>

        <SubscriptionsBlockIntroOffer
          brightTheme
          elemForComparisonRef={buttonWrapperElementRef}
          disclaimerVisible={false}
          customTitle={
            <S.SubscriptionsListTitle>
              {t('purchaseNoOnboarding.subscriptionsListTitle')}
            </S.SubscriptionsListTitle>
          }
        >
          <Button
            type="button"
            data-order="2"
            data-text="try_now"
            onClick={handleClick}
          >
            {t('purchaseNoOnboarding.ctaV2')}
          </Button>
        </SubscriptionsBlockIntroOffer>

        <S.DailyBenefitsContainer>
          {DAILY_BENEFITS_DATA.map(({ iconSrc, title, description }) => (
            <DailyBenefits
              iconSrc={iconSrc}
              title={t(title)}
              description={t(description)}
              key={title}
            />
          ))}
        </S.DailyBenefitsContainer>

        <S.ButtonContainer>
          <Button
            type="button"
            data-order="3"
            data-text="start_your_trial"
            onClick={handleClick}
          >
            {t('purchaseNoOnboarding.cta')}
          </Button>
        </S.ButtonContainer>

        <WorldWideUsers />
        <ReviewsNoOnboarding />
        <PeopleAsk />
        <BodyAge />
        <S.ButtonContainer>
          <Button
            type="button"
            data-order="4"
            data-text="start_your_trial"
            onClick={handleClick}
          >
            {t('purchaseNoOnboarding.cta')}
          </Button>
        </S.ButtonContainer>
      </S.Container>

      <S.BottomContainer>
        <AppStats />
        <S.BottomRow>
          <S.ProgramPlanContainer>
            <S.ProgramPlanTitle>
              {t('purchaseNoOnboarding.offer.title')}
            </S.ProgramPlanTitle>
            <ul>
              <S.ListItem>
                {t('purchaseNoOnboarding.offer.options.option1')}
              </S.ListItem>
              <S.ListItem>
                {t('purchaseNoOnboarding.offer.options.option2')}
              </S.ListItem>
              <S.ListItem>
                {t('purchaseNoOnboarding.offer.options.option3')}
              </S.ListItem>
              <S.ListItem>
                {t('purchaseNoOnboarding.offer.options.option4')}
              </S.ListItem>
              <S.ListItem>
                {t('purchaseNoOnboarding.offer.options.option5')}
              </S.ListItem>
            </ul>
          </S.ProgramPlanContainer>

          <S.SubscriptionContainer>
            <SubscriptionsBlockIntroOffer
              elemForComparisonRef={buttonWrapperElementRef}
              disclaimerVisible={false}
              clearBackground
              withTitle={false}
            >
              <Button
                type="button"
                data-order="5"
                data-text="try_now"
                onClick={handleClick}
              >
                {t('purchaseNoOnboarding.ctaV2')}
              </Button>
            </SubscriptionsBlockIntroOffer>
          </S.SubscriptionContainer>

          <MoneyBackGuarantee customBackground="#fff" />
          <SecurityInfoV2 />
        </S.BottomRow>
      </S.BottomContainer>

      <S.Backdrop
        $isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu $isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink source={PagesSource.PAYWALL} />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink source={PagesSource.PAYWALL} />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <S.HelpAction
            href={SUPPORT_LINK}
            onClick={() => eventLogger.logNeedHelpClicked()}
          >
            {t`actions.needHelp`}
          </S.HelpAction>
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </S.Wrapper>
  )
}
