export const QUESTION = 'What’s your main goal?'

export const OPTION_VALUES_SOMATIC = [
  {
    value: 'releaseEmotions',
    title: 'onboarding.mainGoal.releaseEmotions',
  },
  {
    value: 'loseWeight',
    title: 'onboarding.mainGoal.loseWeight',
  },
  {
    value: 'reduceStressAndWorry',
    title: 'onboarding.mainGoal.reduceStressAndWorry',
  },
  {
    value: 'getOutOfSurvivalMode',
    title: 'onboarding.mainGoal.getOutOfSurvivalMode',
  },
]
