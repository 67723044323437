import { Cohort } from 'root-constants'

export const enum DiscountValue {
  FIFTY = 50,
  SIXTY_FIVE = 65,
  SEVENTY_FIVE = 75,
}

export const DISCOUNT_VALUES: Partial<Record<Cohort, number>> = {
  [Cohort.DANCEBIT_FLOW_1]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_EMAIL_1]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_EMAIL_2]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_EMAIL_3]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_20_30_40]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_CLEAR_FLOW]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DB_FLOW_3D]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DB_FLOW_7D]: DiscountValue.SEVENTY_FIVE,
  [Cohort.DANCEBIT_SHORT]: DiscountValue.FIFTY,
}
