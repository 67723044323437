import styled from 'styled-components'

export const StyledFeaturedBlock = {
  Title: styled.h2`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(19, 29, 48, 0.5);
  `,
  Container: styled.div`
    p {
      margin-top: 20px;
    }

    br {
      display: none;
    }
  `,
}
