import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { getStripePurchaseCommonEventParams } from 'modules/purchase/helpers/getStripePurchaseCommonEventParams'
import { selectSubscriptionTrialPeriodPrice } from 'modules/purchase/redux/selects/common'

import { IAppState } from 'models/store.model'

export const getStripePurchaseSuccessEventParams = (state: IAppState) => {
  const {
    productName,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    screenId,
    accountId,
    accountName,
    paymentSystem,
    isPersonalDataAllowed,
  } = getStripePurchaseCommonEventParams(state)

  const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
  const email = selectUserOnboardingEmail(state)

  return {
    trialPrice: trialPeriodPrice,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    screenId,
    email,
    stripeAccountName: accountName,
    accountId,
    paymentSystem,
    productName,
    isPersonalDataAllowed,
  }
}
