import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsPrimerRetryProcessing } from 'root-redux/selects/common'

import {
  cancelPrimerClientSessionDiscount,
  setIsFirstPaymentRetryPassedAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectIsFirstPaymentRetryPassed,
  selectPrimerClientSessionToken,
} from 'modules/purchase/redux/selects/common'

export const useCancelPrimerDiscount = () => {
  const dispatch = useDispatch()

  const isFirstPaymentRetryPassed = useSelector(selectIsFirstPaymentRetryPassed)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)

  const cancelDiscount = useCallback(async () => {
    if (!isPrimerRetryProcessing && isFirstPaymentRetryPassed) {
      await dispatch(
        cancelPrimerClientSessionDiscount({
          clientToken: primerClientSessionToken,
        }),
      )
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }
  }, [
    dispatch,
    isFirstPaymentRetryPassed,
    isPrimerRetryProcessing,
    primerClientSessionToken,
  ])

  return { cancelDiscount }
}
