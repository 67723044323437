import styled, { css } from 'styled-components'

import arrowSvg from 'assets/images/arrow.svg'

import { Color } from 'root-constants'

type TPhotoResultV3Props = {
  $hasBackground?: boolean
}

export const StyledPhotoResultV3 = {
  Wrapper: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  Description: styled.p`
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
    padding: 0 16px;
  `,
  ResultContainer: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
  `,
  ResultText: styled.span`
    color: ${Color.PRIMARY};
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: 32px;
  `,
  ResultSvgContainer: styled.div`
    width: 40px;
    height: 20px;
    background-image: url(${arrowSvg});
    background-repeat: no-repeat;
  `,
  CardWrapper: styled.div<TPhotoResultV3Props>`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: hidden;
  `,
  Card: styled.div<{ $isActive: boolean; $isPrev: boolean }>`
    max-width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 24px;
    overflow: hidden;
    max-height: 136px;
    opacity: 0;

    ${({ $isActive, $isPrev }) =>
      $isActive &&
      !$isPrev &&
      css`
        transition: all 1s ease-out;
        opacity: 1;

        & > h3 {
          transition: all 1s ease-out;
          line-height: 24px;
        }

        & > p {
          transition: all 1s ease-out;
          line-height: 20px;
        }
      `}
  `,
  Author: styled.h3`
    color: ${Color.DARK};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    line-height: 74px;
    font-weight: 700;
    margin-bottom: 8px;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 88px;
    margin-bottom: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 74px;
    text-align: center;
  `,
  PhotoResultWrapper: styled.div<TPhotoResultV3Props>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    min-height: 460px;
    flex: 1 0 auto;
    padding: 8px 0 24px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.7);
  `,
  Image: styled.img`
    width: 343px;
    margin-bottom: 24px;
  `,
  CarouselContainer: styled.div`
    .swiper-container {
      padding-bottom: 36px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: ${Color.WHITE};
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.PRIMARY};
      height: 6px;
      padding: 1px 0;
      width: 21px;
      border-radius: 20px;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      height: auto;
    }
  `,
  CustomTitle: styled.h2`
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
}
