import { TimeInterval } from 'root-constants'

export function createProductId({
  periodName,
  periodQuantity,
  price,
}: {
  periodName: TimeInterval | null
  periodQuantity: number
  price: number
}): string {
  return `dancebit_${periodQuantity}_${periodName}_${price}`
}
