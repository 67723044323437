import { StyledJapaneseBorder } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { CustomDatePicker } from 'components/CustomDatePicker'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

export const StyledEventDate = {
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
    user-select: none;
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)<{
    $isAdult: boolean
  }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{ $isAdult: boolean }>`
    padding-bottom: 16px;

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: 0.5px solid #a7cdea;
        background-color: #d5ebff;
      `}
  `,
  SkipButton: styled.a`
    display: block;
    color: ${Color.GRAY_60};
    text-align: center;
    font-style: normal;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    margin-top: 10px;
  `,
  CustomDatePicker: styled(CustomDatePicker)<{
    $isJapanese: boolean
    $isAdult: boolean
  }>`
    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-color: ${Color.PRIMARY};
        ${StyledJapaneseBorder};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        background-color: ${Color.WHITE};
        border: 1px solid #c6e0f4;
      `}
  `,
}
