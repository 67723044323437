import styled from 'styled-components'

import { IntroOfferDisclaimer } from 'modules/purchase/components/IntroOfferDisclaimer'

import subscriptionListBackgroundBrightTheme from 'assets/images/purchaseNoOnboarding/subscription-list-background-bright-theme-min.png'
import subscriptionListBackground from 'assets/images/subscription-list-bg.png'

import { CartDisclaimer } from '../CartDisclaimer'

type TSubscriptionsBlockIntroOfferProps = {
  $clearBackground?: boolean
  $brightTheme?: boolean
  $isBellyFlow?: boolean
  className?: string
}

export const StyledSubscriptionsBlockIntroOffer = {
  WrapperContainer: styled.div<TSubscriptionsBlockIntroOfferProps>`
    margin: ${({ $clearBackground }) => ($clearBackground ? 0 : ' 0 0 50px')};
  `,
  Wrapper: styled.div<TSubscriptionsBlockIntroOfferProps>`
    margin: ${({ $clearBackground }) => ($clearBackground ? 0 : ' 0 0 16px')};
    padding: ${({ $clearBackground, $isBellyFlow }) => {
      if ($isBellyFlow) return '24px 16px'

      if ($clearBackground) return '0'

      return '30px 16px'
    }};
    border-radius: 24px;
    background: ${({ $clearBackground, $brightTheme }) =>
      // eslint-disable-next-line no-nested-ternary
      $clearBackground
        ? 'none'
        : $brightTheme
        ? `url(${subscriptionListBackgroundBrightTheme}) center center no-repeat`
        : `url(${subscriptionListBackground}) center center no-repeat`};
    background-size: cover;
  `,
  Title: styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div<{ $isBellyFlow: boolean }>`
    margin: ${({ $isBellyFlow }) => ($isBellyFlow ? '24px 0 0' : '32px 0 0')};
  `,
  SubscriptionDisclaimer: styled(IntroOfferDisclaimer)`
    margin-top: 16px;
  `,
  CartDisclaimer: styled(CartDisclaimer)`
    margin-top: 16px;
  `,
}
