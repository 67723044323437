import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDancingStyle = {
  Title: styled.h1<{
    $isAgeFlow: boolean
  }>`
    font-weight: ${({ $isAgeFlow }) => ($isAgeFlow ? 800 : 700)};
    margin-bottom: ${({ $isAgeFlow }) => ($isAgeFlow ? '24px' : '30px')};
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
  DanceCard: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    video {
      width: 72px;
      height: 100px;
      border-radius: 14px;
      margin-right: 16px;
    }
  `,
  DanceTitle: styled.h3<{
    $isSelected: boolean
  }>`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        color: ${Color.WHITE};
      `}
  `,
  Description: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.DISABLED};
  `,
  New: styled.div<{
    $isSelected: boolean
  }>`
    padding: 2px 6px;
    margin-bottom: 4px;
    border-radius: 6px;
    background: ${Color.PRIMARY};
    color: ${Color.WHITE};
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    width: fit-content;

    ${({ $isSelected }) =>
      $isSelected &&
      css`
        background: ${Color.WHITE};
        color: ${Color.PRIMARY};
      `}
  `,
  DanceInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 188px;
  `,
  AnswerButton: styled(AnswerButton)`
    padding: 8px 45px 8px 8px;
  `,
  Subtitle: styled.p<{
    $isAgeFlow: boolean
  }>`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 24px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 32px;
      `}
  `,
}
