import styled, { css } from 'styled-components'

import bgImage from 'assets/images/discount-timer-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TProps = {
  $isButtonVisible?: boolean
}

export const StyledSubheaderWithTimerFreeOffer = {
  TimerContainer: styled.div<TProps>`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 32px);
    max-height: 60px;
    margin: 0 auto 40px;
    padding: 8px 8px 8px 12px;
    border-radius: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.WHITE};
    z-index: 10;
    max-width: 343px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-image: url(${bgImage});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 16px;
    }

    ${({ $isButtonVisible }) =>
      $isButtonVisible &&
      css`
        width: 100%;

        &:before {
          left: 50%;
          transform: translateX(-50%);
          background: linear-gradient(
              278deg,
              #f8002f 29.76%,
              #ff782a 59.72%,
              #ffb52a 87.4%
            ),
            #fff;
          border-radius: 0;
          box-shadow: 0 8px 20px 0 rgba(18, 22, 39, 0.1);
          width: ${MediaBreakpoint.MAX_PHONE}px;

          @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
            max-width: 375px;
          }
        }
      `}
  `,
  TimerText: styled.p<TProps>`
    max-width: ${({ $isButtonVisible }) => $isButtonVisible && '84px'};
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  `,
  SubheaderTimer: styled(Timer)`
    min-width: 88px;
    padding: 0 10px 0 15px;

    & div {
      font-size: 34px;
      font-weight: 700;
      line-height: 38px;
    }
  `,
  ButtonContainer: styled.div<TProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button`
    height: 48px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: ${Color.PRIMARY};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  `,
  Timer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  TimerSign: styled.div`
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 6px;
    width: 44px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.WHITE};
  `,
}
