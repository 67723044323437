import styled from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

export const StyledCurrentWeightBelly = {
  Form: styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  WeightLabel: styled.div`
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px;
    border-radius: 12px;
    padding: 8px 24px;
    background-color: ${Color.PRIMARY};
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    letter-spacing: 0.5px;
  `,
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
