import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledFallBack = {
  QuestionBlock: styled.div`
    position: relative;
    margin-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 343px;
    height: 306px;
  `,
  QuestionContainer: styled.div`
    width: 100%;
    position: absolute;
    top: -55px;
    left: 21px;
  `,
  Question: styled.div`
    position: relative;
    background: linear-gradient(90deg, #caf3ff 1.89%, #cfe3ff 97.8%);
    border-radius: 16px;
    padding: 12px;
    max-width: 300px;
    font-size: 17px;
    text-align: center;
    line-height: 24px;

    &::after {
      content: '';
      position: absolute;
      bottom: -9px;
      right: 35px;
      border-width: 10px 3px 0 15px;
      border-style: solid;
      border-color: #cfe5ff transparent transparent transparent;
    }
  `,
  ButtonsBLock: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
  `,
  Container: styled(Container)`
    ${StyledNewAdultContainer}
  `,
}
