import styled from 'styled-components'

import horizontalBorder from 'assets/images/horizontal-border.png'
import verticalBorder from 'assets/images/vertical-border.png'

import { Color } from 'root-constants'

export const StyledConfidenceBlock = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    margin-bottom: 40px;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &:first-child {
      padding-right: 16px;

      border-image-source: url(${verticalBorder});
      border-width: 1px;
      border-style: solid;
      border-image-slice: 0 1 0 0;
      border-image-repeat: stretch;
    }

    &:last-child {
      padding-left: 16px;
    }
  `,
  Block: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;

    &:first-child {
      padding-bottom: 16px;
      border-image-source: url(${horizontalBorder});
      border-width: 1px;
      border-style: solid;
      border-image-slice: 0 0 1 0;
      border-image-repeat: stretch;
    }

    &:last-child {
      padding-top: 16px;
    }

    & > h3 {
      margin-bottom: 8px;

      font-weight: 900;
      font-size: 14px;
      line-height: 20px;
    }

    & > span {
      font-weight: 400;
      color: ${Color.GRAYISH_BLUE};
    }
  `,
  DanceLvlBar: styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
  `,
  DanceLvlItem: styled.div`
    width: 34px;
    height: 6px;
    border-radius: 4px;
    background-color: #e3e4e8;

    &[data-active='true'] {
      background: #ae65e7;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  `,
}
