import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import moneyBackBackground from 'assets/images/customer-review-bg.png'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div<{ $hasCustomBackground?: string }>`
    position: relative;
    margin-bottom: 85px;
    padding: 8px;
    background: ${({ $hasCustomBackground }) =>
      $hasCustomBackground ||
      `url(${moneyBackBackground}) center center no-repeat`};
    border-radius: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  `,
  Content: styled.div`
    padding: 22px 12px 24px;
    border: 2px solid ${Color.PRIMARY};
    border-radius: 16px;
  `,
  Text: styled.p`
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -68px;
    right: 0;
    width: 92px;
  `,
}
