import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBodyAge = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Picture: styled.img`
    display: block;
    margin-bottom: 40px;
  `,
  Card: styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Icon: styled.img`
    margin-right: 8px;
    max-width: 64px;
    height: auto;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DARK};
  `,
}
