import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import imageBg from 'assets/images/flow-belly/best-shape-bg.png'
import imageBgLegs from 'assets/images/flow-belly/best-shape-legs-bg.png'

import { Gender } from 'root-constants'

import { BestShapeStyles as S } from './BestShape.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const BestShape: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { isLegsFlow } = useCohortInfo()
  const { imageUrls } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const staticImgBg = useMemo(() => {
    if (isLegsFlow) return imageBgLegs

    return imageBg
  }, [isLegsFlow])

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage || staticImgBg
  }, [staticImgBg, imageUrls, gender])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container isLarge $bgImageUrl={imageUrl}>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.bestShape.title" />
      </PageTitle>

      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          theme={answerTheme.DANCEBIT}
          maxWidth="180px"
          margin="0 0 16px 0"
          disabled={isAnswersDisabled}
          value={value}
          key={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
