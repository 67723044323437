import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Progress } from 'components/Progress'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import checkIcon from 'assets/images/sprite/check-icon-age-flow.svg'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledProgressAnalyzer as S } from './ProgressAnalyzer.styles'

type TProps = {
  value: number
  color: string
  isInProgress?: boolean
  isFinished?: boolean
  children?: React.ReactNode
}

export const ProgressAnalyzer: React.FC<TProps> = ({
  color,
  value,
  isInProgress,
  isFinished,
  children,
}) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  return (
    <S.Wrapper>
      <S.Description $isInProgress={isInProgress} $isFinished={isFinished}>
        <S.Text>{children}</S.Text>
        <S.Value>
          {value < 100 ? (
            `${value}%`
          ) : (
            <SvgImage svg={checkIcon} width={20} height={24} />
          )}
        </S.Value>
      </S.Description>
      <S.ProgressWrapper>
        <Progress
          $background={isAgeFlow ? 'rgba(3, 170, 245, 0.2)' : '#f5f6f7'}
          color={color}
          value={value}
          max={100}
        />
      </S.ProgressWrapper>
    </S.Wrapper>
  )
}
