import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectHasUpsellOffer } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)

  const { steps, currentSubscriptionPageId } = useGetPageInfo()

  const isUpsellAvailable = useMemo(
    () =>
      hasUpsellOffer &&
      userStatus?.upsell.isUpsellAvailable &&
      !userStatus?.upsell.isUpsellTried,
    [hasUpsellOffer, userStatus],
  )

  if (userStatus?.account.hasAccount) {
    return PageId.DOWNLOAD
  }

  if (
    (hasUpsellOffer && userStatus?.upsell.isUpsellPurchased) ||
    (userStatus?.hasSubscription && !isUpsellAvailable)
  ) {
    return PageId.LOGIN
  }

  if (userStatus?.hasSubscription) {
    return PageId.UPSELL
  }

  if (userStatus?.email.hasEmail && userStatus?.email.isEmailConsentTried) {
    return currentSubscriptionPageId || steps[0].id
  }

  if (userStatus?.email.hasEmail && !userStatus?.email.isEmailConsentTried) {
    return PageId.EMAIL_CONSENT
  }

  return ''
}
