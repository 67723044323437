import React, { FC, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { SvgImage } from 'components/SvgImage'

import { selectUserName } from 'root-redux/selects/common'

import { MONTH_CODE } from 'modules/purchase/components/PromoCodeWithTimer/constant'
import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import colonIcon from 'assets/images/sprite/colon-icon.svg'
import greenMark from 'assets/images/sprite/green-mark.svg'
import promocodeIcon from 'assets/images/sprite/promo-code-icon.svg'

import { StyledPromoCodeWithTimer as S } from './PromoCodeWithTimer.styles'

export const PromoCodeWithTimer: FC = () => {
  const { t } = useTranslation()

  const name = useSelector(selectUserName)

  const timerElementHourRef = useRef<HTMLSpanElement>(null)
  const timerElementMinRef = useRef<HTMLSpanElement>(null)
  const timerElementSecRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
    }
  }, [])

  const promocode = (() => {
    const year = dayjs().year().toString().substring(2)
    const month = dayjs().month()

    const formattedName = name.replace(' ', '_')
    const userName =
      formattedName.length < 11 ? formattedName : formattedName.substring(0, 8)

    return `${userName}_${MONTH_CODE[month]}${year}`
  })()

  return (
    <S.TimerContainer>
      <S.Title>
        <SvgImage svg={promocodeIcon} width={24} />
        <Trans i18nKey="purchaseWithoutIntro.promoApplied" />
      </S.Title>
      <S.TextContainer>
        <S.Promocode>
          <SvgImage svg={greenMark} width={24} />
          {promocode}
        </S.Promocode>
        <S.Timer>
          <S.TimerSign>
            <strong ref={timerElementMinRef} />
            <span>{t('commonComponents.minute')}</span>
          </S.TimerSign>
          <SvgImage svg={colonIcon} width={3} height={32} fill="#50ab64" />
          <S.TimerSign>
            <strong ref={timerElementSecRef} />
            <span>{t('commonComponents.second')}</span>
          </S.TimerSign>
        </S.Timer>
      </S.TextContainer>
    </S.TimerContainer>
  )
}
