import React, { useLayoutEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import arrowSvg from 'assets/images/sprite/arrow.svg'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { Cohort, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledChooseAge as S } from './ChooseAge.styles'
import { OPTIONS, OPTIONS_MENOPAUSE, QUESTION } from './constants'

export const ChooseAge: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const [answer, setAnswer] = useState<string>('')
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)

  const { search } = useLocation()
  const { isMenopauseFlow } = useCohortInfo()
  const hasWeightLossPlanScreen = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1206)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const pagePath = getPathFromPageId({
    pageId: PageId.TEN_MILLION_PEOPLE,
    cohort,
    uuid,
    language,
    currentSearch: search,
  })

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: cohort === Cohort.DB_MENOPAUSE ? nextPagePath : pagePath,
  })

  const options = useMemo(() => {
    if (isMenopauseFlow) return OPTIONS_MENOPAUSE

    return OPTIONS
  }, [isMenopauseFlow])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        setAnswer(value)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  useLayoutEffect(() => {
    if (!hasWeightLossPlanScreen && cohort !== Cohort.DB_MENOPAUSE) {
      replaceHistory(pagePath)
    }
  }, [cohort, hasWeightLossPlanScreen, pagePath])

  return (
    <>
      <S.Container isLarge>
        <PageTitle marginBottom={8} marginTop={16}>
          <Trans i18nKey="onboarding.welcomeV2.chooseAgeGroup" />
        </PageTitle>
        <S.Actions>
          {options.map(({ value, text, imageUrl }) => (
            <S.StyledOption
              {...optionProps}
              key={value}
              value={value}
              $isChecked={answer === value}
              disabled={isAnswersDisabled}
            >
              <S.Image
                $isChecked={answer === value}
                src={imageUrl}
                alt={value}
              />
              <S.Action $isChecked={answer === value}>
                <Trans>{text}</Trans> <SvgImage svg={arrowSvg} />
              </S.Action>
            </S.StyledOption>
          ))}
        </S.Actions>
      </S.Container>
    </>
  )
}
