import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { Color, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledEnergyBelly as S } from './EnergyBelly.styles'
import {
  OPTION_VALUES_ENERGY_BELLY,
  OPTION_VALUES_JAPANESE,
  QUESTION,
} from './constants'

export const EnergyBelly: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { optionData } = useDynamicOBConfig()
  const { isNewAdultFlow } = useCohortInfo()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isJapaneseFlow || isNewAdultFlow) return OPTION_VALUES_JAPANESE

    return OPTION_VALUES_ENERGY_BELLY
  }, [isJapaneseFlow, isNewAdultFlow, optionData])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={24}>
        {t('onboarding.energyBelly.title')}
      </PageTitle>

      {options.map(({ value, text, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          value={value}
          theme={theme}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          iconAlignSelf="center"
          margin="0 0 16px 0"
          padding="16px 7px 16px 16px"
          height="80px"
          color={Color.DARK}
          key={value}
          disabled={isAnswersDisabled}
          iconSrc={imageUrl}
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
