import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { PageDescription } from 'components/PageDescription'

import { Color } from 'root-constants'

export const LifestyleStyles = {
  PageDescription: styled(PageDescription)`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  AnswerDescription: styled.span`
    color: ${Color.GRAY_60};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
  `,
  ComplexAnswer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Container: styled(Container)<{ $isJapanese: boolean; $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}
  `,
}
