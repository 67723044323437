import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledDynamicPage as S } from './styles'

export const OptionsLeftToRight: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  } = useDynamicPage(props)

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  return (
    <>
      <S.Container
        $isAgeFlow={isAgeFlow}
        $hasNavigationBtn={optionType === OptionType.CHECKBOX}
      >
        <PageTitle marginBottom={subtitle ? 12 : 24} isAgeFlow={isAgeFlow}>
          {title}
        </PageTitle>
        {!!subtitle && (
          <S.Subtitle $isAgeFlow={isAgeFlow}>{subtitle}</S.Subtitle>
        )}

        {optionType === OptionType.RADIO &&
          optionData?.map(
            ({
              id,
              value,
              text,
              additionalText,
              imageUrl,
              imageWidth,
              imageHeight,
            }) => (
              <Option
                {...optionProps}
                type={OptionType.RADIO}
                key={id}
                value={value}
                disabled={isAnswersDisabled}
              >
                <AnswerButton
                  iconSrc={imageUrl}
                  iconWidth={imageWidth || 24}
                  iconHeight={imageHeight || 24}
                  isAgeFlow={isAgeFlow}
                >
                  {text}
                  {additionalText && (
                    <S.Text $isAgeFlow={isAgeFlow}>{additionalText}</S.Text>
                  )}
                </AnswerButton>
              </Option>
            ),
          )}

        {optionType === OptionType.CHECKBOX &&
          optionData?.map(
            ({
              id,
              value,
              text,
              additionalText,
              imageUrl,
              imageWidth,
              imageHeight,
            }) => (
              <Option
                {...optionProps}
                type={OptionType.CHECKBOX}
                key={id}
                value={value}
                checked={answers.includes(value)}
              >
                <AnswerButton
                  iconSrc={imageUrl}
                  iconWidth={imageWidth || 24}
                  iconHeight={imageHeight || 24}
                  withCheckbox
                  isAgeFlow={isAgeFlow}
                >
                  <S.Title $isAgeFlow={isAgeFlow}>{text}</S.Title>
                  <S.Text $isAgeFlow={isAgeFlow}>{additionalText}</S.Text>
                </AnswerButton>
              </Option>
            ),
          )}
      </S.Container>

      {optionType === OptionType.CHECKBOX && (
        <NavigationButton
          isAgeFlow={isAgeFlow}
          type="button"
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          {buttonText}
        </NavigationButton>
      )}
    </>
  )
}
