import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import map from 'assets/images/purchaseNoOnboarding/world-map-min.png'

import { StyledWorldWideUsers as S } from './WorldWideUsers.styles'

export const WorldWideUsers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>{t('purchaseNoOnboarding.worldWideUsers.title')}</S.Title>
      <S.Picture>
        <img src={map} alt="" />
        <S.Description>
          <Trans
            i18nKey="purchaseNoOnboarding.worldWideUsers.description"
            components={[<strong />]}
          />
        </S.Description>
      </S.Picture>
    </S.Root>
  )
}
