import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { ProgressDate } from 'components/ProgressDate'
import { ReachGraphBelly } from 'components/ReachGraphBelly'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentUserMotivationEvent,
  selectLanguage,
} from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useNextStep } from 'hooks/useNextStep'

import {
  DAY_MONTH_FORMAT,
  DEFAULT_DATE_FORMAT,
  LOCALIZED_DATE_FORMAT,
  getDatesArray,
} from 'helpers/datesForGraph'

import { TPageProps } from 'models/common.model'

import { StylesReach as S } from './Reach.styles'
import { MOTIVATING_EVENTS, NO_MOTIVATING_EVENT, QUESTION } from './constants'

export const Reach: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const motivationEvent = useSelector(selectCurrentUserMotivationEvent)
  const language = useSelector(selectLanguage)

  const {
    goalWeight,
    currentWeight,
    goalMonthAndDay,
    goalDate,
    eventDate,
    weightDifference,
  } = useEventWeightInfo()

  const hasMotivationEvent = useMemo(
    () => motivationEvent !== NO_MOTIVATING_EVENT && !!eventDate,
    [eventDate, motivationEvent],
  )

  const unit = useMemo(() => t(`commonComponents.${measurementSystem}`), [
    measurementSystem,
    t,
  ])

  const isEventBeforePredictableDay = useMemo(
    () => goalDate.diff(dayjs(eventDate, DEFAULT_DATE_FORMAT), 'day') > 0,
    [goalDate, eventDate],
  )

  const eventDateFormatted = useMemo(
    () =>
      dayjs(goalDate, DEFAULT_DATE_FORMAT).year() > dayjs().year()
        ? LOCALIZED_DATE_FORMAT
        : DAY_MONTH_FORMAT,
    [goalDate],
  )

  const lostWeightToEvent = useMemo(
    () =>
      !!eventDate && isEventBeforePredictableDay
        ? weightDifference
        : Number(currentWeight) - Number(goalWeight),
    [
      currentWeight,
      eventDate,
      goalWeight,
      isEventBeforePredictableDay,
      weightDifference,
    ],
  )

  const weight = useMemo(() => `${goalWeight}\u00A0${unit}`, [goalWeight, unit])

  const datesArray = useMemo(
    () => getDatesArray(goalDate, language, eventDateFormatted, false),
    [eventDateFormatted, goalDate, language],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])
  return (
    <>
      <ContainerBeforePinnedButton>
        <Container>
          <S.Title>
            <Trans
              i18nKey="onboarding.reach.title"
              values={{
                weight,
              }}
              components={{
                progressDate:
                  datesArray.length > 1 ? (
                    <ProgressDate dates={datesArray} />
                  ) : (
                    <>{goalMonthAndDay}</>
                  ),
                strong: <strong />,
              }}
            />
          </S.Title>

          <S.Badge $hasMotivationEvent={hasMotivationEvent}>
            {hasMotivationEvent ? (
              <Trans
                i18nKey="onboarding.reach.youWillLoseWithMotivation"
                values={{
                  event: t(MOTIVATING_EVENTS[motivationEvent]),
                  weight: Number(lostWeightToEvent).toFixed(0),
                  unit,
                }}
              />
            ) : (
              <Trans
                i18nKey="onboarding.reach.youWillLose"
                values={{
                  weight: Number(lostWeightToEvent).toFixed(0),
                  unit,
                }}
              />
            )}
          </S.Badge>

          <ReachGraphBelly />

          <S.Description>{t('onboarding.reach.description')}</S.Description>
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t('actions.continue')}
      </NavigationButtonBelly>
    </>
  )
}
