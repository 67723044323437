import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { SubscriptionItemVariant2 } from '../SubscriptionItemVariant2'
import { StyledSubscriptionsBlockV2 as S } from './SubscriptionsBlockV2.styles'

type TProps = {
  children: React.ReactNode
  disclaimerVisible?: boolean
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
}

export const SubscriptionsBlockV2: FC<TProps> = ({
  children,
  disclaimerVisible = true,
  elemForComparisonRef,
}) => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItemVariant2
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}
      <div ref={elemForComparisonRef}>{children}</div>

      {disclaimerVisible && <S.SubscriptionDisclaimer />}
    </>
  )
}
