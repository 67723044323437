import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers, selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import resultGenderFemalePng from 'assets/images/result-gender-female.png'
import resultGenderFemaleWebp from 'assets/images/result-gender-female.webp'
import resultGenderMalePng from 'assets/images/result-gender-male.png'
import resultGenderMaleWebp from 'assets/images/result-gender-male.webp'

import { PageId } from 'page-constants'
import { Gender, MAIN_GOALS } from 'root-constants'

import { StyledIntroMedia as S } from './IntroMedia.styles'

export const IntroMedia: React.FC = () => {
  const userAnswers = useSelector(selectAnswers)
  const isStayFit = useSelector(selectIsStayFitFlow)

  const { title } = useDynamicPaywallConfig()

  const isMale = useMemo(() => userAnswers?.[PageId.GENDER] === Gender.MALE, [
    userAnswers,
  ])

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.Title>
          {title || (
            <Trans
              i18nKey="purchase1.introMediaTitle"
              components={[<br />]}
              values={{
                context: isStayFit
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          )}
        </S.Title>
      </S.TitleContainer>
      <S.MainImageContainer>
        <S.MainImage>
          <source
            srcSet={isMale ? resultGenderMaleWebp : resultGenderFemaleWebp}
            type="image/webp"
          />
          <img
            src={isMale ? resultGenderMalePng : resultGenderFemalePng}
            alt="body"
          />
        </S.MainImage>
      </S.MainImageContainer>
    </S.Wrapper>
  )
}
