import { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

export const ThemeTimerConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background: ${Color.BLACK};
  `,
}
