import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CURRENCY_SYMBOLS,
  Currency,
  GeneralProduct,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { Cohort, TRIAL_COHORT } from 'root-constants'

import { StyledSubscriptionItemWithLabel as S } from './SubscriptionItemWithLabel.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemWithLabel: FC<TProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { periodQuantity, oldPrices, weekly, fullPrice },
    trialPrices,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const isTrialCohort = useMemo(
    () => TRIAL_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isSevenDayPlan = useMemo(() => product === GeneralProduct.SEVEN_DAY, [
    product,
  ])
  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  return (
    <S.Wrapper key={id}>
      <S.Content $isSelected={isSelected}>
        <S.PlanContainer>
          <S.PlanPeriod>
            {isSevenDayPlan ? (
              <Trans
                i18nKey="purchaseIntroOffer.weeklyPeriod"
                values={{ trialPeriodDays: trialPrices?.durationDays }}
                components={[<br />]}
              />
            ) : (
              <Trans
                i18nKey="purchaseIntroOffer.monthlyPeriod"
                values={{
                  count: trialPrices?.durationDays
                    ? Math.round(
                        trialPrices?.durationDays / TRIAL_ONE_MONTH_DURATION,
                      )
                    : periodQuantity,
                }}
                components={[<br />]}
              />
            )}
          </S.PlanPeriod>

          <S.FullPrices>
            <S.PreviousPriceCrossed>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(
                    isTrialCohort
                      ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice
                      : oldPrices.beforeCustomDiscount?.fullPrice,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                }}
              />
            </S.PreviousPriceCrossed>
            <S.PreviousPrice>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(
                    isTrialCohort ? trialPrices?.fullPrice : fullPrice,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                }}
              />
            </S.PreviousPrice>
          </S.FullPrices>
        </S.PlanContainer>

        <S.PriceContainer>
          <S.PreviousPriceCrossed>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: getCurrentPrice(
                  isTrialCohort
                    ? trialPrices.oldPrices.beforeCustomDiscount?.daily
                    : oldPrices.beforeCustomDiscount?.weekly,
                ),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.PreviousPriceCrossed>
          <S.CurrentPrice>
            <S.PriceValue $isMexican={currency === Currency.MXN}>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(
                    isTrialCohort ? trialPrices.daily : weekly,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                }}
              />
            </S.PriceValue>
            <S.Period>
              {t('purchase1.subscription.interval')}{' '}
              {isTrialCohort
                ? t('purchase1.subscription.day')
                : t('purchase1.subscription.week')}
            </S.Period>
          </S.CurrentPrice>
        </S.PriceContainer>
      </S.Content>
      {isSelected && (
        <S.SpecialOffer>
          {t('purchaseWithoutIntro.specialOffer', {
            discount: oldPrices.beforeCustomDiscount?.percentOfDiscount,
          })}
        </S.SpecialOffer>
      )}
    </S.Wrapper>
  )
}
