import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div<{ $margin?: string }>`
    position: relative;
    margin: ${({ $margin }) => $margin || '0 0 50px 0'};
    padding: 50px 20px 20px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    border-radius: 14px;
  `,
  Title: styled.h2`
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Text: styled.p`
    padding-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY_80};

    a {
      font-weight: 600;
      color: ${Color.PRIMARY};
      white-space: nowrap;
    }
  `,
  SecureBadge: styled.img`
    position: absolute;
    top: -30px;
    left: calc(50% - 88px);
    max-width: 176px;
  `,
  TermsLink: styled.a`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.PRIMARY};
    text-decoration: none;
  `,
}
