import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledMainGoalAgeFlow as S } from './MainGoalAgeFlow.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const MainGoalAgeFlow: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId: PageId.MAIN_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )
  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle marginBottom={10} isAgeFlow={isAgeFlow}>
          {t('onboarding.mainGoal.titleWithAge')}
        </PageTitle>
        <S.Subtitle>{t('onboarding.mainGoal.subtitleAgeFlow')}</S.Subtitle>
        {OPTION_VALUES.map(({ value, title }) => (
          <Option
            {...optionProps}
            key={value}
            value={value}
            checked={answers.includes(value)}
          >
            <AnswerButton withCheckbox isAgeFlow={isAgeFlow}>
              {t(title)}
            </AnswerButton>
          </Option>
        ))}
      </S.Container>

      <NavigationButton
        type="button"
        disabled={!answers.length}
        isAgeFlow={isAgeFlow}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </NavigationButton>
    </div>
  )
}
