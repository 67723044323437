import arms from 'assets/images/improve-arms-somatic.png'
import belly from 'assets/images/improve-belly-somatic.png'
import buttocks from 'assets/images/improve-buttocks-somatic.png'
import chest from 'assets/images/improve-chest-somatic.png'
import fullBody from 'assets/images/improve-full-body-somatic.png'
import hips from 'assets/images/improve-hips-somatic.png'
import legs from 'assets/images/improve-legs-somatic.png'

import { IMPROVE_AREAS_VALUES } from '../ImproveAreas/constants'

export const QUESTION = 'How do you feel about your grip of stress?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.improveAreas.fullBody',
    value: IMPROVE_AREAS_VALUES.FULL_BODY,
    imageUrl: fullBody,
  },
  {
    text: `onboarding.improveAreas.arms`,
    value: IMPROVE_AREAS_VALUES.ARMS,
    imageUrl: arms,
  },
  {
    text: 'onboarding.improveAreas.chest',
    value: IMPROVE_AREAS_VALUES.CHEST,
    imageUrl: chest,
  },
  {
    text: 'onboarding.improveAreas.belly',
    value: IMPROVE_AREAS_VALUES.BELLY,
    imageUrl: belly,
  },
  {
    text: 'onboarding.improveAreas.hips',
    value: IMPROVE_AREAS_VALUES.HIPS,
    imageUrl: hips,
  },
  {
    text: 'onboarding.improveAreas.buttocks',
    value: IMPROVE_AREAS_VALUES.BUTTOCKS,
    imageUrl: buttocks,
  },
  {
    text: 'onboarding.improveAreas.legs',
    value: IMPROVE_AREAS_VALUES.LEGS,
    imageUrl: legs,
  },
]
