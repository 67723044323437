import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledMainGoalAgeFlow = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{ $isAgeFlow: boolean }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
