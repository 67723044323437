import React, { ButtonHTMLAttributes } from 'react'

import { StyledLoadingButton as S } from './LoadingButton.styles'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void
}

export const LoadingButton: React.FC<IProps> = ({ onClick, ...props }) => (
  <S.LoadingButton {...props} onClick={onClick}>
    <div />
    <div />
    <div />
  </S.LoadingButton>
)
