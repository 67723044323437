import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDancingLevel = {
  Subtitle: styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;
  `,
  OptionTitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
  `,
  OptionDescription: styled.p`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  Container: styled(Container)<{ $isJapanese: boolean; $isAdult: boolean }>`
    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
}
