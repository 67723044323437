import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants'

import { StyledSeatedForwardBend as S } from './SeatedForwardBend.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const SeatedForwardBend: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { optionData } = useDynamicOBConfig()
  const { isNewAdultFlow } = useCohortInfo()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    return OPTION_VALUES
  }, [optionData])

  return (
    <S.Container isLarge $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={8}>
        {t('onboarding.seatedForwardBend.title')}
      </PageTitle>
      <S.Subtitle>{t('onboarding.seatedForwardBend.subtitle')}</S.Subtitle>
      {options.map(({ value, text, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          theme={
            isNewAdultFlow
              ? answerWithIconTheme.DANCEBIT_ADULT
              : answerWithIconTheme.DANCEBIT
          }
          margin="0 0 16px 0"
          borderRadius="16px"
          color={Color.DARK}
          iconWidth="84px"
          iconHeight="84px"
          iconMinWidth="84px"
          iconMinHeight="84px"
          key={value}
          value={value}
          iconSrc={imageUrl}
          iconAlignSelf="center"
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
