import React, { useEffect, useState } from 'react'

export const enum ViewportScrollPercentage {
  V_0 = 0,
  V_30 = 30,
  V_60 = 60,
  V_90 = 90,
  V_100 = 100,
}

export const useScrolledPercentage = (
  first: React.RefObject<HTMLElement>,
  second: React.RefObject<HTMLElement>,
  third: React.RefObject<HTMLElement>,
): number => {
  const [viewportValue, setViewportValue] = useState(
    ViewportScrollPercentage.V_0,
  )

  useEffect(() => {
    let currentViewportValue = 0

    const setIntermediateViewportValue = (
      value: ViewportScrollPercentage,
      removeEventListener: () => void,
    ) => {
      if (currentViewportValue < value) {
        currentViewportValue = value
        setViewportValue(value)
      }
      removeEventListener()
    }

    const setViewportScrollValue = (event: Event) => {
      event.stopPropagation()

      const removeEventListener = () => {
        if (currentViewportValue === ViewportScrollPercentage.V_100) {
          document.body.removeEventListener('scroll', setViewportScrollValue)
        }
      }

      const {
        offsetHeight: oneScreenHeight,
        scrollHeight: fullScrollHeight,
        scrollTop,
      } = event.target as HTMLBodyElement

      const currentViewportScroll = scrollTop + oneScreenHeight

      if (currentViewportScroll >= fullScrollHeight && scrollTop > 0) {
        setIntermediateViewportValue(
          ViewportScrollPercentage.V_100,
          removeEventListener,
        )
      }

      if (third.current && currentViewportScroll > third.current.offsetTop) {
        setIntermediateViewportValue(
          ViewportScrollPercentage.V_90,
          removeEventListener,
        )
      }

      if (second.current && currentViewportScroll > second.current.offsetTop) {
        setIntermediateViewportValue(
          ViewportScrollPercentage.V_60,
          removeEventListener,
        )
      }

      if (first.current && currentViewportScroll > first.current.offsetTop) {
        setIntermediateViewportValue(
          ViewportScrollPercentage.V_30,
          removeEventListener,
        )
      }
    }

    document.body.addEventListener('scroll', setViewportScrollValue)

    return () => {
      document.body.removeEventListener('scroll', setViewportScrollValue)
    }
  }, [first, second, third])

  return viewportValue
}
