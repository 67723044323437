import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { startFetching } from 'root-redux/actions/common'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { createProductId } from 'helpers/createProductId'

import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { PagePath } from 'root-constants'

import { CheckoutIntroOffer } from '../CheckoutIntroOffer'

export const CheckoutNoOnboarding: React.FC = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { cohort, email, selectedSubscription, uuid } = usePurchaseStore()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useLayoutEffect(() => {
    !selectedSubscription &&
      goTo({
        pathname: PageId.PURCHASE_NO_ONBOARDING,
        search,
      })
  }, [selectedSubscription, search])

  useEffect(() => {
    if (!selectedSubscription) return
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    const {
      mainPrices: { periodName, periodQuantity, fullPrice },
      currency,
    } = selectedSubscription as ISubscription

    window.fbq &&
      window.fbq(
        'track',
        'AddToCart',
        {},
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    window.pintrk &&
      window.pintrk('track', 'addtocart', {
        value: fullPrice,
        currency: currency.toUpperCase(),
        line_items: [
          {
            product_category: 'wellness',
            product_name: createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            product_id: createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            product_price: fullPrice,
          },
        ],
      })

    window.ttq &&
      window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.snaptr &&
      window.snaptr('track', 'ADD_CART', {
        user_email: isPersonalDataAllowed ? email : '',
      })
    window.obApi && window.obApi('track', 'Add To Cart')
    window.rdt &&
      window.rdt('track', 'AddToCart', {
        email: isPersonalDataAllowed ? email : '',
        externalId: isPersonalDataAllowed ? uuid : '',
        ...(!isPersonalDataAllowed && {
          em: '',
          uuid: '',
        }),
      })
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: 'add_to_cart',
      })

    googleAnalyticsLogger.logPageView(`${PagePath.CHECKOUT}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
  }, [
    cohort,
    dispatch,
    email,
    isPersonalDataAllowed,
    selectedSubscription,
    uuid,
  ])

  const handleSetIsCheckoutShown = useCallback(
    (isShown: boolean) => {
      if (!isShown) {
        goTo({
          pathname: PageId.PURCHASE_NO_ONBOARDING,
          search,
        })
      }
    },
    [search],
  )

  return (
    <CheckoutIntroOffer
      isCheckoutShown
      setIsCheckoutShown={handleSetIsCheckoutShown}
    />
  )
}
