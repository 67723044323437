import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'
import {
  selectHasEmail,
  selectIsEmailConsentTried,
} from 'root-redux/selects/user'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

import { StyledDownload as S } from './EmailConsent.styles'
import { QUESTION, USER_ANSWERS } from './constants'

export const EmailConsentBelly: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isEmailConsentTried = useSelector(selectIsEmailConsentTried)
  const hasEmail = useSelector(selectHasEmail)
  const language = useSelector(selectLanguage)
  const { isSomaticFlow } = useCohortInfo()

  useLayoutEffect(() => {
    if (hasEmail && isEmailConsentTried) {
      goTo({
        pathname: PageId.NAME,
        search,
      })
    }
  }, [search, isEmailConsentTried, hasEmail, language])

  useEffect(() => {
    eventLogger.logEmailConsentPageShown()
  }, [])

  const handleClick = useCallback(
    (value: boolean) => {
      goTo({
        pathname: PageId.NAME,
        search,
      })

      eventLogger.logQuestion({
        question: QUESTION,
        answers: value ? USER_ANSWERS.YES : USER_ANSWERS.NO,
      })

      dispatch(sendUserEmailConsentAction(value))
    },
    [dispatch, search],
  )

  if (hasEmail && isEmailConsentTried) return null

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container>
          <S.Title>
            <Trans
              i18nKey={
                isSomaticFlow
                  ? 'onboarding.emailConsent.titleSomatic'
                  : 'onboarding.emailConsent.titleBelly'
              }
            />
          </S.Title>
        </Container>
      </ContainerBeforePinnedButton>
      <NavigationButtonBelly
        type="button"
        onClick={() => handleClick(true)}
        additionalContent={
          <S.NoLinkBelly onClick={() => handleClick(false)}>
            {t('onboarding.emailConsent.no')}
          </S.NoLinkBelly>
        }
      >
        {t('onboarding.emailConsent.yes')}
      </NavigationButtonBelly>
    </>
  )
}
