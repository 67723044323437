import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { ContactWithSupport } from 'components/ContactWithSupport'
import { Notification } from 'components/Notification'
import { Progress } from 'components/Progress'

import {
  getAutologinTokenAction,
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCommonPWADownloadLinks,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
  selectPWADownloadLinks,
} from 'root-redux/selects/common'
import {
  selectAutologinToken,
  selectUserLoginEmail,
} from 'root-redux/selects/user'

import { useProgressAnimation } from 'hooks/useProgressAnimation'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import checkIcon from 'assets/images/sprite/check-icon-rounded.svg'
import copyImg from 'assets/images/sprite/copy.svg'

import {
  ADULT_COHORT,
  BELLY_COHORTS,
  CDN_FOLDER_LINK,
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  Cohort,
  Color,
  GROWTHBOOK_EXPERIMENT,
  PagesSource,
  PlatformOS,
  ScreenId,
} from 'root-constants'

import { StyledDownload as S } from './Download.styles'
import {
  BG_IMAGE,
  LOGIN_METHOD_INSTRUCTION_LINK,
  POPUP_DISAPPEARANCE_TIME,
  START_IMAGE,
} from './constants'

export const Download: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userEmail = useSelector(selectUserLoginEmail)
  const language = useSelector(selectLanguage)
  const autologinToken = useSelector(selectAutologinToken)
  const cohortDownloadLinks = useSelector(selectPWADownloadLinks)
  const dataBaseDownloadLinks = useSelector(selectCommonPWADownloadLinks)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = (parentCohort || cohort) as Cohort

  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID
  const OS = isAndroid ? 'android' : 'ios'

  const hasDownloadPageTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1281)

  const [showModal, setShowModal] = useState(false)
  const [showButton, setShowButton] = useState(false)

  const imageLink = useMemo(() => {
    if (hasDownloadPageTest) {
      return `${CDN_FOLDER_LINK}${BG_IMAGE}-${OS}_${language}.png`
    }

    return `${CDN_FOLDER_LINK}${START_IMAGE}_${language}.jpg`
  }, [OS, hasDownloadPageTest, language])

  const urlParam = useMemo(() => {
    if (isAndroid) return ''

    if (ADULT_COHORT.includes(cohortToUse)) {
      return '90d97d9c-83ed-4226-8404-5d26cf399593'
    }

    if (BELLY_COHORTS.includes(cohortToUse)) {
      return '66003082-4197-4564-86c5-8aae23882d61'
    }

    return ''
  }, [isAndroid, cohortToUse])

  const downloadAppLink = useMemo(() => {
    const defaultLink = urlParam
      ? `${LOGIN_METHOD_INSTRUCTION_LINK}&af_ios_store_cpp=${urlParam}`
      : LOGIN_METHOD_INSTRUCTION_LINK

    const autologinLink =
      dataBaseDownloadLinks?.[OS] || cohortDownloadLinks?.[OS] || defaultLink

    return autologinLink.replace(
      CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
      autologinToken || '',
    )
  }, [OS, autologinToken, cohortDownloadLinks, dataBaseDownloadLinks, urlParam])

  useEffect(() => {
    eventLogger.logGettingAppShown(
      hasDownloadPageTest ? ScreenId.AUTOMATIC_LOADING : ScreenId.MANUAL_CLICK,
    )

    dispatch(sendUserAnswersAction(null, true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
      }),
    )
    dispatch(getAutologinTokenAction())
  }, [dispatch, hasDownloadPageTest])

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(userEmail).then(() => {
      setShowModal(true)

      eventLogger.logEmailCopy()

      setTimeout(() => setShowModal(false), POPUP_DISAPPEARANCE_TIME)
    })
  }, [userEmail])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    (number: number, buttonText: string) => {
      googleAnalyticsLogger.logDownloadButtonPressed()
      eventLogger.logDownloadClicked(handleContinue, number, buttonText)
    },
    [handleContinue],
  )

  const handleAutoredirect = useCallback(() => {
    window.location.href = downloadAppLink
    eventLogger.logRedirectionToStore()
    setShowButton(true)
  }, [downloadAppLink])

  const { counter, progressAnimationPlayback } = useProgressAnimation({
    duration: 3000,
    callback: handleAutoredirect,
  })

  useEffect(() => {
    hasDownloadPageTest && progressAnimationPlayback()
  }, [hasDownloadPageTest, progressAnimationPlayback])

  if (hasDownloadPageTest) {
    return (
      <>
        <S.Container isLarge>
          <S.PageTitle>
            <Trans
              i18nKey="gettingApp.redirectTitle"
              values={{ context: OS }}
            />
          </S.PageTitle>

          <S.ImageWrapper>
            <S.AppImg src={imageLink} />

            <S.Bottom>
              {showButton ? (
                <S.Button
                  type="button"
                  data-deep-link={downloadAppLink}
                  onClick={() => handleClick(1, 'download_dancebit')}
                >
                  {t(
                    isAndroid
                      ? `gettingApp.goToPlayMarket`
                      : `gettingApp.goToAppStore`,
                  )}
                </S.Button>
              ) : (
                <S.ProgressWrapper>
                  <Progress
                    $background={Color.WHITE}
                    color={Color.PRIMARY}
                    value={counter}
                    max={100}
                  />
                </S.ProgressWrapper>
              )}
            </S.Bottom>
          </S.ImageWrapper>
        </S.Container>
      </>
    )
  }

  return (
    <>
      <S.Wrapper>
        <S.Title>{t`gettingApp.title`}</S.Title>

        <S.List>
          <S.ListTitle>{t`gettingApp.howDownload.title`}</S.ListTitle>
          <S.ListItem>
            <S.Text>
              <Trans
                i18nKey="gettingApp.howDownload.firstStep"
                components={{
                  button: (
                    <button
                      type="button"
                      data-deep-link={downloadAppLink}
                      onClick={() => handleClick(1, 'download_dancebit')}
                    >{t`login.app`}</button>
                  ),
                }}
              />
            </S.Text>
          </S.ListItem>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.howDownload.secondStep" />
            </S.Text>
          </S.ListItem>
        </S.List>

        <S.ImageContainer>
          <S.Image
            src={imageLink}
            data-deep-link={downloadAppLink}
            onClick={() => handleClick(2, 'get_started')}
          />
        </S.ImageContainer>

        <S.ListWithBorder>
          <S.ListTitle>{t`gettingApp.notLogged.title`}</S.ListTitle>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.notLogged.firstStep" />
            </S.Text>
          </S.ListItem>
          <S.ListItem>
            <S.Text>
              <Trans i18nKey="gettingApp.notLogged.secondStep" />
            </S.Text>
          </S.ListItem>

          <S.CopyEmail>
            <S.UserEmail>
              <S.Label>{t`gettingApp.notLogged.email`}</S.Label>
              <S.Email type="text" value={userEmail} readOnly />
            </S.UserEmail>

            <S.CopyButton onClick={handleCopy}>
              <S.SvgIcon
                svg={showModal ? checkIcon : copyImg}
                width={20}
                height={20}
              />
              <Notification text={showModal ? t`gettingApp.copy` : ''} />
            </S.CopyButton>
          </S.CopyEmail>

          <S.ListItem>
            <S.Text>
              <Trans
                i18nKey="gettingApp.notLogged.thirdStep"
                components={{
                  linkTag: (
                    <ContactWithSupport
                      text={t`commonComponents.here`}
                      source={PagesSource.DOWNLOAD}
                    />
                  ),
                }}
              />
            </S.Text>
          </S.ListItem>
        </S.ListWithBorder>
      </S.Wrapper>

      <S.ButtonContainer>
        <S.Button
          type="button"
          data-deep-link={downloadAppLink}
          onClick={() => handleClick(3, 'download_dancebit')}
        >
          {t`gettingApp.buttonText`}
        </S.Button>
      </S.ButtonContainer>
    </>
  )
}
