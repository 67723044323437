import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import doctorAvatar from 'assets/images/doctor-avatar.png'
import quote from 'assets/images/sprite/quote.svg'

import { StyledQuotation as S } from './Quotation.styles'
import { QUESTION } from './constants'

export const Quotation: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const handleContinue = useNextStep({
    pageId,
    nextPagePath,
    question: QUESTION,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <Container isLarge>
          <S.QuoteContainer>
            <SvgImage svg={quote} width={21} height={16} />
          </S.QuoteContainer>
          <S.Quote>
            <Trans i18nKey="onboarding.quotation.quote" />
          </S.Quote>

          <S.AvatarBlock>
            <S.Image src={doctorAvatar} alt="Dr. Emma Caldwell" />

            <S.Label>
              <S.Name>
                <Trans i18nKey="onboarding.quotation.doctorName" />
              </S.Name>
              <S.Description>
                <Trans i18nKey="onboarding.quotation.description" />
              </S.Description>
            </S.Label>
          </S.AvatarBlock>
        </Container>
      </S.ContainerBeforePinnedButton>
      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </>
  )
}
