import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledFAQList as S } from './FAQList.styles'

type TProps = {
  className?: string

  hasNewQuestionIcon?: boolean
}

export const FAQList: React.FC<TProps> = ({
  className = '',
  hasNewQuestionIcon = false,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper className={className}>
      <S.Title>{t`purchase1.faqList.title`}</S.Title>
      <S.ListBody>
        <S.ListItem $hasNewQuestionIcon={hasNewQuestionIcon}>
          <S.ListItemTitle>{t`purchase1.faqList.questionFirst`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerFirst`}</S.ListItemText>
        </S.ListItem>
        <S.ListItem $hasNewQuestionIcon={hasNewQuestionIcon}>
          <S.ListItemTitle>{t`purchase1.faqList.questionSecond`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerSecond`}</S.ListItemText>
        </S.ListItem>
        <S.ListItem $hasNewQuestionIcon={hasNewQuestionIcon}>
          <S.ListItemTitle>{t`purchase1.faqList.questionThird`}</S.ListItemTitle>
          <S.ListItemText>{t`purchase1.faqList.answerThird`}</S.ListItemText>
        </S.ListItem>
      </S.ListBody>
    </S.Wrapper>
  )
}
