// eslint-disable-line max-lines
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import {
  EventLoggerInstanceName,
  LoginMethod,
  ScreenId,
  ScreenName,
} from 'root-constants'

export const enum Events {
  SESSION_STARTED = 'session_start',
  AGE_CHOOSED = 'bi_user_age',
  GENDER_CHOOSED = 'bi_user_gender',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  RESULT_PAGE_COMPLETED = 'results_page_completed',
  RESULT_PAGE_SHOW = 'results_page_show',
  UPGRADE_PAGE_SHOW = 'upgrade_plans_page_show',
  UPGRADE_PAGE_CLOSE = 'upgrade_plans_page_close',
  SALE_SCREEN_SHOW = 'plans_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  NEED_HELP = 'need_help_tap',
  TRIAL_PRICE_PAGE_SHOWN = 'trial_price_page_show',
  TRIAL_PRICE_PAGE_COMPLETED_SHOWN = 'trial_price_page_completed',
  PURCHASE_SHOW = 'subs_purchase_show',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_STARTED_PAYPAL = 'subs_purchase_started_paypal',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSED = 'subs_purchase_screen_close',
  UPSELL_PURCHASE_SHOW = 'upsell_purchase_show',
  UPSELL_SUBSCRIPTION_TOGGLE_ENABLE = 'upsell_subscription_toggle_enable',
  UPSELL_SUBSCRIPTION_TOGGLE_DISABLE = 'upsell_subscription_toggle_disable',
  UPSELL_CANCEL_POPUP_CLOSE = 'upsell_cancel_popup_close',
  UPSELL_CANCEL_POPUP_VISIBLE = 'upsell_cancel_popup_show',
  UPSELL_PAGE_SCROLLED = 'upsell_purchase_screen_scrolled',
  UPSELL_PURCHASE_BUTTON_TAP = 'upsell_purchase_button_tap',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  SUBSCRIPTION_DETAILS_SCREEN_SHOW = 'subscription_details_screen_show',
  CANCEL_INSTRUCTION_SELECTED = 'cancel_instruction_selected',
  CANCEL_REASON_SELECTED = 'cancel_reason_selected',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  PAYPAL_PAYMENT_POPUP_CLOSE = 'paypal_payment_popup_close',
  CANCEL_OFFER_PAGE_SHOW = 'cancel_offer_page_show',
  CANCEL_OFFER_MEAL_PLAN_PAGE_SHOW = 'cancel_offer_meal_plan_page_show',
  CANCEL_OFFER_PAGE_ANSWER = 'cancel_offer_page_answer',
  CANCEL_OFFER_PAGE_CLOSE = 'cancel_offer_page_close',
  PLANS_PAGE_BUTTON_TAP = 'plans_page_button_tap',
  PLANS_PAGE_SCREEN_SPIN_TAP = 'plans_page_screen_spin_tap',
  PLANS_PAGE_SCREEN_SUBSCRIPTION_OPEN = 'plans_page_screen_subscription_open',
  LIVE_CHAT_OPENED = 'live_chat_opened',
  LIVE_CHAT_CLOSED = 'live_chat_closed',
  EMAIL_CONSENT_SHOW = 'email_consent_page_show',
  EMAIL_COPIED = 'finish_account_copy_email_tap',
  CONTACT_SUPPORT = 'contact_support_tap',
  ACCOUNT_LOGIN_SHOW = 'account_login_screen_show',
  FIRST_PAGE_BUTTON_TAP = 'first_page_button_tap',
  FIRST_PAGE_SCROLLED = 'first_page_scrolled',
  PAGE_SCROLLED = 'plans_page_scrolled',
  COOKIES_BANNER_SHOW = 'cookies_banner_show',
  COOKIES_BANNER_SETTINGS_TAP = 'cookies_banner_settings_tap',
  COOKIES_ACCEPT_ALL_TAP = 'cookies_accept_all_tap',
  COOKIES_REJECT_ALL_TAP = 'cookies_reject_all_tap',
  COOKIES_SETTINGS_TOGGLE_ENABLE = 'cookies_settings_toggle_enable',
  COOKIES_SETTINGS_TOGGLE_DISABLE = 'cookies_settings_toggle_disable',
  COOKIES_SETTING_CONFIRM_CHOICES_TAP = 'cookies_setting_confirm_choices_tap',
  COOKIES_SETTINGS_SCREEN_CLOSE = 'cookies_settings_screen_close',
  COOKIES_SETTINGS_EXPAND_OPTION_TAP = 'cookies_settings_expand_option_tap',
  QUESTION_PAGE_BACK_TAP = 'question_page_back_tap',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()
      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted(): void {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logSelectedAge(age: string): void {
    const event = Events.AGE_CHOOSED
    const eventProperty = {
      age,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logSelectedGender(gender: string): void {
    const event = Events.GENDER_CHOOSED
    const eventProperty = {
      gender,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logFirstPageCompleted({ question, answers }: TQuestionPageParams): void {
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }
    this.logEventOrPushToQueue(
      Events.FIRST_PAGE_COMPLETED,
      eventProperty,
      false,
    )
  }

  logGrowthBookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    const event = Events.AB_SEGMENT
    const eventProperty = {
      growthbook_feature_key: experimentKey || 'experiment_id_not_set',
      ab_variant: getCurrentOnboardingVariant(variantId),
      ab_segment_name: variantName || 'no_name',
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({ question, answers }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logPayPalPaymentPopupClose(): void {
    const event = Events.PAYPAL_PAYMENT_POPUP_CLOSE
    this.logEventOrPushToQueue(event)
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logEmailConsentPageShown(): void {
    const event = Events.EMAIL_CONSENT_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logResultPageCompleted(): void {
    const event = Events.RESULT_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, {}, false)
  }

  logResultPageShown(): void {
    const event = Events.RESULT_PAGE_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logUpgradePageCompleted(
    screenName: ScreenName,
    goal: string,
    accountId?: string,
    accountName?: string,
  ): void {
    const event = Events.UPGRADE_PAGE_CLOSE

    const eventProperty = {
      screen_name: screenName,
      ob_goal: goal,
      ...(accountId && { stripe_account_id: accountId }),
      ...(accountName && {
        stripe_account_name: accountName,
      }),
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpgradePageShown(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
    accountId?: string,
    accountName?: string,
  ): void {
    const event = Events.UPGRADE_PAGE_SHOW
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
      ...(accountId && { stripe_account_id: accountId }),
      ...(accountName && {
        stripe_account_name: accountName,
      }),
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName,
    screenId,
    isAmplitudeEvent = false,
  }: {
    productIds: string[]
    screenName: ScreenName
    screenId?: ScreenId
    isAmplitudeEvent?: boolean
  }): void {
    const event = Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: screenName,
      screen_id: screenId,
    }

    this.logEventOrPushToQueue(event, eventProperty, isAmplitudeEvent)
  }

  logTermsOfUseClicked(source: string): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event, { source })
  }

  logPrivacyPolicyClicked(source: string): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event, { source })
  }

  logContactSupportClicked(source: string): void {
    const event = Events.CONTACT_SUPPORT
    this.logEventOrPushToQueue(event, { source })
  }

  logAccountLoginShown(source: string): void {
    const event = Events.ACCOUNT_LOGIN_SHOW
    this.logEventOrPushToQueue(event, { source })
  }

  logNeedHelpClicked(): void {
    const event = Events.NEED_HELP
    this.logEventOrPushToQueue(event)
  }

  // Login Page events

  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_SHOW
    this.logEventOrPushToQueue(event)
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    const event = Events.ACCOUNT_CREATED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logEmailCopy(): void {
    const event = Events.EMAIL_COPIED
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(
    callback: () => void,
    buttonNumber: number,
    buttonText: string,
  ): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(
      event,
      {
        button_number: buttonNumber,
        button_text: buttonText,
      },
      true,
      callback,
    )
  }

  logFirstPageButtonClicked(buttonNumber: number, buttonText: string): void {
    const event = Events.FIRST_PAGE_BUTTON_TAP
    this.logEventOrPushToQueue(
      event,
      {
        button_number: buttonNumber,
        button_text: buttonText,
      },
      true,
    )
  }

  logLiveChatOpened(): void {
    this.logEventOrPushToQueue(Events.LIVE_CHAT_OPENED)
  }

  logLiveChatClosed(): void {
    this.logEventOrPushToQueue(Events.LIVE_CHAT_CLOSED)
  }

  // Purchase page events
  logPlansPageButtonTap({
    screenName,
    buttonNumber,
    buttonText,
  }: {
    screenName: ScreenName
    buttonNumber: string
    buttonText: string
  }): void {
    const event = Events.PLANS_PAGE_BUTTON_TAP
    const eventProperty = {
      screen_name: screenName,
      button_number: buttonNumber,
      button_text: buttonText,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logTrialPricePageShown(): void {
    const event = Events.TRIAL_PRICE_PAGE_SHOWN
    this.logEventOrPushToQueue(event)
  }

  logTrialPricePageCompleted({
    trialPrice,
    currency,
  }: {
    trialPrice: number
    currency: string
  }): void {
    const event = Events.TRIAL_PRICE_PAGE_COMPLETED_SHOWN
    const eventProperty = {
      trial_price: trialPrice,
      currency,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseShown({
    productId,
    screenName,
    screenId,
    accountId,
    accountName,
  }: {
    productId: TProductId
    screenName: ScreenName
    screenId: ScreenId
    accountId: string
    accountName: string
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      screen_id: screenId,
      stripe_account_id: accountId,
      stripe_account_name: accountName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPaymentMethodSelected(paymentMethod: PaymentMethod): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    screenName,
    screenId,
    isUpgraded = false,
    amountToPay,
    paymentSystem,
    accountId,
    accountName,
    goal,
  }: {
    productId: TProductId
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    paymentMethod: PaymentMethod
    screenName: ScreenName
    screenId: ScreenId
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem: PaymentSystem
    accountId?: string
    accountName?: string
    goal: string
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      payment_system: paymentSystem,
      ...(accountName && { stripe_account_name: accountName }),
      ...(accountId && { stripe_account_id: accountId }),
      screen_name: screenName,
      screen_id: screenId,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStartedPayPal({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    screenName,
    screenId,
    isUpgraded = false,
    amountToPay,
    paymentSystem,
    accountId,
    accountName,
    goal,
  }: {
    productId: TProductId
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    paymentMethod: PaymentMethod
    screenName: ScreenName
    screenId: ScreenId
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem: PaymentSystem
    accountId?: string
    accountName?: string
    goal: string
  }): void {
    const event = Events.PURCHASE_STARTED_PAYPAL
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.PAYPAL,
      payment_system: paymentSystem,
      ...(accountName && { stripe_account_name: accountName }),
      ...(accountId && { stripe_account_id: accountId }),
      screen_name: screenName,
      screen_id: screenId,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    productName,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    discountApplied,
    screenName,
    screenId,
    stripeAccountName,
    utmSource,
    isUpgraded = false,
    amountToPay,
    paymentSystem,
    accountId,
    goal,
  }: {
    productId: TProductId
    productName: string
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    paymentMethod?: PaymentMethod
    discountApplied?: string
    screenName: ScreenName
    screenId: ScreenId
    stripeAccountName?: string
    utmSource?: string
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem: PaymentSystem
    accountId?: string
    goal: string
  }): void {
    const event = Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      product_name: productName,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      payment_system: paymentSystem,
      screen_name: screenName,
      screen_id: screenId,
      ...(discountApplied && { discount_applied: discountApplied }),
      ...(stripeAccountName && {
        stripe_account_name: stripeAccountName,
      }),
      ...(accountId && { stripe_account_id: accountId }),
      ...(utmSource && { utm_source: utmSource }),
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency },
    error: { description, type, code },
    paymentMethod,
    screenName,
    screenId,
    isUpgraded = false,
    amountToPay,
    paymentSystem,
    accountId,
    accountName,
    goal,
  }: {
    productId: TProductId
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    error: {
      type: string
      error?: string
      declineCode?: string
      description?: string
      code?: string
    }
    paymentMethod?: PaymentMethod
    screenName: ScreenName
    screenId: ScreenId
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem: PaymentSystem
    accountId?: string
    accountName?: string
    goal: string
  }): void {
    const event = Events.PURCHASE_FAILED

    const eventProperty = {
      trial,
      price,
      currency,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      payment_system: paymentSystem,
      ...(accountName && {
        stripe_account_name: accountName,
      }),
      ...(accountId && { stripe_account_id: accountId }),
      screen_name: screenName,
      screen_id: screenId,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
    screenId,
  }: {
    productId: TProductId
    screenName: ScreenName
    screenId: ScreenId
  }): void {
    const event = Events.PURCHASE_SCREEN_CLOSED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      screen_id: screenId,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logSpinAndWinClicked(attemptNumber: number): void {
    this.logEventOrPushToQueue(Events.PLANS_PAGE_SCREEN_SPIN_TAP, {
      attempt_number: attemptNumber,
    })
  }

  logQuestionPageBackTap(): void {
    this.logEventOrPushToQueue(Events.QUESTION_PAGE_BACK_TAP, {}, false)
  }

  logPrizeSubscriptionShown(productId: string): void {
    this.logEventOrPushToQueue(Events.PLANS_PAGE_SCREEN_SUBSCRIPTION_OPEN, {
      product_id: productId,
    })
  }

  logCancelOfferPageShown(): void {
    const event = Events.CANCEL_OFFER_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logCancelOfferPageMealPlanShown(): void {
    const event = Events.CANCEL_OFFER_MEAL_PLAN_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logCancelOfferPageAnswer(answer: string): void {
    const event = Events.CANCEL_OFFER_PAGE_ANSWER
    const eventProperty = {
      answer,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logCancelOfferPageClosed(): void {
    const event = Events.CANCEL_OFFER_PAGE_CLOSE
    this.logEventOrPushToQueue(event)
  }

  // Upsell flow events

  logUpsellPurchaseShown(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
    accountId?: string,
    accountName?: string,
    discountApplied?: boolean,
  ): void {
    const event = Events.UPSELL_PURCHASE_SHOW
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
      discount_applied: discountApplied,
      ...(accountName && {
        stripe_account_name: accountName,
      }),
      ...(accountId && { stripe_account_id: accountId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellSubscriptionDisable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_DISABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellCancelPopupClosed(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
  ) {
    const event = Events.UPSELL_CANCEL_POPUP_CLOSE
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellCancelPopupVisible(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
  ) {
    const event = Events.UPSELL_CANCEL_POPUP_VISIBLE
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPageScrolled(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
    discountApplied: boolean,
    scrolledPart: string,
  ) {
    const event = Events.UPSELL_PAGE_SCROLLED
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
      discount_applied: discountApplied,
      scrolled_part: scrolledPart,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logFirstPageScrolled(scrolledPart: string) {
    const event = Events.FIRST_PAGE_SCROLLED
    const eventProperty = {
      scrolled_part: scrolledPart,
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logUpsellPurchaseButtonTap(
    screenName: ScreenName,
    screenId: ScreenId,
    goal: string,
    discountApplied: boolean,
    buttonNumber: number,
  ) {
    const event = Events.UPSELL_PURCHASE_BUTTON_TAP
    const eventProperty = {
      screen_name: screenName,
      screen_id: screenId,
      ob_goal: goal,
      discount_applied: discountApplied,
      button_number: buttonNumber,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellSubscriptionEnable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_ENABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logSubscriptionDetailsShown(): void {
    const event = Events.SUBSCRIPTION_DETAILS_SCREEN_SHOW
    this.logEventOrPushToQueue(event)
  }

  logPageScrolled(value: number, screenName: ScreenName) {
    const event = Events.PAGE_SCROLLED
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${value}%`,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentShown(): void {
    const event = Events.COOKIES_BANNER_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentSettingsTap(): void {
    const event = Events.COOKIES_BANNER_SETTINGS_TAP
    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentAcceptAllTap(source: string): void {
    const event = Events.COOKIES_ACCEPT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentRejectAllTap(source: string): void {
    const event = Events.COOKIES_REJECT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleEnable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_ENABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleDisable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_DISABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsConfirmChoice(options: string): void {
    const event = Events.COOKIES_SETTING_CONFIRM_CHOICES_TAP
    const eventProperty = { allowed_options: options }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsScreenClose(): void {
    const event = Events.COOKIES_SETTINGS_SCREEN_CLOSE

    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentExpandOptionTap(option: string): void {
    const event = Events.COOKIES_SETTINGS_EXPAND_OPTION_TAP
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
    isAmplitudeEvent = true,
    callback?: () => void,
  ): void {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        isAmplitudeEvent,
        eventProperty,
        callback,
      })
    } else {
      this.eventsQueue.push({
        event,
        eventProperty,
      })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    if (this.loggers) {
      const eventLoggers = [...this.loggers.values()]

      eventLoggers.forEach((logger, index, array) => {
        index === array.length - 1
          ? logger.log(event, eventProperty, callback)
          : logger.log(event, eventProperty)
      })
    }
  }
}

export const eventLogger = new EventLoggerService()
