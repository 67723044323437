/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { selectStripePaymentMethods } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'
import { getSubscriptionWithDynamicDiscounts } from 'helpers/getSubscriptionWithDynamicDiscounts'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { LinkPaymentButton } from 'modules/purchase/components/LinkPaymentButton'
import { PaymentElementButton } from 'modules/purchase/components/PaymentElementButton'
import { PaymentPrimerWaitingModal } from 'modules/purchase/components/PaymentPrimerWaitingModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  PaymentMethod,
  WALLET_PAYMENT_METHODS,
} from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  GET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_PAYMENT_FORM_IS_LOADING,
  paymentElementPurchaseAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ABC_TEST_VARIANTS,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  SEVEN_DAY_TRIAL_DURATION,
  ScreenName,
} from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import {
  EU_PM,
  PAYMENT_METHODS_IMAGES,
  PAYMENT_METHODS_TO_SHOW,
  USA_PM,
  WALLET_IMAGES,
  WW_PM,
} from './constants'

type TCheckoutExtended = {
  setIsCheckoutShown: (value: boolean) => void
  isCancelOfferPage?: boolean
  isCheckoutShown: boolean
}

const USA_COUNTRY_CODE = 'US'
const MINIMAL_AVAILABLE_PAYMENT_METHODS = 1

export const CheckoutSeparateMethods: React.FC<TCheckoutExtended> = ({
  isCheckoutShown,
  setIsCheckoutShown,
  isCancelOfferPage = false,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const allowedPaymentMethods = useSelector(selectStripePaymentMethods)

  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_8)

  const activeTestVariant = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_924,
    ABC_TEST_VARIANTS.VARIANT_A,
  )
  const isPPHidden = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1048)

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const hasPaypalButton = useHasPayPalButton()

  const paymentFormRef = useRef<HTMLDivElement>(null)

  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[] | PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [selectedPaymentMethodTab, setSelectedPaymentMethodTab] = useState<
    PaymentMethod
  >(PaymentMethod.CREDIT_CARD)

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    PaymentMethod[]
  >([PaymentMethod.CREDIT_CARD])

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const { isSaleFlow, isTrialCohort, isSevenDayPlanCohort } = useCohortInfo()

  const {
    uuid,
    subscriptions,
    periodName,
    periodQuantity,
    trialPrice,
    fullPrice,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
    discountAmount,
    hasCancelOffer,
    screenName,
    screenId,
    discountPercentage,
    selectedSubscription,
    fetchingActionsList,
    threeDSecureIframeUrl,
    countryCode,
    fullPriceTax,
    oldPriceBeforeCustomPercentDiscount,
    taxAmount,
    dynamicDiscount,
  } = usePurchaseStore()

  const { isEUUser } = useCookieConsentAnswer()

  const [isUpdated, setIsUpdated] = useState(false)

  const additionalPaymentMethods = useMemo(() => {
    if (isEUUser) return EU_PM

    if (countryCode === USA_COUNTRY_CODE) return USA_PM

    return WW_PM
  }, [countryCode, isEUUser])

  const isWWUser = useMemo(
    () => !isEUUser || countryCode !== USA_COUNTRY_CODE,
    [countryCode, isEUUser],
  )

  const hasAnyRequiredMethod = useMemo(
    () =>
      WALLET_PAYMENT_METHODS.some((method) =>
        allowedPaymentMethods.includes(method),
      ),
    [allowedPaymentMethods],
  )

  const isCardVisible = useMemo(
    () =>
      isCheckoutShown &&
      isScrolled &&
      selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD),
    [isCheckoutShown, isScrolled, selectedPaymentMethods],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(SET_PAYMENT_FORM_IS_LOADING) &&
      !fetchingActionsList.includes(GET_PRIMER_CLIENT_SESSION_TOKEN) &&
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialPrice, fullPrice],
  )

  const paymentMethodsTabs = useMemo(() => {
    if (
      activeTestVariant === ABC_TEST_VARIANTS.VARIANT_C &&
      !hasAnyRequiredMethod
    ) {
      return PAYMENT_METHODS_TO_SHOW[ABC_TEST_VARIANTS.VARIANT_A]
    }

    return PAYMENT_METHODS_TO_SHOW[activeTestVariant]
  }, [activeTestVariant, hasAnyRequiredMethod])

  const isSevenDayPlan = useMemo(
    () =>
      isSevenDayPlanCohort &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [isSevenDayPlanCohort, periodQuantity],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [countryCode, hasVatInfo, taxAmount],
  )

  const subscription = useMemo(() => {
    if (!selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount: 0,
        discountAmount,
      }
    }

    if (isSaleFlow) {
      return getSubscriptionWithDynamicDiscounts(
        selectedSubscription,
        dynamicDiscount,
        isCancelOfferPage,
        hasExcludedVat,
        getCurrentPrice,
      )
    }

    return {
      percentage: discountPercentage || 50,
      totalAmount:
        selectedSubscription.trialPrices.durationDays ===
        Number(SEVEN_DAY_TRIAL_DURATION)
          ? getCurrentPrice(
              selectedSubscription.trialPrices.oldPrices.beforeCustomDiscount
                ?.fullPrice,
            )
          : getCurrentPrice(
              isTrialCohort
                ? fullPrice
                : oldPriceBeforeCustomPercentDiscount.fullPrice,
            ),
      discountAmount: getCurrentPrice(
        isTrialCohort
          ? discountAmount
          : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
      ),
    }
  }, [
    isSaleFlow,
    selectedSubscription,
    discountPercentage,
    discountAmount,
    dynamicDiscount,
    isCancelOfferPage,
    hasExcludedVat,
    getCurrentPrice,
    isTrialCohort,
    fullPrice,
    oldPriceBeforeCustomPercentDiscount,
  ])

  const totalDiscount = useMemo(() => {
    const discountValue = isSaleFlow
      ? subscription.discountAmount
      : getCurrentPrice(
          isTrialCohort
            ? discountAmount
            : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
        )

    return `-${discountValue} ${currencyLabel}`
  }, [
    getCurrentPrice,
    isTrialCohort,
    oldPriceBeforeCustomPercentDiscount,
    currencyLabel,
    discountAmount,
    isSaleFlow,
    subscription,
  ])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  const addAvailablePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (availablePaymentMethods.includes(paymentMethod)) return

      setAvailablePaymentMethods([...availablePaymentMethods, paymentMethod])
    },
    [availablePaymentMethods],
  )

  useLayoutEffect(() => {
    if (!isPPHidden && hasPaypalButton) {
      addAvailablePaymentMethod(PaymentMethod.PAYPAL)
    }
  }, [hasPaypalButton, addAvailablePaymentMethod, isPPHidden])

  useEffect(() => {
    if (
      (!isWWUser || hasAnyRequiredMethod) &&
      PAYMENT_METHODS_TO_SHOW[activeTestVariant].includes(PaymentMethod.WALLETS)
    ) {
      addAvailablePaymentMethod(PaymentMethod.WALLETS)
      return
    }

    if (
      additionalPaymentMethods.length &&
      activeTestVariant !== ABC_TEST_VARIANTS.VARIANT_A
    ) {
      additionalPaymentMethods.forEach((pm) => {
        if (allowedPaymentMethods.includes(pm)) {
          addAvailablePaymentMethod(pm as PaymentMethod)
        }
      })
    }
  }, [
    hasPaypalButton,
    addAvailablePaymentMethod,
    additionalPaymentMethods,
    activeTestVariant,
    allowedPaymentMethods,
    isWWUser,
    hasAnyRequiredMethod,
  ])

  const handlePaymentMethodTabClick = useCallback(
    (paymentMethod: PaymentMethod) => {
      setIsScrolled(false)
      setSelectedPaymentMethodTab(paymentMethod)
      eventLogger.logPaymentMethodSelected(paymentMethod)

      if (paymentMethod === PaymentMethod.WALLETS) {
        const paymentMethodsToShow = allowedPaymentMethods.filter(
          (pm) => pm !== PaymentMethod.CREDIT_CARD,
        )

        if (isPPHidden) {
          setSelectedPaymentMethods([...paymentMethodsToShow])
        } else {
          setSelectedPaymentMethods([
            ...paymentMethodsToShow,
            PaymentMethod.PAYPAL,
          ])
        }

        return
      }

      setSelectedPaymentMethods([paymentMethod])
    },
    [allowedPaymentMethods, isPPHidden],
  )

  useEffect(() => {
    if (!availablePaymentMethods.length || !isCheckoutShown) return

    if (availablePaymentMethods.includes(PaymentMethod.APPLE_PAY)) {
      handlePaymentMethodTabClick(PaymentMethod.APPLE_PAY)
      return
    }

    if (availablePaymentMethods.includes(PaymentMethod.GOOGLE_PAY)) {
      handlePaymentMethodTabClick(PaymentMethod.GOOGLE_PAY)
      return
    }

    handlePaymentMethodTabClick(PaymentMethod.CREDIT_CARD)
  }, [
    isCheckoutShown,
    availablePaymentMethods,
    activeTestVariant,
    handlePaymentMethodTabClick,
  ])

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        screenId,
        accountId: stripeAccountId,
        accountName: stripeAccountName,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        isPersonalDataAllowed,
      })
    }
  }, [
    isPersonalDataAllowed,
    uuid,
    subscriptions,
    isCheckoutShown,
    productId,
    screenId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  ])

  useEffect(() => {
    const { current: elem } = paymentFormRef
    setIsScrolled(false)

    if (
      elem &&
      isCheckoutReady &&
      selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD)
    ) {
      elem.scrollIntoView({
        behavior: 'smooth',
      })
      setIsScrolled(true)
    }
  }, [isCheckoutReady, selectedPaymentMethods])

  const handlePaymentElementClick = async () => {
    if (!elements || !stripe) return

    const { error } = await elements.submit()

    if (error) {
      console.error(error)
    }

    dispatch(
      paymentElementPurchaseAction({
        stripe,
        elements,
      }),
    )
  }

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      screenId,
    })

    if (isCancelOfferPage || !hasCancelOffer) {
      setIsCheckoutShown(false)
      googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
      return
    }

    dispatch(setSubscriptionListAction([]))
    goTo({
      pathname: PageId.CANCEL_OFFER_QUESTION,
      search,
    })
  }, [
    dispatch,
    productId,
    screenName,
    screenId,
    isCancelOfferPage,
    hasCancelOffer,
    search,
    setIsCheckoutShown,
    pathname,
    cohort,
  ])

  const getPaymentMethodTabImageCover = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (paymentMethod !== PaymentMethod.WALLETS) {
        return PAYMENT_METHODS_IMAGES[paymentMethod]
      }

      if (isEUUser) return WALLET_IMAGES.EU

      if (countryCode === USA_COUNTRY_CODE) return WALLET_IMAGES.US

      return WALLET_IMAGES.WW
    },
    [countryCode, isEUUser],
  )

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer $isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {availablePaymentMethods.length > MINIMAL_AVAILABLE_PAYMENT_METHODS
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton onClick={handleCloseCheckout} />
          {availablePaymentMethods.length >
            MINIMAL_AVAILABLE_PAYMENT_METHODS && (
            <S.PaymentMethodsWrapper>
              {paymentMethodsTabs?.map((paymentMethod: PaymentMethod) => {
                if (!availablePaymentMethods.includes(paymentMethod)) {
                  return null
                }

                return (
                  <S.PaymentMethod
                    key={paymentMethod}
                    $isSelected={paymentMethod === selectedPaymentMethodTab}
                    onClick={() => handlePaymentMethodTabClick(paymentMethod)}
                  >
                    {paymentMethod === PaymentMethod.CREDIT_CARD && (
                      <S.PaymentMethodText>
                        {t('checkoutExtended.creditCard')}
                      </S.PaymentMethodText>
                    )}

                    {paymentMethod === PaymentMethod.WALLETS && (
                      <S.PaymentMethodText>
                        {t('checkoutExtended.wallets')}
                      </S.PaymentMethodText>
                    )}

                    <img
                      src={
                        getPaymentMethodTabImageCover(paymentMethod)[
                          paymentMethod === selectedPaymentMethodTab
                            ? 'active'
                            : 'inactive'
                        ]
                      }
                      width={
                        getPaymentMethodTabImageCover(paymentMethod).imageWidth
                      }
                      alt={paymentMethod}
                    />
                  </S.PaymentMethod>
                )
              })}
            </S.PaymentMethodsWrapper>
          )}

          <S.PriceDescription>
            <S.PriceInfo>
              <S.PriceBlockText>
                <Trans i18nKey="purchaseCancelOffer.checkout.personalizedPlan" />
              </S.PriceBlockText>
              <S.PriceBlockText>
                {subscription.totalAmount} {currencyLabel}
              </S.PriceBlockText>
            </S.PriceInfo>

            {!isSevenDayPlan && (
              <S.PriceInfo>
                <S.PriceBlockText>
                  {isSaleFlow && dynamicDiscount?.checkout ? (
                    <Trans
                      i18nKey="checkoutExtended.discount"
                      values={{
                        percentage: subscription.percentage,
                        checkout: dynamicDiscount.checkout,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="checkoutExtended.introductoryDiscount"
                      values={{
                        discountPercentage: subscription.percentage,
                      }}
                    />
                  )}
                </S.PriceBlockText>
                <S.TotalDiscount>{totalDiscount}</S.TotalDiscount>
              </S.PriceInfo>
            )}

            {hasExcludedVat && (
              <S.PriceInfo>
                <S.PriceBlockText>
                  {t(`purchase1.valueAddedTax`, { context: countryCode })}
                </S.PriceBlockText>
                <S.PriceBlockText>
                  {isSevenDayPlan || !isTrialCohort
                    ? fullPriceTax
                    : selectedSubscription?.trialPrices.fullPriceTax}{' '}
                  {currencyLabel}
                </S.PriceBlockText>
              </S.PriceInfo>
            )}
          </S.PriceDescription>

          <S.DailyPayment>
            <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
            <S.DailyAmount>
              {getCurrentPrice(
                isSevenDayPlan || !isTrialCohort
                  ? selectedSubscription.mainPrices.daily
                  : selectedSubscription.trialPrices.daily,
              )}{' '}
              {currencyLabel}
            </S.DailyAmount>
          </S.DailyPayment>

          <S.TotalPayment>
            <S.TotalDescription>
              <S.TotalText>{t('checkoutExtended.total')}</S.TotalText>
              <S.TotalAmount>
                <Trans
                  i18nKey="checkoutExtended.totalAmount"
                  values={{
                    totalAmount:
                      isSevenDayPlan || !isTrialCohort
                        ? fullPrice
                        : selectedSubscription?.trialPrices.fullPrice,
                    currency: currencyLabel,
                    periodQuantity,
                    context:
                      isSevenDayPlan || !isTrialCohort
                        ? periodQuantity
                        : selectedSubscription.trialPrices.durationDays,
                  }}
                />
              </S.TotalAmount>
            </S.TotalDescription>
            {!isSevenDayPlan && (
              <S.SaveText>
                <Trans
                  i18nKey="checkoutExtended.savedAmount"
                  values={{
                    savedAmount: subscription.discountAmount,
                    currency: currencyLabel,
                    discountPercentage: subscription.percentage,
                  }}
                />
              </S.SaveText>
            )}
          </S.TotalPayment>

          <S.PaymentFormWrapper ref={paymentFormRef}>
            <S.PaymentMethodContainer
              $isVisible={
                (selectedPaymentMethods.includes(PaymentMethod.CREDIT_CARD) &&
                  isPrimerActive) ||
                selectedPaymentMethods.includes(PaymentMethod.PAYPAL)
              }
            >
              {isCheckoutShown && (
                <PrimerPaymentForm
                  key={selectedSubscription?.id}
                  showPayPalButton={selectedPaymentMethods.includes(
                    PaymentMethod.PAYPAL,
                  )}
                  showCardForm={selectedPaymentMethods.includes(
                    PaymentMethod.CREDIT_CARD,
                  )}
                />
              )}
            </S.PaymentMethodContainer>

            {!isPrimerActive && (
              <S.PaymentMethodContainer
                $isVisible={selectedPaymentMethods.includes(
                  PaymentMethod.CREDIT_CARD,
                )}
              >
                <CreditCardFormCancelOffer isCheckoutVisible={isCardVisible} />
              </S.PaymentMethodContainer>
            )}

            {selectedPaymentMethods.map((paymentMethod) => (
              <S.PaymentMethodContainer
                key={paymentMethod}
                $isVisible={additionalPaymentMethods.includes(paymentMethod)}
              >
                <PaymentElementButton
                  paymentMethod={paymentMethod}
                  setIsUpdated={setIsUpdated}
                />
              </S.PaymentMethodContainer>
            ))}

            <S.PaymentMethodContainer
              $isVisible={
                selectedPaymentMethods.includes(PaymentMethod.GOOGLE_PAY) ||
                selectedPaymentMethods.includes(PaymentMethod.APPLE_PAY)
              }
            >
              <StripeSeparatePaymentRequestButton
                setAlternativePaymentMethodCallback={addAvailablePaymentMethod}
              />
            </S.PaymentMethodContainer>

            <S.PaymentMethodContainer
              $isVisible={selectedPaymentMethods.includes(PaymentMethod.LINK)}
            >
              <LinkPaymentButton
                setAlternativePaymentMethodCallback={addAvailablePaymentMethod}
              />
            </S.PaymentMethodContainer>
          </S.PaymentFormWrapper>

          {isUpdated && (
            <div
              style={{
                display: 'none',
              }}
            >
              <PaymentElement
                onReady={(element) => element.blur()}
                onChange={handlePaymentElementClick}
              />
            </div>
          )}

          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        {isPrimerActive ? (
          <PaymentPrimerWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        ) : (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
        )}
      </S.Root>
    )
  )
}
