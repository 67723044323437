import React, { FC } from 'react'

import { SvgImage } from 'components/SvgImage'

import arrowBackSvg from 'assets/images/sprite/arrow-back.svg'

import { goBack } from 'browser-history'

import { StyledBackButton as S } from './BackButton.styles'

type TProps = {
  className?: string
}

export const BackButton: FC<TProps> = ({ className }) => (
  <S.BackButton className={className} onClick={() => goBack()}>
    <SvgImage svg={arrowBackSvg} width={18.5} />
  </S.BackButton>
)
