import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Option } from 'components/Option'

import { BodyAge } from 'modules/purchase/components/BodyAge'

import wreath from 'assets/images/wreath.png'

import { Color } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledWelcomeWeightLoss = {
  Root: styled.div`
    padding-top: 32px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 88.53%,
          ${Color.WHITE} 100%
        ),
        linear-gradient(
          187deg,
          #7470ff 0%,
          #ecb8ff 48.6%,
          rgba(116, 113, 246, 0) 86.43%
        );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 6px rgba(11, 8, 79, 0.13);
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  `,
  Subtitle: styled.div`
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 6px rgba(11, 8, 79, 0.13);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 52px;

    strong {
      color: #ffd839;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    p {
      color: ${Color.WHITE};
      text-align: center;
      text-shadow: 0 2px 6px rgba(11, 8, 79, 0.13);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
  `,
  Arrow: styled.div`
    width: 45px;
    margin: 0 auto 24px;
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    border-radius: 12px;
    position: relative;
    background: ${Color.WHITE};
    box-shadow: 0 3px 13px 0 #d6dcf4;
    margin: 16px 0 2px;
    width: 156px;
    height: 165px;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        border: 2px solid ${Color.PRIMARY};
      `}
    &:last-child {
      margin-bottom: 2px;

      ${({ $isChecked }) =>
        $isChecked &&
        css`
          margin-bottom: 0;
        `}
    }

    &:nth-child(2n) {
      margin-left: 16px;
    }
  `,
  Image: styled.img<TRadioButtonProps>`
    position: absolute;
    bottom: 36px;
    left: 0;
    width: 156px;
    height: 140px;
    object-fit: cover;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        bottom: 34px;
      `}
  `,
  Action: styled.div<TRadioButtonProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 12px 12px;
    width: 156px;
    height: 36px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        bottom: -2px;
        left: -2px;
      `}
  `,
  Terms: styled.div`
    z-index: 2;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 16px;

    &:last-child {
      margin-top: 24px;
    }

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
  WeightLossCardContainer: styled.div`
    margin-bottom: 32px;
  `,
  Card: styled.div``,
  CardTitle: styled.h3`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 8px;
  `,
  CardSubtitle: styled.p`
    color: ${Color.DISABLED};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  `,
  CardImage: styled.img`
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 32px;
    }
  `,
  NumberOne: styled.span`
    margin-top: 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;

    & strong {
      font-size: 56px;
      font-weight: 600;
    }
  `,
  Wreath: styled.div`
    min-height: 182px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    background-image: url(${wreath});
    background-size: cover;
    background-repeat: no-repeat;
  `,
  Button: styled(Button)`
    padding: 0;
  `,
  Sponsors: styled.img`
    margin: 48px 0;
  `,
  BodyAgeV2: styled(BodyAge)`
    padding-top: 32px;
    padding-bottom: 8px;
    background-color: ${Color.LIGHT};

    li {
      background-color: transparent;
      padding: 16px 8px;
      flex-direction: row;
      align-items: flex-start;
    }
  `,
}
