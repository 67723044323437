import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  GeneralProduct,
  ONE_HUNDRED_PERCENTS,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import {
  selectDynamicDiscount,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { StyledSubscriptionItemIntroOffer as S } from './SubscriptionItemIntroOffer.styles'

export const SubscriptionItemIntroOffer: React.FC<TSubscriptionItemProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { periodQuantity, fullPrice, daily: mainDailyPrice },
    trialPrices: {
      fullPrice: trialCurrentPrice,
      daily: trialDailyPrice,
      durationDays,
      oldPrices,
    },
    isDefault,
    currency,
  },
  isSelected,
  isCancelOffer = false,
  isClearBackground = false,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const {
    isBellyFlow,
    isSaleFlow,
    isGiftFlow,
    isNewClearFlow,
    isSevenDayPlanCohort,
  } = useCohortInfo()

  const customPriceWithFractionalPart = useMemo(
    () => (trialDailyPrice || mainDailyPrice)?.toFixed(2),
    [trialDailyPrice, mainDailyPrice],
  )

  const hasMexicanCurrency = useMemo(() => currency === Currency.MXN, [
    currency,
  ])

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const isSevenDayPlan = useMemo(
    () =>
      isSevenDayPlanCohort &&
      periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION),
    [isSevenDayPlanCohort, periodQuantity],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value && !hasMexicanCurrency
        ? getCalculatedPrice(value, taxAmount, country)
        : value || '',

    [hasMexicanCurrency, country, hasVatInfo, taxAmount],
  )

  const getOldPrice = useCallback(
    ({
      price,
      isFormattedDiscount,
    }: {
      price: number
      isFormattedDiscount?: boolean
    }) => {
      const defaultDiscount =
        dynamicDiscount?.amount ||
        (isCancelOffer
          ? DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT
          : DEFAULT_DYNAMIC_DISCOUNT)

      const discount = isCancelOffer
        ? defaultDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : defaultDiscount

      const calculatedOldPrice =
        price / ((ONE_HUNDRED_PERCENTS - discount) / ONE_HUNDRED_PERCENTS)

      if (!isFormattedDiscount) {
        return (+getCurrentPrice(calculatedOldPrice)).toFixed(2)
      }

      return (
        Math.ceil(+getCurrentPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      ).toFixed(2)
    },
    [dynamicDiscount?.amount, getCurrentPrice, isCancelOffer],
  )

  const { oldDailyPrice, oldSubscriptionPrice } = useMemo(() => {
    if (isSaleFlow) {
      return {
        oldDailyPrice: getOldPrice({ price: trialDailyPrice }),
        oldSubscriptionPrice: getOldPrice({
          price: trialCurrentPrice,
          isFormattedDiscount: true,
        }),
      }
    }

    return {
      oldDailyPrice: getCurrentPrice(oldPrices.beforeCustomDiscount?.daily),
      oldSubscriptionPrice:
        product === GeneralProduct.SEVEN_DAY || !trialCurrentPrice
          ? getCurrentPrice(oldPrices.beforeCustomDiscount?.fullPrice)
          : getCurrentPrice(fullPrice),
    }
  }, [
    getCurrentPrice,
    getOldPrice,
    isSaleFlow,
    trialDailyPrice,
    trialCurrentPrice,
    product,
    oldPrices,
    fullPrice,
  ])

  const SecretGift = () => (
    <S.SecretGiftContainer>
      <S.SecretGiftIcon />
      <S.SecretGiftText>{t`purchaseWithoutIntro.getGift`}</S.SecretGiftText>
    </S.SecretGiftContainer>
  )

  return (
    <S.Wrapper
      key={id}
      $isClearBackground={isClearBackground}
      $isCancelOffer={isCancelOffer}
      $isSelected={isSelected}
    >
      <S.Content
        $isBellyFlow={isBellyFlow}
        $isSelected={isSelected}
        $isCancelOffer={isCancelOffer}
      >
        {isDefault && (
          <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
        )}
        <S.PlanContainer>
          <S.PlanPeriod $isBellyFlow={isBellyFlow}>
            {product === GeneralProduct.SEVEN_DAY ? (
              <Trans
                i18nKey="purchaseIntroOffer.weeklyPeriod"
                values={{ trialPeriodDays: durationDays || periodQuantity }}
              />
            ) : (
              <Trans
                i18nKey="purchaseIntroOffer.monthlyPeriod"
                values={{
                  count: durationDays
                    ? Math.round(durationDays / TRIAL_ONE_MONTH_DURATION)
                    : periodQuantity,
                }}
              />
            )}
          </S.PlanPeriod>

          <S.PriceContainer>
            {oldSubscriptionPrice && !isSevenDayPlan && (
              <S.PreviousPrice
                $isClearFlow={isNewClearFlow}
                $isBellyFlow={isBellyFlow}
                $hasSmallPrices={hasMexicanCurrency}
              >
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      oldSubscriptionPrice,
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.PreviousPrice>
            )}
            <S.CurrentPrice
              $isBellyFlow={isBellyFlow}
              $hasSmallPrices={hasMexicanCurrency}
            >
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(currentPriceWithFractionalPart),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
          </S.PriceContainer>
        </S.PlanContainer>

        <S.CustomPrice
          $isBellyFlow={isBellyFlow}
          $isSelected={isSelected}
          $isCancelOffer={isCancelOffer}
        >
          <S.CustomPreviousPrice $isBellyFlow={isBellyFlow}>
            {oldDailyPrice && !isSevenDayPlan && (
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(oldDailyPrice, currency),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            )}
          </S.CustomPreviousPrice>
          <S.PriceValue $isClearFlow={isNewClearFlow}>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: formatJaPriceWithCommas(
                  getCurrentPrice(customPriceWithFractionalPart),
                  currency,
                ),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.PriceValue>
          <S.Period>
            {t`purchase1.subscription.interval`}{' '}
            {t(`purchase1.subscription.day`)}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
      {isGiftFlow && periodQuantity === 3 && <SecretGift />}
    </S.Wrapper>
  )
}
