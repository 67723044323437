import styled from 'styled-components'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

export const StyledBoostYourHealth = {
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    position: relative;
    overflow-y: hidden;
    background: linear-gradient(
      180deg,
      rgba(230, 240, 254, 0) 0%,
      rgba(230, 240, 254, 0.83) 14.18%,
      #e6f0fe 26.75%,
      #e6f0fe 77.22%
    );
  `,
  Image: styled.img`
    margin: 0 auto;
    max-width: 375px;
    width: 100%;
    object-fit: cover;
  `,
  Container: styled(Container)`
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    bottom: 25px;
    left: 50%;
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Description: styled.div`
    font-size: 14px;
    font-style: normal;
    line-height: 18px;

    & > p:first-child {
      margin-bottom: 8px;
    }
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: #e6f0fe;
    border: none;
  `,
}
