import React from 'react'

import { StyledCompositeAnswerButton as S } from './CompositeAnswerButton.styles'

type TProps = {
  title: string
  isAgeFlow?: boolean
  children?: React.ReactNode
}

export const CompositeAnswerButton: React.FC<TProps> = ({
  children,
  title,
  isAgeFlow = false,
}) => (
  <S.Root $isAgeFlow={isAgeFlow} data-testid="composite-answer-button">
    <S.Content>
      <S.Title>{title}</S.Title>
      <S.Text>{children}</S.Text>
    </S.Content>
  </S.Root>
)
