import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
  $isInfluencerFlow?: boolean
}

type TTimerTextProps = TTimerContainerProps
type TButtonContainerProps = TTimerContainerProps

export const StyledTwoPlansTimer = {
  TimerContainer: styled.div<TTimerContainerProps>`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    max-height: 60px;
    margin: 0 auto 20px;
    padding: 20px 0;
    background: linear-gradient(96deg, #fffae1 1.79%, #ffd5b7 96.91%);
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    z-index: 102;

    color: ${({ $isInfluencerFlow }) => $isInfluencerFlow && '#7C2D12'};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  TimerText: styled.span<TTimerTextProps>`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'none' : 'inline-block'};
  `,
  SubheaderTimer: styled(Timer)<TTimerContainerProps>`
    min-width: 88px;
    padding: 0 10px 0 15px;
    color: #e85e47;
    justify-content: flex-start;

    & div {
      font-size: ${({ $isButtonVisible }) =>
        $isButtonVisible ? '32px' : '18px'};
      font-weight: ${({ $isButtonVisible }) =>
        $isButtonVisible ? '800' : '400'};
      line-height: 24px;
    }
  `,
  ButtonContainer: styled.div<TButtonContainerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button`
    height: 35px;
    margin: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 12px;
    background-color: ${Color.PRIMARY};
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      padding: 5px 32px;
    }
  `,
}
