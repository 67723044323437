import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady } from '@growthbook/growthbook-react'
import lottie from 'lottie-web/build/player/lottie_light'
import { IMPROVE_AREAS_VALUES } from 'pages/questions/ImproveAreas/constants'
import { Answer } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowAnimation from 'assets/animation/arrowAnimation.json'
import arrowSvg from 'assets/images/sprite/arrow-pink.svg'

import { PageId } from 'page-constants'
import { Cohort, Gender, PagesSource } from 'root-constants'

import { DEFAULT_OPTIONS, OPTION_VALUES } from '../Welcome/constants'
import { WelcomeJapanStyles as S } from './WelcomeJapan.styles'
import { QUESTION } from './constants'

export const WelcomeJapan: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const animationRef = useRef<HTMLDivElement>(null)

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  const { title, stickerText, optionData } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const cards = useMemo(() => {
    if (optionData && optionData.length) return optionData

    return OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS
  }, [optionData, cohortToUse])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            {
              eventID: isPersonalDataAllowed ? uuid : '',
            },
          )
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        dispatch(
          setAnswersAction({
            answers: Gender.FEMALE,
            pageId: PageId.GENDER,
          }),
        )
        dispatch(
          setAnswersAction({
            answers: [IMPROVE_AREAS_VALUES.BELLY],
            pageId: PageId.IMPROVE_AREAS,
          }),
        )

        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      isPersonalDataAllowed,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  return (
    <FeaturesReady fallback={<Spinner />}>
      <S.HeaderV2 />
      <S.Root>
        <S.Container isLarge>
          <div>
            {stickerText && (
              <S.Sticker>
                <p>{stickerText}</p>
              </S.Sticker>
            )}
            <S.Title>
              <Trans
                i18nKey={title || `onboarding.welcomeBellyRef.title`}
                components={{
                  strong: <div />,
                }}
              />
            </S.Title>
          </div>
          <div>
            <S.ActionCall>{t`onboarding.welcomeBellyRef.subtitle`}</S.ActionCall>
            <S.Arrow ref={animationRef} />
            <S.Actions>
              {cards.map(({ value, from, to }) => (
                <Answer
                  {...optionProps}
                  boxShadow="none"
                  width="166px"
                  minWidth="166px"
                  height="54px"
                  padding="8px 12px"
                  backgroundGradientColor="linear-gradient(90deg, #FF74BC 0%, #E43991 100%)"
                  activeBackgroundGradientColor="linear-gradient(0deg, #B71568 100%, #B71568 100%);"
                  border="3px solid #FFE8F4"
                  activeBorder="3px solid #FFE8F4"
                  value={value}
                  disabled={isAnswersDisabled}
                  borderRadius="4px"
                  style={{
                    borderTop: 'none',
                    borderLeft: 'none',
                  }}
                  key={value}
                >
                  <S.Action>
                    <Trans
                      i18nKey="onboarding.welcome.optionTitle"
                      values={{
                        context: from,
                      }}
                      components={{
                        age: to
                          ? t('onboarding.welcome.age', {
                              from,
                              to,
                            })
                          : from,
                        icon: (
                          <SvgImage svg={arrowSvg} height={24} width={24} />
                        ),
                      }}
                    />
                  </S.Action>
                </Answer>
              ))}
            </S.Actions>

            <S.Terms>
              <p>{t('footer.disclaimer')}</p>
              <TermsOfUseLink source={PagesSource.LANDING} />
              <S.Separator />
              <PrivacyPolicyLink source={PagesSource.LANDING} />
              <p>
                {t('footer.allRightsReserved', {
                  year: new Date().getFullYear(),
                })}
              </p>
            </S.Terms>
          </div>
        </S.Container>
      </S.Root>
    </FeaturesReady>
  )
}
