import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSeatedForwardBend = {
  Subtitle: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
}
