import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { StyledUpsellPlansDescription as S } from './UpsellPlansDescription.styles'

type TProps = {
  img: string
  title: string
  text: string
  alt?: string
  withLabel?: boolean
}

export const UpsellCardDescription: FC<TProps> = ({
  img,
  text,
  title,
  alt = 'img',
  withLabel = false,
}) => (
  <S.Card>
    <S.Image src={img} alt={alt} />
    <S.TextContainer>
      <S.Label $withLabel={withLabel}>
        <Trans i18nKey="upsellV2.upsellPlansDescription.femininityPlanLabel" />
      </S.Label>
      <S.Title>
        <Trans i18nKey={title} />
      </S.Title>
      <S.Text>
        <Trans i18nKey={text} />
      </S.Text>
    </S.TextContainer>
  </S.Card>
)
