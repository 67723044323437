import { answerTheme } from 'storybook-ui'

export const QUESTION =
  'After a few weeks of healthier lifestyle, I fall back to my old routine'

export const PROPS_FOR_YES_NO_BUTTONS = {
  theme: answerTheme.DANCEBIT,
  iconHeight: '40px',
  iconMinHeight: '40px',
  iconMinWidth: '40px',
  height: '80px',
  minWidth: '165px',
  padding: '16px',
  iconAlignSelf: 'center' as const,
  iconWidth: '40px',
  margin: '0 0 16px 0',
}
