import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { OptionType } from 'components/Option'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

export const useDynamicPage = ({ pageId, nextPagePath }: TPageProps) => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()

  const [answers, setAnswers] = useState<string[]>([])

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const {
    optionType = OptionType.RADIO,
    customPageId,
    title = '',
    subtitle,
    buttonText,
    optionData,
    imageUrls,
    pageTexts,
  } = useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      optionType: dynamicOBConfig?.[language]?.optionType,
      customPageId: dynamicOBConfig?.[language]?.customPageId,
      title: dynamicOBConfig?.[language]?.title,
      subtitle: dynamicOBConfig?.[language]?.subtitle,
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      optionData: dynamicOBConfig?.[language]?.optionData,
      imageUrls: dynamicOBConfig?.[language]?.imageUrls,
      pageTexts: dynamicOBConfig?.[language]?.pageTexts,
    }
  }, [currentPageIndex, language, steps])

  const handleContinue = useNextStep({
    pageId: customPageId || pageId,
    question: title,
    nextPagePath,
  })

  const handleSingleChoice = useCallback(
    (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const handleMultipleChoice = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
    [answers],
  )

  const getHandler = useMemo(() => {
    if (optionType === OptionType.RADIO) {
      return handleSingleChoice
    }

    return handleMultipleChoice
  }, [optionType, handleMultipleChoice, handleSingleChoice])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: optionType,
      onChange: getHandler,
    }),
    [optionType, pageId, getHandler],
  )

  return {
    title,
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  }
}
