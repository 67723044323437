import styled from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { Color } from 'root-constants'

export const StyledEventDate = {
  Subtitle: styled(PageTitleDescription)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    text-align: center;
    user-select: none;
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    height: calc(100% - 197px);
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    padding-bottom: 0;
  `,
  SkipButton: styled.a`
    display: block;
    color: ${Color.GRAY_60};
    text-align: center;
    font-style: normal;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    margin-top: 10px;
  `,
}
