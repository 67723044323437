import styled from 'styled-components'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import bgImage from 'assets/images/doctor-harvard.png'

import { Color } from 'root-constants'

export const StyledDoctor = {
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 24px;
    max-width: 248px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.h3`
    position: relative;
    z-index: 2;
    width: 254px;
    padding: 24px 20px;
    border-radius: 24px;
    border: 0.5px solid rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 24px 0 rgba(77, 96, 150, 0.1);
    backdrop-filter: blur(4px);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    svg {
      margin-bottom: 8px;
    }
  `,
  Container: styled(Container)`
    position: relative;
    margin-top: 16px;
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 182px 452px;
    min-height: 556px;
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    &::before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        59.02% 57.01% at 44.13% 33.87%,
        #f3f9ff 0%,
        #e0f0ff 50.72%,
        #a4ceff 100%
      );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: 0.5px solid #a7cdea;
    background: #a4ceff;
  `,
}
