import React, { useEffect, useRef, useState } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { EXTRA_OFFSET_FOR_ANIMATION_SMOOTH_TRIGGER } from 'root-constants'

type TUseDelayedAnimationPlaying = {
  animationPath: string
  animationName: string
}

export const useDelayedAnimation = ({
  animationPath,
  animationName,
}: TUseDelayedAnimationPlaying): [React.RefObject<HTMLDivElement>, boolean] => {
  const animationRef = useRef<HTMLDivElement>(null)
  const [isAnimationStarted, setIsAnimationStarted] = useState(false)

  useEffect(() => {
    const VIEWPORT_HEIGHT = window?.innerHeight

    const loadAnimation = () => {
      if (animationRef.current) {
        lottie.loadAnimation({
          container: animationRef.current,
          path: animationPath,
          loop: false,
          name: animationName,
        })
        setIsAnimationStarted(true)
      }
    }

    const shouldAnimationStartImmediately = () => {
      if (animationRef.current) {
        const currentElementOffsetFromTop = animationRef.current.getBoundingClientRect()
          .top
        return (
          currentElementOffsetFromTop +
            EXTRA_OFFSET_FOR_ANIMATION_SMOOTH_TRIGGER <
          VIEWPORT_HEIGHT
        )
      }

      return false
    }

    if (shouldAnimationStartImmediately() || !getIsMobileDevice()) {
      loadAnimation()

      return () => lottie.destroy(animationName)
    }

    const handleScroll = () => {
      if (!shouldAnimationStartImmediately()) return
      loadAnimation()
      document.body.removeEventListener('scroll', handleScroll)
    }

    document.body.addEventListener('scroll', handleScroll)

    return () => {
      document.body.removeEventListener('scroll', handleScroll)
      lottie.destroy(animationName)
    }
  }, [animationRef, animationPath, animationName])

  return [animationRef, isAnimationStarted]
}
