import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { PageDescription } from 'components/PageDescription'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

type TContainerProps = {
  $bgImageUrl?: string
  $isJapanese: boolean
  $isNewAdultFlow: boolean
}

export const StyledHowLongTrain = {
  Container: styled(Container)<TContainerProps>`
    min-height: 560px;
    background-image: ${({ $bgImageUrl }) =>
      $bgImageUrl && `url(${$bgImageUrl})`};
    background-repeat: no-repeat;
    background-size: 375px 500px;
    background-position: bottom;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 560px;
        ${StyledJapaneseContainer};
      `}
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
  PageDescription: styled(PageDescription)`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
