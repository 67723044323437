import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { Disclaimer } from 'modules/purchase/components/Disclaimer'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import { Cohort } from 'root-constants'

import { DISCLAIMER_PATHS } from './constants'

type TProps = {
  className?: string
}

export const PurchaseDisclaimer: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const productId = useSelector(selectProductId)
  const currency = useSelector(selectCurrency)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const count = useSelector(selectSubscriptionPeriodQuantity)
  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)
  const cohortToUse = parentCohort || cohort

  const vatInfo = useMemo(
    () =>
      hasExcludedVat
        ? t('purchase1.exclVat')
        : t('purchase1.inclVat', { context: country }),
    [country, hasExcludedVat, t],
  )

  const disclaimerPath = useMemo(() => {
    switch (cohortToUse) {
      case Cohort.DANCEBIT_FLOW_1:
        return 'purchase1.disclaimer_interval'
      default:
        return DISCLAIMER_PATHS[productId]
    }
  }, [cohortToUse, productId])

  const disclaimerText = useMemo(
    () => (
      <Trans
        i18nKey={disclaimerPath}
        values={{
          price: hasExcludedVat
            ? getCalculatedPrice(currentPrice, taxAmount, country)
            : currentPrice,
          currency: CURRENCY_SYMBOLS[currency],
          count,
          inclVat: hasVatInfo ? vatInfo : '',
          postProcess: 'interval',
        }}
        components={{ linkTag: <AccountLink /> }}
      />
    ),
    [
      disclaimerPath,
      hasExcludedVat,
      currentPrice,
      taxAmount,
      country,
      currency,
      count,
      hasVatInfo,
      vatInfo,
    ],
  )

  return <Disclaimer className={className} disclaimerText={disclaimerText} />
}
