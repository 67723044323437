import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectAnswers } from 'root-redux/selects/common'

import headerMaleImg from 'assets/images/purchase-header-male.png'
import headerImg from 'assets/images/purchase-header.png'
import arrow from 'assets/images/sprite/goal-arrow.svg'

import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { StyledBeforeAfterIntro as S } from './BeforeAfterIntro.styles'

export const BeforeAfterIntro: FC = () => {
  const userAnswers = useSelector(selectAnswers)
  const isMale = useMemo(() => userAnswers?.[PageId.GENDER] === Gender.MALE, [
    userAnswers,
  ])

  return (
    <S.ImageContainer>
      <S.Image
        src={isMale ? headerMaleImg : headerImg}
        alt="Intro img for purchase"
      />

      <S.Title>
        <Trans i18nKey="result.graph.label1" />

        <S.ArrowContainer>
          <SvgImage svg={arrow} height={16} />
          <SvgImage svg={arrow} height={16} />
          <SvgImage svg={arrow} height={16} />
        </S.ArrowContainer>

        <Trans i18nKey="result.graph.label2" />
      </S.Title>
    </S.ImageContainer>
  )
}
