import styled from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

import { ThemeSaleConfig } from './constants'

type TTheme = {
  $theme?: DynamicDiscountTheme
}

export const StyledSale = {
  Root: styled.div<TTheme>`
    display: grid;
    place-content: center;
    height: 53px;
    background-size: cover;
    color: ${Color.WHITE};

    ${({ $theme }) =>
      $theme ? ThemeSaleConfig[$theme] : ThemeSaleConfig.violet}
  `,
  Text: styled.p`
    text-align: center;
    text-shadow: 0 1px 4px rgba(37, 33, 120, 0.2);
    font-size: 22px;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 30px;
  `,
}
