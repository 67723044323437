import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import { StyledTimer as S } from './Timer.styles'

type TProps = {
  isButtonVisible?: boolean
  hasHour?: boolean
  withText?: boolean
  className?: string
}

export const Timer: FC<TProps> = ({
  isButtonVisible = false,
  hasHour = false,
  withText = false,
  className,
}) => {
  const { t } = useTranslation()

  const timerElementHourRef = useRef<HTMLSpanElement>(null)
  const timerElementMinRef = useRef<HTMLSpanElement>(null)
  const timerElementSecRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
    }
  }, [])

  return (
    <S.TimerContainer className={className}>
      <S.Timer $isButtonVisible={isButtonVisible}>
        {hasHour && (
          <>
            <S.TimerSign $isButtonVisible={isButtonVisible}>
              <strong ref={timerElementHourRef} />
              {withText && <span>{t('commonComponents.hour')}</span>}
            </S.TimerSign>
            :
          </>
        )}
        <S.TimerSign $isButtonVisible={isButtonVisible}>
          <strong ref={timerElementMinRef} />
          {withText && <span>{t('commonComponents.minute')}</span>}
        </S.TimerSign>
        :
        <S.TimerSign $isButtonVisible={isButtonVisible}>
          <strong ref={timerElementSecRef} />
          {withText && <span>{t('commonComponents.second')}</span>}
        </S.TimerSign>
      </S.Timer>
    </S.TimerContainer>
  )
}
