export const QUESTION = 'You can reach your goal weight'

export const NO_MOTIVATING_EVENT = 'feelMyBest'

export const MOTIVATING_EVENTS = {
  vacation: 'onboarding.motivatingEvent.vacation',
  wedding: 'onboarding.motivatingEvent.wedding',
  sportsEvent: 'onboarding.motivatingEvent.sportsEvent',
  summer: 'onboarding.motivatingEvent.summer',
  schoolReunion: 'onboarding.motivatingEvent.schoolReunion',
  familyGathering: 'onboarding.motivatingEvent.familyGathering',
  birthdayParty: 'onboarding.motivatingEvent.birthdayParty',
  otherOccasion: 'onboarding.motivatingEvent.otherOccasion',
}
