import heart from 'assets/images/purchaseNoOnboarding/heart.svg'
import stars from 'assets/images/purchaseNoOnboarding/stars.svg'
import toTop from 'assets/images/purchaseNoOnboarding/to-top.svg'

export const DAILY_BENEFITS_DATA = [
  {
    iconSrc: toTop,
    title: 'purchaseNoOnboarding.benefits.longLastingResults.title',
    description: `purchaseNoOnboarding.benefits.longLastingResults.description`,
  },
  {
    iconSrc: stars,
    title: 'purchaseNoOnboarding.benefits.trickYourMind.title',
    description: `purchaseNoOnboarding.benefits.trickYourMind.description`,
  },
  {
    iconSrc: heart,
    title: 'purchaseNoOnboarding.benefits.everyDayHappier.title',
    description: 'purchaseNoOnboarding.benefits.everyDayHappier.description',
  },
]
