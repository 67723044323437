import styled from 'styled-components'

type TProps = {
  $isLastItem?: boolean
}

export const StylesProgressDate = {
  Wrapper: styled.div`
    position: relative;
    height: 32px;
  `,
  Date: styled.div<TProps>`
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: slide-bottom 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;

    &:last-child {
      animation: slide-bottom-last 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
    }

    @keyframes slide-bottom {
      0% {
        top: -100px;
      }
      90% {
        top: 0;
        opacity: 1;
      }
      100% {
        top: 0;
        opacity: 0;
      }
    }

    @keyframes slide-bottom-last {
      0% {
        top: -100px;
      }
      100% {
        top: 0;
        opacity: 1;
      }
    }
  `,
}
