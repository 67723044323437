import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { Disclaimer } from 'modules/purchase/components/Disclaimer'
import {
  CURRENCY_SYMBOLS,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import { Cohort, SEVEN_DAY_TRIAL_DURATION, TRIAL_COHORT } from 'root-constants'

import { DISCLAIMER_PATHS } from './constants'

type TProps = {
  className?: string
}

export const IntroOfferDisclaimer: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const productId = useSelector(selectProductId)
  const currency = useSelector(selectCurrency)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const isFlexiblePeriodCohort = useMemo(() => {
    const currentCohort = parentCohort || cohort

    return TRIAL_COHORT.includes(currentCohort as Cohort)
  }, [cohort, parentCohort])

  const vatInfo = useMemo(() => {
    if (isFlexiblePeriodCohort) {
      return hasExcludedVat
        ? t('purchase1.excludingTaxes', { context: country })
        : t('purchase1.includingTaxes', { context: country })
    }

    return hasExcludedVat
      ? t('purchase1.exclVat')
      : t('purchase1.inclVat', { context: country })
  }, [isFlexiblePeriodCohort, country, hasExcludedVat, t])

  const disclaimerPath = useMemo(() => {
    if (isFlexiblePeriodCohort) {
      if (trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
        return 'purchaseIntroOffer.flexibleWeeklyDisclaimer'
      }

      if (periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION)) {
        return 'purchase1.disclaimer_interval'
      }

      return 'purchaseIntroOffer.flexibleDisclaimer'
    }

    return DISCLAIMER_PATHS[productId]
  }, [isFlexiblePeriodCohort, periodQuantity, productId, trialPeriodDays])

  const disclaimerText = useMemo(
    () => (
      <Trans
        i18nKey={disclaimerPath}
        values={{
          month: t('purchaseIntroOffer.month_interval', {
            postProcess: 'interval',
            count: periodQuantity,
          }),
          count: periodQuantity,
          trialCount: Math.round(trialPeriodDays / TRIAL_ONE_MONTH_DURATION),
          price: hasExcludedVat
            ? getCalculatedPrice(currentPrice, taxAmount, country)
            : currentPrice,
          trialPrice: hasExcludedVat
            ? getCalculatedPrice(trialPrice, taxAmount, country)
            : trialPrice,
          currency: CURRENCY_SYMBOLS[currency],
          inclVat: hasVatInfo ? vatInfo : '',
        }}
        components={{ linkTag: <AccountLink /> }}
        tOptions={{ postProcess: 'interval' }}
      />
    ),
    [
      country,
      currency,
      currentPrice,
      disclaimerPath,
      hasExcludedVat,
      hasVatInfo,
      periodQuantity,
      t,
      taxAmount,
      trialPeriodDays,
      trialPrice,
      vatInfo,
    ],
  )

  return <Disclaimer className={className} disclaimerText={disclaimerText} />
}
