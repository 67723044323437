import { StyledNewAdultContainer } from 'pages/questions/WantToAchieve/WantToAchieve.styles'
import styled from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

export const StyledImproveAreasAdult = {
  Root: styled.div`
    ${StyledNewAdultContainer};
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: 0.5px solid #a7cdea;
    background: #d5ebff;
  `,
}
