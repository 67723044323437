import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useVatInfo'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  MAIN_GOALS,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledChooseTrialPrice as S } from './ChooseTrialPrice.styles'

export const ChooseTrialPrice: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const isStayFit = useSelector(selectIsStayFitFlow)
  const dispatch = useDispatch()
  const hasIncludedVat = useVatInfo()

  const {
    cohort,
    currency,
    fetchingActionsList,
    selectedSubscriptionId,
    subscriptions,
    trialPrice,
  } = usePurchaseStore()

  const [selectedSubscription, setSelectedSubscription] = useState<
    ISubscription
  >()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const defaultTrialPrice = useMemo(
    () =>
      subscriptions.find(({ isDefault }) => isDefault)?.trialPrices.fullPrice,
    [subscriptions],
  )

  const hasThreeDaysTrial = useMemo(() => cohort === Cohort.DB_FLOW_3D, [
    cohort,
  ])

  useEffect(() => eventLogger.logTrialPricePageShown(), [])

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${hasIncludedVat ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX}`,
      ),
    )
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)
    setSelectedSubscription(defaultSubscription)
  }, [subscriptions])

  useEffect(() => {
    if (!selectedSubscription) return
    dispatch(setSelectedSubscriptionAction(selectedSubscription))
  }, [dispatch, selectedSubscription])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      setSelectedSubscription(checkedSubscription)
    },
    [subscriptions],
  )

  const handleNextClick = useCallback(() => {
    if (selectedSubscription) {
      dispatch(setSubscriptionListAction([selectedSubscription]))
      eventLogger.logTrialPricePageCompleted({
        trialPrice,
        currency,
      })

      goTo({
        pathname: PageId.PURCHASE_TRIAL,
        search,
      })
    }
  }, [dispatch, selectedSubscription, trialPrice, currency, search])

  const getFormattedPrice = (price, isDefault) => {
    if (!hasIncludedVat || isDefault) return price

    return Math.trunc(price) || 1
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Title>{t`purchase3.chooseTrialPrice.title`}</S.Title>
      <S.Subtitle>
        <Trans
          i18nKey="purchase3.chooseTrialPrice.subtitle"
          values={{
            context: isStayFit ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT,
          }}
        />
      </S.Subtitle>
      <S.Description>
        {hasThreeDaysTrial
          ? t`purchase3.chooseTrialPrice.threeDaysDescription`
          : t`purchase3.chooseTrialPrice.sevenDaysDescription`}
      </S.Description>
      <S.PriceDescription>
        {t('purchase3.chooseTrialPrice.priceDescription', {
          currency: CURRENCY_SYMBOLS[currency],
          defaultTrialPrice,
        })}
      </S.PriceDescription>
      <S.OptionsContainer>
        {subscriptions.map(
          ({ id, currency: subscriptionCurrency, trialPrices, isDefault }) => (
            <Option
              type={OptionType.RADIO}
              name="subscription"
              value={id}
              key={id}
              withoutMargin
              onChange={handleChange}
            >
              <S.OptionButton
                $isDefault={isDefault}
                $isSelected={id === selectedSubscriptionId}
              >
                {t('purchase3.subscriptionsBlock.price', {
                  price: getFormattedPrice(trialPrices.fullPrice, isDefault),
                  currency: CURRENCY_SYMBOLS[subscriptionCurrency],
                })}
              </S.OptionButton>

              {isDefault && (
                <S.BadgeContainer>
                  <S.Line />
                  <S.Text>{t`purchase3.chooseTrialPrice.mostPopular`}</S.Text>
                </S.BadgeContainer>
              )}
            </Option>
          ),
        )}
      </S.OptionsContainer>
      <Button
        type="button"
        disabled={!subscriptions.length}
        onClick={handleNextClick}
      >
        {t('actions.continue')}
      </Button>
    </S.Wrapper>
  )
}
