import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledName = {
  InputContainer: styled.div`
    margin-top: 24px;
  `,
  Container: styled(Container)<{
    $isJapanese: boolean
    $isNewAdultFlow: boolean
  }>`
    margin-top: 16px;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
  PageDescription: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    padding-top: 24px;
  `,
}
