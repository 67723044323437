import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import { Disclaimer } from 'modules/purchase/components/Disclaimer'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

type TProps = {
  className?: string
}

export const IntroOfferDisclaimerV2: React.FC<TProps> = ({
  className = '',
}) => {
  const { t } = useTranslation()
  const country = useSelector(selectUserCountryCode)
  const currency = useSelector(selectCurrency)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const vatInfo = useMemo(
    () =>
      hasExcludedVat
        ? t('purchase1.excludingTaxes', { context: country })
        : t('purchase1.includingTaxes', { context: country }),
    [country, hasExcludedVat, t],
  )

  const disclaimerText = useMemo(
    () => (
      <Trans
        i18nKey="purchaseWeeklyOffer.disclaimer"
        values={{
          trialDuration: Math.round(trialPeriodDays / 7),
          count: Math.round(trialPeriodDays / 7),
          fullPrice: formatJaPriceWithCommas(fullPrice, currency),
          trialPrice: formatJaPriceWithCommas(trialPrice, currency),
          periodDuration: Math.round(periodQuantity / 7),
          currency: CURRENCY_SYMBOLS[currency],
          inclVat: hasVatInfo ? vatInfo : '',
        }}
        components={{ linkTag: <AccountLink /> }}
      />
    ),
    [
      trialPeriodDays,
      fullPrice,
      currency,
      trialPrice,
      periodQuantity,
      hasVatInfo,
      vatInfo,
    ],
  )

  return <Disclaimer className={className} disclaimerText={disclaimerText} />
}
