import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { useCancelPrimerDiscount } from 'hooks/useCancelPrimerDiscount'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  PAYMENT_METHODS_IMAGES,
  PAYMENT_METHODS_IMAGES_ABC_TEST,
  PAYMENT_METHODS_TO_SHOW,
  WALLET_IMAGES,
} from 'modules/purchase/pages/CheckoutSeparateMethods/constants'

import { ABC_TEST_VARIANTS, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledPaymentMethodToggle as S } from './PaymentMethodToggle.styled'

const USA_COUNTRY_CODE = 'US'
const MINIMAL_AVAILABLE_PAYMENT_METHODS = 1

type TProps = {
  handlePaymentMethodTabClick: (paymentMethod: PaymentMethod) => void
  availablePaymentMethods: PaymentMethod[]
  selectedPaymentMethodTab: PaymentMethod
  className?: string
}

export const PaymentMethodToggle: FC<TProps> = ({
  handlePaymentMethodTabClick,
  availablePaymentMethods,
  selectedPaymentMethodTab,
  className,
}) => {
  const { t } = useTranslation()

  const { isEUUser } = useCookieConsentAnswer()
  const { countryCode } = usePurchaseStore()
  const { cancelDiscount } = useCancelPrimerDiscount()

  const variantOfModalCheckout = useFeatureValue(
    GROWTHBOOK_EXPERIMENT.DAN_1291,
    ABC_TEST_VARIANTS.VARIANT_A,
  )

  const getPaymentMethodTabImageCover = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (
        variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_B ||
        variantOfModalCheckout === ABC_TEST_VARIANTS.VARIANT_C
      ) {
        return PAYMENT_METHODS_IMAGES_ABC_TEST[paymentMethod]
      }

      if (paymentMethod !== PaymentMethod.WALLETS) {
        return PAYMENT_METHODS_IMAGES[paymentMethod]
      }

      if (isEUUser) return WALLET_IMAGES.EU

      if (countryCode === USA_COUNTRY_CODE) return WALLET_IMAGES.US

      return WALLET_IMAGES.WW
    },
    [countryCode, isEUUser, variantOfModalCheckout],
  )

  const paymentMethodsTabs = useMemo(
    () => PAYMENT_METHODS_TO_SHOW[ABC_TEST_VARIANTS.VARIANT_A],
    [],
  )

  if (availablePaymentMethods.length < MINIMAL_AVAILABLE_PAYMENT_METHODS) {
    return null
  }

  return (
    <S.PaymentMethodsWrapper className={className}>
      {paymentMethodsTabs?.map((paymentMethod: PaymentMethod) => {
        if (!availablePaymentMethods.includes(paymentMethod)) {
          return null
        }

        if (paymentMethod === PaymentMethod.PAYPAL) {
          return (
            <S.PaymentMethod
              key={paymentMethod}
              $isSelected={paymentMethod === selectedPaymentMethodTab}
              onClick={() => {
                handlePaymentMethodTabClick(paymentMethod)
                cancelDiscount()
              }}
            >
              <img
                src={
                  getPaymentMethodTabImageCover(paymentMethod)[
                    paymentMethod === selectedPaymentMethodTab
                      ? 'active'
                      : 'inactive'
                  ]
                }
                width={getPaymentMethodTabImageCover(paymentMethod).imageWidth}
                alt={paymentMethod}
              />
            </S.PaymentMethod>
          )
        }

        return (
          <S.PaymentMethod
            key={paymentMethod}
            $isSelected={paymentMethod === selectedPaymentMethodTab}
            onClick={() => handlePaymentMethodTabClick(paymentMethod)}
          >
            {paymentMethod === PaymentMethod.CREDIT_CARD && (
              <S.PaymentMethodText>
                {t('checkoutExtended.creditCard')}
              </S.PaymentMethodText>
            )}

            <img
              src={
                getPaymentMethodTabImageCover(paymentMethod)[
                  paymentMethod === selectedPaymentMethodTab
                    ? 'active'
                    : 'inactive'
                ]
              }
              width={getPaymentMethodTabImageCover(paymentMethod).imageWidth}
              alt={paymentMethod}
            />
          </S.PaymentMethod>
        )
      })}
    </S.PaymentMethodsWrapper>
  )
}
