import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledGoalWeight = {
  WeightValue: styled.div<{ $isAgeFlow: boolean }>`
    margin: 0 auto 30px;
    height: 56px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px solid ${Color.PRIMARY};
    color: ${Color.PRIMARY};
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        border: 1px solid ${Color.PRIMARY_LIGHT};
        background: ${Color.WHITE};
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
      `}
  `,
}
