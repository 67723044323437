import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import tickerIcon from 'assets/images/sprite/ticker.svg'

import { DynamicDiscountTheme } from 'root-constants'

import { StyledSubheaderWithTimerFreeOffer as S } from './SubheaderWithTimerFreeOffer.styles'

type TProps = {
  theme?: DynamicDiscountTheme
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
}

export const SubheaderWithTimerFreeOffer: React.FC<TProps> = ({
  theme,
  elemForComparisonRef,
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)

  const timerElementHourRef = useRef<HTMLDivElement>(null)
  const timerElementMinRef = useRef<HTMLDivElement>(null)
  const timerElementSecRef = useRef<HTMLDivElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
    }
  }, [])

  return (
    <S.TimerContainer $isButtonVisible={isButtonVisible} $theme={theme}>
      <S.TimerText $isButtonVisible={isButtonVisible}>
        {!isButtonVisible && (
          <SvgImage svg={tickerIcon} width={24} height={24} />
        )}
        {t`purchase1.subheaderText`}
      </S.TimerText>

      <S.Timer>
        <S.TimerSign ref={timerElementMinRef} />
        :
        <S.TimerSign ref={timerElementSecRef} />
      </S.Timer>

      <S.ButtonContainer $isButtonVisible={isButtonVisible}>
        <S.TimerButton
          data-order="1"
          data-text="get_now"
          onClick={onButtonClick}
        >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
