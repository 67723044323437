import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectRedditPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useRedditPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const redditPixelId = useSelector(selectRedditPixelId)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldRedditPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [isEUUser, userCookieConsentAnswer, hasAdvertizingAndMarketingCookie],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useLayoutEffect(() => {
    if (!redditPixelId || shouldRedditPixelBePaused || !userCountryCode) return

    const script = document.createElement('script')
    script.text = `!function(w,d)
    {if(!w.rdt){var p=w.rdt=function(){p.sendEvent?
    p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var
    t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var
    s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}
    (window,document);`
    script.async = true
    document.head.appendChild(script)

    window.rdt &&
      window.rdt('init', redditPixelId, {
        email: isPersonalDataAllowed ? email : '',
        externalId: isPersonalDataAllowed ? uuid : '',
        ...(!isPersonalDataAllowed && { em: '', uuid: '' }),
      })
    window.rdt &&
      window.rdt('track', 'PageVisit', {
        ...(!isPersonalDataAllowed && { em: '', uuid: '' }),
      })
  }, [
    email,
    redditPixelId,
    uuid,
    shouldRedditPixelBePaused,
    userCountryCode,
    isPersonalDataAllowed,
    dispatch,
  ])
}
