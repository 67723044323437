import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  setScreenIdAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useViewportValue } from 'hooks/useViewportValue'

import { CourseDescription } from 'modules/purchase/components/CourseDescription'
import { FeaturedBlockV2 } from 'modules/purchase/components/FeaturedBlockV2'
import { GoalWeightGraph } from 'modules/purchase/components/GoalWeightGraph'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PlanHighlights } from 'modules/purchase/components/PlanHighlights'
import { ReviewsCarousel } from 'modules/purchase/components/ReviewsCarousel'
import { SubheaderWithTimerV2 } from 'modules/purchase/components/SubheaderWithTimerV2'
import { SubscriptionsBlockTrial } from 'modules/purchase/components/SubscriptionsBlockTrial'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { CheckoutTrial } from 'modules/purchase/pages/CheckoutTrial'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenId, ScreenName } from 'root-constants'

import { StyledPurchaseTrial as S } from './PurchaseTrial.styles'

export const PurchaseTrial: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const selectedSubscription = useSelector(selectSubscription)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const { buttonText } = useDynamicPaywallConfig()
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
    screenId: ScreenId.SHORT_SEVEN_DAYS,
  })

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  useLayoutEffect(() => {
    if (!selectedSubscription) {
      replaceHistory({
        pathname: PageId.CHOOSE_TRIAL_PRICE,
        search,
      })
    }
  }, [selectedSubscription, search])

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.SHORT_SEVEN_DAYS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
  }, [dispatch])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !selectedSubscription ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <CourseDescription />
          <S.Wrapper>
            <SubheaderWithTimerV2 />
            {isStayFit ? <S.Chart /> : <GoalWeightGraph />}

            <S.Button
              type="button"
              data-order="1"
              data-text="continue"
              onClick={handleShowPayment}
            >
              {buttonText || t('actions.continue')}
            </S.Button>

            <PlanHighlights />
            <SubscriptionsBlockTrial />
            <ReviewsCarousel />

            <S.ButtonContainer>
              <S.Button
                type="button"
                data-order="2"
                data-text="continue"
                onClick={handleShowPayment}
              >
                {buttonText || t('actions.continue')}
              </S.Button>
            </S.ButtonContainer>

            <MoneyBackGuarantee />
            <S.SecurityInfo />
            <FeaturedBlockV2 />
          </S.Wrapper>
        </>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <Header showHeader />
        <CheckoutTrial
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
