import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers, selectIsStayFitFlow } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { selectSubscriptionPeriodQuantity } from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import { MAIN_GOALS } from 'root-constants'

import { StyledCourseDescription as S } from './CourseDescription.styles'

export const CourseDescription: React.FC = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const isStayFit = useSelector(selectIsStayFitFlow)
  const { title } = useDynamicPaywallConfig()

  const weight = useMemo(() => {
    const goalWeight = userAnswers?.goalWeight
    const unit = t(`commonComponents.${userAnswers?.measurementSystem}`)

    return `${goalWeight}\u00A0${unit}`
  }, [t, userAnswers?.goalWeight, userAnswers?.measurementSystem])

  return (
    <>
      <S.Wrapper>
        <S.Course>
          {title || (
            <Trans
              i18nKey="purchase3.courseDescription.description"
              values={{
                periodQuantity,
                name: userAnswers?.[PageId.NAME],
              }}
              components={{
                br: <br />,
                name: <span />,
              }}
            />
          )}
        </S.Course>
      </S.Wrapper>

      <S.GoalContainer>
        <S.Goal>
          <Trans
            i18nKey="purchase3.courseDescription.targetWeight"
            values={{
              weight,
              context: isStayFit ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT,
            }}
          />
        </S.Goal>
        <S.Goal>
          <Trans i18nKey="purchase3.courseDescription.longLastingResults" />
        </S.Goal>

        <S.Goal>
          <Trans i18nKey="purchase3.courseDescription.healthierLife" />
        </S.Goal>
      </S.GoalContainer>
    </>
  )
}
