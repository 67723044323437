import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import firstPhotoInMirror from 'assets/images/result-mirror-1.png'
import secondPhotoInMirror from 'assets/images/result-mirror-2.png'
import thirdPhotoInMirror from 'assets/images/result-mirror-3.png'
import arrowSvg from 'assets/images/sprite/right-blue-arrow.svg'

import { StyledResultInMirror as S } from './ResultInMirror.styles'
import { QUESTION } from './constants'

export const ResultInMirror: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })
  const handleClick = useCallback(() => handleContinue(''), [handleContinue])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Wrapper>
          <Container isLarge>
            <PageTitle marginBottom={8}>
              <Trans i18nKey="onboarding.resultInMirror.title" />
            </PageTitle>
            <S.Subtitle>
              <Trans i18nKey="onboarding.resultInMirror.subtitle" />
            </S.Subtitle>

            <S.ResultInMirrorBlock>
              <S.Photo src={firstPhotoInMirror} />
              <S.Photo src={secondPhotoInMirror} />
              <S.Photo src={thirdPhotoInMirror} />
            </S.ResultInMirrorBlock>

            <S.WeightProgress>
              <S.Weight>
                <Trans i18nKey="onboarding.resultInMirror.firstWeightOnPhoto" />
              </S.Weight>
              <S.Weight>
                <SvgImage svg={arrowSvg} width={16} height={20} />
                <Trans i18nKey="onboarding.resultInMirror.secondWeightOnPhoto" />
              </S.Weight>
              <S.Weight>
                <SvgImage svg={arrowSvg} width={16} height={20} />
                <Trans i18nKey="onboarding.resultInMirror.thirdWeightOnPhoto" />
              </S.Weight>
            </S.WeightProgress>
          </Container>
        </S.Wrapper>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
