import styled from 'styled-components'

import { Option } from 'components/Option'

import { Color, MediaBreakpoint } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledMainGoalSomatic = {
  Root: styled.div`
    position: relative;

    &::before {
      content: '';
      position: fixed;
      background: #010020;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    video {
      position: fixed;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% - 98px);
    }
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: calc(var(--full-height) - 168px);
    height: 100%;
    padding: 0 14px;
    position: relative;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      min-height: calc(var(--full-height) - 208px);
      padding-top: 120px;
      justify-content: space-around;
    }
  `,
  Banner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(264deg, #bf57ff 28.49%, #fc68ff 89.15%);
    height: 48px;
    width: 100%;
    color: ${Color.WHITE};
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 40px;
      height: 78px;
    }
  `,
  ActionCall: styled.h3`
    position: relative;
    text-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    max-width: 248px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.WHITE};
    cursor: pointer;
    width: fit-content;
    margin: 0 auto 6px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: none;
    }

    &::before {
      position: absolute;
      content: '';
      border-radius: 40px;
      background: rgba(2, 1, 34, 0.5);
      width: 100%;
      height: 100%;
      z-index: -1;
      filter: blur(17.5px);
    }
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 16px;
    }
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 618px;
      margin: 0 auto 16px auto;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex: 1;
    border-radius: 10px;
    max-width: 236px;
    border: 2px solid ${Color.PRIMARY};
    background-color: ${({ $isChecked }) =>
      $isChecked ? Color.WHITE : Color.PRIMARY};
    color: ${({ $isChecked }) => ($isChecked ? Color.PRIMARY : Color.WHITE)};

    &:first-child {
      margin-right: 12px;
    }
  `,
  Action: styled.div`
    width: 100%;
    min-width: 156px;
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
  `,
  Terms: styled.div`
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
