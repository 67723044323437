import { DANCING_LEVEL } from 'pages/questions/DancingLevel/constants'

import { AnimationState } from 'modules/purchase/constants'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAppConfig,
  IFirebaseConfig,
  IPaymentConfig,
  TGiaApiKeys,
} from 'models/config.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IStep, IVariant } from 'models/variant.model'

import {
  Cohort,
  Gender,
  MAIN_GOALS,
  MeasurementSystem,
  ScreenId,
  ScreenName,
} from 'root-constants'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariantCohort = (state: IAppState): Cohort | null =>
  state.common.currentVariant?.cohort || null
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectCurrentVariantParentCohort = (
  state: IAppState,
): Cohort | null => state.common.currentVariant?.parentCohort || null
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectSubscriptionList = (state: IAppState): ISubscription[] =>
  state.common.subscriptions || []
export const selectConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.appConfig?.giaApiKeys || null
export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.appConfig?.firebaseConfig || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.appConfig?.googleAnalyticsId || null
export const selectLiveChatWorkingHours = (
  state: IAppState,
): { startTime: string; endTime: string } | null =>
  state.common.appConfig?.liveChatWorkingHours || null
export const selectDownloadLinks = (
  state: IAppState,
): { ios: string; android: string } | null =>
  state.common.appConfig?.downloadLinks || null
export const selectPaymentConfig = (state: IAppState): IPaymentConfig | null =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripePaymentMethods = (state: IAppState): string[] =>
  state.common.paymentConfig?.stripe.paymentMethods || []
export const selectStripeAccountName = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.publicKey || ''
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectUserName = (state: IAppState): string =>
  state.common.answers?.name?.toString() || ''
export const selectUserHeight = (state: IAppState): string =>
  state.common.answers?.currentHeight?.toString() || ''
export const selectUserAge = (state: IAppState): number =>
  Number(state.common.answers?.age) || 0
export const selectUserDancingLevel = (state: IAppState): string =>
  state.common.answers?.dancingLevel?.toString() || DANCING_LEVEL.NEWBIE
export const selectCurrentUserCurrentWeight = (state: IAppState): string =>
  state.common.answers?.currentWeight?.toString() || ''
export const selectCurrentUserLifestyle = (state: IAppState): string =>
  state.common.answers?.lifestyle?.toString() || ''
export const selectCurrentUserDreamBody = (state: IAppState): string =>
  state.common.answers?.dreamBody?.toString() || ''
export const selectCurrentUserGoalWeight = (state: IAppState): string =>
  state.common.answers?.goalWeight?.toString() || ''
export const selectCurrentUserMeasurementSystem = (state: IAppState): string =>
  state.common.answers?.measurementSystem?.toString() ||
  MeasurementSystem.METRIC
export const selectCurrentUserMotivation = (state: IAppState): string =>
  state.common.answers?.motivation.toString() || ''
export const selectCurrentUserMotivationEvent = (state: IAppState): string =>
  state.common.answers?.motivatingEvent.toString() || ''
export const selectCurrentUserGender = (state: IAppState): string =>
  state.common.answers?.gender?.toString() || Gender.FEMALE
export const selectGrowthBookExperiments = (
  state: IAppState,
): Record<string, Record<string, string>> | null =>
  state.common.currentVariant?.growthBookExperiments || null
export const selectLanguage = (state: IAppState) => state.common.language
export const selectScreenName = (state: IAppState): ScreenName =>
  state.common.screenName
export const selectScreenId = (state: IAppState): ScreenId =>
  state.common.screenId
export const selectTiktokPixelId = (state: IAppState): string =>
  state.common.currentVariant?.tiktokPixelId || ''
export const selectTaboolaPixelId = (state: IAppState): string =>
  state.common.currentVariant?.taboolaPixelId || ''
export const selectOutbrainPixelId = (state: IAppState): string =>
  state.common.currentVariant?.outbrainPixelId || ''
export const selectSnapPixelId = (state: IAppState): string =>
  state.common.currentVariant?.snapPixelId || ''
export const selectRedditPixelId = (state: IAppState): string =>
  state.common.currentVariant?.redditPixelId || ''
export const selectHasCancelOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasCancelOffer || false
export const selectHasUpsellOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasUpsellOffer || false
export const selectUtmTags = (state: IAppState): TUtmTags =>
  state.common.utmTags || {}
export const selectIsPaymentStatusShown = (state: IAppState): boolean =>
  state.common.isPaymentStatusShown || false
export const selectPaymentAnimationStatus = (
  state: IAppState,
): AnimationState | boolean => state.common.paymentAnimationStatus || false
export const selectIsStayFitFlow = (state: IAppState): boolean =>
  !state.common.answers?.mainGoal?.includes(MAIN_GOALS.LOSE_WEIGHT) || false
export const selectIsPrimerRetryProcessing = (state: IAppState): boolean =>
  state.common.isPrimerRetryProcessing || false
export const selectIsInContextShown = (state: IAppState): boolean =>
  state.common.isInContextShown
