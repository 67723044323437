import styled, { keyframes } from 'styled-components'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

const appearTitle = keyframes`
  from {
    transform: translateX(-50%) scale(0.7);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
`

const appearButton = keyframes`
  from {
    transform:  scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const pulseShadow = keyframes`
  0% {
    filter: drop-shadow(0px 0px 4px ${Color.PRIMARY}60);
  }
  50% {
    filter: drop-shadow(0px 0px 20px ${Color.PRIMARY});
  }
  100% {
    filter: drop-shadow(0px 0px 4px ${Color.PRIMARY}60);
  }
`

const scaleButton = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

export const StyledForgetPlanks = {
  Root: styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 52px);
    background: linear-gradient(
      180deg,
      #3b3e38 15.63%,
      #575954 39.94%,
      #656661 63.27%,
      #94928c 100%
    );
  `,
  PageTitle: styled(PageTitle)`
    position: absolute;
    width: 100%;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    text-shadow: 0px 4px 12px rgba(0, 0, 0, 1);
    color: ${Color.WHITE};
    opacity: 0;
    animation: ${appearTitle} 0.5s ease-out 1s forwards;
  `,
  ContentWrapper: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    width: 100%;
    max-width: 489px;
    height: 100%;
    overflow: hidden;

    & > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    position: absolute;
    background: transparent;
    border: none;
    opacity: 0;
    animation: ${appearButton} 0.5s ease-out 3s forwards,
      ${pulseShadow} 2s ease-in-out infinite 3s,
      ${scaleButton} 2s ease-in-out infinite 7s; ;
  `,
}
