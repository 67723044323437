import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { ToggleV2 } from 'components/ToggleV2'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { UpsellProduct } from 'root-constants'

import { StyledUpsellSubscriptionItemV2 as S } from './UpsellSubscriptionItemV2.styles'

type TProps = {
  subscription: ISubscription
  onChange: (value: UpsellProduct) => void
  isSelected: boolean
  showPrice: boolean
  name: string
}

export const UpsellSubscriptionItemV2: FC<TProps> = ({
  onChange,
  name,
  isSelected,
  showPrice,
  subscription: {
    product,
    id,
    currency,
    mainPrices: { fullPrice },
  },
}) => (
  <S.Wrapper $isChecked={isSelected} key={id}>
    <S.ToggleContainer>
      <ToggleV2 onChange={onChange} value={product} isChecked={isSelected} />
      <S.PlanName $isChecked={isSelected} $hasMaxWidth={showPrice}>
        <Trans components={{ br: showPrice ? <br /> : <span /> }}>{name}</Trans>
      </S.PlanName>
    </S.ToggleContainer>

    {showPrice && (
      <S.PriceBlock $isChecked={isSelected}>
        <Trans
          i18nKey="upsellV2.price"
          values={{
            currency: CURRENCY_SYMBOLS[currency],
            price: fullPrice,
          }}
        />
      </S.PriceBlock>
    )}
  </S.Wrapper>
)
