import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { selectHighestDiscountPercentage } from 'modules/purchase/redux/selects/common'

import couponBg from 'assets/images/cancel-offer-bg-ja.png'
import giftPng from 'assets/images/cancel-offer-gift-ja.png'

import { MAIN_GOALS } from 'root-constants'

import { StyledPersonalPlan as S } from './PersonalPlanJa.styles'

type TProps = {
  externalDiscountPercentage?: number | null
}

export const PersonalPlanJa: React.FC<TProps> = ({
  externalDiscountPercentage,
}) => {
  const highestDiscountPercentage = useSelector(selectHighestDiscountPercentage)
  const { title } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Content>
        <div>
          <S.Title>
            {title || (
              <Trans
                i18nKey="purchaseCancelOffer.personalPlan.title"
                values={{
                  contest: MAIN_GOALS.LOSE_WEIGHT,
                }}
              />
            )}
          </S.Title>
          <S.DiscountText>
            <Trans
              i18nKey="purchaseCancelOffer.personalPlan.upToDiscountText"
              values={{
                discountAmount:
                  externalDiscountPercentage || highestDiscountPercentage,
              }}
            />
          </S.DiscountText>
        </div>
        <S.Picture src={giftPng} alt="gift" />
      </S.Content>
      <S.BgImage src={couponBg} alt="coupon" />
    </S.Wrapper>
  )
}
