import styled from 'styled-components'

import { Option } from 'components/Option'

import introDesktopBg from 'assets/images/intro-somatic-desktop.jpg'
import introBg from 'assets/images/intro-somatic.jpg'

import {
  Color,
  MINIMUM_HEIGHT_BREAKPOINT,
  MediaBreakpoint,
} from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledMainGoalSomatic = {
  Root: styled.div`
    position: relative;
    background-image: url(${introBg});
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: url(${introDesktopBg});
    }
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(var(--full-height) - 56px);
    height: 100%;
    padding: 80px 14px 0;
    position: relative;
    z-index: 2;

    @media (min-height: ${MINIMUM_HEIGHT_BREAKPOINT}px) {
      padding-top: 120px;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      padding-top: 0;
      justify-content: space-around;
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 16px;
    font-feature-settings: 'clig' off, 'liga' off;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 24px;
      line-height: 32px;
    }
  `,
  Title: styled.h1`
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: ${Color.WHITE};
    margin: 0 auto 16px auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 40px;
    }
  `,
  ActionCall: styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: ${Color.WHITE};
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
  `,
  Arrow: styled.div`
    width: 24px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 16px;
    }
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 618px;
      margin: 0 auto 16px auto;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex: 1;
    border-radius: 10px;
    border: 2px solid ${Color.PRIMARY};
    background-color: ${({ $isChecked }) =>
      $isChecked ? Color.WHITE : Color.PRIMARY};
    color: ${({ $isChecked }) => ($isChecked ? Color.PRIMARY : Color.WHITE)};

    &:first-child {
      margin-right: 12px;
    }
  `,
  Action: styled.div`
    width: 100%;
    min-width: 156px;
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
  `,
  Terms: styled.div`
    z-index: 2;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 14px;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
