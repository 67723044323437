import styled from 'styled-components'

import { animatedTitleStyles } from 'modules/purchase/pages/PurchaseNew/PurchaseNew.styles'

import bgWithGradientStars from 'assets/images/paywall-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReachTarget = {
  Wrapper: styled.div`
    position: relative;
    margin: 0 auto 8px;
    padding: 24px 0 0;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 320px;
      background: url(${bgWithGradientStars}) no-repeat center;
      background-size: cover;
      margin: 0 auto 8px;
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
      border-radius: 0 0 120px 120px;
    }
  `,
  Text: styled.div<{ $isVisible: boolean }>`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    span {
      color: #ffcb78;
    }

    i,
    strong {
      font-size: 18px;
      line-height: 24px;
    }

    i {
      font-weight: 400;
    }

    ${animatedTitleStyles}
  `,
  CarouselContainer: styled.div`
    width: 343px;
    min-height: 317px;
    margin: 0 auto;
  `,
  PhotoResultWrapper: styled.div`
    max-width: 343px;
    height: 317px;
  `,
  Image: styled.img`
    image-rendering: -webkit-optimize-contrast;
  `,
}
