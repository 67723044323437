import styled from 'styled-components'

export const StyledSubscriptionsBlockWithSpecialLabel = {
  Wrapper: styled.div`
    margin: 50px auto;
    border-radius: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin-top: 20px;
  `,
}
