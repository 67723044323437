import styled from 'styled-components'

import { Button } from 'components/Button'

import { PaymentMethod } from 'modules/purchase/constants'

import { PAYMENT_ELEMENT_STYLE } from './constants'

export const StyledPaymentElementButton = {
  Button: styled(Button)<{ $type: PaymentMethod }>`
    position: relative;

    ${({ $type }) =>
      PAYMENT_ELEMENT_STYLE[$type] || PAYMENT_ELEMENT_STYLE.default}
  `,
}
