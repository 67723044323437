import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import {
  selectCurrentVariant,
  selectCurrentVariantParentCohort,
  selectIsInContextShown,
} from 'root-redux/selects/common'

import { DynamicTitles } from 'hooks/useTitleFromUrl'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { browserHistory } from 'browser-history'
import {
  COHORT_QUERY_PARAM,
  Cohort,
  CurrentEnvironment,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Locale,
} from 'root-constants'

import { SUPPORTED_LOCALES_MAP } from '../constants/supportedLocalesMap'

export const useLanguage = (): void => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const variant = useSelector(selectCurrentVariant, shallowEqual)

  const isInContextShown = useSelector(selectIsInContextShown)

  const isDevEnvironment = getCurrentEnv() === CurrentEnvironment.DEV

  const isInContextActive = useMemo(
    () => isDevEnvironment && isInContextShown,
    [isDevEnvironment, isInContextShown],
  )

  useEffect(() => {
    if (!variant) return

    const URLParams = new URLSearchParams(window.location.search)
    const urlLangParam = URLParams.get(LANG_QUERY_PARAM)
    const cohort = URLParams.get(COHORT_QUERY_PARAM)

    const langParam = urlLangParam || i18n.resolvedLanguage || ''

    const supportedLocales =
      SUPPORTED_LOCALES_MAP[(parentCohort as Cohort) || (cohort as Cohort)]

    if (cohort && !supportedLocales?.includes(langParam)) {
      dispatch(
        setLanguageAction(
          isInContextActive ? Locale.IN_CONTEXT : INITIAL_LANGUAGE,
        ),
      )

      URLParams.set(LANG_QUERY_PARAM, INITIAL_LANGUAGE)
      browserHistory.replace({
        pathname: window.location.pathname,
        search: URLParams.toString(),
      })
      return
    }

    dispatch(
      setLanguageAction(isInContextActive ? Locale.IN_CONTEXT : langParam),
    )

    if (!urlLangParam) {
      URLParams.delete(DynamicTitles.HEADLINE)
      URLParams.delete(DynamicTitles.SUBTEXT)

      URLParams.set(LANG_QUERY_PARAM, langParam)
      browserHistory.replace({
        pathname: window.location.pathname,
        search: URLParams.toString(),
      })
    }
  }, [
    dispatch,
    i18n.resolvedLanguage,
    isInContextActive,
    parentCohort,
    variant,
  ])
}
