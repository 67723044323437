import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import {
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useProgressAnimationData } from 'hooks/useProgressAnimationData'

import { TPageProps } from 'models/common.model'

import ratingImage from 'assets/images/five-stars.png'
import trustedBy from 'assets/images/trusted-by.png'

import { ADULT_COHORT, Cohort, Color } from 'root-constants'

import { StyledPreparePlan as S } from './PreparePlan.styles'
import { QUESTION, STATIC_ANIMATION_DURATION } from './constants'

export const PreparePlan: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const goToNextPage = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  const {
    firstCounter,
    isFirstCounterFinished,
    secondCounter,
    isSecondCounterFinished,
    thirdCounter,
    isThirdCounterFinished,
    fourthCounter,
    isFourthCounterFinished,
  } = useProgressAnimationData({
    goToNextPage,
    STATIC_ANIMATION_DURATION,
  })

  return (
    <S.Container $isAgeFlow={isAgeFlow}>
      <S.Title>
        <Trans
          i18nKey="onboarding.preparePlanV2.title"
          components={{ highlighted: <span /> }}
        />
      </S.Title>
      <S.ProgressContainer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={firstCounter}
          isInProgress={!!firstCounter}
          isFinished={isFirstCounterFinished}
        >
          {t`onboarding.preparePlanV2.analyzingGoals`}
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={secondCounter}
          isInProgress={!!secondCounter}
          isFinished={isSecondCounterFinished}
        >
          {t`onboarding.preparePlanV2.analyzingProblemAreas`}
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={thirdCounter}
          isInProgress={!!thirdCounter}
          isFinished={isThirdCounterFinished}
        >
          {t`onboarding.preparePlanV2.choosingDanceWorkouts`}
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={fourthCounter}
          isInProgress={!!fourthCounter}
          isFinished={isFourthCounterFinished}
        >
          {t`onboarding.preparePlanV2.preparingPlan`}
        </ProgressAnalyzer>
      </S.ProgressContainer>

      <S.CardRating src={trustedBy} alt="trusted by clients" />
      <S.RatingTitle>{t`onboarding.preparePlanV2.trustedBy`}</S.RatingTitle>

      <S.Card>
        <S.CardRating src={ratingImage} alt="five stars rating" />
        <S.CardReview>
          {t('onboarding.preparePlanV2.review', { context: measurementSystem })}
        </S.CardReview>
        <S.CardReviewer>{t`onboarding.preparePlanV2.reviewerName`}</S.CardReviewer>
      </S.Card>
    </S.Container>
  )
}
