import closeToFeet from 'assets/images/close-to-feet.png'
import easilyTouch from 'assets/images/easily-touch.png'
import farFromFeet from 'assets/images/far-from-feet.png'

export const QUESTION = 'How far could you do a seated forward bend?'

export const OPTION_VALUES = [
  {
    value: 'farFromFeet',
    text: 'onboarding.seatedForwardBend.farFromFeet',
    imageUrl: farFromFeet,
  },
  {
    value: 'closeToFeet',
    text: 'onboarding.seatedForwardBend.closeToFeet',
    imageUrl: closeToFeet,
  },
  {
    value: 'easilyTouch',
    text: 'onboarding.seatedForwardBend.easilyTouch',
    imageUrl: easilyTouch,
  },
]
