export const ACCORDION_CONTENT = [
  {
    title: 'purchaseTwoPlans.accordion.first.title',
    content: 'purchaseTwoPlans.accordion.first.content',
  },
  {
    title: 'purchaseTwoPlans.accordion.second.title',
    content: 'purchaseTwoPlans.accordion.second.content',
  },
  {
    title: 'purchaseTwoPlans.accordion.third.title',
    content: 'purchaseTwoPlans.accordion.third.content',
  },
]
