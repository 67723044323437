import React, { ButtonHTMLAttributes } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Color, GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledNavigationButton as S } from './NavigationButtonBelly.styles'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  onClick?: () => void
  className?: string
  additionalContent?: React.ReactNode
  additionalTitle?: React.ReactNode
  hasClearBackground?: boolean
}

export const NavigationButtonBelly: React.FC<IButtonProps> = ({
  disabled,
  onClick,
  children,
  additionalContent,
  additionalTitle,
  className,
  hasClearBackground,
  ...props
}) => {
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  return (
    <S.ButtonContainer
      className={className}
      $isJapanese={isJapaneseFlow}
      $hasClearBackground={hasClearBackground}
    >
      {additionalTitle}

      <S.Button
        $isJapanese={isJapaneseFlow}
        $disabled={!!disabled}
        disabled={disabled}
        onClick={onClick}
        disabledBackgroundColor={Color.GRAY_40}
        style={
          isJapaneseFlow
            ? {
                borderRight: disabled
                  ? '4px solid #A9A9A9'
                  : '4px solid #0390CF',
                borderBottom: disabled
                  ? '4px solid #A9A9A9'
                  : '4px solid #0390CF',
              }
            : {}
        }
        {...props}
      >
        {children}
      </S.Button>
      {additionalContent}
    </S.ButtonContainer>
  )
}
