import aboveLowIcon from 'assets/images/flow-belly/icons/above-low-energy.svg'
import highIcon from 'assets/images/flow-belly/icons/high-energy.svg'
import lowIcon from 'assets/images/flow-belly/icons/low-energy.svg'
import mediumIcon from 'assets/images/flow-belly/icons/medium-energy.svg'

export const QUESTION = 'How are your energy levels during the day?'

export const OPTION_VALUES_ENERGY_BELLY = [
  {
    value: 'low',
    text: 'onboarding.energyBelly.low',
    imageUrl: lowIcon,
  },
  {
    value: 'aboveLow',
    text: 'onboarding.energyBelly.aboveLow',
    imageUrl: aboveLowIcon,
  },
  {
    value: 'medium',
    text: 'onboarding.energyBelly.medium',
    imageUrl: mediumIcon,
  },
  {
    value: 'high',
    text: 'onboarding.energyBelly.high',
    imageUrl: highIcon,
  },
]
