import styled, { keyframes } from 'styled-components'

import { Color } from 'root-constants'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const StyledResultInMirror = {
  Wrapper: styled.div`
    height: 100%;
    padding-top: 16px;
  `,
  Subtitle: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;
  `,
  ResultInMirrorBlock: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  `,
  Photo: styled.img`
    width: 115px;
    height: 248px;
    opacity: 0;

    &:nth-child(1) {
      animation: ${appear} 1s ease-out 0.3s forwards;
    }

    &:nth-child(2) {
      animation: ${appear} 1s ease-out 1s forwards;
    }

    &:nth-child(3) {
      animation: ${appear} 1s ease-out 2s forwards;
    }
  `,
  WeightProgress: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Weight: styled.p`
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
    color: ${Color.PRIMARY};
    opacity: 0;

    & svg {
      margin-right: 13px;
    }

    &:nth-child(1) {
      animation: ${appear} 1s ease-out 0.5s forwards;
    }

    &:nth-child(2) {
      animation: ${appear} 1s ease-out 1.2s forwards;
    }

    &:nth-child(3) {
      animation: ${appear} 1s ease-out 2.2s forwards;
    }
  `,
}
