import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReviews = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ReviewsImage: styled.img`
    width: 100%;
    min-height: 288px;
    margin-bottom: 16px;
  `,
  CarouselContainer: styled.div`
    margin: 0 -16px;

    .swiper-container {
      padding-bottom: 36px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      bottom: 0;
      margin: 0 6px;
      background: ${Color.WHITE};
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.PRIMARY};
      height: 6px;
      padding: 1px 0;
      width: 21px;
      border-radius: 20px;
    }

    .swiper-slide {
      display: flex;
      height: auto;
    }
  `,
  Author: styled.p`
    font-size: 16px;
    font-weight: bold;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 88px;

    svg {
      width: 14px;
      height: 14px;
    }
  `,
  Slide: styled.div`
    max-width: 343px;
    padding: 16px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.7);
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  `,
  Title: styled.div`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
  `,
  Name: styled.div`
    color: ${Color.GRAY_80};
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}
