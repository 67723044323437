import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import resultTrainingCardAge from 'assets/images/result-personal-card-age.png'
import resultTrainingCardAgeWebp from 'assets/images/result-personal-card-age.webp'
import resultTrainingMaleCardAge from 'assets/images/result-personal-card-male-age.png'
import resultTrainingMaleCardAgeWebp from 'assets/images/result-personal-card-male-age.webp'
import femaleImagePng from 'assets/images/result-training-card-female.png'
import femaleImageWebp from 'assets/images/result-training-card-female.webp'
import maleImagePng from 'assets/images/result-training-card-male.png'
import maleImageWebp from 'assets/images/result-training-card-male.webp'

import { PageId } from 'page-constants'
import { ADULT_COHORT, Cohort, GenderStepOption } from 'root-constants'

import { StyledResultTrainingCard as S } from './ResultTrainingCard.styles'
import { FULL_BODY_AREAS_AMOUNT_V2 } from './constant'

export const ResultTrainingCard: React.FC<{ personImg?: string }> = ({
  personImg,
}) => {
  const { t } = useTranslation()

  const userAnswers = useSelector(selectAnswers)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const { currentPageId } = useGetPageInfo()

  const cohortToUse = parentCohort || cohort

  const isFemale = useMemo(
    () => userAnswers?.gender === GenderStepOption.FEMALE,
    [userAnswers],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const isAgeFlow = useMemo(
    () =>
      ADULT_COHORT.includes(cohortToUse as Cohort) ||
      currentPageId === PageId.PREPAYWALL,
    [cohortToUse, currentPageId],
  )

  const areas = useMemo(() => {
    const improveAreasFromUserAnswers =
      (userAnswers?.improveAreas as string[]) || []

    return improveAreasFromUserAnswers.map((area) => (
      <React.Fragment key={area}>
        {t(`onboarding.improveAreas.${area}`)}
        <br />
      </React.Fragment>
    ))
  }, [t, userAnswers?.improveAreas])

  const mainGoalTitle = useMemo(
    () =>
      isStayFitGoal
        ? 'onboarding.mainGoal.stayFit'
        : 'onboarding.mainGoal.loseWeight',
    [isStayFitGoal],
  )

  const isClearCohort = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW ||
      cohortToUse === Cohort.DB_CLEAR_INTRO_FAST,
    [cohortToUse],
  )

  const level = useMemo(
    () => t(`onboarding.dancingLevel.${userAnswers?.dancingLevel}`),
    [t, userAnswers?.dancingLevel],
  )

  const minutes = useMemo(
    () => t(`result.lessonLength.${userAnswers?.lessonLength}`),
    [t, userAnswers?.lessonLength],
  )

  const goalWeightTitle = useMemo(() => {
    if (isAgeFlow || isClearCohort) {
      return 'result.trainingCard.goalWeightTitleClearEdition'
    }

    return 'result.trainingCard.goalWeightTitleClearEdition'
  }, [isAgeFlow, isClearCohort])

  const weight = useMemo(() => {
    const goalWeight = userAnswers?.goalWeight || '50'
    const unit = t(`commonComponents.${userAnswers?.measurementSystem}`)

    return `${goalWeight} ${unit}`
  }, [t, userAnswers?.goalWeight, userAnswers?.measurementSystem])

  const title = useMemo(
    () =>
      isAgeFlow ? 'result.trainingCard.titleAge' : 'result.trainingCard.title',
    [isAgeFlow],
  )

  const personImgToUse = useMemo(() => {
    if (isAgeFlow) {
      return {
        png: isFemale ? resultTrainingMaleCardAge : resultTrainingCardAge,
        webp: isFemale
          ? resultTrainingCardAgeWebp
          : resultTrainingMaleCardAgeWebp,
      }
    }
    return {
      png: isFemale ? femaleImagePng : maleImagePng,
      webp: isFemale ? femaleImageWebp : maleImageWebp,
    }
  }, [isAgeFlow, isFemale])

  return (
    <>
      <S.Title>
        <Trans i18nKey={title} components={[<br />]} />
      </S.Title>
      <S.Wrapper $isAgeFlow={isAgeFlow}>
        <S.Sections $isAgeFlow={isAgeFlow}>
          <S.Section $isAgeFlow={isAgeFlow}>
            <S.Label
              $isAgeFlow={isAgeFlow}
            >{t`result.trainingCard.durationTitle`}</S.Label>
            <S.Value
              $isAgeFlow={isAgeFlow}
            >{t`result.trainingCard.durationValue`}</S.Value>
          </S.Section>
          <S.Section $isAgeFlow={isAgeFlow}>
            <S.Label
              $isAgeFlow={isAgeFlow}
            >{t`result.trainingCard.goalTitle`}</S.Label>
            <S.Value $isAgeFlow={isAgeFlow}>
              <p>
                <Trans i18nKey={mainGoalTitle} />
              </p>
            </S.Value>
          </S.Section>
          <S.Section $isAgeFlow={isAgeFlow}>
            <S.Label
              $isAgeFlow={isAgeFlow}
            >{t`result.trainingCard.dancingLevelTitle`}</S.Label>
            <S.Value $isAgeFlow={isAgeFlow}>{level}</S.Value>
          </S.Section>
          {!isStayFitFlow && (
            <S.Section $isAgeFlow={isAgeFlow}>
              <S.Label $isAgeFlow={isAgeFlow}>
                <Trans i18nKey={goalWeightTitle} />
              </S.Label>
              <S.Value $isAgeFlow={isAgeFlow}>{weight}</S.Value>
            </S.Section>
          )}
          <S.Section $isAgeFlow={isAgeFlow}>
            <S.Label
              $isAgeFlow={isAgeFlow}
            >{t`result.trainingCard.lessonLengthTitle`}</S.Label>
            <S.Value $isAgeFlow={isAgeFlow}>{minutes}</S.Value>
          </S.Section>
          <S.Section $isAgeFlow={isAgeFlow}>
            <S.Label $isAgeFlow={isAgeFlow}>
              {areas.length > 1 ? (
                <Trans i18nKey="result.trainingCard.areasTitle" />
              ) : (
                <Trans i18nKey="result.trainingCard.areaTitle" />
              )}
            </S.Label>
            <S.Value $isAgeFlow={isAgeFlow}>
              {areas.length === FULL_BODY_AREAS_AMOUNT_V2
                ? t`result.trainingCard.fullBody`
                : areas}
            </S.Value>
          </S.Section>
        </S.Sections>

        <S.ImageContainer $isAgeFlow={isAgeFlow}>
          <picture>
            <source srcSet={personImgToUse.webp} type="image/webp" />
            <S.Image src={personImg || personImgToUse.png} alt="" />
          </picture>
        </S.ImageContainer>
      </S.Wrapper>
    </>
  )
}
