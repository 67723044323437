import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'

import { Color } from 'root-constants'

const commonSubtitleStyles = css`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${Color.DARK};
`

export const StyledDynamicPage = {
  Container: styled(Container)<{
    $isAgeFlow: boolean
    $hasNavigationBtn: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        padding: 0 16px 24px;
        ${StyledAgeFlowContainer};
      `}

    padding-bottom: 24px;

    ${({ $hasNavigationBtn }) =>
      $hasNavigationBtn &&
      css`
        padding-bottom: 0;
      `}
  `,
  Subtitle: styled.h3<{ $isAgeFlow: boolean }>`
    ${commonSubtitleStyles};

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-size: 18px;
        line-height: 22px;
        color: ${Color.DARK};
      `}
  `,
  AnswerButton: styled(AnswerButton)`
    height: auto;
  `,
  Title: styled.h3<{ $isAgeFlow: boolean }>`
    margin-bottom: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      `}
  `,
  Text: styled.p<{ $isAgeFlow: boolean }>`
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.DISABLED};

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        font-size: 16px;
        line-height: 22px;
      `}
  `,
}

export const StyledDynamicPageWithImage = {
  AnimationContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  `,
  AnimationTitle: styled.h2`
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  `,
  PageSubtitle: styled.h2`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    padding-bottom: 44px;
  `,
  Animation: styled.img<{
    $isAgeFlow: boolean
  }>`
    min-height: 254px;
    min-width: 320px;

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        border-radius: 24px;
        background: ${Color.WHITE};
        padding: 10px 12px;
        box-shadow: 0 8px 20px 0 rgba(2, 146, 211, 0.15);
        margin-top: 30px;
      `}
  `,
  ButtonContainer: styled.div`
    padding-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
  PictureContainer: styled.div`
    width: 100%;
    margin: 0 auto 24px auto;
    aspect-ratio: 320/316;

    @media (min-height: 500px) and (max-height: 699px) {
      width: 100%;
      max-width: 225px;
    }

    @media (min-height: 699px) and (max-height: 799px) {
      width: 100%;
      max-width: 275px;
    }
  `,
}
