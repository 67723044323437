import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import { CDN_FOLDER_LINK, Locale } from 'root-constants'

import { StyledGoalChartAge as S } from './GoalChartAge.styles'
import { ANIMATION_PATH } from './constants'

type TProps = {
  hasTitle?: boolean
  className?: string
}

export const GoalChartAge: FC<TProps> = ({ hasTitle = true, className }) => {
  const lang = useSelector(selectLanguage)

  const [graphContentRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
    animationName: 'goalChart',
  })

  const isLongLabel = useMemo(
    () => lang === Locale.SPANISH || lang === Locale.PORTUGUESE,
    [lang],
  )

  const isGermanOrItalian = useMemo(
    () => lang === Locale.DEUTSCH || lang === Locale.ITALIAN,
    [lang],
  )

  return (
    <S.Wrapper className={className}>
      {hasTitle && (
        <S.Title>
          <Trans i18nKey="result.goalChart.title" />
        </S.Title>
      )}

      <S.GraphContent>
        <div ref={graphContentRef} />

        <S.CurrentMe>
          <Trans i18nKey="result.ageStayFitGraph.current" />
        </S.CurrentMe>

        <S.BoostMetabolism
          $isLongLabel={isLongLabel}
          $isGermanOrItalian={isGermanOrItalian}
        >
          <Trans i18nKey="result.ageStayFitGraph.boost" />
        </S.BoostMetabolism>

        <S.ToneMuscles
          $isLongLabel={isLongLabel}
          $isGermanOrItalian={isGermanOrItalian}
        >
          <Trans i18nKey="result.ageStayFitGraph.tone" />
        </S.ToneMuscles>

        <S.BurstWithEnergy $isLongLabel={isLongLabel}>
          <Trans i18nKey="result.ageStayFitGraph.burst" />
        </S.BurstWithEnergy>
      </S.GraphContent>
    </S.Wrapper>
  )
}
