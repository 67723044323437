import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const StyledHelpAchieveResults = {
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    background-color: #ecf2fa;
  `,
  Container: styled(Container)`
    padding-top: 16px;
  `,
  Animation: styled.div`
    position: relative;
    min-width: 328px;
    display: flex;
    flex-direction: column;
    padding: 20px 7px 16px 7px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.WHITE};
  `,
  Graph: styled.div`
    min-height: 175px;
  `,
  YourWeight: styled.div`
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 16px;
  `,
  DurationBlock: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: ${Color.GRAY_80};
    font-weight: 400;
    line-height: 18px;
    padding: 0 12px;
  `,
  WithDancebit: styled.div`
    position: absolute;
    gap: 5px;
    right: 26px;
    top: 13px;
    min-width: 75px;
    height: 42px;
    padding: 4px 6px 6px 6px;
    background: #55d16f;
    border-radius: 6px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-delay: 2300ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #55d16f;
      border-radius: 0 0 5px 5px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      color: ${Color.WHITE};
    }
  `,
  WithoutDancebit: styled.div`
    position: absolute;
    gap: 5px;
    right: 16px;
    bottom: 90px;
    height: 36px;
    padding: 4px 6px 6px 6px;
    background: #fde9ee;
    border-radius: 6px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    opacity: 0;
    animation-name: ${appearAnimation};
    animation-timing-function: linear;
    animation-delay: 2300ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fde9ee;
      border-radius: 0 0 5px 5px;
    }

    span {
      font-size: 11px;
      font-weight: 600;
      line-height: 13px;
      text-align: center;
      color: #f94949;
    }
  `,
  DescriptionBlock: styled.div`
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
  `,
  Logo: styled.img`
    width: 64px;
    height: 64px;
  `,
  Description: styled.p`
    font-size: 15px;
    line-height: 20px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: #ecf2fa;
  `,
}
