import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { FAQList } from 'modules/purchase/components/FAQList'
import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { HeaderWithTimerSomatic } from 'modules/purchase/components/HeaderWithTimerSomatic'
import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { IntroOfferDisclaimerV2 } from 'modules/purchase/components/IntroOfferDisclaimerV2'

import cardBgSmall from 'assets/images/image-with-wallet-small.png'
import cardBg from 'assets/images/image-with-wallet.png'

import { Color } from 'root-constants'

export const StyledPurchaseAdultNew = {
  Root: styled.div<{ $isVisible: boolean }>``,
  Wrapper: styled(Container)`
    padding-bottom: 16px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Container: styled.div`
    margin-bottom: 50px;
  `,
  FeaturedBlock: styled(FeaturedBlock)`
    margin-bottom: 50px;
  `,
  SubscriptionIntroDisclaimer: styled(IntroOfferDisclaimerV2)`
    margin-bottom: 50px;
    color: ${Color.GRAY_80};

    a {
      color: ${Color.GRAY_80};
    }
  `,
  FAQList: styled(FAQList)`
    p {
      margin-bottom: 40px;
    }
  `,
  PurchaseButton: styled(Button)`
    margin: 0 auto 24px !important;
  `,
  HowItWorks: styled(HowItWorks)`
    button {
      max-width: 100%;
    }
  `,
  RefundCard: styled.div`
    position: relative;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 16px;
    background: linear-gradient(128deg, #a6f1df 4.73%, #50b5c3 79.07%);
    width: 100%;
    min-height: 152px;
    padding: 64px 12px 16px;
    margin-top: 24px;
    margin-bottom: 16px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: -28px;
      width: 100%;
      height: 180px;
      background-image: url(${cardBg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  RefundCardSmall: styled.div`
    position: relative;
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(124deg, #a6f1df 11.31%, #50b5c3 77.01%);
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    min-height: 120px;
    padding: 24px 16px 16px 96px;
    margin-bottom: 50px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${cardBgSmall});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    strong {
      font-weight: 700;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  HeaderWithTimer: styled(HeaderWithTimerSomatic)`
    margin-bottom: 0;

    span {
      font-weight: 400;
      letter-spacing: normal;
    }
  `,
}
