import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledDreamBody as S } from './DreamBody.styles'
import {
  OPTION_VALUES,
  OPTION_VALUES_ADULT,
  OPTION_VALUES_JAPANESE,
  OPTION_VALUES_LEGS,
  OPTION_VALUES_MENOPAUSE,
  QUESTION,
} from './constants'

export const DreamBody: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const { isLegsFlow, isMenopauseFlow, isNewAdultFlow } = useCohortInfo()

  const { optionData } = useDynamicOBConfig()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isLegsFlow) return OPTION_VALUES_LEGS

    if (isMenopauseFlow) return OPTION_VALUES_MENOPAUSE

    if (isJapaneseFlow) return OPTION_VALUES_JAPANESE

    if (isNewAdultFlow) return OPTION_VALUES_ADULT

    return OPTION_VALUES
  }, [optionData, isLegsFlow, isMenopauseFlow, isJapaneseFlow, isNewAdultFlow])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerWithIconTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerWithIconTheme.DANCEBIT_JAPANESE
    }

    return answerWithIconTheme.DANCEBIT
  })()

  return (
    <S.Root $isAdult={isNewAdultFlow}>
      <S.Container isLarge $isJapanese={isJapaneseFlow}>
        <PageTitle marginBottom={24}>
          {t(`onboarding.dreamBodyBelly.title`)}
        </PageTitle>

        {options.map(({ value, text, imageUrl }) => (
          <AnswerWithIcon
            {...optionProps}
            theme={theme}
            margin="0 0 16px 0"
            disabled={isAnswersDisabled}
            value={value}
            key={value}
            iconSrc={imageUrl}
            iconWidth={isMenopauseFlow ? '152px' : '100px'}
          >
            <Trans>{text}</Trans>
          </AnswerWithIcon>
        ))}
      </S.Container>
    </S.Root>
  )
}
