import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import bgImage from 'assets/images/fitness-experience-bg.png'

import { StyledHelpAchieveResults as S } from './FitnessExperience.styles'
import { QUESTION } from './constants'

export const FitnessExperience: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(''), [
    handleContinue,
  ])

  return (
    <>
      <S.Wrapper>
        <S.Image src={bgImage} />
        <S.Container isLarge>
          <S.Title marginBottom={16}>
            {t`onboarding.fitnessExperience.title`}
          </S.Title>
          <S.Subtitle>
            <Trans i18nKey="onboarding.fitnessExperience.subtitle" />
          </S.Subtitle>
          <S.Description>
            <Trans i18nKey="onboarding.fitnessExperience.text" />
          </S.Description>
        </S.Container>
      </S.Wrapper>
      <NavigationButtonBelly type="button" onClick={handleNextClick}>
        {t`purchaseCancelOffer.questionPage.gotIt`}
      </NavigationButtonBelly>
    </>
  )
}
