import styled, { keyframes } from 'styled-components'

import couponBg from 'assets/images/discount-bg.png'

import { Color } from 'root-constants'

const popupAnimation = keyframes`
  to {
    margin-top: 25px;
  }
`

export const StyledGetDiscountPopup = {
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 16px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;
  `,
  Wrapper: styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
  `,
  Content: styled.div`
    border-radius: 24px 24px 0 0;
    padding: 16px 0;
    margin-top: 100vh;
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 25px);
    background: ${Color.WHITE};
    bottom: -100%;
    animation: ${popupAnimation} 1s ease forwards;

    img {
      margin-bottom: 16px;
    }
  `,
  GraphContainer: styled.div`
    padding: 12px 8px;
    border-radius: 16px;
    border: 1px solid #d5d7d9;
  `,
  Discount: styled.div`
    background: url(${couponBg});
    background-size: 328px 138px;
    display: flex;
    padding: 20px 16px;
    margin-bottom: 16px;
    width: 328px;
    height: 138px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 8px;

      strong {
        font-weight: 700;
        color: ${Color.PRIMARY};
      }
    }
  `,
  DiscountTitle: styled.h3`
    background: linear-gradient(74deg, #c668ff 24.77%, #fc68ff 85.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    text-align: center;
    margin-right: 43px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  `,
}
