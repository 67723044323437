import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CompositeAnswerButton } from 'components/CompositeAnswerButton'
import { Option, OptionType } from 'components/Option'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledDancingLevel as S } from './DancingLevel.styles'
import { DANCING_LEVEL, QUESTION } from './constants'

export const DancingLevel: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container $isAgeFlow={isAgeFlow}>
      <S.Title $isAgeFlow={isAgeFlow}>
        <Trans i18nKey="onboarding.dancingLevel.question" />
      </S.Title>
      <S.Subtitle>{t`onboarding.dancingLevel.subtitle`}</S.Subtitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.NEWBIE}
      >
        <CompositeAnswerButton
          title={t`onboarding.dancingLevel.newbie`}
          isAgeFlow={isAgeFlow}
        >
          {t`onboarding.dancingLevel.newbieDescription`}
        </CompositeAnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.INTERMEDIATE}
      >
        <CompositeAnswerButton
          title={t`onboarding.dancingLevel.intermediate`}
          isAgeFlow={isAgeFlow}
        >
          {t`onboarding.dancingLevel.intermediateDescription`}
        </CompositeAnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.ADVANCED}
      >
        <CompositeAnswerButton
          title={t`onboarding.dancingLevel.advanced`}
          isAgeFlow={isAgeFlow}
        >
          {t`onboarding.dancingLevel.advancedDescription`}
        </CompositeAnswerButton>
      </Option>
    </S.Container>
  )
}
