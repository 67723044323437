import styled from 'styled-components'

import { Button } from 'components/Button'
import { GoalChart } from 'components/GoalChart'

import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

export const StyledPurchaseTrial = {
  Wrapper: styled.div`
    max-width: 360px;
    padding: 0 20px;
    margin: 0 auto;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 40px;
  `,
  Button: styled(Button)`
    max-width: 320px;
  `,
  Chart: styled(GoalChart)`
    margin-bottom: 0;
  `,
  SecurityInfo: styled(SecurityInfo)`
    border-radius: 24px;

    p {
      font-size: 12px;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
