import { Cohort } from 'root-constants'

export const QUESTION = 'Creating your personalized plan...'

export const REVIEWS_MAP = {
  [Cohort.DB_SOMATIC]: {
    first: 'onboarding.preparePlanSomatic.firstReview',
    second: 'onboarding.preparePlanSomatic.secondReview',
    third: 'onboarding.preparePlanSomatic.thirdReview',
  },
  [Cohort.DB_BELLY]: {
    first: 'onboarding.preparePlanV2.review',
    second: 'onboarding.preparePlanBelly.secondReview',
    third: 'onboarding.preparePlanBelly.thirdReview',
  },
  [Cohort.DANCEBIT_CHAT_UPGRADE_ANNUALS_PDF]: {
    first: 'purchaseLiveChat.firstReview',
    third: 'purchaseLiveChat.thirdReview',
    second: 'purchaseLiveChat.secondReview',
  },
}

export const REVIEWS_MAP_CLEAR = {
  first: 'onboarding.preparePlanClear.firstReview',
  second: 'onboarding.preparePlanClear.secondReview',
  third: 'onboarding.preparePlanClear.thirdReview',
}
