import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledUpsell = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px 20px;
  `,
  Title: styled.h1`
    padding: 5px 0 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  Subtitle: styled.h2`
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  `,
  Button: styled(Button)`
    max-width: 328px;
    margin: 16px 0 12px !important;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  Link: styled.a`
    display: block;
    padding-bottom: 16px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-decoration: underline;
    color: ${Color.DISABLED};
    cursor: pointer;
  `,
  Disclaimer: styled.p`
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #51555b;
  `,
  SubscriptionDescription: styled.p`
    margin-bottom: 16px;
    padding: 24px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DISABLED};
    text-align: center;
    box-shadow: 0 8px 20px #e1e3ec;
    border-radius: 16px;

    a {
      color: ${Color.PRIMARY};
      font-weight: 600;
      text-decoration: underline;
    }
  `,
}
