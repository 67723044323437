import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color } from 'root-constants'

export const StyledCookieConsent = {
  Root: styled.div<{ $fullHeight: boolean }>`
    width: 100%;
    box-shadow: 0 8px 20px 0 #cccfde;
    background-color: ${Color.WHITE};
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    ${({ $fullHeight }) =>
      $fullHeight &&
      css`
        height: 100dvh;
        overflow-y: auto;
      `};
  `,
  HeaderWrapper: styled.header<{ $isBellyFlow: boolean }>`
    position: relative;
    z-index: 1;
    padding: ${({ $isBellyFlow }) => ($isBellyFlow ? '8px 0' : '10px 0')};
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};
    cursor: pointer;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.DARK};

    svg {
      margin-right: 10px;
    }
  `,
  HeaderBelly: styled.div`
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;

    svg {
      margin-right: 12px;
      border-radius: 11px;
      box-shadow: 0 2px 10px 0 rgba(167, 167, 167, 0.3);
    }
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 16px;
  `,
  Container: styled(Container)`
    margin: 16px auto;
  `,
  Separator: styled.div`
    background: ${Color.GRAY_20};
    height: 1px;
    width: 100%;
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  SecondaryTitle: styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 12px;
  `,
  Description: styled.p`
    color: ${Color.GRAY_80};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;

    a {
      color: ${Color.GRAY_80};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-decoration: underline;
    }
  `,
  PrimaryButton: styled(Button)`
    border-radius: 8px;
    height: 38px;
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  SecondaryButton: styled(Button)`
    border-radius: 8px;
    height: 38px;
    background-color: ${Color.GRAY_10};
    margin-bottom: 8px;
    color: ${Color.PRIMARY};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Settings: styled.ul`
    border: 1px solid #e5e7eb;
    border-bottom: none;
    margin-bottom: 24px;
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  `,
  SettingsTitle: styled.h2`
    color: ${Color.DARK};
    margin: 32px auto 20px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  Setting: styled.details`
    &[open] summary::after {
      background-image: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 160px;
    }
  `,
  SettingName: styled.summary`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 41px;
    font-style: normal;
    min-height: 64px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: 1px solid #e5e7eb;
    position: relative;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      color: ${Color.DARK};
      flex-basis: 200px;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${plusIcon});
      background-size: contain;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  `,
  SettingDetails: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 16px;
    color: ${Color.GRAY_80};
    border-bottom: 1px solid #e5e7eb;
  `,
  ActiveLabel: styled.strong`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: end;
    min-width: 96px;
    color: ${Color.PRIMARY};
  `,
}
