import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 16px;
    border-radius: 30px;
    border: 1px solid #d3e6ff;
    background: ${Color.WHITE};
  `,
  Title: styled.h2`
    margin-bottom: 12px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  `,
  Text: styled.p`
    color: ${Color.GRAY_80};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-align: center;

    a {
      color: ${Color.GRAY_80};
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    width: 97px;
    height: 96px;
    margin-bottom: 12px;
  `,
}
