import nineToFiveIconJa from 'assets/images/flow-belly/icons/briefcase-ja.svg'
import nineToFiveIcon from 'assets/images/flow-belly/icons/briefcase.svg'
import flexibleIconJa from 'assets/images/flow-belly/icons/cup-coffee-ja.svg'
import flexibleIcon from 'assets/images/flow-belly/icons/cup-coffee.svg'
import nightShiftsIconJa from 'assets/images/flow-belly/icons/evening-ja.svg'
import nightShiftsIcon from 'assets/images/flow-belly/icons/evening.svg'
import atHomeParentIconJa from 'assets/images/flow-belly/icons/sofa-ja.svg'
import atHomeParentIcon from 'assets/images/flow-belly/icons/sofa.svg'
import noWorkIconJa from 'assets/images/flow-belly/icons/umbrella-ja.svg'
import noWorkIcon from 'assets/images/flow-belly/icons/umbrella.svg'

export const QUESTION = 'What’s your work schedule like?'

export const OPTION_VALUES = [
  {
    value: 'nineToFive',
    title: 'onboarding.workSchedule.fromNineToFive',
    iconSrc: nineToFiveIcon,
  },
  {
    value: 'flexible',
    title: 'onboarding.workSchedule.flexible',
    iconSrc: flexibleIcon,
  },
  {
    value: 'nightShifts',
    title: 'onboarding.workSchedule.nightShifts',
    iconSrc: nightShiftsIcon,
  },
  {
    value: 'noWork',
    title: 'onboarding.workSchedule.noWork',
    iconSrc: noWorkIcon,
  },
  {
    value: 'atHomeParent',
    title: 'onboarding.workSchedule.atHomeParent',
    iconSrc: atHomeParentIcon,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    value: 'nineToFive',
    title: 'onboarding.workSchedule.fromNineToFive',
    iconSrc: nineToFiveIconJa,
  },
  {
    value: 'flexible',
    title: 'onboarding.workSchedule.flexible',
    iconSrc: flexibleIconJa,
  },
  {
    value: 'nightShifts',
    title: 'onboarding.workSchedule.nightShifts',
    iconSrc: nightShiftsIconJa,
  },
  {
    value: 'noWork',
    title: 'onboarding.workSchedule.noWork',
    iconSrc: noWorkIconJa,
  },
  {
    value: 'atHomeParent',
    title: 'onboarding.workSchedule.atHomeParent',
    iconSrc: atHomeParentIconJa,
  },
]
