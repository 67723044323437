import busyLife from 'assets/images/flow-belly/icons/busy-life.svg'
import covid from 'assets/images/flow-belly/icons/covid.svg'
import divorce from 'assets/images/flow-belly/icons/divorce.svg'
import financialChallenges from 'assets/images/flow-belly/icons/financial-challenges.svg'
import injury from 'assets/images/flow-belly/icons/injury.svg'
import none from 'assets/images/flow-belly/icons/none.svg'
import other from 'assets/images/flow-belly/icons/other-events.svg'
import pregnancy from 'assets/images/flow-belly/icons/pregnancy.svg'
import slowMetabolism from 'assets/images/flow-belly/icons/slow-metabolism.svg'
import workPressure from 'assets/images/flow-belly/icons/work-pressure.svg'

export const QUESTION =
  'Have any of the following events led to weight gain in the last few years?'

export const SELECT_NONE = 'none'

export const OPTION_VALUES = [
  {
    text: 'onboarding.weightGainEvents.workPressure',
    value: 'workPressure',
    imageUrl: workPressure,
  },
  {
    text: 'onboarding.weightGainEvents.busyLife',
    value: 'busyLife',
    imageUrl: busyLife,
  },
  {
    text: 'onboarding.weightGainEvents.divorce',
    value: 'divorce',
    imageUrl: divorce,
  },
  {
    text: 'onboarding.weightGainEvents.slowMetabolism',
    value: 'slowMetabolism',
    imageUrl: slowMetabolism,
  },
  {
    text: 'onboarding.weightGainEvents.pregnancy',
    value: 'pregnancy',
    imageUrl: pregnancy,
  },
  {
    text: 'onboarding.weightGainEvents.financialChallenges',
    value: 'financialChallenges',
    imageUrl: financialChallenges,
  },
  {
    text: 'onboarding.weightGainEvents.covid',
    value: 'covid',
    imageUrl: covid,
  },
  {
    text: 'onboarding.weightGainEvents.injury',
    value: 'injury',
    imageUrl: injury,
  },
  {
    text: 'onboarding.weightGainEvents.other',
    value: 'other',
    imageUrl: other,
  },
  {
    text: 'onboarding.weightGainEvents.none',
    value: SELECT_NONE,
    imageUrl: none,
  },
]
