import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import imageBg from 'assets/images/flow-belly/how-long-train-bg.png'
import imageBgLegs from 'assets/images/flow-belly/how-long-train-legs-bg.png'
import imageBgJa from 'assets/images/how-long-ja.png'

import { PageId } from 'page-constants'
import { GROWTHBOOK_EXPERIMENT, Gender } from 'root-constants'

import { StyledHowLongTrain as S } from './HowLongTrain.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const HowLongTrain: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()
  const { isLegsFlow, isNewAdultFlow } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.LESSON_LENGTH,
    question: QUESTION,
    nextPagePath,
  })

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const bgImage = useMemo(() => {
    if (imageUrl) return imageUrl

    if (isLegsFlow) return imageBgLegs

    if (isJapaneseFlow) return imageBgJa

    return imageBg
  }, [imageUrl, isJapaneseFlow, isLegsFlow])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container
      isLarge
      $bgImageUrl={bgImage}
      $isJapanese={isJapaneseFlow}
      $isNewAdultFlow={isNewAdultFlow}
    >
      <PageTitle marginBottom={8}>
        {t('onboarding.howLongTrain.title')}
      </PageTitle>
      <S.PageDescription marginBottom={24}>
        {t('onboarding.howLongTrain.description')}
      </S.PageDescription>

      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          theme={theme}
          maxWidth="180px"
          margin="0 0 16px 0"
          value={value}
          disabled={isAnswersDisabled}
          key={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
