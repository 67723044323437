import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithCheckbox, answerWithCheckboxTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { CDN_FOLDER_LINK, GROWTHBOOK_EXPERIMENT, Locale } from 'root-constants'

import { StyledDancingStyleBelly as S } from './DancingStyleBelly.styles'
import {
  ADDITIONAL_STYLES,
  NEW_DANCE_STYLES,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const DancingStyleBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const { isTTClearFlow, isNewAdultFlow } = useCohortInfo()
  const lang = useSelector(selectLanguage)
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const options = useMemo(() => {
    if (lang === Locale.ENGLISH) {
      return {
        ...ADDITIONAL_STYLES,
        ...OPTION_VALUES,
      }
    }

    return OPTION_VALUES
  }, [lang])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerWithCheckboxTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerWithCheckboxTheme.DANCEBIT_JAPANESE
    }

    return answerWithCheckboxTheme.DANCEBIT
  })()

  return (
    <>
      <S.Container
        isLarge
        $isJapanese={isJapaneseFlow}
        $isNewAdultFlow={isNewAdultFlow}
      >
        <PageTitle marginBottom={8}>
          <Trans i18nKey="onboarding.dancingStyle.question" />
        </PageTitle>
        <S.Subtitle>{t`onboarding.dancingStyle.description`}</S.Subtitle>

        {Object.values(options).map((style) => (
          <AnswerWithCheckbox
            {...optionProps}
            theme={theme}
            isFullWidth
            padding="8px 16px 8px 8px"
            margin="0 0 16px 0"
            borderRadius="16px"
            height="116px"
            iconSrc={checkIcon}
            key={style}
            value={style}
            checked={answers.includes(style)}
          >
            <S.DanceCard $isSelected={answers.includes(style)}>
              <video
                muted
                loop
                autoPlay
                controls={false}
                playsInline
                poster={`${CDN_FOLDER_LINK}/videos/${style}Cover.jpg`}
              >
                <source
                  src={`${CDN_FOLDER_LINK}/videos/${style}.mp4`}
                  type="video/mp4"
                />
              </video>
              <S.DanceInfo>
                {NEW_DANCE_STYLES.includes(style) && (
                  <S.New>{t`onboarding.dancingStyle.new`}</S.New>
                )}
                <S.DanceTitle>
                  {t(`onboarding.dancingStyle.${style}`, {
                    context: 'v2',
                  })}
                </S.DanceTitle>
                <S.Description>
                  {t(`onboarding.dancingStyle.${style}Description`, {
                    context: isTTClearFlow ? 'clear' : '',
                  })}
                </S.Description>
              </S.DanceInfo>
            </S.DanceCard>
          </AnswerWithCheckbox>
        ))}
      </S.Container>

      <S.NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
        $isNewAdultFlow={isNewAdultFlow}
      >
        {t`actions.continue`}
      </S.NavigationButtonBelly>
    </>
  )
}
