import gobletIcon from 'assets/images/flow-belly/icons/goblet.svg'
import injuryIcon from 'assets/images/flow-belly/icons/injury.svg'
import happyIcon from 'assets/images/happy-icon.svg'
import likeIcon from 'assets/images/like-icon.svg'
import lovelyIcon from 'assets/images/lovely-icon.svg'

export const QUESTION =
  'After reaching your goal weight, how would you see yourself?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.seeYourself.proudOfMyself',
    value: 'proudOfMyself',
    imageUrl: gobletIcon,
  },
  {
    text: 'onboarding.seeYourself.feelingGreat',
    value: 'feelingGreat',
    imageUrl: lovelyIcon,
  },
  {
    text: 'onboarding.seeYourself.believeMyself',
    value: 'believeMyself',
    imageUrl: happyIcon,
  },
  {
    text: 'onboarding.seeYourself.feelEmpowered',
    value: 'feelEmpowered',
    imageUrl: injuryIcon,
  },
  {
    text: 'onboarding.seeYourself.worryLess',
    value: 'worryLess',
    imageUrl: likeIcon,
  },
]
