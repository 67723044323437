import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES, QUESTION } from './constants'

export const WhatsHoldYou: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <div>
      <Container>
        <PageTitle marginBottom={32}>
          {t('onboarding.whatsHoldYou.title')}
        </PageTitle>

        {OPTION_VALUES.map(({ value, title }) => (
          <Option
            {...optionProps}
            key={value}
            value={value}
            checked={answers.includes(value)}
          >
            <AnswerButton withCheckbox>{t(title)}</AnswerButton>
          </Option>
        ))}
      </Container>

      <NavigationButton
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </NavigationButton>
    </div>
  )
}
