import phonePic1 from 'assets/images/purchase-phone-slider-1.png'
import phonePic2 from 'assets/images/purchase-phone-slider-2.png'
import phonePic3 from 'assets/images/purchase-phone-slider-3.png'

export const SLIDES = [
  {
    title: 'purchaseV2.slider.challenge',
    img: phonePic1,
    subtitle: 'result.phoneSlider.subtitle',
    alt: '28-day dancing challenge',
  },
  {
    title: 'purchaseV2.slider.withExperts',
    img: phonePic2,
    subtitle: '',
    alt: 'Set of programs and live events',
  },
  {
    title: 'purchaseV2.slider.collection',
    img: phonePic3,
    subtitle: '',
    alt: 'Collection of workouts',
  },
]
