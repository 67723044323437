import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectIsStayFitFlow, selectUserAge } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FAQList } from 'modules/purchase/components/FAQList'
import { HeaderWithTimerSomatic } from 'modules/purchase/components/HeaderWithTimerSomatic'
import { MoneyBackCancelOffer } from 'modules/purchase/components/MoneyBackCancelOffer'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE_SOMATIC,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { Reviews } from 'modules/purchase/components/Reviews'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import { CUSTOMER_REVIEWS_SOMATIC } from 'modules/purchase/pages/PurchaseWithoutIntro/constant'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'
import discountBadge from 'assets/images/sprite/discount-icon.svg'

import { PageId } from 'page-constants'
import {
  GROWTHBOOK_EXPERIMENT,
  MAIN_GOALS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseCancelOffer as S } from './PurchaseCancelOfferSomatic.styles'

export const PurchaseCancelOfferSomatic: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const isStayFit = useSelector(selectIsStayFitFlow)
  const age = useSelector(selectUserAge)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const {
    currentMeasurementSystem,
    fetchingActionsList,
    subscriptions,
    userAnswers,
    discountPercentage,
  } = usePurchaseStore()

  const { benefits, benefitsBlockTitle, buttonText } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenId: ScreenId.CANCEL_DEFAULT,
    isAmplitudeEvent: true,
  })

  const hasIncludedVat = useVatInfo()
  const hasHighPrices = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_360)

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.CANCEL_OFFER, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))
    dispatch(setScreenIdAction(ScreenId.CANCEL_DEFAULT))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
      ),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [age, dispatch, hasHighPrices, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.CANCEL_OFFER,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          <HeaderWithTimerSomatic onButtonClick={handleShowPayment} />
          <S.Wrapper>
            <S.PreviousDiscount>
              <Trans
                i18nKey="purchaseCancelOfferSomatic.previousPlan"
                values={{
                  discount: discountPercentage - 15,
                }}
              />
            </S.PreviousDiscount>

            <S.PersonalPlan>
              <SvgImage svg={discountBadge} width={40} />
              <p>
                <Trans
                  i18nKey="purchaseCancelOfferSomatic.personalPlan"
                  values={{
                    discount: discountPercentage,
                  }}
                />
              </p>
            </S.PersonalPlan>

            <S.SubscriptionsBlockIntroOffer
              elemForComparisonRef={buttonWrapperElementRef}
              hasInnerDisclaimer={false}
              isCancelOffer
            >
              <S.Button
                data-order="2"
                data-text="get_my_plan"
                type="button"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </S.Button>
            </S.SubscriptionsBlockIntroOffer>
            <MoneyBackCancelOffer />
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {benefitsBlockTitle || t`purchaseCancelOffer.appBenefits.title`}
              </S.ProgramPlanTitle>
              <ul>
                {benefits.length ? (
                  benefits.map(({ text, id }) => (
                    <S.ListItem key={id}>{text}</S.ListItem>
                  ))
                ) : (
                  <>
                    <S.ListItem>
                      <Trans
                        i18nKey="purchaseCancelOffer.appBenefits.targetWeight"
                        values={{
                          context: isStayFit
                            ? MAIN_GOALS.STAY_FIT
                            : MAIN_GOALS.LOSE_WEIGHT,
                          weight: userAnswers?.[PageId.GOAL_WEIGHT],
                          unit: t(
                            `commonComponents.${currentMeasurementSystem}`,
                          ),
                        }}
                      />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.sustainSuccess" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.moreEnergy" />
                    </S.ListItem>
                    {!isStayFit && (
                      <S.ListItem>
                        <Trans i18nKey="purchaseCancelOffer.appBenefits.betterHealth" />
                      </S.ListItem>
                    )}
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.motivation" />
                    </S.ListItem>
                    <S.ListItem>
                      <Trans i18nKey="purchaseCancelOffer.appBenefits.happierYou" />
                    </S.ListItem>
                  </>
                )}
              </ul>
            </S.ProgramPlanContainer>
            <PhotoResultV3
              femaleSlides={SLIDES_FEMALE_SOMATIC}
              maleSlides={SLIDES_MALE}
            />
            <FAQList />
            <Reviews reviews={CUSTOMER_REVIEWS_SOMATIC} />

            <Button
              data-order="3"
              data-text="get_my_plan"
              type="button"
              margin="0 0 24px"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </Button>

            <S.IntroOfferDisclaimer />
          </S.Wrapper>
          <S.FeaturedBlockContainer>
            <S.FeaturedBlockTitle>{t`purchase1.featuredBlockTitle`}</S.FeaturedBlockTitle>
            <picture>
              <source srcSet={featuredImageWebp} type="image/webp" />
              <img src={featuredImagePng} alt="body" />
            </picture>
          </S.FeaturedBlockContainer>
        </div>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
          isCancelOfferPage
        />
      </S.CheckoutContainer>
    </>
  )
}
