import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { FreeOfferCard } from 'modules/purchase/components/FreeOfferCard'
import { PhotoResultFreeOffer } from 'modules/purchase/components/PhotoResultFreeOffer'
import { RESULTS } from 'modules/purchase/components/PhotoResultFreeOffer/constants'
import { ReceivedPlanBelly } from 'modules/purchase/components/ReceivedPlanBelly'
import { ReviewsFreeOffer } from 'modules/purchase/components/ReviewsFreeOffer'
import { CUSTOMER_REVIEWS } from 'modules/purchase/components/ReviewsFreeOffer/constants'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubheaderWithTimerFreeOffer } from 'modules/purchase/components/SubheaderWithTimerFreeOffer'
import { DEFAULT_DYNAMIC_DISCOUNT } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CUSTOMER_REVIEWS_BELLY,
  RESULTS_BELLY,
} from 'modules/purchase/pages/PurchaseFreeOffer/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import whiteCheck from 'assets/images/sprite/white-check.svg'

import {
  Color,
  GROWTHBOOK_EXPERIMENT,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseFreeOffer as S } from './PurchaseFreeOffer.styles'

export const PurchaseFreeOffer: FC = () => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const goalWeight = useSelector(selectCurrentUserGoalWeight) || 44
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const dispatch = useDispatch()

  const { isSaleFlow } = useCohortInfo()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const hasPurchaseFreeOfferBellyTest = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1042,
  )

  const screenName = hasPurchaseFreeOfferBellyTest
    ? ScreenName.FAT_BELLY
    : ScreenName.ONBOARDING

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
  } = usePurchaseStore()

  const { buttonText } = useDynamicPaywallConfig()

  usePurchaseAnalytics({
    screenName,
    screenId: ScreenId.PAYWALL_LEGS,
  })

  useViewportValue(screenName, !isCheckoutShown)

  const hasIncludedVat = useVatInfo()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(screenName))
    dispatch(setScreenIdAction(ScreenId.PAYWALL_LEGS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat, screenName])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback(
    (event) => {
      setIsCheckoutShown(true)

      eventLogger.logPlansPageButtonTap({
        screenName,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })
    },
    [screenName],
  )

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.RootWrapper>
          <S.BannerImage $isSaleFlow={isSaleFlow}>
            <S.TitleText>
              {t(
                isSaleFlow
                  ? 'purchaseFreeOfferBelly.title'
                  : 'purchaseFreeOffer.title',
              )}
            </S.TitleText>
            <S.GoalWeight>
              {goalWeight} {t(`commonComponents.${currentMeasurementSystem}`)}
            </S.GoalWeight>
            <S.SubtitleText>{t`purchaseFreeOffer.subtitle`}</S.SubtitleText>
          </S.BannerImage>
          <S.DiscountActivated
            $marginBottom={32}
            $theme={dynamicDiscount?.theme}
          >
            {t('purchaseFreeOfferBelly.discountActivated', {
              discount: dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT,
            })}
            <SvgImage svg={whiteCheck} width={20} height={20} />
          </S.DiscountActivated>
          <div>
            <S.Wrapper isLarge>
              <ReviewsFreeOffer
                reviews={isSaleFlow ? CUSTOMER_REVIEWS_BELLY : CUSTOMER_REVIEWS}
              />
            </S.Wrapper>

            <SubheaderWithTimerFreeOffer
              theme={dynamicDiscount?.theme}
              elemForComparisonRef={buttonWrapperElementRef}
              onButtonClick={handleShowPayment}
            />

            <S.Wrapper isLarge>
              {isSaleFlow ? (
                <ReceivedPlanBelly
                  elemForComparisonRef={buttonWrapperElementRef}
                />
              ) : (
                <S.ReceivedPlan
                  elemForComparisonRef={buttonWrapperElementRef}
                />
              )}

              <FreeOfferCard />
              <S.SubscriptionsBlockIntroOffer disclaimerVisible={false}>
                <S.Button
                  type="button"
                  data-order="2"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </S.SubscriptionsBlockIntroOffer>
              <S.IntroOfferDisclaimer $marginBottom={40} />
              <S.Container>
                <FeaturedBlock />
              </S.Container>
              <S.MoneyBackGuarantee customBackground={Color.WHITE} />
              <PhotoResultFreeOffer
                reviews={isSaleFlow ? RESULTS_BELLY : RESULTS}
              />
              <S.FAQList />
            </S.Wrapper>

            <S.DiscountActivated
              $marginBottom={40}
              $theme={dynamicDiscount?.theme}
            >
              {t('purchaseFreeOfferBelly.discountActivated', {
                discount: dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT,
              })}
              <SvgImage svg={whiteCheck} width={20} height={20} />
            </S.DiscountActivated>

            <S.Wrapper>
              <S.SubscriptionsBlockIntroOffer disclaimerVisible={false}>
                <S.OfferCard />
                <S.Button
                  type="button"
                  data-order="3"
                  data-text="get_my_plan"
                  onClick={handleShowPayment}
                >
                  {buttonText || t`actions.getMyPlan`}
                </S.Button>
              </S.SubscriptionsBlockIntroOffer>
              <S.IntroOfferDisclaimer $marginBottom={60} />
              <SecurityInfo />
            </S.Wrapper>
          </div>
        </S.RootWrapper>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
