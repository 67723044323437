import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import progressCheckImg from 'assets/images/check-icon-rounded.svg'

import { Color, MediaBreakpoint } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  $pointsNumber: number
}

type TStepNumberProps = {
  $isActive: boolean
  $isPassed?: boolean
}

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledPostPaymentProgressBar = {
  Wrapper: styled.div`
    margin: 50px auto 20px;
    display: flex;
    justify-content: center;
  `,
  StepBlock: styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    max-width: ${({ $pointsNumber }) =>
      `${(250 - 18 * ($pointsNumber + 1)) / $pointsNumber}px`};
    height: 1px;
    appearance: none;
    border: none;
    display: block;
    margin: 0 4px;

    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background: #eceaf4;
    }

    &::-webkit-progress-value {
      background: ${Color.PRIMARY};
    }

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      max-width: ${({ $pointsNumber }) =>
        `${(290 - 18 * ($pointsNumber + 1)) / $pointsNumber - 5}px`};
    }
  `,
  Step: styled.div<TStepNumberProps>`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${Color.GRAY_20};
    transition: background 0.2s linear;
    color: ${Color.BLACK};
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ $isPassed }) =>
      $isPassed &&
      `   
          background: url(${progressCheckImg}) center center no-repeat;
          background-size: contain;
          color: ${Color.GRAY_80}; 
        `}
  `,
  StepText: styled.span`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
    min-width: 68px;
  `,
}
