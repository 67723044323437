import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import amandaExpert from 'assets/images/amanda-expert.png'
import oliverExpert from 'assets/images/oliver-expert.png'
import shieldIcon from 'assets/images/shield-icon.png'
import tieExpert from 'assets/images/tie-expert.png'

import { StyledOurPrograms as S } from './OurPrograms.styles'

export const OurPrograms: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.ShieldIcon>
        <img src={shieldIcon} alt="World-class experts" />
      </S.ShieldIcon>
      <S.Title>{t(`purchaseUpgradeMealPlan.ourPrograms.title`)}</S.Title>

      <S.Card>
        <S.CardImg src={amandaExpert} alt="Amanda Enget" />

        <S.CardText>
          <S.CardTitle>
            {t(`purchaseUpgradeMealPlan.ourPrograms.experts.firstExpert.name`)}
          </S.CardTitle>
          <S.CardDescription>
            {t(
              `purchaseUpgradeMealPlan.ourPrograms.experts.firstExpert.description`,
            )}
          </S.CardDescription>
        </S.CardText>
      </S.Card>

      <S.Card>
        <S.CardImg src={oliverExpert} alt="Dr. Oliver Carey" />

        <S.CardText>
          <S.CardTitle>
            {t(`purchaseUpgradeMealPlan.ourPrograms.experts.secondExpert.name`)}
          </S.CardTitle>
          <S.CardDescription>
            {t(
              `purchaseUpgradeMealPlan.ourPrograms.experts.secondExpert.description`,
            )}
          </S.CardDescription>
        </S.CardText>
      </S.Card>

      <S.Card>
        <S.CardImg src={tieExpert} alt="Tie Scott" />

        <S.CardText>
          <S.CardTitle>
            {t(`purchaseUpgradeMealPlan.ourPrograms.experts.thirdExpert.name`)}
          </S.CardTitle>
          <S.CardDescription>
            {t(
              `purchaseUpgradeMealPlan.ourPrograms.experts.thirdExpert.description`,
            )}
          </S.CardDescription>
        </S.CardText>
      </S.Card>
    </S.Wrapper>
  )
}
