import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectAnswers,
  selectCurrentUserMeasurementSystem,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectHasCancelOffer,
  selectLanguage,
  selectPaymentConfig,
  selectScreenId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
  selectUserName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserAvailableSpins,
  selectUserCountryCode,
  selectUserOnboardingEmail,
  selectUserPaymentSystem,
  selectUserStatus,
} from 'root-redux/selects/user'

import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectDynamicDiscount,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionDiscountAmount,
  selectSubscriptionDiscountPercentage,
  selectSubscriptionFullPrice,
  selectSubscriptionFullPriceTax,
  selectSubscriptionId,
  selectSubscriptionLookupKey,
  selectSubscriptionOldPriceBefore33PercentDiscount,
  selectSubscriptionOldPriceBefore50PercentDiscount,
  selectSubscriptionOldPriceBefore75PercentDiscount,
  selectSubscriptionOldPriceBeforeCustomDiscount,
  selectSubscriptionOldPriceTrialBeforeCustomDiscount,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPeriodPriceTax,
  selectTaxAmount,
  selectUpgradeDiffSubscriptionAmountToPay,
  selectUpgradeDiffSubscriptionFullPrice,
  selectUpgradeDiffSubscriptionOldPrice,
} from 'modules/purchase/redux/selects/common'

export const usePurchaseStore = () => {
  const availableSpins = useSelector(selectUserAvailableSpins)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const countryCode = useSelector(selectUserCountryCode)
  const currency = useSelector(selectCurrency)
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const lookupKey = useSelector(selectSubscriptionLookupKey)
  const discountAmount = useSelector(selectSubscriptionDiscountAmount)
  const discountPercentage = useSelector(selectSubscriptionDiscountPercentage)
  const email = useSelector(selectUserOnboardingEmail)
  const fetchingActionsList = useSelector(selectActionList)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const fullPriceTax = useSelector(selectSubscriptionFullPriceTax)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const language = useSelector(selectLanguage)
  const oldPriceBefore33PercentDiscount = useSelector(
    selectSubscriptionOldPriceBefore33PercentDiscount,
  )
  const oldPriceBefore50PercentDiscount = useSelector(
    selectSubscriptionOldPriceBefore50PercentDiscount,
  )
  const oldPriceBefore75PercentDiscount = useSelector(
    selectSubscriptionOldPriceBefore75PercentDiscount,
  )
  const oldPriceBeforeCustomPercentDiscount = useSelector(
    selectSubscriptionOldPriceBeforeCustomDiscount,
  )
  const oldTrialPriceBeforeCustomPercentDiscount = useSelector(
    selectSubscriptionOldPriceTrialBeforeCustomDiscount,
  )
  const paymentConfig = useSelector(selectPaymentConfig)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const productId = useSelector(selectProductId)
  const screenName = useSelector(selectScreenName)
  const screenId = useSelector(selectScreenId)
  const selectedProductId = useSelector(selectProductId)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const subscriptions = useSelector(selectSubscriptionList)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPriceTax = useSelector(selectSubscriptionTrialPeriodPriceTax)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const userAnswers = useSelector(selectAnswers)
  const userName = useSelector(selectUserName)
  const userStatus = useSelector(selectUserStatus)
  const uuid = useSelector(selectUUID)
  const amountToPay = useSelector(selectUpgradeDiffSubscriptionAmountToPay)
  const upgradeFullPrice = useSelector(selectUpgradeDiffSubscriptionFullPrice)
  const upgradeOldPrice = useSelector(selectUpgradeDiffSubscriptionOldPrice)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const taxAmount = useSelector(selectTaxAmount)

  return {
    availableSpins,
    cohort,
    countryCode,
    currency,
    currentMeasurementSystem,
    discountAmount,
    discountPercentage,
    email,
    fetchingActionsList,
    fullPrice,
    fullPriceTax,
    amountToPay,
    hasCancelOffer,
    language,
    oldPriceBefore33PercentDiscount,
    oldPriceBefore50PercentDiscount,
    oldPriceBefore75PercentDiscount,
    oldPriceBeforeCustomPercentDiscount,
    oldTrialPriceBeforeCustomPercentDiscount,
    paymentConfig,
    periodName,
    periodQuantity,
    productId,
    screenName,
    screenId,
    selectedProductId,
    selectedSubscription,
    selectedSubscriptionId,
    subscriptions,
    threeDSecureIframeUrl,
    trialPeriodDays,
    trialPrice,
    trialPriceTax,
    trialPriceId,
    userAnswers,
    userName,
    userStatus,
    uuid,
    stripeAccountId,
    stripeAccountName,
    paymentMethod,
    paymentSystem,
    lookupKey,
    upgradeFullPrice,
    upgradeOldPrice,
    dynamicDiscount,
    taxAmount,
    parentCohort,
  }
}
