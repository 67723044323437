import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledPhotoResultV3 as S } from './PhotoResultFreeOffer.styles'
import { CUSTOMER_REVIEWS } from './constants'

export const PhotoResultFreeOffer: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.Title>{t`purchaseWithoutIntro.photoResult.title`}</S.Title>
        <S.Description>{t`purchaseWithoutIntro.photoResult.description`}</S.Description>
      </S.TitleContainer>

      {CUSTOMER_REVIEWS.map(
        ({ icon, date, name, image, likesCount, commentsCount, text }) => (
          <S.ReviewCard
            key={name}
            icon={icon}
            date={date}
            name={name}
            image={image}
            likesCount={likesCount}
            commentsCount={commentsCount}
            text={text}
          />
        ),
      )}
    </S.Wrapper>
  )
}
