import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDesignNewLife = {
  Subtitle: styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    color: ${Color.DARK};
    text-align: center;
  `,
  Container: styled(Container)<{ $isAdult: boolean }>`
    margin-top: 16px;

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  Animation: styled.div`
    min-height: 348px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;
      `}
  `,
}
