import React from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { PagesSource } from 'root-constants'

import { StyledFooter as S } from './Footer.styles'

export type TProps = {
  className?: string
}

export const Footer: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.Footer className={className}>
      <p>{t`footer.disclaimer`}</p>
      <S.List>
        <S.ListItem>
          <TermsOfUseLink source={PagesSource.EMAIL} />
        </S.ListItem>
        <S.ListItemSeparator>|</S.ListItemSeparator>
        <S.ListItem>
          <PrivacyPolicyLink source={PagesSource.EMAIL} />
        </S.ListItem>
      </S.List>
      <p>{t('footer.allRightsReserved', { year: new Date().getFullYear() })}</p>
    </S.Footer>
  )
}
