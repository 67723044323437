import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const WorkScheduleBelly: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )
  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t('onboarding.workSchedule.question')}
      </PageTitle>

      {OPTION_VALUES.map(({ value, title, iconSrc }) => (
        <AnswerWithIcon
          {...optionProps}
          value={value}
          theme={answerTheme.DANCEBIT}
          iconSrc={iconSrc}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          height="80px"
          padding="16px"
          maxWidth="345px"
          iconAlignSelf="center"
          iconWidth="40px"
          margin="0 0 16px 0"
          color={Color.DARK}
          key={value}
          disabled={isAnswersDisabled}
        >
          {t(title)}
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
