import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import reviewsBg from 'assets/images/reviews-bg.png'
import starSvg from 'assets/images/sprite/star.svg'

import { StyledReviews as S } from './ReviewsNew.styles'
import { REVIEWS } from './constants'

type TProps = {
  className?: string
}

export const ReviewsNew: React.FC<TProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const { customerReviews } = useDynamicPaywallConfig()
  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )

  return (
    <S.Wrapper className={className}>
      <S.ReviewsImage src={reviewsBg} />
      <S.CarouselContainer>
        <Carousel
          spaceBetween={12}
          slidesPerView={1.15}
          centeredSlides
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {(customerReviews.length ? customerReviews : REVIEWS).map(
            ({ name, text, date, title }) => (
              <SwiperSlide key={name}>
                <S.Slide>
                  <S.Header>
                    <div>
                      <S.Title>{t(title)}</S.Title>
                      <S.Rating>
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                        <SvgImage svg={starSvg} />
                      </S.Rating>
                    </div>
                    <div>
                      <S.Name>
                        {t(date)} <br />
                        {t(name)}
                      </S.Name>
                    </div>
                  </S.Header>
                  <S.Text>
                    <Trans
                      i18nKey={text}
                      values={{
                        context: currentMeasurementSystem,
                      }}
                    />
                  </S.Text>
                </S.Slide>
              </SwiperSlide>
            ),
          )}
        </Carousel>
      </S.CarouselContainer>
    </S.Wrapper>
  )
}
