import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledHealthProfile = {
  Container: styled(Container)`
    margin-top: 16px;
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
  `,
  Img: styled.img`
    margin: 0 auto 24px;
    max-width: 277px;
    min-height: 277px;
  `,
  ChartContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  ParamsTop: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  Activity: styled.div`
    color: #8379f0;
  `,
  MentalHealth: styled.div`
    color: #95a755;
  `,
  ParamsBottom: styled.div`
    display: flex;
    justify-content: center;
  `,
  StressManagement: styled.div`
    color: #ff6e8a;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
}
