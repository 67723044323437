import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledDancingStyleBelly = {
  DanceCard: styled.div<{
    $isSelected: boolean
  }>`
    display: flex;
    flex-direction: row;

    video {
      width: 72px;
      height: 100px;
      border-radius: 14px;
      margin-right: 16px;
    }
  `,
  DanceTitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.DARK};
  `,
  Description: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
  New: styled.div`
    padding: 2px 6px;
    margin-bottom: 4px;
    border-radius: 6px;
    background: ${Color.PRIMARY};
    color: ${Color.WHITE};
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: fit-content;
  `,
  DanceInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 190px;
  `,
  Subtitle: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Container: styled(Container)<{
    $isJapanese: boolean
    $isNewAdultFlow: boolean
  }>`
    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        ${StyledJapaneseContainer};
      `}
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{
    $isNewAdultFlow: boolean
  }>`
    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;
      `}
  `,
}
