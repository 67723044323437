import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledAppStats = {
  Root: styled.div`
    margin-bottom: 24px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 24px auto;
    }
  `,
  Title: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 34px;
  `,
  Card: styled.div`
    padding: 24px 0;
    background: #2b292e;
    border-radius: 24px;
    min-height: 164px;
  `,
  Image: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    & svg {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  `,
  CardTitle: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.WHITE};
    margin-bottom: 16px;
    text-align: center;
  `,
  CardFootnote: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  CardText: styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${Color.GRAY_40};
  `,
}
