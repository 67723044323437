import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CompositeAnswerButton } from 'components/CompositeAnswerButton'
import { Option, OptionType } from 'components/Option'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledActivityLevel as S } from './ActivityLevel.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const ActivityLevel: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const textVariant = useMemo(
    () =>
      isAgeFlow
        ? 'onboarding.activityLevelAgeFlow'
        : 'onboarding.activityLevel',
    [isAgeFlow],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container $isAgeFlow={isAgeFlow}>
      <S.Title>
        <Trans i18nKey={`${textVariant}.question`} />
      </S.Title>
      <S.Subtitle>{t(`${textVariant}.subtitle`)}</S.Subtitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SEDENTARY}
      >
        <CompositeAnswerButton
          isAgeFlow={isAgeFlow}
          title={t(`${textVariant}.sedentary`)}
        >
          {t(`${textVariant}.sedentaryDescription`)}
        </CompositeAnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.OCCASIONALLY_ACTIVE}
      >
        <CompositeAnswerButton
          isAgeFlow={isAgeFlow}
          title={t(`${textVariant}.occasionallyActive`)}
        >
          {t(`${textVariant}.occasionallyActiveDescription`)}
        </CompositeAnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.MODERATELY_ACTIVE}
      >
        <CompositeAnswerButton
          isAgeFlow={isAgeFlow}
          title={t(`${textVariant}.moderatelyActive`)}
        >
          {t(`${textVariant}.moderatelyActiveDescription`)}
        </CompositeAnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.VERY_ACTIVE}
      >
        <CompositeAnswerButton
          isAgeFlow={isAgeFlow}
          title={t(`${textVariant}.veryActive`)}
        >
          {t(`${textVariant}.veryActiveDescription`)}
        </CompositeAnswerButton>
      </Option>
    </S.Container>
  )
}
