import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
}

type TTimerTextProps = TTimerContainerProps
type TButtonContainerProps = TTimerContainerProps

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div<TTimerContainerProps>`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    justify-content: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'space-evenly' : 'center'};
    align-items: center;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    max-height: 56px;
    margin: 0 auto;
    padding: 8px 0;
    background-color: #ffecb5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    z-index: 10;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  TimerText: styled.span<TTimerTextProps>`
    ${({ $isButtonVisible }) =>
      $isButtonVisible &&
      css`
        max-width: 113px;
        padding-left: 6px;
        text-align: left;
      `},
  `,
  SubheaderTimer: styled(Timer)`
    min-width: 88px;
    padding: 0 10px 0 12px;

    & div {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      gap: 8px;

      & > div {
        color: #f84560;
        border-radius: 12px;
        padding: 4px;
        width: 40px;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  `,
  ButtonContainer: styled.div<TButtonContainerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button<{ $isBellyFlow: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 112px;
    margin: 5px;
    border: none;
    border-radius: ${({ $isBellyFlow }) => ($isBellyFlow ? `30px` : '12px')};
    background-color: ${Color.PRIMARY};
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  `,
}
