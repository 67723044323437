import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import mapImg from 'assets/images/happy-women-map.png'

import { StyledHappyWomen as S } from './HappyWomen.styles'
import { QUESTION } from './constants'

export const HappyWomen: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <img src={mapImg} alt="women-map" height="210px" width="100%" />
          <S.PageTitle marginBottom={8}>
            <Trans i18nKey="onboarding.happyWomen.title" />
          </S.PageTitle>
          <S.Subtitle>{t('onboarding.happyWomen.subtitle')}</S.Subtitle>
        </S.Container>
      </ContainerBeforePinnedButton>
      <S.NavigationButtonBelly onClick={handleClick}>
        {t('actions.continue')}
      </S.NavigationButtonBelly>
    </>
  )
}
