import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { Cohort, Gender } from 'root-constants'

import { StyledHowLongTrain as S } from './HowLongTrain.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const HowLongTrain: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const gender = useSelector(selectCurrentUserGender)
  const cohortToUse = parentCohort || cohort

  const { imageUrls } = useDynamicOBConfig()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.LESSON_LENGTH,
    question: QUESTION,
    nextPagePath,
  })

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )
  return (
    <S.Container
      $isLegsFlow={cohortToUse === Cohort.DB_LEGS}
      isLarge
      $bgImageUrl={imageUrl}
    >
      <PageTitle marginBottom={8}>
        {t('onboarding.howLongTrain.title')}
      </PageTitle>
      <S.PageDescription marginBottom={24}>
        {t('onboarding.howLongTrain.description')}
      </S.PageDescription>

      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          theme={answerTheme.DANCEBIT}
          maxWidth="180px"
          margin="0 0 16px 0"
          value={value}
          disabled={isAnswersDisabled}
          key={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
