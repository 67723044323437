import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledGender = {
  Container: styled(Container)`
    &::before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        102.14% 70.44% at 34% 31.4%,
        #f3f9ff 0%,
        #e0f0ff 39.74%,
        #a4ceff 100%
      );
      background-size: cover;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.PRIMARY};
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 16px;
  `,
  TickerContainer: styled.div`
    width: 100%;
    margin-bottom: 16px;
  `,
  AnswerButton: styled(AnswerButton)`
    position: relative;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0 4px 14px 0 rgba(7, 19, 50, 0.08);
    padding: 11px 24px;

    input:checked + & {
      border: 2px solid ${Color.PRIMARY};
      padding: 10px 23px;
      background: #cfe7ff;
    }

    input:checked + & p {
      color: ${Color.DARK};
    }
  `,
  Text: styled.p`
    color: ${Color.DARK};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  ArrowContainer: styled.div`
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    background-color: ${Color.PRIMARY};
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Terms: styled.div`
    position: relative;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: ${Color.GRAY_80};
    margin: 16px auto 0;
    padding-bottom: 14px;

    a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY_80};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
