import { StyledJapaneseContainer } from 'pages/questions/TenMillionPeopleJapan/TenMillionPeopleJapan.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

type TContainerProps = {
  $bgImageUrl?: string
  $isJapanese: boolean
  $isNewAdultFlow: boolean
}

export const StyledTimesPerWeek = {
  Container: styled(Container)<TContainerProps>`
    background-image: ${({ $bgImageUrl }) =>
      $bgImageUrl && `url(${$bgImageUrl})`};
    background-repeat: no-repeat;
    min-height: 560px;
    background-size: 375px 500px;
    background-position: bottom;

    ${({ $isJapanese }) =>
      $isJapanese &&
      css`
        background-size: 375px 583px;
        ${StyledJapaneseContainer};
      `}

    ${({ $isNewAdultFlow }) =>
      $isNewAdultFlow &&
      css`
        ${StyledNewAdultContainer};
      `}
  `,
}
