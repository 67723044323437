import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DEFAULT_DYNAMIC_DISCOUNT } from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledHeaderWithTimer as S } from './HeaderWithTimerV2.styles'

const ButtonStyles = {
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: 'normal',
}

type TProps = {
  onButtonClick: (event) => void
  className?: string
}

export const HeaderWithTimerV2: FC<TProps> = ({ onButtonClick, className }) => {
  const { t } = useTranslation()

  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return (
    <S.TimerContainer className={className}>
      <S.TextContainer>
        <S.Text>
          <Trans
            i18nKey="purchaseV2.timerText"
            values={{
              discount: dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT,
            }}
          />
        </S.Text>
        <S.Timer />
      </S.TextContainer>

      <S.ButtonContainer>
        <S.Button
          type="button"
          data-order="1"
          data-text="get_my_plan"
          style={ButtonStyles}
          onClick={onButtonClick}
        >
          {t('purchaseWithoutIntro.subheaderTimer.getNowButton')}
        </S.Button>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
