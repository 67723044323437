import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Option, OptionType } from 'components/Option'
import { PageDescription } from 'components/PageDescription'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { StyledGender as S } from './Gender.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Gender: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logSelectedGender(value)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Container $isAgeFlow={isAgeFlow}>
      <PageTitle
        marginBottom={10}
        isAgeFlow={isAgeFlow}
      >{t`onboarding.gender.question`}</PageTitle>
      <PageDescription
        marginBottom={30}
      >{t`onboarding.gender.description`}</PageDescription>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={isAgeFlow ? OPTION_VALUES.FEMALE : OPTION_VALUES.MALE}
      >
        <AnswerButton isAgeFlow={isAgeFlow}>
          {isAgeFlow ? t`onboarding.gender.female` : t`onboarding.gender.male`}
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={isAgeFlow ? OPTION_VALUES.MALE : OPTION_VALUES.FEMALE}
      >
        <AnswerButton isAgeFlow={isAgeFlow}>
          {isAgeFlow ? t`onboarding.gender.male` : t`onboarding.gender.female`}
        </AnswerButton>
      </Option>
    </S.Container>
  )
}
