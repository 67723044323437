import styled from 'styled-components'

import { PageDescription } from 'components/PageDescription'

import { Color } from 'root-constants'

export const StyledHowLongTrain = {
  PageDescription: styled(PageDescription)`
    color: ${Color.DARK};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
