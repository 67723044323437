import styled from 'styled-components'

import priceLabel from 'assets/images/green-price-label.png'

import { Color } from 'root-constants'

export const StyledSubscriptionsBlockMealPlan = {
  Wrapper: styled.div`
    margin-bottom: 32px;
  `,
  Title: styled.h2`
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: center;
  `,
  MealPlan: styled.div`
    background: linear-gradient(90.55deg, #28b446 0%, #56d26f 100%),
      linear-gradient(90.55deg, #28b446 0%, #56d26f 100%);
    border-radius: 20px;
    padding: 4px 2px 2px 2px;
  `,
  MealPlanTitle: styled.h5`
    font-size: 12px;
    line-height: 14px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    margin-bottom: 2px;
  `,
  MealPlanCard: styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
    position: relative;
    background-color: ${Color.WHITE};
  `,
  MealPlanCardIcon: styled.img`
    max-width: 20px;
    margin-right: 8px;
  `,
  MealPlanCardText: styled.span`
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK};
    max-width: 94px;
    text-transform: uppercase;
    padding-right: 4px;
  `,
  MealPlanCardLabel: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 87px;
    min-height: 48px;
    padding-left: 6px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: ${Color.WHITE};
    margin: 0 0 0 auto;
    background: url(${priceLabel}) no-repeat center;
    background-size: contain;
    text-transform: uppercase;
  `,
  Plans: styled.div`
    padding-top: 8px;
    background: linear-gradient(0deg, #03aaf5, #03aaf5),
      linear-gradient(90.55deg, #2b64e2 0%, #03aaf5 100%);
    border-radius: 24px;
  `,
  PlansTitle: styled.h4`
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 8px;
    color: ${Color.WHITE};
  `,
  Options: styled.div`
    padding: 12px 8px 8px 8px;
    background: ${Color.LIGHT};
    border-radius: 24px;
  `,
}
