import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useViewportValue } from 'hooks/useViewportValue'

import { BodyAgeV2 } from 'modules/purchase/components/BodyAgeV2'
import { FeaturedBlockUpgrade } from 'modules/purchase/components/FeaturedBlockUpgrade'
import { HelpsBenefits } from 'modules/purchase/components/HelpsBenefits'
import { HELPS_BENEFITS_DATA } from 'modules/purchase/components/HelpsBenefits/constants'
import { IntroMediaV2 } from 'modules/purchase/components/IntroMediaV2'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfoUpgrade } from 'modules/purchase/components/SecurityInfoUpgrade'
import { SubscriptionBonusItem } from 'modules/purchase/components/SubscriptionBonusItem'
import { UpgradeSubscriptionItem } from 'modules/purchase/components/UpgradeSubscriptionItem'
import { PDF_COHORTS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { usePurchaseUpgradeAnalytics } from 'modules/purchase/hooks/usePurchaseUpgradeAnalytics'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/pages/PurchaseVariant2/constants'
import { purchaseUpgrade } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  ScreenId,
  ScreenName,
  SubscriptionListType,
} from 'root-constants'

import { StyledPurchaseUpgrade as S } from './PurchaseUpgrade.styles'

export const PurchaseUpgrade: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const {
    fetchingActionsList,
    periodQuantity,
    amountToPay,
    upgradeFullPrice,
    subscriptions,
  } = usePurchaseStore()

  const { buttonText } = useDynamicPaywallConfig()

  const currency = useSelector(selectUserPaymentCurrency)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const [isPricesFetchingStarted, setIsPricesFetchingStarted] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const cohortToUse = parentCohort || cohort
  const screenId = PDF_COHORTS.includes(cohortToUse as Cohort)
    ? ScreenId.UPGRADE_PDF
    : ScreenId.UPGRADE

  usePurchaseUpgradeAnalytics(ScreenName.LIVECHAT_UPGRADE, screenId)

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useViewportValue(ScreenName.LIVECHAT_UPGRADE)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(setScreenIdAction(screenId))
    dispatch(getSubscriptionListAction(SubscriptionListType.UPGRADE))
    setIsPricesFetchingStarted(true)
  }, [dispatch, screenId])

  useEffect(() => {
    if (!isPricesFetchingStarted || !hasPrices) return

    if (!subscriptions.length) {
      goTo({
        pathname: PageId.DOWNLOAD,
        search,
      })
    }
  }, [subscriptions, search, isPricesFetchingStarted, hasPrices])

  useEffect(() => {
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  useEffect(() => {
    if (upgradeFullPrice && periodQuantity) {
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          subscription_upgrade_price: amountToPay || upgradeFullPrice,
          subscription_upgrade_duration: periodQuantity,
        }),
      )
    }
  }, [upgradeFullPrice, amountToPay, dispatch, currency, periodQuantity])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const upgradePlan = useCallback(
    (event) => {
      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.LIVECHAT_UPGRADE,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      addToCart()
    },
    [addToCart],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <IntroMediaV2 />
      <S.Wrapper>
        <S.PhotoResultV3
          femaleSlides={SLIDES_FEMALE}
          maleSlides={SLIDES_MALE}
          hasTitle={false}
          hasBackground
          isUpgrade
        />
        <UpgradeSubscriptionItem />
        <S.ButtonContainer>
          <Button
            type="button"
            data-order="1"
            data-text="continue"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </S.ButtonContainer>
        <S.Disclaimer />
        <SubscriptionBonusItem />
        <BodyAgeV2 />
        <S.ButtonContainer>
          <Button
            type="button"
            data-order="2"
            data-text="continue"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </S.ButtonContainer>
        <S.BenefitsWrapper>
          <S.BenefitsTitle>
            {t`purchaseUpgrade.helpsBenefits.mainTitle`}
          </S.BenefitsTitle>
          {HELPS_BENEFITS_DATA.map(({ iconSrc, title, description }) => (
            <HelpsBenefits
              iconSrc={iconSrc}
              title={t(title)}
              description={t(description)}
              key={title}
            />
          ))}
        </S.BenefitsWrapper>
        <UpgradeSubscriptionItem />
        <S.ButtonContainer>
          <Button
            type="button"
            data-order="3"
            data-text="continue"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </S.ButtonContainer>
        <S.Disclaimer />
        <SecurityInfoUpgrade />
        <S.FeaturedBlockContainer>
          <FeaturedBlockUpgrade />
        </S.FeaturedBlockContainer>
      </S.Wrapper>
      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        tryAgain={() => addToCart()}
      />
    </>
  )
}
