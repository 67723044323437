import { TSlide } from 'modules/purchase/constants'

import image1 from 'assets/images/before-after-1.png'
import image2 from 'assets/images/before-after-2.png'
import image3 from 'assets/images/before-after-3.png'

export const SLIDES: TSlide[] = [
  {
    id: '1',
    imageUrl: image1,
    author: 'purchaseWithoutIntro.photoResult.author1.author',
    review: 'purchaseWithoutIntro.photoResult.author1.review',
    weight: 'purchaseWithoutIntro.photoResult.author1.weightNew',
  },
  {
    id: '2',
    imageUrl: image2,
    author: 'purchaseWithoutIntro.photoResult.author2.author',
    review: 'purchaseWithoutIntro.photoResult.author2.review',
    weight: 'purchaseWithoutIntro.photoResult.author2.weightNew',
  },
  {
    id: '3',
    imageUrl: image3,
    author: 'purchaseWithoutIntro.photoResult.author3.author',
    review: 'purchaseWithoutIntro.photoResult.author3.review',
    weight: 'purchaseWithoutIntro.photoResult.author3.weightNew',
  },
]
