import styled from 'styled-components'

import { Container } from 'components/Container'
import { Option } from 'components/Option'

import backImg from 'assets/images/background-history.png'
import quotesImg from 'assets/images/right-quotes.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledPersonalHistory = {
  BgImage: styled.div`
    position: absolute;
    z-index: -1;
    background-image: url(${backImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
    height: 275px;
    transform: translateX(-50%);
    left: 50%;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Container: styled(Container)``,
  TitleContainer: styled.div`
    color: ${Color.DARK};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  `,
  SVGContainer: styled.div`
    width: 24px;
    height: 24px;

    margin-bottom: 12px;

    border-radius: 4px;

    background: ${Color.PRIMARY};
    background-image: url(${quotesImg});
    background-repeat: no-repeat;
    background-position: center;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;

    margin-bottom: 12px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  `,
  BeforeAfterBlock: styled.div`
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: ${Color.DARK};
  `,
  BeforeAfterImg: styled.img`
    margin-bottom: 16px;
    min-height: 220px;
  `,
  ButtonBlock: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 8px;
  `,
  ButtonTitle: styled.span`
    margin-bottom: 16px;

    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;
    width: 100%;
  `,
  HistoryText: styled.p`
    margin-bottom: 16px;

    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.DARK};

    &:last-child {
      margin-bottom: 32px;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex: 1;
    border-radius: 16px;

    color: ${Color.WHITE};

    &:first-child {
      background-color: #7369e4;
      margin-right: 12px;
    }

    &:last-child {
      background-color: ${Color.PRIMARY};
    }
  `,
  Action: styled.div`
    width: 100%;
    min-width: 80px;
    max-width: 160px;
    min-height: 55px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 8px;

    transition: all 0.2s ease-out;

    border: 0;
    border-radius: 10px;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    color: inherit;
    background-color: inherit;
    cursor: pointer;
  `,
  Text: styled.p`
    margin-bottom: 16px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  TrusedBlock: styled.div`
    max-width: 200px;
    margin: 0 auto;

    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & img:last-child {
      transform: scale(-1, 1);
    }
  `,
  Brench: styled.img``,
  TrusedInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;

    & span > strong {
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
    }

    & span:first-child {
      margin-bottom: 5px;

      color: ${Color.LIGHT_GREY};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  `,
  Disclaimer: styled.div`
    margin-bottom: 12px;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.LIGHT_GREY};
    text-align: center;
  `,
  Terms: styled.div`
    z-index: 2;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT_GREY};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
