import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { StyledPersonalizedPlan as S } from './PersonalizedPlan.styles'

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLDivElement>
}

export const PersonalizedPlan: React.FC<IProps> = ({
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  return (
    <S.Wrapper>
      <S.PlanDescription>
        {t`purchaseVariantEmail.planDescription`}
      </S.PlanDescription>
      {isTargetHidden && <S.Text>{t`purchaseVariantEmail.planText`}</S.Text>}
      <S.TimerContainer data-is-target-hidden={isTargetHidden}>
        <S.TimerText data-is-target-hidden={isTargetHidden}>
          {t`purchaseVariantEmail.planText`}
        </S.TimerText>
        <S.PersonalizedTimer $isTargetHidden={isTargetHidden} />
      </S.TimerContainer>
    </S.Wrapper>
  )
}
