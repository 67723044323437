import { PaymentMethod } from 'modules/purchase/constants'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchase/types'

import { IDynamicDiscount } from 'models/payment.model'
import { IAppState } from 'models/store.model'
import { ISubscription, TOldPrices } from 'models/subscriptions.model'

import {
  CENTS_IN_DOLLAR,
  DEFAULT_CURRENCY,
  EMPTY_OLD_PRICE,
  PlanAddition,
  TimeInterval,
} from 'root-constants'

export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.selectedSubscription?.id || ''
export const selectSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectUpgradeSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.upgradeSubscription?.lookupKey || ''
export const selectSubscriptionTrialLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionPeriodQuantity = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectSubscriptionOldPriceBeforeCustomDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .beforeCustomDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionOldPriceBefore33PercentDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .before33PercentDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionOldPriceBefore50PercentDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .before50PercentDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionOldPriceBefore75PercentDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .before75PercentDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionOldPriceTrialBeforeCustomDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.trialPrices.oldPrices
    .beforeCustomDiscount || EMPTY_OLD_PRICE
export const selectSubscriptionPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0
export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionFullPriceTax = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPriceTax || 0
export const selectSubscriptionDiscountAmount = (state: IAppState): number =>
  state.payment.selectedSubscription?.introDiff?.discountAmount || 0
export const selectSubscriptionDiscountPercentage = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.introDiff?.discountPercentage || 0
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodPriceTax = (
  state: IAppState,
): number => state.payment.selectedSubscription?.trialPrices.fullPriceTax || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency.toLowerCase() || DEFAULT_CURRENCY
export const selectProductId = (state: IAppState): string =>
  state.payment.selectedSubscription?.product || ''
export const selectUpgradeDiffSubscriptionAmountPaid = (
  state: IAppState,
): number =>
  (state.payment.selectedSubscription?.upgradeDiff?.amountPaid || 0) /
  CENTS_IN_DOLLAR
export const selectUpgradeDiffSubscriptionAmountToPay = (
  state: IAppState,
): number =>
  (state.payment.selectedSubscription?.upgradeDiff?.amountToPay || 0) /
  CENTS_IN_DOLLAR
export const selectUpgradeDiffSubscriptionFullPrice = (
  state: IAppState,
): number =>
  (state.payment.selectedSubscription?.upgradeDiff?.fullPrice || 0) /
  CENTS_IN_DOLLAR
export const selectUpgradeDiffSubscriptionOldPrice = (
  state: IAppState,
): number =>
  (state.payment.selectedSubscription?.upgradeDiff?.oldFullPrice || 0) /
  CENTS_IN_DOLLAR
export const selectIsSubscriptionUpgraded = (state: IAppState): boolean =>
  state.payment.isSubscriptionUpgraded || false
export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.paypalPlanId || ''
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectCreatedSubscriptionId = (state: IAppState): string =>
  state.payment.createdSubscriptionId
export const selectPlanAdditions = (state: IAppState): PlanAddition[] =>
  state.payment.planAdditions
export const selectTaxAmount = (state: IAppState): number =>
  state.payment.taxAmount || 0
export const selectHighestDiscountPercentage = (state: IAppState): number =>
  state.payment.highestDiscountPercentage || 0
export const selectSubscriptionTrialDayPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.daily || 0
export const selectSubscriptionMainDayPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.daily || 0
export const selectPaymentMethod = (state: IAppState): PaymentMethod =>
  state.payment.paymentMethod
export const selectPrimerClientSessionToken = (state: IAppState): string =>
  state.payment.primerClientSessionToken
export const selectBackupPrimerSubscriptionConfig = (
  state: IAppState,
): IPrimerSubscriptionBackupConfig | null =>
  state.payment.backupPrimerSubscriptionConfig
export const selectIsFirstPaymentRetryPassed = (state: IAppState): boolean =>
  state.payment.isFirstPaymentRetryPassed
export const selectDynamicDiscount = (
  state: IAppState,
): IDynamicDiscount | null => state.payment.dynamicDiscount || null
