import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import commentWithHeartPng from 'assets/images/comment-with-heart.png'
import commentWithHeartWebp from 'assets/images/comment-with-heart.webp'
import starSvg from 'assets/images/sprite/star.svg'

import { CUSTOMER_REVIEWS } from 'root-constants'

import { StyledCustomerReviews as S } from './CustomerReviews.styles'
import { TReview } from './constants'

type TCustomerReviews = {
  customerReviews?: TReview[]
  isHeaderVisible?: boolean
  className?: string
}

export const CustomerReviews: React.FC<TCustomerReviews> = ({
  isHeaderVisible = true,
  className = '',
  customerReviews = CUSTOMER_REVIEWS,
}) => {
  const { t } = useTranslation()
  const {
    customerReviews: reviews,
    customerReviewsTitle,
  } = useDynamicPaywallConfig()

  return (
    <S.Wrapper className={className}>
      {isHeaderVisible && (
        <S.Header data-testid="customer-review">
          <S.Title>
            {customerReviewsTitle || (
              <Trans
                i18nKey="purchaseCancelOffer.customerReviews.title"
                components={[<strong />]}
              />
            )}
          </S.Title>
          <S.Picture>
            <source srcSet={commentWithHeartWebp} type="image/webp" />
            <img src={commentWithHeartPng} alt="comment-with-heart" />
          </S.Picture>
        </S.Header>
      )}

      <S.Comments>
        {(reviews.length ? reviews : customerReviews).map(
          ({ author, review }) => (
            <S.Comment key={author}>
              <S.Author>{t(author)}</S.Author>
              <S.Rating>
                <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
                <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
                <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
                <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
                <SvgImage svg={starSvg} data-testid="svg-image-test-id" />
              </S.Rating>
              <S.Text>{t(review)}</S.Text>
            </S.Comment>
          ),
        )}
      </S.Comments>
    </S.Wrapper>
  )
}
