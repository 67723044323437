import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import arrowSvg from 'assets/images/sprite/arrow.svg'

import { Cohort, DynamicDiscountType, PagesSource } from 'root-constants'

import { StyledWelcome as S } from './Welcome.styles'
import { DEFAULT_OPTIONS, OPTION_VALUES, QUESTION } from './constants'

export const Welcome: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const cohortToUse = parentCohort || cohort

  const [answer, setAnswer] = useState<string>('')

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { title, subtitle, stickerText, optionData } = useDynamicOBConfig()
  const { headline, subtext } = useTitleFromUrl()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useStartSession()

  const { isSaleFlow } = useCohortInfo()

  const isSaleFlowEnabled = useMemo(
    () =>
      isSaleFlow || cohort === Cohort.DB_WHEEL || cohort === Cohort.DB_WHEEL2,
    [cohort, isSaleFlow],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { titleToShow, subtitleToShow } = useMemo(() => {
    const defaultSubtitle = (
      <Trans
        i18nKey="onboarding.welcome.subtitle"
        components={{
          strong: <strong />,
          styled: <p />,
        }}
        values={{ context: cohortToUse }}
      />
    )

    const defaultTitle = t('onboarding.welcome.title', {
      context: cohortToUse,
    })

    return {
      titleToShow: headline || title || defaultTitle,
      subtitleToShow: subtext || subtitle || defaultSubtitle,
    }
  }, [cohortToUse, headline, subtext, subtitle, t, title])

  const cards = useMemo(() => {
    if (optionData && optionData.length) return optionData

    return OPTION_VALUES[cohortToUse as Cohort] || DEFAULT_OPTIONS
  }, [cohortToUse, optionData])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq &&
          window.fbq(
            'track',
            'CustomizeProduct',
            {},
            { eventID: isPersonalDataAllowed ? uuid : '' },
          )
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        setAnswer(value)
      },
    }),
    [
      isPersonalDataAllowed,
      pageId,
      dispatch,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Root>
      {isSaleFlowEnabled &&
        dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}

      {isSaleFlowEnabled &&
        dynamicDiscount?.type !== DynamicDiscountType.STATIC && <Ticker />}

      {stickerText && (
        <S.Sticker>
          <p>{stickerText}</p>
        </S.Sticker>
      )}
      <S.Container>
        <S.Title $hasSticker={!!stickerText}>
          <Trans>{titleToShow}</Trans>
        </S.Title>
        <S.Subtitle>
          <Trans>{subtitleToShow}</Trans>
        </S.Subtitle>
        <S.Actions>
          {cards.map(({ value, text, imageUrl, from, to }) => (
            <S.StyledOption
              {...optionProps}
              key={value}
              value={value}
              $isChecked={answer === value}
              disabled={isAnswersDisabled}
            >
              <S.Image
                $isChecked={answer === value}
                src={imageUrl}
                alt={value}
              />
              {text ? (
                <S.Action $isChecked={answer === value}>
                  <Trans>{text}</Trans>{' '}
                  <SvgImage svg={arrowSvg} height={10} width={16} />
                </S.Action>
              ) : (
                <S.Action $isChecked={answer === value}>
                  <Trans
                    i18nKey="onboarding.welcome.optionTitle"
                    values={{
                      context: from,
                    }}
                    components={{
                      age: to
                        ? t('onboarding.welcome.age', {
                            from,
                            to,
                          })
                        : from,
                      icon: <SvgImage svg={arrowSvg} height={10} width={16} />,
                    }}
                  />
                </S.Action>
              )}
            </S.StyledOption>
          ))}
        </S.Actions>
        <S.Terms>
          <p>{t('footer.disclaimer')}</p>
          <TermsOfUseLink source={PagesSource.LANDING} />
          <S.Separator />
          <PrivacyPolicyLink source={PagesSource.LANDING} />
          <p>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </p>
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
