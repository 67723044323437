import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserMeasurementSystem } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import arrowSvg from 'assets/images/sprite/right-yellow-arrow.svg'
import starSvg from 'assets/images/sprite/star.svg'

import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  Color,
  MeasurementSystem,
} from 'root-constants'

import { StyledHelpedMillionsPeople as S } from './HelpedMillionsPeople.styles'
import { ANSWER, QUESTION, REVIEW_SLIDES } from './constants'

Swiper.use([Pagination])
export const HelpedMillionsPeople: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(
    selectCurrentUserMeasurementSystem,
  )
  const userCountryCode = useSelector(selectUserCountryCode)

  const isCountryWithImperialSystem = COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(
    userCountryCode,
  )

  const { isNewAdultFlow } = useCohortInfo()

  const [isOpen, setIsOpen] = useState(false)

  const isMetricSystem = useMemo(() => {
    if (currentMeasurementSystem) {
      return currentMeasurementSystem === MeasurementSystem.METRIC
    }

    return !isCountryWithImperialSystem
  }, [currentMeasurementSystem, isCountryWithImperialSystem])

  const handleSwitch = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(ANSWER), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Wrapper $isAdult={isNewAdultFlow}>
          <Container isLarge>
            <PageTitle marginBottom={16} highlightColor={Color.PRIMARY}>
              <Trans i18nKey="onboarding.helpedMillionsPeople.title" />
            </PageTitle>

            <S.CarouselContainer>
              <Carousel
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true }}
              >
                {REVIEW_SLIDES.map(
                  ({ img, name, review, weight, isBigReview }) => (
                    <SwiperSlide key={name}>
                      <S.Review>
                        <S.Image src={img} />

                        <S.ReviewText $isBigReview={!!isBigReview}>
                          <S.BeforeAfterBlock>
                            <span>
                              <Trans
                                i18nKey={weight}
                                values={{
                                  context: isMetricSystem
                                    ? 'beforeKg'
                                    : 'beforeLbs',
                                }}
                              />
                            </span>

                            <SvgImage svg={arrowSvg} width={16} height={20} />

                            <span>
                              <Trans
                                i18nKey={weight}
                                values={{
                                  context: isMetricSystem
                                    ? 'afterKg'
                                    : 'afterLbs',
                                }}
                              />
                            </span>
                          </S.BeforeAfterBlock>

                          <S.ReviewerContainer>
                            <S.Reviewer>
                              <S.Name>
                                <Trans i18nKey={name} />
                              </S.Name>

                              <S.Rating>
                                <SvgImage
                                  svg={starSvg}
                                  width={16}
                                  height={16}
                                />
                                <SvgImage
                                  svg={starSvg}
                                  width={16}
                                  height={16}
                                />
                                <SvgImage
                                  svg={starSvg}
                                  width={16}
                                  height={16}
                                />
                                <SvgImage
                                  svg={starSvg}
                                  width={16}
                                  height={16}
                                />
                                <SvgImage
                                  svg={starSvg}
                                  width={16}
                                  height={16}
                                />
                              </S.Rating>
                            </S.Reviewer>

                            <S.Comment
                              $isBigReview={!!isBigReview}
                              $isOpen={isOpen}
                            >
                              <Trans
                                i18nKey={review}
                                values={{
                                  context: isMetricSystem && 'metric',
                                }}
                              />
                            </S.Comment>

                            {isBigReview && (
                              <S.ViewMore onClick={handleSwitch}>
                                <Trans
                                  i18nKey={
                                    isOpen
                                      ? 'onboarding.helpedMillionsPeople.showLess'
                                      : 'onboarding.helpedMillionsPeople.viewMore'
                                  }
                                />
                              </S.ViewMore>
                            )}
                          </S.ReviewerContainer>
                        </S.ReviewText>
                      </S.Review>
                    </SwiperSlide>
                  ),
                )}
              </Carousel>
            </S.CarouselContainer>
          </Container>
        </S.Wrapper>
      </ContainerBeforePinnedButton>

      <S.NavigationButtonBelly
        $isAdult={isNewAdultFlow}
        type="button"
        onClick={handleClick}
      >
        {t`actions.doIt`}
      </S.NavigationButtonBelly>
    </>
  )
}
