import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InputWithLabel } from 'components/InputWithLabel'
import { PasswordInput } from 'components/PasswordInput'

import { TInputName } from 'hooks/useNameField'
import { TRepeatPassword } from 'hooks/useRepeatPasswordInputField'

import { IInputFieldState } from 'models/inputField.model'

type TProps = {
  name: TInputName
  repeatPassword: TRepeatPassword
  email: IInputFieldState
  password: IInputFieldState
  setName: Dispatch<SetStateAction<TInputName>>
  setEmail: Dispatch<SetStateAction<IInputFieldState>>
  setPassword: Dispatch<SetStateAction<IInputFieldState>>
  setRepeatPassword: Dispatch<SetStateAction<TRepeatPassword>>
}

export const EmailLogin: React.FC<TProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  name,
  setName,
  repeatPassword,
  setRepeatPassword,
}) => {
  const { t } = useTranslation()

  const errorText = useMemo(() => {
    if (!email.isValid) return email.firebaseError || email.validationText

    return ''
  }, [email.isValid, email.validationText, email.firebaseError])

  const handleInputChange = useCallback(
    <T extends IInputFieldState | TRepeatPassword>(
      stateCallback: Dispatch<SetStateAction<T>>,
      inputValue: Partial<T>,
    ) => {
      stateCallback((prevState) => ({ ...prevState, ...inputValue }))
    },
    [],
  )

  return (
    <>
      <InputWithLabel
        maxLength={30}
        isValid={name.isValid}
        validationText={name.validationText}
        labelName={t`login.userName`}
        value={name.value}
        marginBottom={25}
        onChange={(event) =>
          handleInputChange(setName, { value: event.target.value.trim() })
        }
        onFocus={() => handleInputChange(setName, { isFocused: true })}
        onBlur={() => handleInputChange(setName, { isFocused: false })}
        hasValidationIcon
      />

      <InputWithLabel
        value={email.value}
        isValid={email.isValid}
        validationText={errorText}
        labelName={t`login.emailPlaceholder`}
        marginBottom={25}
        inputHeight={64}
        onChange={(event) =>
          handleInputChange(setEmail, { value: event.target.value.trim() })
        }
        onFocus={() => handleInputChange(setEmail, { isFocused: true })}
        onBlur={() => handleInputChange(setEmail, { isFocused: false })}
        hasValidationIcon
      />

      <PasswordInput
        value={password.value}
        isValid={password.isValid}
        validationText={password.validationText}
        labelName={t`login.passwordPlaceholder`}
        marginBottom={25}
        inputHeight={64}
        onChange={(event) =>
          handleInputChange(setPassword, { value: event.target.value })
        }
        onFocus={() => handleInputChange(setPassword, { isFocused: true })}
        onBlur={() => handleInputChange(setPassword, { isFocused: false })}
      />

      <PasswordInput
        value={repeatPassword.value}
        isValid={repeatPassword.isValid}
        marginBottom={36}
        labelName={t`login.repeatPasswordPlaceholder`}
        validationText={repeatPassword.validationText}
        inputHeight={64}
        onChange={(event) =>
          handleInputChange(setRepeatPassword, { value: event.target.value })
        }
        onFocus={() =>
          handleInputChange(setRepeatPassword, { isFocused: true })
        }
        onBlur={() =>
          handleInputChange(setRepeatPassword, { isFocused: false })
        }
      />
    </>
  )
}
