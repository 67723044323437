import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getPaymentConfigAction,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectUserPaymentCurrency } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useViewportValue } from 'hooks/useViewportValue'

import { FeaturedBlockUpgrade } from 'modules/purchase/components/FeaturedBlockUpgrade'
import { HelpsBenefits } from 'modules/purchase/components/HelpsBenefits'
import { HELPS_BENEFITS_DATA } from 'modules/purchase/components/HelpsBenefits/constants'
import { IntroMediaV2 } from 'modules/purchase/components/IntroMediaV2'
import { OurPrograms } from 'modules/purchase/components/OurPrograms'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfoUpgrade } from 'modules/purchase/components/SecurityInfoUpgrade'
import { SubscriptionBonusItem } from 'modules/purchase/components/SubscriptionBonusItem'
import { UpgradeSubscriptionItemMealPlan } from 'modules/purchase/components/UpgradeSubscriptionItemMealPlan'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { usePurchaseUpgradeAnalytics } from 'modules/purchase/hooks/usePurchaseUpgradeAnalytics'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseUpgrade,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import usersWithMealPlan from 'assets/images/meal-users.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenId, ScreenName, SubscriptionListType } from 'root-constants'

import { StyledPurchaseUpgradeMealplan as S } from './PurchaseUpgradeMealplan.styles'

export const PurchaseUpgradeMealplan: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { buttonText } = useDynamicPaywallConfig()
  const { search } = useLocation()

  const {
    periodQuantity,
    amountToPay,
    upgradeFullPrice,
    fetchingActionsList,
    subscriptions,
  } = usePurchaseStore()

  const currency = useSelector(selectUserPaymentCurrency)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isPricesFetchingStarted, setIsPricesFetchingStarted] = useState(false)

  usePurchaseUpgradeAnalytics(
    ScreenName.LIVECHAT_UPGRADE,
    ScreenId.UPGRADE_MEAL_PLAN,
  )

  useViewportValue(ScreenName.LIVECHAT_UPGRADE)

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(setScreenIdAction(ScreenId.UPGRADE_MEAL_PLAN))
    dispatch(getSubscriptionListAction(SubscriptionListType.UPGRADE))

    setIsPricesFetchingStarted(true)
  }, [dispatch])

  useEffect(() => {
    if (!isPricesFetchingStarted || !hasPrices) return

    if (!subscriptions.length) {
      goTo({
        pathname: PageId.DOWNLOAD,
        search,
      })
    }
  }, [subscriptions, search, isPricesFetchingStarted, hasPrices])

  useEffect(() => {
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  useEffect(() => {
    if (upgradeFullPrice && periodQuantity) {
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          subscription_upgrade_price: amountToPay || upgradeFullPrice,
          subscription_upgrade_duration: periodQuantity,
        }),
      )
    }
  }, [amountToPay, upgradeFullPrice, dispatch, currency, periodQuantity])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const upgradePlan = useCallback(
    (event) => {
      dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

      eventLogger.logPlansPageButtonTap({
        screenName: ScreenName.LIVECHAT_UPGRADE,
        buttonNumber: event.target.getAttribute('data-order'),
        buttonText: event.target.getAttribute('data-text'),
      })

      addToCart()
    },
    [addToCart, dispatch],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <IntroMediaV2 />
      <Container>
        <S.PhotoResult hasBackground />

        <S.Title>{t`purchaseUpgradeMealPlan.supportBody`}</S.Title>
        <S.Subtitle>{t`purchaseUpgradeMealPlan.supportBodySubtitle`}</S.Subtitle>

        <UpgradeSubscriptionItemMealPlan>
          <Button
            data-order="1"
            data-text="continue"
            type="button"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </UpgradeSubscriptionItemMealPlan>

        <S.UserWithMealPlan>
          <S.Img src={usersWithMealPlan} alt="Users with meal plan" />
          <S.Text>
            <Trans i18nKey="purchaseUpgradeMealPlan.usersWithMealPlan" />
          </S.Text>
        </S.UserWithMealPlan>

        <SubscriptionBonusItem />

        <OurPrograms />

        <S.ButtonContainer>
          <Button
            type="button"
            data-order="2"
            data-text="continue"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </S.ButtonContainer>

        <S.BodyAge />

        <S.BenefitsWrapper>
          <S.Title>{t`purchaseUpgrade.helpsBenefits.mainTitle`}</S.Title>
          {HELPS_BENEFITS_DATA.map(({ iconSrc, title, description }) => (
            <HelpsBenefits
              iconSrc={iconSrc}
              title={t(title)}
              description={t(description)}
              key={title}
            />
          ))}
        </S.BenefitsWrapper>

        <UpgradeSubscriptionItemMealPlan>
          <Button
            data-order="3"
            data-text="continue"
            type="button"
            onClick={upgradePlan}
          >
            {buttonText || t`actions.continue`}
          </Button>
        </UpgradeSubscriptionItemMealPlan>

        <SecurityInfoUpgrade />
        <S.FeaturedBlockContainer>
          <FeaturedBlockUpgrade />
        </S.FeaturedBlockContainer>

        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          tryAgain={() => addToCart()}
        />
      </Container>
    </>
  )
}
