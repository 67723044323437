import React, { FC, useEffect, useRef } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import arrowAnimation from 'assets/animation/headerTimerButton.json'

import { StyledButtonWithAnimation } from './ButtonWithAnimation.styles'

type TProps = {
  children?: React.ReactNode
}

export const ButtonWithAnimation: FC<TProps> = ({ children }) => {
  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: arrowAnimation,
        loop: true,
        name: 'arrowAnimation',
      })
    }

    return () => lottie.destroy('arrowAnimation')
  }, [])

  return (
    <StyledButtonWithAnimation ref={animationRef}>
      {children}
    </StyledButtonWithAnimation>
  )
}
