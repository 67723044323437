import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledDesignNewLife = {
  Subtitle: styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    color: ${Color.DARK};
    text-align: center;
  `,
  Container: styled(Container)`
    margin-top: 16px;
  `,
  Animation: styled.div`
    min-height: 348px;
  `,
}
