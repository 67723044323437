import midSizedLegs from 'assets/images/mid-sized-legs.png'
import midSized from 'assets/images/mid-sized.png'
import overweightLegs from 'assets/images/overweight-legs.png'
import overweight from 'assets/images/overweight.png'
import plusSizedLegs from 'assets/images/plus-sized-legs.png'
import plusSized from 'assets/images/plus-sized.png'
import slimLegs from 'assets/images/slim-legs.png'
import slim from 'assets/images/slim.png'

export const QUESTION = 'What image describes your physical build and belly?'

export const OPTION_VALUES = [
  {
    value: 'Slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slim,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSized,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSized,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweight,
  },
]
export const OPTION_VALUES_LEGS = [
  {
    value: 'slim',
    text: 'onboarding.physicalBuild.slim',
    imageUrl: slimLegs,
  },
  {
    value: 'midSized',
    text: 'onboarding.physicalBuild.midSized',
    imageUrl: midSizedLegs,
  },
  {
    value: 'plusSized',
    text: 'onboarding.physicalBuild.plusSized',
    imageUrl: plusSizedLegs,
  },
  {
    value: 'overweight',
    text: 'onboarding.physicalBuild.overweight',
    imageUrl: overweightLegs,
  },
]
