import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badgeImg from 'assets/images/money-back-badge.png'

import { PagesSource } from 'root-constants'

import { StyledMoneyCancelOffer as S } from './MoneyBackCancelOffer.styles'

export const MoneyBackCancelOffer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`purchaseCancelOffer.moneyBackGuarantee.title`}</S.Title>

        <S.TextContainer>
          <S.Text>
            <S.Badge src={badgeImg} alt="badge" />
            <Trans i18nKey="purchaseCancelOffer.moneyBackGuarantee.text" />{' '}
            <TermsOfUseLink source={PagesSource.PAYWALL} />
          </S.Text>
        </S.TextContainer>
      </S.Content>
    </S.Wrapper>
  )
}
