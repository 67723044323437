import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroMedia = {
  Wrapper: styled.div`
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  TitleContainer: styled.div`
    background-color: ${Color.PRIMARY};
  `,
  Title: styled.h1`
    padding: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  MainImageContainer: styled.div`
    padding-top: 73.888888%;
    position: relative;
    background-color: #80daff;
  `,
  MainImage: styled.picture`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 1;
  `,
}
