import React, { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { BUTTON_WITH_ICON_STYLE_PROPS, Color } from 'root-constants'

import { StyledRewardYourself as S } from './RewardYourself.styles'
import { OPTION_VALUES, OPTION_VALUES_ADULT, QUESTION } from './constants'

export const RewardYourself: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const {
    handleChange,
    setIsAnswersDisabled,
    isAnswersDisabled,
  } = useDelayedContinue()

  const { isNewAdultFlow } = useCohortInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...BUTTON_WITH_ICON_STYLE_PROPS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const theme = isNewAdultFlow
    ? answerWithIconTheme.DANCEBIT_ADULT
    : answerWithIconTheme.DANCEBIT

  return (
    <S.Container isLarge $isNewAdultFlow={isNewAdultFlow}>
      <PageTitle marginBottom={24} highlightColor={Color.PRIMARY}>
        <Trans i18nKey="onboarding.rewardYourself.question" />
      </PageTitle>

      {(isNewAdultFlow ? OPTION_VALUES_ADULT : OPTION_VALUES).map(
        ({ text, value, imageUrl }) => (
          <AnswerWithIcon
            {...optionProps}
            theme={theme}
            disabled={isAnswersDisabled}
            key={value}
            value={value}
            iconSrc={imageUrl}
          >
            <Trans>{text}</Trans>
          </AnswerWithIcon>
        ),
      )}
    </S.Container>
  )
}
