import { useMemo } from 'react'

import { getSubscriptionWithDynamicDiscounts } from 'helpers/getSubscriptionWithDynamicDiscounts'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { Cohort, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

export const useGetSubscription = ({
  isSevenDayPlan,
  isCancelOfferPage,
  hasExcludedVat,
  getCurrentPrice,
  isTrial,
}) => {
  const {
    fullPrice,
    cohort,
    discountAmount,
    discountPercentage,
    selectedSubscription,
    oldPriceBeforeCustomPercentDiscount,
    dynamicDiscount,
  } = usePurchaseStore()

  const { isSaleFlow, isNewYearFlow } = useCohortInfo()

  return useMemo(() => {
    if (!selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount: 0,
        discountAmount,
      }
    }

    if (isSaleFlow && !isSevenDayPlan) {
      const isFormattedPrice = cohort !== Cohort.DB_BELLY_WEEK

      return getSubscriptionWithDynamicDiscounts(
        selectedSubscription,
        dynamicDiscount,
        isCancelOfferPage,
        hasExcludedVat,
        getCurrentPrice,
        isFormattedPrice,
      )
    }

    if (!isTrial) {
      return {
        percentage: discountPercentage || 50,
        totalAmount: getCurrentPrice(
          selectedSubscription.mainPrices?.fullPrice,
        ),
        discountAmount: getCurrentPrice(
          oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
        ),
      }
    }

    if (isNewYearFlow) {
      return getSubscriptionWithDynamicDiscounts(
        selectedSubscription,
        dynamicDiscount,
        isCancelOfferPage,
        hasExcludedVat,
        getCurrentPrice,
        false,
        selectedSubscription.trialPrices?.oldPrices.beforeCustomDiscount
          ?.percentOfDiscount,
      )
    }

    return {
      percentage: discountPercentage || 50,
      totalAmount:
        selectedSubscription.trialPrices?.durationDays ===
        Number(SEVEN_DAY_TRIAL_DURATION)
          ? getCurrentPrice(
              selectedSubscription.trialPrices?.oldPrices.beforeCustomDiscount
                ?.fullPrice,
            )
          : getCurrentPrice(
              isTrial
                ? fullPrice
                : oldPriceBeforeCustomPercentDiscount.fullPrice,
            ),
      discountAmount: getCurrentPrice(
        isTrial
          ? discountAmount
          : oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
      ),
    }
  }, [
    isSevenDayPlan,
    selectedSubscription,
    isSaleFlow,
    isTrial,
    isNewYearFlow,
    discountPercentage,
    getCurrentPrice,
    fullPrice,
    oldPriceBeforeCustomPercentDiscount.fullPrice,
    oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
    discountAmount,
    cohort,
    dynamicDiscount,
    isCancelOfferPage,
    hasExcludedVat,
  ])
}
