export const QUESTION = 'What is your dancing level?'

export const DANCING_LEVEL = {
  NEWBIE: 'newbie',
  INTERMEDIATE: 'intermediate',
  ADVANCED: 'advanced',
}

export const DANCING_LEVEL_POINTS = {
  newbie: 1,
  intermediate: 2,
  advanced: 3,
}
