import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
  selectLanguage,
} from 'root-redux/selects/common'

import { CDN_FOLDER_LINK, Cohort } from 'root-constants'

import { UpsellCardDescription } from './UpsellCardDescription'
import { StyledUpsellPlansDescription as S } from './UpsellPlansDescription.styles'
import { CARDS_LOSE_WEIGHT, CARDS_STAY_FIT } from './constants'

type TProps = {
  withStayFitFlow?: boolean
  className?: string
}

export const UpsellPlansDescription: React.FC<TProps> = ({
  withStayFitFlow = false,
  className = '',
}) => {
  const language = useSelector(selectLanguage)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohort = useSelector(selectCurrentVariantCohort)

  const cohortToUse = parentCohort || cohort

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const cardsToUse = useMemo(() => {
    if (!withStayFitFlow) return CARDS_LOSE_WEIGHT

    if (withStayFitFlow && isStayFitFlow) return CARDS_STAY_FIT

    return CARDS_LOSE_WEIGHT
  }, [isStayFitFlow, withStayFitFlow])

  const imgToUse = useMemo(
    () =>
      cardsToUse.map((card) => `${CDN_FOLDER_LINK}${card.img}_${language}.png`),
    [cardsToUse, language],
  )

  return (
    <S.Wrapper className={className}>
      {cardsToUse.map((card, index) => (
        <UpsellCardDescription
          key={imgToUse[index]}
          alt={card.alt}
          title={card.title}
          text={card.text}
          img={imgToUse[index]}
          withLabel={card.withLabel}
        />
      ))}
    </S.Wrapper>
  )
}
