import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import 'firebase/auth'

import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  updateAnswersAction,
} from 'root-redux/actions/common'
import {
  bindUserAction,
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { useNameField } from 'hooks/useNameField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'
import { useRepeatPasswordInputField } from 'hooks/useRepeatPasswordInputField'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'modules/login/redux'
import { setBackupPrimerSubscriptionConfigAction } from 'modules/purchase/redux/actions/common'
import { selectBackupPrimerSubscriptionConfig } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'

import { StyledLogin as S } from './Login.styles'
import { Disclaimer } from './components/Disclaimer'
import { EmailLogin } from './components/EmailLogin'

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const backupPrimerConfig = useSelector(selectBackupPrimerSubscriptionConfig)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const [userName, setUserName] = useNameField()
  const [email, setEmail] = useEmailInputField(emailErrorMessage, () =>
    dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )
  const [repeatPassword, setRepeatPassword] = useRepeatPasswordInputField(
    password.value,
  )

  const isComplete = useMemo(
    () =>
      userName.isValid &&
      userName.value !== '' &&
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '' &&
      repeatPassword.isValid &&
      repeatPassword.value !== '',
    [userName, email, password, repeatPassword],
  )

  useEffect(() => {
    setEmail((prevState) => ({
      ...prevState,
      value: userOnboardingEmail,
    }))
  }, [setEmail, userOnboardingEmail])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    if (!(priceId && price) && backupPrimerConfig) {
      dispatch(
        sendUserConfigAction({
          payment_currency: backupPrimerConfig.paymentCurrency,
          payment_method: backupPrimerConfig.paymentMethod,
          subscription_price: backupPrimerConfig.subscriptionPrice,
          subscription_duration: backupPrimerConfig.subscriptionDuration,
          price_id: backupPrimerConfig.priceId,
          trial_price: backupPrimerConfig.trialPrice,
          trial_period: backupPrimerConfig.trialPeriod,
        }),
      )
    }

    if (backupPrimerConfig) {
      dispatch(setBackupPrimerSubscriptionConfigAction(null))
    }
  }, [backupPrimerConfig, dispatch, price, priceId])

  const handleContinueWithEmail = useCallback(
    (event) => {
      event.preventDefault()

      dispatch(
        updateAnswersAction({
          answers: {
            [PageId.NAME]: userName.value,
          },
        }),
      )

      dispatch(sendUserAnswersAction(null, true))

      dispatch(
        registerWithEmailFirebaseAction({
          email: email.value,
          password: password.value,
        }),
      )
    },
    [dispatch, email.value, password.value, userName.value],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="login.newTitle" />
        </S.Title>
      </S.Wrapper>

      <form onSubmit={handleContinueWithEmail}>
        <S.Wrapper>
          <EmailLogin
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            repeatPassword={repeatPassword}
            setRepeatPassword={setRepeatPassword}
            name={userName}
            setName={setUserName}
          />

          <S.DisclaimerContainer>
            <Disclaimer />
          </S.DisclaimerContainer>
        </S.Wrapper>

        <S.ButtonContainer>
          <S.Button
            type="submit"
            disabled={!isComplete || isFetching}
          >{t`login.createMyAccount`}</S.Button>
        </S.ButtonContainer>
      </form>

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
        error={error}
      />
    </>
  )
}
