import styled from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'

import { Color } from 'root-constants'

export const StyledTenMillionPeople = {
  Image: styled.img`
    position: absolute;
    top: 52px;
    transform: translateX(-50%);
    left: 50%;
    height: 92px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK};
    margin-top: 76px;
    margin-bottom: 16px;
  `,
  Subtitle: styled.div`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    strong {
      color: ${Color.PRIMARY};
      font-weight: 700;
    }

    p:first-child {
      margin-bottom: 8px;
    }
  `,
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)`
    background: ${Color.LIGHT};
  `,
}
