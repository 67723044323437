import { useEffect, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOutbrainPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useOutbrainPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const outbrainPixelId = useSelector(selectOutbrainPixelId)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldOutbrainPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [
      isEUUser,
      userCookieConsentAnswer?.length,
      hasAdvertizingAndMarketingCookie,
    ],
  )

  useLayoutEffect(() => {
    if (
      !outbrainPixelId ||
      !uuid ||
      !userCountryCode ||
      shouldOutbrainPixelBePaused
    ) {
      return
    }

    const outbrainScript = document.createElement('script')

    outbrainScript.type = 'text/javascript'
    outbrainScript.setAttribute('data-obct', 'true')
    outbrainScript.text = `
      !function(_window, _document) {
        var OB_ADV_ID = '${outbrainPixelId}';
        if (_window.obApi) {
          var toArray = function(object) {
            return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
          };
          _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
          return;
        }

        var api = _window.obApi = function() {
          api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
        };
        api.version = '1.1';
        api.loaded = true;
        api.marketerId = OB_ADV_ID;
        api.queue = [];
        var tag = _document.createElement('script');
        tag.async = true;
        tag.src = '//amplify.outbrain.com/cp/obtp.js';
        tag.type = 'text/javascript';
        var script = _document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(tag, script);
      }(window, document);
      `

    document.body.appendChild(outbrainScript)
  }, [outbrainPixelId, shouldOutbrainPixelBePaused, userCountryCode, uuid])

  useEffect(() => {
    if (!outbrainPixelId || !uuid) {
      return
    }

    window.obApi && window.obApi('track', 'PAGE_VIEW')
  }, [outbrainPixelId, uuid])
}
