import styled from 'styled-components'

import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { UpgradeDisclaimer } from 'modules/purchase/components/UpgradeDisclaimer'

export const StyledPurchaseUpgrade = {
  Wrapper: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 16px 20px;
  `,
  BenefitsTitle: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  BenefitsWrapper: styled.div``,
  FeaturedBlockContainer: styled.div`
    margin-bottom: 50px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 32px;
  `,
  SecurityInfo: styled(SecurityInfo)`
    a {
      font-weight: 400;
      text-decoration: underline;
    }
  `,
  PhotoResultV3: styled(PhotoResultV3)`
    padding: 0;

    .swiper-container {
      padding-bottom: 48px;
    }

    .swiper-slide {
      border-radius: 0 0 16px 16px;
      overflow: hidden;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  `,
  Disclaimer: styled(UpgradeDisclaimer)`
    margin: 24px 0;
    padding-top: 0;
  `,
}
