import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { getAxonItem } from 'helpers/getAxonItem'

import { SubscriptionItemIntroOfferAdult } from 'modules/purchase/components/SubscriptionItemIntroOfferAdult'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionsBlockIntroOfferAdult as S } from './SubscriptionsBlockIntroOfferAdult.styles'

type TProps = {
  children: React.ReactNode
  className?: string
}

export const SubscriptionsBlockIntroOfferAdult: React.FC<TProps> = ({
  children,
  className = '',
}) => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.WrapperContainer>
      <S.Wrapper className={className}>
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.planId}
            onChange={handleChange}
            withoutMargin
          >
            <SubscriptionItemIntroOfferAdult
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </Option>
        ))}

        <S.ButtonContainer>{children}</S.ButtonContainer>
      </S.Wrapper>
      <S.SubscriptionDisclaimer />
    </S.WrapperContainer>
  )
}
