import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionItemMealPlan as S } from './SubscriptionItemMealPlan.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemMealPlan: React.FC<TProps> = ({
  subscription: { id, mainPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)

  const prevPagePriceWithFractionalPart = useMemo(
    () => mainPrices.oldPrices.beforeCustomDiscount?.weekly.toFixed(2),
    [mainPrices],
  )

  const oldPriceWithFractionalPart = useMemo(
    () => mainPrices.oldPrices.before50PercentDiscount?.weekly.toFixed(2),
    [mainPrices],
  )

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  return (
    <S.Wrapper $isSelected={isSelected} key={id}>
      <S.Content $isSelected={isSelected}>
        <S.PlanPeriod $isSelected={isSelected}>
          <Trans
            i18nKey="purchase1.paymentForm.planDescription_interval"
            values={{
              count: mainPrices.periodQuantity,
              postProcess: 'interval',
            }}
            components={[<br />]}
          />
        </S.PlanPeriod>
        <S.PriceContainer>
          <S.PreviousPricesContainer>
            <S.PreviousPagePrice>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(prevPagePriceWithFractionalPart),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPagePrice>
            <S.OldPrice>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(oldPriceWithFractionalPart),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.OldPrice>
          </S.PreviousPricesContainer>
          <S.CurrentPrice $isSelected={isSelected}>
            <S.PriceValue>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: getCurrentPrice(mainPrices.weekly),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PriceValue>
            <S.Period>
              {t`purchaseCancelOfferMealPlan.subscriptions.interval`}{' '}
              {t(`purchaseCancelOfferMealPlan.subscriptions.week`)}
            </S.Period>
          </S.CurrentPrice>
        </S.PriceContainer>
      </S.Content>
    </S.Wrapper>
  )
}
