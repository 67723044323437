import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $isDefault?: boolean
}

const commonPriceStyles = css`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: ${Color.GRAY_60};
`

const crossedPriceStyles = css`
  text-decoration: line-through;
  text-decoration-color: #ff043a;
  color: ${Color.GRAY_60};
`

export const StyledSubscriptionItemIntroOfferAdult = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 11px 16px 11px 1px;
    border-radius: 20px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.GRAY_10};

    ${({ $isSelected }) =>
      $isSelected &&
      `
        padding: 10px 15px 10px 0;
        border: 2px solid ${Color.PRIMARY};
        background: ${Color.WHITE};
        
    `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border-radius: 50%;
      border: 1px solid ${Color.GRAY_40};
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularWrapper: styled.div<{ $isDefault: boolean }>`
    ${({ $isDefault }) =>
      $isDefault &&
      css`
        position: relative;
        border-top: 22px solid ${Color.PRIMARY};
        border-radius: 20px;
        background: ${Color.PRIMARY};
      `};
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 52px;
  `,
  PlanPeriod: styled.span`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 4px;
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span<{ $hasMxnCurrency: boolean }>`
    ${commonPriceStyles};
    display: flex;
    align-items: center;

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      css`
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      `};
  `,
  PreviousPrice: styled.span<{ $hasMxnCurrency: boolean }>`
    ${commonPriceStyles};
    ${crossedPriceStyles};

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      css`
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      `};
  `,
  PricesBox: styled.div<{ $hasMxnCurrency: boolean }>`
    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > span {
          padding-left: 0;
        }
      `};
  `,
  CustomPrice: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding-left: 28px;
    border-left: 1px solid ${Color.GRAY_20};
  `,
  PriceValue: styled.span<{ $hasMxnCurrency: boolean }>`
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${Color.DARK};
    padding-left: 4px;

    ${({ $hasMxnCurrency }) =>
      $hasMxnCurrency &&
      css`
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      `};
  `,
  Period: styled.span`
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.DARK};
  `,
  Discount: styled.div`
    border-radius: 6px;
    background: #ffdee5;
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    color: #ff043a;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
  `,
}
