import age18 from 'assets/images/age-18-24.png'
import age25 from 'assets/images/age-25-34.png'
import age35 from 'assets/images/age-35-44.png'
import age45 from 'assets/images/age-45+.png'
import age54 from 'assets/images/age-45-54.png'
import age64 from 'assets/images/age-54-65.png'

export const QUESTION = 'Choose your age group'

export const OPTIONS = [
  {
    value: '18',
    text: '18-24',
    imageUrl: age18,
  },
  {
    value: '25',
    text: '25-34',
    imageUrl: age25,
  },
  {
    value: '35',
    text: '35-44',
    imageUrl: age35,
  },
  {
    value: '45',
    text: '45+',
    imageUrl: age45,
  },
]

export const OPTIONS_MENOPAUSE = [
  {
    value: '35',
    text: '35-44',
    imageUrl: age18,
  },
  {
    value: '45',
    text: '45-54',
    imageUrl: age54,
  },
  {
    value: '55',
    text: '55-64',
    imageUrl: age64,
  },
  {
    value: '65',
    text: '65+',
    imageUrl: age45,
  },
]
