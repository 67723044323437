import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { Color, MediaBreakpoint } from 'root-constants'

const commonLabelStyles = css`
  padding: 8px 5px;
  background: #eefee6;
  border: 2px solid #71c554;
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #71c554;

  @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
    font-size: 12px;
  }
`

const commonTitleStyles = css`
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 24px;
`

export const StyledDownload = {
  Wrapper: styled(Container)``,
  Title: styled.h1`
    ${commonTitleStyles};
  `,
  Subtitle: styled.h2`
    ${commonTitleStyles};
    margin-bottom: 16px;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 24px;
    height: 24px;
  `,
  LoginDescription: styled.p`
    margin-bottom: 16px;
    padding: 12px 14px;
    background: #fdf7e7;
    border: 2px solid #e9c96d;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DARK};
  `,
  ListTitle: styled.h3`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  `,
  List: styled.ul`
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    list-style-type: none;
    counter-reset: my-counter 0;
  `,
  ListWithBorder: styled.div`
    padding: 16px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    list-style-type: none;
    counter-reset: my-counter 0;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
    margin-bottom: 26px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: counter(my-counter);
      counter-increment: my-counter;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 11px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      background-color: #f2f3f5;
      margin-right: 30px;
      border-radius: 50%;
    }

    &:last-child {
      margin-bottom: 18px;
    }

    & strong {
      font-weight: 600;
    }

    & strong:nth-child(2) {
      text-decoration: underline;
      color: ${Color.PRIMARY};
    }
  `,
  Text: styled.div`
    margin-left: 32px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    & button {
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 15px;
      color: ${Color.PRIMARY};
      font-weight: 600;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  `,
  DownloadAppText: styled.p`
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;

    span {
      display: inline-block;
      ${commonLabelStyles};
    }
  `,
  ContinueText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  `,
  EmailLabel: styled.p`
    ${commonLabelStyles};
    display: inline-block;
    margin: 13px 0 0 15px;
  `,
  ImageContainer: styled.div`
    width: 330px;
  `,
  Image: styled.img`
    width: 100%;
    margin: 16px auto 0;
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};
  `,
  Button: styled.button`
    ${StyledButtonBase};
    max-width: 328px;
    font-size: 17px;
    font-weight: 700;
    line-height: 54px;
    border-radius: 30px;
  `,
  CopyEmail: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_40};
    background: ${Color.WHITE};
    margin-bottom: 16px;
  `,
  UserEmail: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,
  Label: styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY_80};
  `,
  Email: styled.input`
    width: 100%;
    border: none;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: text;

    &:focus-visible {
      outline: none;
    }
  `,
  SvgIcon: styled(SvgImage)`
    cursor: pointer;
  `,
  CopyButton: styled.button`
    position: relative;
    background: none;
    border: none;
  `,
  ContactWithSupport: styled(ContactWithSupport)`
    color: ${Color.PRIMARY};
    text-decoration: underline;
  `,
}
