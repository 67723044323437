import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { GraphNew } from 'components/GraphNew'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { DynamicTimer } from 'modules/purchase/components/DynamicTimer'
import { FAQListNew } from 'modules/purchase/components/FAQListNew'
import { FeaturedBlockNew } from 'modules/purchase/components/FeaturedBlockNew'
import { HeaderWithTimerV2 } from 'modules/purchase/components/HeaderWithTimerV2'
import { LimitedDeal } from 'modules/purchase/components/LimitedDeal'
import { MoneyBackGuaranteeNew } from 'modules/purchase/components/MoneyBackGuaranteeNew'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PhotoResultNew } from 'modules/purchase/components/PhotoResultNew'
import { ReachTargetNew } from 'modules/purchase/components/ReachTargetNew'
import { ReceivedPlanNew } from 'modules/purchase/components/ReceivedPlanNew'
import { ReviewsNew } from 'modules/purchase/components/ReviewsNew'
import { SecurityInfoNew } from 'modules/purchase/components/SecurityInfoNew'
import { SubheaderWithTimerNew } from 'modules/purchase/components/SubheaderWithTimerNew'
import { SubscriptionsBlockIntroOfferNew } from 'modules/purchase/components/SubscriptionsBlockIntroOfferNew'
import { TimerWithDiscount } from 'modules/purchase/components/TimerWithDiscount'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { SLIDES } from 'modules/purchase/pages/PurchaseNew/constant'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  DynamicDiscountType,
  GROWTHBOOK_EXPERIMENT,
  Locale,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutSeparateMethods } from '../CheckoutSeparateMethods'
import { StyledPurchaseWithoutIntro as S } from './PurchaseNew.styles'

export const PurchaseNew: FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
    language,
  } = usePurchaseStore()

  const { buttonText } = useDynamicPaywallConfig()
  const { isSaleFlow } = useCohortInfo()

  const hasIncreasedPricesEn = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1294)
  const hasIncreasedPricesLocales = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.DAN_1295,
  )

  const isFitMePrices = language === Locale.ENGLISH || hasIncreasedPricesLocales

  usePaymentElementStatus()

  const hasIncludedVat = useVatInfo()

  usePurchaseAnalytics({
    screenId: ScreenId.PAYWALL_NEW,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (hasIncreasedPricesEn) {
      pricesTags = `${SubscriptionTags.INCREASED_INTRO},${pricesTags}`
    }

    if (isFitMePrices) {
      pricesTags = `${SubscriptionTags.INCREASED},${pricesTags}`
    }

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.PAYWALL_NEW))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncreasedPricesEn, hasIncludedVat, isFitMePrices])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Content $isCheckoutShown={isCheckoutShown}>
      {!isCheckoutShown && (
        <div>
          {isSaleFlow && <Ticker />}

          {isSaleFlow && (
            <HeaderWithTimerV2 onButtonClick={handleShowPayment} />
          )}

          <ReachTargetNew />
          <S.Wrapper isLarge>
            <S.RefundCard>
              <Trans i18nKey="purchaseRef.getRefundNoQuestions" />
            </S.RefundCard>
            <ReceivedPlanNew />

            {isSaleFlow && dynamicDiscount?.promoText ? (
              <TimerWithDiscount />
            ) : (
              <>
                {isSaleFlow &&
                  dynamicDiscount?.type === DynamicDiscountType.STATIC && (
                    <LimitedDeal />
                  )}

                {isSaleFlow &&
                  dynamicDiscount?.type !== DynamicDiscountType.STATIC && (
                    <DynamicTimer />
                  )}
              </>
            )}

            <GraphNew />
          </S.Wrapper>

          <SubheaderWithTimerNew
            elemForComparisonRef={buttonWrapperElementRef}
            onButtonClick={handleShowPayment}
          />
          <SubscriptionsBlockIntroOfferNew
            elemForComparisonRef={buttonWrapperElementRef}
          >
            <Button
              style={{
                maxWidth: '100%',
              }}
              type="button"
              data-order="2"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </Button>
          </SubscriptionsBlockIntroOfferNew>
          <S.Wrapper isLarge>
            <S.RefundCardSmall $marginBottom={40}>
              <Trans i18nKey="purchaseRef.getRefund" />
            </S.RefundCardSmall>
            <MoneyBackGuaranteeNew />
            <FeaturedBlockNew />
          </S.Wrapper>
          <S.HowItWorks
            isLarge
            buttonOrder={3}
            onButtonClick={handleShowPayment}
          />
          <S.Wrapper isLarge>
            <PhotoResultNew reviews={SLIDES} />
            <FAQListNew />
            <ReviewsNew />
            <S.RefundCardSmall $marginBottom={16}>
              <Trans i18nKey="purchaseRef.getRefund" />
            </S.RefundCardSmall>
          </S.Wrapper>
          <SubscriptionsBlockIntroOfferNew>
            <Button
              style={{
                maxWidth: '100%',
              }}
              type="button"
              data-order="4"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </Button>
          </SubscriptionsBlockIntroOfferNew>
          <S.Wrapper isLarge>
            <SecurityInfoNew />
          </S.Wrapper>
        </div>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>

      {!isCheckoutShown && (
        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        />
      )}
    </S.Content>
  )
}
