import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { StyledSubheaderWithTimer as S } from './SubheaderWithTimerJa.styles'

type TProps = {
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  className?: string
}

export const SubheaderWithTimerJa: React.FC<TProps> = ({
  elemForComparisonRef,
  onButtonClick,
  className,
}) => {
  const { t } = useTranslation()
  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)

  return (
    <S.TimerContainer className={className} $isButtonVisible={isButtonVisible}>
      <S.TimerText $isButtonVisible={isButtonVisible}>
        {t`purchase1.subheaderText`}
      </S.TimerText>
      <S.SubheaderTimer isButtonVisible />
      {!isButtonVisible && (
        <S.TimerText $isButtonVisible={isButtonVisible}>
          {t`purchase1.subheaderTimeUnit`}
        </S.TimerText>
      )}
      <S.ButtonContainer $isButtonVisible={isButtonVisible}>
        <S.TimerButton
          data-order="1"
          data-text="get_now"
          onClick={onButtonClick}
        >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
