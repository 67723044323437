import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledRecommendationCard = {
  Container: styled.div<{ $background?: string }>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 24px 16px 16px;
    border-radius: 24px;
    background: ${({ $background }) =>
      $background ||
      `radial-gradient(
      120.26% 112.91% at 93% 33.94%,
      #ffeaf0 1.55%,
      #f2b3c5 70.82%,
      #fba6be 88.05%
    )`};
    box-shadow: 4px 14px 20px 0px rgba(28, 13, 47, 0.1);
  `,
  Header: styled.div<{ $headerBackground?: string }>`
    min-width: 315px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 30px;
    background: ${({ $headerBackground }) => $headerBackground || '#ff5777'};
    backdrop-filter: blur(4px);
  `,
  Icon: styled.div<{ $iconSrc: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 24px;
    background-color: ${Color.WHITE};
    background-image: ${({ $iconSrc }) => `url(${$iconSrc})`};
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(1.5px);
  `,
  HeaderText: styled.span<{ $headerTextColor?: string }>`
    color: ${({ $headerTextColor }) => $headerTextColor || Color.WHITE};
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  TitleContainer: styled.div`
    display: flex;
    align-items: center;
  `,
  Title: styled.h3<{ $color?: string }>`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${({ $color }) => $color || Color.DARK};
  `,
  Image: styled.img<{ $hasImagePadding?: boolean }>`
    width: 148px;
    height: 121px;
    flex-shrink: 0;

    ${({ $hasImagePadding }) =>
      $hasImagePadding &&
      css`
        padding-top: 7px;
      `}
  `,
  BenefitList: styled.ul`
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    backdrop-filter: blur(4px);
  `,
  BenefitItem: styled.li`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  `,
  CheckMark: styled.img`
    width: 20px;
    height: 20px;
  `,
  BenefitText: styled.p`
    color: #2e3258;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    flex: 1 0 0;
  `,
}
