import styled from 'styled-components'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledPurchaseVariantEmail = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  `,
  MainTitle: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 10px;
    text-align: center;
    margin-top: 30px;
  `,
  MainSubtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 40px;
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 295px;
    margin: 50px auto;
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 38px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 20px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 24px;
      height: 24px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled.div`
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    padding-bottom: 32px;
    margin-top: -18px;
  `,
  CustomerReviewsTitle: styled.h2`
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 19px;
    color: ${Color.DARK};
    text-align: center;
  `,

  Prices: styled.div`
    margin-bottom: 38px;
  `,
  PricesTitle: styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
  `,
  PricesTable: styled.div`
    padding: 20px 0;
    border-top: 2px solid ${Color.LIGHT};
    border-bottom: 2px solid ${Color.LIGHT};
  `,
  PricesRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  PriceTotal: styled.span`
    font-weight: 600;
    font-size: 16px;
    color: ${Color.PRIMARY};
  `,
  Discount: styled.span`
    padding: 6px 10px;
    background-color: ${Color.PRIMARY};
    border-radius: 51px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.WHITE};
  `,
  OldPrice: styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: line-through;
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
}
