import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledDancingLevel as S } from './DancingLevelBelly.styles'
import { DANCING_LEVEL, QUESTION } from './constants'

export const DancingLevelBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const { isNewAdultFlow } = useCohortInfo()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      margin: '0 0 16px 0',
      maxWidth: '100%',
      disabled: isAnswersDisabled,
      theme,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      theme,
      isAnswersDisabled,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={8}>
        <Trans i18nKey="onboarding.dancingLevel.question" />
      </PageTitle>
      <S.Subtitle>{t`onboarding.dancingLevel.subtitle`}</S.Subtitle>

      <Answer {...optionProps} value={DANCING_LEVEL.NEWBIE}>
        <S.OptionTitle>{t`onboarding.dancingLevel.newbie`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.newbieDescription`}</S.OptionDescription>
      </Answer>

      <Answer {...optionProps} value={DANCING_LEVEL.INTERMEDIATE}>
        <S.OptionTitle>{t`onboarding.dancingLevel.intermediate`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.intermediateDescription`}</S.OptionDescription>
      </Answer>

      <Answer {...optionProps} value={DANCING_LEVEL.ADVANCED}>
        <S.OptionTitle>{t`onboarding.dancingLevel.advanced`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.advancedDescription`}</S.OptionDescription>
      </Answer>
    </S.Container>
  )
}
