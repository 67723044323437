import checkMarkPinkIcon from 'assets/images/flow-belly/icons/check-mark-icon-pink.svg'
import checkMarkPurpleIcon from 'assets/images/flow-belly/icons/check-mark-icon-purple.svg'
import superchargeYourselfMiniature from 'assets/images/flow-belly/supercharge-yourself-miniature.png'
import superchargeYourselfCover from 'assets/images/flow-belly/supercharge-yourself-program-cover.png'
import ultimateToningPilatesMiniature from 'assets/images/flow-belly/ultimate-toning-pilates-miniature.png'
import ultimateToningPilatesCover from 'assets/images/flow-belly/ultimate-toning-pilates-program-cover.png'

import { UpsellId } from 'root-constants'

export const CART_PRODUCT = {
  [UpsellId.SUPERCHARGE_YOURSELF]: {
    title: 'purchaseWithCart.cartProducts.SuperchargeYourself.title',
    imgSrc: superchargeYourselfMiniature,
    points: [
      'purchaseWithCart.cartProducts.SuperchargeYourself.points.first',
      'purchaseWithCart.cartProducts.SuperchargeYourself.points.second',
      'purchaseWithCart.cartProducts.SuperchargeYourself.points.third',
    ],
    checkMarkImage: checkMarkPinkIcon,
    programCoverImage: superchargeYourselfCover,
    benefitsBorderColor: '#FFDFE8',
    benefitsBackgroundColor:
      'radial-gradient(120.26% 112.91% at 93% 33.94%, rgba(255, 231, 237, 0.20) 1.55%, rgba(242, 179, 197, 0.20) 70.82%, rgba(251, 166, 190, 0.20) 100%), #FFF',
  },
  [UpsellId.ULTIMATE_TONING_PILATES]: {
    title: 'purchaseWithCart.cartProducts.UltimateToningPilates.title',
    imgSrc: ultimateToningPilatesMiniature,
    points: [
      'purchaseWithCart.cartProducts.UltimateToningPilates.points.first',
      'purchaseWithCart.cartProducts.UltimateToningPilates.points.second',
      'purchaseWithCart.cartProducts.UltimateToningPilates.points.third',
    ],
    checkMarkImage: checkMarkPurpleIcon,
    programCoverImage: ultimateToningPilatesCover,
    benefitsBorderColor: '#E5DFFF',
    benefitsBackgroundColor:
      'linear-gradient(58deg, rgba(131, 120, 239, 0.20) -24.03%, rgba(212, 211, 247, 0.20) 75.86%), #FFF',
  },
}
