import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import tickerIcon from 'assets/images/sprite/ticker.svg'

import { StyledTwoPlansTimer as S } from './FreePlanCancelOfferTimer.styles.'

type TProps = {
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  className?: string
}

export const FreePlanCancelOfferTimer: FC<TProps> = ({
  elemForComparisonRef,
  onButtonClick,
  className,
}) => {
  const { t } = useTranslation()
  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)

  return (
    <S.TimerContainer className={className} $isButtonVisible={isButtonVisible}>
      <S.TimerText>
        <SvgImage svg={tickerIcon} width={24} height={24} />
        {t`purchaseFreeOffer.offerEnds`} <S.SubheaderTimer />
      </S.TimerText>
      <S.ButtonContainer $isButtonVisible={isButtonVisible}>
        <S.TimerButton
          data-order="1"
          data-text="get_now"
          onClick={onButtonClick}
        >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
      </S.ButtonContainer>
    </S.TimerContainer>
  )
}
