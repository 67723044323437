import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderV2 } from 'components/HeaderV2'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import avatar from 'assets/images/influencer-avatar.png'
import arrowSvg from 'assets/images/sprite/arrow-black.svg'

import { PagesSource } from 'root-constants'

import { StyledInfluencerWelcome as S } from './InfluencerWelcome.styles'
import { QUESTION } from './constants'

export const InfluencerWelcome: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq &&
      window.fbq(
        'track',
        'CustomizeProduct',
        {},
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: QUESTION,
    })
    handleContinue(QUESTION)
  }, [dispatch, handleContinue, isPersonalDataAllowed, uuid])

  return (
    <>
      <S.BgImage />
      <HeaderV2 />
      <S.Container>
        <S.QuoteContainer>
          <S.Quote>
            <S.AvatarContainer>
              <S.Avatar src={avatar} alt="Yarina, model and actress" />

              <S.Label>
                <span>
                  <Trans i18nKey="onboarding.influencer.name" />
                </span>
                <span>
                  <Trans i18nKey="onboarding.influencer.work" />
                </span>
              </S.Label>
            </S.AvatarContainer>

            <S.QuoteText>
              <Trans i18nKey="onboarding.influencer.quote" />
            </S.QuoteText>

            <S.TakeOff>
              <Trans i18nKey="onboarding.influencer.takeOff" />
            </S.TakeOff>
          </S.Quote>
        </S.QuoteContainer>

        <S.WelcomeText>
          <Trans i18nKey="onboarding.influencer.welcomeText" />
        </S.WelcomeText>

        <S.Arrow>
          <SvgImage svg={arrowSvg} height={32} width={32} />
        </S.Arrow>

        <S.ContinueButton type="button" onClick={handleClick}>
          <Trans i18nKey="actions.continue" />
        </S.ContinueButton>

        <S.Description>
          <Trans i18nKey="onboarding.influencer.description" />
        </S.Description>
      </S.Container>

      <S.Terms>
        <p>{t`footer.disclaimer`}</p>
        <TermsOfUseLink source={PagesSource.LANDING} />
        <S.Separator />
        <PrivacyPolicyLink source={PagesSource.LANDING} />
        <p>
          {t('footer.allRightsReserved', {
            year: new Date().getFullYear(),
          })}
        </p>
      </S.Terms>
    </>
  )
}
