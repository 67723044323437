import React, { useCallback, useMemo } from 'react'

import { PAGES_WITHOUT_BACK_BUTTON } from 'components/HeaderBellyFlow/constants'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'

import { StyledOnboardingProgressBar as S } from './OnboardingProgressBarBelly.styles'

const PROGRESS_THRESHOLD_AMOUNT = 4

export const OnboardingProgressBarBelly: React.FC = () => {
  const {
    currentPageId,
    hasProgressbar,
    pagesWithProgressBar,
  } = useGetPageInfo()

  const isPageWithoutBackButton = useMemo(
    () => PAGES_WITHOUT_BACK_BUTTON.includes(currentPageId as PageId),
    [currentPageId],
  )

  const [
    firstSectionThreshold,
    secondSectionThreshold,
    thirdSectionThreshold,
    currentPageIndex,
  ] = useMemo(() => {
    const firstThreshold = Math.round(
      pagesWithProgressBar.length / PROGRESS_THRESHOLD_AMOUNT,
    )
    const secondThreshold = firstThreshold * 2
    const thirdThreshold = firstThreshold * 3

    const pageIndex =
      pagesWithProgressBar.findIndex((step) => step.id === currentPageId) + 1

    return [firstThreshold, secondThreshold, thirdThreshold, pageIndex]
  }, [currentPageId, pagesWithProgressBar])

  const getProgressBarValue = useCallback(
    (threshold: number) =>
      currentPageIndex < threshold ? 0 : currentPageIndex - threshold,
    [currentPageIndex],
  )

  return !hasProgressbar ? null : (
    <S.Wrapper>
      {!isPageWithoutBackButton && <S.HeaderBackButton />}
      <S.StepBlock key={0}>
        <S.StepBlockProgress
          data-active={currentPageIndex > firstSectionThreshold}
          value={currentPageIndex}
          max={firstSectionThreshold}
        />
      </S.StepBlock>
      <S.StepBlock key={1}>
        <S.StepBlockProgress
          data-active={
            currentPageIndex <= secondSectionThreshold &&
            currentPageIndex > firstSectionThreshold
          }
          value={getProgressBarValue(firstSectionThreshold)}
          max={secondSectionThreshold - firstSectionThreshold}
        />
      </S.StepBlock>
      <S.StepBlock key={2}>
        <S.StepBlockProgress
          data-active={
            currentPageIndex <= thirdSectionThreshold &&
            currentPageIndex > secondSectionThreshold
          }
          value={getProgressBarValue(secondSectionThreshold)}
          max={thirdSectionThreshold - secondSectionThreshold}
        />
      </S.StepBlock>
      <S.StepBlock key={3}>
        <S.StepBlockProgress
          data-active={currentPageIndex > thirdSectionThreshold}
          value={getProgressBarValue(thirdSectionThreshold)}
          max={pagesWithProgressBar.length - thirdSectionThreshold}
        />
      </S.StepBlock>
    </S.Wrapper>
  )
}
