import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOfferCard = {
  Wrapper: styled.div`
    border-radius: 16px;
    padding: 12px;
    gap: 13px;
    border: 1px solid #dedede;
    background: ${Color.WHITE};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  `,
  OfferTitle: styled.p`
    color: #9b9ea1;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    max-width: 132px;

    strong {
      color: ${Color.DARK};
      font-weight: 700;
    }
  `,
  Price: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 94px;
  `,
  Free: styled.div`
    display: flex;
    align-items: center;
    color: #f90130;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 4px;

    svg {
      margin-right: 4px;
    }
  `,
  PriceValue: styled.div`
    color: #9b9ea1;
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  `,
  Image: styled.img`
    width: 56px;
    height: 56px;
    border-radius: 14px;
    border: 1px solid ${Color.WHITE};
    background: radial-gradient(
      127.9% 120.72% at 39.29% 41.07%,
      #5decff 2.1%,
      #2e8eff 100%
    );
    box-shadow: 0 4px 12px 0 #e5e5e5;
  `,
}
