import reviewDerek from 'assets/images/helped-millions-people-dereck.png'
import reviewMike from 'assets/images/helped-millions-people-mike.png'
import reviewPatricia from 'assets/images/helped-millions-people-patricia.png'
import reviewSamantha from 'assets/images/helped-millions-people-samantha.png'

export const QUESTION =
  'They did it. You can do too! Start your transformation today!'

export const ANSWER = 'Let’s Do It!'

export const REVIEW_SLIDES = [
  {
    name: 'purchaseFreeOffer.reviews.second.name',
    review: 'onboarding.helpedMillionsPeople.reviewSamantha',
    weight: 'onboarding.helpedMillionsPeople.weightSamantha',
    img: reviewSamantha,
    isBigReview: true,
  },
  {
    name: 'purchaseWithoutIntro.photoResult.author2.author',
    review: 'purchaseWithoutIntro.photoResult.author2.review',
    weight: 'onboarding.helpedMillionsPeople.weightPatricia',
    img: reviewPatricia,
  },
  {
    name: 'purchaseWithoutIntro.photoResult.author4.author',
    review: 'onboarding.helpedMillionsPeople.reviewDerek',
    weight: 'purchaseWithoutIntro.photoResult.author4.weight',
    img: reviewDerek,
  },

  {
    name: 'purchaseWithoutIntro.photoResult.author5.author',
    review: 'purchaseWithoutIntro.photoResult.author5.review',
    weight: 'onboarding.helpedMillionsPeople.weightMike',
    img: reviewMike,
  },
]
