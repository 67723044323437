import styled from 'styled-components'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledWeightLossLifecycle = {
  Root: styled.div`
    ${StyledNewAdultContainer}
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
  `,
  Image: styled.img``,
  TextBlock: styled.div`
    position: absolute;
    width: 100%;
    height: -webkit-fill-available;
    max-width: 375px;
    left: 50%;
    top: 280px;
    transform: translateX(-50%);
    display: flex;
    padding: 24px 0 0 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    border-radius: 24px 24px 0px 0px;
    background: ${Color.WHITE};
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  `,
  Title: styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  Paragraph: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY_80};

    strong {
      color: #17202a;
    }
  `,
}
