import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { PromoCodeWithTimer } from 'modules/purchase/components/PromoCodeWithTimer'
import { ReachTargetAdult } from 'modules/purchase/components/ReachTargetAdult'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { Reviews } from 'modules/purchase/components/Reviews'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockIntroOfferAdult } from 'modules/purchase/components/SubscriptionsBlockIntroOfferAdult'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseAdultNew as S } from './PurchaseAdultNew.styles'

export const PurchaseAdultNew: FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const { subscriptions, fetchingActionsList } = usePurchaseStore()
  const { buttonText } = useDynamicPaywallConfig()
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  usePurchaseAnalytics({
    screenId: ScreenId.BEFORE_AFTER_PHOTOS,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const hasIncludedVat = useVatInfo()

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    const pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.BEFORE_AFTER_PHOTOS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setScreenNameAction(null))
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          <S.HeaderWithTimer onButtonClick={handleShowPayment} />
          <ReachTargetAdult />

          <S.Wrapper isLarge>
            <S.RefundCard>
              <Trans i18nKey="purchaseRef.getRefundNoQuestions" />
            </S.RefundCard>
            <ReceivedPlan />
          </S.Wrapper>

          <S.Wrapper isLarge>
            <S.Title>{t`purchase1.subscription.title`}</S.Title>
            <PromoCodeWithTimer />
            <SubscriptionsBlockIntroOfferAdult>
              <Button
                type="button"
                data-order="2"
                data-text="get_my_plan"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </SubscriptionsBlockIntroOfferAdult>

            <S.Container>
              <S.RefundCardSmall>
                <Trans i18nKey="purchaseRef.getRefund" />
              </S.RefundCardSmall>
              <S.FeaturedBlock />
              <MoneyBackGuarantee />
            </S.Container>
          </S.Wrapper>

          <S.HowItWorks buttonOrder={3} onButtonClick={handleShowPayment} />

          <S.Wrapper>
            <PhotoResultV3
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <S.FAQList />
            <Reviews />

            <S.PurchaseButton
              type="button"
              data-order="4"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.PurchaseButton>

            <S.SubscriptionIntroDisclaimer />
            <SecurityInfo />
          </S.Wrapper>
        </div>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
          key={selectedSubscriptionId}
        />
      </S.CheckoutContainer>
    </>
  )
}
