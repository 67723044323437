import relaxedEmoji from 'assets/images/relaxed-emoji-min.png'
import resultAfterPng2 from 'assets/images/result2-female-after.png'
import resultAfterWebp2 from 'assets/images/result2-female-after.webp'
import resultBeforePng2 from 'assets/images/result2-female-before.png'
import resultBeforeWebp2 from 'assets/images/result2-female-before.webp'
import resultAfterPng3 from 'assets/images/result3-female-after.png'
import resultAfterWebp3 from 'assets/images/result3-female-after.webp'
import resultBeforePng3 from 'assets/images/result3-female-before.png'
import resultBeforeWebp3 from 'assets/images/result3-female-before.webp'
import resultAfterPng from 'assets/images/result-female-after.png'
import resultAfterWebp from 'assets/images/result-female-after.webp'
import resultBeforePng from 'assets/images/result-female-before.png'
import resultBeforeWebp from 'assets/images/result-female-before.webp'

export const REVIEWS_LIST = [
  {
    imageBefore: resultBeforeWebp,
    imageBeforePng: resultBeforePng,
    imageAfter: resultAfterWebp,
    imageAfterPng: resultAfterPng,
    startDate: 'purchaseNoOnboarding.reviews.review1.startDate',
    endDate: 'purchaseNoOnboarding.reviews.review1.endDate',
    review: 'purchaseNoOnboarding.reviews.review1.review',
    name: 'purchaseNoOnboarding.reviews.review1.reviewer',
  },
  {
    imageBefore: resultBeforeWebp2,
    imageBeforePng: resultBeforePng2,
    imageAfter: resultAfterWebp2,
    imageAfterPng: resultAfterPng2,
    startDate: 'purchaseNoOnboarding.reviews.review2.startDate',
    endDate: 'purchaseNoOnboarding.reviews.review2.endDate',
    review: 'purchaseNoOnboarding.reviews.review2.review',
    reviewIcon: relaxedEmoji,
    name: 'purchaseNoOnboarding.reviews.review2.reviewer',
  },
  {
    imageBefore: resultBeforeWebp3,
    imageBeforePng: resultBeforePng3,
    imageAfter: resultAfterWebp3,
    imageAfterPng: resultAfterPng3,
    startDate: 'purchaseNoOnboarding.reviews.review3.startDate',
    endDate: 'purchaseNoOnboarding.reviews.review3.endDate',
    review: 'purchaseNoOnboarding.reviews.review3.review',
    name: 'purchaseNoOnboarding.reviews.review3.reviewer',
  },
]
