import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './ButtonJa.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
  color?: string
  className?: string
}

export const ButtonJa: React.FC<IProps> = ({ children, ...props }) => (
  <StyledButton {...props} $disabled={props.disabled}>
    {children}
  </StyledButton>
)
