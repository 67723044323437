import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectIsStayFitFlow,
  selectScreenId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectUserName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCart,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createUpsellProductId } from 'helpers/createUpsellProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { logGeneralAddToCartEvents } from 'modules/purchase/helpers/logGeneralEvents'
import {
  selectCurrency,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { ONBOARDING_GOAL_EVENT } from 'root-constants'

export const usePrimerAnalytics = (): {
  logPaymentStarted: (paymentMethod: PaymentMethod) => void
  logPaypalPaymentStarted: () => void
  logPaymentFailed: ({
    error,
    paymentMethod,
  }: {
    error: any
    paymentMethod: PaymentMethod
  }) => void
} => {
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPeriodPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const currency = useSelector(selectCurrency)
  const screenName = useSelector(selectScreenName)
  const accountName = useSelector(selectStripeAccountName)
  const accountId = useSelector(selectStripeAccountId)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const screenId = useSelector(selectScreenId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const selectedSubscription = useSelector(selectSubscription)
  const userName = useSelector(selectUserName)
  const userCart = useSelector(selectUserCart)
  const priceId = useSelector(selectSubscriptionLookupKey)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPeriodQuantity = useSelector(selectSubscriptionTrialPeriodDays)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const goal = useMemo(
    () =>
      isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    [isStayFitGoal],
  )

  const upsellProducts = useMemo(
    () =>
      userCart.map(({ mainPrices, trialPrices }) =>
        createUpsellProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
          trialPrice: trialPrices?.fullPrice,
          periodDays: trialPrices?.durationDays,
        }),
      ),
    [userCart],
  )

  const productId = useMemo(
    () =>
      createIntroOfferProductId({
        priceId,
        trialPriceId,
        trialPeriodQuantity,
      }),
    [priceId, trialPriceId, trialPeriodQuantity],
  )

  const logPaymentStarted = useCallback(
    (paymentMethod) => {
      eventLogger.logPurchaseStarted({
        // email,
        screenName,
        screenId,
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        accountName,
        accountId,
        goal,
        upsellProducts,
      })

      logGeneralAddToCartEvents({
        currency,
        email,
        periodQuantity,
        periodName,
        fullPrice: currentPrice,
        selectedSubscription,
        uuid,
        isPersonalDataAllowed,
        userName,
      })
    },
    [
      upsellProducts,
      userName,
      screenName,
      screenId,
      productId,
      currentPrice,
      trialPeriodPrice,
      currency,
      accountName,
      accountId,
      goal,
      email,
      periodQuantity,
      periodName,
      selectedSubscription,
      uuid,
      isPersonalDataAllowed,
    ],
  )

  const logPaymentFailed = useCallback(
    ({ error, paymentMethod }) => {
      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        error: {
          type: error.type,
          code: error.code,
          description: error.message,
        },
        paymentMethod,
        screenName,
        screenId,
        paymentSystem: PaymentSystem.PRIMER,
        accountId,
        accountName,
        goal,
        upsellProducts,
      })
    },
    [
      upsellProducts,
      productId,
      currentPrice,
      trialPeriodPrice,
      currency,
      screenName,
      screenId,
      accountId,
      accountName,
      goal,
    ],
  )

  const logPaypalPaymentStarted = useCallback(() => {
    eventLogger.logPurchaseStartedPayPal({
      // email,
      screenName,
      screenId,
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PRIMER,
      accountName,
      accountId,
      goal,
      upsellProducts,
    })
  }, [
    upsellProducts,
    screenName,
    screenId,
    productId,
    currentPrice,
    trialPeriodPrice,
    currency,
    accountName,
    accountId,
    goal,
  ])

  return {
    logPaymentStarted,
    logPaymentFailed,
    logPaypalPaymentStarted,
  }
}
