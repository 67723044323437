export const QUESTION = 'How would you describe your weight fluctuations?'

export const OPTION_VALUES = [
  {
    value: 'weightFastLoseSlowly',
    title: 'onboarding.weightFluctuations.weightFastLoseSlowly',
  },
  {
    value: 'gainLoseEasily',
    title: 'onboarding.weightFluctuations.gainLoseEasily',
  },
  {
    value: 'struggleGainWeight',
    title: 'onboarding.weightFluctuations.struggleGainWeight',
  },
]

export const OPTION_VALUES_CLEAR = [
  {
    value: 'weightFastLoseSlowly',
    title: 'onboarding.weightFluctuations.weightFastLoseSlowly',
  },
  {
    value: 'gainLoseEasily',
    title: 'onboarding.weightFluctuations.gainLoseEasilyClear',
  },
  {
    value: 'struggleGainWeight',
    title: 'onboarding.weightFluctuations.struggleGainWeight',
  },
]
