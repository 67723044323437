import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCancelOfferQuestion = {
  Root: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    padding-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  CancelOfferQuestionContainer: styled(Container)`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 375/176;
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: top;
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  List: styled.ul`
    padding-left: 26px;
  `,
  Option: styled.li`
    max-width: 280px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    position: relative;

    &::before {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      background-color: ${Color.PRIMARY};
      border-radius: 50%;
      position: absolute;
      left: -18px;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  Subtitle: styled.h2`
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
  `,
  Actions: styled.div`
    display: flex;
    margin-bottom: 32px;

    & label {
      width: calc(50% - 5px);

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  `,
  Button: styled.div<{ $isBellyFlow: boolean }>`
    border-radius: 14px;
    background-color: #e3e4e8;
    border: 0;
    padding: 16px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;

    input:checked + & {
      background: ${Color.PRIMARY};
      color: #fff;
    }

    ${({ $isBellyFlow }) =>
      $isBellyFlow &&
      css`
        font-size: 17px;
        font-weight: 700;
        border-radius: 30px;
      `}
  `,
  Offer: styled.div`
    padding: 40px 13px;
    background-color: ${Color.LIGHT};
    border-radius: 16px;
  `,
  OfferTitle: styled.h2`
    font-weight: 900;
    font-size: 24px;
    color: ${Color.DARK};
    line-height: 32px;
    margin-bottom: 12px;
    text-align: center;
  `,
  OfferSubtitle: styled.p`
    color: ${Color.DARK};
    margin-bottom: 8px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  `,
  OfferSubtitleTop: styled.span`
    font-weight: 600;
  `,
  OfferImage: styled.img`
    vertical-align: top;
    margin-bottom: 6px;
  `,
  OfferContainer: styled.div`
    position: relative;
  `,
  OfferText: styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    width: 264px;
    color: ${Color.WHITE};
    text-align: center;

    & span {
      text-transform: uppercase;
    }
  `,
  OfferDescription: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DARK};
    text-align: center;
    margin: 0;
  `,
  ButtonContainer: styled(Container)`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 30px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
}
