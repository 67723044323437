import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledButton = styled.button<{
  $disabledBackgroundColor?: string
  $disabled?: boolean
}>`
  width: 100%;
  height: 56px;
  display: block;
  margin: 0 auto;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 30px;
  background: ${({ $disabled }) =>
    $disabled
      ? Color.GRAY_40
      : 'linear-gradient(90deg,#4ac7ff 0%,#03aaf5 79.2%,#01a3eb 100%)'};
  border-right: ${({ $disabled }) =>
    $disabled ? '4px solid #A9A9A9' : '4px solid #0390CF'};
  border-bottom: ${({ $disabled }) =>
    $disabled ? '4px solid #A9A9A9' : '4px solid #0390CF'};
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  color: ${Color.WHITE};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:disabled {
    background-color: ${Color.GRAY_40};
  }
`
