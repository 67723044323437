import styled from 'styled-components'

import { dotsAnimationStyles } from 'components/styles'

import { Color } from 'root-constants'

export const StyledLoadingButton = {
  LoadingButton: styled.button`
    min-width: 88px;
    border: 1px solid ${Color.GRAY};
    background-color: transparent;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    color: ${Color.GRAY};
    margin-right: 11px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & > div {
      ${dotsAnimationStyles};
    }
  `,
}
