import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setIsInContextShownAction,
  setLanguageAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsInContextShown,
} from 'root-redux/selects/common'

import { Cohort, Locale } from 'root-constants'

import { SUPPORTED_LOCALES_MAP } from '../../constants/supportedLocalesMap'
import { StyledInContextControlPanel as S } from './InContextControlPanel.styles'

export const InContextControlPanel: React.FC = () => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohort = useSelector(selectCurrentVariantCohort)

  const cohortToUse = parentCohort || cohort

  const handleChangeInContextVisibility = useCallback(() => {
    dispatch(setIsInContextShownAction(true))
  }, [dispatch])

  const handleChangeLanguage = useCallback(
    ({ target: { value } }) => {
      dispatch(setLanguageAction(value))
    },
    [dispatch],
  )

  return (
    <S.Wrapper>
      {isInContextShown ? (
        <S.Select onChange={handleChangeLanguage}>
          <option value={Locale.IN_CONTEXT}>In-context</option>
          {SUPPORTED_LOCALES_MAP[cohortToUse as Cohort].map((locale) => (
            <option key={locale} value={locale}>
              {locale.toUpperCase()}
            </option>
          ))}
        </S.Select>
      ) : (
        <S.Button type="button" onClick={handleChangeInContextVisibility}>
          Turn on In-Context
        </S.Button>
      )}
    </S.Wrapper>
  )
}
