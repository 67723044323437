import styled, { css, keyframes } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

const appearAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const tipStyles = css`
  position: absolute;
  padding: 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  color: white;
  border-radius: 7px;
  transform: translateX(-50%);
  white-space: nowrap;
`

export const StyledLoseWeightraph = {
  Wrapper: styled.div`
    overflow: hidden;
    margin-bottom: 70px;
  `,
  Title: styled.h2`
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    strong {
      color: ${Color.PRIMARY};
    }
  `,
  GraphContent: styled.div`
    position: relative;
    min-height: 184px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      min-height: 337px;
    }
  `,
  Labels: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: -70px auto 0;
    padding: 0 40px;
  `,
  Label: styled.p`
    opacity: 0.8;
    font-size: 11px;
    line-height: 2;
    text-align: center;
  `,
  TipNow: styled.p`
    ${tipStyles};
    top: 138px;
    left: 49px;
    animation-delay: 2000ms;
    background-color: ${Color.DISABLED};
  `,
  TipAfter: styled.p`
    ${tipStyles};
    top: 180px;
    left: 280px;
    margin: 0 -16px;
    animation-delay: 3000ms;
    background-color: ${Color.PRIMARY};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      margin: 0;
      top: 216px;
    }
  `,
}
