import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetAnswersAction } from 'root-redux/actions/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { eventLogger } from 'services/eventLogger.service'

export const useStartSession = (): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useEffect(() => {
    dispatch(resetAnswersAction())
    eventLogger.logSessionStarted()
  }, [dispatch])

  useEffect(() => {
    if (uuid) {
      window.fbq &&
        window.fbq(
          'track',
          'ViewContent',
          {},
          { eventID: isPersonalDataAllowed ? uuid : '' },
        )
      window.rdt &&
        window.rdt('track', 'ViewContent', {
          email: isPersonalDataAllowed ? email : '',
          externalId: isPersonalDataAllowed ? uuid : '',
          ...(!isPersonalDataAllowed && {
            em: '',
            uuid: '',
          }),
        })
    }
  }, [email, isPersonalDataAllowed, uuid])

  useEffect(() => window.pintrk && window.pintrk('track', 'pagevisit'), [])
}
