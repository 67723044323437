import React, { FC, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { removeProductFromCart } from 'root-redux/actions/user'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { eventLogger } from 'services/eventLogger.service'

import { CART_PRODUCT } from '../CartBlock/constants'
import { PopupUpsellProgram } from '../PopupUpsellProgram'
import { StyledCartProduct as S } from './CartProduct.styles'

export const CartProduct: FC<{
  price: number
  product: string
  planId: number
  hasBorder?: boolean
}> = ({ price, product, planId, hasBorder }) => {
  const dispatch = useDispatch()

  const { currency } = usePurchaseStore()

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const closePopup = () => setIsPopupOpen(false)

  const handleClick = () => {
    dispatch(removeProductFromCart(product, planId))
  }

  const handleClickLearnMore = () => {
    setIsPopupOpen(true)
    eventLogger.logUpsellPurchaseLearnMoreTap(product)
  }

  return (
    <>
      <S.Product $hasBorder={hasBorder}>
        <S.ProductImage src={CART_PRODUCT[product].imgSrc} />

        <S.ProductDescription>
          <S.Text>
            <S.ProductTitle>
              <Trans
                i18nKey={`purchaseWithCart.cartProducts.${product}.title`}
              />
            </S.ProductTitle>
            <S.ProductTitle>
              <Trans
                i18nKey="purchase1.checkout.fullPrice"
                values={{
                  fullPrice: price,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.ProductTitle>
          </S.Text>

          <S.Text>
            <S.LearnMore onClick={handleClickLearnMore}>
              <Trans i18nKey="purchaseWithCart.learnMore" />
            </S.LearnMore>

            <S.Cross onClick={handleClick} />
          </S.Text>
        </S.ProductDescription>
      </S.Product>

      <PopupUpsellProgram
        isVisible={isPopupOpen}
        setIsVisible={closePopup}
        product={product}
        {...CART_PRODUCT[product]}
      />
    </>
  )
}
