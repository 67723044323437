import React, { FC, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MOTIVATING_EVENTS } from 'pages/questions/Reach/constants'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMeasurementSystem,
  selectCurrentUserMotivationEvent,
} from 'root-redux/selects/common'

import { useIsInViewport } from 'hooks/useIsInViewport'

import usersRecommended from 'assets/images/recommended-by.png'

import { StyledGraphNew as S } from './GraphNew.styles'

export const GraphNew: FC = () => {
  const { t } = useTranslation()
  const weight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const motivationEvent = useSelector(selectCurrentUserMotivationEvent)
  const titleRef = useRef(null)

  const isVisible = useIsInViewport(titleRef)

  return (
    <>
      <S.PageTitle $isVisible={isVisible} ref={titleRef}>
        <Trans
          i18nKey="purchaseNew.graphTitle"
          values={{
            weight,
            metric: t(`commonComponents.${measurementSystem}`),
            occasion: t(MOTIVATING_EVENTS[motivationEvent]).toLowerCase(),
            context: motivationEvent,
          }}
          components={{
            div: <div />,
          }}
        />
      </S.PageTitle>

      <S.GraphContainer>
        <S.Graph />
        <S.UserRecommended>
          <S.Img src={usersRecommended} alt="Users recommended" />
          <S.Text>{t('onboarding.planPageIsReady.recommendedBy')}</S.Text>
        </S.UserRecommended>
      </S.GraphContainer>
    </>
  )
}
