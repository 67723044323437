import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  EventTypes,
  ICardPaymentMethodManager,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { stopFetching } from 'root-redux/actions/common'

import { PrimerErrorCode } from 'modules/purchase/constants'
import { getDefaultPaymentErrorsState } from 'modules/purchase/helpers/rootHelpers'
import { SET_PAYMENT_FORM_IS_LOADING } from 'modules/purchase/redux/actions/common'
import { TPaymentErrorState } from 'modules/purchase/types'

import {
  CardPaymentFieldName,
  PRIMER_CARD_CVC_ID,
  PRIMER_CARD_EXPIRY_ID,
  PRIMER_CARD_NUMBER_ID,
} from 'root-constants'

export const useInitPrimerCardForm = ({
  primerRef,
  formStyles,
  shouldAutofocus = true,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
  formStyles: Record<string, any>
  shouldAutofocus?: boolean
}): {
  initCardForm: () => Promise<void>
  cardManagerRef: React.RefObject<ICardPaymentMethodManager | null>
  errors: TPaymentErrorState
  isFormValid: boolean
  handleChange: (eventValue: {
    fieldName: CardPaymentFieldName
    hasError: boolean
    isComplete: boolean
    isFocused: boolean
  }) => void
} => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cardManagerRef = useRef<ICardPaymentMethodManager | null>(null)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )

  const hasErrors = useMemo(
    () => Object.values(errors).some(({ error }) => !!error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () => Object.values(errors).some(({ isTouched }) => !isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some(({ isComplete }) => !isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  const handleChange = useCallback(
    ({
      fieldName,
      hasError,
      isComplete,
      isFocused,
    }: {
      fieldName: CardPaymentFieldName
      hasError: boolean
      isComplete: boolean
      isFocused: boolean
    }) => {
      let error = ''

      if (hasError) {
        error = PrimerErrorCode.CARD_IS_INVALID
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: {
          isTouched: true,
          error,
          isComplete,
          isShown: true,
          isFocused,
        },
      }))
    },
    [],
  )

  const initCardForm = useCallback(async () => {
    if (!primerRef.current) return

    const cardManager = await primerRef.current?.createPaymentMethodManager(
      PaymentMethodType.PAYMENT_CARD,
    )

    if (!cardManager) return

    const {
      cardNumberInput,
      expiryInput,
      cvvInput,
    } = cardManager.createHostedInputs()

    await cardManager.validate()
    await Promise.all([
      cardNumberInput.render(PRIMER_CARD_NUMBER_ID, {
        placeholder: t('purchase1.paymentForm.cardNumber'),
        style: formStyles,
      }),
      expiryInput.render(PRIMER_CARD_EXPIRY_ID, {
        placeholder: t('purchase1.paymentForm.cardExpiryPlaceholder'),
        style: formStyles,
      }),
      cvvInput.render(PRIMER_CARD_CVC_ID, {
        placeholder: t('purchaseCancelOffer.checkout.securityNumber'),
        style: formStyles,
      }),
    ])

    if (shouldAutofocus) {
      cardNumberInput.focus()
    }

    cardNumberInput.addEventListener(EventTypes.CHANGE, (event: any) => {
      handleChange({
        fieldName: CardPaymentFieldName.NUMBER,
        hasError:
          !event.valid &&
          (event.errorCode === PrimerErrorCode.CARD_IS_INCOMPLETE ||
            event.errorCode === PrimerErrorCode.CARD_IS_INVALID),
        isComplete: event.valid,
        isFocused: event.active,
      })
    })

    expiryInput.addEventListener(EventTypes.CHANGE, (event: any) => {
      handleChange({
        fieldName: CardPaymentFieldName.EXPIRY,
        hasError:
          !event.valid &&
          (event.errorCode === PrimerErrorCode.CARD_EXPIRY_YEAR_INVALID ||
            event.errorCode === PrimerErrorCode.CARD_EXPIRY_MONTH_INVALID ||
            event.errorCode === PrimerErrorCode.CARD_IS_EXPIRED),
        isComplete: event.valid,
        isFocused: event.active,
      })
    })

    cvvInput.addEventListener(EventTypes.CHANGE, (event: any) => {
      handleChange({
        fieldName: CardPaymentFieldName.CVC,
        hasError:
          !event.valid &&
          (event.errorCode === PrimerErrorCode.CVV_IS_INCOMPLETE ||
            event.errorCode === PrimerErrorCode.CVV_IS_INVALID),
        isComplete: event.valid,
        isFocused: event.active,
      })
    })

    cardManagerRef.current = cardManager
    dispatch(stopFetching(SET_PAYMENT_FORM_IS_LOADING))
  }, [dispatch, formStyles, handleChange, primerRef, shouldAutofocus, t])

  return {
    initCardForm,
    cardManagerRef,
    errors,
    isFormValid,
    handleChange,
  }
}
