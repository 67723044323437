import arms from 'assets/images/arms-issue.png'
import armsJa from 'assets/images/arms-ja.png'
import elbowsLegs from 'assets/images/elbows-issue-legs.png'
import elbows from 'assets/images/elbows-issue.png'
import elbowsJa from 'assets/images/elbows-ja.png'
import feetLegs from 'assets/images/feet-issue-legs.png'
import feet from 'assets/images/feet-issue.png'
import feetJa from 'assets/images/feet-ja.png'
import kneesLegs from 'assets/images/knees-issue-legs.png'
import knees from 'assets/images/knees-issue.png'
import kneesJa from 'assets/images/knees-ja.png'
import legsLegs from 'assets/images/legs-issue-legs.png'
import legs from 'assets/images/legs-issue.png'
import legsJa from 'assets/images/legs-ja.png'
import neck from 'assets/images/neck-issue.png'
import neckJa from 'assets/images/neck-ja.png'
import noneLegs from 'assets/images/no-issue-legs.png'
import noneJa from 'assets/images/no-issues-ja.png'
import none from 'assets/images/none-issue.png'
import shouldersLegs from 'assets/images/shoulders-issue-legs.png'
import shoulders from 'assets/images/shoulders-issue.png'
import shouldersJa from 'assets/images/shoulders-ja.png'

export const QUESTION = 'Have you suffered any injuries recently?'
export const SELECT_NONE = 'noIssues'

export const OPTION_VALUES = [
  {
    text: `onboarding.injuriesIssues.${SELECT_NONE}`,
    value: SELECT_NONE,
    imageUrl: none,
  },
  {
    text: 'onboarding.injuriesIssues.knees',
    value: 'knees',
    imageUrl: knees,
  },
  {
    text: 'onboarding.injuriesIssues.shoulders',
    value: 'shoulders',
    imageUrl: shoulders,
  },
  {
    text: 'onboarding.injuriesIssues.neck',
    value: 'neck',
    imageUrl: neck,
  },
  {
    text: 'onboarding.injuriesIssues.arms',
    value: 'arms',
    imageUrl: arms,
  },
  {
    text: 'onboarding.injuriesIssues.elbows',
    value: 'elbows',
    imageUrl: elbows,
  },
  {
    text: 'onboarding.injuriesIssues.legs',
    value: 'legs',
    imageUrl: legs,
  },
  {
    text: 'onboarding.injuriesIssues.feet',
    value: 'feet',
    imageUrl: feet,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    text: `onboarding.injuriesIssues.${SELECT_NONE}`,
    value: SELECT_NONE,
    imageUrl: noneJa,
  },
  {
    text: 'onboarding.injuriesIssues.knees',
    value: 'knees',
    imageUrl: kneesJa,
  },
  {
    text: 'onboarding.injuriesIssues.shoulders',
    value: 'shoulders',
    imageUrl: shouldersJa,
  },
  {
    text: 'onboarding.injuriesIssues.neck',
    value: 'neck',
    imageUrl: neckJa,
  },
  {
    text: 'onboarding.injuriesIssues.arms',
    value: 'arms',
    imageUrl: armsJa,
  },
  {
    text: 'onboarding.injuriesIssues.elbows',
    value: 'elbows',
    imageUrl: elbowsJa,
  },
  {
    text: 'onboarding.injuriesIssues.legs',
    value: 'legs',
    imageUrl: legsJa,
  },
  {
    text: 'onboarding.injuriesIssues.feet',
    value: 'feet',
    imageUrl: feetJa,
  },
]
export const OPTION_VALUES_LEGS = [
  {
    text: `onboarding.injuriesIssues.${SELECT_NONE}`,
    value: SELECT_NONE,
    imageUrl: noneLegs,
  },
  {
    text: 'onboarding.injuriesIssues.knees',
    value: 'knees',
    imageUrl: kneesLegs,
  },
  {
    text: 'onboarding.injuriesIssues.shoulders',
    value: 'shoulders',
    imageUrl: shouldersLegs,
  },
  {
    text: 'onboarding.injuriesIssues.neck',
    value: 'neck',
    imageUrl: neck,
  },
  {
    text: 'onboarding.injuriesIssues.arms',
    value: 'arms',
    imageUrl: arms,
  },
  {
    text: 'onboarding.injuriesIssues.elbows',
    value: 'elbows',
    imageUrl: elbowsLegs,
  },
  {
    text: 'onboarding.injuriesIssues.legs',
    value: 'legs',
    imageUrl: legsLegs,
  },
  {
    text: 'onboarding.injuriesIssues.feet',
    value: 'feet',
    imageUrl: feetLegs,
  },
]
