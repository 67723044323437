import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledReachingGoalResult = {
  Container: styled(Container)<{ $imageUrl: string }>`
    position: relative;
    height: calc(100% - 52px);
    ${StyledNewAdultContainer}

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      max-width: 375px;
      background-image: url(${({ $imageUrl }) => $imageUrl});
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
    }
  `,
  Root: styled.div`
    position: relative;
    height: 100%;
  `,
  TextBlock: styled.div`
    position: absolute;
    top: 60%;
    left: 0;
    z-index: 1;
    width: 100%;

    @media (min-height: 700px) {
      top: 50%;
    }
  `,
  Subtitle: styled.p`
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    border-top: none;
    background-color: transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
}
