import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpgradeSubscriptionItem = {
  UpgradePlanBlock: styled.div`
    background: ${Color.WHITE};
  `,
  PlanTitle: styled.p`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 12px;
  `,
  AdditionalInfoContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
  `,
  BonusContainer: styled.div`
    display: flex;
  `,
  FirstTitle: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding-right: 8px;
    color: ${Color.PRIMARY};
  `,
  SecondTitle: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY};
  `,
  BonusPrice: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY};
  `,
  Period: styled.div`
    padding: 2px 12px;
    background: #eaf7ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    color: #03aaf5;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 32px;
  `,
}
