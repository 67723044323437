import { createProductId } from 'helpers/createProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { ScreenId, ScreenName, TimeInterval } from 'root-constants'

export const handleLogPurchaseCompleted = ({
  productId,
  productName,
  price,
  paymentMethod,
  screenName,
  screenId,
  currency,
  isTrialActive,
  paymentSystem,
  goal,
}: {
  productId: string
  productName: string
  price: number
  paymentMethod: PaymentMethod
  screenName: ScreenName
  screenId: ScreenId
  currency: string
  paymentSystem: PaymentSystem
  isTrialActive?: boolean
  goal: string
}): void => {
  eventLogger.logPurchaseCompleted({
    productId,
    productName,
    priceDetails: {
      price,
      currency,
      trial: isTrialActive,
    },
    paymentMethod,
    screenName,
    screenId,
    paymentSystem,
    goal,
  })
}

export const handleLogGoogleAnalyticsEvent = ({
  subscriptionId,
  price,
  periodName,
  periodQuantity,
  currency,
  screenName,
}: {
  subscriptionId: string
  price: number
  periodName: TimeInterval | null
  periodQuantity: number
  currency: string
  screenName: ScreenName
}): void => {
  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
}

export const handleLogPinterestEvent = ({
  price,
  periodName,
  periodQuantity,
  uuid,
  currency,
}: {
  price: number
  periodName: TimeInterval | null
  periodQuantity: number
  uuid: string
  currency: string
}): void => {
  window.pintrk &&
    window.pintrk('track', 'checkout', {
      value: price,
      currency: currency.toUpperCase(),
      order_id: uuid,
      line_items: [
        {
          product_category: 'wellness',
          product_name: createProductId({
            periodName,
            periodQuantity,
            price,
          }),
          product_id: createProductId({
            periodName,
            periodQuantity,
            price,
          }),
          product_price: price,
        },
      ],
    })
}

export const logSuccessfulPayPalPayment = ({
  productId,
  productName,
  price,
  predictedLtv,
  trialPrice,
  trialPeriodDays,
  subscriptionId,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  currency,
  screenName,
  screenId,
  email,
  paymentSystem,
  goal,
  isPersonalDataAllowed,
  name,
}: {
  productId: TProductId
  productName: string
  price: number
  predictedLtv: number | null
  trialPrice: number
  trialPeriodDays: number
  subscriptionId: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number
  paymentMethod: PaymentMethod
  currency: string
  screenName: ScreenName
  screenId: ScreenId
  email: string
  paymentSystem: PaymentSystem
  goal: string
  isPersonalDataAllowed: boolean
  name: string
}): void => {
  if (trialPeriodDays) {
    window.fbq &&
      window.fbq(
        'track',
        'StartTrial',
        {
          value: trialPrice,
          currency,
          subscription_id: subscriptionId,
          subscription_sid: subscriptionId,
        },
        { eventID: isPersonalDataAllowed ? uuid : '' },
      )
  }
  window.ttq &&
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  window.ttq &&
    window.ttq.track('CompletePayment', {
      value: trialPrice || price,
      currency: currency.toUpperCase(),
      content_id: subscriptionId,
      event_id: isPersonalDataAllowed ? uuid : '',
    })
  window.axon &&
    window.axon('track', 'purchase', {
      value: trialPrice || price,
      currency: currency.toUpperCase(),
      user_id: isPersonalDataAllowed ? uuid : '',
      items: {
        item_id: subscriptionId,
        item_name: `${periodQuantity} ${periodName} plan`,
        price: trialPrice || price,
        quantity: 1,
      },
    })
  window.snaptr &&
    window.snaptr('track', 'PURCHASE', {
      price: trialPrice || price,
      currency,
      client_dedup_id: subscriptionId,
      transaction_id: subscriptionId,
      user_email: isPersonalDataAllowed ? email : '',
      firstname: isPersonalDataAllowed ? name : '',
    })
  window.obApi &&
    window.obApi('track', 'Purchase', {
      orderValue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })
  window.fbq &&
    window.fbq(
      'track',
      'Subscribe',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: isPersonalDataAllowed ? uuid : '' },
    )
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'make_purchase',
      revenue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })
  window.rdt &&
    window.rdt('track', 'Purchase', {
      value: trialPrice || price,
      currency,
      transactionId: subscriptionId,
      email: isPersonalDataAllowed ? email : '',
      externalId: isPersonalDataAllowed ? uuid : '',
      ...(!isPersonalDataAllowed && {
        em: '',
        uuid: '',
      }),
    })
  window.fbq &&
    window.fbq(
      'track',
      'Purchase',
      {
        value: predictedLtv || trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: isPersonalDataAllowed ? uuid : '' },
    )
  handleLogPurchaseCompleted({
    productName,
    productId,
    price,
    paymentMethod,
    screenName,
    screenId,
    currency,
    isTrialActive: !!trialPeriodDays,
    paymentSystem,
    goal,
  })
  handleLogGoogleAnalyticsEvent({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
  handleLogPinterestEvent({
    price,
    periodName,
    periodQuantity,
    uuid,
    currency,
  })
}
