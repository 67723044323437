import styled, { css } from 'styled-components'

import { PaymentMethodToggle } from 'components/PaymentMethodToggle'

import closeSvg from 'assets/images/small-cross.svg'

import { Color } from 'root-constants'

type TContainerProps = {
  $hasBottomPadding: boolean
}

export const StyledCheckoutModal = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(53, 57, 72, 0.5);
  `,
  Container: styled.div<TContainerProps>`
    width: 359px;
    position: relative;
    top: 30px;
    max-height: 630px;
    height: fit-content;
    overflow-y: auto;
    overscroll-behavior: none;
    padding: 16px 16px 24px 16px;
    border-radius: 20px;
    background: ${Color.WHITE};

    ${({ $hasBottomPadding }) =>
      $hasBottomPadding &&
      css`
        padding-bottom: 115px;
      `};

    @media (min-height: 800px) {
      min-height: 498px;
      max-height: 711px;
    }
  `,
  ModalHeader: styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 24px;
  `,
  ModalName: styled.div`
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  Cross: styled.button`
    position: absolute;
    right: 16px;
    padding: 0;
    display: block;
    width: 10px;
    height: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  Title: styled.h2`
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Total: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;

    & > span:first-child {
      color: ${Color.GRAY_60};
      font-size: 15px;
      font-weight: 600;
    }

    & > span:last-child {
      font-size: 15px;
      color: #292d3d;
      font-weight: 600;
      line-height: 20px;
    }
  `,
  PaymentFormWrapper: styled.div<{ $isHidePaymentMethodToggle?: boolean }>`
    width: 100%;
  `,
  PaymentsSystemImage: styled.img`
    margin: 32px auto 16px;
    max-width: 256px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 24px;
  `,
  PaymentsSystemText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.DARK};
    text-align: center;
    padding-bottom: 32px;
    font-style: normal;
    opacity: 0.5;
  `,
  PaymentMethodContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  PayFastTitle: styled.h2<{ $isHidePaymentMethodToggle?: boolean }>`
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: ${({ $isHidePaymentMethodToggle }) =>
      $isHidePaymentMethodToggle ? '16px' : '24px'};
  `,
  PaymentMethodToggle: styled(PaymentMethodToggle)`
    border-bottom: none;
    height: 78px;
    padding-bottom: 0;
    margin-top: 0;
  `,
  PriceDescription: styled.div`
    margin: 24px 0 0;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid #e9eaed;

    & > p {
      margin-bottom: 8px;
    }

    & > p:last-of-type {
      margin-bottom: 0;
    }
  `,
  PriceInfo: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  OldPrice: styled.span`
    color: #bd4260;
    font-weight: 600;
    text-decoration: line-through;
    margin-right: 4px;
  `,
  PriceBlockText: styled.span`
    color: #9498a5;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  TotalDiscount: styled.span`
    color: #bd4260;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyPayment: styled.div`
    margin: 16px 0 0;
    width: 100%;
    border-radius: 14px;
    background-color: #f2faf0;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DailyText: styled.span`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyAmount: styled.span`
    color: #7ad164;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  TotalPayment: styled.div`
    width: 100%;
    margin: 16px 0 24px;
  `,
  TotalDescription: styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Color.DARK};
    font-size: 16px;
    line-height: 24px;
  `,
  TotalText: styled.span`
    font-weight: 600;
  `,
  TotalAmount: styled.span`
    font-weight: 400;

    & > strong {
      font-weight: 700;
    }
  `,
  SaveText: styled.p`
    color: #bd4260;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  ButtonTitle: styled.span<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
    margin-top: 24px;
  `,
}
