export const QUESTION = 'How long ago were you in the best shape of your life?'

export const OPTION_VALUES = [
  {
    value: 'lessYearsAgo',
    title: 'onboarding.bestShape.lessYearsAgo',
  },
  {
    value: 'oneToTwo',
    title: 'onboarding.bestShape.oneToTwo',
  },
  {
    value: 'moreThenThree',
    title: 'onboarding.bestShape.moreThenThree',
  },
  {
    value: 'never',
    title: 'onboarding.bestShape.never',
  },
]
