import React from 'react'
import { Trans } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import arrow from 'assets/images/sprite/arrow-down.svg'

import { AccordionStyles as S } from './Accordion.styles'

type TProps = {
  title: string
  content: string
  isOpen: boolean
  onClick: () => void
}

export const AccordionItem: React.FC<TProps> = ({
  content,
  title,
  isOpen,
  onClick,
}) => (
  <S.AccordionItem>
    <S.AccordionButton onClick={onClick} $isOpen={isOpen}>
      <S.AccordionTitle>
        <Trans i18nKey={title} />
      </S.AccordionTitle>

      <SvgImage svg={arrow} width={24} />
    </S.AccordionButton>

    <S.AccordionContent $isOpen={isOpen}>
      {isOpen && <Trans i18nKey={content} />}
    </S.AccordionContent>
  </S.AccordionItem>
)
