import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { Color } from 'root-constants'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledUpgradeLife = {
  Container: styled(Container)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  Subtitle: styled.h3`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 14px;
    margin-top: 16px;
    color: ${Color.DARK};
    text-align: center;
  `,
  AnimationContainer: styled.div``,
  NavigationButton: styled(NavigationButton)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;
      `}
  `,
}
