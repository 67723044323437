import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  setIsPaymentStatusShown,
  setPaymentAnimationStatus,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectIsPaymentStatusShown,
  selectPaymentAnimationStatus,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useLockScroll } from 'hooks/useLockScroll'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { AnimationState } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import paymentErrorAnimation from 'assets/animation/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animation/paymentSuccessAnimation.json'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { EXTENDED_MODAL_TIMEOUT } from 'root-constants'

import { StyledPaymentModal as S } from './PaymentStatusModal.styles'

const commonLottieProps = {
  width: '132px',
  height: '132px',
}

type TPaymentStatusModalProps = {
  hasDisclaimer?: boolean
}

export const PaymentStatusModal: React.FC<TPaymentStatusModalProps> = ({
  hasDisclaimer = false,
}) => {
  const { t } = useTranslation()
  const { search, pathname } = useLocation()

  const animationStatus = useSelector(selectPaymentAnimationStatus)
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const isPaymentStatusShown = useSelector(selectIsPaymentStatusShown)

  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  const currentPageId = useMemo(() => getPageIdFromPathName(pathname), [
    pathname,
  ])

  useLockScroll(isPaymentStatusShown)

  const handlePaymentWaitingTimeout = useCallback(() => {
    dispatch(setIsPaymentStatusShown(false))
    dispatch(setIsPaymentStatusShown(false))
    dispatch(setSubscriptionListAction([]))
    dispatch(setSelectedSubscriptionAction(null))
    dispatch(getUserStatusAction(uuid))

    if (currentPageId === PageId.RESULT) {
      goTo({
        pathname: PageId.LOGIN,
        search,
      })
    }
  }, [currentPageId, dispatch, search, uuid])

  useEffect(() => {
    switch (animationStatus) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        break
    }

    return () => lottie.destroy()
  }, [animationStatus])

  useEffect(() => {
    let timeoutId

    if (animationStatus === AnimationState.SUCCESS && isPaymentStatusShown) {
      timeoutId = setTimeout(
        handlePaymentWaitingTimeout,
        EXTENDED_MODAL_TIMEOUT,
      )
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [
    animationStatus,
    currentPageId,
    dispatch,
    handlePaymentWaitingTimeout,
    isPaymentStatusShown,
    search,
    uuid,
  ])

  const handleResetError = useCallback(() => {
    dispatch(setIsPaymentStatusShown(false))
    dispatch(setPaymentAnimationStatus(false))
  }, [dispatch])

  return isPaymentStatusShown ? (
    <S.ModalWrapper>
      <S.Container>
        <S.LottieContainer>
          {animationStatus === AnimationState.SUCCESS && (
            <>
              <div
                ref={completeAnimationRef}
                style={{ ...commonLottieProps }}
              />
              <S.PaymentStatus>
                {t`purchase1.paymentWaiting.paymentWasSuccessful`}
              </S.PaymentStatus>

              {hasDisclaimer && (
                <S.Disclaimer>{t`purchase1.paymentWaiting.disclaimerForSuccessfulPayment`}</S.Disclaimer>
              )}
            </>
          )}
          {animationStatus === AnimationState.ERROR && (
            <>
              <div ref={errorAnimationRef} style={{ ...commonLottieProps }} />

              <S.ErrorStatus>
                {t`purchase1.paymentWaiting.errorDuringPayment`}
              </S.ErrorStatus>
              <S.ErrorInfo />

              <S.ErrorButton onClick={handleResetError} type="button">
                {t`purchase1.paymentWaiting.retry`}
              </S.ErrorButton>
            </>
          )}
        </S.LottieContainer>
      </S.Container>
    </S.ModalWrapper>
  ) : null
}
