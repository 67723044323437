import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import circleLine from 'assets/images/circled-line.svg'

import { Color } from 'root-constants'

export const StyledEmail = {
  Title: styled.h2<{
    $isAgeFlow?: boolean
  }>`
    margin-top: 45px;
    font-weight: ${({ $isAgeFlow }) => ($isAgeFlow ? 800 : 700)};
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }

    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        margin-bottom: 30px;
      `}
  `,
  Subtitle: styled.p<{
    $isMetricSystemSelected?: boolean
  }>`
    margin: 8px 0 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.PRIMARY};

    span {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -8px;
        left: ${({ $isMetricSystemSelected }) =>
          $isMetricSystemSelected ? '-4px' : '0'};
        background-image: url(${circleLine});
        width: ${({ $isMetricSystemSelected }) =>
          $isMetricSystemSelected ? '58px' : '68px'};
        height: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: ${({ $isMetricSystemSelected }) =>
          $isMetricSystemSelected ? '58px 40px' : '68px 40px'};
      }
    }

    svg {
      vertical-align: middle;
    }
  `,
  InputContainer: styled.div`
    margin-bottom: 30px;
  `,
  Disclaimer: styled.p`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(19, 29, 48, 0.5);
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 8px;
  `,
  ButtonContainer: styled.div<{
    $isAgeFlow: boolean
  }>`
    position: relative;
    margin-bottom: 30px;
  `,
  Container: styled(Container)<{
    $isAgeFlow: boolean
  }>`
    ${({ $isAgeFlow }) =>
      $isAgeFlow &&
      css`
        ${StyledAgeFlowContainer};
      `}
  `,
}
