import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AnswerWithIcon, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

import { StyledMotivatingEvent as S } from './MotivatingEvent.styles'
import { OPTION_VALUES, OPTION_VALUES_JAPANESE, QUESTION } from './constants'

export const MotivatingEvent: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { optionData } = useDynamicOBConfig()
  const { isTTClearFlow, isNewAdultFlow } = useCohortInfo()
  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const options = useMemo(() => {
    if (optionData?.length) return optionData

    if (isJapaneseFlow || isNewAdultFlow) return OPTION_VALUES_JAPANESE

    return OPTION_VALUES
  }, [isNewAdultFlow, isJapaneseFlow, optionData])

  const theme = (() => {
    if (isNewAdultFlow) {
      return answerTheme.DANCEBIT_ADULT
    }

    if (isJapaneseFlow) {
      return answerTheme.DANCEBIT_JAPANESE
    }

    return answerTheme.DANCEBIT
  })()

  return (
    <S.Container isLarge $isJapanese={isJapaneseFlow} $isAdult={isNewAdultFlow}>
      <PageTitle marginBottom={24}>
        {t(
          isTTClearFlow
            ? 'onboarding.motivatingEvent.titleClear'
            : 'onboarding.motivatingEvent.title',
        )}
      </PageTitle>

      {options.map(({ text, value, imageUrl }) => (
        <AnswerWithIcon
          {...optionProps}
          disabled={isAnswersDisabled}
          key={value}
          value={value}
          iconSrc={imageUrl}
          theme={theme}
          iconHeight="40px"
          iconMinHeight="40px"
          iconMinWidth="40px"
          height="80px"
          padding="16px"
          maxWidth="345px"
          iconAlignSelf="center"
          iconWidth="40px"
          margin="0 0 16px 0"
        >
          <Trans>{text}</Trans>
        </AnswerWithIcon>
      ))}
    </S.Container>
  )
}
