import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenNameAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { FAQList } from 'modules/purchase/components/FAQList'
import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { IntroMedia } from 'modules/purchase/components/IntroMedia'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PhotoResult } from 'modules/purchase/components/PhotoResult'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubheaderWithTimer } from 'modules/purchase/components/SubheaderWithTimer'
import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { Checkout } from 'modules/purchase/pages/Checkout'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  Cohort,
  MAIN_GOALS,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseVariant1 as S } from './PurchaseVariant1.styles'

export const PurchaseVariant1: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)
  const isStayFit = useSelector(selectIsStayFitFlow)

  const {
    cohort,
    fetchingActionsList,
    subscriptions,
    selectedSubscriptionId,
  } = usePurchaseStore()

  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const cohortToUse = parentCohort || cohort

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
  })

  const { buttonText, title } = useDynamicPaywallConfig()

  const hasIncludedVat = useVatInfo()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const isClearCohort = useMemo(
    () => cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW,
    [cohortToUse],
  )

  const hasPrices = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const hasBottomDisclaimer = useMemo(
    () =>
      cohortToUse === Cohort.DANCEBIT_FLOW_1 ||
      cohortToUse === Cohort.DANCEBIT_CLEAR_FLOW,
    [cohortToUse],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${hasIncludedVat ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX}`,
      ),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])
  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <IntroMedia />
          <S.Wrapper>
            <S.ProgramPlanContainer>
              <S.ProgramPlanTitle>
                {title || (
                  <Trans
                    i18nKey={
                      isClearCohort
                        ? `purchase1.programDescriptionTitleClearEdition`
                        : `purchase1.programDescriptionTitle`
                    }
                    components={[<br />]}
                    values={{
                      context: isStayFit
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                )}
              </S.ProgramPlanTitle>
              <ul>
                <S.ListItem>
                  <Trans
                    i18nKey={
                      isClearCohort
                        ? `purchase1.personalPlanClearEdition`
                        : `purchase1.personalPlan`
                    }
                    components={[<strong />]}
                    values={{
                      context: isStayFit
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                </S.ListItem>
                <S.ListItem>
                  <Trans
                    i18nKey="purchase1.effectiveWorkouts"
                    values={{
                      context: isStayFit
                        ? MAIN_GOALS.STAY_FIT
                        : MAIN_GOALS.LOSE_WEIGHT,
                    }}
                  />
                </S.ListItem>
                <S.ListItem>{t`purchase1.specialPrograms`}</S.ListItem>
                <S.ListItem>{t`purchase1.practiceAnywhere`}</S.ListItem>
                <S.ListItem>{t`purchase1.popularDanceStyles`}</S.ListItem>
              </ul>
            </S.ProgramPlanContainer>
          </S.Wrapper>

          <SubheaderWithTimer
            elemForComparisonRef={buttonWrapperElementRef}
            onButtonClick={handleShowPayment}
          />

          <SubscriptionsBlock buttonWrapperElementRef={buttonWrapperElementRef}>
            <S.Button
              type="button"
              data-order="2"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.Button>
          </SubscriptionsBlock>

          <S.Wrapper>
            <S.FeaturedBlockContainer>
              <FeaturedBlock />
            </S.FeaturedBlockContainer>
            <MoneyBackGuarantee />
            <SecurityInfo />
            <PhotoResult />
            <FAQList />
            <CustomerReviews />
            <S.ButtonContainer>
              <Button
                type="button"
                data-order="3"
                data-text="get_my_plan"
                onClick={handleShowPayment}
              >
                {buttonText || t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            {hasBottomDisclaimer && <S.SubscriptionDisclaimer />}
          </S.Wrapper>
        </>
      )}
      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <Checkout
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
