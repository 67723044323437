import React, { useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useSkipStep } from 'hooks/useSkipStep'

import { TPageProps } from 'models/common.model'

import { ADULT_COHORT, Cohort } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const Motivation: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const isStayFitGoal = useSelector(selectIsStayFitFlow)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const skipStep = useSkipStep({
    nextPagePath,
    pageId,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const shouldSkipStep = useMemo(
    () =>
      (cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal) ||
      ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse, isStayFitGoal],
  )

  useLayoutEffect(() => {
    if (shouldSkipStep) {
      skipStep(OPTION_VALUES.HOW_FITS)
    }
  }, [shouldSkipStep, skipStep])

  return (
    <Container>
      <PageTitle
        marginBottom={30}
      >{t`onboarding.motivation.question`}</PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.HOW_FITS}
      >
        <AnswerButton>{t`onboarding.motivation.howFits`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.READY_LOSE_WEIGHT}
      >
        <AnswerButton>{t`onboarding.motivation.readyLoseWeight`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.DROP_MOST_WEIGHT}
      >
        <AnswerButton>{t`onboarding.motivation.dropMostWeight`}</AnswerButton>
      </Option>
    </Container>
  )
}
