import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import firstUser from 'assets/images/avatar-user-1.png'
import secondUser from 'assets/images/avatar-user-2.png'
import thirdUser from 'assets/images/avatar-user-3.png'

import { StyledDancebitUsers as S } from './DancebitUsers.styles'
import { QUESTION } from './constants'

export const DancebitUsers: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.Wrapper>
        <ContainerBeforePinnedButton>
          <S.Container isLarge>
            <S.Content>
              <S.UsersBlock>
                <S.User src={firstUser} />
                <S.User src={secondUser} />
                <S.User src={thirdUser} />
              </S.UsersBlock>

              <S.UsersBlockDescription>
                <div>
                  <Trans i18nKey="onboarding.dancebitUsers.description" />
                </div>
              </S.UsersBlockDescription>

              <S.Title>
                <Trans i18nKey="onboarding.dancebitUsers.title" />
              </S.Title>

              <S.Subtitle>
                <Trans i18nKey="onboarding.dancebitUsers.subtitle" />
              </S.Subtitle>
            </S.Content>
          </S.Container>
        </ContainerBeforePinnedButton>
      </S.Wrapper>

      <S.NavigationButtonBelly type="button" onClick={handleNextClick}>
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </>
  )
}
