export const QUESTION = 'Select your additional goals'

export const MINIMUM_ANSWERS_COUNT = 1

export const OPTION_VALUES = {
  stayFit: [
    {
      value: 'getFirm',
      title: 'onboarding.mainGoal.getFirm',
    },
    {
      value: 'developFlex',
      title: 'onboarding.mainGoal.developFlex',
    },
    {
      value: 'learnDance',
      title: 'onboarding.mainGoal.learnDance',
    },
    {
      value: 'loveBody',
      title: 'onboarding.mainGoal.loveBody',
    },
    {
      value: 'reduceStress',
      title: 'onboarding.mainGoal.reduceStress',
    },
    {
      value: 'improveHeart',
      title: 'onboarding.mainGoal.improveHeart',
    },
  ],
  loseWeight: [
    {
      value: 'getFirm',
      title: 'onboarding.mainGoal.getFirm',
    },
    {
      value: 'developFlex',
      title: 'onboarding.mainGoal.developFlex',
    },
    {
      value: 'stayFit',
      title: 'onboarding.mainGoal.stayFit',
    },
    {
      value: 'learnDance',
      title: 'onboarding.mainGoal.learnDance',
    },
    {
      value: 'loveBody',
      title: 'onboarding.mainGoal.loveBody',
    },
    {
      value: 'reduceStress',
      title: 'onboarding.mainGoal.reduceStress',
    },
    {
      value: 'improveHeart',
      title: 'onboarding.mainGoal.improveHeart',
    },
  ],
}
