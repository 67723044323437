import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOfferCard = {
  TimerRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    width: 100%;

    &,
    div {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      color: ${Color.WHITE};
    }
  `,
  Wrapper: styled.div`
    width: 100%;
    border-radius: 8px;
    border: 2px solid #ff7171;
    background-color: #ff7171;
    margin-top: 12px;
  `,
  CardContainer: styled.div`
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    background: ${Color.WHITE};
  `,
  OfferInfo: styled.div`
    width: 199px;
    color: ${Color.LIGHT_GREY};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 12px;
  `,
  Price: styled.div<{ $isMXN: boolean }>`
    text-align: right;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    span {
      color: ${Color.LIGHT_GREY};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: line-through;

      ${({ $isMXN }) =>
        $isMXN &&
        `
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
      `}
    }
  `,
  PriceTitle: styled.div<{ $isMXN: boolean }>`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    ${({ $isMXN }) =>
      $isMXN &&
      `
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
      `}
  `,
  Title: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
    color: ${Color.DARK};
  `,
  Plan: styled.div`
    display: flex;
  `,
}
