import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledFAQList = {
  Wrapper: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
  `,
  ListBody: styled.ul``,
  ListItem: styled.li`
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.7);
    padding: 8px 8px 8px 16px;
    margin-bottom: 8px;
  `,
  ListItemTitle: styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ListItemText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 8px;
    padding-top: 16px;
    color: ${Color.GRAY_80};
  `,
  SvgIcon: styled(SvgImage)<{ $isOpened: boolean }>`
    padding: 5px;
    border-radius: 10px;
    background: #e3ebff;
    height: 32px;
    width: 32px;

    ${({ $isOpened }) =>
      $isOpened &&
      css`
        transform: rotate(180deg);
      `}
  `,
}
