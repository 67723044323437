import { StyledAgeFlowContainer } from 'pages/questions/Gender/Gender.styles'
import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledHowQuickly = {
  TitleContainer: styled.div`
    max-width: 328px;
    margin: 0 auto;
  `,
  Container: styled(Container)`
    ${StyledAgeFlowContainer};
  `,
}
