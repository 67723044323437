import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TSubheaderWithOfferTimerProps = {
  $isButtonVisible?: boolean
}
export const StyledSubheaderWithOfferTimer = {
  Wrapper: styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    align-items: center;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    max-height: 60px;
    margin: 30px auto 20px;
    padding: 16px;
    background: linear-gradient(87deg, #b1446b -5.86%, #ff5995 100%);
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    z-index: 10;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Container: styled.div<TSubheaderWithOfferTimerProps>`
    width: 100%;
    display: flex;
    justify-content: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'flex-start' : 'space-evenly'};
    align-items: center;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  TimerWithOffer: styled(Timer)<TSubheaderWithOfferTimerProps>`
    & > div {
      position: ${({ $isButtonVisible }) =>
        $isButtonVisible ? 'absolute' : 'static'};
      bottom: 10px;
      right: 130px;

      & div:not(:last-child) {
        margin-right: ${({ $isButtonVisible }) =>
          $isButtonVisible ? '0' : '5px'};
      }
    }
  `,
  TimerDisplay: styled.div`
    display: flex;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  TimerText: styled.span`
    font-feature-settings: 'clig' off, 'liga' off;

    &:first-child {
      font-size: 16px;
      font-style: normal;
      text-transform: uppercase;
      font-weight: 800;

      & strong {
        position: relative;
        font-size: 16px;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0;
          background-color: ${Color.WHITE};
        }
      }
    }

    &:last-child {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      & strong {
        font-size: 18px;
        font-weight: 800;
        line-height: 20px;
        text-decoration: none;
        margin-left: 8px;
      }
    }
  `,
  ButtonContainer: styled.div<TSubheaderWithOfferTimerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
  `,
  TimerButton: styled.button`
    display: flex;
    height: 40px;
    width: 95px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
  `,
}
