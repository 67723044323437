import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DEFAULT_AGE } from 'pages/questions/AgeWithScroll/constants'
import Swiper, { Mousewheel, Virtual } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectUserAge,
} from 'root-redux/selects/common'

import { useDisableOverscroll } from 'hooks/useDisableOverscroll'

import { ADULT_COHORT, Cohort, MIN_AGE } from 'root-constants'

import { StyledAge as S } from './AgeWithScroll.styles'

type TProps = {
  onChange: ({ target: { value, validity } }) => void
}

Swiper.use([Virtual, Mousewheel])

const AGE_OPTIONS_AMOUNT = 85

export const AgeWithScroll: React.FC<TProps> = ({ onChange }) => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort
  const userAge = useSelector(selectUserAge)

  useDisableOverscroll()

  const [isTouched, setIsTouched] = useState(false)

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const agesArray = Array.from(
    { length: AGE_OPTIONS_AMOUNT },
    (_, index) => MIN_AGE + index,
  )

  const handleChange = useCallback(
    (event) => {
      const index = event.activeIndex
      onChange({
        target: {
          value: agesArray[index].toString(),
          validity: { valid: isTouched },
        },
      })

      setIsTouched(true)
    },
    [agesArray, isTouched, onChange],
  )

  return (
    <S.WheelContainer $isAgeFlow={isAgeFlow}>
      <Carousel
        slidesPerView={3}
        direction="vertical"
        virtual={{
          addSlidesBefore: 5,
          addSlidesAfter: 5,
        }}
        centeredSlides
        mousewheel
        initialSlide={agesArray.indexOf(userAge || DEFAULT_AGE)}
        onSlideChange={handleChange}
        spaceBetween={16}
      >
        {agesArray.map((age, index) => (
          <SwiperSlide key={age} virtualIndex={index}>
            {({ isActive }) => (
              <S.NextAge $isActive={isActive}>{age}</S.NextAge>
            )}
          </SwiperSlide>
        ))}
      </Carousel>
    </S.WheelContainer>
  )
}
