import React from 'react'

import { StyledPageTitleDescription as S } from './PageTitleDescription.styles'

export type TPageTitleDescriptionProps = {
  marginBottom?: number
  className?: string
  children?: React.ReactNode
}

export const PageTitleDescription: React.FC<TPageTitleDescriptionProps> = ({
  children,
  className,
  marginBottom,
}) => (
  <S.PageTitleDescription className={className} $marginBottom={marginBottom}>
    {children}
  </S.PageTitleDescription>
)
