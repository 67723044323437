import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import {
  DEFAULT_INTRO_OFFER_SUBSCRIPTION_ITEM,
  useDynamicPaywallConfig,
} from 'hooks/useDynamicPaywallConfig'

import { SUBSCRIPTION_ITEMS } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionsBlockIntroOffer as S } from './SubscriptionsBlockIntroOffer.styles'

export type TThemeProps = {
  clearBackground?: boolean
  brightTheme?: boolean
}

type TProps = {
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  isCancelOffer?: boolean
  disclaimerVisible?: boolean
  children: React.ReactNode
  customTitle?: React.ReactNode
  withTitle?: boolean
  className?: string
}

export const SubscriptionsBlockIntroOffer: React.FC<TProps & TThemeProps> = ({
  elemForComparisonRef,
  isCancelOffer = false,
  disclaimerVisible = true,
  clearBackground = false,
  children,
  brightTheme = false,
  customTitle,
  withTitle = true,
  className = '',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { isBellyFlow } = useCohortInfo()

  const {
    subscriptionBlockTitle,
    subscriptionBlockType = DEFAULT_INTRO_OFFER_SUBSCRIPTION_ITEM,
  } = useDynamicPaywallConfig()

  const SubscriptionItem = SUBSCRIPTION_ITEMS[subscriptionBlockType]

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper
      className={className}
      $clearBackground={clearBackground}
      $brightTheme={brightTheme}
      $isBellyFlow={isBellyFlow}
    >
      {(subscriptionBlockTitle && (
        <S.Title>{subscriptionBlockTitle}</S.Title>
      )) ||
        (withTitle && customTitle) ||
        (withTitle && <S.Title>{t`purchase1.subscription.title`}</S.Title>)}
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
            isCancelOffer={isCancelOffer}
          />
        </Option>
      ))}
      <S.ButtonContainer ref={elemForComparisonRef} $isBellyFlow={isBellyFlow}>
        {children}
      </S.ButtonContainer>
      {disclaimerVisible && <S.SubscriptionDisclaimer />}
    </S.Wrapper>
  )
}
