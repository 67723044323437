import styled from 'styled-components'

import { BackButton } from 'components/BackButton'
import { OuterLink } from 'components/OuterLink'
import { SvgImage } from 'components/SvgImage'
import {
  TMenuProps,
  backdropStyles,
  menuCloseButtonStyles,
  sidebarStyles,
} from 'components/styles'

import { Color } from 'root-constants'

type TWrapperProps = {
  $hasWidget: boolean
}

export const StyledHeader = {
  Wrapper: styled.header<TWrapperProps>`
    position: relative;
    z-index: 1;
    padding: ${({ $hasWidget }) =>
      $hasWidget ? '11px 10px 11px 20px' : '10px 0'};
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: ${({ $hasWidget }) =>
      $hasWidget ? 'space-between' : 'center'};
    border-bottom: 1px solid rgba(19, 29, 48, 0.1);
    background-color: ${Color.WHITE};
  `,
  Logo: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.DARK};
  `,
  HeaderBackButton: styled(BackButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  `,
  SvgLogo: styled(SvgImage)`
    height: 30px;
    width: 30px;
    margin-right: 10px;
  `,
  NySvgLogo: styled(SvgImage)`
    height: 40px;
    width: 40px;
    margin-right: 10px;
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  SupportAction: styled(OuterLink)`
    min-width: 88px;
    border: 1px solid ${Color.GRAY};
    background-color: transparent;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    color: ${Color.GRAY};
    margin-right: 11px;
    cursor: pointer;
  `,
  Burger: styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    background-color: ${Color.WHITE};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Backdrop: styled.div<TMenuProps>`
    ${backdropStyles}
  `,
  Menu: styled.nav<TMenuProps>`
    ${sidebarStyles}
  `,
  MenuCloseButton: styled.button`
    ${menuCloseButtonStyles}
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
}
