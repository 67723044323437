import React, { FC, ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { GROWTHBOOK_EXPERIMENT, SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { SubscriptionItemInfo } from '../UpgradeSubscriptionItem/SubscriptionItemInfo'
import { StyledUpgradeSubscriptionItemMealPlan as S } from './UpgradeSubscriptionItemMealPlan.styles'

type TProps = {
  children: ReactNode
}

export const UpgradeSubscriptionItemMealPlan: FC<TProps> = ({ children }) => {
  const { t } = useTranslation()

  const currency = useSelector(selectCurrency)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const { subscriptionBlockTitle } = useDynamicPaywallConfig()
  const hasDisclaimerTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_708)

  const hasSevenDayTrial = useMemo(
    () => trialPeriodDays === SEVEN_DAY_TRIAL_DURATION,
    [trialPeriodDays],
  )

  return (
    <>
      <S.UpgradePlanBlock>
        <S.PlanTitle>
          {subscriptionBlockTitle || t('purchaseUpgrade.planTitle')}
        </S.PlanTitle>
        {hasSevenDayTrial ? (
          <S.TrialSubscriptionItemInfo />
        ) : (
          <SubscriptionItemInfo />
        )}

        <S.AdditionalInfoContainer>
          <S.BonusContainer>
            <S.FirstTitle>{t('purchaseUpgrade.firstTitle')}</S.FirstTitle>
            <S.SecondTitle>
              <Trans i18nKey="purchaseUpgradeMealPlan.bonus" />
            </S.SecondTitle>
          </S.BonusContainer>
          <S.BonusPrice>
            {CURRENCY_SYMBOLS[currency]}
            {Number(0).toFixed(2)}
          </S.BonusPrice>
        </S.AdditionalInfoContainer>

        <S.ButtonContainer>{children}</S.ButtonContainer>
      </S.UpgradePlanBlock>
      {hasDisclaimerTest && <S.UpgradeDisclaimer />}
    </>
  )
}
