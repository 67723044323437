import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { StyledReachTarget as S } from './ReachTargetClear.styles'

export const ReachTargetClear: FC = () => {
  const { title } = useDynamicPaywallConfig()
  return (
    <S.Wrapper>
      <S.Text>
        {title || <Trans i18nKey="purchaseWithoutIntro.feelYourBest" />}
      </S.Text>
    </S.Wrapper>
  )
}
