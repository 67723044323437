import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import changeImage from 'assets/images/you-can-always-change.png'

import { StyledChangeForBetter as S } from './ChangeForBetter.styles'
import { QUESTION } from './constants'

export const ChangeForBetter: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <S.ContainerBeforePinnedButton>
        <Container isLarge>
          <S.Image
            src={changeImage}
            alt="You can always change your habits for the better"
          />

          <PageTitle marginTop={24} marginBottom={8}>
            <Trans i18nKey="onboarding.changeForBetter.title" />
          </PageTitle>
          <S.Description>
            <Trans i18nKey="onboarding.changeForBetter.description" />
          </S.Description>
        </Container>
      </S.ContainerBeforePinnedButton>
      <S.NavigationButtonBelly type="button" onClick={handleClick}>
        <Trans i18nKey="actions.continue" />
      </S.NavigationButtonBelly>
    </>
  )
}
