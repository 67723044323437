import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContactWithSupport } from 'components/ContactWithSupport'

import { selectLanguage } from 'root-redux/selects/common'

import { STRIPE_IMAGE_PATH } from 'modules/purchase/constants'

import { CDN_FOLDER_LINK, PagesSource } from 'root-constants'

import { StyledSecurityInfo as S } from './SecurityInfo.styles'

export type TSecurityInfoProps = {
  className?: string
  margin?: string
}

export const SecurityInfo: React.FC<TSecurityInfoProps> = ({
  className = '',
  margin,
}) => {
  const { t } = useTranslation()

  const language = useSelector(selectLanguage)

  const secureBadgeImg = useMemo(
    () => `${CDN_FOLDER_LINK}${STRIPE_IMAGE_PATH}_${language}.png`,
    [language],
  )

  return (
    <S.Wrapper className={className} $margin={margin}>
      <S.SecureBadge src={secureBadgeImg} alt="Secure badge" />
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textFirst`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.Text>{t`purchase1.securityInfo.textSecond`}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>
      <S.Text>
        <ContactWithSupport
          text={t('commonComponents.contactSupport')}
          source={PagesSource.PAYWALL}
        />
      </S.Text>
    </S.Wrapper>
  )
}
