import birthdayPartyJa from 'assets/images/flow-belly/icons/birthday-party-ja.svg'
import birthdayParty from 'assets/images/flow-belly/icons/birthday-party.svg'
import familyGatheringJa from 'assets/images/flow-belly/icons/family-gathering-ja.svg'
import familyGathering from 'assets/images/flow-belly/icons/family-gathering.svg'
import feelMyBestJa from 'assets/images/flow-belly/icons/feel-my-best-ja.svg'
import feelMyBest from 'assets/images/flow-belly/icons/feel-my-best.svg'
import otherOccasionJa from 'assets/images/flow-belly/icons/other-occasion-ja.svg'
import otherOccasion from 'assets/images/flow-belly/icons/other-occasion.svg'
import schoolReunionJa from 'assets/images/flow-belly/icons/school-reunion-ja.svg'
import schoolReunion from 'assets/images/flow-belly/icons/school-reunion.svg'
import sportsEventJa from 'assets/images/flow-belly/icons/sport-ja.svg'
import sportsEvent from 'assets/images/flow-belly/icons/sport.svg'
import summerJa from 'assets/images/flow-belly/icons/summer-ja.svg'
import summer from 'assets/images/flow-belly/icons/summer.svg'
import vacationJa from 'assets/images/flow-belly/icons/vacation-ja.svg'
import vacation from 'assets/images/flow-belly/icons/vacation.svg'
import weddingJa from 'assets/images/flow-belly/icons/wedding-ja.svg'
import wedding from 'assets/images/flow-belly/icons/wedding.svg'

export const QUESTION =
  'Is there any specific event motivating you to lose weight right now?'

export const OPTION_VALUES = [
  {
    text: 'onboarding.motivatingEvent.vacation',
    value: 'vacation',
    imageUrl: vacation,
  },
  {
    text: 'onboarding.motivatingEvent.wedding',
    value: 'wedding',
    imageUrl: wedding,
  },
  {
    text: 'onboarding.motivatingEvent.sportsEvent',
    value: 'sportsEvent',
    imageUrl: sportsEvent,
  },
  {
    text: 'onboarding.motivatingEvent.summer',
    value: 'summer',
    imageUrl: summer,
  },
  {
    text: 'onboarding.motivatingEvent.schoolReunion',
    value: 'schoolReunion',
    imageUrl: schoolReunion,
  },
  {
    text: 'onboarding.motivatingEvent.familyGathering',
    value: 'familyGathering',
    imageUrl: familyGathering,
  },
  {
    text: 'onboarding.motivatingEvent.birthdayParty',
    value: 'birthdayParty',
    imageUrl: birthdayParty,
  },
  {
    text: 'onboarding.motivatingEvent.otherOccasion',
    value: 'otherOccasion',
    imageUrl: otherOccasion,
  },
  {
    text: 'onboarding.motivatingEvent.feelMyBest',
    value: 'feelMyBest',
    imageUrl: feelMyBest,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    text: 'onboarding.motivatingEvent.vacation',
    value: 'vacation',
    imageUrl: vacationJa,
  },
  {
    text: 'onboarding.motivatingEvent.wedding',
    value: 'wedding',
    imageUrl: weddingJa,
  },
  {
    text: 'onboarding.motivatingEvent.sportsEvent',
    value: 'sportsEvent',
    imageUrl: sportsEventJa,
  },
  {
    text: 'onboarding.motivatingEvent.summer',
    value: 'summer',
    imageUrl: summerJa,
  },
  {
    text: 'onboarding.motivatingEvent.schoolReunion',
    value: 'schoolReunion',
    imageUrl: schoolReunionJa,
  },
  {
    text: 'onboarding.motivatingEvent.familyGathering',
    value: 'familyGathering',
    imageUrl: familyGatheringJa,
  },
  {
    text: 'onboarding.motivatingEvent.birthdayParty',
    value: 'birthdayParty',
    imageUrl: birthdayPartyJa,
  },
  {
    text: 'onboarding.motivatingEvent.otherOccasion',
    value: 'otherOccasion',
    imageUrl: otherOccasionJa,
  },
  {
    text: 'onboarding.motivatingEvent.feelMyBest',
    value: 'feelMyBest',
    imageUrl: feelMyBestJa,
  },
]
