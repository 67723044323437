import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { Timer } from '../Timer'

type TTimerContainerProps = {
  $isButtonVisible?: boolean
}

export const StyledTwoPlansTimer = {
  TimerContainer: styled.div<TTimerContainerProps>`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: ${({ $isButtonVisible }) => ($isButtonVisible ? '68px' : '44px')};
    margin: ${({ $isButtonVisible }) =>
      $isButtonVisible ? '0 auto -24px' : '0 auto 20px'};
    background: linear-gradient(
        278deg,
        #f8002f 29.76%,
        #ff782a 59.72%,
        #ffb52a 87.4%
      ),
      #fff;
    border-radius: 0;
    box-shadow: 0 8px 20px 0 rgba(18, 22, 39, 0.1);
    justify-content: center;
    gap: 16px;
    z-index: 10;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  TimerText: styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${Color.WHITE};

    & > svg {
      margin-right: 8px;
    }

    & > div {
      margin-left: 8px;
    }
  `,
  SubheaderTimer: styled(Timer)`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  ButtonContainer: styled.div<TTimerContainerProps>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button`
    height: 48px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: ${Color.PRIMARY};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  `,
}
