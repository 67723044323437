export const BELLY_PLAN_LINK = '/images/upsell-belly-plan'
export const BODY_PROGRAM_LINK = '/images/upsell-body-program'

export const FEMININITY_LINK = '/images/upsell-femininity-plan'
export const CONFIDENCE_CARDIO_LINK = '/images/upsell-confidence-cardio'

export const CARDS_LOSE_WEIGHT = [
  {
    img: BELLY_PLAN_LINK,
    title: 'upsell.upsellDescription.bellyPlan',
    text: 'upsell.upsellPlansDescription.bellyPlanText',
    alt: 'belly-plan',
    withLabel: false,
  },
  {
    img: BODY_PROGRAM_LINK,
    title: 'upsell.upsellDescription.bodyProgram',
    text: 'upsell.upsellPlansDescription.fullBodyText',
    alt: 'full-body',
    withLabel: false,
  },
]

export const CARDS_STAY_FIT = [
  {
    img: FEMININITY_LINK,
    title: 'upsellV2.upsellPlansDescription.femininityPlanTitle',
    text: 'upsellV2.upsellPlansDescription.femininityPlanText',
    alt: 'belly-plan',
    withLabel: false,
  },
  {
    img: CONFIDENCE_CARDIO_LINK,
    title: 'upsellV2.upsellPlansDescription.confidenceCardioTitle',
    text: 'upsellV2.upsellPlansDescription.confidenceCardioText',
    alt: 'full-body',
    withLabel: true,
  },
]
