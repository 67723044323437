import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDailyBenefits = {
  Root: styled.div`
    padding: 0 16px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Caption: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  `,
  Title: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    flex-grow: 1;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK};
  `,
  Icon: styled.img`
    width: 40px;
    height: 40px;
    margin-right: 16px;
  `,
}
