import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReviewsCancelOffer = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto 50px;
    padding: 24px 16px;
    background-color: ${Color.LIGHT};
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 0;
  `,
  Title: styled.div`
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: bold;
    line-height: 34px;
    text-align: left;

    strong {
      color: ${Color.PRIMARY};
      text-transform: uppercase;
    }
  `,
  Span: styled.span`
    color: ${Color.PRIMARY};
    text-transform: uppercase;
  `,
  Picture: styled.picture`
    max-width: 117px;
  `,
  Comment: styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 30px 20px;
    border-radius: 25px;
    background-color: ${Color.WHITE};
    text-align: left;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `,
  Author: styled.p`
    font-size: 16px;
    font-weight: bold;
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    width: 85px;
    padding: 8px 0;

    svg {
      width: 14px;
      height: 14px;
    }
  `,
  Text: styled.p`
    font-size: 16px;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
  `,
}
