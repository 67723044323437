import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import imageBg from 'assets/images/flow-belly/try-workouts-bg.png'
import imageBgLegs from 'assets/images/flow-belly/try-workouts-legs-bg.png'
import imageBgJa from 'assets/images/try-workouts-ja.png'

import { GROWTHBOOK_EXPERIMENT, Gender } from 'root-constants'

import { StyledTryWorkouts as S } from './TryWorkouts.styles'
import {
  OPTION_VALUES,
  QUESTION,
  QUESTION_LEGS,
  QUESTION_MENOPAUSE,
  QUESTION_SOMATIC,
} from './constants'

export const TryWorkouts: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const { isLegsFlow, isSomaticFlow, isMenopauseFlow } = useCohortInfo()
  const { imageUrls } = useDynamicOBConfig()

  const isJapaneseFlow = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1288)

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const { question, pageTitle } = useMemo(() => {
    if (isLegsFlow) {
      return {
        question: QUESTION_LEGS,
        pageTitle: 'onboarding.tryWorkouts.titleLegs',
      }
    }

    if (isSomaticFlow) {
      return {
        question: QUESTION_SOMATIC,
        pageTitle: 'onboarding.tryWorkouts.titleSomatic',
      }
    }

    if (isMenopauseFlow) {
      return {
        question: QUESTION_MENOPAUSE,
        pageTitle: 'onboarding.tryWorkouts.titleMenopause',
      }
    }

    return {
      question: QUESTION,
      pageTitle: 'onboarding.tryWorkouts.title',
    }
  }, [isMenopauseFlow, isLegsFlow, isSomaticFlow])

  const handleContinue = useNextStep({
    pageId,
    question,
    nextPagePath,
  })

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  const bgImage = useMemo(() => {
    if (imageUrl) return imageUrl

    if (isLegsFlow) return imageBgLegs

    if (isJapaneseFlow) return imageBgJa

    return imageBg
  }, [imageUrl, isJapaneseFlow, isLegsFlow])

  const theme = isJapaneseFlow
    ? answerTheme.DANCEBIT_JAPANESE
    : answerTheme.DANCEBIT

  return (
    <S.Container isLarge $bgImageUrl={bgImage} $isJapanese={isJapaneseFlow}>
      <PageTitle marginBottom={24}>
        <Trans i18nKey={pageTitle} />
      </PageTitle>
      {OPTION_VALUES.map(({ value, title }) => (
        <Answer
          {...optionProps}
          disabled={isAnswersDisabled}
          theme={theme}
          maxWidth="180px"
          margin="0 0 16px 0"
          borderRadius="16px"
          key={value}
          value={value}
        >
          {t(title)}
        </Answer>
      ))}
    </S.Container>
  )
}
