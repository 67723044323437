import React from 'react'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { StyledFeaturedBlockUpgrade as S } from './FeaturedBlockUpgrade.styles'

export const FeaturedBlockUpgrade: React.FC = () => (
  <S.Container>
    <picture>
      <source srcSet={featuredImageWebp} type="image/webp" />
      <img src={featuredImagePng} alt="body" />
    </picture>
  </S.Container>
)
