import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'

export const StyledWorkSchedule = {
  AnswerButton: styled(AnswerButton)`
    & img {
      width: 48px;
      height: 48px;
    }
  `,
}
