import styled from 'styled-components'

export const StyledScratchCard = {
  Container: styled.div`
    width: 311px;
    height: 311px;
    position: relative;
    user-select: none;
  `,
  Animation: styled.div`
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
}
