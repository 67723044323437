import armsFemale from 'assets/images/arms-female.svg'
import armsMaleAge from 'assets/images/arms-male-age.svg'
import armsMale from 'assets/images/arms-male.svg'
import arms from 'assets/images/arms.svg'
import bellyFemale from 'assets/images/belly-female.svg'
import bellyMaleAge from 'assets/images/belly-male-age.svg'
import belly from 'assets/images/belly.svg'
import buttocksFemale from 'assets/images/buttocks-female.svg'
import buttocksMaleAge from 'assets/images/buttocks-male-age.svg'
import buttocksMale from 'assets/images/buttocks-male.svg'
import buttocks from 'assets/images/buttocks.svg'
import chestFemale from 'assets/images/chest-female.svg'
import chestMaleAge from 'assets/images/chest-male-age.svg'
import chest from 'assets/images/chest.svg'
import hipsFemale from 'assets/images/hips-female.svg'
import hipsMaleAge from 'assets/images/hips-male-age.svg'
import hipsMale from 'assets/images/hips-male.svg'
import hips from 'assets/images/hips.svg'
import improveAreasWomanAgeFlowPng from 'assets/images/improve-areas-female-age.png'
import improveAreasWomanAgeFlowWebp from 'assets/images/improve-areas-female-age.webp'
import improveAreasWomanPng from 'assets/images/improve-areas-female.png'
import improveAreasWomanWebp from 'assets/images/improve-areas-female.webp'
import improveAreasManAgeFlowPng from 'assets/images/improve-areas-male-age.png'
import improveAreasManAgeFlowWebp from 'assets/images/improve-areas-male-age.webp'
import improveAreasManPng from 'assets/images/improve-areas-male.png'
import improveAreasManWebp from 'assets/images/improve-areas-male.webp'
import legsFemale from 'assets/images/legs-female.svg'
import legsMaleAge from 'assets/images/legs-male-age.svg'
import legs from 'assets/images/legs.svg'

import { Gender } from 'root-constants'

export const QUESTION = 'Which areas would you like to improve?'

export const IMPROVE_AREAS_VALUES = {
  ARMS: 'arms',
  CHEST: 'chest',
  BELLY: 'belly',
  HIPS: 'hips',
  LEGS: 'legs',
  BUTTOCKS: 'buttocks',
  FULL_BODY: 'arms,chest,belly,hips,buttocks,legs',
}

export const BODY_IMAGE_MAP_AGE_FLOW = {
  [Gender.MALE]: {
    PNG: improveAreasManAgeFlowPng,
    WEBP: improveAreasManAgeFlowWebp,
  },
  [Gender.FEMALE]: {
    PNG: improveAreasWomanAgeFlowPng,
    WEBP: improveAreasWomanAgeFlowWebp,
  },
}

export const BODY_IMAGE_MAP = {
  [Gender.MALE]: {
    PNG: improveAreasManPng,
    WEBP: improveAreasManWebp,
  },
  [Gender.FEMALE]: {
    PNG: improveAreasWomanPng,
    WEBP: improveAreasWomanWebp,
  },
}

export const STYLES_MAP_AGE_FLOW = {
  [Gender.FEMALE]: {
    [IMPROVE_AREAS_VALUES.ARMS]: {
      $img: `url(${arms})`,
      $width: '54px',
      $top: '98px',
      $height: '50px',
      $right: '48px',
    },
    [IMPROVE_AREAS_VALUES.BELLY]: {
      $img: `url(${belly})`,
      $width: '55px',
      $top: '7px',
      $height: '44px',
      $right: '104px',
    },
    [IMPROVE_AREAS_VALUES.BUTTOCKS]: {
      $img: `url(${buttocks})`,
      $width: '50px',
      $top: '-30px',
      $height: '67px',
      $right: '84px',
    },
    [IMPROVE_AREAS_VALUES.CHEST]: {
      $img: `url(${chest})`,
      $width: '54px',
      $top: '40px',
      $right: '106px',
      $height: '50px',
    },
    [IMPROVE_AREAS_VALUES.HIPS]: {
      $img: `url(${hips})`,
      $width: '105px',
      $height: '80px',
      $top: '-56px',
      $right: '-20px',
    },
    [IMPROVE_AREAS_VALUES.LEGS]: {
      $img: `url(${legs})`,
      $width: '100px',
      $top: '-55px',
      $height: '56px',
      $right: '-8px',
    },
  },
  [Gender.MALE]: {
    [IMPROVE_AREAS_VALUES.ARMS]: {
      $img: `url(${armsMaleAge})`,
      $width: '54px',
      $top: '88px',
      $height: '72px',
      $right: '56px',
    },
    [IMPROVE_AREAS_VALUES.BELLY]: {
      $img: `url(${bellyMaleAge})`,
      $width: '55px',
      $top: '-3px',
      $height: '56px',
      $right: '103px',
    },
    [IMPROVE_AREAS_VALUES.BUTTOCKS]: {
      $img: `url(${buttocksMaleAge})`,
      $width: '50px',
      $top: '-28px',
      transform: 'translateX(-100%)',
      $height: '67px',
      $right: '87px',
    },
    [IMPROVE_AREAS_VALUES.CHEST]: {
      $img: `url(${chestMaleAge})`,
      $width: '50px',
      $top: '16px',
      $height: '44px',
      $right: '115px',
    },
    [IMPROVE_AREAS_VALUES.HIPS]: {
      $img: `url(${hipsMaleAge})`,
      $width: '65px',
      $top: '-52px',
      $height: '72px',
      $right: '70px',
    },
    [IMPROVE_AREAS_VALUES.LEGS]: {
      $img: `url(${legsMaleAge})`,
      $width: '48px',
      $top: '-54px',
      $height: '77px',
      $right: '88px',
    },
  },
}

export const STYLES_MAP = {
  [Gender.FEMALE]: {
    [IMPROVE_AREAS_VALUES.ARMS]: {
      $img: `url(${armsFemale})`,
      $width: '54px',
      $top: '99px',
      $height: '62px',
      $right: '58px',
    },
    [IMPROVE_AREAS_VALUES.BELLY]: {
      $img: `url(${bellyFemale})`,
      $width: '55px',
      $top: '12px',
      $height: '44px',
      $right: '122px',
    },
    [IMPROVE_AREAS_VALUES.BUTTOCKS]: {
      $img: `url(${buttocksFemale})`,
      $width: '50px',
      $top: '-9px',
      $height: '67px',
      $right: '100px',
    },
    [IMPROVE_AREAS_VALUES.CHEST]: {
      $img: `url(${chestFemale})`,
      $width: '54px',
      $top: '35px',
      $right: '112px',
      $height: '50px',
    },
    [IMPROVE_AREAS_VALUES.HIPS]: {
      $img: `url(${hipsFemale})`,
      $width: '105px',
      $height: '80px',
      $top: '-55px',
      $right: '-18px',
    },
    [IMPROVE_AREAS_VALUES.LEGS]: {
      $img: `url(${legsFemale})`,
      $width: '100px',
      $top: '-37px',
      $height: '56px',
      $right: '-30px',
    },
  },
  [Gender.MALE]: {
    [IMPROVE_AREAS_VALUES.ARMS]: {
      $img: `url(${armsMale})`,
      $width: '54px',
      $top: '88px',
      $height: '97px',
      $right: '76px',
    },
    [IMPROVE_AREAS_VALUES.BELLY]: {
      $img: `url(${bellyMaleAge})`,
      $width: '55px',
      $top: '0px',
      $height: '56px',
      $right: '110px',
    },
    [IMPROVE_AREAS_VALUES.BUTTOCKS]: {
      $img: `url(${buttocksMale})`,
      $width: '50px',
      $top: '-27px',
      $height: '67px',
      $right: '90px',
    },
    [IMPROVE_AREAS_VALUES.CHEST]: {
      $img: `url(${chestMaleAge})`,
      $width: '50px',
      $top: '16px',
      $height: '44px',
      $right: '122px',
    },
    [IMPROVE_AREAS_VALUES.HIPS]: {
      $img: `url(${hipsMale})`,
      $width: '65px',
      $top: '-43px',
      $height: '72px',
      $right: '72px',
    },
    [IMPROVE_AREAS_VALUES.LEGS]: {
      $img: `url(${legsMaleAge})`,
      $width: '48px',
      $top: '-39px',
      $height: '77px',
      $right: '94px',
    },
  },
}
