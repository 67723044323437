export const enum SubscriptionDuration {
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  ONE_YEAR = 12,
  TWO_YEAR = 24,
}

export const DISCLAIMERS = {
  [SubscriptionDuration.SIX_MONTHS]: 'purchaseUpgrade.disclaimer.sixMonths',
  [SubscriptionDuration.NINE_MONTHS]: 'purchaseUpgrade.disclaimer.nineMonths',
  [SubscriptionDuration.ONE_YEAR]: 'purchaseUpgrade.disclaimer.oneYear',
  [SubscriptionDuration.TWO_YEAR]: 'purchaseUpgrade.disclaimer.twoYear',
}
