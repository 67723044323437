import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

export const logGeneralAddToCartEvents = ({
  currency,
  email,
  periodQuantity,
  periodName,
  fullPrice,
  selectedSubscription,
  uuid,
  isPersonalDataAllowed,
}): void => {
  window.fbq &&
    window.fbq(
      'track',
      'AddToCart',
      {},
      { eventID: isPersonalDataAllowed ? uuid : '' },
    )
  window.pintrk &&
    window.pintrk('track', 'addtocart', {
      value: fullPrice,
      currency: currency.toUpperCase(),
      line_items: [
        {
          product_category: 'wellness',
          product_name: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          product_id: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          product_price: fullPrice,
        },
      ],
    })

  window.ttq &&
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  window.ttq &&
    window.ttq.track('AddToCart', {
      event_id: isPersonalDataAllowed ? uuid : '',
    })
  window.snaptr && window.snaptr('track', 'ADD_CART')
  window.obApi && window.obApi('track', 'Add To Cart')
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'add_to_cart',
    })
  window.rdt &&
    window.rdt('track', 'AddToCart', {
      email: isPersonalDataAllowed ? email : '',
      externalId: isPersonalDataAllowed ? uuid : '',
    })
  window.axon &&
    window.axon('track', 'add_to_cart', {
      value: fullPrice,
      currency,
    })
  googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
}

export const logGeneralCheckoutEvents = ({
  uuid,
  subscriptions,
  isPersonalDataAllowed,
}): void => {
  window.axon &&
    window.axon('track', 'begin_checkout', { items: subscriptions })
  window.fbq &&
    window.fbq(
      'track',
      'InitiateCheckout',
      {},
      { eventID: isPersonalDataAllowed ? uuid : '' },
    )
  googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  window.obApi && window.obApi('track', 'Checkout')
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'start_checkout',
    })
}
