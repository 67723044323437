import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfoUpgrade = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 32px;
    padding: 23px 0 22px 16px;
    border-radius: 16px;
    box-shadow: 0 8px 20px #e1e3ec;
    background: ${Color.WHITE}; ;
  `,
  Title: styled.h2`
    padding-bottom: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DISABLED};

    a {
      font-weight: 700;
      color: ${Color.PRIMARY};
    }
  `,
  SafeText: styled.p`
    padding-right: 97px;
    padding-bottom: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
  SecureText: styled.p`
    padding-right: 24px;
    padding-bottom: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
  SecureBadge: styled.img`
    position: absolute;
    top: 24px;
    right: 16px;
    max-width: 68px;
    height: 48px;
  `,
}
