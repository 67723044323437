import { IUserStatus, IUserStatusRaw } from 'models/user.model'

import { LOGIN_METHOD_RAW_MAP } from 'root-constants'

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
): IUserStatus => ({
  countryCode: userStatusRaw.country_code,
  isOnboardingSkipped: userStatusRaw.onboarding.is_skipped,
  onboarding: userStatusRaw.onboarding.quiz,
  config: {
    availableSpins: userStatusRaw.config?.availableSpins || 3,
    payment_currency: userStatusRaw.config?.payment_currency || '',
    payment_method: userStatusRaw.config?.payment_method || '',
    payment_system: userStatusRaw.config?.payment_system || '',
    is_download_visited: userStatusRaw.config?.is_download_visited || false,
    discount_applied: userStatusRaw.config?.discount_applied || false,
    subscription_price: userStatusRaw.config?.subscription_price || '',
    subscription_duration: userStatusRaw.config?.subscription_duration || '',
    price_id: userStatusRaw.config?.price_id || '',
    trial_period: userStatusRaw.config?.trial_period || '',
    trial_price: userStatusRaw.config?.trial_price || '',
    product_name: userStatusRaw.config?.product_name || '',
    upsell_product_name: userStatusRaw.config?.upsell_product_name || '',
    upsell_product_price: userStatusRaw.config?.upsell_product_price || '',
    goal: userStatusRaw.config?.goal || '',
    screen_name: userStatusRaw.config?.screen_name || '',
    period_name: userStatusRaw.config?.period_name || '',
    period_quantity: userStatusRaw.config?.period_quantity || '',
    item_name: userStatusRaw.config?.item_name,
    item_price: userStatusRaw.config?.item_price,
    payment_id: userStatusRaw.config?.payment_id,
    cookie_consent: userStatusRaw.config?.cookie_consent,
  },
  email: {
    hasEmail: userStatusRaw.email.has_email,
    hasEmailConsent: userStatusRaw.email.has_email_consent,
    isEmailConsentTried: userStatusRaw.email.is_email_consent_tried,
    onboardingEmail: userStatusRaw.email.onboarding_email,
  },
  hasSubscription: userStatusRaw.subscription.has_subscription,
  account: {
    hasAccount: userStatusRaw.account.has_account,
    loginMethod:
      LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method] || null,
    email: userStatusRaw.account.identifier,
  },
  upsell: {
    isUpsellPurchased: userStatusRaw.upsell.is_upsell_purchased,
    isUpsellAvailable: userStatusRaw.upsell.is_upsell_available,
    isUpsellTried: userStatusRaw.upsell.is_upsell_tried,
  },
  upgrade: {
    isUpgradePassed: userStatusRaw.upgrade.is_upgrade_passed,
    isUpgradeAvailable: userStatusRaw.upgrade.is_upgrade_available,
  },
})
