import React, { useCallback, useMemo } from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { StyledOnboardingProgressBar as S } from './OnboardingProgressBar.styles'

const PROGRESS_THRESHOLD_AMOUNT = 3

export const OnboardingProgressBar: React.FC = () => {
  const {
    currentPageId,
    hasProgressbar,
    pagesWithProgressBar,
  } = useGetPageInfo()

  const [
    firstSectionThreshold,
    secondSectionThreshold,
    currentPageIndex,
  ] = useMemo(() => {
    const firstThreshold = Math.round(
      pagesWithProgressBar.length / PROGRESS_THRESHOLD_AMOUNT,
    )
    const secondThreshold = pagesWithProgressBar.length - firstThreshold

    const pageIndex =
      pagesWithProgressBar.findIndex((step) => step.id === currentPageId) + 1

    return [firstThreshold, secondThreshold, pageIndex]
  }, [currentPageId, pagesWithProgressBar])

  const getProgressBarValue = useCallback(
    (threshold: number) =>
      currentPageIndex < threshold ? 0 : currentPageIndex - threshold,
    [currentPageIndex],
  )

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.StepBlock key={0}>
        <S.StepBlockProgress
          data-active={currentPageIndex > firstSectionThreshold}
          value={currentPageIndex}
          max={firstSectionThreshold}
        />
      </S.StepBlock>
      <S.StepBlock key={1}>
        <S.StepBlockProgress
          data-active={
            currentPageIndex <= secondSectionThreshold &&
            currentPageIndex > firstSectionThreshold
          }
          value={getProgressBarValue(firstSectionThreshold)}
          max={secondSectionThreshold - firstSectionThreshold}
        />
      </S.StepBlock>
      <S.StepBlock key={2}>
        <S.StepBlockProgress
          data-active={currentPageIndex > secondSectionThreshold}
          value={getProgressBarValue(secondSectionThreshold)}
          max={pagesWithProgressBar.length - secondSectionThreshold}
        />
      </S.StepBlock>
    </S.Wrapper>
  )
}
