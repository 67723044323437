import { IAppConfig, IAppConfigRaw } from 'models/config.model'

export const getAppConfigFromConfigRaw = (
  appConfig: IAppConfigRaw,
): IAppConfig => ({
  amplitudeApiKey: appConfig.amplitude_api_key,
  firebaseConfig: appConfig.firebase_config,
  giaApiKeys: appConfig.gia_api_keys,
  googleAnalyticsId: appConfig.google_analytics_id,
  liveChatWorkingHours: appConfig.livechat,
  downloadLinks: appConfig.download_links,
})
