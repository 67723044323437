import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledMenopause as S } from './Menopause.styled'
import { OPTIONS_VALUE, QUESTION } from './constants'

export const Menopause: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      margin: '0 0 16px 0',
      maxWidth: '100%',
      disabled: isAnswersDisabled,
      theme: answerTheme.DANCEBIT_ADULT,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      isAnswersDisabled,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Container isLarge>
      <PageTitle marginBottom={8}>
        <Trans i18nKey="onboarding.menopause.question" />
      </PageTitle>
      <S.Subtitle>{t`onboarding.menopause.subtitle`}</S.Subtitle>

      <Answer {...optionProps} value={OPTIONS_VALUE.NO}>
        <S.OptionTitle>{t`onboarding.menopause.no`}</S.OptionTitle>
      </Answer>

      <Answer {...optionProps} value={OPTIONS_VALUE.GOING_THROUGH_IT}>
        <S.OptionTitle>{t`onboarding.menopause.goingThroughIt`}</S.OptionTitle>
      </Answer>

      <Answer {...optionProps} value={OPTIONS_VALUE.ALREADY_PASSED_IT}>
        <S.OptionTitle>{t`onboarding.menopause.alreadyPassedIt`}</S.OptionTitle>
      </Answer>

      <Answer {...optionProps} value={OPTIONS_VALUE.PREFER_NOT_TO_ANSWER}>
        <S.OptionTitle>{t`onboarding.menopause.preferNotToAnswer`}</S.OptionTitle>
      </Answer>
    </S.Container>
  )
}
