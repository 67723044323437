import likeIcon from 'assets/images/like-icon.svg'
import lovelyIcon from 'assets/images/lovely-icon.svg'
import sadIcon from 'assets/images/sad-icon.svg'

export const QUESTION = 'How satisfied are you with your current body image?'

export const OPTION_VALUES = [
  {
    value: 'completelySatisfied',
    text: 'onboarding.satisfied.completelySatisfied',
    iconSrc: lovelyIcon,
  },
  {
    value: 'loveBody',
    text: 'onboarding.satisfied.loveBody',
    iconSrc: likeIcon,
  },
  {
    value: 'completelyDissatisfied',
    text: 'onboarding.satisfied.completelyDissatisfied',
    iconSrc: sadIcon,
  },
]
