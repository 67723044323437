import React from 'react'

import { StyledPageTitle as S } from './PageTitle.styles'

export type TPageTitleProps = {
  marginBottom?: number
  isAgeFlow?: boolean
  className?: string
  children?: React.ReactNode
}

export const PageTitle: React.FC<TPageTitleProps> = ({
  children,
  marginBottom,
  className,
  isAgeFlow = false,
}) => (
  <S.PageTitle
    className={className}
    $marginBottom={marginBottom}
    $isAgeFlow={isAgeFlow}
  >
    {children}
  </S.PageTitle>
)
