import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const StyledBoostYourHealth = {
  Container: styled(Container)<{ $imageUrl: string; $isAdult: boolean }>`
    position: relative;
    height: calc(100% - 52px);

    &::before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(230, 240, 254, 0) 0%,
        rgba(230, 240, 254, 0.83) 14.18%,
        #e6f0fe 26.75%,
        #e6f0fe 77.22%
      );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      max-width: 375px;
      background-image: url(${({ $imageUrl }) => $imageUrl});
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
    }
  `,
  PageTitle: styled(PageTitle)<{ $isAdult: boolean }>`
    text-align: left;

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        text-align: center;
        max-width: 328px;
        margin: 0 auto 8px;
      `}
  `,
  Root: styled.div`
    position: relative;
    height: 100%;
  `,
  TextBlock: styled.div`
    position: absolute;
    top: 52%;
    left: 0;
    z-index: 1;
    width: 100%;

    @media (min-height: 700px) {
      top: 47%;
    }
  `,
  Subtitle: styled.p<{ $isAdult: boolean }>`
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    & > p:first-child {
      margin-bottom: 8px;
    }

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        line-height: 18px;
      `}
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{ $isAdult: boolean }>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e6f0fe;
    border: none;

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: none;
        background-color: transparent;
      `}
  `,
}
