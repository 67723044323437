import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DECORATING_DISCOUNT,
  DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTS,
} from 'modules/purchase/constants'

import { IDynamicDiscount } from 'models/payment.model'
import { ISubscription } from 'models/subscriptions.model'

const getPriceWithFormattedDiscount = (
  subscriptionPrice: number,
  discount: number,
  priceFormatter,
) => {
  const calculatedOldPrice =
    subscriptionPrice /
    ((ONE_HUNDRED_PERCENTS - discount) / ONE_HUNDRED_PERCENTS)

  return Math.ceil(+priceFormatter(calculatedOldPrice)) - DECORATING_DISCOUNT
}

export const getSubscriptionWithDynamicDiscounts = (
  subscription: ISubscription,
  dynamicDiscount: IDynamicDiscount | null,
  isCancelOfferPage: boolean,
  hasExcludedVat: boolean,
  priceFormatter: (value: string | number) => string | number,
) => {
  const defaultDiscount =
    dynamicDiscount?.amount ||
    (isCancelOfferPage
      ? DEFAULT_CANCEL_OFFER_DYNAMIC_DISCOUNT
      : DEFAULT_DYNAMIC_DISCOUNT)

  const percentage = isCancelOfferPage
    ? defaultDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
    : defaultDiscount

  const totalAmount = getPriceWithFormattedDiscount(
    subscription.trialPrices.fullPrice,
    percentage,
    priceFormatter,
  )

  const taxDiscount = hasExcludedVat ? subscription.trialPrices.fullPriceTax : 0

  return {
    percentage,
    totalAmount: totalAmount.toFixed(2),
    discountAmount: (
      totalAmount -
      subscription.trialPrices.fullPrice +
      taxDiscount
    ).toFixed(2),
  }
}
