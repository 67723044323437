import styled, { css } from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { StyledNewAdultContainer } from '../WantToAchieve/WantToAchieve.styles'

export const DailyWaterStyles = {
  ContainerBeforePinnedButton: styled(ContainerBeforePinnedButton)<{
    $isAdult: boolean
  }>`
    height: calc(100% - 160px);

    ${({ $isAdult }) =>
      $isAdult &&
      css`
        ${StyledNewAdultContainer}
      `}
  `,
  GlassesContainer: styled.div`
    min-height: 340px;
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)<{ $isAdult: boolean }>`
    ${({ $isAdult }) =>
      $isAdult &&
      css`
        border-top: 0.5px solid #a7cdea;
        background: #d5ebff;
      `}
  `,
}
