import styled, { css } from 'styled-components'

import check from 'assets/images/check.svg'
import protectedIcon from 'assets/images/protected-payment-icon.svg'

import { Color } from 'root-constants'

type TPremium = {
  $isPremium?: boolean
  $isBasic?: boolean
}
export const StyledSubscriptionTwoPlansItem = {
  Card: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px 16px 16px;
    align-items: center;
    border-radius: 20px;
    background: #f0f4f8;
  `,
  NavigationContainer: styled.div<TPremium>`
    padding: 5px;
    position: fixed;
    top: -74px;
    width: 100%;
    display: flex;
    justify-content: ${({ $isPremium }) =>
      $isPremium ? 'flex-end' : 'flex-start'};
    align-items: center;
  `,
  PrevButton: styled.div<TPremium>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;

    ${({ $isBasic }) =>
      $isBasic &&
      css`
        display: flex;
        box-shadow: 0px 3px 12px 0px rgba(141, 144, 158, 0.3);

        & img {
          transform: rotate(180deg);
        }
      `}
  `,
  NextButton: styled.div<TPremium>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;

    ${({ $isPremium }) =>
      $isPremium &&
      css`
        display: flex;
        box-shadow: 0px 3px 12px 0px rgba(141, 144, 158, 0.3);
      `}
  `,
  Badge: styled.div<TPremium>`
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 5px 16px 0px rgba(141, 144, 158, 0.3);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    margin-bottom: 12px;

    ${({ $isPremium }) =>
      $isPremium
        ? css`
            background: radial-gradient(
              2678.6% 158.67% at -14.35% 130%,
              #fd7212 0%,
              #ffbe1b 46.27%,
              #fd7212 100%
            );
          `
        : css`
            background: radial-gradient(
              138.43% 169.69% at 27.82% 0%,
              #22d7ff 0%,
              #03aaf5 100%
            );
          `}
  `,
  PlanTitle: styled.h3`
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  `,
  PlanDescription: styled.p<TPremium>`
    text-align: center;
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: ${({ $isPremium }) => ($isPremium ? '12px' : '32px')};
  `,
  OldPrice: styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: strikethrough;
    margin-bottom: 6px;
    text-decoration: line-through;
  `,
  NewPrice: styled.div`
    color: ${Color.PRIMARY};
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
  `,
  WhatInSubscription: styled.ul<TPremium>`
    margin-bottom: ${({ $isPremium }) => ($isPremium ? '16px' : '106px')};
  `,
  Item: styled.li<TPremium>`
    position: relative;
    margin-bottom: 8px;
    padding-left: 34px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${({ $isBasic }) => $isBasic && Color.DISABLED};

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
      color: ${Color.WHITE};
      background: url(${check}) center center no-repeat;

      background-size: contain;
      ${({ $isBasic }) =>
        $isBasic &&
        css`
          background: url(${protectedIcon}) center center no-repeat;
        `}
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin: 18px 0 0;
  `,
  Bonus: styled.div`
    display: flex;
    width: 296px;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 12px;
    border: 1px solid ${Color.PRIMARY};
  `,
  BonusTitle: styled.div`
    color: ${Color.PRIMARY};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  BonusDescription: styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}
