import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeaturedBlockUpgrade = {
  Container: styled.div``,
  Disclaimer: styled.p`
    padding-top: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.LIGHT_GREY};
    text-align: center;

    a {
      color: ${Color.PRIMARY};
      text-decoration: underline;
    }
  `,
}
