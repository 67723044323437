import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTenMillionPeople = {
  Title: styled.h2`
    font-size: 36px;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 8px;
    color: ${Color.PRIMARY};
    text-align: center;
  `,
  Subtitle: styled.h3`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: ${Color.DARK};
    text-align: center;
  `,
  PictureContainer: styled.div`
    margin: 0 auto 32px auto;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 327px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
}
