import styled from 'styled-components'

import { ThemeTickerConfig } from 'components/Ticker/constants'

import { Color } from 'root-constants'

import {
  ThemeLimitedDealContainerConfig,
  ThemeLimitedDealDiscountLabelConfig,
  ThemeLimitedDealTextConfig,
} from './constants'

export const StyledLimitedDeal = {
  Container: styled.div<{ $theme?: string }>`
    display: flex;
    width: 100%;
    padding: 14px 12px;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 32px;

    ${({ $theme }) =>
      $theme
        ? ThemeLimitedDealContainerConfig[$theme]
        : ThemeTickerConfig.violet}
  `,
  DiscountLabel: styled.div<{ $theme?: string }>`
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 16px;
    border-radius: 10px;
    ${({ $theme }) =>
      $theme
        ? ThemeLimitedDealDiscountLabelConfig[$theme]
        : ThemeTickerConfig.violet}
  `,
  Text: styled.p<{ $theme?: string }>`
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${({ $theme }) =>
      $theme ? ThemeLimitedDealTextConfig[$theme] : ThemeTickerConfig.violet}
  `,
}
