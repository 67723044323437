import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY'
export const LOCALIZED_DATE_FORMAT = 'MMMM D, YYYY'
export const FULL_MONTH_FORMAT = 'MMMM'
export const DAY_MONTH_FORMAT = 'MMMM D'
export const SLASH_DATE_FORMAT = 'YYYY/MM/DD'
export const POLAND_DECLINATION = 'LL'
export const STANDARD_FORMAT = 'PP'

export const getFormattedTodaysDate = (
  dateFormat = DEFAULT_DATE_FORMAT,
  date = dayjs(),
  locale: string,
): string => date.locale(locale).format(dateFormat)

export const getFormattedDate = (
  targetFormat: string,
  currentFormat: string,
  date: string,
  locale: string,
): string => dayjs(date, currentFormat).locale(locale).format(targetFormat)

export const addDaysToCurrentDate = (
  days: number,
  dateFormat = DEFAULT_DATE_FORMAT,
): string => dayjs().add(days, 'day').format(dateFormat)

const getRandomDayInMonth = (
  month: Dayjs,
  format: string,
  locale: string,
): string => {
  const daysInMonth = month.daysInMonth()
  const randomDay = Math.floor(Math.random() * daysInMonth) + 1
  return month.date(randomDay).locale(locale).format(format)
}

export const getIntermediateDates = (
  goalDate: Dayjs,
  format = FULL_MONTH_FORMAT,
  hasDiffFloat = true,
  locale: string,
): string[] => {
  const currentDate = dayjs()
  const lastDateOfCurrentMonth = currentDate.endOf('month')

  const totalMonthsDiff = goalDate.diff(currentDate, 'month', hasDiffFloat)
  const remainingMonthsDiff =
    totalMonthsDiff - lastDateOfCurrentMonth.diff(currentDate, 'month', true)
  const countOfMonths = Math.max(0, Math.ceil(remainingMonthsDiff))

  const intermediateDates = Array.from({ length: countOfMonths }, (_, index) =>
    getRandomDayInMonth(currentDate.add(index + 1, 'month'), format, locale),
  )

  const goalDateFormatted = goalDate.locale(locale).format(format)

  if (intermediateDates.includes(goalDateFormatted)) {
    intermediateDates.pop()
  }

  return [
    currentDate.locale(locale).format(format),
    ...intermediateDates,
    goalDateFormatted,
  ]
}

export const getDatesArray = (
  predictableDate: Dayjs,
  locale: string,
  format?: string,
  hasDiffFloat?: boolean,
): string[] => {
  const allMonths = getIntermediateDates(
    predictableDate,
    format,
    hasDiffFloat,
    locale,
  )

  if (allMonths.length === 1) {
    return [...allMonths, ...allMonths]
  }

  if (allMonths.length > 5) {
    const composedMonths: string[] = new Array(4).fill('')

    return [
      ...composedMonths.reduce(
        (acc: string[], _, index) =>
          index <= 2
            ? [...acc, allMonths[index]]
            : [...acc, allMonths[index + 1]],
        [],
      ),
      allMonths[allMonths.length - 1],
    ]
  }

  return allMonths
}
