import styled from 'styled-components'

import { Option } from 'components/Option'

import { Color, MediaBreakpoint } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

type TBannerWithButtonProps = {
  $isVisable: boolean
}

export const StyledBannerWithButton = {
  Banner: styled.div<TBannerWithButtonProps>`
    width: 100%;

    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 5;

    display: ${({ $isVisable }) => ($isVisable ? 'flex' : 'none')};
    justify-content: center;

    padding: 12px 14px;

    background: #d3d7ea;
  `,
  Container: styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.div`
    max-width: 130px;

    color: ${Color.DARK};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  `,
  ButtonContainer: styled.div`
    display: flex;
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    width: 80px;
    height: 40px;
    border-radius: 10px;
    padding: 10px;

    color: ${Color.WHITE};

    &:first-child {
      background-color: #7369e4;
      margin-right: 12px;
    }

    &:last-child {
      background-color: ${Color.PRIMARY};
    }
  `,
  Action: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 8px;

    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
  `,
}
