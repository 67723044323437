import { useEffect } from 'react'

import { createProductId } from 'helpers/createProductId'

import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PagePath, ScreenId, ScreenName } from 'root-constants'

export const usePurchaseAnalytics = ({
  screenName,
  screenId,
  isAmplitudeEvent,
}: {
  screenName: ScreenName
  screenId?: ScreenId
  isAmplitudeEvent?: boolean
}): void => {
  const { subscriptions, cohort } = usePurchaseStore()

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map((subscription) =>
        createProductId({
          periodName: subscription.mainPrices.periodName,
          periodQuantity: subscription.mainPrices.periodQuantity,
          price: subscription.mainPrices.fullPrice,
        }),
      ),
      screenName,
      screenId,
      isAmplitudeEvent,
    })

    googleAnalyticsLogger.logPageView(`${PagePath.CHECKOUT}/${cohort}`)
  }, [cohort, subscriptions, screenName, screenId, isAmplitudeEvent])
}
