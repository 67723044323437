import characteristicImg1 from 'assets/images/characteristic-img1.png'
import characteristicImg2 from 'assets/images/characteristic-img2.png'

export const QUESTION = 'social proof'

export const CHARACTERISTIC_COMMENTS = [
  {
    img: characteristicImg1,
    name: 'onboarding.socialProof.firstCards.name',
    text: 'onboarding.socialProof.firstCards.text',
    danceStyles: [
      'onboarding.dancingStyle.aerobics',
      'onboarding.dancingStyle.bollywood',
      'onboarding.dancingStyle.latin',
    ],
  },
  {
    img: characteristicImg2,
    name: 'onboarding.socialProof.secondCards.name',
    text: 'onboarding.socialProof.secondCards.text',
    danceStyles: [
      'onboarding.dancingStyle.hipHop',
      'onboarding.dancingStyle.kiatJudDai_v2',
    ],
  },
]
