import React from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedAnimation } from 'hooks/useDelayedAnimation'

import { CDN_FOLDER_LINK, GOAL_WEIGHT_ANIMATION_PATH } from 'root-constants'

import { StyledGoalWeightGraph as S } from './GoalWeightGraph.styles'

export const GoalWeightGraph: React.FC = () => {
  const language = useSelector(selectLanguage)

  const [animationRef] = useDelayedAnimation({
    animationPath: `${CDN_FOLDER_LINK}${GOAL_WEIGHT_ANIMATION_PATH}_${language}.json`,
    animationName: 'goalWeightGraph',
  })

  return (
    <S.Wrapper>
      <S.GraphRef ref={animationRef} />
    </S.Wrapper>
  )
}
