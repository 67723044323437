import styled from 'styled-components'

import { UpsellBenefits } from 'modules/purchase/components/UpsellBenefits'
import { UpsellPlansDescription } from 'modules/purchase/components/UpsellPlansDescription'

import upsellBenefitsBg from 'assets/images/upsell-benefits-cover.png'

import { Color } from 'root-constants'

export const StyledUpsell = {
  Wrapper: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 4px 16px 20px;

    &:before {
      content: '';
      position: fixed;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #fff 4.54%, #f2f3f6 55%);
      background-size: contain;
      left: 0;
      top: 0;
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    padding-bottom: 16px;
  `,
  Subtitle: styled.h2`
    padding-bottom: 24px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  `,
  Link: styled.a`
    display: block;
    margin-bottom: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: underline;
    color: ${Color.GRAY_80};
    cursor: pointer;
  `,
  Disclaimer: styled.p`
    padding-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: ${Color.GRAY_80};
  `,
  Description: styled.p`
    max-width: 298px;
    margin: 0 auto 40px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  `,
  SubscriptionDescription: styled.p`
    margin-bottom: 16px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY_80};
    text-align: center;
    border-radius: 24px;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.WHITE};

    a {
      color: ${Color.PRIMARY};
      font-weight: 400;
      text-decoration: underline;
    }
  `,
  UpsellBenefits: styled(UpsellBenefits)`
    padding: 40px 16px;
    margin-bottom: 24px;
    border-radius: 24px;
    background: url(${upsellBenefitsBg}) center center no-repeat;
    background-size: 343px 396px;
    max-width: 100%;

    p {
      margin-bottom: 16px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  UpsellPlansDescription: styled(UpsellPlansDescription)`
    & > div {
      background-color: ${Color.WHITE};
      border: 1px solid ${Color.GRAY_20};
    }

    p {
      color: ${Color.GRAY_80};
    }
  `,
}
