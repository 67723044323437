import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledDancingLevel as S } from './DancingLevelBelly.styles'
import { DANCING_LEVEL, QUESTION } from './constants'

export const DancingLevelBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={8}>
        <Trans i18nKey="onboarding.dancingLevel.question" />
      </PageTitle>
      <S.Subtitle>{t`onboarding.dancingLevel.subtitle`}</S.Subtitle>
      <Answer
        {...optionProps}
        theme={answerTheme.DANCEBIT}
        margin="0 0 16px 0"
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.NEWBIE}
      >
        <S.OptionTitle>{t`onboarding.dancingLevel.newbie`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.newbieDescription`}</S.OptionDescription>
      </Answer>
      <Answer
        {...optionProps}
        theme={answerTheme.DANCEBIT}
        margin="0 0 16px 0"
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.INTERMEDIATE}
      >
        <S.OptionTitle>{t`onboarding.dancingLevel.intermediate`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.intermediateDescription`}</S.OptionDescription>
      </Answer>
      <Answer
        {...optionProps}
        theme={answerTheme.DANCEBIT}
        margin="0 0 16px 0"
        disabled={isAnswersDisabled}
        value={DANCING_LEVEL.ADVANCED}
      >
        <S.OptionTitle>{t`onboarding.dancingLevel.advanced`}</S.OptionTitle>
        <S.OptionDescription>{t`onboarding.dancingLevel.advancedDescription`}</S.OptionDescription>
      </Answer>
    </Container>
  )
}
