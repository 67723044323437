import React from 'react'
import { useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import tickerImage from 'assets/images/featured-logo.png'

import { StyledFeaturedBlock as S } from './FeaturedBlockNew.styles'

export const FeaturedBlockNew: React.FC<{
  hasTitle?: boolean
  className?: string
}> = ({ hasTitle = true, className }) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      {hasTitle && <S.Title>{t`purchase1.featuredBlockTitle`}</S.Title>}
      <S.CarouselContainer>
        <Carousel
          slidesPerView={0.5}
          loop
          speed={9000}
          observeParents
          observer
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <S.Img src={tickerImage} />
          </SwiperSlide>
          <SwiperSlide>
            <S.Img src={tickerImage} />
          </SwiperSlide>
          <SwiperSlide>
            <S.Img src={tickerImage} />
          </SwiperSlide>
        </Carousel>
      </S.CarouselContainer>
    </S.Container>
  )
}
