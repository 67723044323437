import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import sugarCravingsImage from 'assets/images/sugarCravings-somatic.png'

import { Gender } from 'root-constants'

import { StyledSugarCravings as S } from './SugarCravings.styles'
import { QUESTION } from './constants'

export const SugarCravings: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <S.Title marginBottom={16}>
            <Trans i18nKey="onboarding.sugarCravings.question" />
          </S.Title>

          <S.Paragraph>
            <Trans i18nKey="onboarding.sugarCravings.firstParagraph" />
          </S.Paragraph>
          <S.Paragraph>
            <Trans i18nKey="onboarding.sugarCravings.secondParagraph" />
          </S.Paragraph>

          <img
            src={imageUrl || sugarCravingsImage}
            alt="Break free from the grip of stress"
          />
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly type="button" onClick={handleClick}>
        {t('actions.continue')}
      </NavigationButtonBelly>
    </>
  )
}
