import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { Option } from 'components/Option'

import stickerImg from 'assets/images/sale-sticker-3.png'

import { Color } from 'root-constants'

type TRadioButtonProps = {
  $isChecked: boolean
}

export const StyledWelcome = {
  Root: styled.div`
    height: calc(100vh - 50px);

    &::before {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          226deg,
          rgba(50, 126, 238, 0.2) 10.31%,
          rgba(215, 76, 240, 0.2) 112.97%
        ),
        ${Color.WHITE};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `,
  Container: styled(Container)`
    padding: 0 16px;
  `,
  Subtitle: styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${Color.DARK};
    text-align: center;
    text-transform: uppercase;

    p {
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: ${Color.BLACK};
    }
  `,
  Title: styled.h1<{ $hasSticker: boolean }>`
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: ${Color.DARK};
    margin: 0 auto 16px auto;
    padding-top: ${({ $hasSticker }) => ($hasSticker ? ' 48px' : '30px')};
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    border-radius: 12px;
    position: relative;
    background: ${Color.WHITE};
    box-shadow: 0 3px 13px 0 #d6dcf4;
    margin: 16px 0 2px;
    width: 156px;
    height: 165px;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        border: 2px solid ${Color.PRIMARY};
      `}
    &:last-child {
      margin-bottom: 2px;

      ${({ $isChecked }) =>
        $isChecked &&
        css`
          margin-bottom: 0;
        `}
    }

    &:nth-child(2n) {
      margin-left: 16px;
    }
  `,
  Image: styled.img<TRadioButtonProps>`
    position: absolute;
    bottom: 36px;
    left: 0;
    width: 156px;
    height: 140px;
    object-fit: cover;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        bottom: 34px;
      `}
  `,
  Action: styled.div<TRadioButtonProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 12px 12px;
    width: 156px;
    height: 36px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        bottom: -2px;
        left: -2px;
      `}
  `,
  Terms: styled.div`
    z-index: 2;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
    margin: 0 auto;
    padding-bottom: 16px;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.DISABLED};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Sticker: styled.div`
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    width: 168px;
    height: 46px;
    background: url(${stickerImg});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      color: ${Color.WHITE};
      font-family: Jost, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }
  `,
}
