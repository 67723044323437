import aboveLowIconJa from 'assets/images/flow-belly/icons/above-low-energy-ja.svg'
import aboveLowIcon from 'assets/images/flow-belly/icons/above-low-energy.svg'
import highIconJa from 'assets/images/flow-belly/icons/high-energy-ja.svg'
import highIcon from 'assets/images/flow-belly/icons/high-energy.svg'
import lowIconJa from 'assets/images/flow-belly/icons/low-energy-ja.svg'
import lowIcon from 'assets/images/flow-belly/icons/low-energy.svg'
import mediumIconJa from 'assets/images/flow-belly/icons/medium-energy-ja.svg'
import mediumIcon from 'assets/images/flow-belly/icons/medium-energy.svg'

export const QUESTION = 'How are your energy levels during the day?'

export const OPTION_VALUES_ENERGY_BELLY = [
  {
    value: 'low',
    text: 'onboarding.energyBelly.low',
    imageUrl: lowIcon,
  },
  {
    value: 'aboveLow',
    text: 'onboarding.energyBelly.aboveLow',
    imageUrl: aboveLowIcon,
  },
  {
    value: 'medium',
    text: 'onboarding.energyBelly.medium',
    imageUrl: mediumIcon,
  },
  {
    value: 'high',
    text: 'onboarding.energyBelly.high',
    imageUrl: highIcon,
  },
]
export const OPTION_VALUES_JAPANESE = [
  {
    value: 'low',
    text: 'onboarding.energyBelly.low',
    imageUrl: lowIconJa,
  },
  {
    value: 'aboveLow',
    text: 'onboarding.energyBelly.aboveLow',
    imageUrl: aboveLowIconJa,
  },
  {
    value: 'medium',
    text: 'onboarding.energyBelly.medium',
    imageUrl: mediumIconJa,
  },
  {
    value: 'high',
    text: 'onboarding.energyBelly.high',
    imageUrl: highIconJa,
  },
]
