import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPageTitleDescription = {
  PageTitleDescription: styled.p<{ $marginBottom?: number }>`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom && `${$marginBottom}px`};
  `,
}
