import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerWithIcon } from 'storybook-ui'

import { Container } from 'components/Container'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BUTTON_WITH_ICON_STYLE_PROPS, Color } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const Relaxed: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const {
    isAnswersDisabled,
    handleChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.RADIO,
      ...BUTTON_WITH_ICON_STYLE_PROPS,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container isLarge>
      <PageTitle marginBottom={24}>
        {t('onboarding.relaxed.question')}
      </PageTitle>

      {OPTION_VALUES.map(({ value, text, iconSrc }) => (
        <AnswerWithIcon
          {...optionProps}
          value={value}
          iconSrc={iconSrc}
          color={Color.DARK}
          key={value}
          disabled={isAnswersDisabled}
        >
          {t(text)}
        </AnswerWithIcon>
      ))}
    </Container>
  )
}
