import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeaturedBlock = {
  Title: styled.h2`
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.GRAY_60};
  `,
  Container: styled.div`
    p {
      margin-top: 20px;
    }

    br {
      display: none;
    }
  `,
}
