import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'firebase/auth'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import {
  Currency,
  countriesListIncludedVat,
  countriesListWithExcludedVat,
} from 'modules/purchase/constants'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { Cohort } from 'root-constants'

const VAT_INCLUDED_COHORTS = [
  Cohort.DB_INTRO_DOP,
  Cohort.DB_INTRO_FAST,
  Cohort.DB_INTRO_2,
  Cohort.DANCEBIT_FLOW_1,
  Cohort.DB_FLOW_7D,
  Cohort.DB_FLOW_2,
  Cohort.DB_CLEAR_INTRO_FAST,
  Cohort.DB_ADULT,
  Cohort.DB_ADULT_2,
  Cohort.DB_ADULT_NEW,
  Cohort.EMAIL_INTRO_FAST,
  Cohort.CANCEL_INTRO_FAST,
  Cohort.SOMATIC_GOAL,
  Cohort.SOMATIC_AGE,
  Cohort.DB_TWO_PLANS,
  Cohort.DB_BELLY,
  Cohort.DB_MENOPAUSE,
  Cohort.DB_MENOPAUSE_REF,
  Cohort.DB_BELLY_SALE,
  Cohort.DB_BELLY_WEEK,
  Cohort.DB_BELLY_WEEK2,
  Cohort.DB_LEGS,
  Cohort.DB_REF,
  Cohort.DB_BELLY_REF,
  Cohort.DB_INFLUENCER,
  Cohort.DB_WHEEL,
  Cohort.DB_WHEEL2,
  Cohort.DANCEBIT_CHAT_UPGRADE_ANNUALS_PDF,
]

export const useVatInfo = (hasExcludedVat?: boolean): boolean => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const currency = useSelector(selectCurrency)
  const countryCode = useSelector(selectUserCountryCode)
  const cohortToUse = parentCohort || cohort

  return useMemo(() => {
    if (!VAT_INCLUDED_COHORTS.includes(cohortToUse as Cohort)) return false

    if (currency === Currency.MXN && hasExcludedVat) return false

    return (hasExcludedVat
      ? countriesListWithExcludedVat
      : [...countriesListIncludedVat, ...countriesListWithExcludedVat]
    ).includes(countryCode)
  }, [cohortToUse, currency, hasExcludedVat, countryCode])
}
