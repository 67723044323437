export const QUESTION = 'What’s holding you back from reaching your goals?'

export const OPTION_VALUES = [
  {
    value: 'notKnowWhereStart',
    title: 'onboarding.whatsHoldYou.notKnow',
  },
  {
    value: 'lackOfSupport',
    title: 'onboarding.whatsHoldYou.lackOfSupport',
  },
  {
    value: 'busySchedule',
    title: 'onboarding.whatsHoldYou.busySchedule',
  },
  {
    value: 'unhealthyHabits',
    title: 'onboarding.whatsHoldYou.unhealthyHabits',
  },
  {
    value: 'nothingSpecific',
    title: 'onboarding.whatsHoldYou.nothingSpecific',
  },
]
