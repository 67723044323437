import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { NavigationButton } from 'components/NavigationButton'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsStayFitFlow,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { ADULT_COHORT, Cohort, MAIN_GOALS } from 'root-constants'

import { StyledAdditionalGoals as S } from './AdditionalGoals.styles'
import { MINIMUM_ANSWERS_COUNT, OPTION_VALUES, QUESTION } from './constants'

export const AdditionalGoals: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  const userGoal = isStayFitGoal ? MAIN_GOALS.STAY_FIT : MAIN_GOALS.LOSE_WEIGHT

  const [answers, setAnswers] = useState<string[]>([userGoal])

  const cohortToUse = parentCohort || cohort

  const handleContinue = useNextStep({
    pageId: PageId.MAIN_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isStayFitFlow = useMemo(
    () => cohortToUse !== Cohort.DB_FLOW_2 && isStayFitGoal,
    [cohortToUse, isStayFitGoal],
  )

  const additionalGoal = useMemo(
    () =>
      isStayFitFlow
        ? OPTION_VALUES[MAIN_GOALS.STAY_FIT]
        : OPTION_VALUES[MAIN_GOALS.LOSE_WEIGHT],
    [isStayFitFlow],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <div>
      <S.Container $isAgeFlow={isAgeFlow}>
        <PageTitle marginBottom={32} isAgeFlow={isAgeFlow}>
          {t('onboarding.additionalGoals.title')}
        </PageTitle>

        {additionalGoal.map(({ value, title }) => (
          <Option
            {...optionProps}
            key={value}
            value={value}
            checked={answers.includes(value)}
          >
            <AnswerButton withCheckbox isAgeFlow={isAgeFlow}>
              {t(title)}
            </AnswerButton>
          </Option>
        ))}
      </S.Container>

      <NavigationButton
        type="button"
        isAgeFlow={isAgeFlow}
        disabled={answers.length <= MINIMUM_ANSWERS_COUNT}
        onClick={handleClick}
      >
        {t('actions.continue')}
      </NavigationButton>
    </div>
  )
}
