import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import glassAnimation from 'assets/animation/glass.json'

import { StyledGlasses as S } from './Glasses.styles'

type TPageProps = {
  selectedQuantity: string
  setSelectedQuantity: (value: string) => void
  quantity?: number
}

export const Glasses: React.FC<TPageProps> = ({
  selectedQuantity,
  setSelectedQuantity,
  quantity = 10,
}) => {
  const animationRefs = useRef<(HTMLButtonElement | null)[]>([])

  const glassesList = useMemo(
    () => Array.from({ length: quantity }, (_, index) => index + 1),
    [quantity],
  )

  useEffect(() => {
    const refs = animationRefs.current

    refs.forEach((ref, index) => {
      if (ref) {
        lottie.loadAnimation({
          container: ref,
          animationData: glassAnimation,
          loop: true,
          name: `glasses-${index}`,
        })
      }
    })

    return () => {
      refs.forEach((_, index) => {
        lottie.destroy(`glasses-${index}`)
      })
    }
  }, [quantity])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedQuantity(event.currentTarget.value)
    },
    [setSelectedQuantity],
  )

  return (
    <S.Root>
      {glassesList.map((item, index) => {
        const isActiveGlass =
          !!selectedQuantity && item <= Number(selectedQuantity)

        return (
          <S.Button
            ref={(el) => {
              animationRefs.current[index] = el
            }}
            $isActive={isActiveGlass}
            key={item}
            type="button"
            value={item.toString()}
            onClick={handleClick}
            $index={index}
          />
        )
      })}
    </S.Root>
  )
}
