import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledBeforeAfterIntro = {
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Image: styled.img`
    min-height: 300px;
  `,
  Title: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 8px 16px;

    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;

    color: ${Color.WHITE};
    background: linear-gradient(273deg, #ae65e7 20.78%, #dcb0ff 121.36%);
  `,
  ArrowContainer: styled.div`
    & svg:nth-child(1) {
      opacity: 0.2;
    }

    & svg:nth-child(2) {
      opacity: 0.6;
    }
  `,
}
